import { useEffect } from 'react';
import useDebounce from './useDebounce';
import { isEqual } from 'lodash';

const useFormikDebouncedState = <ValueType>(
  callback: (value: ValueType) => void,
  currentState: ValueType
): [ValueType, (value: ValueType) => void] => {
  const [debounced, value, setValue] = useDebounce<ValueType>(300, currentState);

  useEffect(() => {
    if (isEqual(currentState, debounced)) return;
    callback(debounced);
  }, [debounced, currentState]);

  return [value, setValue];
};

export default useFormikDebouncedState;
