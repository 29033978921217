import React, { useEffect, useMemo, useState } from 'react';
import DefaultStatusTile from './DefaultStatusTile';
import ApprovedStatusTile from './ApprovedStatusTile';
import { PageModelService } from '../../../../../../../../models/permissions/services/PageModelService';
import { DeliverableCCTMFlow } from '../../../../../../../../models/permissions/flows/deliverable/DeliverableCCTMFlow';
import { DeliverableCCTMBBFlow } from '../../../../../../../../models/permissions/flows/deliverable/DeliverableCCTMBBFlow';
import { COLLABORATOR_ROLE_TYPES } from '../../../../../../../../models/permissions/collaborator/CollaboratorRole';
import { capitalize } from 'lodash';
import { DELIVERABLE_STATUSES } from '../../../../../../../../models/enums/DeliverableStatuses';

export type DeliverableStatusTileSettingsType = {
  toolTip?: { [K in DELIVERABLE_STATUSES]?: string } | null | {};
  approvedOutlined?: boolean;
};

export type DeliverableStatusTileType = {
  [DELIVERABLE_STATUSES.IN_PROGRESS]: JSX.Element;
  [DELIVERABLE_STATUSES.PENDING_APPROVAL]: JSX.Element;
  [DELIVERABLE_STATUSES.APPROVED]: JSX.Element;
  [DELIVERABLE_STATUSES.EMPTY]: JSX.Element;
};

type UseDeliverableStatusTileType = (
  status: DELIVERABLE_STATUSES,
  settings: DeliverableStatusTileSettingsType
) => DeliverableStatusTileType | null;

const useDeliverableStatusTile: UseDeliverableStatusTileType = (status, settings) => {
  const [statusTile, setStatusTile] = useState<DeliverableStatusTileType | null>(null);

  const tiles: DeliverableStatusTileType = useMemo(() => {
    return {
      [DELIVERABLE_STATUSES.IN_PROGRESS]: (
        <DefaultStatusTile
          statusText="In progress"
          status={DELIVERABLE_STATUSES.IN_PROGRESS}
          withTooltipText={settings?.toolTip?.[DELIVERABLE_STATUSES.IN_PROGRESS] ?? null}
        />
      ),
      [DELIVERABLE_STATUSES.PENDING_APPROVAL]: (
        <DefaultStatusTile
          statusText="Pending approval"
          status={DELIVERABLE_STATUSES.PENDING_APPROVAL}
          withTooltipText={settings?.toolTip?.[DELIVERABLE_STATUSES.PENDING_APPROVAL] ?? null}
        />
      ),
      [DELIVERABLE_STATUSES.APPROVED]: <ApprovedStatusTile outline={!!settings?.approvedOutlined} />,
      [DELIVERABLE_STATUSES.EMPTY]: (
        <DefaultStatusTile
          statusText="Empty"
          status={DELIVERABLE_STATUSES.EMPTY}
          withTooltipText={settings?.toolTip?.[DELIVERABLE_STATUSES.EMPTY] ?? null}
        />
      ),
    };
  }, [settings]);

  useEffect(() => {
    if (!status) return;
    setStatusTile(tiles[status]);
  }, [status, tiles]);

  return statusTile;
};

type StatusesTooltipsSettingsObjectType = {
  [DELIVERABLE_STATUSES.IN_PROGRESS]: string;
  [DELIVERABLE_STATUSES.PENDING_APPROVAL]: string;
  [DELIVERABLE_STATUSES.EMPTY]: string;
};

type DeliverableStatusesTooltipsType = {
  concept: StatusesTooltipsSettingsObjectType | {};
  content: StatusesTooltipsSettingsObjectType | {};
};

type GetDeliverableStatusesTooltips = (roleType?: COLLABORATOR_ROLE_TYPES, collaborators?: any) => DeliverableStatusesTooltipsType;

export const getDeliverableStatusesTooltips: GetDeliverableStatusesTooltips = (roleType, collaborators) => {
  const currentFlow = PageModelService.getCurrentDeliverablePageFlow(collaborators);
  const isCCTMBA = currentFlow instanceof DeliverableCCTMFlow || currentFlow instanceof DeliverableCCTMBBFlow;
  const collaboratorsTypes = collaborators.map(collaborator => collaborator.type);
  const isABExists =
    collaboratorsTypes.includes(COLLABORATOR_ROLE_TYPES.AGENCY) && collaboratorsTypes.includes(COLLABORATOR_ROLE_TYPES.BRAND);

  const getSection = sectionName => {
    switch (roleType) {
      case COLLABORATOR_ROLE_TYPES.AGENCY: {
        const texts = {
          [DELIVERABLE_STATUSES.EMPTY]: `Content Creator have yet not started to work on ${sectionName}`,
          [DELIVERABLE_STATUSES.IN_PROGRESS]: `Content Creator is working on your ${sectionName}`,
          [DELIVERABLE_STATUSES.PENDING_APPROVAL]: `Collaborators are waiting for your approval. Let them know if you agree with the ${sectionName} by clicking Approve`,
        };

        return texts;
      }
      case COLLABORATOR_ROLE_TYPES.BRAND: {
        const texts = {
          [DELIVERABLE_STATUSES.EMPTY]: `Content Creator have yet not started to work on ${sectionName}`,
          [DELIVERABLE_STATUSES.IN_PROGRESS]: `Content Creator is working on your ${sectionName}`,
          [DELIVERABLE_STATUSES.PENDING_APPROVAL]: `Collaborators are waiting for your approval. Let them know if you agree with the ${sectionName} by clicking Approve`,
        };

        return texts;
      }

      case COLLABORATOR_ROLE_TYPES.TALENT_MANAGER: {
        const texts = {
          [DELIVERABLE_STATUSES.EMPTY]: `${capitalize(sectionName)} is pending to be shared by Content Creator`,
          [DELIVERABLE_STATUSES.IN_PROGRESS]: `${capitalize(
            sectionName
          )} is yet to be shared with agency / brand (use agency if agency is invited and brand if brand is invited) . Let them know by clicking Share button`,
          [DELIVERABLE_STATUSES.PENDING_APPROVAL]: `${capitalize(
            sectionName
          )} is pending approval from Agency/Brand. Don't worry we will notify you once they approve`,
        };

        if (isCCTMBA) {
          let textPartBA = 'Brand/Agency';
          if (!isABExists) {
            const brandOrAgency = collaborators.find(
              collaborator => collaborator.type === COLLABORATOR_ROLE_TYPES.BRAND || collaborator.type === COLLABORATOR_ROLE_TYPES.AGENCY
            );
            if (brandOrAgency) {
              textPartBA = capitalize(brandOrAgency?.type);
            }
          }
          texts[DELIVERABLE_STATUSES.IN_PROGRESS] = `${capitalize(sectionName)} is yet to be shared with ${textPartBA}`;
        }

        return texts;
      }

      case COLLABORATOR_ROLE_TYPES.CONTENT_CREATOR: {
        const texts = {
          [DELIVERABLE_STATUSES.EMPTY]: `You are yet to work on ${sectionName}`,
          [DELIVERABLE_STATUSES.IN_PROGRESS]: `Your ${capitalize(sectionName)} is shared with your Talent Manager`,
          [DELIVERABLE_STATUSES.PENDING_APPROVAL]: `Your ${capitalize(
            sectionName
          )} is pending approval from Agency/Brand. Don't worry we will notify you once they approve`,
        };

        if (isCCTMBA) {
          let textPartBA = 'Brand/Agency';
          if (!isABExists) {
            const brandOrAgency = collaborators.find(
              collaborator => collaborator.type === COLLABORATOR_ROLE_TYPES.BRAND || collaborator.type === COLLABORATOR_ROLE_TYPES.AGENCY
            );
            if (brandOrAgency) {
              textPartBA = capitalize(brandOrAgency?.type);
            }
          }
          texts[DELIVERABLE_STATUSES.IN_PROGRESS] = `${capitalize(sectionName)} is yet to be shared with ${textPartBA}`;
        }
        return texts;
      }
      default:
        return {};
    }
  };
  return { concept: getSection('concept'), content: getSection('content') };
};

export default useDeliverableStatusTile;
