import React from 'react';
import styles from './CounterV1.module.scss';
import cs from 'classnames';

const CounterV1 = ({ currentCount, totalCount }) => {
  return (
    <div className={cs(styles.root, { [styles.active]: currentCount !== 0 })}>
      {currentCount}/{totalCount}
    </div>
  );
};

export default CounterV1;
