import { OrganizationPermission } from '../enum/OrganizationPermission';
import { ORGANIZATION_TYPES } from '../../enums/OrganizationTypes';

export abstract class Organization {
  abstract type: ORGANIZATION_TYPES;
  protected abstract permissions: OrganizationPermission[];
  hasAccess(permission: OrganizationPermission): boolean {
    return this.permissions.includes(permission);
  }
}
