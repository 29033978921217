/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './TeamMembers.module.scss';
import cs from 'classnames';
import { useMediaQuery } from 'react-responsive/src';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';
import { AddIcon } from '../../../expert/components/IconsSvg/ReactIcons/ReactIcons';
import TeamMemberCell from '../../../components/Cells/TeamMemberCell';
import LoaderModal from '../../../expert/components/Modal/LoaderModal';
import AgreeModal from '../../../expert/components/Modal/AgreeModal';
import { useDispatch, useSelector } from 'react-redux';
import { changeMemberRole, changeUserStatus, deleteMemberInvite, getAllMembers } from '../../../actions/organizations.actions';
import { NavLink } from 'react-router-dom';
import MembersSVG from '../../../components/IconsSvg/MembersSVG';
import { ReactComponent as IconUser } from '../../../assets/img/icons/IconUser.svg';
import PlanProgressBar from '../../../components/PlanProgressBar';
import TeamMembersLoading from './TeamMembersLoading';
import Pagination from '../../../components/Pagination';
import { useHistory } from 'react-router';
import InviteMemberModal from '../../../components/Modals/InviteMemberModal';
import { inviteMember } from '../../../actions/user.actions';
import { MEMBER_ROLE_IN_ORGANIZATION_TYPES } from '../../../models/enums/MemberRoleInOrganizationTypes';
import { getCurrentOrganizationPricingPlan, me } from '../../../actions/auth.actions';
import ButtonLightPurpleWhite from '../../../components/Buttons/ButtonLightPurpleWhite';

const TeamMembers = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const componentMounted = useRef(true);
  const { data: members, totalCount, loading: memberLoading } = useSelector(state => state.organizations.allMembers);
  const isGetMeLoading = useSelector(state => state.auth.isGetMeLoading);
  const membersInPlan = useSelector(state => state.auth.currentOrganizationPlan?.team_members);
  const currentMembers = useSelector(state => state.auth.currentOrganizationPlan?.usage?.team_members);
  const currentOrg = useSelector(state => state.auth.currentOrganization);
  const user = useSelector(state => state.auth.user);
  const currentPlan = useSelector(state => state.auth.currentOrganizationPlan);
  const allOrganizationPlans = useSelector(state => state.payment.allPlansByOrgType);
  const loadingUpdate = useSelector(state => state.organizations.organizationMembers.loadingUpdate);
  const searchParams = new URLSearchParams(history.location.search);
  const urlPage = Number(searchParams.get('page'));
  /* iPad portrait is 768px */
  const isHorizontal = useMediaQuery({
    query: '(min-width: 769px)',
  });
  const [isRemoveModal, setIsRemoveModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState();
  const [isInviteOpen, setIsInviteOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(urlPage || 1);
  const status = 'active,pending';
  const pageSize = 10;
  const isFreePlan = currentPlan?.is_free;
  const disabled = isFreePlan || (currentPlan?.team_members !== -1 && currentPlan?.usage?.team_members >= currentPlan?.team_members);
  const isOwner = currentOrg?.role.toLowerCase() === MEMBER_ROLE_IN_ORGANIZATION_TYPES.OWNER;
  const disabledInvite = !isOwner || (currentPlan?.team_members !== -1 && currentPlan?.usage?.team_members >= currentPlan?.team_members);

  const isDisplayUpgrade = useMemo(() => {
    if (!currentPlan || Number(currentPlan?.team_members) === -1) return false;
    const allPlans = allOrganizationPlans.filter(plan => plan.id !== 1007);
    return allPlans.some(plan => plan.team_members > currentPlan.team_members);
  }, [currentPlan, allOrganizationPlans]);

  const loadPlanInfo = async () => {
    if (!currentOrg) {
      return;
    }
    history.replace(`/organization/members/?page=${page}`);
    await dispatch(getCurrentOrganizationPricingPlan(currentOrg?.organization_id));
    await dispatch(getAllMembers(currentOrg?.organization_id, page, pageSize, status));
    if (componentMounted.current) {
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => (componentMounted.current = false);
  }, []);

  useEffect(() => {
    loadPlanInfo();
  }, [currentOrg]);

  useEffect(() => {
    if (!currentOrg) {
      return;
    }
    dispatch(getAllMembers(currentOrg?.organization_id, page, pageSize, status));
    history.replace(`/organization/members/?page=${page}`);
  }, [page]);

  const handleRemove = member => {
    setSelectedMember(member);
    setIsRemoveModal(true);
  };

  const onRemove = () => {
    setIsRemoveModal(false);
    if (selectedMember.type === 'invite') {
      dispatch(deleteMemberInvite(currentOrg.organization_id, selectedMember.invite_id)).then(() => {
        dispatch(getCurrentOrganizationPricingPlan(currentOrg?.organization_id));
        dispatch(getAllMembers(currentOrg?.organization_id, page, pageSize, status));
      });
    } else {
      dispatch(changeUserStatus(currentOrg.organization_id, [selectedMember.user_id], 'inactive')).then(() => {
        dispatch(getCurrentOrganizationPricingPlan(currentOrg?.organization_id));
        dispatch(getAllMembers(currentOrg?.organization_id, page, pageSize, status));
      });
    }
  };

  const onInvite = async email => {
    await dispatch(inviteMember([{ email: email }])).then(response => {
      window.analytics.track('team_invite_sent', {
        category: 'Invite',
        userId: user.id,
        invite_id: response.data[0].id,
        email: email,
      });
      // if component still mounted
      if (componentMounted.current) {
        setLoading(false);
        dispatch(getCurrentOrganizationPricingPlan(currentOrg?.organization_id));
        dispatch(getCurrentOrganizationPricingPlan(currentOrg?.organization_id));
        dispatch(getAllMembers(currentOrg?.organization_id, page, pageSize, status));
      }
    });
  };

  const onChangeStatus = data => {
    dispatch(changeMemberRole(currentOrg?.organization_id, data)).then(() => {
      dispatch(me());
      dispatch(getCurrentOrganizationPricingPlan(currentOrg?.organization_id));
      dispatch(getAllMembers(currentOrg?.organization_id, page, pageSize, status));
    });
  };

  const isOnlyOneOwnerInOrg = useMemo(() => {
    if (!members) return false;
    const ownersCount = members.reduce((acc, member) => {
      if (member.role_in_organization === MEMBER_ROLE_IN_ORGANIZATION_TYPES.OWNER) return ++acc;
      return acc;
    }, 0);

    return ownersCount < 2;
  }, [members]);

  return (
    <>
      {loading || memberLoading || isGetMeLoading ? (
        <TeamMembersLoading role={currentOrg?.role?.toLowerCase()} />
      ) : (
        <div className={styles.wrapper}>
          {!currentOrg?.pricing_plan_id && !loading && !isFreePlan && <div className={styles.isBlur} />}
          <div className={cs(styles.inviteMemberWrapper, { [styles.isLandscape]: isHorizontal })}>
            <div className={styles.membersActions}>
              <ButtonLightPurpleWhite
                onClick={() => setIsInviteOpen(true)}
                presetIcon="plus2"
                designVersion="v2"
                disabled={disabledInvite}
                className={cs({ [styles.notVisible]: !isOwner })}
              >
                Invite
              </ButtonLightPurpleWhite>
              {isOwner && (
                <div
                  className={cs(styles.inviteWarning, {
                    [styles.visible]: isDisplayUpgrade,
                  })}
                >
                  <NavLink to="/upgrade-plan">Upgrade your account</NavLink> to invite more people to the team.
                </div>
              )}
            </div>
            <div className={cs(styles.membersCounter, { [styles.isLandscape]: isHorizontal })}>
              <IconUser />
              <div className={styles.title}>Members</div>
              <div className={styles.progressWrapper}>
                <PlanProgressBar current={+currentPlan?.usage?.team_members} of={+currentPlan?.team_members} />
              </div>
              <div className={styles.users}>
                {currentMembers}/{membersInPlan === -1 ? 'Unlimited' : membersInPlan}
              </div>
            </div>
          </div>
          <div>
            {members.length ? (
              <>
                {members.map((member, index) => {
                  // owner can't edit his status if there aren't any another owners;
                  const isCurrentMemberOwner = member.role_in_organization === MEMBER_ROLE_IN_ORGANIZATION_TYPES.OWNER;
                  return (
                    <TeamMemberCell
                      onChangeStatus={onChangeStatus}
                      canEditStatus={isOwner && !(isCurrentMemberOwner && isOnlyOneOwnerInOrg)}
                      member={member}
                      key={index}
                      org={currentOrg}
                      user={user}
                      handleRemove={handleRemove}
                    />
                  );
                })}
              </>
            ) : (
              <div className={styles.emptyContainer}>
                <div className={styles.empty}>
                  <MembersSVG />
                  <span>You have no team members yet</span>
                  <ButtonCustom onClick={() => setIsInviteOpen(true)} Icon={AddIcon} disabled={disabled}>
                    Invite Member
                  </ButtonCustom>
                </div>
              </div>
            )}
            {totalCount > pageSize && (
              <Pagination totalCount={totalCount} amount={pageSize} page={page} setPage={setPage} className={styles.pagination} />
            )}
          </div>
          <LoaderModal open={loadingUpdate} />
          {selectedMember && (
            <AgreeModal
              open={isRemoveModal}
              onClose={() => setIsRemoveModal(false)}
              onSubmit={onRemove}
              agreeText={'Yes, remove'}
              title={`Are you sure you want to remove ${
                selectedMember.first_name ? selectedMember.first_name : selectedMember.email
              } from the team?`}
            />
          )}
        </div>
      )}
      <InviteMemberModal open={isInviteOpen} onClose={() => setIsInviteOpen(false)} submitText={'Back to Team'} onSubmit={onInvite} />
    </>
  );
};

export default TeamMembers;
