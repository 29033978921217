import { COLLABORATOR_ROLE_TYPES } from '../collaborator/CollaboratorRole';

export enum FlowsTypes {
  DELIVERABLE_CC_BA = 'DELIVERABLE_CC_BA',
  DELIVERABLE_CC_TM_BA = 'DELIVERABLE_CC_TM_BA',
  DELIVERABLE_CC_TM = 'DELIVERABLE_CC_TM',
  SHARED_FLOW = 'SHARED_FLOW',
}

export interface IFlow<Permission> {
  type: FlowsTypes;
  permissions?: { [type in COLLABORATOR_ROLE_TYPES]?: Permission[] };
  generalPermissions?: Permission[];
  getPermissions(roleType?: COLLABORATOR_ROLE_TYPES): Permission[] | null;
}
