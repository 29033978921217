import {FlowsTypes, IFlow} from '../Flow';
import {COLLABORATOR_ROLE_TYPES} from '../../collaborator/CollaboratorRole';
import {ContentBoardPermission} from '../../enum/ContentBoardPermission';

export class DefaultBoardFlow implements IFlow<ContentBoardPermission> {
  type: FlowsTypes = FlowsTypes.DELIVERABLE_CC_TM_BA;
  permissions = {
    [COLLABORATOR_ROLE_TYPES.CONTENT_CREATOR]: [
      ContentBoardPermission.BOARD_DOCUMENTS_ONLY_VIEW_MODE,
      ContentBoardPermission.CAN_SEE_FILE_CARD_INFO_TOOLTIP,
      ContentBoardPermission.CAN_EDIT_RESULTS,
    ],
    [COLLABORATOR_ROLE_TYPES.TALENT_MANAGER]: [ContentBoardPermission.CAN_EDIT_RESULTS] as ContentBoardPermission[],
    [COLLABORATOR_ROLE_TYPES.BRAND]: [ContentBoardPermission.CAN_REQUEST_RESULTS] as ContentBoardPermission[],
    [COLLABORATOR_ROLE_TYPES.AGENCY]: [ContentBoardPermission.CAN_REQUEST_RESULTS] as ContentBoardPermission[],
  };

  generalPermissions: ContentBoardPermission[] = [
    ContentBoardPermission.CAN_SEE_HEADER_INFO_AND_ACTIONS,
    ContentBoardPermission.CAN_EDIT_BOARD_NAME,
    ContentBoardPermission.CAN_SEE_DELIVERABLE_ACTIONS,
    ContentBoardPermission.CAN_CREATE_NEW_DELIVERABLE,
    ContentBoardPermission.CAN_DOWNLOAD_ALL_DELIVERABLES_ASSETS,
  ];

  getPermissions(roleType: COLLABORATOR_ROLE_TYPES): ContentBoardPermission[] | null {
    switch (roleType) {
      case COLLABORATOR_ROLE_TYPES.CONTENT_CREATOR:
        return this.permissions[COLLABORATOR_ROLE_TYPES.CONTENT_CREATOR].concat(this.generalPermissions);
      case COLLABORATOR_ROLE_TYPES.TALENT_MANAGER:
        return this.permissions[COLLABORATOR_ROLE_TYPES.TALENT_MANAGER].concat(this.generalPermissions);
      case COLLABORATOR_ROLE_TYPES.BRAND:
        return this.permissions[COLLABORATOR_ROLE_TYPES.BRAND].concat(this.generalPermissions);
      case COLLABORATOR_ROLE_TYPES.AGENCY:
        return this.permissions[COLLABORATOR_ROLE_TYPES.AGENCY].concat(this.generalPermissions);
      default:
        return null;
    }
  }
}
