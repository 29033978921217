import React, { useMemo } from 'react';
import styles from './DeliverableCell.module.scss';
import DeliverablePlatform from './DeliverablePlatform';
import DeliverableName from './DeliverableName';
import useDeliverableStatusTile, {
  getDeliverableStatusesTooltips,
} from '../../../pages/ContentApproval/DeliverableItem/components/sections/components/SectionHeader/statuses/useDeliverableStatusTile';
import { getFormattedPlacement } from '../../../shared/utils/helpers';
import dayjs from 'dayjs';
import TextColorDueDate from '../../TextColorDueDate';
import { useTimeRelativeToNow } from '../../../shared/hooks/useTimeRelativeToNow';
import useTooltip from '../../../shared/hooks/fixedTooltip/useTooltip';
import { useMediaQuery } from 'react-responsive/src';
import ThreeDotsButton from '../../ThreeDotsButton';
import { ReactComponent as DeleteIcon } from '../../../assets/img/icons/trash.svg';
import { ReactComponent as DuplicateIcon } from '../../../assets/img/icons/duplicate-icon.svg';
import cs from 'classnames';
import { useSelector } from 'react-redux';
import AssetImagePlaceholder from './AssetImagePlaceholder';

const MIN_NAME_LENGTH_FOR_TOOLTIP = 25;

const DeliverableCell = ({ deliverable, onClick, isTablet, onDelete, onDuplicate, hideActions }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const pageModel = useSelector(state => state.contentBoard.pageModel);
  const collaborators = useSelector(state => state.contentBoard.collaborators);

  const { toolTip, onEnter, onLeave } = useTooltip('bottom', { text: deliverable.name, className: styles.tooltip });

  const liveDate = useMemo(() => {
    if (deliverable.live_datetime) {
      const date = dayjs(deliverable.live_datetime);
      return date.format(date.get('hour') === 0 && date.get('minute') === 0 ? 'DD MMM YYYY' : 'DD MMM YYYY hh:mm A');
    }
    return 'No date';
  }, [deliverable]);

  const { isUpcomingDate, isPastedDate } = useTimeRelativeToNow(liveDate);

  const { ToolTipTextSettingsConcept, ToolTipTextSettingsContent } = useMemo(() => {
    const deliverableStatusTooltips = getDeliverableStatusesTooltips(pageModel?.role?.type, collaborators);

    return {
      ToolTipTextSettingsConcept: { toolTip: deliverableStatusTooltips?.concept || null },
      ToolTipTextSettingsContent: { toolTip: deliverableStatusTooltips?.content || null },
    };
  }, [pageModel, collaborators]);

  const conceptStatusTile = useDeliverableStatusTile(deliverable.status_concept, ToolTipTextSettingsConcept);
  const contentStatusTile = useDeliverableStatusTile(deliverable.status_content, ToolTipTextSettingsContent);

  const isTooltip = deliverable.name.length > MIN_NAME_LENGTH_FOR_TOOLTIP;

  return isTablet ? (
    <div className={cs(styles.root, { [styles.hideLastColumn]: hideActions })} onClick={onClick}>
      <div className={styles.headerContainer}>
        {deliverable.preview_thumbnail ? (
          <img className={styles.image} src={deliverable.preview_thumbnail} alt={''} />
        ) : (
          <AssetImagePlaceholder asset={deliverable} />
        )}
        <DeliverableName name={deliverable.name} id={deliverable.id} />
        <div className={styles.threeDotsContainer}>
          {!hideActions && <ThreeDotsButtonComponent onDelete={onDelete} onDuplicate={onDuplicate} />}
        </div>
        <div className={styles.headerBottom}>
          <div className={styles.platformWrapper}>
            <div className={styles.platform}>
              <DeliverablePlatform platform={deliverable?.platform} isTablet={isTablet} className={styles.platform} />
            </div>
            <span className={styles.placement}>{getFormattedPlacement(deliverable.placement)}</span>
          </div>
          <p className={styles.dateWrapper}>
            <span className={styles.datePrevText}>Live Date:</span>
            <TextColorDueDate
              date={liveDate}
              contentStatus={deliverable.status_content}
              isPastedDate={isPastedDate}
              isUpcomingDate={isUpcomingDate}
            >
              {liveDate}
            </TextColorDueDate>
          </p>
        </div>
      </div>
      <div className={styles.bottomContent}>
        <div className={styles.buttons}>
          <div className={styles.left}>
            <div>Concept</div>
            <div className={styles.status}>{conceptStatusTile}</div>
          </div>

          <div className={styles.right}>
            <div>Content</div>
            <div className={styles.status}>{contentStatusTile}</div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={cs(styles.root, { [styles.hideLastColumn]: hideActions })} onClick={onClick}>
      <div className={styles.nameContainer}>
        {deliverable.preview_thumbnail ? (
          <img className={styles.image} src={deliverable.preview_thumbnail} alt={''} />
        ) : (
          <AssetImagePlaceholder asset={deliverable} />
        )}
        <div onMouseEnter={onEnter} onMouseLeave={onLeave} className={styles.tooltipWrapper}>
          {!isMobile && isTooltip && toolTip}
          <DeliverableName name={deliverable.name} id={deliverable.id} />
        </div>
      </div>
      <div>
        <DeliverablePlatform
          className={styles.cellText}
          platform={deliverable.platform}
          isTablet={isTablet}
          customText={getFormattedPlacement(deliverable.placement)}
        />
      </div>
      <div className={cs(styles.dateContainer, styles.cellText)}>
        <TextColorDueDate
          date={liveDate}
          contentStatus={deliverable.status_content}
          isPastedDate={isPastedDate}
          isUpcomingDate={isUpcomingDate}
        >
          {liveDate}
        </TextColorDueDate>
      </div>
      <div className={styles.status}>{conceptStatusTile}</div>
      <div className={styles.status}>{contentStatusTile}</div>
      {!hideActions && (
        <div className={styles.threeDotsContainer}>
          <ThreeDotsButtonComponent onDelete={onDelete} onDuplicate={onDuplicate} />
        </div>
      )}
    </div>
  );
};

const ThreeDotsButtonComponent = ({ onDelete, onDuplicate }) => {
  return (
    <ThreeDotsButton
      dropdownClassName={styles.threeDotsDropdown}
      options={[
        {
          text: 'Duplicate',
          icon: <DuplicateIcon />,
          className: styles.duplicate,
          onClick: (event, close) => {
            event.stopPropagation();
            onDuplicate();
            close();
          },
        },
        {
          text: 'Delete',
          icon: <DeleteIcon />,
          className: styles.delete,
          onClick: (event, close) => {
            event.stopPropagation();
            onDelete();
            close();
          },
        },
      ]}
    />
  );
};

export default DeliverableCell;
