import useValidateFileInputAccept from "./useValidateFileInputAccept";
import useHiddenFileInput from "./useHiddenFileInput";
import {ChangeEventHandler, useCallback} from "react";

export default function useMultipleFilesUpload(
  handleAction: (files: File[]) => void,
  allowedLength: number,
  inputSettings?: Parameters<typeof useHiddenFileInput>[1],
  onError?: (errorExtension: string) => void
) {

  const onCreate: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    const files = e.target.files;
    if (!files?.length) return;
    handleAction(Array.from(files));
  }, [handleAction, allowedLength]);

  const validateFileUpload = useValidateFileInputAccept(inputSettings?.accept, onCreate, onError);
  const {renderInput, clickFileInput: onFileUpload} = useHiddenFileInput(validateFileUpload, inputSettings);

  return {
    renderInput,
    onFileUpload,
  }
}
