import React, { FC, useMemo } from 'react';
import styles from './ContentApprovalEmptyHeader.module.scss';
import { ReactComponent as CheckmarkSquareSVG } from '../../../../../assets/img/icons/checkmarkSquare.svg';
import UserCommentsSection from '../../../components/UserCommentsSection';
import { USER_COMMENTS_CONFIG } from '../../../../../shared/constants/tsConstants';
import { ORGANIZATION_TYPES } from '../../../../../models/enums/OrganizationTypes';

type PropsType = {
  organizationType: ORGANIZATION_TYPES;
};

const USER_COMMENTS_CONFIG_WITH_CLASSNAMES = Object.entries(USER_COMMENTS_CONFIG).reduce((acc, [key, item]) => {
  return {
    ...acc,
    [key]: {
      ...item,
      imageClass: key === ORGANIZATION_TYPES.CONTENT_CREATOR ? styles.oldOtterImage : styles.otterImageWithPhone,
    },
  };
}, {});

const ContentApprovalEmptyHeader: FC<PropsType> = props => {
  const currentConfig = useMemo(() => USER_COMMENTS_CONFIG_WITH_CLASSNAMES[props.organizationType], []);

  return (
    <header className={styles.root}>
      <div className={styles.leftImageWrapper}>
        <img className={currentConfig?.imageClass} src={currentConfig?.image} alt="cool otterfish" />
      </div>
      <div className={styles.headingContainer}>
        <CheckmarkSquareSVG className={styles.checkMarkSvg} />
        <h1 className={styles.heading}>Content approval</h1>
      </div>
      <UserCommentsSection
        rootClassName={styles.userCommentsRoot}
        currentRate={currentConfig?.currentRate}
        rateOf={currentConfig?.rateOf}
        message={currentConfig?.message}
        name={currentConfig?.name}
        company={currentConfig?.company}
      />
    </header>
  );
};

export default ContentApprovalEmptyHeader;
