import { NotificationPreferencesDTOArrayType } from '../../../../models/entities/notificationPreferences/dto/NotificationPreferences';
import NotificationPreferencesHydrator from '../../../../models/entities/notificationPreferences/hydrators/NotificationPreferencesHydrator';
import NotificationPreferencesSettingHydrator from '../../../../models/entities/notificationPreferences/hydrators/NotificationPreferencesSettingHydrator';
import { NotificationPreferencesArrayType } from '../../../../models/entities/notificationPreferences/NotificationPreferences';
import { NOTIFICATION_PREFERENCES_GROUP_TYPE } from '../../../../models/entities/notificationPreferences/enum/NotificationPreferencesGroupType';

const normalizeRequestedNotificationPreferences = (dto: NotificationPreferencesDTOArrayType): NotificationPreferencesArrayType => {
  return dto.map(notificationPreferenceDTO => {
    if (notificationPreferenceDTO.group_type === NOTIFICATION_PREFERENCES_GROUP_TYPE.ACTIVITIES) {
      const notifications = notificationPreferenceDTO.notifications.map(
        notification => new NotificationPreferencesSettingHydrator(notification)
      );
      return new NotificationPreferencesHydrator(notificationPreferenceDTO, notifications);
    }
    if (notificationPreferenceDTO.group_type === NOTIFICATION_PREFERENCES_GROUP_TYPE.CHAT_NOTIFICATIONS) {
      const notifications = notificationPreferenceDTO.notifications.map(
        notification => new NotificationPreferencesSettingHydrator(notification)
      );
      return new NotificationPreferencesHydrator(notificationPreferenceDTO, notifications);
    }
    if (notificationPreferenceDTO.group_type === NOTIFICATION_PREFERENCES_GROUP_TYPE.DAILY_AND_WEEKLY_UPDATES) {
      const notifications = notificationPreferenceDTO.notifications.map(
        notification => new NotificationPreferencesSettingHydrator(notification)
      );
      return new NotificationPreferencesHydrator(notificationPreferenceDTO, notifications);
    }
    return notificationPreferenceDTO as never;
  });
};

export default normalizeRequestedNotificationPreferences;
