import React, { useMemo } from 'react';
import { useAppSelector } from '../../../../shared/hooks/reduxHooks';
import { PRICING_PLAN_PERIOD } from '../../../../models/entities/pricingPlans/enum/PricingPlanPeriod';
import cs from 'classnames';
import styles from './StorageCard.module.scss';
import CheckboxButton from '../../../../pages/CampaignBrief/components/CheckboxButton';
import { formatBytes } from '../../../../shared/utils/helpers';
import { IExtraPlanDTO } from '../index';

type StorageCardProps = {
  plan: IExtraPlanDTO;
  onSelect: () => void;
  isSelected: boolean;
};

const StorageCard = (props: StorageCardProps) => {
  const currentOrg = useAppSelector(state => state.auth.currentOrganization);

  const isMonthly = useMemo(() => {
    if (!currentOrg) return true;
    return currentOrg.pricing_plan_recurrence === PRICING_PLAN_PERIOD.MONTHLY;
  }, [currentOrg]);

  return (
    <div className={cs(styles.storageCard, { [styles.selected]: props.isSelected })} onClick={props.onSelect}>
      <div className={styles.checkboxContainer}>
        {/* @ts-ignore */}
        <CheckboxButton wrapperClassName={styles.checkboxWrapper} className={styles.checkbox} isActive={props.isSelected} />
        <div className={styles.storageCount}>{formatBytes(Number(props.plan.storage), 1)}</div>
      </div>
      <div className={styles.durationContainer}>
        <div className={styles.priceAmount}>${isMonthly ? props.plan.base_price_monthly : props.plan.base_price_yearly}</div>
        <div className={styles.duration}>USD/{isMonthly ? 'mo.' : 'yea.'}</div>
      </div>
    </div>
  );
};
export default StorageCard;
