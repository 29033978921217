import React, { useState } from 'react';
import styles from './CampaignBriefCollaborators.module.scss';
import CollaboratorTooltip from '../../../../components/Cells/ContentApprovalCell/CollaboratorsInfo/CollaboratorTooltip';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import DotsDropdownButton from '../../../../components/DotsDropdownButton';
import CollaboratorsDropdown from '../../../../components/CollaboratorsDropdown';
import InviteBoardCollaboratorsModal from '../../../../components/Modals/InviteCollaboratorsModal';
import DropdownCollaboratorCell from './components/DropdownCollaboratorCell';
import { inviteCollaborator, deleteInviteBriefCollaborator } from '../../../../actions/brief.actions';
import ConfirmDeleteInvitedCollaboratorsModal from '../../../../components/Modals/ConfirmDeleteInvitedCollaboratorsModal';
import * as briefServices from '../../../../services/brief';
import CollaboratorDropdownInvitedCell from '../../../../components/CollaboratorDropdownInvitedCell';
import { Modal } from '../../../../components/Modals';
import { useMediaQuery } from 'react-responsive/src';
import { TYPES_CAMPAIGNS } from '../../../../shared/constants/constants';

const allowed = [TYPES_CAMPAIGNS.CONTENT_CREATOR, TYPES_CAMPAIGNS.AGENCY, TYPES_CAMPAIGNS.BRAND, TYPES_CAMPAIGNS.TALENT_MANAGER];

const CampaignBriefCollaborators = ({ availableOrganizationDropdownOption, campaignID, currentBriefItem, disableInvitedCollaborators }) => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const currentOrganizationID = useSelector(state => state.auth.currentOrganization?.organization_id);
  const [isInitCollaboratorsLoading, setIsInitCollaboratorsLoading] = useState(true);
  const [collaborators, setCollaborators] = useState(currentBriefItem.collaborators);
  const [invitedCollaborators, setInvitedCollaborators] = useState([]);
  const [copiedLinkId, setCopiedLinkId] = useState(null);
  const userId = useSelector(state => state.auth.user.id);
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

  const onOpenAction = () => {
    if (isInitCollaboratorsLoading) {
      setIsInitCollaboratorsLoading(false);
      briefServices.getBriefOrganization(currentOrganizationID, campaignID, currentBriefItem.id).then(response => {
        setCollaborators(response.data);
      });

      if (!disableInvitedCollaborators) {
        briefServices.getBriefInvitedCollaborators(currentOrganizationID, campaignID, currentBriefItem.id).then(response => {
          setInvitedCollaborators(response.data);
        });
      }
    }
  };

  const [isOpenInviteModal, setIsOpenInviteModal] = useState(false);

  const withoutTooltip = true;
  const collaboratorsCount = collaborators.length + invitedCollaborators.length;
  const displayableIconsCount = 3;
  const [invitedCollaboratorIdToDelete, setInvitedCollaboratorIdToDelete] = useState(null);

  const handleInviteCollaborators = invites => {
    dispatch(
      inviteCollaborator(currentOrganizationID, match.params.campaignID, currentBriefItem, invites, () => {
        briefServices.getBriefInvitedCollaborators(currentOrganizationID, campaignID, currentBriefItem.id).then(response => {
          setInvitedCollaborators(response.data);
        });
      })
    );
  };

  const handleDeleteInvitedCollaborator = collaborator => {
    dispatch(
      deleteInviteBriefCollaborator(currentOrganizationID, match.params.campaignID, currentBriefItem.id, collaborator.id, () => {
        briefServices.getBriefInvitedCollaborators(currentOrganizationID, campaignID, currentBriefItem.id).then(response => {
          setInvitedCollaborators(response.data);
        });
      })
    );
  };

  const handleResendInvitedCollaborator = collaborator => {
    dispatch(inviteCollaborator(currentOrganizationID, match.params.campaignID, currentBriefItem, collaborator));
  };

  const getCollaboratorsIcons = () => {
    return collaborators.map((item, index) => {
      if (index <= displayableIconsCount - 1) {
        const letter = item.organization_name.slice(0, 1).toUpperCase();
        return (
          <div key={item.organization_id} className={styles.icon}>
            {letter}
            {!withoutTooltip && <CollaboratorTooltip collaborator={item} iconText={letter} className={styles.collaboratorTooltip} />}
          </div>
        );
      }
      return null;
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.icons}>{getCollaboratorsIcons()}</div>
      <DotsDropdownButton
        buttonClassName={styles.dotsButtonClassName}
        onOpenAction={onOpenAction}
        renderDropdown={(dropdownRef, closeDropdown) =>
          isTablet ? (
            <Modal borderRadius={'10px'} open={true} onClose={closeDropdown} className={styles.modalCollaborators}>
              <CollaboratorsDropdown
                forwardedRef={dropdownRef}
                collaborators={collaborators}
                invitedCollaborators={invitedCollaborators}
                counter={collaboratorsCount}
                onAddClick={() => {
                  setIsOpenInviteModal(true);
                  if (currentBriefItem.parent_id.toString() === '0') {
                    window.analytics.track('initiate_campaign_brief_invite', {
                      category: 'Invite',
                      label: currentBriefItem.name,
                      userId: userId,
                      campaign_brief_id: currentBriefItem.id,
                    });
                  } else {
                    window.analytics.track('initiate_creator_brief_invite', {
                      category: 'Invite',
                      label: currentBriefItem.name,
                      userId: userId,
                      creator_brief_id: currentBriefItem.id,
                    });
                  }
                  closeDropdown();
                }}
                disableAddButton={!availableOrganizationDropdownOption || collaboratorsCount >= 3}
                collaboratorsTableHeader={<DropdownCollaboratorCell tableHeader />}
                renderCollaboratorCell={collaborator => (
                  <DropdownCollaboratorCell key={collaborator.approver_id} collaborator={collaborator} />
                )}
                renderInvitedCollaboratorCell={collaborator => (
                  <CollaboratorDropdownInvitedCell
                    key={collaborator.id}
                    collaborator={collaborator}
                    copy={{
                      id: copiedLinkId,
                      setCopyId: setCopiedLinkId,
                    }}
                    onDelete={() => {
                      setInvitedCollaboratorIdToDelete(collaborator);
                      closeDropdown();
                    }}
                    onResend={() => handleResendInvitedCollaborator(collaborator)}
                  />
                )}
              />
            </Modal>
          ) : (
            <CollaboratorsDropdown
              forwardedRef={dropdownRef}
              collaborators={collaborators}
              invitedCollaborators={invitedCollaborators}
              counter={collaboratorsCount}
              onAddClick={() => {
                setIsOpenInviteModal(true);
                if (currentBriefItem.parent_id.toString() === '0') {
                  window.analytics.track('initiate_campaign_brief_invite', {
                    category: 'Invite',
                    label: currentBriefItem.name,
                    userId: userId,
                    campaign_brief_id: currentBriefItem.id,
                  });
                } else {
                  window.analytics.track('initiate_creator_brief_invite', {
                    category: 'Invite',
                    label: currentBriefItem.name,
                    userId: userId,
                    creator_brief_id: currentBriefItem.id,
                  });
                }
                closeDropdown();
              }}
              disableAddButton={!availableOrganizationDropdownOption || collaboratorsCount >= 3}
              collaboratorsTableHeader={<DropdownCollaboratorCell tableHeader />}
              renderCollaboratorCell={collaborator => (
                <DropdownCollaboratorCell key={collaborator.approver_id} collaborator={collaborator} />
              )}
              renderInvitedCollaboratorCell={collaborator => (
                <CollaboratorDropdownInvitedCell
                  key={collaborator.id}
                  collaborator={collaborator}
                  copy={{
                    id: copiedLinkId,
                    setCopyId: setCopiedLinkId,
                  }}
                  onDelete={() => {
                    setInvitedCollaboratorIdToDelete(collaborator);
                    closeDropdown();
                  }}
                  onResend={() => handleResendInvitedCollaborator(collaborator)}
                />
              )}
            />
          )
        }
      />
      <InviteBoardCollaboratorsModal
        allowedCollaborators={allowed}
        open={isOpenInviteModal}
        onClose={() => setIsOpenInviteModal(false)}
        onSubmit={values => handleInviteCollaborators(values)}
        availableOrganizationDropdownOption={availableOrganizationDropdownOption}
        collaborators={[...collaborators, ...invitedCollaborators]}
      />
      <ConfirmDeleteInvitedCollaboratorsModal
        open={!!invitedCollaboratorIdToDelete}
        onClose={() => setInvitedCollaboratorIdToDelete(null)}
        onSubmit={() => {
          handleDeleteInvitedCollaborator(invitedCollaboratorIdToDelete);
          setInvitedCollaboratorIdToDelete(null);
        }}
      />
    </div>
  );
};

export default CampaignBriefCollaborators;
