import { IViewBriefData } from '../models/entities/boardBrief/ViewBrief';
import { IBriefAsset } from '../models/entities/asset/BriefAsset';
import { BOARD_BRIEF_ACTION_TYPES, BoardBriefActionsType } from '../actions/BoardBriefActions/types';

export type BoardBriefReducerStateType = {
  boardBriefLoading: boolean;
  boardBriefError: string | null;
  boardBrief: IViewBriefData | null;

  boardBriefAssetsLoading: boolean;
  boardBriefAssetsError: string | null;
  boardBriefAssets: IBriefAsset[];

  boardBriefCreateLoading: boolean;
  boardBriefCreateError: string | null;

  boardBriefUpdateLoading: boolean;
  boardBriefUpdateError: string | null;
};

const initialState: BoardBriefReducerStateType = {
  boardBriefLoading: false,
  boardBriefError: null,
  boardBrief: null,

  boardBriefAssetsLoading: false,
  boardBriefAssetsError: null,
  boardBriefAssets: [],

  boardBriefCreateLoading: false,
  boardBriefCreateError: null,

  boardBriefUpdateLoading: false,
  boardBriefUpdateError: null,
};

const boardBriefReducer = (state = initialState, action: BoardBriefActionsType): BoardBriefReducerStateType => {
  switch (action.type) {
    case BOARD_BRIEF_ACTION_TYPES.GET_BOARD_BRIEF_REQUEST:
      return { ...state, boardBriefLoading: true };
    case BOARD_BRIEF_ACTION_TYPES.GET_BOARD_BRIEF_SUCCESS:
      return { ...state, boardBriefLoading: false, boardBrief: action.payload };
    case BOARD_BRIEF_ACTION_TYPES.GET_BOARD_BRIEF_FAILURE:
      return { ...state, boardBriefLoading: false, boardBriefError: action.payload };
    case BOARD_BRIEF_ACTION_TYPES.GET_BOARD_BRIEF_ASSETS_REQUEST:
      return { ...state, boardBriefAssetsLoading: true };
    case BOARD_BRIEF_ACTION_TYPES.GET_BOARD_BRIEF_ASSETS_SUCCESS:
      return { ...state, boardBriefAssetsLoading: false, boardBriefAssets: action.payload };
    case BOARD_BRIEF_ACTION_TYPES.GET_BOARD_BRIEF_ASSETS_FAILURE:
      return { ...state, boardBriefAssetsLoading: false };
    case BOARD_BRIEF_ACTION_TYPES.CREATE_BOARD_BRIEF_REQUEST:
      return { ...state, boardBriefCreateLoading: true };
    case BOARD_BRIEF_ACTION_TYPES.CREATE_BOARD_BRIEF_SUCCESS:
      return { ...state, boardBriefCreateLoading: false };
    case BOARD_BRIEF_ACTION_TYPES.CREATE_BOARD_BRIEF_FAILURE:
      return { ...state, boardBriefCreateLoading: false, boardBriefCreateError: action.payload };
    case BOARD_BRIEF_ACTION_TYPES.UPDATE_BOARD_BRIEF_REQUEST:
      return { ...state, boardBriefUpdateLoading: true };
    case BOARD_BRIEF_ACTION_TYPES.UPDATE_BOARD_BRIEF_SUCCESS:
      return { ...state, boardBriefUpdateLoading: false };
    case BOARD_BRIEF_ACTION_TYPES.UPDATE_BOARD_BRIEF_FAILURE:
      return { ...state, boardBriefUpdateLoading: false, boardBriefUpdateError: action.payload };
    case BOARD_BRIEF_ACTION_TYPES.CLEAR_BOARD_BRIEF_ERRORS:
      return { ...state, boardBriefError: null, boardBriefAssetsError: null, boardBriefCreateError: null, boardBriefUpdateError: null };
    case BOARD_BRIEF_ACTION_TYPES.CLEAR_BOARD_BRIEF_STATE:
      return { ...initialState };
    default:
      return state;
  }
};

export default boardBriefReducer;
