const useValidateFileInputAccept = (accept?: string, onSuccess?: any, onError?: any) => {
  return event => {
    if (accept) {
      const allowedExtensions: string[] = [];
      const allowedMimeTypes: string[] = [];

      accept.split(',').forEach(accept => {
        accept = accept.trim();
        if (accept.startsWith('.')) {
          allowedExtensions.push(accept.substring(1));
        } else if (accept.includes('/')) {
          allowedMimeTypes.push(accept.replace('*', ''));
        }
      });

      const files: any[] = Array.from(event.target.files);
      const invalidFile = files.find(
        file => !allowedExtensions.includes(file.name.split('.').pop()) && !allowedMimeTypes.some(type => file.type.includes(type))
      );

      if (invalidFile) {
        const fileExtension = invalidFile.name.split('.').pop();
        onError && onError(fileExtension);
        return;
      }
    }
    onSuccess(event);
  };
};

export default useValidateFileInputAccept;
