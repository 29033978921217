import React, { FC, MouseEvent, MouseEventHandler, useMemo, useRef, useState } from 'react';
import styles from './TeamMemberStatus.module.scss';
import cs from 'classnames';
import { ReactComponent as MemberStatusSVG } from '../../../assets/img/icons/userStatus.svg';
import { ReactComponent as CheckedSVG } from '../../../assets/img/icons/checked_ppr.svg';
import { useClickOutside } from '../../../shared/hooks/useClickOutside';
import { MEMBER_ROLE_IN_ORGANIZATION_TYPES } from '../../../models/enums/MemberRoleInOrganizationTypes';

enum MEMBER_STATUS_TYPES {
  ACTIVE = 'active',
  PENDING = 'pending',
}

export enum MEMBERS_STATUS_VIEW_TYPES {
  ADMIN = 'admin',
  MEMBER = 'member',
  PENDING = 'pending',
}

type PropsType = {
  role_in_organization: MEMBER_ROLE_IN_ORGANIZATION_TYPES;
  status: MEMBER_STATUS_TYPES;
  canEditStatus: boolean;
  onSelect: (status: MEMBER_ROLE_IN_ORGANIZATION_TYPES) => void;
};

const membersStatusConfig = {
  [MEMBERS_STATUS_VIEW_TYPES.ADMIN]: { title: 'Admin', text: 'All the members privileges, plus the ability to manage users' },
  [MEMBERS_STATUS_VIEW_TYPES.MEMBER]: { title: 'Member', text: 'Members can view and manage campaigns and boards in this organization' },
  [MEMBERS_STATUS_VIEW_TYPES.PENDING]: { title: 'Pending', text: '' },
};

const TeamMemberStatus: FC<PropsType> = props => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(wrapperRef, null, () => setIsOpen(false));

  const memberCurrentStatus: MEMBERS_STATUS_VIEW_TYPES = useMemo(() => {
    if (props.status === MEMBER_STATUS_TYPES.PENDING) {
      return MEMBERS_STATUS_VIEW_TYPES.PENDING;
    }

    switch (props.role_in_organization) {
      case MEMBER_ROLE_IN_ORGANIZATION_TYPES.OWNER:
        return MEMBERS_STATUS_VIEW_TYPES.ADMIN;
      case MEMBER_ROLE_IN_ORGANIZATION_TYPES.MEMBER:
        return MEMBERS_STATUS_VIEW_TYPES.MEMBER;
      default: {
        const exhaustiveCheck: never = props.role_in_organization;

        return exhaustiveCheck;
      }
    }
  }, [props.status, props.role_in_organization]);

  const memberTitle = useMemo(() => membersStatusConfig[memberCurrentStatus].title, [memberCurrentStatus]);

  const canEditStatus = useMemo(() => {
    return props.status !== MEMBER_STATUS_TYPES.PENDING && props.canEditStatus;
  }, [props.status]);

  const getValueToSelect = (viewType: MEMBERS_STATUS_VIEW_TYPES.MEMBER | MEMBERS_STATUS_VIEW_TYPES.ADMIN) => {
    switch (viewType) {
      case MEMBERS_STATUS_VIEW_TYPES.ADMIN:
        return MEMBER_ROLE_IN_ORGANIZATION_TYPES.OWNER;
      case MEMBERS_STATUS_VIEW_TYPES.MEMBER:
        return MEMBER_ROLE_IN_ORGANIZATION_TYPES.MEMBER;
      default: {
        const exhaustiveCheck: never = viewType;

        return exhaustiveCheck;
      }
    }
  };

  const onStatusClick: MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();
    setIsOpen(open => !open);
  };

  const onSelectRole = (e: MouseEvent<HTMLDivElement>, status: Parameters<typeof getValueToSelect>[0]) => {
    e.stopPropagation();
    if (!canEditStatus) return;
    props.onSelect(getValueToSelect(status));
  };

  return (
    <div
      aria-label={'button'}
      ref={wrapperRef}
      className={cs(styles.root, styles[memberCurrentStatus], {
        [styles.center]: !canEditStatus,
        [styles.opened]: isOpen && canEditStatus,
      })}
      onClick={onStatusClick}
    >
      <span className={styles.statusText}>{memberTitle}</span>
      {canEditStatus ? (
        <>
          <div className={styles.arrow} />
          <div className={cs(styles.dropDown, { [styles.activeDropDown]: isOpen })}>
            {(Object.values(MEMBERS_STATUS_VIEW_TYPES) as MEMBERS_STATUS_VIEW_TYPES[]).map(status => {
              if (!status || status === MEMBERS_STATUS_VIEW_TYPES.PENDING) return null;
              return (
                <div
                  key={status}
                  onClick={e => onSelectRole(e, status)}
                  className={cs(styles.dropDownSection, styles[status], { [styles.selected]: status === memberCurrentStatus })}
                >
                  <div className={styles.iconWrapper}>
                    <MemberStatusSVG className={styles.userIcon} />
                  </div>
                  <h5 className={styles.title}>{membersStatusConfig[status].title}</h5>
                  {memberCurrentStatus === status ? (
                    <div className={styles.iconWrapper}>
                      <CheckedSVG className={styles.checkMark} />
                    </div>
                  ) : null}
                  <p className={styles.text}>{membersStatusConfig[status].text}</p>
                </div>
              );
            })}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default TeamMemberStatus;
