import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../store';
import { IBoardListItem } from '../../models/entities/board/BoardListItem';

export enum CONTENT_APPROVAL_ACTION_TYPES {
  GET_CONTENT_BOARDS_REQUEST = 'GET_CONTENT_BOARDS_REQUEST',
  GET_CONTENT_BOARDS_SUCCESS = 'GET_CONTENT_BOARDS_SUCCESS',
  GET_CONTENT_BOARDS_FAILURE = 'GET_CONTENT_BOARDS_FAILURE',

  CREATE_BOARD_REQUEST = 'CREATE_BOARD_REQUEST',
  CREATE_BOARD_SUCCESS = 'CREATE_BOARD_SUCCESS',
  CREATE_BOARD_FAILURE = 'CREATE_BOARD_FAILURE',

  DUPLICATE_BOARD_REQUEST = 'DUPLICATE_BOARD_REQUEST',
  DUPLICATE_BOARD_SUCCESS = 'DUPLICATE_BOARD_SUCCESS',
  DUPLICATE_BOARD_FAILURE = 'DUPLICATE_BOARD_FAILURE',

  DELETE_BOARD_REQUEST = 'DELETE_BOARD_REQUEST',
  DELETE_BOARD_SUCCESS = 'DELETE_BOARD_SUCCESS',
  DELETE_BOARD_FAILURE = 'DELETE_BOARD_FAILURE',

  SET_IS_ALL_BOARD_TYPES_EMPTY = 'SET_IS_ALL_BOARD_TYPES_EMPTY',
  UPDATE_CONTENT_APPROVAL_LIST_BOARD = 'UPDATE_CONTENT_APPROVAL_LIST_BOARD',
  SET_CONTENT_APPROVAL_TAB_SWITCHER = 'SET_CONTENT_APPROVAL_TAB_SWITCHER',
}

type GetContentBoardsRequest = Action<CONTENT_APPROVAL_ACTION_TYPES.GET_CONTENT_BOARDS_REQUEST> & { loading?: boolean };
type GetContentBoardsSuccess = Action<CONTENT_APPROVAL_ACTION_TYPES.GET_CONTENT_BOARDS_SUCCESS> & {
  payload: IBoardListItem[];
  totalCount: number;
  archivedTotalCount: number;
};
type GetContentBoardsFailure = Action<CONTENT_APPROVAL_ACTION_TYPES.GET_CONTENT_BOARDS_FAILURE>;

type CreateBoardsRequestActionType = Action<CONTENT_APPROVAL_ACTION_TYPES.CREATE_BOARD_REQUEST>;
type CreateBoardsSuccessActionType = Action<CONTENT_APPROVAL_ACTION_TYPES.CREATE_BOARD_SUCCESS>;
type CreateBoardsFailureActionType = Action<CONTENT_APPROVAL_ACTION_TYPES.CREATE_BOARD_FAILURE>;

type DuplicateBoardsRequestActionType = Action<CONTENT_APPROVAL_ACTION_TYPES.DUPLICATE_BOARD_REQUEST>;
type DuplicateBoardsSuccessActionType = Action<CONTENT_APPROVAL_ACTION_TYPES.DUPLICATE_BOARD_SUCCESS> & {
  payload: {
    afterId: string | number;
    board: IBoardListItem;
  };
};
type DuplicateBoardsFailureActionType = Action<CONTENT_APPROVAL_ACTION_TYPES.DUPLICATE_BOARD_FAILURE>;

type DeleteBoardsRequestActionType = Action<CONTENT_APPROVAL_ACTION_TYPES.DELETE_BOARD_REQUEST>;
type DeleteBoardsSuccessActionType = Action<CONTENT_APPROVAL_ACTION_TYPES.DELETE_BOARD_SUCCESS>;
type DeleteBoardsFailureActionType = Action<CONTENT_APPROVAL_ACTION_TYPES.DELETE_BOARD_FAILURE>;

type SetIsAllBoardTypesEmptyActionType = Action<CONTENT_APPROVAL_ACTION_TYPES.SET_IS_ALL_BOARD_TYPES_EMPTY> & { payload: boolean };
type UpdateContentApprovalListBoardActionType = Action<CONTENT_APPROVAL_ACTION_TYPES.UPDATE_CONTENT_APPROVAL_LIST_BOARD> & {
  payload: IBoardListItem[];
};
type SetContentApprovalTabSwitcherActionType = Action<CONTENT_APPROVAL_ACTION_TYPES.SET_CONTENT_APPROVAL_TAB_SWITCHER> & {
  payload: 'boards' | 'archived';
};

export type ContentApprovalActionTypes =
  | GetContentBoardsRequest
  | GetContentBoardsSuccess
  | GetContentBoardsFailure
  | CreateBoardsRequestActionType
  | CreateBoardsSuccessActionType
  | CreateBoardsFailureActionType
  | DuplicateBoardsRequestActionType
  | DuplicateBoardsSuccessActionType
  | DuplicateBoardsFailureActionType
  | DeleteBoardsRequestActionType
  | DeleteBoardsSuccessActionType
  | DeleteBoardsFailureActionType
  | SetIsAllBoardTypesEmptyActionType
  | UpdateContentApprovalListBoardActionType
  | SetContentApprovalTabSwitcherActionType;

export type ContentApprovalThunkReturnType<ReturnType = void> = ThunkAction<
  Promise<ReturnType>,
  RootState,
  null,
  ContentApprovalActionTypes
>;

export type ContentApprovalSyncThunkReturnType<ReturnType = void> = ThunkAction<ReturnType, RootState, null, ContentApprovalActionTypes>;
