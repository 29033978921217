import React, { CSSProperties, FC, ReactElement, ReactNode } from 'react';

type PropsType = {
  height?: CSSProperties['height'];
  width?: CSSProperties['width'];
  maxWidth?: CSSProperties['maxWidth'];
  rowGap?: CSSProperties['rowGap'];
  columnGap?: CSSProperties['columnGap'];
  justifyContent?: CSSProperties['justifyContent'];
  flexDirection?: CSSProperties['flexDirection'];
  alignItems?: CSSProperties['alignItems'];
  background?: CSSProperties['background'];
  margin?: CSSProperties['margin'];
  children?: JSX.Element | ReactElement | ReactNode | string | null;
  borderRadius?: CSSProperties['borderRadius'];
};

const LoadingContainerWrapper: FC<PropsType> = ({
  height,
  width,
  maxWidth,
  rowGap,
  columnGap,
  justifyContent,
  flexDirection,
  alignItems,
  background,
  margin,
  children,
  borderRadius,
}) => (
  <div
    style={{
      display: 'flex',
      height: height || '100%',
      width: width || '100%',
      rowGap: rowGap || '0',
      columnGap: columnGap || '0',
      maxWidth: maxWidth || 'auto',
      justifyContent: justifyContent || 'center',
      alignItems: alignItems || 'center',
      flexDirection: flexDirection || 'row',
      background: background || 'transparent',
      borderRadius: borderRadius || '5px',
      margin: margin || '0',
    }}
  >
    {children}
  </div>
);

export default LoadingContainerWrapper;
