import React, { ReactElement, useMemo, useState } from 'react';
import styles from './CreateBoardBrief.module.scss';
import MainLayout from '../../../../../components/MainLayout';
import VisualElementSVG from '../../../../../components/IconsSvg/VisualElementSVG';
import DocumentSVG from '../../../../../expert/components/IconsSvg/DocumentSVG';
import FormTab from '../../components/FormTab';
import BriefPageContentWrapper from '../../../../CampaignBrief/components/BriefPageContentWrapper';
import TabBrandDetails from './tabs/TabBrandDetails';
import TabVisualAssets from './tabs/TabVisualAssets';
import TabBriefDetails from './tabs/TabBriefDetails';
import TabMainName from '../../tabs/TabMainName';
import BriefSubHeader from './components/BriefSubHeader';
import { ReactComponent as StarIcon } from '../../../../../assets/img/icons/purple-star-2.svg';
import { ReactComponent as HornIcon } from '../../../../../assets/img/icons/purple-horn-icon.svg';
import ConfirmationModal from '../../../../../components/Modals/ConfirmationModal';
import { isEqual } from 'lodash';
import FormLoadingState from './components/FormLoadingState';
import ContentApprovalHeaderBreadcrumbs from '../../../components/ContentApprovalHeaderBreadcrumbs';
import { FormikProps } from 'formik';
import { useAppSelector } from '../../../../../shared/hooks/reduxHooks';
import cs from 'classnames';
import { ContentApprovalBreadcrumbsPageType } from '../../../../../models/entities/breadcrumbs/enums/ContentApprovalBreadcrumbsPage';

type PropsType = {
  formik: FormikProps<any>;
  visualAssets: any;
  editedState?: any;
  onCancel: (...args: any) => any;
  onSubmit: (...args: any) => any;
  title?: string | ReactElement;
  breadCrumbsPageKey?: ContentApprovalBreadcrumbsPageType;
  description?: string;
  buttonTitle?: string;
  isSubmitDisabled?: boolean;
  isLoading?: boolean;
  withConfirmCancel?: boolean;
};

function CreateBoardBrief({
  formik,
  visualAssets,
  editedState,
  onCancel,
  onSubmit,
  title,
  breadCrumbsPageKey,
  description,
  buttonTitle,
  isSubmitDisabled = false,
  isLoading = false,
  withConfirmCancel = false,
}: PropsType) {
  const currentOrganizationID = useAppSelector(state => state.auth.currentOrganization?.organization_id);
  const [openConfirmCancel, setOpenConfirmCancel] = useState(false);

  const tabsData = useMemo(
    () => [
      {
        title: 'Brief',
        children: (
          <TabMainName className={styles.briefNameInput} formik={formik} placeholder="Brief name" title="Brief Name" field="itemName" />
        ),
        icon: <DocumentSVG className={styles.documentSVG} />,
      },
      {
        title: 'Brand background',
        children: <TabBrandDetails formik={formik}></TabBrandDetails>,
        icon: <StarIcon />,
      },
      {
        title: 'Campaign details',
        children: <TabBriefDetails formik={formik} />,
        icon: <HornIcon />,
        optionalText: 'All fields are optional',
      },
      {
        title: 'Visual Direction',
        children: <TabVisualAssets visualAssets={visualAssets} />,
        icon: <VisualElementSVG className={styles.visualElementSVG} />,
        optionalText: 'Optional',
      },
    ],
    [formik]
  );

  const cancelHandler = () => {
    if (!withConfirmCancel) {
      onCancel();
      return;
    }
    const initialState = editedState || formik.initialValues;
    const currentState = formik.values;
    const isEqualStates = isEqual(initialState, currentState);
    if (!isEqualStates) {
      setOpenConfirmCancel(true);
    } else {
      onCancel();
    }
  };

  return (
    <form onKeyDown={e => e.keyCode === 13 && e.preventDefault()}>
      {/* @ts-ignore */}
      <MainLayout
        contentClassName={cs(styles.root, { [styles.withBreadCrumbs]: !!breadCrumbsPageKey })}
        title={breadCrumbsPageKey && <ContentApprovalHeaderBreadcrumbs page={breadCrumbsPageKey} />}
      >
        {breadCrumbsPageKey && <ContentApprovalHeaderBreadcrumbs asMobile page={breadCrumbsPageKey} />}
        <BriefSubHeader
          title={title}
          description={description}
          buttonTitle={buttonTitle}
          onCancel={cancelHandler}
          onSubmit={onSubmit}
          errors={formik.errors}
          canSubmit={currentOrganizationID && formik.dirty && formik.isValid && !formik.isSubmitting && !isSubmitDisabled && !isLoading}
        />
        <BriefPageContentWrapper>
          {isLoading ? (
            <FormLoadingState />
          ) : (
            <>
              {tabsData.map(item => (
                <div key={item.title}>
                  <FormTab {...item} />
                </div>
              ))}
            </>
          )}
        </BriefPageContentWrapper>
      </MainLayout>
      <ConfirmationModal
        open={openConfirmCancel}
        onClose={() => setOpenConfirmCancel(false)}
        iconPreset="warning"
        config={{
          text: (
            <>
              You are about to move away from the brief. <br /> All your progress will be lost. Are you Sure?
            </>
          ),
          cancelButton: {
            text: 'Discard',
            action: () => {
              setOpenConfirmCancel(false);
            },
          },
          submitButton: {
            text: 'Yes, leave',
            action: () => onCancel(),
          },
        }}
      />
    </form>
  );
}

export default CreateBoardBrief;
