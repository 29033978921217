import React, { ComponentProps, FC } from 'react';
import ButtonWithPurpleRoundedIcon from '../../../../../../../components/Buttons/ButtonWithPurpleRoundedIcon';
import styles from './AddDocumentButton.module.scss';
import { useMediaQuery } from 'react-responsive';

type PropsType = Omit<ComponentProps<typeof ButtonWithPurpleRoundedIcon>, 'className' | 'size'>;
const AddDocumentButton: FC<PropsType> = props => {
  const isTablet: boolean = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <ButtonWithPurpleRoundedIcon {...props} className={styles.button} size={isTablet ? 'medium' : 'large'}>
      {props.children}
    </ButtonWithPurpleRoundedIcon>
  );
};

export default AddDocumentButton;
