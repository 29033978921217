import React, { FC, useCallback, useState } from 'react';
import BriefMediaCard from '../../components/BriefMediaCard';
import { IBriefAsset } from '../../../../../../../models/entities/asset/BriefAsset';
import Visual from '../../components/Visual';
import VisualDescriptionModal from '../../components/VisualDescriptionModal';
import VisualsCardEmptyState from './components/VisualsCardEmptyState';
import VisualsCardLoadingState from './components/VisualsCardLoadingState';

type PropsType = {
  isLoading: boolean;
  assets: IBriefAsset[];
};
const VisualsCard: FC<PropsType> = props => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [openAsset, setOpenAsset] = useState<IBriefAsset | null>(null);
  const onCloseModal = useCallback(() => setIsOpenModal(false), []);

  const onHoverClick = useCallback((asset: IBriefAsset) => {
    setOpenAsset(asset);
    setIsOpenModal(true);
  }, []);

  return (
    <>
      <BriefMediaCard visualsLength={props.assets.length} headerTitle={'Visual Directions'}>
        {props.isLoading ? <VisualsCardLoadingState /> : null}
        {!props.isLoading && props.assets.length
          ? props.assets.map(asset => <Visual key={asset.url} asset={asset} onHoverClick={onHoverClick} />)
          : null}
        {!props.isLoading && !props.assets.length ? <VisualsCardEmptyState /> : null}
      </BriefMediaCard>
      <VisualDescriptionModal isOpen={isOpenModal} openAsset={openAsset} onClose={onCloseModal} />
    </>
  );
};

export default VisualsCard;
