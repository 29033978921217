import React, { FC, memo } from 'react';
import TagBlock from '../../../../../../../CampaignBrief/components/TagBlock';
import styles from './SubTabHashtags.module.scss';
import { useMediaQuery } from 'react-responsive/src';
import { FormikProps } from 'formik';
import { areEqualFormikState } from '../../../../../../../../shared/utils/typescriptHelpers';
import useFormikDebouncedState from '../../../../../../../../shared/hooks/useFormikDebouncedState';

type PropsType = {
  formik: FormikProps<any>;
};

const propsAreEqual: Parameters<typeof memo<FC<PropsType>>>[1] = (prevProps, nextProps) => {
  return areEqualFormikState(['hashtags', 'socialTagging'], prevProps.formik, nextProps.formik);
};

const SubTabHashtags = memo(({ formik }: PropsType) => {
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  const [hashtags, setHashtags] = useFormikDebouncedState<string[]>(
    (value: string[]) => formik.setFieldValue('hashtags', value),
    formik.values.hashtags
  );

  const [socialTagging, setSocialTagging] = useFormikDebouncedState<string[]>(
    (value: string[]) => formik.setFieldValue('socialTagging', value),
    formik.values.socialTagging
  );

  const addHashtagsHandler = value => {
    if (hashtags.indexOf('#' + value) === -1) {
      if (value.charAt(0) === '#') {
        setHashtags([...formik.values.hashtags, value]);
      } else {
        setHashtags([...formik.values.hashtags, '#' + value]);
      }
    }
  };

  const addSocialTaggingHandler = value => {
    if (socialTagging.indexOf('@' + value) === -1) {
      if (value.charAt(0) === '@') {
        setSocialTagging([...formik.values.socialTagging, value]);
      } else {
        setSocialTagging([...formik.values.socialTagging, '@' + value]);
      }
    }
  };

  const removeTagHandler = value => {
    switch (value.charAt(0)) {
      case '#':
        setHashtags(formik.values.hashtags.filter(tag => value !== tag));
        break;
      case '@':
        setSocialTagging(formik.values.socialTagging.filter(tag => value !== tag));
        break;
      default:
        formik.setFieldValue(
          'callToActions',
          formik.values.callToActions.filter(tag => value !== tag)
        );
        break;
    }
  };

  return (
    <div>
      <div className={styles.root}>
        <div>
          <h3 className={styles.title}>Tags</h3>
        </div>

        <TagBlock
          title={'Hashtags'}
          tags={hashtags}
          addTag={addHashtagsHandler}
          removeTag={removeTagHandler}
          placeholder={!isMobile ? 'Please provide the hashtags you want to be featured with your content.' : 'Please provide the hashtags'}
          className={styles.tagBlock}
          disableSpace
          isDisplayButtonAdd
        />
        <TagBlock
          title={'Social handles'}
          tags={socialTagging}
          addTag={addSocialTaggingHandler}
          removeTag={removeTagHandler}
          placeholder={
            !isMobile ? 'Please provide the social handles you want to be featured with your content.' : 'Please provide the social handles'
          }
          className={styles.tagBlock}
          disableSpace
          isDisplayButtonAdd
        />
      </div>
    </div>
  );
}, propsAreEqual);

export default SubTabHashtags;
