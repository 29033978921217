export enum DeliverablePermission {
  DISPLAY_SECTION_EMPTY_STATE = 'DISPLAY_SECTION_EMPTY_STATE',
  ONLY_BA_CAN_VIEW = 'ONLY_BA_CAN_VIEW',
  ONLY_CC_CAN_VIEW = 'ONLY_CC_CAN_VIEW',
  CAN_REMOVE_SECTION = 'CAN_REMOVE_SECTION',
  DISABLED_SHARE_BUTTON = 'DISABLED_SHARE_BUTTON',
  CONCEPT_IN_PROGRESS_STATUS_TOOLTIP = 'CONCEPT_IN_PROGRESS_STATUS_TOOLTIP',
  CONCEPT_PENDING_APPROVAL_STATUS_TOOLTIP_CC = 'CONCEPT_PENDING_APPROVAL_STATUS_TOOLTIP_CC',
  CONCEPT_PENDING_APPROVAL_STATUS_TOOLTIP_BA = 'CONCEPT_PENDING_APPROVAL_STATUS_TOOLTIP_BA',
  CONTENT_APPROVE_BUTTON_TOOLTIP_BA = 'CONTENT_APPROVE_BUTTON_TOOLTIP_BA',
  CAN_APPROVE_SECTION = 'CAN_APPROVE_SECTION',
  CAN_SHARE_SECTION = 'CAN_SHARE_SECTION',
  SHARE_AS_APPROVE = 'SHARE_AS_APPROVE',
  GIVE_FEEDBACK_FIRST_CONTENT_CREATOR = 'GIVE_FEEDBACK_FIRST_CONTENT_CREATOR',
  CAN_SEE_FOOTER_UPDATER_INFO = 'CAN_SEE_FOOTER_UPDATER_INFO',
  DISPLAY_GIVE_FEEDBACK = 'DISPLAY_GIVE_FEEDBACK',
  DISPLAY_ADD_SECTION_BUTTON = 'DISPLAY_ADD_SECTION_BUTTON',
  DISPLAY_ACTION_BUTTONS = 'DISPLAY_ACTION_BUTTONS',
  AUTO_SHARE_SAVED_DELIVERABLE_SECTION = 'AUTO_SHARE_SAVED_DELIVERABLE_SECTION',
  CAN_EDIT_LIVE_DUE_DATE = 'CAN_EDIT_LIVE_DUE_DATE',
  CAN_EDIT_REMINDER_DATE = 'CAN_EDIT_REMINDER_DATE',
  CAN_SEE_SECTION_FOOTER = 'CAN_SEE_SECTION_FOOTER',
  CAN_SEE_VISUAL_CARD_POPOVER = 'CAN_SEE_VISUAL_CARD_POPOVER',
  CAN_EDIT_DELIVERABLE_NAME = 'CAN_EDIT_DELIVERABLE_NAME',
  CAN_DOWNLOAD_ASSETS = 'CAN_DOWNLOAD_ASSETS',
}
