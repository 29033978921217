import CustomText from '../../../CustomText';
import React from 'react';
import { getSectionEditNotificationTextEnd } from './functions';

export const NOTIFICATIONS_TYPES = {
  DISCUSSION_ROOM_CREATE: 'discussion_room_create',
  CONTENT_BOARD_INVITE: 'content_board_invite',
  CAMPAIGN_BRIEF_ITEM_INVITE: 'campaign_brief_item_invite',
  CONTENT_BOARD_CREATE_FROM_CREATOR_BRIEF: 'content_board_create_from_creator_brief',
  CONTENT_BOARD_DELIVERABLE_UPDATE_NAME: 'content_board_deliverable_update_name',
  CONTENT_BOARD_CHANGE_APPROVER: 'content_board_change_approver',
  CONTENT_BOARD_CREATE_DELIVERABLES: 'content_board_create_deliverables',
  CONTENT_BOARD_DELIVERABLE_VERSION_CONCEPT_EDIT: 'content_board_deliverable_version_concept_edit',
  CONTENT_BOARD_DELIVERABLE_VERSION_CONTENT_EDIT: 'content_board_deliverable_version_content_edit',
  CONTENT_BOARD_DELIVERABLE_VERSION_CONCEPT_SHARE: 'content_board_deliverable_version_concept_share',
  CONTENT_BOARD_DELIVERABLE_VERSION_CONTENT_SHARE: 'content_board_deliverable_version_content_share',
  CONTENT_BOARD_DELIVERABLE_VERSION_CONCEPT_APPROVE: 'content_board_deliverable_version_concept_approve',
  CONTENT_BOARD_DELIVERABLE_VERSION_CONTENT_APPROVE: 'content_board_deliverable_version_content_approve',
  CONTENT_BOARD_DELIVERABLE_UPDATE_LIVE_DATETIME: 'content_board_deliverable_update_live_datetime',
  CONTENT_BOARD_DELIVERABLE_CONCEPT_REMINDER: 'content_board_deliverable_concept_reminder',
  CONTENT_BOARD_DELIVERABLE_CONTENT_REMINDER: 'content_board_deliverable_content_reminder',
  CONTENT_BOARD_DELIVERABLE_REMIND_TODAY_CONCEPT_DUE_DATETIME: 'content_board_deliverable_remind_today_concept_due_datetime',
  CONTENT_BOARD_DELIVERABLE_REMIND_1DAY_BEFORE_CONCEPT_DUE_DATETIME: 'content_board_deliverable_remind_1day_before_concept_due_datetime',
  CONTENT_BOARD_DELIVERABLE_REMIND_2DAYS_BEFORE_CONCEPT_DUE_DATETIME: 'content_board_deliverable_remind_2days_before_concept_due_datetime',
  CONTENT_BOARD_DELIVERABLE_REMIND_TODAY_CONTENT_DUE_DATETIME: 'content_board_deliverable_remind_today_content_due_datetime',
  CONTENT_BOARD_DELIVERABLE_REMIND_1DAY_BEFORE_CONTENT_DUE_DATETIME: 'content_board_deliverable_remind_1day_before_content_due_datetime',
  CONTENT_BOARD_DELIVERABLE_REMIND_2DAYS_BEFORE_CONTENT_DUE_DATETIME: 'content_board_deliverable_remind_2days_before_content_due_datetime',
  CONTENT_BOARD_DELIVERABLE_REMIND_TODAY_LIVE_DATETIME: 'content_board_deliverable_remind_today_live_datetime',
  CONTENT_BOARD_DELIVERABLE_REMIND_1DAY_BEFORE_LIVE_DATETIME: 'content_board_deliverable_remind_1day_before_live_datetime',
  CONTENT_BOARD_DELIVERABLE_REMIND_2DAYS_BEFORE_LIVE_DATETIME: 'content_board_deliverable_remind_2days_before_live_datetime',
};

export const NOTIFICATIONS_TEXTS = {
  [NOTIFICATIONS_TYPES.DISCUSSION_ROOM_CREATE]: ({ data, settings }) => (
    <CustomText
      render={Text => (
        <>
          <Text.Bold>{data?.senderUser?.first_name}</Text.Bold> has created a new discussion room{' '}
          <Text.Bold presetColor="purple">"{data?.discussionRoom?.name}"</Text.Bold> to chat with you.
        </>
      )}
    />
  ),
  [NOTIFICATIONS_TYPES.CONTENT_BOARD_INVITE]: ({ data, settings }) => (
    <CustomText
      render={Text => (
        <>
          <Text.Bold>
            {data?.senderUser?.first_name}({data?.senderOrganization?.name})
          </Text.Bold>{' '}
          has invited you to collaborate on the Content Board: <Text.Bold presetColor="purple">"{data?.contentBoard?.name}"</Text.Bold>
        </>
      )}
    />
  ),
  [NOTIFICATIONS_TYPES.CAMPAIGN_BRIEF_ITEM_INVITE]: ({ data, settings }) => (
    <CustomText
      render={Text => (
        <>
          <Text.Bold>
            {data?.senderUser?.first_name}({data?.senderOrganization?.name})
          </Text.Bold>{' '}
          has invited you to collaborate on the brief:{' '}
          <Text.Bold presetColor="purple">"{data?.campaignBriefItem?.name || data?.campaignBrief?.name}"</Text.Bold>
        </>
      )}
    />
  ),
  [NOTIFICATIONS_TYPES.CONTENT_BOARD_CREATE_FROM_CREATOR_BRIEF]: ({ data, settings }) => (
    <CustomText
      render={Text => (
        <>
          <Text.Bold>{data?.senderUser?.first_name}</Text.Bold> has created a new content board{' '}
          <Text.Bold presetColor="purple">"{data?.contentBoard?.name}"</Text.Bold> for{' '}
          <Text.Bold presetColor="purple">
            "{data?.contentBoard?.campaign_brief_item_name || data?.contentBoard?.campaign_brief_name}"
          </Text.Bold>{' '}
          brief.
        </>
      )}
    />
  ),
  [NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_UPDATE_NAME]: ({ data, settings }) => (
    <CustomText
      render={Text => (
        <>
          <Text.Bold>{data?.senderUser?.first_name}</Text.Bold> has updated the name of the{' '}
          <Text.Bold presetColor="purple">"{data?.contentBoardDeliverable?.old_name}"</Text.Bold> to{' '}
          <Text.Bold presetColor="purple">
            <Text.RouterLink
              onClick={() => settings.onClose && settings.onClose()}
              to={`/content-board/${data?.contentBoardDeliverable?.content_board_id}/deliverables/${data?.contentBoardDeliverable?.id}`}
            >
              "{data?.contentBoardDeliverable?.name}"
            </Text.RouterLink>
          </Text.Bold>
        </>
      )}
    />
  ),
  [NOTIFICATIONS_TYPES.CONTENT_BOARD_CHANGE_APPROVER]: ({ data, settings }) => (
    <CustomText
      render={Text => (
        <>
          <Text.Bold>{data?.senderUser?.first_name}</Text.Bold> has assigned content board{' '}
          <Text.Bold presetColor="purple">"{data?.contentBoard?.name}"</Text.Bold> to you.
        </>
      )}
    />
  ),
  [NOTIFICATIONS_TYPES.CONTENT_BOARD_CREATE_DELIVERABLES]: ({ data, settings }) => (
    <CustomText
      render={Text => (
        <>
          <Text.Bold>{`${data.senderUser.first_name} ${data.senderUser.last_name} `}</Text.Bold>
          {`have added ${data.deliverables.length} new deliverables to the content board `}
          <Text.Bold presetColor="purple">
            <Text.RouterLink
              onClick={() => settings.onClose && settings.onClose()}
              to={`/content-board/${data?.deliverables?.[0]?.content_board_id}`}
            >
              "{data?.deliverables?.[0]?.content_board_name}"
            </Text.RouterLink>
          </Text.Bold>{' '}
        </>
      )}
    />
  ),
  [NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_VERSION_CONCEPT_EDIT]: ({ data, settings }) => (
    <CustomText
      render={Text => (
        <>
          <Text.Bold>{data?.senderUser?.first_name}</Text.Bold> has updated the concept for Deliverable:{' '}
          <Text.Bold presetColor="purple">
            <Text.RouterLink
              onClick={() => settings.onClose && settings.onClose()}
              to={`/content-board/${data?.contentBoardDeliverable?.content_board_id}/deliverables/${data?.contentBoardDeliverable?.id}`}
            >
              "{data?.contentBoardDeliverable?.name}"
            </Text.RouterLink>
          </Text.Bold>{' '}
          {getSectionEditNotificationTextEnd(settings.role, settings.rolesFlow)}.
        </>
      )}
    />
  ),
  [NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_VERSION_CONTENT_EDIT]: ({ data, settings }) => (
    <CustomText
      render={Text => (
        <>
          <Text.Bold>{data?.senderUser?.first_name}</Text.Bold> has updated the content for Deliverable:{' '}
          <Text.Bold presetColor="purple">
            <Text.RouterLink
              onClick={() => settings.onClose && settings.onClose()}
              to={`/content-board/${data?.contentBoardDeliverable?.content_board_id}/deliverables/${data?.contentBoardDeliverable?.id}`}
            >
              "{data?.contentBoardDeliverable?.name}"
            </Text.RouterLink>
          </Text.Bold>{' '}
          {getSectionEditNotificationTextEnd(settings.role, settings.rolesFlow)}.
        </>
      )}
    />
  ),
  [NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_VERSION_CONCEPT_SHARE]: ({ data, settings }) => (
    <CustomText
      render={Text => (
        <>
          <Text.Bold>{data?.senderUser?.first_name}</Text.Bold> has shared the concept for Deliverable:{' '}
          <Text.Bold presetColor="purple">
            <Text.RouterLink
              onClick={() => settings.onClose && settings.onClose()}
              to={`/content-board/${data?.contentBoardDeliverable?.content_board_id}/deliverables/${data?.contentBoardDeliverable?.id}`}
            >
              "{data?.contentBoardDeliverable?.name}"
            </Text.RouterLink>
          </Text.Bold>{' '}
          with Agency/Brand <Text.Bold>({data?.receiverOrganization?.name})</Text.Bold> for approval.
        </>
      )}
    />
  ),
  [NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_VERSION_CONTENT_SHARE]: ({ data, settings }) => (
    <CustomText
      render={Text => (
        <>
          <Text.Bold>{data?.senderUser?.first_name}</Text.Bold> has shared the content for Deliverable:{' '}
          <Text.Bold presetColor="purple">
            <Text.RouterLink
              onClick={() => settings.onClose && settings.onClose()}
              to={`/content-board/${data?.contentBoardDeliverable?.content_board_id}/deliverables/${data?.contentBoardDeliverable?.id}`}
            >
              "{data?.contentBoardDeliverable?.name}"
            </Text.RouterLink>
          </Text.Bold>{' '}
          with Agency/Brand <Text.Bold>({data?.receiverOrganization?.name})</Text.Bold> for approval.
        </>
      )}
    />
  ),
  [NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_VERSION_CONCEPT_APPROVE]: ({ data, settings }) => (
    <CustomText
      render={Text => (
        <>
          🎉 Woohoo!! Concept for Deliverable:{' '}
          <Text.Bold presetColor="purple">
            <Text.RouterLink
              onClick={() => settings.onClose && settings.onClose()}
              to={`/content-board/${data?.contentBoardDeliverable?.content_board_id}/deliverables/${data?.contentBoardDeliverable?.id}`}
            >
              "{data?.contentBoardDeliverable?.name}"
            </Text.RouterLink>
          </Text.Bold>{' '}
          has been approved.
        </>
      )}
    />
  ),
  [NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_VERSION_CONTENT_APPROVE]: ({ data, settings }) => (
    <CustomText
      render={Text => (
        <>
          🎉 Woohoo!! Content for Deliverable:{' '}
          <Text.Bold presetColor="purple">
            <Text.RouterLink
              onClick={() => settings.onClose && settings.onClose()}
              to={`/content-board/${data?.contentBoardDeliverable?.content_board_id}/deliverables/${data?.contentBoardDeliverable?.id}`}
            >
              "{data?.contentBoardDeliverable?.name}"
            </Text.RouterLink>
          </Text.Bold>{' '}
          has been approved.
        </>
      )}
    />
  ),
  [NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_UPDATE_LIVE_DATETIME]: ({ data, settings }) => (
    <CustomText
      render={Text => (
        <>
          <Text.Bold>{data?.senderUser?.first_name}</Text.Bold> has updated live date for Deliverable:{' '}
          <Text.Bold presetColor="purple">
            <Text.RouterLink
              onClick={() => settings.onClose && settings.onClose()}
              to={`/content-board/${data?.contentBoardDeliverable?.content_board_id}/deliverables/${data?.contentBoardDeliverable?.id}`}
            >
              "{data?.contentBoardDeliverable?.name}"
            </Text.RouterLink>
          </Text.Bold>{' '}
          to {settings?.newLiveDate}.
        </>
      )}
    />
  ),
  [NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_CONCEPT_REMINDER]: ({ data, settings }) => (
    <CustomText
      render={Text => (
        <>
          Concept for the Deliverable:{' '}
          <Text.Bold presetColor="purple">
            <Text.RouterLink
              onClick={() => settings.onClose && settings.onClose()}
              to={`/content-board/${data?.contentBoardDeliverable?.content_board_id}/deliverables/${data?.contentBoardDeliverable?.id}`}
            >
              "{data?.contentBoardDeliverable?.name}"
            </Text.RouterLink>
          </Text.Bold>{' '}
          is due at {settings?.dueDate}.
        </>
      )}
    />
  ),
  [NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_CONTENT_REMINDER]: ({ data, settings }) => (
    <CustomText
      render={Text => (
        <>
          Content for the Deliverable:{' '}
          <Text.Bold presetColor="purple">
            <Text.RouterLink
              onClick={() => settings.onClose && settings.onClose()}
              to={`/content-board/${data?.contentBoardDeliverable?.content_board_id}/deliverables/${data?.contentBoardDeliverable?.id}`}
            >
              "{data?.contentBoardDeliverable?.name}"
            </Text.RouterLink>
          </Text.Bold>{' '}
          is due at {settings?.dueDate}.
        </>
      )}
    />
  ),
  [NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_REMIND_TODAY_CONCEPT_DUE_DATETIME]: ({ data, settings }) => (
    <CustomText
      render={Text => (
        <>
          Concept for the Deliverable:{' '}
          <Text.Bold presetColor="purple">
            <Text.RouterLink
              onClick={() => settings.onClose && settings.onClose()}
              to={`/content-board/${data?.contentBoardDeliverable?.content_board_id}/deliverables/${data?.contentBoardDeliverable?.id}`}
            >
              "{data?.contentBoardDeliverable?.name}"
            </Text.RouterLink>
          </Text.Bold>{' '}
          is due today.
        </>
      )}
    />
  ),
  [NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_REMIND_1DAY_BEFORE_CONCEPT_DUE_DATETIME]: ({ data, settings }) => (
    <CustomText
      render={Text => (
        <>
          Concept for the Deliverable:{' '}
          <Text.Bold presetColor="purple">
            <Text.RouterLink
              onClick={() => settings.onClose && settings.onClose()}
              to={`/content-board/${data?.contentBoardDeliverable?.content_board_id}/deliverables/${data?.contentBoardDeliverable?.id}`}
            >
              "{data?.contentBoardDeliverable?.name}"
            </Text.RouterLink>
          </Text.Bold>{' '}
          is due in one day on {settings?.dueDate}.
        </>
      )}
    />
  ),
  [NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_REMIND_2DAYS_BEFORE_CONCEPT_DUE_DATETIME]: ({ data, settings }) => (
    <CustomText
      render={Text => (
        <>
          Concept for the Deliverable:{' '}
          <Text.Bold presetColor="purple">
            <Text.RouterLink
              onClick={() => settings.onClose && settings.onClose()}
              to={`/content-board/${data?.contentBoardDeliverable?.content_board_id}/deliverables/${data?.contentBoardDeliverable?.id}`}
            >
              "{data?.contentBoardDeliverable?.name}"
            </Text.RouterLink>
          </Text.Bold>{' '}
          is due in two days on {settings?.dueDate}.
        </>
      )}
    />
  ),
  [NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_REMIND_TODAY_CONTENT_DUE_DATETIME]: ({ data, settings }) => (
    <CustomText
      render={Text => (
        <>
          Content for the Deliverable:{' '}
          <Text.Bold presetColor="purple">
            <Text.RouterLink
              onClick={() => settings.onClose && settings.onClose()}
              to={`/content-board/${data?.contentBoardDeliverable?.content_board_id}/deliverables/${data?.contentBoardDeliverable?.id}`}
            >
              "{data?.contentBoardDeliverable?.name}"
            </Text.RouterLink>
          </Text.Bold>{' '}
          is due today.
        </>
      )}
    />
  ),
  [NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_REMIND_1DAY_BEFORE_CONTENT_DUE_DATETIME]: ({ data, settings }) => (
    <CustomText
      render={Text => (
        <>
          Content for the Deliverable:{' '}
          <Text.Bold presetColor="purple">
            <Text.RouterLink
              onClick={() => settings.onClose && settings.onClose()}
              to={`/content-board/${data?.contentBoardDeliverable?.content_board_id}/deliverables/${data?.contentBoardDeliverable?.id}`}
            >
              "{data?.contentBoardDeliverable?.name}"
            </Text.RouterLink>
          </Text.Bold>{' '}
          is due in one day on {settings?.dueDate}.
        </>
      )}
    />
  ),
  [NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_REMIND_2DAYS_BEFORE_CONTENT_DUE_DATETIME]: ({ data, settings }) => (
    <CustomText
      render={Text => (
        <>
          Content for the Deliverable:{' '}
          <Text.Bold presetColor="purple">
            <Text.RouterLink
              onClick={() => settings.onClose && settings.onClose()}
              to={`/content-board/${data?.contentBoardDeliverable?.content_board_id}/deliverables/${data?.contentBoardDeliverable?.id}`}
            >
              "{data?.contentBoardDeliverable?.name}"
            </Text.RouterLink>
          </Text.Bold>{' '}
          is due in two days on {settings?.dueDate}.
        </>
      )}
    />
  ),
  [NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_REMIND_TODAY_LIVE_DATETIME]: ({ data, settings }) => (
    <CustomText
      render={Text => (
        <>
          Content for the Deliverable:{' '}
          <Text.Bold presetColor="purple">
            <Text.RouterLink
              onClick={() => settings.onClose && settings.onClose()}
              to={`/content-board/${data?.contentBoardDeliverable?.content_board_id}/deliverables/${data?.contentBoardDeliverable?.id}`}
            >
              "{data?.contentBoardDeliverable?.name}"
            </Text.RouterLink>
          </Text.Bold>{' '}
          is due to go live today at {settings?.liveDate}.
        </>
      )}
    />
  ),
  [NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_REMIND_1DAY_BEFORE_LIVE_DATETIME]: ({ data, settings }) => (
    <CustomText
      render={Text => (
        <>
          Content for the Deliverable:{' '}
          <Text.Bold presetColor="purple">
            <Text.RouterLink
              onClick={() => settings.onClose && settings.onClose()}
              to={`/content-board/${data?.contentBoardDeliverable?.content_board_id}/deliverables/${data?.contentBoardDeliverable?.id}`}
            >
              "{data?.contentBoardDeliverable?.name}"
            </Text.RouterLink>
          </Text.Bold>{' '}
          is due to go live in one day at {settings?.liveDate}.
        </>
      )}
    />
  ),
  [NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_REMIND_2DAYS_BEFORE_LIVE_DATETIME]: ({ data, settings }) => (
    <CustomText
      render={Text => (
        <>
          Content for the Deliverable:{' '}
          <Text.Bold presetColor="purple">
            <Text.RouterLink
              onClick={() => settings.onClose && settings.onClose()}
              to={`/content-board/${data?.contentBoardDeliverable?.content_board_id}/deliverables/${data?.contentBoardDeliverable?.id}`}
            >
              "{data?.contentBoardDeliverable?.name}"
            </Text.RouterLink>
          </Text.Bold>{' '}
          is due to go live in two days at {settings?.liveDate}.
        </>
      )}
    />
  ),
};
