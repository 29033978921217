import React from 'react';
import styles from './CheckBox.module.scss';
import cs from 'classnames';
import CheckmarkSVG from '../../../../components/IconsSvg/CheckmarkSVG';

type PropsType = {
  isActive: boolean;
};

function CheckBox(props: PropsType) {
  return (
    <span className={cs(styles.checkbox, { [styles.active]: props.isActive })}>
      <CheckmarkSVG />
    </span>
  );
}

export default CheckBox;
