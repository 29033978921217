import { BOARD_STATUTES_TYPES } from '../../../models/entities/board/enums/boardStatuses';
import styles from '../../../components/statuses/components/statuses-colors.module.scss';

const boardStatusesConfig = {
  [BOARD_STATUTES_TYPES.BRIEFING]: {
    status: 'Briefing',
    shortStatus: 'Briefing',
    text: 'Working on the brief to finalize the details for the campaign',
    colorClass: styles['color-pink'],
    dotClass: styles['dot-pink'],
  },
  [BOARD_STATUTES_TYPES.PENDING_CONTRACT]: {
    status: 'Pending Contract',
    shortStatus: 'Pending',
    text: 'Contract is pending awaiting approval.',
    colorClass: styles['color-orange'],
    dotClass: styles['dot-orange'],
  },
  [BOARD_STATUTES_TYPES.CONTENT_CREATION]: {
    status: 'Content creation',
    shortStatus: 'Creation',
    text: 'Content is being created for approval.',
    colorClass: styles['color-pink'],
    dotClass: styles['dot-pink'],
  },
  [BOARD_STATUTES_TYPES.UNDER_REVIEW]: {
    status: 'Under review',
    shortStatus: 'Review',
    text: 'Content has been uploaded and is currently being reviewed.',
    colorClass: styles['color-blue'],
    dotClass: styles['dot-blue'],
  },
  [BOARD_STATUTES_TYPES.COMPLETED]: {
    status: 'Completed',
    shortStatus: 'Completed',
    text: 'Campaign has been completed and results shared',
    colorClass: styles['color-green'],
    dotClass: styles['dot-green'],
  },
};

export default boardStatusesConfig;
