import React, { ReactElement } from 'react';
import styles from './ContentBoardAndDeliverableRoutesWrapper.module.scss';
import MainLayout from '../../../../components/MainLayout';
import { Route } from 'react-router-dom';
import DeliverableItem from '../../DeliverableItem';
import ContentBoard from '../../ContentBoard';
import { Switch, useLocation } from 'react-router';
import ContentApprovalHeaderBreadcrumbs from '../ContentApprovalHeaderBreadcrumbs';
import DiscussionBackground from '../../../../expert/components/Discussion/DiscussionBackground';
import { useMediaQuery } from 'react-responsive/src';
import { UserPermission } from '../../../../models/permissions/enum/UserPermission';
import { ContentApprovalBreadcrumbsPageType } from '../../../../models/entities/breadcrumbs/enums/ContentApprovalBreadcrumbsPage';
import cs from 'classnames';
import { AuthorizedUser } from '../../../../models/permissions/users/AuthorizedUser';
import { useAppSelector } from '../../../../shared/hooks/reduxHooks';

const ContentBoardTitle = (): ReactElement => {
  return <ContentApprovalHeaderBreadcrumbs page={ContentApprovalBreadcrumbsPageType.BOARD} />;
};

const DeliverableItemTitle = (): ReactElement => {
  return <ContentApprovalHeaderBreadcrumbs page={ContentApprovalBreadcrumbsPageType.DELIVERABLE} />;
};

type PageType = {
  className: string;
  title: ReactElement;
};

type PageConfigType = {
  contentBoard: PageType;
  deliverableItem: PageType;
};

const pagesConfig: PageConfigType = {
  contentBoard: {
    className: styles.contentBoardLayout,
    title: <ContentBoardTitle />,
  },
  deliverableItem: {
    className: styles.deliverableItemLayout,
    title: <DeliverableItemTitle />,
  },
};

const ContentBoardAndDeliverableRoutesWrapper = () => {
  const location = useLocation();
  const userModel = useAppSelector(state => state.auth.userModel);
  const isDeliverable = /deliverables/g.test(location.pathname);
  const config = isDeliverable ? pagesConfig.deliverableItem : pagesConfig.contentBoard;
  const isDesktopLandscape = useMediaQuery({
    query: '(min-width: 992px) and (orientation: landscape)',
  });

  return (
    <>
      <MainLayout
        contentClassName={cs(config.className, { [styles.unauthorizedUserLayout]: !(userModel instanceof AuthorizedUser) })}
        title={userModel?.hasAccess(UserPermission.CAN_SEE_HEADER_CONTENT_BOARD_DELIVERABLE_BREADCRUMBS) ? config.title : null}
      >
        {!isDesktopLandscape && userModel?.hasAccess(UserPermission.CAN_SEE_HEADER_CONTENT_BOARD_DELIVERABLE_BREADCRUMBS) && (
          <ContentApprovalHeaderBreadcrumbs
            asMobile
            page={isDeliverable ? ContentApprovalBreadcrumbsPageType.DELIVERABLE : ContentApprovalBreadcrumbsPageType.BOARD}
          />
        )}
        <Switch>
          <Route path="/content-board/:boardId" exact>
            <ContentBoard />
          </Route>
          <Route path="/content-board/:boardId/deliverables/:deliverableId" exact>
            <DeliverableItem />
          </Route>
        </Switch>
        {userModel?.hasAccess(UserPermission.CAN_SEE_DISCUSSION) && <DiscussionBackground />}
      </MainLayout>
    </>
  );
};

export default ContentBoardAndDeliverableRoutesWrapper;
