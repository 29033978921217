import React, { FC, ReactElement, useCallback } from 'react';
import styles from './WarningCard.module.scss';
import { ReactComponent as InfoIcon } from '../../../../../../../assets/img/icons/information-icon.svg';
import { ReactComponent as WarningIcon } from '../../../../../../../assets/img/icons/triangle-warning-icon-sharp.svg';
import cs from 'classnames';

export enum WARNING_CARD_ICONS {
  INFO = 'info',
  WARNING = 'warning',
}

type PropsType = {
  warningText: string | ReactElement;
  className?: string;
  type?: WARNING_CARD_ICONS;
};

const WarningCard: FC<PropsType> = ({ type = WARNING_CARD_ICONS.INFO, ...props }) => {
  const getIcon = useCallback(() => {
    switch (type) {
      case WARNING_CARD_ICONS.INFO:
        return <InfoIcon className={styles.infoIcon} />;
      case WARNING_CARD_ICONS.WARNING:
        return <WarningIcon className={styles.warningIcon} />;
      default:
        return <InfoIcon className={styles.infoIcon} />;
    }
  }, [type]);

  return (
    <div className={cs(styles.root, props.className)}>
      <div>{getIcon()}</div>
      <div className={styles.warningText}>{props.warningText}</div>
    </div>
  );
};

export default WarningCard;
