import React, { useCallback, useMemo, useState } from 'react';
import { generateDeliverableContentWithAI } from '../../../../../../services/contentApproval';
import { useAppSelector } from '../../../../../../shared/hooks/reduxHooks';
import { useParams } from 'react-router';
import { DeliverablesReducerState } from '../../../../../../reducers/deliverables.reducer';
import { ConceptFlowType } from '../../../hooks/useDeliverableState';
import usePlatformPlacementSettings from '../../../hooks/usePlatformPlacementSettings';
import ErrorHandler from '../../../../../../models/entities/error/ErrorHandler';

const useGenerateContentWithAI = (isEditingMode: boolean, conceptFlow: ConceptFlowType) => {
  const organizationId = useAppSelector(state => state.auth.currentOrganization?.organization_id);
  const isPageUpdateLoading = useAppSelector<DeliverablesReducerState['deliverableContentUpdateLoading']>(
    state => state.deliverables.deliverableContentUpdateLoading
  );
  const openedBoard = useAppSelector(state => state.contentBoard.openedBoard);
  const openedDeliverable = useAppSelector(state => state.deliverables.openedDeliverable);
  const params = useParams();
  const { settings } = usePlatformPlacementSettings();
  const [isGenerateLoading, setIsGenerateLoading] = useState<boolean>(false);

  const isGenerateAvailable = useMemo(() => {
    const isBriefExist = !!openedBoard && (openedBoard.campaign_brief_id || openedBoard.campaign_brief_item_id || openedBoard.briefFile);
    return !settings?.isVerticalView && isEditingMode && !isPageUpdateLoading && (isBriefExist || openedDeliverable?.concept?.description);
  }, [
    isEditingMode,
    isPageUpdateLoading,
    openedBoard,
    isPageUpdateLoading,
    conceptFlow,
    settings?.isVerticalView,
    openedDeliverable?.concept?.description,
  ]);

  const generate = useCallback(
    async callback => {
      if (organizationId && params.boardId && params.deliverableId) {
        setIsGenerateLoading(true);
        await generateDeliverableContentWithAI(organizationId, params.boardId, params.deliverableId)
          .then(response => {
            const message = response?.data?.response?.replace('\n\n', '');
            callback(message);
          })
          .catch(err => {
            throw new ErrorHandler(err).getError();
          })
          .finally(() => {
            setIsGenerateLoading(false);
          });
      }
    },
    [organizationId, params]
  );

  return { generate, isGenerateLoading, isGenerateAvailable };
};

export default useGenerateContentWithAI;
