import { COLLABORATOR_ROLE_TYPES } from '../collaborator/CollaboratorRole';
import { DeliverablePermission } from '../enum/DeliverablePermission';

export const roleConstantPermissionsFactory = (roleType: COLLABORATOR_ROLE_TYPES): DeliverablePermission[] => {
  switch (roleType) {
    case COLLABORATOR_ROLE_TYPES.CONTENT_CREATOR:
      return [];
    case COLLABORATOR_ROLE_TYPES.TALENT_MANAGER:
      return [DeliverablePermission.CAN_EDIT_LIVE_DUE_DATE, DeliverablePermission.CAN_EDIT_REMINDER_DATE];
    case COLLABORATOR_ROLE_TYPES.BRAND:
      return [DeliverablePermission.CAN_EDIT_LIVE_DUE_DATE];
    case COLLABORATOR_ROLE_TYPES.AGENCY:
      return [DeliverablePermission.CAN_EDIT_LIVE_DUE_DATE];
    default:
      return [];
  }
};
