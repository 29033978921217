import { ICreateBoardBrief } from '../CreateBoardBrief';
import { CURRENCIES_TYPES } from '../../../enums/Currencies';
import { PREFERRED_ADD_DECLARATION_TYPES } from '../enums/AddDeclarationsTypes';
import { IBoardBriefFormikValues } from '../BoardBriefFormikValues';
import dayjs from 'dayjs';

export default class CreateBoardBriefHydrator implements ICreateBoardBrief {
  item_name: string | null;
  // brand_logo?: File;
  // brand_name: string | null;
  brand_description: string | null;
  brand_provides_product: string | null;
  notes: string | null;
  key_message: string | null;
  objective: string;
  date_start: string | null;
  date_end: string | null;
  live_date_start: string | null;
  target_audience: string;
  exclusivity: string | null;
  reposting_platform: string | null;
  reposting_duration: number | null;
  hashtags: string;
  social_tagging: string;
  total_budget: number | null;
  boosting_budget: number | null;
  boosting_duration: number | null;
  boosting_manager: string | null;
  total_budget_currency: CURRENCIES_TYPES;
  boosting_budget_currency: CURRENCIES_TYPES;
  preferred_ad_declaration: PREFERRED_ADD_DECLARATION_TYPES;
  other_infos: string | null;

  constructor(briefFormikValues: IBoardBriefFormikValues) {
    this.item_name = briefFormikValues.itemName;
    // if (briefFormikValues.brandLogo.file) {
    //   this.brand_logo = briefFormikValues.brandLogo.file;
    // } else {
    //   delete this.brand_logo;
    // }
    // this.brand_name = briefFormikValues.brandName || null;
    this.brand_description = briefFormikValues.brandBackground || null;
    this.brand_provides_product = briefFormikValues.showBrandProvidesProduct ? briefFormikValues.brandProvidesProduct : null;
    this.notes = briefFormikValues.notes || null;
    this.key_message = briefFormikValues.messaging || null;
    this.objective = JSON.stringify(briefFormikValues.campaignObjective);
    this.date_start = briefFormikValues.date_start ? dayjs(briefFormikValues.date_start).format('YYYY-MM-DD') : null;
    this.date_end = briefFormikValues.date_end ? dayjs(briefFormikValues.date_end).format('YYYY-MM-DD') : null;
    this.live_date_start = briefFormikValues.date_post ? dayjs(briefFormikValues.date_post).format('YYYY-MM-DD') : null;
    this.target_audience = JSON.stringify({
      location: {
        country: briefFormikValues.country,
        city: briefFormikValues.city,
      },
      gender: briefFormikValues.gender ? [briefFormikValues.gender] : [],
      age: {
        min: briefFormikValues.ageMin, // Number 13-65, 65 = 65+
        max: briefFormikValues.ageMax, // Number 13-65
      },
      interests_and_behaviours: briefFormikValues.interests,
    });
    this.exclusivity = briefFormikValues.exclusivity;
    this.reposting_platform = briefFormikValues.repostingPlatform?.length ? JSON.stringify(briefFormikValues.repostingPlatform) : null;
    this.reposting_duration = briefFormikValues.repostingPlatform?.length ? briefFormikValues.repostingDuration : null;
    this.hashtags = JSON.stringify(briefFormikValues.hashtags);
    this.social_tagging = JSON.stringify(briefFormikValues.socialTagging);
    this.total_budget = briefFormikValues.total_budget || null;
    this.boosting_budget = briefFormikValues.boosting_budget || null;
    this.boosting_duration = briefFormikValues.boosting_duration || null;
    this.boosting_manager = !!briefFormikValues.boosting_manager?.length ? JSON.stringify(briefFormikValues.boosting_manager) : null;
    this.total_budget_currency = briefFormikValues.total_budget_currency;
    this.boosting_budget_currency = briefFormikValues.boosting_budget_currency;
    this.preferred_ad_declaration = briefFormikValues.adInCaption
      ? PREFERRED_ADD_DECLARATION_TYPES.AD_IN_CAPTION
      : PREFERRED_ADD_DECLARATION_TYPES.PAID_PARTNERSHIP_TOOL;
    this.other_infos = briefFormikValues.other_infos || null;
  }
}
