import React, { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { clearContentBoard, getContentBoard } from '../../../../actions/ContentBoardActions/contentBoard.actions';
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/reduxHooks';
import { BreadcrumbRoute } from '../../../../models/entities/breadcrumbs/BreadcrumbRoute';
import HeaderBreadcrumbs from '../../../../components/BriefsAndBoardsFlows/HeaderBreadcrumbs';
import { ContentApprovalBreadcrumbsPageType } from '../../../../models/entities/breadcrumbs/enums/ContentApprovalBreadcrumbsPage';
import { LSBoardBackRoute } from '../../../../models/entities/breadcrumbs/LSBoardBackRoute';

type PropsType = {
  page: ContentApprovalBreadcrumbsPageType;
  asMobile?: boolean;
};

const ContentApprovalHeaderBreadcrumbs: FC<PropsType> = ({ page, asMobile = false }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const params = useParams<{ boardId: string }>();

  const currentOrg = useAppSelector(state => state.auth.currentOrganization);
  const { openedBoard, boardLoading } = useAppSelector(state => state.contentBoard);
  const { openedDeliverable } = useAppSelector(state => state.deliverables);
  const boardBrief = useAppSelector(state => state.boardBrief.boardBrief);
  const [boardName, setBoardName] = useState<string>(openedBoard?.name || '');
  const [deliverableName, setDeliverableName] = useState<string>(openedDeliverable?.deliverable?.name || '');
  const [boardBriefName, setBoardBriefName] = useState<string>(openedDeliverable?.deliverable?.name || '');
  const [backRoute, setBackRoute] = useState<LSBoardBackRoute | null>(JSON.parse(localStorage.getItem('boardBackRoute') || '{}'));

  useEffect(() => {
    if (!openedBoard && page === ContentApprovalBreadcrumbsPageType.DELIVERABLE && currentOrg) {
      dispatch(getContentBoard(params.boardId));
    }
    if (openedBoard) {
      setBoardName(openedBoard.name || 'Board');
    }
    if (openedDeliverable?.deliverable) {
      setDeliverableName(openedDeliverable?.deliverable.name || 'Deliverable');
    }
    if (boardBrief?.name) {
      setBoardBriefName(boardBrief.name || 'Board brief');
    }
  }, [openedBoard, boardLoading, openedDeliverable, currentOrg, boardBrief]);

  const items: BreadcrumbRoute[] = useMemo(() => {
    let rootRouteName = 'Content Approval';
    let rootRoute = '/content-approval';

    if (backRoute) {
      if (+backRoute.boardId === +params.boardId) {
        rootRouteName = backRoute.routes?.[0].routeName;
        rootRoute = backRoute.routes?.[0].route;
      } else {
        localStorage.removeItem('boardBackRoute');
        setBackRoute(null);
      }
    }

    const array: BreadcrumbRoute[] = [];

    if (boardName.length) {
      if (page !== ContentApprovalBreadcrumbsPageType.BRIEF_EDIT && page !== ContentApprovalBreadcrumbsPageType.BOARD_BRIEF_CREATE) {
        array.push({
          name: rootRouteName,
          callback() {
            history.push(rootRoute);
            localStorage.removeItem('boardBackRoute');
            dispatch(clearContentBoard());
          },
        });
      }

      if (
        backRoute &&
        backRoute.routes?.[0]?.route === '/campaign' &&
        page !== ContentApprovalBreadcrumbsPageType.BRIEF_EDIT &&
        page !== ContentApprovalBreadcrumbsPageType.BOARD_BRIEF_CREATE
      ) {
        const newRoutes = [...backRoute.routes];
        newRoutes.shift();
        array.push(
          ...newRoutes.map(item => ({
            name: item.routeName,
            callback() {
              history.push(item.route);
              localStorage.removeItem('boardBackRoute');
              dispatch(clearContentBoard());
            },
          }))
        );
      }

      array.push({
        name: 'Board: ' + boardName,
        callback() {
          history.push(`/content-board/${params.boardId}`);
        },
      });
    }

    if (page === ContentApprovalBreadcrumbsPageType.DELIVERABLE && deliverableName.length) {
      array.push({
        name: deliverableName,
        callback() {},
      });
    }

    if (page === ContentApprovalBreadcrumbsPageType.BOARD_BRIEF && boardBriefName.length) {
      array.push({
        name: boardBriefName,
        callback() {},
      });
    }

    if (page === ContentApprovalBreadcrumbsPageType.BRIEF_EDIT) {
      array.push(
        {
          name: boardBriefName,
          callback() {
            history.push(`/content-board/${params.boardId}/brief`);
          },
        },
        {
          name: 'Brief Edit',
          callback() {},
        }
      );
    }

    if (page === ContentApprovalBreadcrumbsPageType.BOARD_BRIEF_CREATE) {
      array.push({
        name: 'Create Otterfish brief',
        callback() {},
      });
    }

    return array;
  }, [page, boardName, deliverableName, backRoute, openedBoard, boardBriefName]);

  return <HeaderBreadcrumbs items={items} asMobile={asMobile} />;
};

export default ContentApprovalHeaderBreadcrumbs;
