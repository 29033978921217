import React, { forwardRef, useMemo } from 'react';
import styles from './AddAssetFileInput.module.scss';
import cs from 'classnames';
import dayjs from 'dayjs';
import { DELIVERABLE_SECTION_TYPES } from '../../../../../../models/enums/DeliverableSectionTypes';
import { ICreatedAsset } from '../../../../../../models/entities/asset/createdAsset';
import { INPUT_FILE_ALLOWED_TYPE } from '../../../../../../shared/constants/tsConstants';
import notificationDataConfig from '../../../../../../shared/utils/notificationDataConfig';
import { TOAST_NOTIFICATION_TYPES } from '../../../../../../shared/constants/toastNotificationsData';
import { createNotification } from '../../../../../../actions/toastNotificationActions/toastNotification.actions';
import { useAppDispatch } from '../../../../../../shared/hooks/reduxHooks';
import { FileData, IFileData } from '../../../../hooks/useFileUpload';
import { ASSET_STATUSES } from '../../../../../../models/entities/asset/enums/AssetStatuses';

const FILE_EXTENSION_REGEX = /(?:\.([^.]+))?$/;

type PropsType = {
  onAddAsset: (asset: ICreatedAsset) => void;
  fileTypes?: string[];
  className?: string;
  allowedLength?: number;
  assetType: DELIVERABLE_SECTION_TYPES;
};

const AddAssetFileInput = forwardRef<HTMLInputElement | null, PropsType>(
  ({ onAddAsset, fileTypes = ['image', 'video'], className, allowedLength = 1, assetType }, forwardedRef) => {
    const dispatch = useAppDispatch();

    const handleCreate = ({ url, file }: IFileData) => {
      const isVideo = /^video/g.test(file.type);
      const isImage = /^image/g.test(file.type);
      const isMov = file.type === 'video/quicktime';

      if ((!isVideo && !isImage) || (isVideo && !fileTypes.includes(INPUT_FILE_ALLOWED_TYPE.VIDEO_INPUT_ALLOWED))) {
        const notification = notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.FILE_TYPE_ERROR, { text: [file.type] });
        dispatch(createNotification(notification));
        return;
      }

      if (!(typeof url === 'string')) return;

      onAddAsset({
        id: 'new' + Math.random(),
        file: file,
        mime_type: file.type,
        caption: '',
        status: isVideo ? ASSET_STATUSES.PROCESSING : ASSET_STATUSES.ACTIVE,
        isImagePreview: !isVideo || isMov,
        error: null,
        filename: file.name,
        locationForPreview: url,
        size: file.size,
        extension: FILE_EXTENSION_REGEX.exec(file.name)![1],
        created_at: dayjs().toISOString(),
        type: assetType,
      });
    };

    const addAsset = (file: File) =>
      new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          resolve(new FileData(file, reader.result));
        };
      }).then(res => {
        if (res instanceof FileData) {
          handleCreate(res);
        }
      });

    const handleAddVisual = event => {
      const files = event.target.files;

      for (let i = 0; i < files.length && i < allowedLength; i++) {
        addAsset(files[i]);
      }
    };

    const fileType: string = useMemo(() => {
      return (
        fileTypes.reduce((acc, type) => {
          return acc + ', ' + type;
        }) || ''
      );
    }, [fileTypes]);

    return (
      <input
        ref={forwardedRef}
        type="file"
        accept={fileType}
        onChange={handleAddVisual}
        className={cs(styles.fileInput, className)}
        multiple={allowedLength > 1}
      />
    );
  }
);

export default AddAssetFileInput;
