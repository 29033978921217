import React, { FC, PropsWithChildren } from 'react';
import styles from './LocalBanner.module.scss';
import cs from 'classnames';
import { ReactComponent as InfoIcon } from '../../assets/img/icons/information-icon.svg';
import { CloseIcon } from '../../expert/components/IconsSvg/ReactIcons/ReactIcons';

type PropsType = {
  className?: string;
  isOpen?: boolean;
  onClose: () => void;
  type?: 'info';
} & PropsWithChildren;

const LocalBanner: FC<PropsType> = ({ className, children, isOpen = false, onClose, type = 'info' }) => {
  const getIcon = () => {
    switch (type) {
      case 'info':
        return <InfoIcon className={styles.icon} />;
      default:
        return <InfoIcon className={styles.icon} />;
    }
  };

  return (
    <div className={cs(styles.root, styles[type], { [styles.hidden]: !isOpen }, className)}>
      <div className={styles.content}>
        {getIcon()}
        <div className={styles.text}>{children}</div>
      </div>
      <CloseIcon className={styles.close} size={25} onClick={onClose} />
    </div>
  );
};

export default LocalBanner;
