import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import styles from './InnerConcept.module.scss';
import EditorStyledField from '../../../../../../../../components/EditorStyledField';
import cs from 'classnames';
import SectionDescriptionLoadingWrapper from '../../../components/SectionDescriptionLoadingWrapper';
import SectionFooter from '../../../components/SectionFooter';
import SectionVisualsWrapper from '../../../components/SectionVisualsWrapper';
import VisualCard from '../../../../visuals/VisualCard';
import { useMediaQuery } from 'react-responsive/src';
import VisualsDraggableListWrapper from '../../../../visuals/VisualsDraggableListWrapper';
import { DeliverablePermission } from '../../../../../../../../models/permissions/enum/DeliverablePermission';
import { ConceptFlowType } from '../../../../../hooks/useDeliverableState';
import { AssetStateType } from '../../../../../../../../models/entities/asset/AssetState';
import { IAssetMedia } from '../../../../../../../../models/entities/asset/AssetMedia';
import { useAppDispatch, useAppSelector } from '../../../../../../../../shared/hooks/reduxHooks';
import { DeliverablesReducerState } from '../../../../../../../../reducers/deliverables.reducer';
import { INPUT_FILE_ALLOWED_TYPE } from '../../../../../../../../shared/constants/tsConstants';
import useGenerateConceptWithAI from '../../useGenerateConceptWithAI';
import ButtonLightPurpleWhite from '../../../../../../../../components/Buttons/ButtonLightPurpleWhite';
import { createNotification } from '../../../../../../../../actions/toastNotificationActions/toastNotification.actions';
import notificationDataConfig from '../../../../../../../../shared/utils/notificationDataConfig';
import { TOAST_NOTIFICATION_TYPES } from '../../../../../../../../shared/constants/toastNotificationsData';

type PropsType = {
  isEditingMode: boolean;
  isConceptEmpty: boolean;
  setIsCreateConceptWithGenerateAI: Dispatch<SetStateAction<boolean>>;
  conceptFlow: ConceptFlowType;
  openAsset: (asset: AssetStateType | IAssetMedia) => void;
  setIsEditingMode: (value: boolean) => void;
  setIsOpenConfirmCancel: (value: boolean) => void;
  onViewMoreClick: () => void;
  isViewMore: boolean;
  setIsViewMore: (value: boolean) => void;
  onEdit: () => void;
  onRemove: (() => void) | null;
  isCreateConceptWithGenerateAI: boolean;
};

const InnerConcept: FC<PropsType> = props => {
  const dispatch = useAppDispatch();
  const isPageLoading = useAppSelector<DeliverablesReducerState['deliverableLoading']>(state => state.deliverables.deliverableLoading);
  const openedDeliverable = useAppSelector<DeliverablesReducerState['openedDeliverable']>(state => state.deliverables.openedDeliverable);
  const isPageUpdateLoading = useAppSelector<DeliverablesReducerState['deliverableConceptUpdateLoading']>(
    state => state.deliverables.deliverableConceptUpdateLoading
  );
  const pageModel = useAppSelector<DeliverablesReducerState['pageModel']>(state => state.deliverables.pageModel);

  const isDesktopLandscape: boolean = useMediaQuery({
    query: '(min-width: 992px) and (orientation: landscape)',
  });

  const [isShowAllVisual, setIsShowAllVisual] = useState<boolean>(false);

  const [isRerender, setIsRerender] = useState<boolean>(false);
  const [isFirstGenerate, setIsFirstGenerate] = useState(true);
  const { generate, isGenerateLoading, isGenerateAvailable } = useGenerateConceptWithAI(props.isEditingMode);

  const generateConceptWithAI = useCallback(async () => {
    const loadingStyles = "font-size: 13px; font-style: italic; font-family: 'OpenSans', sans-serif;";

    await props.conceptFlow.concept.onChangeConcept(
      props.conceptFlow.concept.description +
        `${
          isFirstGenerate ? '' : '<p></p>'
        }<p style="${loadingStyles}">Your concept idea is being generated, this might take up to one minute to complete.</p><p style="${loadingStyles}">Loading...</p>`
    );
    setIsRerender(true);
    setIsRerender(false);

    await generate(result => {
      const value =
        props.conceptFlow.concept.description +
        `${isFirstGenerate ? '' : '<p></p>'}<p><strong>New Concept Idea:</strong></p><p>${result}</p>`;
      props.conceptFlow.concept.onChangeConcept(value);
      setIsRerender(true);
      setIsRerender(false);
    }).catch(err => {
      dispatch(
        createNotification(
          notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.DELIVERABLE_GENERATE_AI_ERROR, { text: [err.getMessage()] })
        )
      );
      props.conceptFlow.concept.onChangeConcept(props.conceptFlow.concept.description);
      setIsRerender(true);
      setIsRerender(false);
    });
  }, [generate, props.conceptFlow, isFirstGenerate]);

  useEffect(() => {
    if (props.isCreateConceptWithGenerateAI && isFirstGenerate && props.isConceptEmpty) {
      generateConceptWithAI().then(() => {
        setIsFirstGenerate(false);
        props.setIsCreateConceptWithGenerateAI(false);
      });
    }
  }, []);

  return (
    <>
      <div className={styles.content}>
        <div
          className={cs(styles.contentWrapper, {
            [styles.wrapperCollapsed]: !props.isEditingMode && !isPageUpdateLoading && !isPageLoading,
          })}
        >
          <SectionDescriptionLoadingWrapper isLoading={isPageLoading}>
            {!props.isEditingMode && !props.conceptFlow.concept.description && !isPageUpdateLoading ? null : (
              <>
                <div className={styles.conceptWrapper}>
                  <EditorStyledField
                    keyForRerender={props.isEditingMode && !isRerender ? false : `${Math.random()}`}
                    placeholder={'Share your ideas about your concept'}
                    initialValue={props.conceptFlow.concept.description}
                    onChange={props.conceptFlow.concept.onChangeConcept}
                    readOnly={(!props.isEditingMode && !isPageUpdateLoading) || isGenerateLoading}
                    wrapperClassName={cs(styles.editor, {
                      [styles.withBottomSpace]: props.isEditingMode,
                    })}
                    toolbarClassName={styles.editorToolbar}
                    editorClassName={cs({ [styles.editorViewState]: !isPageUpdateLoading && !props.isEditingMode })}
                  />
                  {props.isEditingMode && (
                    <ButtonLightPurpleWhite
                      className={styles.generateButton}
                      onClick={generateConceptWithAI}
                      size="small"
                      designVersion="v1"
                      disabled={isGenerateLoading || !isGenerateAvailable}
                    >
                      Generate Ideas with AI
                    </ButtonLightPurpleWhite>
                  )}
                </div>
                {!!props.isEditingMode && !isFirstGenerate && (
                  <span className={styles.generateText}>
                    Otterfish AI helps you to generate some ideas for your brief as a starting point. We encourage you to use these ideas to
                    come up with a single concept for your deliverable.
                  </span>
                )}
              </>
            )}
          </SectionDescriptionLoadingWrapper>

          <SectionVisualsWrapper
            assetsFlow={props.conceptFlow.conceptAssets}
            isPageLoading={isPageLoading}
            isPageUpdateLoading={isPageUpdateLoading}
            isEditingMode={props.isEditingMode}
            maxVisualsLength={10}
            subTitle="Feel free to add visuals for your concept idea"
            questionTooltipText={'Supported formats'}
            infoBannerText={'Preview of uploaded files will be available after saving the concept!'}
            fileTypes={[INPUT_FILE_ALLOWED_TYPE.IMAGE_INPUT_ALLOWED, INPUT_FILE_ALLOWED_TYPE.VIDEO_INPUT_ALLOWED]}
            isViewMore={props.isViewMore}
            isShowAllVisual={isShowAllVisual}
            setIsShowAllVisual={setIsShowAllVisual}
            isConcept={true}
            // sectionSubTitle={'Your visuals'}
            // downloadButton={{
            //   isDisplay:
            //     conceptFlow?.concept?.status !== DELIVERABLE_STATUSES.EMPTY &&
            //     pageModel?.roleCan(DeliverablePermission.CAN_DOWNLOAD_ASSETS) &&
            //     !!openedDeliverable.concept.assets.length,
            //   assets: openedDeliverable?.concept?.assets,
            // }}
          >
            <VisualsDraggableListWrapper
              onReorder={props.conceptFlow.conceptAssets.onReorder}
              assets={props.conceptFlow.conceptAssets.current}
              withDrag={props.isEditingMode && props.conceptFlow.conceptAssets.current.length > 1}
            >
              {props.conceptFlow.conceptAssets.current.map(asset => (
                <VisualCard
                  key={asset.id}
                  isStoryTile={false}
                  withCaption
                  fileTypes={[INPUT_FILE_ALLOWED_TYPE.IMAGE_INPUT_ALLOWED, INPUT_FILE_ALLOWED_TYPE.VIDEO_INPUT_ALLOWED]}
                  asset={asset}
                  onDeleteVisual={props.conceptFlow.conceptAssets.onDelete}
                  onUpdateVisual={props.conceptFlow.conceptAssets.onUpdate}
                  openAsset={props.openAsset}
                  isEditingMode={props.isEditingMode}
                  isViewMore={props.isViewMore}
                  isPageUpdateLoading={isPageUpdateLoading}
                  textConfig={{
                    deleteWithDescription: 'Deleting a visual will delete its description too. Are you sure you want to delete it?',
                    deleteWithoutDescription: 'Are you sure you want to delete the visual?',
                    descriptionPlaceholder: 'Add more details about your visual',
                  }}
                  assetsCount={props.conceptFlow.conceptAssets.current.length}
                  isEffectsDisabled={false}
                />
              ))}
            </VisualsDraggableListWrapper>
          </SectionVisualsWrapper>
        </div>
      </div>
      {!isPageLoading && (!isDesktopLandscape || pageModel?.roleCan(DeliverablePermission.CAN_SEE_SECTION_FOOTER)) && (
        <SectionFooter
          assets={props.conceptFlow.conceptAssets.current}
          section={openedDeliverable?.concept}
          editText="Edit Concept"
          isEditingMode={props.isEditingMode}
          isPageUpdateLoading={isPageUpdateLoading}
          onCancel={() => {
            if (isGenerateLoading) return;
            if (props.conceptFlow.isSectionChanged) {
              props.setIsOpenConfirmCancel(true);
            } else {
              props.setIsEditingMode(false);
            }
          }}
          isDisabled={!props.conceptFlow.isSectionChanged || isGenerateLoading}
          onEdit={() => {
            props.onEdit();
            props.conceptFlow.conceptEdit.checkpointConcept();
            props.setIsEditingMode(true);
          }}
          onRemove={props.onRemove}
          onSave={() => {
            if (isGenerateLoading) return;
            props.conceptFlow.concept.saveConcept();
            if (!isDesktopLandscape) {
              props.setIsViewMore(false);
              setIsShowAllVisual(false);
            }
            props.setIsEditingMode(false);
          }}
          onViewMoreClick={props.onViewMoreClick}
          isViewMore={props.isViewMore}
        />
      )}
    </>
  );
};

export default InnerConcept;
