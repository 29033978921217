import React, { FC } from 'react';
import styles from './CollaboratorsIcons.module.scss';
import CollaboratorTooltip from '../../../../components/Cells/ContentApprovalCell/CollaboratorsInfo/CollaboratorTooltip';
import cs from 'classnames';
import { IBoardCollaborator } from '../../../../models/entities/board/BoardCollaborator';

type PropsType = {
  collaborators: IBoardCollaborator[];
  onCollaboratorClick?: () => void;
};

const displayableIconsCount = 3;

const CollaboratorsIcons: FC<PropsType> = props => {
  return (
    <>
      {props.collaborators.map((item, index) => {
        if (index <= displayableIconsCount - 1) {
          const nameArray = item.organization_name.toUpperCase().split(' ');
          let nameSymbols = '';
          for (const nameArrayElement of nameArray) {
            nameSymbols += nameArrayElement[0] || '';
          }
          nameSymbols = nameSymbols.substr(0, 2);

          return (
            <div key={item.organization_id} onClick={props.onCollaboratorClick || function () {}} className={styles.icon}>
              {nameSymbols}
              <CollaboratorTooltip collaborator={item} iconText={nameSymbols} className={styles.collaboratorTooltip} />
            </div>
          );
        }
      })}
      {props.collaborators.length > 0 && <div className={cs(styles.icon, styles.qty)}>{props.collaborators.length}</div>}
    </>
  );
};

export default CollaboratorsIcons;
