import React, { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import styles from './SelectDropdownV1.module.scss';
import { ReactComponent as ArrowDownSVG } from '../../assets/img/icons/dropdown-purple-triangle.svg';
import cs from 'classnames';
import { useClickOutside } from '../../shared/hooks/useClickOutside';

type PropsType = {
  className?: string;
  dropDownClassName?: string;
  options: any[];
  placeholder?: string;
  optionKey?: (item: any, index: number) => number | string;
  renderOption: (item: any) => number | string;
  renderDropdownFooter?: () => ReactElement;
  onSelect: (item: any,option: any) => void;
  selectedId: number;
  disabled?: boolean;
  error?: boolean;
};

const SelectDropdownV1 = (props: PropsType) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useClickOutside(containerRef, null, () => setIsDropdownOpened(false));

  const isDisplayPlaceholder = useMemo(() => {
    return props.selectedId === null || props.selectedId === -1 || !props.options.length;
  }, [props.selectedId, props.options]);

  const onSelect = useCallback(
    (event, index, option: any) => {
      event.stopPropagation();
      if (props.selectedId !== index) {
        props.onSelect(index, option);
        setIsDropdownOpened(false);
      }
    },
    [props.selectedId, props.onSelect]
  );

  return (
    <div className={cs(styles.root, props.className)} ref={containerRef}>
      <div
        className={cs(styles.input, { [styles.active]: isDropdownOpened, [styles.error]: props.error }, { [styles.disabled]: props.disabled })}
        onClick={() => {
          if (props.disabled) return;
          setIsDropdownOpened(!isDropdownOpened);
        }}
      >
        <div className={styles.selectedValue}>
          {isDisplayPlaceholder ? (
            <span className={cs(styles.placeholder, {[styles.error]: props.error})}>{props.placeholder || 'None'}</span>
          ) : (
            props.renderOption && props.renderOption(props.options[props.selectedId])
          )}
        </div>
        <div className={cs(styles.iconContainer, { [styles.active]: isDropdownOpened })}>
          <ArrowDownSVG />
        </div>
      </div>

      {isDropdownOpened && (
        <div className={cs(styles.dropdown, props.dropDownClassName)}>
          {props.options.map((option, index) => (
            <div
              key={props.optionKey ? props.optionKey(option, index) : index}
              className={cs(styles.selectItem, { [styles.selected]: index === props.selectedId })}
              onClick={e => onSelect(e, index, option)}
            >
              {props.renderOption && props.renderOption(option)}
            </div>
          ))}
          {props.renderDropdownFooter && props.renderDropdownFooter()}
        </div>
      )}
    </div>
  );
};

export default SelectDropdownV1;
