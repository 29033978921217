import React, { FC } from 'react';
import styles from './Tooltip.module.scss';
import cs from 'classnames';
import { TOOLTIP_POSITIONS } from '../useTooltip';

type PropsType = {
  text?: string;
  top: number;
  left: number;
  transformStyle: TOOLTIP_POSITIONS | null;
  className?: string;
};

const Tooltip: FC<PropsType> = ({ text, top, left, transformStyle, className }) => {
  return (
    <div
      className={cs(styles.tooltip, styles[transformStyle || ''], className)}
      // @ts-ignore
      style={{ '--data-tooltip-top': `${top}px`, '--data-tooltip-left': `${left}px` }}
    >
      {text}
    </div>
  );
};

export default Tooltip;
