import React, { FC, forwardRef, SVGProps, useMemo } from 'react';
import PlusSVG from '../../../expert/components/IconsSvg/PlusSVG';
import { ReactComponent as EmptyStar } from '../../../assets/img/icons/emptyStar.svg';
import { ReactComponent as UploadSVG } from '../../../assets/img/icons/upload.svg';
import { ReactComponent as Plus2SVG } from '../../../assets/img/icons/plusInCircle.svg';
import { ReactComponent as DownloadSVG } from '../../../assets/img/icons/download.svg';
import styles from './WhiteBorderedButton.module.scss';
import cs from 'classnames';

const presetIcons = {
  plus: {
    icon: PlusSVG,
    style: styles.plusIcon,
  },
  emptyStar: {
    icon: EmptyStar,
    style: styles.emptyStar,
  },
  upload: {
    icon: UploadSVG,
    style: styles.uploadIcon,
  },
  plus2: {
    icon: Plus2SVG,
    style: styles.plus2Icon,
  },
  download: {
    icon: DownloadSVG,
    style: styles.downloadIcon,
  },
};

type PropsType = {
  className?: string;
  withDisabledClass?: boolean;
  LeftIcon?: FC<{ size?: number } & unknown> | FC<SVGProps<SVGSVGElement>> | null;
  RightIcon?: FC<{ size?: number } & unknown> | FC<SVGProps<SVGSVGElement>> | null;
  presetIconLeft?: keyof typeof presetIcons;
  presetIconRight?: keyof typeof presetIcons;
  designVersion: 'white' | 'transparent';
  size?: 'large' | 'medium' | 'small';
} & Omit<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'className'>;

const BorderedButton = forwardRef<HTMLButtonElement | null, PropsType>(
  (
    { size = 'medium', designVersion, LeftIcon, presetIconLeft, presetIconRight, RightIcon, className, withDisabledClass, ...buttonProps },
    ref
  ) => {
    const LeftIconComponent = useMemo(() => {
      if (LeftIcon) return LeftIcon;
      const leftIconFromPreset = presetIconLeft ? presetIcons[presetIconLeft] : null;
      if (leftIconFromPreset) return leftIconFromPreset.icon;
      return null;
    }, [presetIconLeft, LeftIcon]);

    const RightIconComponent = useMemo(() => {
      if (RightIcon) return RightIcon;
      const rightIconFromPreset = presetIconRight ? presetIcons[presetIconRight] : null;
      if (rightIconFromPreset) return rightIconFromPreset.icon;
      return null;
    }, [presetIconRight, RightIcon]);

    return (
      <button
        {...buttonProps}
        className={cs(styles.root, styles[designVersion], styles[size], className, {
          [styles.square]: !buttonProps.children,
          [styles.disabled]: withDisabledClass || buttonProps.disabled,
        })}
        ref={ref}
      >
        {LeftIconComponent ? (
          <span className={cs(styles.iconContainer, presetIconLeft ? presetIcons[presetIconLeft].style : null)}>
            <LeftIconComponent />
          </span>
        ) : null}
        {buttonProps.children ? <span className={styles.textContainer}>{buttonProps.children}</span> : null}
        {RightIconComponent ? (
          <span className={cs(styles.iconContainer, presetIconRight ? presetIcons[presetIconRight].style : null)}>
            <RightIconComponent />
          </span>
        ) : null}
      </button>
    );
  }
);

export default BorderedButton;
