export enum UserPermission {
  CAN_SEE_DISCUSSION = 'CAN_SEE_DISCUSSION',
  CAN_SEE_HEADER_CONTENT_BOARD_DELIVERABLE_BREADCRUMBS = 'CAN_SEE_HEADER_CONTENT_BOARD_DELIVERABLE_BREADCRUMBS',
  CAN_SEE_LEFT_ROOT_MENU = 'CAN_SEE_LEFT_ROOT_MENU',
  CAN_SEE_TOP_MENU_USER_ACTIONS = 'CAN_SEE_TOP_MENU_USER_ACTIONS',
  CAN_SEE_HEADER_SIGN_UP_BUTTON = 'CAN_SEE_HEADER_SIGN_UP_BUTTON',
  CAN_SEE_HEADER_DASHBOARD_BUTTON = 'CAN_SEE_HEADER_DASHBOARD_BUTTON',
  CAN_SEE_HEADER_DESKTOP_FULL_LOGO = 'CAN_SEE_HEADER_DESKTOP_FULL_LOGO',
  CAN_GET_BOARD_DATA = 'CAN_GET_BOARD_DATA',
  CAN_GET_BOARD_COLLABORATORS_DATA = 'CAN_GET_BOARD_COLLABORATORS_DATA',
  CAN_GET_BOARD_INVITED_COLLABORATORS_DATA = 'CAN_GET_BOARD_INVITED_COLLABORATORS_DATA',
  CAN_GET_ORGANIZATIONS_DATA = 'CAN_GET_ORGANIZATIONS_DATA',
  CAN_GET_BOARD_DELIVERABLES_DATA = 'CAN_GET_BOARD_DELIVERABLES_DATA',
  CAN_GET_ALL_BOARD_DELIVERABLES_ASSETS = 'CAN_GET_ALL_BOARD_DELIVERABLES_ASSETS',
  CAN_GET_DELIVERABLE_ITEM_DATA = 'CAN_GET_DELIVERABLE_ITEM_DATA',
  CAN_GET_DELIVERABLE_ITEM_SECTION_DATA = 'CAN_GET_DELIVERABLE_ITEM_SECTION_DATA',
  CAN_SEE_BOARD_INFO_BANNER = 'CAN_SEE_BOARD_INFO_BANNER',
  CAN_SEE_HEADER = 'CAN_SEE_HEADER',
  CAN_SEE_PURPLE_BANNER = 'CAN_SEE_PURPLE_BANNER',
  CAN_SEE_CONTENT_BOARD_HEADER = 'CAN_SEE_CONTENT_BOARD_HEADER',
}
