import React, { FC, memo } from 'react';
import { FormikProps } from 'formik';
import { areEqualFormikState } from '../../../../../../../../shared/utils/typescriptHelpers';
import CheckboxButton from '../../../../../../../CampaignBrief/components/CheckboxButton';
import styles from '../TabBriefDetails.module.scss';

type PropsType = {
  formik: FormikProps<any>;
};

const propsAreEqual: Parameters<typeof memo<FC<PropsType>>>[1] = (prevProps, nextProps) => {
  return areEqualFormikState(['adInCaption'], prevProps.formik, nextProps.formik);
};

const SubTabPrefferedAdDeclaration = memo(({ formik }: PropsType) => {
  return (
    <div>
      <h2>Preferred Ad Declaration</h2>
      <CheckboxButton
        onClick={() => formik.setFieldValue('adInCaption', false)}
        isActive={!formik.values.adInCaption}
        item={'Paid Partnership tool'}
        paragraph={'Paid partnership tag appears at the top of the content your content creator post'}
        className={styles.checkboxHeading}
      />

      <CheckboxButton
        onClick={() => formik.setFieldValue('adInCaption', true)}
        isActive={formik.values.adInCaption}
        item={'Ad in Caption'}
        paragraph={'#AD included in post copy and on static or video asset to adhere to requirements.'}
        className={styles.checkboxHeading}
      />
    </div>
  );
}, propsAreEqual);

export default SubTabPrefferedAdDeclaration;
