import React, { FC, useRef } from 'react';
import styles from '../Statuses.module.scss';
import cs from 'classnames';
import useTooltip, { TOOLTIP_POSITIONS } from '../../../../../../../../../shared/hooks/fixedTooltip/useTooltip';
import { DELIVERABLE_STATUSES } from '../../../../../../../../../models/enums/DeliverableStatuses';

type PropsType = {
  statusText?: string;
  status: DELIVERABLE_STATUSES;
  withTooltipText?: string | null;
};

const DefaultStatusTile: FC<PropsType> = ({ statusText, status, withTooltipText }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { toolTip, onEnter, onLeave } = useTooltip<HTMLDivElement>(TOOLTIP_POSITIONS.BOTTOM, {
    text: withTooltipText || '',
    className: styles.tooltip,
    wrapperRef,
  });

  return (
    <div ref={wrapperRef} className={cs(styles.defaultStatusTile, styles[status])} onMouseEnter={onEnter} onMouseLeave={onLeave}>
      <span>{statusText}</span>
      {withTooltipText && toolTip}
    </div>
  );
};

export default DefaultStatusTile;
