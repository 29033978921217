import * as contentApprovalServices from '../../services/contentApproval';
import { createNotification } from '../toastNotificationActions/toastNotification.actions';
import { isEqual } from '../../pages/ContentApproval/DeliverableItem/helpers';
import notificationDataConfig from '../../shared/utils/notificationDataConfig';
import { IDeliverable } from '../../models/entities/deliverable/Deliverable';
import { DELIVERABLE_SECTION_TYPES } from '../../models/enums/DeliverableSectionTypes';
import { DELIVERABLE_CALL_TO_ACTIONS } from '../../models/enums/deliverableCallToActions';
import { AssetStateType } from '../../models/entities/asset/AssetState';
import { ICreatedAsset } from '../../models/entities/asset/createdAsset';
import {
  ClearDeliverablesErrorsActionType,
  DELIVERABLES_ACTION_TYPES,
  DeliverablesActionsTypes,
  DeliverableSyncThunkReturnType,
  DeliverableThunkReturnType,
  UpdateDeliverableItemDataType,
} from './types';
import { SharedRequestsProxyService } from '../../models/permissions/services/SharedRequestsService';
import { DeliverablePermission } from '../../models/permissions/enum/DeliverablePermission';
import { DeliverableItemPage } from '../../models/permissions/pages/DeliverableItemPage';
import { Page } from '../../models/permissions/pages/Page';
import { TOAST_NOTIFICATION_TYPES } from '../../shared/constants/toastNotificationsData';
import { RootState } from '../../store';
import { DeliverablesReducerState } from '../../reducers/deliverables.reducer';
import { ASSET_STATUSES } from '../../models/entities/asset/enums/AssetStatuses';
import { ThunkDispatch } from 'redux-thunk/src/types';
import { AxiosError } from 'axios';
import { IContentSection } from '../../models/entities/deliverableSections/ContentSection';
import { IConceptSection } from '../../models/entities/deliverableSections/ConceptSection';
import TypedServerError from '../../models/entities/error/TypedServerError';
import CustomError from '../../models/entities/error/CustomError';
import ErrorHandler from '../../models/entities/error/ErrorHandler';

const getDeliverableActionTypeFabric = (
  start: string,
  type: DELIVERABLE_SECTION_TYPES
): ((state: 'REQUEST' | 'FAILURE' | 'SUCCESS') => DELIVERABLES_ACTION_TYPES) => {
  return (state: 'REQUEST' | 'FAILURE' | 'SUCCESS') => {
    return DELIVERABLES_ACTION_TYPES[`${start}_${type.toUpperCase()}_${state}`] as DELIVERABLES_ACTION_TYPES;
  };
};

export const clearDeliverablesErrors = (): ClearDeliverablesErrorsActionType => ({ type: DELIVERABLES_ACTION_TYPES.CLEAR_ERRORS });

export const getBoardDeliverableItem =
  (boardId: number | string, deliverableId: number | string): DeliverableThunkReturnType =>
  async (dispatch, getState) => {
    const userModel = getState().auth.userModel;
    const getBoardDeliverableItem = new SharedRequestsProxyService(userModel).getRequestFunction('getBoardDeliverableItem');
    const getBoardDeliverableItemSection = new SharedRequestsProxyService(userModel).getRequestFunction('getBoardDeliverableItemSection');
    if (!getBoardDeliverableItem || !getBoardDeliverableItemSection) {
      return;
    }

    try {
      dispatch({ type: DELIVERABLES_ACTION_TYPES.GET_BOARD_DELIVERABLE_ITEM_REQUEST });

      const deliverableResponse = await getBoardDeliverableItem(+boardId, +deliverableId);
      const conceptResponse = await getBoardDeliverableItemSection(+boardId, +deliverableId, DELIVERABLE_SECTION_TYPES.CONCEPT);
      const contentResponse = await getBoardDeliverableItemSection(+boardId, +deliverableId, DELIVERABLE_SECTION_TYPES.CONTENT);

      dispatch({
        type: DELIVERABLES_ACTION_TYPES.GET_BOARD_DELIVERABLE_ITEM_SUCCESS,
        payload: {
          deliverable: deliverableResponse.data[0],
          concept: conceptResponse?.data?.[0] || null,
          content: contentResponse?.data?.[0] || null,
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        dispatch({ type: DELIVERABLES_ACTION_TYPES.GET_BOARD_DELIVERABLE_ITEM_FAILURE, payload: e.message });
      }
    }
  };

export interface ISaveDeliverableSectionData {
  section: {
    isChanged: boolean;
    payload: {
      type: DELIVERABLE_SECTION_TYPES;
      description?: string | null;
      call_to_action?: DELIVERABLE_CALL_TO_ACTIONS[] | null;
    };
  };
  assets?: { current?: AssetStateType[]; create?: ICreatedAsset[]; update?: AssetStateType[]; delete?: AssetStateType[] };
}

// DELIVERABLE SAVE FUNCTIONS
const createServerErrorNotification = (message: [string]) => {
  return notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.ERROR_ON_SAVE_DELIVERABLE_ASSET, {
    text: message,
  });
};

const allSettledHandler =
  (results: PromiseSettledResult<any>[], onEndCallback: () => void, settings) =>
  async (dispatch: ThunkDispatch<RootState, null, DeliverablesActionsTypes>) => {
    for (const result of results) {
      if (result.status === 'rejected') {
        if (result.reason instanceof TypedServerError) {
          dispatch({ type: settings.getActionType('FAILURE'), payload: result.reason || null, loading: false });
          dispatch(getBoardDeliverableItem(settings.boardId, settings.deliverableId));
          return;
        }

        const notification = createServerErrorNotification(result.reason.response.data.message);
        dispatch(createNotification(notification));
      } else if ('value' in result && result.value instanceof Error) {
        const notification = createServerErrorNotification([result.value.message]);
        dispatch(createNotification(notification));
      }
    }
    onEndCallback();
  };

const createDeliverableSectionCatch =
  (dispatch: ThunkDispatch<RootState, null, DeliverablesActionsTypes>, getActionType: ReturnType<typeof getDeliverableActionTypeFabric>) =>
  (e: AxiosError) => {
    const notification = createServerErrorNotification(e.response?.data.message);
    dispatch(createNotification(notification));
    // @ts-ignore
    dispatch({ type: getActionType('FAILURE'), payload: e.response?.data.message || null, loading: false });
  };

const isEqualVersions = (
  existingVersion: IContentSection | IConceptSection | null | undefined,
  latestVersion: IContentSection | IConceptSection
) => {
  const isVersionAlreadyExists = !existingVersion && latestVersion;
  const isVersionNotEqualLatestVersion = !!existingVersion && existingVersion.id !== latestVersion.id;
  const isVersionsAreDifferent =
    !!existingVersion &&
    !!latestVersion &&
    !isEqual(existingVersion, latestVersion, ['created_at', 'updated_at'], {
      assets: ['created_at', 'updated_at', 'status', 'preview_file_id', 'preview_location'],
    });
  return !(isVersionAlreadyExists || isVersionNotEqualLatestVersion || isVersionsAreDifferent);
};

export const saveDeliverableSection =
  (
    organizationId: number | string,
    boardId: number | string,
    deliverableId: number | string,
    data: ISaveDeliverableSectionData
  ): DeliverableThunkReturnType =>
  async (dispatch, getState) => {
    const { section, assets } = data;

    const state = getState();
    const deliverablesState: DeliverablesReducerState = state.deliverables;
    const userModel = state.auth.userModel;
    const pageModel = deliverablesState.pageModel;
    const existingVersion =
      deliverablesState.openedDeliverable?.[
        section.payload.type === DELIVERABLE_SECTION_TYPES.CONTENT ? DELIVERABLE_SECTION_TYPES.CONTENT : DELIVERABLE_SECTION_TYPES.CONCEPT
      ];
    const getBoardDeliverableItemSection = new SharedRequestsProxyService(userModel).getRequestFunction('getBoardDeliverableItemSection');
    if (!getBoardDeliverableItemSection) {
      return;
    }

    const getActionType = getDeliverableActionTypeFabric('UPDATE_DELIVERABLE', section.payload.type);
    // @ts-ignore
    dispatch({ type: getActionType('REQUEST') });

    const successSaveEnd = (versionId: string | number) => {
      // @ts-ignore
      dispatch({ type: getActionType('SUCCESS') });
      const notification = notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.SAVE_CHANGES, {});
      dispatch(createNotification(notification));
      dispatch(getBoardDeliverableItem(boardId, deliverableId));
      contentApprovalServices.sendEditNotification(organizationId, boardId, deliverableId, versionId);
    };
    const finallyHandler = versionId => {
      if (pageModel?.roleCan(DeliverablePermission.AUTO_SHARE_SAVED_DELIVERABLE_SECTION)) {
        contentApprovalServices
          .shareDeliverableSection(organizationId, boardId, deliverableId, versionId)
          .then(() => successSaveEnd(versionId));
        return;
      }
      successSaveEnd(versionId);
    };

    try {
      getBoardDeliverableItemSection(boardId, deliverableId, section.payload.type).then(response => {
        const latestVersion: IContentSection | IConceptSection = response.data[0];

        if (!isEqualVersions(existingVersion, latestVersion)) {
          const notification = notificationDataConfig.getNotificationData(
            TOAST_NOTIFICATION_TYPES.DELIVERABLE_VERSION_ALREADY_CHANGED_ERROR,
            {}
          );
          dispatch(createNotification(notification));
          // @ts-ignore
          dispatch({ type: getActionType('FAILURE'), loading: false });
          return;
        }

        const createNewVersionCase = () => {
          contentApprovalServices
            .createDeliverableSection(organizationId, boardId, deliverableId, section.payload)
            .then(response => {
              const newVersion = response.data[0];
              if (!assets) return finallyHandler(newVersion.id);
              const requests: Promise<any>[] = [];
              if (assets.create) {
                requests.push(
                  contentApprovalServices.addDeliverableAssets(organizationId, boardId, deliverableId, newVersion.id, assets.create)
                );
              }
              Promise.allSettled(requests).then(results =>
                allSettledHandler(results, () => finallyHandler(newVersion.id), {
                  getActionType,
                  boardId,
                  deliverableId,
                })(dispatch)
              );
            })
            .catch(createDeliverableSectionCatch(dispatch, getActionType));
        };

        const updateAndCreateNewVersionCase = () => {
          contentApprovalServices
            .createDeliverableSection(organizationId, boardId, deliverableId, section.payload)
            .then(response => {
              const newVersion = response.data[0];
              if (!assets) return finallyHandler(newVersion.id);

              const newAssets = newVersion.assets;
              const requests: Promise<any>[] = [];

              if (assets.create) {
                requests.push(
                  contentApprovalServices.addDeliverableAssets(organizationId, boardId, deliverableId, newVersion.id, assets.create)
                );
              }

              if (assets.update) {
                for (const asset of assets.update) {
                  const newId = newAssets?.find(a => a.old_asset_id === asset.id)?.id;
                  requests.push(
                    contentApprovalServices.updateDeliverableAsset(
                      organizationId,
                      boardId,
                      deliverableId,
                      newVersion.id,
                      newId ?? asset.id,
                      {
                        caption: asset.caption || null,
                        file: 'file' in asset ? asset.file : null,
                        display_order: asset.display_order ?? null,
                      }
                    )
                  );
                }
              }

              if (assets.delete) {
                for (const asset of assets.delete) {
                  const newId = newAssets?.find(a => a.old_asset_id === asset.id)?.id;
                  requests.push(
                    contentApprovalServices.deleteDeliverableAsset(organizationId, boardId, deliverableId, newVersion.id, newId ?? asset.id)
                  );
                }
              }
              Promise.allSettled(requests).then(results =>
                allSettledHandler(results, () => finallyHandler(newVersion.id), {
                  getActionType,
                  boardId,
                  deliverableId,
                })(dispatch)
              );
            })
            .catch(createDeliverableSectionCatch(dispatch, getActionType));
        };

        const updateVersionCase = () => {
          const requests: Promise<any>[] = [];

          if (!existingVersion) return;

          if (section.isChanged) {
            requests.push(
              contentApprovalServices.updateDeliverableSection(organizationId, boardId, deliverableId, existingVersion.id, section.payload)
            );
          }

          if (assets) {
            if (assets.create) {
              requests.push(
                contentApprovalServices.addDeliverableAssets(organizationId, boardId, deliverableId, existingVersion.id, assets.create)
              );
            }

            if (assets.update) {
              for (const asset of assets.update) {
                requests.push(
                  contentApprovalServices.updateDeliverableAsset(organizationId, boardId, deliverableId, existingVersion.id, asset.id, {
                    caption: asset.caption || null,
                    file: 'file' in asset ? asset?.file : null,
                    display_order: asset.display_order ?? null,
                  })
                );
              }
            }

            if (assets.delete) {
              for (const asset of assets.delete) {
                requests.push(
                  contentApprovalServices.deleteDeliverableAsset(organizationId, boardId, deliverableId, existingVersion.id, asset.id)
                );
              }
            }
          }

          Promise.allSettled(requests).then(results =>
            allSettledHandler(results, () => finallyHandler(existingVersion.id), {
              getActionType,
              boardId,
              deliverableId,
            })(dispatch)
          );
        };

        if (!existingVersion) {
          createNewVersionCase();
        } else {
          if (existingVersion.is_shared) {
            updateAndCreateNewVersionCase();
          } else {
            updateVersionCase();
          }
        }
      });
    } catch (e: any) {
      const error = e instanceof CustomError ? e : new ErrorHandler(e).getError();
      const notification = notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.ERROR_ON_SAVE_DELIVERABLE_ASSET, {
        text: [error.getMessage()],
      });
      dispatch(createNotification(notification));
      // @ts-ignore
      dispatch({ type: getActionType('FAILURE'), payload: error.getMessage() || null, loading: false });
      dispatch(getBoardDeliverableItem(boardId, deliverableId));
    }
  };

export const deleteDeliverableSection =
  (
    organizationId: string | number,
    boardId: string | number,
    deliverableId: string | number,
    type: DELIVERABLE_SECTION_TYPES
  ): DeliverableThunkReturnType =>
  async dispatch => {
    const getActionType = getDeliverableActionTypeFabric('UPDATE_DELIVERABLE', type);
    // @ts-ignore
    dispatch({ type: getActionType('REQUEST') });

    try {
      contentApprovalServices.deleteDeliverableSection(organizationId, boardId, deliverableId, type).then(() => {
        // @ts-ignore
        dispatch({ type: getActionType('SUCCESS') });
        const notification = notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.REMOVE_DELIVERABLE_SECTION, {});
        dispatch(createNotification(notification));
        dispatch(getBoardDeliverableItem(boardId, deliverableId));
      });
    } catch (e) {
      if (e instanceof Error) {
        // @ts-ignore
        dispatch({ type: getActionType('FAILURE'), payload: e.message, loading: false });
      }
      dispatch(getBoardDeliverableItem(boardId, deliverableId));
    }
  };

export const approveDeliverableSection =
  (
    organizationId: number | string,
    boardId: number | string,
    deliverableId: number | string,
    conceptVersionId: number | string
  ): DeliverableThunkReturnType =>
  async dispatch => {
    dispatch({ type: DELIVERABLES_ACTION_TYPES.APPROVE_DELIVERABLE_REQUEST });
    try {
      await contentApprovalServices.approveDeliverableSection(organizationId, boardId, deliverableId, conceptVersionId);
      dispatch(getBoardDeliverableItem(boardId, deliverableId));
      dispatch({ type: DELIVERABLES_ACTION_TYPES.APPROVE_DELIVERABLE_SUCCESS });
    } catch (e) {
      if (e instanceof Error) {
        dispatch({ type: DELIVERABLES_ACTION_TYPES.APPROVE_DELIVERABLE_FAILURE, payload: 'Approve failed.' + ' ' + e.message });
      }
    }
  };

export const shareDeliverableSection =
  (
    organizationId: number | string,
    boardId: number | string,
    deliverableId: number | string,
    conceptVersionId: number | string
  ): DeliverableThunkReturnType =>
  async dispatch => {
    dispatch({ type: DELIVERABLES_ACTION_TYPES.APPROVE_DELIVERABLE_REQUEST });
    try {
      await contentApprovalServices.shareDeliverableSection(organizationId, boardId, deliverableId, conceptVersionId);
      dispatch(getBoardDeliverableItem(boardId, deliverableId));
      dispatch({ type: DELIVERABLES_ACTION_TYPES.APPROVE_DELIVERABLE_SUCCESS });
    } catch (e) {
      if (e instanceof Error) {
        dispatch({ type: DELIVERABLES_ACTION_TYPES.APPROVE_DELIVERABLE_FAILURE, payload: 'Share failed.' + ' ' + e.message });
      }
    }
  };

export const shareAndApproveDeliverableSection =
  (
    organizationId: number | string,
    boardId: number | string,
    deliverableId: number | string,
    sectionVersionId: number | string
  ): DeliverableThunkReturnType =>
  async dispatch => {
    dispatch({ type: DELIVERABLES_ACTION_TYPES.APPROVE_DELIVERABLE_REQUEST });
    try {
      await contentApprovalServices.shareDeliverableSection(organizationId, boardId, deliverableId, sectionVersionId);
      await contentApprovalServices.approveDeliverableSection(organizationId, boardId, deliverableId, sectionVersionId);
      dispatch(getBoardDeliverableItem(boardId, deliverableId));
      dispatch({ type: DELIVERABLES_ACTION_TYPES.APPROVE_DELIVERABLE_SUCCESS });
    } catch (e) {
      if (e instanceof Error) {
        dispatch({ type: DELIVERABLES_ACTION_TYPES.APPROVE_DELIVERABLE_FAILURE, payload: 'Approve failed.' + ' ' + e.message });
      }
    }
  };

export const updateVersionAssets =
  (
    organizationId: string | number,
    boardId: string | number,
    deliverableId: string | number,
    versionId: string | number,
    type: DELIVERABLE_SECTION_TYPES,
    updatedAssetId: string | number | null
  ): DeliverableThunkReturnType =>
  async (dispatch, getState) => {
    try {
      const assets = getState().deliverables.openedDeliverable?.[type]?.assets;
      if (!assets) return;
      if (!updatedAssetId) return;

      const response = await contentApprovalServices.getBoardDeliverableItemSectionAsset(
        organizationId,
        boardId,
        deliverableId,
        versionId,
        updatedAssetId
      );

      if (!response.data[0]) {
        return;
      }

      const newAsset = { ...response.data[0] };
      const isUpdateAsset = assets.some(
        currentAsset => currentAsset.status === ASSET_STATUSES.PROCESSING && newAsset.id === currentAsset.id
      );

      if (isUpdateAsset) {
        if ('content_board_deliverable_id' in newAsset) delete newAsset.content_board_deliverable_id;
        if ('creator_id' in newAsset) delete newAsset.creator_id;
        newAsset.size = +newAsset.size;
        if ('updated_at' in newAsset) delete newAsset.updated_at;
        if ('updater_id' in newAsset) delete newAsset.updater_id;
        newAsset.type = type;

        dispatch({ type: DELIVERABLES_ACTION_TYPES.UPDATE_DELIVERABLE_SECTION_ASSETS, payload: { asset: newAsset, type } });
      }
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        const notification = notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.ERROR_ON_SAVE_DELIVERABLE_ASSET, {
          text: [e.message],
        });
        dispatch(createNotification(notification));
      }
    }
  };

export const updateDeliverableItem =
  (
    organizationId: string | number,
    boardId: string | number,
    deliverableId: string | number,
    data: UpdateDeliverableItemDataType
  ): DeliverableThunkReturnType =>
  async (dispatch, getState, extra) => {
    dispatch({ type: DELIVERABLES_ACTION_TYPES.UPDATE_DELIVERABLE_ITEM_REQUEST });

    contentApprovalServices
      .updateDeliverableItem(organizationId, boardId, deliverableId, data)
      .then(response => {
        dispatch({ type: DELIVERABLES_ACTION_TYPES.UPDATE_DELIVERABLE_ITEM_SUCCESS, payload: data });

        const notification = notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.SAVE_CHANGES, {});
        dispatch(createNotification(notification));

        updateOpenedDeliverable(response.data.data[0])(dispatch, getState, extra);
      })
      .catch(error => {
        if (error.response) {
          dispatch({
            type: DELIVERABLES_ACTION_TYPES.UPDATE_DELIVERABLE_ITEM_FAILURE,
            payload: error.response?.data?.errors?.body[0]?.message,
          });
        }
      });
  };

export const clearOpenedDeliverableItem = (): DeliverablesActionsTypes => ({
  type: DELIVERABLES_ACTION_TYPES.CLEAR_OPENED_DELIVERABLE_ITEM,
});

export const clearAssetsToUpdate = (): DeliverablesActionsTypes => ({ type: DELIVERABLES_ACTION_TYPES.CLEAR_ASSETS_TO_UPDATE });

export const updateOpenedDeliverable =
  (deliverable: IDeliverable): DeliverableSyncThunkReturnType =>
  (dispatch, getState) => {
    const oldDeliverable = getState && getState().deliverables?.openedDeliverable?.deliverable;

    if (oldDeliverable && deliverable) {
      const newDeliverable = {
        ...deliverable,
        status_content: oldDeliverable.status_content,
        status_concept: oldDeliverable.status_concept,
      };
      dispatch({ type: DELIVERABLES_ACTION_TYPES.UPDATE_OPENED_DELIVERABLE_ITEM, payload: newDeliverable });
    }
  };

export const setDeliverablePageModel = (page: DeliverableItemPage | Page<DeliverablePermission>): DeliverablesActionsTypes => ({
  type: DELIVERABLES_ACTION_TYPES.SET_DELIVERABLE_PAGE_MODEL,
  payload: page,
});
