import React, { FC } from 'react';
import styles from './DeliverableAssetModal.module.scss';
import { Modal } from '../../../../../../../components/Modals';
import TrashSVG from '../../../../../../../components/IconsSvg/TrashSVG';
import DownloadSVG from '../../../../../../../expert/components/IconsSvg/DownloadSVG';
import cs from 'classnames';
import SmallLoader from '../../../../../../../components/SmallLoader';
import { formatBytes } from '../../../../../../../shared/utils/helpers';
import dayjs from 'dayjs';
import MobileModalAssetPreview from '../MobileModalAssetPreview';
import DesktopModalAssetPreview from '../DesktopModalAssetPreview';
import { IAssetMedia } from '../../../../../../../models/entities/asset/AssetMedia';
import { AssetStateType } from '../../../../../../../models/entities/asset/AssetState';
import { useAppSelector } from '../../../../../../../shared/hooks/reduxHooks';
import { DeliverablePermission } from '../../../../../../../models/permissions/enum/DeliverablePermission';
import {IResultAsset} from "../../../../../../../models/entities/asset/ResultAsset";

type ModalContentProps = {
  mediaAssets: IAssetMedia[];
  onDelete: () => void;
  isDisabledDelete: boolean;
  isMobile: boolean;
  isAssetLoading: boolean;
  onDownloadAsset: () => void;
  setOpenedAssetId: (assetId: AssetStateType['id']) => void;
  openedAsset: AssetStateType | IResultAsset;
  openedMediaAsset: IAssetMedia | null;
  openedAssetIndex: number;
  canDownload: boolean;
};

const ModalContent: FC<ModalContentProps> = ({
  mediaAssets,
  onDelete,
  isDisabledDelete,
  isMobile,
  isAssetLoading,
  onDownloadAsset,
  setOpenedAssetId,
  openedAsset,
  openedAssetIndex,
  openedMediaAsset,
  canDownload,
}) => {
  return (
    <div className={styles.modalContentWrapper}>
      {isMobile && <div className={styles.topBlur} />}
      <div className={styles.assetWrapper}>
        {isMobile ? (
          <MobileModalAssetPreview openedSlideIndex={openedAssetIndex} assets={mediaAssets} setOpenedAssetId={setOpenedAssetId} />
        ) : (
          <DesktopModalAssetPreview asset={openedMediaAsset} />
        )}
      </div>
      <div className={styles.modalFooter}>
        <div className={styles.fileInfo}>
          <div className={styles.infoRow}>
            {openedAsset?.filename && <div className={styles.fileName}>{openedAsset?.filename}</div>}
            <ul>
              {openedAsset?.extension && <li>{openedAsset?.extension.toUpperCase()}</li>}
              {openedAsset?.size && <li>{formatBytes(openedAsset?.size, 0)}</li>}
            </ul>
          </div>
          <div className={styles.infoRow}>
            {openedAsset?.created_at && (
              <div>
                <span>Added:</span>
                {dayjs(openedAsset?.created_at).format('DD:MM:YYYY HH:mm')}
              </div>
            )}
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          {!isDisabledDelete && (
            <div className={cs(styles.button, styles.deleteButton)} onClick={onDelete}>
              <TrashSVG />
              {!isMobile && 'Delete visual'}
            </div>
          )}
          {openedAsset.extension && canDownload && (
            <div className={cs(styles.button, styles.downloadButton)} onClick={onDownloadAsset}>
              {/* @ts-ignore */}
              {isAssetLoading ? <SmallLoader /> : <DownloadSVG />}
              {!isMobile && 'Download'}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const DeliverableAssetModal = props => {
  return (
    <Modal maxWidth={'1270px'} maxHeigt={'730px'} className={styles.modalRoot} {...props}>
      <ModalContent {...props} />
    </Modal>
  );
};

export default DeliverableAssetModal;
