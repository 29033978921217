import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import styles from './AddDeliverablesPlatform.module.scss';
import CheckBox from '../../../../../components/CheckBox';
import AddDeliverablesPlacement from '../AddDeliverablesPlacement';
import cs from 'classnames';
import {
  DeliverableFormikStateType,
  DeliverablesFormSettingsDataType,
  UseDeliverablesFormHandlersType,
} from '../../hooks/useDeliverablesFormData';
import { FormikProps } from 'formik';

type PropsType = {
  deliverablesFormSettings: DeliverablesFormSettingsDataType;
  handlers: UseDeliverablesFormHandlersType;
  formik: FormikProps<DeliverableFormikStateType>;
  platformName: keyof DeliverablesFormSettingsDataType;
  platformSVGs: FC[];
  canEditDueDates: boolean;
  canEditNotifications: boolean;
};

const AddDeliverablesPlatform: FC<PropsType> = memo(
  ({ formik, platformName, platformSVGs, handlers, deliverablesFormSettings, canEditDueDates, canEditNotifications }) => {
    const [isOpen, setIsOpen] = useState(false);

    // const isDeliverablesLengthWithCurrentPlatform = useMemo(() => {
    //   return deliverables.some(deliverable => deliverable.platform === platformName);
    // }, [platformName, deliverables]);

    useEffect(() => {
      if (formik.values.deliverables.some(deliverable => deliverable.platform === platformName)) {
        setIsOpen(true);
      }
    }, []);

    const onToggleCheckbox = () => {
      if (isOpen) {
        setIsOpen(false);
        handlers.deleteAllPlatformDeliverables(platformName);
        return;
      }

      setIsOpen(true);
    };

    const nameView = useMemo(() => (platformName[0].toUpperCase() + platformName.substring(1)).replace('_', ' '), [platformName]);

    return (
      <div className={cs({ [styles.root]: !isOpen, [styles.activeRoot]: isOpen })}>
        <h2 className={styles.socialHeading}>
          <div className={styles.clickArea} onClick={onToggleCheckbox} />
          <CheckBox isActive={isOpen} />
          <div className={styles.socialIconWrapper}>
            {platformSVGs.map((SVG, index) => (
              <SVG key={index} />
            ))}
          </div>
          <span>{nameView}</span>
        </h2>
        {isOpen && (
          <div className={styles.placementsList}>
            {deliverablesFormSettings[platformName].map(placementsName => {
              return (
                <AddDeliverablesPlacement
                  canEditNotifications={canEditNotifications}
                  canEditDueDates={canEditDueDates}
                  key={placementsName}
                  placementsName={placementsName}
                  platformName={platformName}
                  handlers={handlers}
                  formik={formik}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  }
);

export default AddDeliverablesPlatform;
