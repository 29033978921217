import React, { FC, memo, useMemo } from 'react';
import BriefTextCard from '../../components/BriefTextCard';
import TextRow from '../../components/TextRow';
import TagsList from '../../components/TagsList';
import { IViewBriefData } from '../../../../../../../models/entities/boardBrief/ViewBrief';
import repostingTimeTextConfig from '../../../../../../ContentApproval/Constants/repostingTimeTextConfig';
import PlatformsList from '../../components/PlatformsList';

type PropsType = {
  hashtags: IViewBriefData['hashtags'];
  social_tagging: IViewBriefData['social_tagging'];
  reposting_duration: IViewBriefData['reposting_duration'];
  reposting_platform: IViewBriefData['reposting_platform'];
  isLoading: boolean;
};

const TagsCard: FC<PropsType> = memo(props => {
  const platformsText = useMemo(
    () => repostingTimeTextConfig.find(item => item.key === props?.reposting_duration)?.value || 'Not set',
    [props.reposting_duration]
  );

  return (
    <BriefTextCard headerTitle={'Tags required'}>
      <TextRow title={'Hashtags #'}>
        <TagsList isLoading={props.isLoading} tags={props?.hashtags} />
      </TextRow>
      <TextRow title={'Social tags @'}>
        <TagsList isLoading={props.isLoading} tags={props.social_tagging} />
      </TextRow>
      <TextRow title={'Reposting'}>
        <PlatformsList platforms={props.reposting_platform} text={platformsText} isLoading={props.isLoading} />
      </TextRow>
    </BriefTextCard>
  );
});

export default TagsCard;
