import React, { FC } from 'react';

const BlogPostSVG: FC = () => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="4" width="30" height="30" rx="5" fill="#ECECFF" />
      <path
        d="M21 17H17V13L29 1L33 5L21 17Z"
        fill="#4E3B8B"
        stroke="#4E3B8B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M26 4L30 8" stroke="#ECECFF" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M6 28H24" stroke="#BFBBEE" strokeWidth="3" strokeLinecap="round" />
      <path d="M6 22H24" stroke="#BFBBEE" strokeWidth="3" strokeLinecap="round" />
      <path d="M6 16H12" stroke="#BFBBEE" strokeWidth="3" strokeLinecap="round" />
    </svg>
  );
};

export default BlogPostSVG;
