import React, { useEffect, useState } from 'react';

const ImageLoadedControlWrapper = ({ url, resetLoaded, children }) => {
  const [currentUrl, setCurrentUrl] = useState(null);
  useEffect(() => {
    if (currentUrl === url) return;
    setCurrentUrl(url);
    resetLoaded();
  }, [url]);
  return children;
};

export default ImageLoadedControlWrapper;
