import React, { FC, useCallback, useRef, useState } from 'react';
import styles from './ContentBoardCollaborators.module.scss';
import { useParams } from 'react-router-dom';
import {
  deleteInvitedBoardCollaborator,
  inviteBoardCollaborator,
  InviteDataType,
} from '../../../../../actions/ContentBoardActions/contentBoard.actions';
import DotsDropdownButton from '../../../../../components/DotsDropdownButton';
import CollaboratorsDropdown from '../../../../../components/CollaboratorsDropdown';
import InviteBoardCollaboratorsModal, {
  CollaboratorsFormDropdownOptionsType,
} from '../../../../../components/Modals/InviteCollaboratorsModal';
import { TYPES_CAMPAIGNS, TYPES_CAMPAIGNS_OBJECTS } from '../../../../../shared/constants/constants';
import DropdownCollaboratorCell from './components/DropdownCollaboratorCell';
import ConfirmDeleteInvitedCollaboratorsModal from '../../../../../components/Modals/ConfirmDeleteInvitedCollaboratorsModal';
import CollaboratorDropdownInvitedCell from '../../../../../components/CollaboratorDropdownInvitedCell';
import { useMediaQuery } from 'react-responsive/src';
import { Modal } from '../../../../../components/Modals';
import DelegateOwnershipModal from './components/DelegateOwnershipModal';
import cs from 'classnames';
import UserPlusSVG from '../../../../../components/IconsSvg/UserPlusSVG';
import useTooltip, { TOOLTIP_POSITIONS } from '../../../../../shared/hooks/fixedTooltip/useTooltip';
import CollaboratorsIcons from '../../../components/CollaboratorsIcons';
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks/reduxHooks';
import { IBoardInvitedCollaborator } from '../../../../../models/entities/board/BoardInvitedCollaborator';
import { COLLABORATOR_ROLE_TYPES } from '../../../../../models/permissions/collaborator/CollaboratorRole';

const allowed = [TYPES_CAMPAIGNS.BRAND, TYPES_CAMPAIGNS.AGENCY, TYPES_CAMPAIGNS.TALENT_MANAGER, TYPES_CAMPAIGNS.CONTENT_CREATOR];
const ORG_TYPES = TYPES_CAMPAIGNS_OBJECTS.filter(item => allowed.includes(item.type)).map(item => ({ ...item }));

type PropsType = {
  className?: string;
};
const ContentBoardCollaborators: FC<PropsType> = props => {
  const dispatch = useAppDispatch();
  const params: { boardId: string } = useParams<{ boardId: string }>();
  const currentOrg = useAppSelector<any>(state => state.auth.currentOrganization);
  const userId = useAppSelector<string | number>(state => state.auth.user.id);
  const currentBoard = useAppSelector(state => state.contentBoard.openedBoard);
  const { collaborators, invitedCollaborators } = useAppSelector(state => state.contentBoard);
  const [isOpenInviteModal, setIsOpenInviteModal] = useState<boolean>(false);
  const [delegateOwnershipModalState, setDelegateOwnershipModalState] = useState<boolean>(false);
  const [copiedLinkId, setCopiedLinkId] = useState<string | null>(null);
  const isTablet: boolean = useMediaQuery({ query: '(max-width: 768px)' });
  const toggleDropDownRef = useRef<(() => void) | null>(null);
  const ignoreClickRef = useRef<HTMLDivElement | null>(null);
  const { toolTip, onEnter, onLeave } = useTooltip(TOOLTIP_POSITIONS.BOTTOM, { text: 'Add collaborator' });

  const [invitedCollaboratorIdToDelete, setInvitedCollaboratorIdToDelete] = useState<null | IBoardInvitedCollaborator>(null);

  const collaboratorsCount = collaborators.length + invitedCollaborators.length;

  const handleInviteCollaborators = (invites: InviteDataType) => {
    dispatch(inviteBoardCollaborator(currentOrg.organization_id, params.boardId, invites));
  };

  const handleDeleteInvitedCollaborator = (collaborator: IBoardInvitedCollaborator | null) => {
    if (!collaborator?.id) return;
    dispatch(deleteInvitedBoardCollaborator(currentOrg.organization_id, params.boardId, collaborator.id));
  };

  const handleResendInvitedCollaborator = (collaborator: IBoardInvitedCollaborator) => {
    dispatch(inviteBoardCollaborator(currentOrg.organization_id, params.boardId, collaborator));
  };

  const onClickOwnership = () => {
    setDelegateOwnershipModalState(true);
  };

  const updatedOpenCollaboratorHandler = useCallback(() => toggleDropDownRef.current && toggleDropDownRef.current(), [toggleDropDownRef]);

  return (
    <div className={cs(styles.root, props.className)} ref={ignoreClickRef}>
      <div className={styles.icons}>
        <CollaboratorsIcons onCollaboratorClick={updatedOpenCollaboratorHandler} collaborators={collaborators} />
      </div>
      <DotsDropdownButton
        ignoreRef={ignoreClickRef}
        buttonClassName={styles.dotsButtonClassName}
        customSVG={<UserPlusSVG />}
        onMouseEnter={onEnter}
        onMouseLeave={onLeave}
        ref={toggleDropDownRef}
        renderDropdown={(dropdownRef, closeDropdown) =>
          isTablet ? (
            <Modal borderRadius={'10px'} open={true} onClose={closeDropdown} className={styles.modalCollaborators}>
              <CollaboratorsDropdown
                forwardedRef={dropdownRef}
                collaborators={collaborators}
                invitedCollaborators={invitedCollaborators}
                counter={collaboratorsCount}
                onAddClick={() => {
                  setIsOpenInviteModal(true);
                  window.analytics.track('initiate_content_board_invite', {
                    category: 'Invite',
                    label: currentBoard?.name,
                    userId: userId,
                    content_board_id: currentBoard?.id,
                  });
                  closeDropdown();
                }}
                onClose={() => closeDropdown()}
                isTablet={isTablet}
                collaboratorsTableHeader={<DropdownCollaboratorCell tableHeader />}
                renderCollaboratorCell={collaborator => (
                  <DropdownCollaboratorCell
                    key={collaborator.approver_id}
                    collaborator={collaborator}
                    onClickOwnership={onClickOwnership}
                  />
                )}
                renderInvitedCollaboratorCell={collaborator => (
                  <CollaboratorDropdownInvitedCell
                    key={collaborator.id}
                    collaborator={collaborator}
                    onDelete={() => {
                      setInvitedCollaboratorIdToDelete(collaborator);
                      closeDropdown();
                    }}
                    onResend={() => handleResendInvitedCollaborator(collaborator)}
                    copy={{
                      id: copiedLinkId,
                      setCopyId: setCopiedLinkId,
                    }}
                  />
                )}
              />
            </Modal>
          ) : (
            <CollaboratorsDropdown
              forwardedRef={dropdownRef}
              collaborators={collaborators}
              invitedCollaborators={invitedCollaborators}
              counter={collaboratorsCount}
              onAddClick={() => {
                setIsOpenInviteModal(true);
                window.analytics.track('initiate_content_board_invite', {
                  category: 'Invite',
                  label: currentBoard?.name,
                  userId: userId,
                  content_board_id: currentBoard?.id,
                });
                closeDropdown();
              }}
              collaboratorsTableHeader={<DropdownCollaboratorCell tableHeader />}
              renderCollaboratorCell={collaborator => (
                <DropdownCollaboratorCell key={collaborator.approver_id} collaborator={collaborator} onClickOwnership={onClickOwnership} />
              )}
              renderInvitedCollaboratorCell={collaborator => (
                <CollaboratorDropdownInvitedCell
                  key={collaborator.id}
                  collaborator={collaborator}
                  copy={{
                    id: copiedLinkId,
                    setCopyId: setCopiedLinkId,
                  }}
                  onDelete={() => {
                    setInvitedCollaboratorIdToDelete(collaborator);
                    closeDropdown();
                  }}
                  onResend={() => handleResendInvitedCollaborator(collaborator)}
                />
              )}
            />
          )
        }
      >
        {toolTip}
      </DotsDropdownButton>
      <InviteBoardCollaboratorsModal
        allowedCollaborators={allowed as COLLABORATOR_ROLE_TYPES[]}
        open={isOpenInviteModal}
        onClose={() => setIsOpenInviteModal(false)}
        onSubmit={values => handleInviteCollaborators(values)}
        availableOrganizationDropdownOption={ORG_TYPES as CollaboratorsFormDropdownOptionsType[]}
        isTablet={isTablet}
        collaborators={[...collaborators, ...invitedCollaborators]}
      />
      <ConfirmDeleteInvitedCollaboratorsModal
        open={!!invitedCollaboratorIdToDelete}
        onClose={() => setInvitedCollaboratorIdToDelete(null)}
        onSubmit={() => {
          handleDeleteInvitedCollaborator(invitedCollaboratorIdToDelete);
          setInvitedCollaboratorIdToDelete(null);
        }}
      />
      <DelegateOwnershipModal
        collaborators={collaborators}
        open={delegateOwnershipModalState}
        onClose={() => setDelegateOwnershipModalState(false)}
      />
    </div>
  );
};

export default ContentBoardCollaborators;
