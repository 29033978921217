import React, { FC, ReactElement, ReactNode } from 'react';
import { rectSortingStrategy, SortableContext, SortingStrategy } from '@dnd-kit/sortable';

type CustomSortableContextProps = {
  items: (object & { id: string | number })[];
  strategy?: SortingStrategy;
  children: ReactNode | ReactElement;
};

const CustomSortableContext: FC<CustomSortableContextProps> = ({ strategy = rectSortingStrategy, children, items }) => {
  return (
    <SortableContext items={items} strategy={strategy}>
      {children}
    </SortableContext>
  );
};

export default CustomSortableContext;
