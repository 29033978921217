import React from 'react';
import styles from './ConfirmDeleteInvitedCollaboratorsModal.module.scss';
import { Modal } from '..';
import Button from '../../../expert/components/Buttons/ButtonCustom';

type PropsType = {
  onClose: () => void;
  onSubmit: () => void;
};

const ModalContent = (props: PropsType) => {
  return (
    <div className={styles.root}>
      <div className={styles.title}>Are you sure you want to delete Invite?</div>
      <div className={styles.navigationBtns}>
        <Button className={styles.submitButton} onClick={props.onSubmit}>
          Yes, delete
        </Button>
        <Button outline onClick={props.onClose}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

const ConfirmDeleteInvitedCollaboratorsModal = props => {
  return (
    <Modal className={styles.modal} {...props} maxWidth="268px">
      <ModalContent {...props} />
    </Modal>
  );
};

export default ConfirmDeleteInvitedCollaboratorsModal;
