import React, { FC, memo, PropsWithChildren, useState } from 'react';
import styles from './FormTab.module.scss';

export type FormTabProps = {
  title: string;
  icon: React.ReactElement;
  optionalText?: string | React.ReactElement;
  subHeader?: string;
} & PropsWithChildren;

const FormTab: FC<FormTabProps> = memo(props => {
  const [isTabDisplayed, setIsTabDisplayed] = useState(true);

  return (
    <div className={styles.briefTab}>
      <div
        className={styles.briefHeader}
        onClick={() => {
          // setIsTabDisplayed(!isTabDisplayed);
        }}
      >
        <div className={styles.leftSide}>
          <span className={styles.iconWrapper}>{props.icon}</span>
          <h2>{props.title}</h2>
          {props.optionalText && <span className={styles.optional}>{props.optionalText}</span>}
        </div>
        {/* <span className={cs(styles.arrowBlock, { [styles.active]: isTabDisplayed })}>*/}
        {/*  <span className={styles.dropdownArrow}>*/}
        {/*    <TriangleArrow />*/}
        {/*  </span>*/}
        {/* </span>*/}
      </div>
      {isTabDisplayed ? (
        <div className={styles.tabContent}>
          {props.subHeader ? <h4 className={styles.tabSubHeader}>{props.subHeader}</h4> : null}
          {props.children}
        </div>
      ) : null}
    </div>
  );
});

export default FormTab;
