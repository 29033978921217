import moment, { Moment as IMoment } from 'moment';
import React, { ComponentProps, FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import SingleDatePicker from './components/SingleDatePicker';
import useUserTimezone from '../../../shared/hooks/useUserTimezone';
import timeArray, { DEFAULT_TIME_INDEX, maxTimeIndex, minTimeIndex } from './constants/timeArray';
import dayjs from 'dayjs';
import useTooltip, { TOOLTIP_POSITIONS } from '../../../shared/hooks/fixedTooltip/useTooltip';
import styles from './LiveDueDate.module.scss';
import DueDatePickerInputsSection from './components/DueDatePickerInputsSection';
import { CalendarProps } from 'react-multi-date-picker';
import { apiFormat, dateWithoutTimeFormat, timeFormat } from './constants/timeFormats';
import getDefaultNotificationDay from './utils/getDefaultNotificationDate';
import mapCalendarsDatesFabrik from './utils/mapCalendarsDatesFabrik';
import { useMediaQuery } from 'react-responsive/src';

type PropsType = {
  initialMainDate: string | null;
  initialReminderDate?: string | null;
  minMainDate: string | null;
  maxMainDate: string | null;
  onSave: (mainDate: string | null, reminderDate: string | null) => void | Promise<void>;
  onClear: () => void | Promise<void>;
  disableRemainder?: boolean;
  canEdit?: boolean;
  renderInputComponent: (value: { expanded: boolean; onClick: () => void; isLoading: boolean }) => ReactNode | JSX.Element;
  modalVersion: ComponentProps<typeof SingleDatePicker>['modalVersion'];
  calendarRootClassName?: ComponentProps<typeof SingleDatePicker>['calendarRootClassName'];
  title?: string;
  mainInputPlaceHolder?: string;
  reminderDateText?: string;
  reminderRecipientName?: string;
};

const LiveDueDate: FC<PropsType> = props => {
  const { userTimezone, convertUtcDateToDateByTimezone } = useUserTimezone();

  const getTimeIndexByDateString = (dateString: string | null): number | null => {
    if (!dateString) return null;
    const time = convertUtcDateToDateByTimezone(dateString)?.format(timeFormat);
    if (!time) return null;
    return timeArray.findIndex(value => value == time);
  };

  // view
  const tooltipSettings = useTooltip(TOOLTIP_POSITIONS.BOTTOM, {
    text: 'Set a time for the due date before you choose the same date as the reminder date',
    className: styles.tooltip,
  });

  const isNotebook: boolean = useMediaQuery({ query: '(max-width: 1200px)' });
  const [activeInput, setActiveInput] = useState<'main' | 'reminder'>('main');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);

  const onSetExpanded = useCallback(
    (value: boolean | ((value: boolean) => boolean)) => {
      if (!props.canEdit) return;
      setExpanded(value);
    },
    [props.canEdit]
  );

  const renderInputComponent = useCallback(
    (value: { expanded: boolean; onClick: () => void }) => props.renderInputComponent({ ...value, isLoading }),
    [props.renderInputComponent, isLoading]
  );

  // Dates without time
  const toDayWithoutTime = useMemo(
    () =>
      moment()
        .tz(userTimezone?.name || moment.tz.guess())
        .format(dateWithoutTimeFormat),
    [userTimezone]
  );

  const [mainDateWithoutTime, setMainDateWithoutTime] = useState<string | null>(
    convertUtcDateToDateByTimezone(props.initialMainDate)?.format(dateWithoutTimeFormat) ?? null
  );
  const minMainDateWithoutTimeDueProps = useMemo<string | null>(
    () => convertUtcDateToDateByTimezone(props.minMainDate)?.format(dateWithoutTimeFormat) ?? null,
    [convertUtcDateToDateByTimezone, props.minMainDate]
  );
  const maxMainDateWithoutTimeDueProps = useMemo<string | null>(
    () => convertUtcDateToDateByTimezone(props.maxMainDate)?.format(dateWithoutTimeFormat) ?? null,
    [props.maxMainDate, convertUtcDateToDateByTimezone]
  );

  const [reminderDateWithoutTime, setReminderDateWithoutTime] = useState<string | null>(
    convertUtcDateToDateByTimezone(props.initialReminderDate)?.format(dateWithoutTimeFormat) ?? null
  );

  // time indexes
  const [mainTimeIndex, setMainTimeIndex] = useState<number | null>(getTimeIndexByDateString(props.initialMainDate));
  const [reminderTimeIndex, setReminderTimeIndex] = useState<number | null>(getTimeIndexByDateString(props.initialReminderDate ?? null));

  const minMainTimeIndex = useMemo(() => {
    if (!props.minMainDate && reminderTimeIndex == null) return minTimeIndex;
    const isSameDatesReminderAndMainDate = moment.utc(reminderDateWithoutTime).isSame(moment.utc(mainDateWithoutTime), 'date');
    const isSameDatesMinMainDateAndMainDate = moment.utc(minMainDateWithoutTimeDueProps).isSame(moment.utc(mainDateWithoutTime), 'date');

    const minTimeIndexDueMinDateProps = getTimeIndexByDateString(props.minMainDate);

    if (isSameDatesMinMainDateAndMainDate && isSameDatesReminderAndMainDate && reminderTimeIndex && minTimeIndexDueMinDateProps) {
      return reminderTimeIndex > minTimeIndexDueMinDateProps ? reminderTimeIndex + 1 : minTimeIndexDueMinDateProps;
    }

    if (isSameDatesReminderAndMainDate && reminderTimeIndex !== null) {
      return reminderTimeIndex + 1;
    } else if (isSameDatesReminderAndMainDate && !reminderTimeIndex) return minTimeIndex;

    if (isSameDatesMinMainDateAndMainDate && minTimeIndexDueMinDateProps !== null) {
      return minTimeIndexDueMinDateProps;
    } else if (isSameDatesReminderAndMainDate && !reminderTimeIndex) return minTimeIndex;

    return minTimeIndex;
  }, [
    reminderTimeIndex,
    props.minMainDate,
    reminderDateWithoutTime,
    mainDateWithoutTime,
    minMainDateWithoutTimeDueProps,
    getTimeIndexByDateString,
  ]);

  const maxMainTimeIndex = useMemo(() => {
    if (!props.maxMainDate && reminderTimeIndex == null) return maxTimeIndex;
    const isSameDatesMaxMainDateAndMainDate = moment.utc(maxMainDateWithoutTimeDueProps).isSame(moment.utc(mainDateWithoutTime), 'date');
    const maxTimeIndexDueMaxDateProps = getTimeIndexByDateString(props.maxMainDate);

    if (isSameDatesMaxMainDateAndMainDate && maxTimeIndexDueMaxDateProps) {
      return maxTimeIndexDueMaxDateProps;
    } else if (isSameDatesMaxMainDateAndMainDate && !maxTimeIndexDueMaxDateProps) return maxTimeIndex;

    return maxTimeIndex;
  }, [props.maxMainDate, mainDateWithoutTime, minMainDateWithoutTimeDueProps, getTimeIndexByDateString]);

  const minReminderTimeIndex = minTimeIndex;
  const maxReminderTimeIndex = useMemo(() => {
    if (
      !mainDateWithoutTime ||
      !reminderDateWithoutTime ||
      !moment.utc(reminderDateWithoutTime).isSame(moment.utc(mainDateWithoutTime), 'date') ||
      !mainTimeIndex
    )
      return maxTimeIndex;

    return mainTimeIndex - 1;
  }, [mainDateWithoutTime, reminderDateWithoutTime, mainTimeIndex]);

  // BUSINESS LOGIC

  const minCalendarMainDate: string | null = useMemo(() => {
    if (!minMainDateWithoutTimeDueProps && !reminderDateWithoutTime) return toDayWithoutTime;

    if (reminderDateWithoutTime && minMainDateWithoutTimeDueProps) {
      const latestName: 'reminder' | 'minDate' = moment.utc(reminderDateWithoutTime).isAfter(moment.utc(minMainDateWithoutTimeDueProps))
        ? 'reminder'
        : 'minDate';

      if (latestName === 'reminder' && mainTimeIndex === 0 && reminderDateWithoutTime === toDayWithoutTime) {
        return moment.utc(reminderDateWithoutTime).add(1, 'day').format(dateWithoutTimeFormat);
      }

      return latestName === 'minDate' ? minMainDateWithoutTimeDueProps : reminderDateWithoutTime;
    }

    if (reminderDateWithoutTime && mainTimeIndex === 0 && reminderDateWithoutTime === toDayWithoutTime) {
      return moment.utc(reminderDateWithoutTime).add(1, 'day').format(dateWithoutTimeFormat);
    }

    if (reminderDateWithoutTime) {
      return reminderDateWithoutTime;
    }

    return minMainDateWithoutTimeDueProps;
  }, [minMainDateWithoutTimeDueProps, toDayWithoutTime, reminderDateWithoutTime, reminderTimeIndex, mainTimeIndex]);

  const maxCalendarMainDate: string | null = useMemo(() => {
    if (!maxMainDateWithoutTimeDueProps) return null;

    return maxMainDateWithoutTimeDueProps;
  }, [maxMainDateWithoutTimeDueProps]);

  const minCalendarReminderDate: string | null = toDayWithoutTime;
  const maxCalendarReminderDate: string | null = useMemo(() => {
    if (!mainDateWithoutTime) return null;

    if (!mainTimeIndex) return moment.utc(mainDateWithoutTime).subtract(1, 'day').format(dateWithoutTimeFormat);

    return mainDateWithoutTime;
  }, [mainDateWithoutTime, mainTimeIndex]);

  const currentCalendarMinDate: string | null = useMemo(
    () => (activeInput === 'main' ? minCalendarMainDate : minCalendarReminderDate),
    [activeInput, minCalendarMainDate, minCalendarReminderDate]
  );
  const currentCalendarMaxDate: string | null = useMemo(
    () => (activeInput === 'main' ? maxCalendarMainDate : maxCalendarReminderDate),
    [activeInput, maxCalendarMainDate, maxCalendarReminderDate]
  );

  const defaultNotificationDay: null | IMoment = useMemo(
    () => getDefaultNotificationDay(minCalendarReminderDate, maxCalendarReminderDate),
    [maxCalendarReminderDate, minCalendarReminderDate]
  );

  const mapCalendarDays = useMemo(
    () =>
      mapCalendarsDatesFabrik(
        mainDateWithoutTime,
        reminderDateWithoutTime,
        mainTimeIndex,
        activeInput,
        props.disableRemainder,
        defaultNotificationDay,
        tooltipSettings
      ),
    [
      props.disableRemainder,
      reminderDateWithoutTime,
      activeInput,
      mainDateWithoutTime,
      tooltipSettings,
      mainTimeIndex,
      defaultNotificationDay,
    ]
  );

  const setDate: CalendarProps['onChange'] = useCallback(
    dateValue => {
      const dateValueWithoutTime = dayjs(dateValue).format(dateWithoutTimeFormat);

      if (activeInput === 'main') {
        setMainDateWithoutTime(dateValueWithoutTime);
      }

      if (activeInput === 'reminder') {
        setReminderDateWithoutTime(dateValueWithoutTime);
      }
    },
    [activeInput]
  );

  const onAddMainTime = useCallback(() => {
    setMainTimeIndex(DEFAULT_TIME_INDEX);
  }, []);

  const onAddReminder = useCallback(() => {
    setReminderTimeIndex(DEFAULT_TIME_INDEX);
  }, []);

  const onSubmit = useCallback(async () => {
    setExpanded(false);
    const momentDateTime = moment.utc(timeArray[mainTimeIndex !== null ? mainTimeIndex : DEFAULT_TIME_INDEX], timeFormat);
    const momentReminderTime = moment.utc(
      timeArray[reminderTimeIndex !== null ? reminderTimeIndex : mainTimeIndex ? mainTimeIndex - 1 : DEFAULT_TIME_INDEX],
      timeFormat
    );
    setIsLoading(true);

    await props.onSave(
      mainDateWithoutTime
        ? moment(mainDateWithoutTime)
            .tz(userTimezone?.name || moment.tz.guess(), true)
            .startOf('day')
            .set({ hour: momentDateTime.hour(), minute: momentDateTime.minute() })
            .format(apiFormat)
        : null,
      reminderDateWithoutTime
        ? moment(reminderDateWithoutTime)
            .tz(userTimezone?.name || moment.tz.guess(), true)
            .startOf('day')
            .set({ hour: momentReminderTime.hour(), minute: momentReminderTime.minute() })
            .format(apiFormat)
        : null
    );
    setIsLoading(false);
  }, [userTimezone, mainDateWithoutTime, reminderDateWithoutTime, mainTimeIndex, reminderTimeIndex]);

  // clear FNs

  const onClearMainDate = useCallback(() => {
    setMainDateWithoutTime(null);
    setMainTimeIndex(DEFAULT_TIME_INDEX);
    setReminderDateWithoutTime(null);
    setReminderTimeIndex(null);
  }, []);

  const onClearReminderDate = useCallback(() => {
    setReminderDateWithoutTime(null);
    setReminderTimeIndex(DEFAULT_TIME_INDEX);
  }, []);

  const onClearMainTime = useCallback(() => {
    setMainTimeIndex(DEFAULT_TIME_INDEX);
  }, []);

  const onClearReminderTime = useCallback(() => {
    setReminderTimeIndex(DEFAULT_TIME_INDEX);
  }, []);

  const onCloseModal = useCallback(() => {
    setMainDateWithoutTime(convertUtcDateToDateByTimezone(props.initialMainDate)?.format(dateWithoutTimeFormat) ?? null);
    setReminderDateWithoutTime(convertUtcDateToDateByTimezone(props.initialReminderDate)?.format(dateWithoutTimeFormat) ?? null);
    setMainTimeIndex(getTimeIndexByDateString(props.initialMainDate));
    setReminderTimeIndex(getTimeIndexByDateString(props.initialReminderDate ?? null));
    setExpanded(false);
    setActiveInput('main');
  }, [props.initialMainDate, props.initialReminderDate, convertUtcDateToDateByTimezone]);

  useEffect(() => {
    if (!expanded) onCloseModal();
  }, [expanded]);

  const onClear = useCallback(() => {
    setMainDateWithoutTime(null);
    setMainTimeIndex(DEFAULT_TIME_INDEX);
    setReminderDateWithoutTime(null);
    setReminderTimeIndex(null);
    // props.onClear();
    // setActiveInput('main');
  }, [props.onClear]);

  useEffect(
    function moveDatesDueUserActions() {
      const isSameDatesMainDateAndReminderDate = moment.utc(mainDateWithoutTime).isSame(moment.utc(reminderDateWithoutTime), 'date');
      const isSameDatesMainDateAndMinMainDate = moment.utc(mainDateWithoutTime).isSame(moment.utc(minMainDateWithoutTimeDueProps), 'date');
      const timeIndexDueMinDateProps = getTimeIndexByDateString(props.minMainDate);

      if (
        isSameDatesMainDateAndReminderDate &&
        isSameDatesMainDateAndMinMainDate &&
        reminderTimeIndex &&
        mainTimeIndex &&
        timeIndexDueMinDateProps &&
        reminderTimeIndex > timeIndexDueMinDateProps &&
        mainTimeIndex < timeIndexDueMinDateProps
      ) {
        setMainTimeIndex(timeIndexDueMinDateProps);
        setReminderTimeIndex(timeIndexDueMinDateProps);
        return;
      }

      if (mainTimeIndex !== null && mainTimeIndex > maxMainTimeIndex) {
        setMainTimeIndex(maxMainTimeIndex);
        return;
      }

      if (
        mainTimeIndex !== null &&
        minMainTimeIndex > mainTimeIndex &&
        (!reminderTimeIndex || !reminderDateWithoutTime || mainTimeIndex > reminderTimeIndex)
      ) {
        setMainTimeIndex(minMainTimeIndex);
        return;
      }

      if (isSameDatesMainDateAndReminderDate && mainTimeIndex === 0) {
        setReminderDateWithoutTime(moment.utc(mainDateWithoutTime).subtract(1, 'day').format(dateWithoutTimeFormat));
        return;
      }

      if (isSameDatesMainDateAndReminderDate && mainTimeIndex !== null && reminderTimeIndex && mainTimeIndex <= reminderTimeIndex) {
        setReminderTimeIndex(mainTimeIndex - 1);
        return;
      }
    },
    [mainDateWithoutTime, mainTimeIndex, reminderDateWithoutTime, reminderTimeIndex, maxMainTimeIndex, minMainTimeIndex]
  );

  useEffect(
    function updateDateAndTimeDueProps() {
      const newMainDate = convertUtcDateToDateByTimezone(props.initialMainDate)?.format(dateWithoutTimeFormat) ?? null;
      const newMainTimeIndex = getTimeIndexByDateString(props.initialMainDate);

      const newReminderDate = convertUtcDateToDateByTimezone(props.initialReminderDate)?.format(dateWithoutTimeFormat) ?? null;
      const newReminderTimeIndex = getTimeIndexByDateString(props.initialReminderDate ?? null);

      if (newMainDate !== mainDateWithoutTime) {
        setMainDateWithoutTime(newMainDate);
      }

      if (newMainTimeIndex !== mainTimeIndex) {
        setMainTimeIndex(newMainTimeIndex);
      }

      if (newReminderDate !== reminderDateWithoutTime) {
        setReminderDateWithoutTime(newReminderDate);
      }

      if (newReminderTimeIndex !== reminderTimeIndex) {
        setReminderTimeIndex(newReminderTimeIndex);
      }
    },
    [props.initialMainDate, props.initialReminderDate]
  );

  return (
    <>
      <SingleDatePicker
        expanded={expanded}
        setExpanded={onSetExpanded}
        renderInputComponent={renderInputComponent}
        modalVersion={props.modalVersion}
        date={mainDateWithoutTime}
        onCloseModal={onCloseModal}
        onChangeDate={setDate}
        mapCalendarDays={mapCalendarDays}
        calendarMinDate={currentCalendarMinDate}
        calendarMaxDate={currentCalendarMaxDate}
        calendarRootClassName={props.calendarRootClassName}
        calendarClassName={styles.calendar}
        maxModalWidth={isNotebook ? '300px' : 'fit-content'}
      >
        <DueDatePickerInputsSection
          activeDateInput={activeInput}
          mainInputPlaceHolder={props.mainInputPlaceHolder}
          reminderRecipientName={props.reminderRecipientName}
          onAddMainTime={onAddMainTime}
          mainDateTitle={props.title}
          reminderDateText={props.reminderDateText}
          mainDate={mainDateWithoutTime}
          reminderDate={reminderDateWithoutTime}
          disableReminder={props.disableRemainder}
          onChangeActiveDateInput={setActiveInput}
          minMainTimeIndex={minMainTimeIndex}
          maxMainTimeIndex={maxMainTimeIndex}
          mainTimeIndex={mainTimeIndex}
          minReminderTimeIndex={minReminderTimeIndex}
          maxReminderTimeIndex={maxReminderTimeIndex}
          reminderTimeIndex={reminderTimeIndex}
          onClearHandler={onClear}
          onSubmitHandler={onSubmit}
          onClearMainDate={onClearMainDate}
          onClearReminderDate={onClearReminderDate}
          onClearMainTime={onClearMainTime}
          onClearReminderTime={onClearReminderTime}
          onChangeMainTime={setMainTimeIndex}
          onChangeReminderTime={setReminderTimeIndex}
          onAddReminder={onAddReminder}
        />
      </SingleDatePicker>
    </>
  );
};

export default LiveDueDate;
