import { IDeliverableFormData } from '../hooks/useDeliverablesFormData';
import { DELIVERABLES_PLACEMENTS_NAMING_CONFIG, DELIVERABLES_PLATFORMS_NAMING_CONFIG } from '../constants/DeliverableNamingConfig';

const getInitialDeliverableName = (
  platformName: IDeliverableFormData['platform'],
  placementName: IDeliverableFormData['placement'],
  index: number
) => {
  return `${DELIVERABLES_PLATFORMS_NAMING_CONFIG[platformName]}_${DELIVERABLES_PLACEMENTS_NAMING_CONFIG[placementName]}_Deliverable${index}`;
};

export default getInitialDeliverableName;
