import React from 'react';
import styles from './Pagination.module.scss';
import cs from 'classnames';
import { ReactComponent as ArrowIcon } from '../../assets/img/icons/pagination_arrow.svg';
import { useMediaQuery } from 'react-responsive/src';

const Pagination = ({ totalCount, amount, page, setPage, className, activeClassName }) => {
  const maxPage = Math.ceil(totalCount / amount);
  const arrayPage = Array.from({ length: maxPage }, (_, i) => i + 1);
  const isMobile = useMediaQuery({ query: '(max-width: 575px)' });

  const desktopPaginationFunction = i => {
    switch (i) {
      case 1:
        return (
          <button
            key={i}
            onClick={() => setPage(i)}
            className={cs(styles.button, { [styles.active]: page === i }, { [activeClassName]: page === i })}
          >
            {i}
          </button>
        );
      case page - 1:
        return (
          <button
            key={i}
            onClick={() => setPage(i)}
            className={cs(styles.button, { [styles.active]: page === i }, { [activeClassName]: page === i })}
          >
            {i}
          </button>
        );
      case page:
        return (
          <button
            key={i}
            onClick={() => setPage(i)}
            className={cs(styles.button, { [styles.active]: page === i }, { [activeClassName]: page === i })}
          >
            {i}
          </button>
        );
      case page + 1:
        return (
          <button
            key={i}
            onClick={() => setPage(i)}
            className={cs(styles.button, { [styles.active]: page === i }, { [activeClassName]: page === i })}
          >
            {i}
          </button>
        );
      case maxPage:
        return (
          <button
            key={i}
            onClick={() => setPage(i)}
            className={cs(styles.button, { [styles.active]: page === i }, { [activeClassName]: page === i })}
          >
            {i}
          </button>
        );
      default:
        return <div key={Math.random()}> ... </div>;
    }
  };

  const mobilePaginationFunction = i => {
    switch (i) {
      case 1:
        return (
          <button
            key={i}
            onClick={() => setPage(i)}
            className={cs(styles.button, { [styles.active]: page === i }, { [activeClassName]: page === i })}
          >
            {i}
          </button>
        );
      case page:
        return (
          <button
            key={i}
            onClick={() => setPage(i)}
            className={cs(styles.button, { [styles.active]: page === i }, { [activeClassName]: page === i })}
          >
            {i}
          </button>
        );
      case maxPage:
        return (
          <button
            key={i}
            onClick={() => setPage(i)}
            className={cs(styles.button, { [styles.active]: page === i }, { [activeClassName]: page === i })}
          >
            {i}
          </button>
        );
      default:
        return <div key={Math.random()}>...</div>;
    }
  };

  return (
    <div className={cs(styles.pagination, className)}>
      <button className={cs(styles.button, styles.buttonPrev)} onClick={() => setPage(page - 1)} disabled={page === 1}>
        <ArrowIcon />
      </button>
      <div>{arrayPage.slice(0, page).map(i => (isMobile ? mobilePaginationFunction(i) : desktopPaginationFunction(i)))}</div>
      <div>{arrayPage.slice(page).map(i => (isMobile ? mobilePaginationFunction(i) : desktopPaginationFunction(i)))}</div>
      <button className={cs(styles.button, styles.buttonNext)} onClick={() => setPage(page + 1)} disabled={page === maxPage}>
        <ArrowIcon />
      </button>
    </div>
  );
};

export default Pagination;
