import { IDeliverableOption } from '../DeliverableOption';
import { IDeliverableOptionDTO } from '../dto/DeliverableOption';

export class DeliverableOptionHydrator implements IDeliverableOption {
  name: string;
  id: number;

  constructor(dto: IDeliverableOptionDTO) {
    this.name = dto.name;
    this.id = dto.id;
  }
}
