/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';
import UpgradePlanBg from '../../assets/img/upgradePlanBg.png';
import styles from './ChoosePlan.module.scss';
import { loadStripe } from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';
import { updateOrganizationPricingPlan } from '../../actions/organizations.actions';
import { me, recalculateRoutePermissionsTrigger } from '../../actions/auth.actions';
import Loader from '../../expert/components/Loader';
import { createCheckoutSession } from '../../actions/stripe.actions';
import FullLogoSVG from '../../expert/components/IconsSvg/LogoSVG';
import PeriodSwitcher from './components/PeriodSwitcher';
import PlanCard from './components/PlanCard';
import { useAppDispatch, useAppSelector, useThunkDispatch } from '../../shared/hooks/reduxHooks';
import { PricingPlanHydrator } from '../../models/entities/pricingPlans/hydrators/PricingPlanHydrator';
import { PRICING_PLAN_PERIOD } from '../../models/entities/pricingPlans/enum/PricingPlanPeriod';
import { MEMBER_ROLE_IN_ORGANIZATION_TYPES } from '../../models/enums/MemberRoleInOrganizationTypes';
import { Redirect } from 'react-router';
import cs from 'classnames';
import { ORGANIZATION_TYPES } from '../../models/enums/OrganizationTypes';
import CustomPaidPlanTMBA from '../../models/entities/pricingPlans/CustomPlans/CustomPaidPlanTMBA';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK || '');

const redirectToStripe = async sessionId => {
  const stripe = await stripePromise;
  stripe
    ?.redirectToCheckout({
      sessionId: sessionId,
    })
    .then();
};

const ChoosePlanContent = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const thunkDispatch = useThunkDispatch();
  const currentOrg = useAppSelector(state => state.auth.currentOrganization);
  const allAvailablePlans = useAppSelector(state => state.payment.allPlansByOrgType);
  const user = useAppSelector(state => state.auth.user);

  const plans = useMemo(() => {
    if (allAvailablePlans?.length && currentOrg) {
      const res = allAvailablePlans.map(plan => new PricingPlanHydrator(plan, Boolean(currentOrg.pricing_plan_id)));

      if (currentOrg.organization_type !== ORGANIZATION_TYPES.CONTENT_CREATOR) {
        res.push(new CustomPaidPlanTMBA());
      }

      return res;
    }
    return [];
  }, [allAvailablePlans, currentOrg?.pricing_plan_id]);

  const [updatingPlan, setUpdatingPlan] = useState(false);
  const [period, setPeriod] = useState<PRICING_PLAN_PERIOD>(PRICING_PLAN_PERIOD.MONTHLY);

  useEffect(() => {
    return () => {
      // When returning back through the browser, access rights to routes are not recalculated. Therefore, we recalculate them manually here so that it would be impossible to leave the page.
      dispatch(recalculateRoutePermissionsTrigger());
    };
  }, []);

  const backButtonDisplayStatus = useMemo(() => {
    if (currentOrg) {
      return Boolean(currentOrg.pricing_plan_id);
    }
    return false;
  }, [currentOrg]);

  const goBack = () => {
    history.push('/content-approval');
  };

  const handleSelectPlan = async plan => {
    if (plan.isFree || currentOrg?.stripe?.setup_intent_status === 'succeeded') {
      localStorage.removeItem('OTTERFISH_CURRENT_ORGANIZATION');
      setUpdatingPlan(true);

      try {
        const data = {
          pricing_plan_id: plan.id,
          pricing_plan_recurrence: period,
        };

        const response = await thunkDispatch(updateOrganizationPricingPlan(currentOrg.organization_id, data));
        setUpdatingPlan(false);

        if (response) {
          setUpdatingPlan(true);

          setTimeout(async () => {
            window.analytics.track('plan_chosen', {
              category: 'User Signup',
              label: plan.name,
              userId: user.id,
            });

            await dispatch(me());
            setUpdatingPlan(false);
            history.push('/complete-organization');
          }, 1500);
        }
      } catch (e) {
        setUpdatingPlan(false);
      }
    } else {
      setUpdatingPlan(true);
      (dispatch(createCheckoutSession(currentOrg.organization_id, `/checkout-success?pricing_plan_id=${plan.id}&pricing_plan_recurrence=${period}`, '/upgrade-plan')) as any).then(
        // checkout-success?pricing_plan_id=${plan.id}&pricing_plan_recurrence=${period}
        response => {
          redirectToStripe(response?.data?.[0]?.id);
          setUpdatingPlan(false);
        }
      );
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.logo}>
          <FullLogoSVG />
        </div>
        {backButtonDisplayStatus && (
          <div className={styles.backButton} onClick={goBack}>
            Go to Otterfish
          </div>
        )}
      </div>
      <div className={styles.pageBackground} />
      <img src={UpgradePlanBg} className={styles.pageBackgroundImage} alt={'page background'} />
      <div className={styles.content}>
        <div className={styles.textContent}>
          <div>Pricing</div>
          <h2>Choose a plan that’s right for you</h2>
          <div>
            Otterfish offers both free and paid plans, pick the one that best fits your needs. Upgrade to our Pro plan for even more
            features and control.
          </div>
        </div>
        <div className={cs(styles.switcherContainer, { [styles.space]: plans?.every(plan => plan.isFree) })}>
          {!plans?.every(plan => plan.isFree || plan.isCustom) && <PeriodSwitcher onChange={tab => setPeriod(tab)} initialValue={period} />}
        </div>
        <div className={styles.cardsContainer}>
          {plans?.map(plan => (
            <PlanCard
              key={plan.name}
              plan={plan}
              period={period}
              isCurrent={(plan.isFree || currentOrg.stripe.subscription_status === 'active') && currentOrg?.pricing_plan_id === plan.id}
              onSelect={handleSelectPlan}
              updatingPlan={updatingPlan}
            />
          ))}
        </div>
      </div>
      {updatingPlan && (
        <div className={styles.loaderNew}>
          <Loader textContent="We are completing your profile. You will be ready to go in seconds" />
        </div>
      )}
    </div>
  );
};

const ChoosePlan = () => {
  const currentOrg = useAppSelector(state => state.auth.currentOrganization);
  if (!currentOrg) return null;
  if (currentOrg.role === MEMBER_ROLE_IN_ORGANIZATION_TYPES.MEMBER) {
    return <Redirect to="/" />;
  }
  return <ChoosePlanContent />;
};

export default ChoosePlan;
