import { IConceptSection } from '../models/entities/deliverableSections/ConceptSection';
import { IDeliverable } from '../models/entities/deliverable/Deliverable';
import { AssetStateType } from '../models/entities/asset/AssetState';
import { DELIVERABLE_SECTION_TYPES } from '../models/enums/DeliverableSectionTypes';
import { IContentSection } from '../models/entities/deliverableSections/ContentSection';
import { DELIVERABLES_ACTION_TYPES, DeliverablesActionsTypes } from '../actions/deliverablesActions/types';
import { DeliverableItemPage } from '../models/permissions/pages/DeliverableItemPage';
import { Page } from '../models/permissions/pages/Page';
import { DeliverablePermission } from '../models/permissions/enum/DeliverablePermission';
import CustomError from '../models/entities/error/CustomError';

export type DeliverablesReducerState = {
  openedDeliverable: {
    deliverable?: IDeliverable | null;
    concept?: IConceptSection | null;
    content?: IContentSection | null;
  } | null;
  pageModel: DeliverableItemPage | Page<DeliverablePermission> | null;
  assetsToUpdate: { asset: AssetStateType; type: DELIVERABLE_SECTION_TYPES } | null;
  deliverableApprovals: null;
  deliverableLoading: boolean;
  deliverableItemUpdateLoading: boolean;
  deliverableConceptUpdateLoading: boolean;
  deliverableContentUpdateLoading: boolean;
  approveLoading: boolean;
  deliverableItemError: null | string;
  deliverableItemUpdateError: null | string;
  deliverableConceptUpdateError: null | CustomError;
  deliverableContentUpdateError: null | CustomError;
  approveError: null | string;
  deliverableApprovalsLoading: boolean;
};

const initialState: DeliverablesReducerState = {
  openedDeliverable: null,
  pageModel: null,
  assetsToUpdate: null,
  deliverableApprovals: null,
  deliverableLoading: true,
  deliverableItemUpdateLoading: false,
  deliverableConceptUpdateLoading: false,
  deliverableContentUpdateLoading: false,
  approveLoading: false,
  deliverableItemError: null,
  deliverableItemUpdateError: null,
  deliverableConceptUpdateError: null,
  deliverableContentUpdateError: null,
  approveError: null,
  deliverableApprovalsLoading: false,
};

const deliverablesReducer = (state = initialState, action: DeliverablesActionsTypes): DeliverablesReducerState => {
  const type = action.type;
  switch (type) {
    case DELIVERABLES_ACTION_TYPES.GET_BOARD_DELIVERABLE_ITEM_REQUEST:
      return {
        ...state,
        deliverableLoading: true,
      };
    case DELIVERABLES_ACTION_TYPES.GET_BOARD_DELIVERABLE_ITEM_SUCCESS:
      return {
        ...state,
        openedDeliverable: action.payload,
        deliverableLoading: false,
      };
    case DELIVERABLES_ACTION_TYPES.GET_BOARD_DELIVERABLE_ITEM_FAILURE:
      return {
        ...state,
        deliverableLoading: false,
        deliverableItemError: action.payload,
      };
    case DELIVERABLES_ACTION_TYPES.CLEAR_OPENED_DELIVERABLE_ITEM:
      return {
        ...state,
        openedDeliverable: null,
        pageModel: null,
        deliverableApprovals: null,
      };
    case DELIVERABLES_ACTION_TYPES.UPDATE_DELIVERABLE_ITEM_REQUEST:
      return {
        ...state,
        deliverableItemUpdateLoading: true,
      };
    case DELIVERABLES_ACTION_TYPES.UPDATE_DELIVERABLE_ITEM_SUCCESS:
      return {
        ...state,
        deliverableItemUpdateLoading: false,
        openedDeliverable: {
          ...state.openedDeliverable,
          deliverable: state.openedDeliverable?.deliverable ? { ...state.openedDeliverable?.deliverable, ...action.payload } : null,
        },
      };
    case DELIVERABLES_ACTION_TYPES.UPDATE_DELIVERABLE_ITEM_FAILURE:
      return {
        ...state,
        deliverableItemUpdateLoading: false,
        deliverableItemUpdateError: action.payload,
      };
    case DELIVERABLES_ACTION_TYPES.UPDATE_DELIVERABLE_CONCEPT_REQUEST:
      return {
        ...state,
        deliverableConceptUpdateLoading: true,
      };
    case DELIVERABLES_ACTION_TYPES.UPDATE_DELIVERABLE_CONCEPT_SUCCESS:
      return {
        ...state,
        deliverableConceptUpdateLoading: false,
      };
    case DELIVERABLES_ACTION_TYPES.UPDATE_DELIVERABLE_CONCEPT_FAILURE:
      return {
        ...state,
        deliverableConceptUpdateLoading: action.loading || false,
        deliverableConceptUpdateError: action.payload || null,
      };
    case DELIVERABLES_ACTION_TYPES.UPDATE_DELIVERABLE_CONTENT_REQUEST:
      return {
        ...state,
        deliverableContentUpdateLoading: true,
      };
    case DELIVERABLES_ACTION_TYPES.UPDATE_DELIVERABLE_CONTENT_SUCCESS:
      return {
        ...state,
        deliverableContentUpdateLoading: false,
      };
    case DELIVERABLES_ACTION_TYPES.UPDATE_DELIVERABLE_CONTENT_FAILURE:
      return {
        ...state,
        deliverableContentUpdateLoading: action.loading || false,
        deliverableContentUpdateError: action.payload || null,
      };
    case DELIVERABLES_ACTION_TYPES.APPROVE_DELIVERABLE_REQUEST:
      return {
        ...state,
        approveLoading: true,
      };
    case DELIVERABLES_ACTION_TYPES.APPROVE_DELIVERABLE_SUCCESS:
      return {
        ...state,
        approveLoading: false,
      };
    case DELIVERABLES_ACTION_TYPES.APPROVE_DELIVERABLE_FAILURE:
      return {
        ...state,
        approveLoading: false,
        approveError: action.payload,
      };
    case DELIVERABLES_ACTION_TYPES.CLEAR_ASSETS_TO_UPDATE:
      return {
        ...state,
        assetsToUpdate: null,
      };
    case DELIVERABLES_ACTION_TYPES.UPDATE_OPENED_DELIVERABLE_ITEM:
      return {
        ...state,
        openedDeliverable: { ...state.openedDeliverable, deliverable: action.payload },
      };
    case DELIVERABLES_ACTION_TYPES.UPDATE_DELIVERABLE_SECTION_ASSETS:
      const openedDeliverable = state.openedDeliverable;

      if (openedDeliverable) {
        return {
          ...state,
          openedDeliverable: {
            ...openedDeliverable,
            [action.payload.type]: openedDeliverable[action.payload.type]
              ? {
                  ...openedDeliverable[action.payload.type],
                  assets: openedDeliverable[action.payload.type]?.assets.map(asset => {
                    if (asset.id === action.payload.asset.id) {
                      return { ...action.payload.asset };
                    }
                    return asset;
                  }),
                }
              : null,
          },
          assetsToUpdate: { asset: { ...action.payload.asset }, type: action.payload.type },
        };
      } else {
        return state;
      }
    //  none
    // case DELIVERABLES.GET_DELIVERABLE_APPROVALS_REQUEST:
    //   return {
    //     ...state,
    //     deliverableApprovalsLoading: true,
    //   };
    // none
    // case DELIVERABLES.GET_DELIVERABLE_APPROVALS_SUCCESS:
    //   return {
    //     ...state,
    //     deliverableApprovals: action.payload,
    //     deliverableApprovalsLoading: false,
    //   };
    // none
    // case DELIVERABLES.GET_DELIVERABLE_APPROVALS_FAILURE:
    //   return {
    //     ...state,
    //     deliverableApprovalsLoading: false,
    //   };
    case DELIVERABLES_ACTION_TYPES.CLEAR_ERRORS:
      return {
        ...state,
        approveError: null,
        deliverableItemUpdateError: null,
        deliverableItemError: null,
        deliverableConceptUpdateError: null,
        deliverableContentUpdateError: null,
      };
    case DELIVERABLES_ACTION_TYPES.SET_DELIVERABLE_PAGE_MODEL:
      return {
        ...state,
        pageModel: action.payload,
      };
    default:
      return state;
  }
};

export default deliverablesReducer;
