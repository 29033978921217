import { INotificationPreferencesObject } from '../NotificationPreferences';
import { NOTIFICATION_PREFERENCES_GROUP_TYPE } from '../enum/NotificationPreferencesGroupType';

import { INotificationPreferencesObjectDTO } from '../dto/NotificationPreferences';
import { NOTIFICATIONS_PREFERENCES_ACTIVITIES_TYPE } from '../enum/NotificationsPreferencesActivitiesType';
import { NOTIFICATION_PREFERENCES_CHAT_NOTIFICATIONS_TYPE } from '../enum/NotificationPreferencesChatNotificationsType';
import { NOTIFICATION_PREFERENCES_D_AND_W_TYPE } from '../enum/NotificationPreferencesDAndWType';
import { INotificationPreferencesSetting } from '../NotificationPreferencesSettings';

class NotificationPreferencesHydrator<
  NotificationsType extends
    | NOTIFICATIONS_PREFERENCES_ACTIVITIES_TYPE
    | NOTIFICATION_PREFERENCES_CHAT_NOTIFICATIONS_TYPE
    | NOTIFICATION_PREFERENCES_D_AND_W_TYPE,
  GroupType extends NOTIFICATION_PREFERENCES_GROUP_TYPE
> implements INotificationPreferencesObject<NotificationsType, GroupType>
{
  group_type: GroupType;
  group_enabled: boolean;
  notifications: INotificationPreferencesSetting<NotificationsType, GroupType>[];

  constructor(
    dto: INotificationPreferencesObjectDTO<NotificationsType, GroupType>,
    notifications: INotificationPreferencesSetting<NotificationsType, GroupType>[]
  ) {
    this.group_type = dto.group_type;
    this.group_enabled = dto.group_enabled;
    this.notifications = notifications;
  }
}

export default NotificationPreferencesHydrator;
