import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './EditCampaignItem.module.scss';
import MainLayout from '../../../components/MainLayout';
import TabBrandDetails from '../components/tabs/TabBrandDetails';
import { useFormik } from 'formik';
import SpeakerSVG from '../../../components/IconsSvg/SpeakerSVG';
import BriefTab from '../components/BriefTab';
import { useDispatch } from 'react-redux';
import { updateBrief, getBrief, clearBriefFailure } from '../../../actions/brief.actions';
import * as Yup from 'yup';
import BriefFailureModal from '../components/BriefFailureModal';
import BriefSubHeader from '../../ContentApproval/createPages/CreateBoardWrapper/CreateBoardBrief/components/BriefSubHeader';
import ErrorModal from '../../../expert/components/Modal/ErrorModal';

function EditCampaignItem() {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentOrganizationID = useSelector(state => state.auth.currentOrganization?.organization_id);
  const failure = useSelector(state => state.brief.failure);
  const match = useRouteMatch();

  const [logoObject, setLogoObject] = useState({ file: null, fileUrl: null });

  const currentBrief = useSelector(state => state.brief.currentBrief.data);

  useEffect(() => {
    if (currentOrganizationID) {
      dispatch(getBrief(currentOrganizationID, match.params.campaignID, () => history.push('/campaign/')));
    }
  }, [currentOrganizationID]);

  useEffect(() => {
    if (currentBrief) {
      setLogoObject({ fileUrl: currentBrief.brand_logo_location });
      formik.setValues(getInitialState(currentBrief));
    }
  }, [currentBrief]);

  const deleteLogo = () => {
    return !!(!logoObject.fileUrl && currentBrief.brand_logo_location);
  };

  const getInitialState = (currentBrief = {}) => {
    return {
      campaignName: currentBrief.name || '',
      brandName: currentBrief.brand_name || '',
      brandBackground: currentBrief.brand_description || '',
      showBrandProvidesProduct: (currentBrief.brand_provides_product && true) || false,
      brandProvidesProduct: currentBrief.brand_provides_product || '',
      notes: currentBrief.notes || '',
    };
  };

  const formik = useFormik({
    initialValues: getInitialState(),
    validationSchema: Yup.object().shape({
      campaignName: Yup.string().trim().max(200, 'Maximum length of 200 characters exceeded').required('Campaign name is required'),
      brandName: Yup.string().max(200, 'Maximum length of 200 characters exceeded'),
    }),
    onSubmit: () => {
      submitHandler();
    },
  });

  const tabsData = [
    {
      title: 'Campaign Overview',
      content: <TabBrandDetails formik={formik} logoObject={logoObject} setLogoObject={setLogoObject} />,
      icon: <SpeakerSVG />,
    },
  ];

  const submitHandler = () => {
    if (currentOrganizationID) {
      const editBriefData = {
        name: formik.values.campaignName || null,
        brand_name: formik.values.brandName || null,
        notes: formik.values.notes || null,
        brand_description: formik.values.brandBackground || null,
        brand_provides_product: formik.values.showBrandProvidesProduct ? formik.values.brandProvidesProduct : null,
      };

      if (logoObject.file) {
        editBriefData['brand_logo'] = logoObject.file;
      }

      dispatch(
        updateBrief(
          currentOrganizationID,
          editBriefData,
          () => history.push(`/campaign/${match.params.campaignID}`),
          match.params.campaignID,
          deleteLogo()
        )
      );
    }
  };

  const clearFailure = () => {
    formik.setSubmitting(false);
    dispatch(clearBriefFailure());
  };

  return (
    <form onSubmit={formik.handleSubmit} onKeyDown={e => e.keyCode === 13 && e.preventDefault()}>
      <ErrorModal
        open={!!(Array.isArray(failure) ? failure.errors : failure)}
        onClose={() => dispatch(clearFailure)}
        text={Array.isArray(failure) ? failure?.errors?.body.map(item => item.message).join(', ') : failure}
      />
      <BriefFailureModal formik={formik} />
      <MainLayout title={'Campaign Overview'} contentClassName={styles.root}>
        <BriefSubHeader
          errors={formik.errors}
          title={'Edit campaign title'}
          description={'Edit campaign description'}
          buttonTitle={'Save'}
          onCancel={() => history.push(`/campaign/${match.params.campaignID}`)}
          canSubmit={currentOrganizationID && formik.dirty && formik.isValid && !formik.isSubmitting}
        />
        {currentBrief && (
          <div className={styles.pageContent}>
            {tabsData.map((item, index) => (
              <div key={item.title}>
                <BriefTab data={item} />
              </div>
            ))}
          </div>
        )}
      </MainLayout>
    </form>
  );
}

export default EditCampaignItem;
