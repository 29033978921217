import React, { CSSProperties, FC, MutableRefObject, ReactNode } from 'react';
import styles from './CollaboratorsDropdown.module.scss';
import ButtonCustom from '../../expert/components/Buttons/ButtonCustom';
import cs from 'classnames';
import { IBoardCollaborator } from '../../models/entities/board/BoardCollaborator';
import { IBoardInvitedCollaborator } from '../../models/entities/board/BoardInvitedCollaborator';
import ButtonLightPurpleWhite from '../Buttons/ButtonLightPurpleWhite';

type PropsType = {
  collaborators: IBoardCollaborator[];
  invitedCollaborators: IBoardInvitedCollaborator[];
  counter?: number;
  onAddClick: () => void;
  collaboratorsTableHeader?: ReactNode;
  renderCollaboratorCell: (collaborator: IBoardCollaborator) => ReactNode | JSX.Element;
  renderInvitedCollaboratorCell: (collaborator: IBoardInvitedCollaborator) => ReactNode | JSX.Element;
  forwardedRef: MutableRefObject<HTMLDivElement | null>;
  disableAddButton?: boolean;
  rootStyle?: CSSProperties;
  onClose?: () => void;
  isTablet?: boolean;
};
const CollaboratorsDropdown: FC<PropsType> = ({
  collaborators,
  invitedCollaborators,
  counter,
  onAddClick,
  collaboratorsTableHeader,
  renderCollaboratorCell,
  renderInvitedCollaboratorCell,
  forwardedRef,
  disableAddButton = false,
  rootStyle = {},
  onClose,
  isTablet,
}) => {
  return (
    <div className={styles.root} ref={forwardedRef} style={rootStyle}>
      <div className={styles.header}>
        <div className={styles.column}>
          <div className={styles.title}>Collaborators</div>
          {counter && <div className={styles.counter}>{counter}</div>}
        </div>
        <div className={styles.column}>
          {onAddClick && !disableAddButton && (
            <ButtonLightPurpleWhite designVersion="v2" className={styles.addButton} presetIcon="plus2" onClick={onAddClick}>
              Add
            </ButtonLightPurpleWhite>
          )}
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.list}>
          <div className={styles.collaboratorsSection}>
            <div className={styles.sectionTitle}>{collaboratorsTableHeader}</div>
            <div className={styles.sectionList}>{collaborators?.map(collaborator => renderCollaboratorCell(collaborator))}</div>
          </div>
          {!!invitedCollaborators.length && (
            <div className={styles.invitedSection}>
              <div className={cs(styles.sectionTitle, styles.invitedTitle)}>Invited collaborators</div>
              <div className={styles.sectionList}>
                {invitedCollaborators.map(collaborator => renderInvitedCollaboratorCell(collaborator))}
              </div>
            </div>
          )}
        </div>
        {isTablet && (
          <ButtonCustom className={styles.buttonClose} onClick={onClose} outline>
            Close
          </ButtonCustom>
        )}
      </div>
    </div>
  );
};

export default CollaboratorsDropdown;
