import React, { FC, PropsWithChildren } from 'react';
import styles from './LightPurpleButton.module.scss';
import cs from 'classnames';

type PropsType = {
  className?: string;
  onClick: () => void;
  isSmall?: boolean;
  disabled?: boolean;
} & PropsWithChildren;

const LightPurpleButton: FC<PropsType> = ({ className, children, onClick = () => {}, isSmall, disabled }) => {
  return (
    <button
      className={cs(styles.viewButton, { [styles.small]: isSmall }, { [styles.disabled]: disabled }, className)}
      onClick={() => onClick()}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default LightPurpleButton;
