import React, { useMemo } from 'react';
import styles from './GuestUserPurpleBanner.module.scss';
import useSetUserModel from '../../../../shared/hooks/useSetUserModel';
import FullLogoSVG from '../../../../expert/components/IconsSvg/LogoSVG';
import { ReactComponent as SmallLogoSVG } from '../../../../assets/img/icons/otterfish_smal_logo.svg';

import ButtonCustom from '../../../../expert/components/Buttons/ButtonCustom';
import { useHistory } from 'react-router';
import { useAppSelector } from '../../../../shared/hooks/reduxHooks';
import { Organization } from '../../../../models/permissions/organization/Organization';
import { USER_COMMENTS_CONFIG } from '../../../../shared/constants/tsConstants';
import { ORGANIZATION_TYPES } from '../../../../models/enums/OrganizationTypes';
import UserCommentsSection from '../UserCommentsSection';
import { UserPermission } from '../../../../models/permissions/enum/UserPermission';
import { useMediaQuery } from 'react-responsive';
import cs from 'classnames';
import useTooltip, { TOOLTIP_POSITIONS } from '../../../../shared/hooks/fixedTooltip/useTooltip';
import { Guest } from '../../../../models/permissions/users/Guest';

type ConfigItemType = typeof USER_COMMENTS_CONFIG[keyof typeof USER_COMMENTS_CONFIG] & { imageClass: string };

const DEFAULT_USER_ORGANIZATION_TYPE = ORGANIZATION_TYPES.CONTENT_CREATOR;

const USER_COMMENTS_CONFIG_WITH_CLASSNAMES = Object.entries(USER_COMMENTS_CONFIG).reduce<
  Record<keyof typeof USER_COMMENTS_CONFIG, ConfigItemType>
>((acc, [key, item]) => {
  return {
    ...acc,
    [key]: {
      ...item,
      imageClass: key === ORGANIZATION_TYPES.CONTENT_CREATOR ? styles.oldOtterImage : styles.otterImageWithPhone,
    },
  };
}, {} as Record<keyof typeof USER_COMMENTS_CONFIG, ConfigItemType>);

const GuestUserPurpleBanner = () => {
  const organizationModel = useAppSelector<Organization | null>(state => state.auth?.currentOrganizationModel || null);
  const userModel = useAppSelector<Guest | null>(state => state.auth.userModel);
  const isTablet: boolean = useMediaQuery({ query: '(max-width: 768px)' });
  const setNewUserModel = useSetUserModel();
  const history = useHistory();

  const viewConfig = useMemo(() => {
    if (!organizationModel && !userModel?.sharedData?.payload?.collaboratorRole) {
      return USER_COMMENTS_CONFIG_WITH_CLASSNAMES[DEFAULT_USER_ORGANIZATION_TYPE];
    }

    if (userModel?.sharedData?.payload?.collaboratorRole) {
      return USER_COMMENTS_CONFIG_WITH_CLASSNAMES[userModel?.sharedData?.payload?.collaboratorRole];
    }

    if (organizationModel) {
      return USER_COMMENTS_CONFIG_WITH_CLASSNAMES[organizationModel.type];
    }

    return USER_COMMENTS_CONFIG_WITH_CLASSNAMES[DEFAULT_USER_ORGANIZATION_TYPE] as never;
  }, [organizationModel, userModel]);

  const {
    toolTip: signUpTooltip,
    onEnter: onSignUpEnter,
    onLeave: onSignUpLeave,
  } = useTooltip(TOOLTIP_POSITIONS.BOTTOM, {
    text: 'Click on Sign Up and Collaborate in the email you received to sign up for free',
    className: styles.tooltip,
  });

  if (!userModel?.hasAccess(UserPermission.CAN_SEE_PURPLE_BANNER)) return null;

  const LogoSVG = isTablet ? SmallLogoSVG : FullLogoSVG;

  return (
    <header className={styles.root}>
      <div className={styles.leftContent}>
        <LogoSVG
          className={styles.otterfishLogo}
          onClick={() => {
            history.push('/content-approval');
            setNewUserModel(null, true);
          }}
        />
        <h2 className={styles.heading}>
          Making <span className={styles.greenText}>Branded Content</span> Easy
        </h2>
        {userModel?.hasAccess(UserPermission.CAN_SEE_HEADER_SIGN_UP_BUTTON) && (
          <ButtonCustom
            onMouseEnter={onSignUpEnter}
            onMouseLeave={onSignUpLeave}
            onClick={() => history.push('/sign-up')}
            className={styles.whiteButton}
          >
            {signUpTooltip}
            Sign Up
          </ButtonCustom>
        )}
        {userModel?.hasAccess(UserPermission.CAN_SEE_HEADER_DASHBOARD_BUTTON) && (
          <ButtonCustom
            className={styles.whiteButton}
            onClick={() => {
              history.push('/content-approval');
              setNewUserModel(null, true);
            }}
          >
            Dashboard
          </ButtonCustom>
        )}
      </div>
      <div className={styles.otterfishImageWrapper}>
        <img className={cs(styles.otterfishImage, viewConfig.imageClass)} src={viewConfig.image} alt="Cool otterfish" />
      </div>
      <UserCommentsSection
        rootClassName={styles.userCommentsRoot}
        currentRate={viewConfig.currentRate}
        rateOf={viewConfig.rateOf}
        message={viewConfig.message}
        name={viewConfig.name}
        company={viewConfig.company}
      />
    </header>
  );
};

export default GuestUserPurpleBanner;
