import React, {FC} from "react";
import styles from "./EmptyState.module.scss";
import ButtonCustom from "../../../../expert/components/Buttons/ButtonCustom";
import {ReactComponent as UploadIcon} from '../../../../assets/img/icons/upload-purple-icon.svg';
import useMultipleFilesUpload from "../../hooks/useMultipleFilesUpload";
import {UPLOAD_RESULTS_FORM_INPUT_PROPS} from "../../components/results/UploadResultsForm";

type EmptyStateUploadPropsType = {
  onUpload: (files: File[]) => void;
}

const EmptyStateUpload: FC<EmptyStateUploadPropsType> = props => {
  const {onFileUpload, renderInput} = useMultipleFilesUpload(props.onUpload, 10, UPLOAD_RESULTS_FORM_INPUT_PROPS);
  return (
    <div className={styles.root}>
      <p className={styles.text}>
        Upload your results and we will notify your collaborators
      </p>
      <ButtonCustom
        className={styles.button}
        Icon={UploadIcon}
        onClick={onFileUpload}
        iconClassName={styles.uploadIcon}
      >
        {renderInput()}
        Upload Results
      </ButtonCustom>
    </div>
  )
}

export default EmptyStateUpload;
