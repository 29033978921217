import React, {FC} from 'react';
import styles from '../ResultsFilledCard/ResultsFilledCard.module.scss';
import ImagePlaceholderIconV1 from "../../../../../../../../components/IconsSvg/ImagePlaceholderIconV1";
import {useMediaQuery} from "react-responsive";

type PropsType = {};

const LoadingState: FC<PropsType> = props => {
  const isTablet = useMediaQuery({query: '(max-width: 768px)'});

  return (
    <div className={styles.root}>
      {!isTablet && (
        <div className={styles.mediaContainer} style={{background: '#ececff'}}>
        </div>
      )}
      <div className={styles.bottom}>
        <div className={styles.bottomLeft}>
          <ImagePlaceholderIconV1 className={styles.bottomLeftIcon}/>
          <p className={styles.bottomLeftText}>Results</p>
        </div>
      </div>
    </div>
  )
};

export default LoadingState;
