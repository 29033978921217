import React, { FC } from 'react';
import styles from './SelectCallToAction.module.scss';
import { ReactComponent as IconArrow } from '../../../../../../../../assets/img/icons/play-white-arrow.svg';
import { DELIVERABLE_CALL_TO_ACTIONS_TEXTS } from '../../../../../constants';
import SelectDropdown from '../../../../../../../../expert/components/FormControls/SelectDropdown';
import cs from 'classnames';
import SectionSubTitle from '../SectionSubTitle';
import { DELIVERABLE_CALL_TO_ACTIONS as DELIVERABLE_CALL_TO_ACTIONS_TYPE } from '../../../../../../../../models/entities/deliverable/enums/DeliverableCallToActions';

type PropsType = {
  className?: string;
  onSelect: (value: DELIVERABLE_CALL_TO_ACTIONS_TYPE | null) => void;
  value: DELIVERABLE_CALL_TO_ACTIONS_TYPE | null;
  disabled: boolean;
};

const SelectCallToAction: FC<PropsType> = ({ className, onSelect, value, disabled }) => {
  return (
    <div className={styles.root}>
      {disabled && <SectionSubTitle subTitle="Call to action" />}
      <SelectDropdown
        withNothing
        className={styles.callToAction}
        inputClassName={cs(styles.callToActionInput, className)}
        // @ts-ignore
        activeInputClassName={styles.callToActionInputActive}
        placeholderClassName={styles.placeholderClassName}
        dropdownClassName={styles.callToActionDropdown}
        placeholder={'None'}
        options={DELIVERABLE_CALL_TO_ACTIONS_TEXTS.map((call, idx) => ({ id: idx, name: call.label })) || []}
        selectedId={DELIVERABLE_CALL_TO_ACTIONS_TEXTS.findIndex(cta => cta.value === value)}
        onSelected={index => {
          if (index === -1) {
            onSelect(null);
          } else {
            onSelect(DELIVERABLE_CALL_TO_ACTIONS_TEXTS[index].value as DELIVERABLE_CALL_TO_ACTIONS_TYPE);
          }
        }}
        optionKey={option => option.id}
        renderOption={option => option.name}
        IconArrow={disabled ? () => <React.Fragment /> : CallToActionArrowIcon}
        disabled={disabled}
      />
    </div>
  );
};

const CallToActionArrowIcon = () => {
  return (
    <div className={styles.arrowIcon}>
      <IconArrow />
    </div>
  );
};

export default SelectCallToAction;
