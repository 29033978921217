import React, { ComponentProps, FC, MouseEvent, MutableRefObject, RefObject, useMemo, useState } from 'react';
import Tooltip from './Tooltip';

export enum TOOLTIP_POSITIONS {
  TOP = 'top',
  LEFT = 'left',
  BOTTOM = 'bottom',
  RIGHT = 'right',
}

type GetPositionFunctionType = (rect: DOMRect) => number;

type TooltipPositionSettingType = {
  [TOOLTIP_POSITIONS.TOP]: GetPositionFunctionType;
  [TOOLTIP_POSITIONS.LEFT]: GetPositionFunctionType;
};

type TooltipPositionSettingsType = Record<TOOLTIP_POSITIONS, TooltipPositionSettingType>;

type ToolTipComponentProps = ComponentProps<typeof Tooltip>;

export type ToolTipSettings<WrapperElementType extends HTMLElement = HTMLElement> = {
  customTooltip?: FC<ToolTipComponentProps & unknown>;
  className?: string;
  wrapperRef?: MutableRefObject<WrapperElementType> | RefObject<WrapperElementType> | null;
  text?: string;
};

const tooltipPositionSettings: TooltipPositionSettingsType = {
  top: {
    top: rect => rect.y,
    left: rect => rect.x + rect.width / 2,
  },
  right: {
    top: rect => rect.y + rect.height / 2,
    left: rect => rect.right,
  },
  bottom: {
    top: rect => rect.y + rect.height,
    left: rect => rect.x + rect.width / 2,
  },
  left: {
    top: rect => rect.y + rect.height / 2,
    left: rect => rect.left,
  },
};

function useTooltip<WrapperElementType extends HTMLElement = HTMLElement>(
  position: TOOLTIP_POSITIONS | TooltipPositionSettingType = TOOLTIP_POSITIONS.RIGHT,
  settings: ToolTipSettings<WrapperElementType> = {}
) {
  const [toolTip, setToolTip] = useState<null | JSX.Element>(null);

  const { top, left } = useMemo(() => {
    if (typeof position === 'string') {
      return tooltipPositionSettings[position];
    }

    return position;
  }, [position]);

  const getTooltipComponent: (props: ToolTipComponentProps) => JSX.Element = useMemo(() => {
    const CustomToolTip = settings?.customTooltip;

    if (CustomToolTip) {
      return props => <CustomToolTip {...props} />;
    }

    return props => <Tooltip {...props} />;
  }, [settings.customTooltip]);

  const onLeave = () => setToolTip(null);
  const onEnter = (event: MouseEvent<WrapperElementType>) => {
    const container = settings?.wrapperRef?.current ?? event.currentTarget;

    const rect = container.getBoundingClientRect();

    setToolTip(
      getTooltipComponent({
        top: top(rect),
        left: left(rect),
        text: settings.text,
        transformStyle: typeof position === 'string' ? position : null,
        className: settings.className,
      })
    );
  };

  return { toolTip, onEnter, onLeave };
}

export default useTooltip;
