/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React from 'react';
import styles from './TeamMemberCell.module.scss';
import avatar from '../../../assets/img/no-avatar.svg';
import cs from 'classnames';
import { useHistory } from 'react-router-dom';
import TeamMemberStatus from '../../statuses/TeamMemberStatus';
import { MEMBER_ROLE_IN_ORGANIZATION_TYPES } from '../../../models/enums/MemberRoleInOrganizationTypes';

const TeamMemberTabletCell = ({ member, org, user, handleRemove, canEditStatus, onChangeStatus }) => {
  const history = useHistory();

  const onRemove = () => {
    handleRemove(member);
  };

  const toMemberPage = () => {
    if (member.status_in_organization === 'pending') return;
    history.push(`/team-member/${member.user_id}`);
  };

  return (
    <div className={cs(styles.wrapper, styles.tablet)}>
      <div className={cs(styles.cell, styles.cell35)} onClick={toMemberPage}>
        <div className={styles.avatar}>
          <img src={member.photo_location || avatar} alt="avatar" />
        </div>
        <div className={cs(styles.nameWrapper, styles.tablet)}>
          <div className={cs(styles.name, styles.tablet)}>
            {member.first_name} {member.last_name}
            {!member.first_name && member.email}
          </div>
        </div>
      </div>
      <div className={cs(styles.cell, styles.cell25)}>
        {member?.role_in_organization || member?.status_in_organization ? (
          <TeamMemberStatus
            onSelect={role => onChangeStatus({ role, user_ids: [member.user_id] })}
            canEditStatus={canEditStatus}
            id={member.user_id}
            role_in_organization={member.role_in_organization}
            status={member.status_in_organization}
          />
        ) : null}
      </div>
      <div className={cs(styles.cell, styles.cell40)}>
        <div className={styles.email}>{member.email}</div>
      </div>
      {org.role?.toLowerCase() === MEMBER_ROLE_IN_ORGANIZATION_TYPES.OWNER && user.id !== member.user_id && (
        <div className={styles.removeButton} onClick={() => onRemove(member)}>
          Remove
        </div>
      )}
    </div>
  );
};

export default TeamMemberTabletCell;
