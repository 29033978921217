import React, { FC, useCallback, useEffect, useMemo } from 'react';
import styles from './SetupNotificationPreferencesForm.module.scss';
import NotificationPreferencesFormItem from '../NotificationPreferencesFormItem';
import useNotificationPreferencesFormSettings, {
  NotificationPreferencesFormikSubmitType,
} from '../../hooks/NotificationPreferencesFormSettings';
import cs from 'classnames';
import useHttp, { HTTP_METHODS_TYPE } from '../../../../../shared/hooks/useHttp';
import { getNotificationPreferences, updateNotificationPreferencesGroup } from '../../../../../services/NotificationPreferencesService';
import normalizeRequestedNotificationPreferences from '../../utils/normalizeRequestedNotificationPreferences';
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks/reduxHooks';
import normalizeFormikToUpdateGroupDTO from '../../utils/normalizeFormikToUpdateGroupDTO';
import notificationDataConfig from '../../../../../shared/utils/notificationDataConfig';
import { TOAST_NOTIFICATION_TYPES } from '../../../../../shared/constants/toastNotificationsData';
import { createNotification } from '../../../../../actions/toastNotificationActions/toastNotification.actions';
import SmallLoader from '../../../../../components/SmallLoader';

const SetupNotificationPreferencesForm: FC = () => {
  const userId = useAppSelector<number | null>(state => state.auth.user?.id || null);
  const dispatch = useAppDispatch();

  const {
    data: notificationPreferencesArray,
    isLoading: isPreferencesLoading,
    isFirstRequestStarted,
    request: refetchPreferences,
  } = useHttp(getNotificationPreferences, {
    method: HTTP_METHODS_TYPE.GET,
    args: userId ? [userId] : null,
    parseResultFn: normalizeRequestedNotificationPreferences,
  });

  const onPreferenceUpdateError = useCallback(() => {
    if (!userId) return;
    refetchPreferences(userId).then(() => {
      formik.resetForm();
    });

    const notification = notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.UPDATE_NOTIFICATION_PREFERENCES_ERROR, {});
    dispatch(createNotification(notification));
  }, [userId]);

  const {
    data: updatedNotificationPreferencesArray,
    request: updatePreferences,
    isLoading: isUpdateLoading,
    isSuccess,
    clearSuccess,
  } = useHttp(updateNotificationPreferencesGroup, {
    method: HTTP_METHODS_TYPE.POST,
    onError: onPreferenceUpdateError,
    parseResultFn: normalizeRequestedNotificationPreferences,
  });

  const currentNotificationPreferences = useMemo(
    () => updatedNotificationPreferencesArray || notificationPreferencesArray,
    [notificationPreferencesArray, updatedNotificationPreferencesArray]
  );

  const onSubmit = useCallback<NotificationPreferencesFormikSubmitType>(
    data => {
      if (!currentNotificationPreferences || userId == null || isUpdateLoading) return;

      const requestData = normalizeFormikToUpdateGroupDTO(currentNotificationPreferences, data);

      if (!requestData.length) return;

      updatePreferences(userId, requestData);
    },
    [userId, isUpdateLoading, currentNotificationPreferences]
  );

  const { textListSettings, formik } = useNotificationPreferencesFormSettings(onSubmit, notificationPreferencesArray);

  useEffect(
    function clearSuccessOnFormikChange() {
      if (isSuccess) {
        clearSuccess();
      }
    },
    [formik.values]
  );

  return (
    <div className={styles.root}>
      <h3 className={styles.header}>Uncheck the types of Emails you do not want to receive:</h3>
      {textListSettings.map(setting => {
        return (
          <NotificationPreferencesFormItem
            isLoading={isPreferencesLoading || !isFirstRequestStarted}
            key={setting.id}
            title={setting.title}
            text={setting.text}
            fieldName={setting.fieldName}
            formik={formik}
          />
        );
      })}
      {isSuccess ? (
        <h2 className={styles.successMessage}>Your preferences have been saved !!</h2>
      ) : (
        <div className={styles.buttonWrapper}>
          <button
            type="submit"
            onClick={() => formik.handleSubmit()}
            className={cs(styles.saveButton, { [styles.saveButtonDisabled]: (!formik.isValid && !formik.dirty) || isUpdateLoading })}
          >
            Save Preferences
            {isUpdateLoading ? <SmallLoader /> : null}
          </button>
        </div>
      )}
    </div>
  );
};

export default SetupNotificationPreferencesForm;
