import { User } from '../users/User';
import * as sharedService from '../../../services/shared';
import { AuthorizedUser } from '../users/AuthorizedUser';
import { AuthorizedGuest } from '../users/AuthorizedGuest';
import { UnauthorizedGuest } from '../users/UnauthorizedGuest';

export class UserModelService {
  isAuth = false;
  organizationId: number;
  path: string;

  constructor(isAuth: boolean, organizationId: number, path: string) {
    this.isAuth = isAuth;
    this.path = path;
    this.organizationId = organizationId;
  }

  async getUserModel(): Promise<User> {
    try {
      const parts = this.path.split('/').filter(item => !!item);
      if (!parts.length || parts.length < 3 || parts[0] !== 'shared') {
        return this.getAuthorizedUser();
      }

      const hash = parts[1];
      const route = parts[2];

      const response = await sharedService.checkSharedHash(hash);

      if (!response.data.length) {
        return this.getAuthorizedUser();
      }

      const hashObject = response.data[0];

      const Guest = this.isAuth ? AuthorizedGuest : UnauthorizedGuest;
      return new Guest(hashObject, [
        {
          route: `/${route}`,
          path: `/${route}/${hashObject.object_id}`,
        },
      ]);
    } catch (e) {
      return this.getAuthorizedUser();
    }
  }

  private getAuthorizedUser() {
    const user = new AuthorizedUser();
    if (this.organizationId) {
      user.organizationId = this.organizationId;
    }
    return user;
  }
}
