import { useCallback, useMemo } from 'react';
import moment from 'moment';
import { useAppSelector } from './reduxHooks';

const useUserTimezone = () => {
  const userTimezone = useAppSelector<null | { name: string }>(state => state.auth.userTimezone);

  const convertUtcDateToDateByTimezone = useCallback(
    date => {
      if (!date) return;
      if (userTimezone?.name) return moment.utc(date).tz(userTimezone && userTimezone?.name ? userTimezone.name : moment.tz.guess());
      return moment(date);
    },
    [userTimezone]
  );

  return useMemo(() => ({ userTimezone, convertUtcDateToDateByTimezone }), [userTimezone, convertUtcDateToDateByTimezone]);
};

export default useUserTimezone;
