export enum ContentBoardPermission {
  CAN_SEE_HEADER_INFO_AND_ACTIONS = 'CAN_SEE_HEADER_INFO_AND_ACTIONS',
  CAN_EDIT_BOARD_NAME = 'CAN_EDIT_BOARD_NAME',
  CAN_SEE_DELIVERABLE_ACTIONS = 'CAN_SEE_DELIVERABLE_ACTIONS',
  CAN_CREATE_NEW_DELIVERABLE = 'CAN_CREATE_NEW_DELIVERABLE',
  CAN_DOWNLOAD_ALL_DELIVERABLES_ASSETS = 'CAN_DOWNLOAD_ALL_DELIVERABLES_ASSETS',
  BOARD_DOCUMENTS_ONLY_VIEW_MODE = 'BOARD_DOCUMENTS_ONLY_VIEW_MODE',
  CAN_SEE_FILE_CARD_INFO_TOOLTIP = 'CAN_SEE_FILE_CARD_INFO_TOOLTIP',
  CAN_EDIT_LIVE_DUE_DATE = 'CAN_EDIT_LIVE_DUE_DATE',
  CAN_EDIT_REMINDER_DATE = 'CAN_EDIT_REMINDER_DATE',
  CAN_EDIT_RESULTS = 'CAN_EDIT_RESULTS',
  CAN_REQUEST_RESULTS = 'CAN_REQUEST_RESULTS',
}
