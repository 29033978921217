import React, { useState } from 'react';
import styles from './BreadcrumbsDropdown.module.scss';
import { useMediaQuery } from 'react-responsive/src';
import cs from 'classnames';

const BreadcrumbsDropdown = ({ items, onClick, pointAmount = 6 }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const isDesktopLandscape = useMediaQuery({
    query: '(min-width: 992px) and (orientation: landscape)',
  });
  const pointStr = '.';

  return (
    <span className={cs(styles.root, { [styles.rootMobile]: !isDesktopLandscape })} onMouseLeave={() => setIsDropdownOpen(false)}>
      <span onClick={event => event.stopPropagation()} onMouseEnter={() => setIsDropdownOpen(true)} className={styles.points}>
        {pointStr.repeat(pointAmount)}
      </span>
      <div className={styles.bridge} />
      {isDropdownOpen && (
        <div className={styles.dropdown}>
          <ul>
            {items.map((item, i) => (
              <li key={item.name + i} onClick={onClick ? onClick(item?.callback, i) : null}>
                {item.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </span>
  );
};

export default BreadcrumbsDropdown;
