import { UserPermission } from '../enum/UserPermission';
import { User } from './User';

export class AuthorizedUser extends User {
  organizationId: number | null = null;
  permissions: UserPermission[] = [
    UserPermission.CAN_SEE_DISCUSSION,
    UserPermission.CAN_SEE_HEADER_CONTENT_BOARD_DELIVERABLE_BREADCRUMBS,
    UserPermission.CAN_SEE_LEFT_ROOT_MENU,
    UserPermission.CAN_SEE_TOP_MENU_USER_ACTIONS,
    UserPermission.CAN_GET_BOARD_DATA,
    UserPermission.CAN_GET_BOARD_COLLABORATORS_DATA,
    UserPermission.CAN_GET_BOARD_INVITED_COLLABORATORS_DATA,
    UserPermission.CAN_GET_ORGANIZATIONS_DATA,
    UserPermission.CAN_GET_BOARD_DELIVERABLES_DATA,
    UserPermission.CAN_GET_ALL_BOARD_DELIVERABLES_ASSETS,
    UserPermission.CAN_GET_DELIVERABLE_ITEM_DATA,
    UserPermission.CAN_GET_DELIVERABLE_ITEM_SECTION_DATA,
    UserPermission.CAN_SEE_HEADER,
    UserPermission.CAN_SEE_CONTENT_BOARD_HEADER,
  ];
}
