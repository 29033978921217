import React, { FC, memo } from 'react';
import styles from '../SubTabOtherTerms.module.scss';
import EditorStyledField from '../../../../../../../../../components/EditorStyledField';
import useFormikDebouncedState from '../../../../../../../../../shared/hooks/useFormikDebouncedState';
import { FormikProps } from 'formik';
import { areEqualFormikState } from '../../../../../../../../../shared/utils/typescriptHelpers';

type PropsType = {
  formik: FormikProps<any>;
};

const propsAreEqual: Parameters<typeof memo<FC<PropsType>>>[1] = (prevProps, nextProps) => {
  return areEqualFormikState(['other_infos'], prevProps.formik, nextProps.formik);
};

const SubTabAdditional = memo(({ formik }: PropsType) => {
  const [otherInfos, setOtherInfos] = useFormikDebouncedState<string>(
    (value: string) => formik.setFieldValue('other_infos', value),
    formik.values.other_infos
  );

  return (
    <>
      <h2 style={{ marginBottom: 10 }}>
        Additional information <span className={styles.optionalText}>Optional</span>
      </h2>
      <p>If applicable share any additional information about your campaign here</p>
      <EditorStyledField
        toolbarClassName={styles.editorToolbar}
        wrapperClassName={styles.editorWrapperClassName}
        editorClassName={styles.editorClassName}
        placeholder={'Tip: Use Ctrl + Shift + V on windows or Cmd + Shift + V to paste your text with formatting'}
        onChange={text => {
          setOtherInfos(text);
        }}
        initialValue={otherInfos || ''}
        // @ts-ignore
        error={formik.errors.other_infos}
      />
    </>
  );
}, propsAreEqual);

export default SubTabAdditional;
