import React, { FC } from 'react';
import LoaderModal from '../../../../../expert/components/Modal/LoaderModal';
import { useAppSelector } from '../../../../../shared/hooks/reduxHooks';

const BoardLoaderModal: FC = () => {
  const isLoading = useAppSelector(state => state.contentBoard.contentBoardPatchLoading);
  return <LoaderModal open={isLoading} />;
};

export default BoardLoaderModal;
