import { INotificationPreferencesSetting } from '../NotificationPreferencesSettings';
import { NOTIFICATION_PREFERENCES_GROUP_TYPE } from '../enum/NotificationPreferencesGroupType';
import { NOTIFICATION_PREFERENCES_D_AND_W_TYPE } from '../enum/NotificationPreferencesDAndWType';
import { NOTIFICATION_PREFERENCES_CHAT_NOTIFICATIONS_TYPE } from '../enum/NotificationPreferencesChatNotificationsType';
import { NOTIFICATIONS_PREFERENCES_ACTIVITIES_TYPE } from '../enum/NotificationsPreferencesActivitiesType';
import { INotificationPreferencesSettingDTO } from '../dto/NotificationPreferencesSettings';

class NotificationPreferencesSettingHydrator<
  T extends
    | NOTIFICATIONS_PREFERENCES_ACTIVITIES_TYPE
    | NOTIFICATION_PREFERENCES_CHAT_NOTIFICATIONS_TYPE
    | NOTIFICATION_PREFERENCES_D_AND_W_TYPE,
  GroupType extends NOTIFICATION_PREFERENCES_GROUP_TYPE
> implements INotificationPreferencesSetting<T, GroupType>
{
  id: number;
  user_id: number;
  group_type: GroupType;
  type: T;
  is_enabled: boolean;
  created_at: string;
  updater_id: null | number;
  updated_at: string;

  constructor(dto: INotificationPreferencesSettingDTO<T, GroupType>) {
    this.id = dto.id;
    this.user_id = dto.user_id;
    this.group_type = dto.group_type;
    this.type = dto.type;
    this.is_enabled = dto.is_enabled;
    this.created_at = dto.created_at;
    this.updater_id = dto.updater_id;
    this.updated_at = dto.updated_at;
  }
}

export default NotificationPreferencesSettingHydrator;
