import React, { FC, ReactElement, ReactNode, useEffect, useMemo } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { DraggableAttributes } from '@dnd-kit/core';
import cs from 'classnames';

type SortableItemProps = {
  draggingClass?: string;
  defaultClass?: string;
  id: string | number;
  children: ReactNode | ReactElement;
  withHandler?: boolean;
  renderHandler?: (
    attributes: DraggableAttributes,
    setNodeRef: (node: HTMLElement | null) => void,
    listeners?: SyntheticListenerMap
  ) => ReactNode | ReactElement;
  setIsDragging?: (isDragging: boolean) => void;
};
const SortableItem: FC<SortableItemProps> = ({ id, setIsDragging, defaultClass, draggingClass, children, withHandler, renderHandler }) => {
  const { isDragging, attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } = useSortable({
    id,
  });

  const style = useMemo(() => {
    if (transform?.scaleX) {
      transform.scaleX = 1;
    }

    if (transform?.scaleY) {
      transform.scaleY = 1;
    }

    return {
      transform: CSS.Transform.toString(transform),
      transition: transition || undefined,
    };
  }, [transform, transition]);

  useEffect(() => {
    setIsDragging && setIsDragging(isDragging);
  }, [isDragging]);

  const wrapperListeners = useMemo(() => (withHandler ? null : listeners), [withHandler, listeners]);
  const wrapperAttributes = useMemo(() => (withHandler ? null : attributes), [withHandler, attributes]);

  return (
    <div
      className={cs({ [defaultClass || '']: !isDragging, [draggingClass || '']: isDragging })}
      style={{ pointerEvents: 'all', ...style }}
      {...wrapperAttributes}
      ref={setNodeRef}
      {...wrapperListeners}
    >
      {children}
      {withHandler && renderHandler && renderHandler(attributes, setActivatorNodeRef, listeners)}
    </div>
  );
};

export default SortableItem;
