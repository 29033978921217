import React from 'react';
import cs from 'classnames';

const Option = ({ children, className, active, activeClassName = '', disabled, title, onClick, value }) => {
  const handleClick = () => {
    if (!disabled) {
      onClick(value);
    }
  };
  return (
    <div
      className={cs('rdw-option-wrapper', className, {
        [`rdw-option-active ${activeClassName}`]: active,
        'rdw-option-disabled': disabled,
      })}
      onClick={handleClick}
      aria-selected={active}
      title={title}
    >
      {children}
    </div>
  );
};

export default Option;
