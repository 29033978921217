import React, { FC, ReactElement, ReactNode, useState } from 'react';
import ButtonLightPurpleWhite from '../Buttons/ButtonLightPurpleWhite';
import { useDispatch } from 'react-redux';
import { setDiscussionRoomIndex, setDiscussionsIsExpanded } from '../../actions/discussion.actions';
import { useAppSelector } from '../../shared/hooks/reduxHooks';

type PropsType = {
  collaborator: any;
  children: JSX.Element | ReactElement | ReactNode | string | null;
  disabled?: boolean;
};

const isPairRoomWithCollaborator = (room, collaborator, isAnyPair = false): boolean => {
  return (
    room.collaborators.length === 2 &&
    room.creator_id === '1' &&
    (isAnyPair || room.collaborators.some(c => c.organization_id === collaborator.organization_id))
  );
};

const GiveFeedbackButton: FC<PropsType> = ({ collaborator, children, disabled }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { discussionRoomsLoading, discussionRooms, isExpanded } = useAppSelector<any>(state => state.discussion);

  const openDiscussionWithCollaborator = (): void => {
    if (!collaborator) return;
    setIsLoading(true);

    let room = discussionRooms.find(room => isPairRoomWithCollaborator(room, collaborator));
    if (!room) {
      room = discussionRooms.find(room => isPairRoomWithCollaborator(room, collaborator, true));
    }
    if (room) {
      if (isExpanded) {
        dispatch(setDiscussionsIsExpanded(false));
      }
      const roomIndex = discussionRooms.findIndex(r => r.id === room.id);
      if (roomIndex !== -1) {
        dispatch(setDiscussionRoomIndex(roomIndex));
      }
    }
    setIsLoading(false);
  };

  return (
    <ButtonLightPurpleWhite
      disabled={discussionRoomsLoading || disabled || isLoading}
      designVersion="v2"
      presetIcon="feedback"
      size="small"
      onClick={openDiscussionWithCollaborator}
    >
      {children}
    </ButtonLightPurpleWhite>
  );
};

export default GiveFeedbackButton;
