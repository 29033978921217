import React from 'react';
import styles from './VisualBlock.module.scss';
import cs from 'classnames';
import { ReactComponent as EditSVG } from '../../../../../../../assets/img/icons/edit_pen.svg';
import { ReactComponent as TrashIcon } from '../../../../../../../assets/img/icons/trash.svg';
import EditorStyledField from '../../../../../../../components/EditorStyledField';
import { INPUT_FILE_ALLOWED_TYPE } from '../../../../../../../shared/constants/tsConstants';
import { validateImage } from '../../../../../../../shared/utils/helpers';

type PropsType = {
  imgUrl: string;
  description: string;
  setDescription: (value: string) => any;
  setFileImage: (file: File, newFileUrl: string) => any;
  deleteVisualElement: (...args: any) => any;
  deleteButton?: string;
  editButton?: string;
};

function VisualBlock({ imgUrl, description, setDescription, setFileImage, deleteVisualElement, deleteButton, editButton }: PropsType) {
  const handleUploadVisualElements = function (event) {
    const file = validateImage(event.target.files[0]);
    if (!file) return;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setFileImage(file, reader.result as string);
    };
  };

  return (
    <div className={styles.root}>
      <img className={styles.visualImg} src={imgUrl} alt={'your visual'} />
      <EditorStyledField
        toolbarClassName={styles.editorToolbar}
        wrapperClassName={styles.editorWrapperClassName}
        editorClassName={styles.editorClassName}
        initialValue={description}
        onChange={text => setDescription(text)}
        placeholder={'Share any details around the visuals'}
      />
      <div className={styles.buttons}>
        <span className={cs(styles.imgButton, { [styles.edit]: !editButton }, editButton)}>
          <input
            type="file"
            accept={INPUT_FILE_ALLOWED_TYPE.IMAGE_INPUT_ALLOWED}
            onChange={handleUploadVisualElements}
            className={styles.editImageInput}
          />
          <EditSVG />
        </span>
        <span
          className={cs(styles.imgButton, { [styles.delete]: !deleteButton }, deleteButton)}
          onClick={() => deleteVisualElement(imgUrl)}
        >
          <TrashIcon />
        </span>
      </div>
    </div>
  );
}

export default VisualBlock;
