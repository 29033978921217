/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import styles from './GenericCell.module.scss';
import { ReactComponent as EnvelopeIcon } from '../../../../assets/img/icons/envelope.svg';
import cs from 'classnames';

const GenericCell = ({ text, textWithJSX, timestamp, unread, onClick, action1 }) => {
  return (
    <div className={styles.wrapper} onClick={() => onClick && unread && onClick()}>
      <div className={styles.icon}>
        <EnvelopeIcon />
      </div>
      <div className={styles.contentWrapper}>
        {textWithJSX ? textWithJSX : <div className={styles.text}>{text}</div>}
        {action1 && (
          <div
            className={styles.actionButton}
            onClick={() => {
              action1.onClick();
            }}
          >
            {action1.text}
          </div>
        )}
      </div>

      <div className={cs(styles.timestamp, { [styles.unread]: unread })}>{timestamp}</div>
      {unread && <div className={styles.bullet} />}
    </div>
  );
};

export default GenericCell;
