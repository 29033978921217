import { useCallback, useState } from 'react';
import { IBoardBriefFormikValues } from '../../../../models/entities/boardBrief/BoardBriefFormikValues';
import { FormikProps } from 'formik';
import { ICreateBoardFormikValues } from '../CreateBoardWrapper/CreateBoard/useCreateBoardFormik';
import { UseBriefVisualAssetsReturnType } from '../CreateBoardWrapper/CreateBoardBrief/useBriefVisualAssets';

type UseBoardFormHandlersType = (
  createBoardFormik: FormikProps<ICreateBoardFormikValues>,
  boardBriefFormik: FormikProps<IBoardBriefFormikValues>,
  briefFormAssets: UseBriefVisualAssetsReturnType['briefFormAssets']
) => {
  briefFormSubmit: () => void;
  briefFormCancel: () => void;
  onEditBrief: () => void;
  onDeleteBrief: () => void;
  onCreateBriefPage: () => void;
  page: 'board' | 'brief';
};

const useBoardFormHandlers: UseBoardFormHandlersType = (createBoardFormik, boardBriefFormik, briefFormAssets) => {
  const [page, setPage] = useState<'board' | 'brief'>('board');

  const briefFormSubmit = useCallback(() => {
    createBoardFormik.setFieldValue('createdBrief', { ...boardBriefFormik.values });
    setPage('board');
  }, [boardBriefFormik.values]);

  const briefFormCancel = useCallback(() => {
    setPage('board');
    boardBriefFormik.setValues({ ...boardBriefFormik.initialValues });
    boardBriefFormik.setTouched({ ...boardBriefFormik.initialTouched });
    boardBriefFormik.setErrors({ ...boardBriefFormik.initialErrors });
    briefFormAssets.setValue([]);
  }, [boardBriefFormik.initialValues, boardBriefFormik.initialTouched, boardBriefFormik.initialErrors]);

  const onEditBrief = useCallback(() => {
    setPage('brief');
    const briefValues = createBoardFormik.values.createdBrief as IBoardBriefFormikValues;
    boardBriefFormik.setValues({ ...briefValues });
  }, [createBoardFormik.values.createdBrief]);

  const onDeleteBrief = useCallback(() => {
    createBoardFormik.setFieldValue('createdBrief', null);
    boardBriefFormik.setValues({ ...boardBriefFormik.initialValues });
    briefFormAssets.setValue([]);
  }, [boardBriefFormik.initialValues]);

  const onCreateBriefPage = useCallback(() => {
    setPage('brief');
  }, []);

  return {
    briefFormSubmit,
    briefFormCancel,
    onEditBrief,
    onDeleteBrief,
    onCreateBriefPage,
    page,
  };
};

export default useBoardFormHandlers;
