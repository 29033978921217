import React, { FC, useRef } from 'react';
import styles from './FilledDocumentCard.module.scss';
import ThreeDotsButton from '../../../../../../../components/ThreeDotsButton';
import { DOCUMENT_CARD_VIEW_TYPES } from '../../types/DocumentCardViewTypes';
import useFilledDocumentCardConfig from '../../hooks/useFilledDocumentCardConfig';
import { useMediaQuery } from 'react-responsive/src';
import CardInfoIcon from './CardInfoIcon';
import cs from 'classnames';

type PropsType = {
  className?: string;
  documentType: DOCUMENT_CARD_VIEW_TYPES;
  onDelete: () => void;
  onReplace?: () => void;
  onDownload?: () => void;
  onEdit?: () => void;
  documentUrl: string | null;
  documentName: string | null;
  hoverText: string;
  cardTypeText: string;
  hasActions: boolean;
  isLoading: boolean;
  isOnlyView?: boolean;
  isOnlyPreview?: boolean;
};

const FilledDocumentCard: FC<PropsType> = props => {
  const buttonRef = useRef<HTMLDivElement | null>(null);
  const settings = useFilledDocumentCardConfig(
    props.documentType,
    {
      onDelete: props.onDelete,
      onEdit: props.onEdit,
      onDownload: props.onDownload,
      onReplace: props.onReplace,
    },
    props.isOnlyView,
    props.isOnlyPreview
  );
  const isTablet: boolean = useMediaQuery({ query: '(max-width: 768px)' });

  const onHoverClick = () => {
    if (!props.documentUrl || props.isOnlyPreview) return;

    window.open(props.documentUrl, '_blank')?.focus();
  };

  const onMobileCardClick = () => {
    if (!props.documentUrl || props.isOnlyPreview || !isTablet) return;

    if (props.documentType !== DOCUMENT_CARD_VIEW_TYPES.OTTERFISH_BRIEF && buttonRef.current) {
      buttonRef.current?.click();
      return;
    }

    if (props.documentType === DOCUMENT_CARD_VIEW_TYPES.OTTERFISH_BRIEF) {
      window.open(props.documentUrl, '_blank')?.focus();
      return;
    }
  };

  return (
    <div className={cs(styles.root, props.className)} onClick={isTablet ? onMobileCardClick : onHoverClick}>
      {!isTablet && (
        <div className={styles.cardBody}>
          <div className={styles.hoverState}>
            <CardInfoIcon documentType={props.documentType} />
            <p className={styles.hoverStateText}>{props.hoverText}</p>
          </div>
          <img className={styles.documentImage} src={settings.documentImage} alt={'document'} />
        </div>
      )}
      <div className={styles.cardFooter}>
        <settings.image className={cs({ [styles.grayFileImage]: props.isOnlyPreview })} />
        <div className={styles.textWrapper}>
          <h5 className={styles.cardName}>{props.documentName}</h5>
          <p className={styles.cardType}>{props.cardTypeText}</p>
        </div>
        <div className={styles.buttonsWrapper}>
          {isTablet && !props.isOnlyPreview ? (
            <div className={styles.infoIconWrapper}>
              <CardInfoIcon documentType={props.documentType} />
            </div>
          ) : null}
          {props.hasActions && settings.options.length ? (
            <ThreeDotsButton ref={buttonRef} dropdownClassName={styles.actionButtonDropDown} options={settings.options} />
          ) : null}
          {props.isOnlyPreview && isTablet ? <p className={styles.tabletPreviewText}>{props.hoverText}</p> : null}
        </div>
      </div>
    </div>
  );
};

export default FilledDocumentCard;
