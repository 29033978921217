import React, { FC } from 'react';
import styles from './ApproveButton.module.scss';
import GreenRedButton from '../../../../../../../../../components/Buttons/GreenRedButton';
import useTooltip, { TOOLTIP_POSITIONS, ToolTipSettings } from '../../../../../../../../../shared/hooks/fixedTooltip/useTooltip';

type PropsType = {
  disabled?: boolean;
  buttonText?: string;
  approveButtonTooltipSettings?: ToolTipSettings | null;
  onClick: () => void;
};

const ApproveButton: FC<PropsType> = ({ disabled, buttonText, approveButtonTooltipSettings, onClick }) => {
  const { toolTip, onEnter, onLeave } = useTooltip<HTMLDivElement>(TOOLTIP_POSITIONS.BOTTOM, {
    text: approveButtonTooltipSettings ? approveButtonTooltipSettings.text : '',
    className: styles.tooltip,
  });

  return (
    <div onMouseEnter={onEnter} onMouseLeave={onLeave}>
      <GreenRedButton className={styles.approveButton} color="green" size="small" disabled={disabled} onClick={onClick}>
        {buttonText}
      </GreenRedButton>
      {approveButtonTooltipSettings && toolTip}
    </div>
  );
};

export default ApproveButton;
