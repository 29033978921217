import { COLLABORATOR_ROLE_TYPES, CollaboratorRole } from '../collaborator/CollaboratorRole';
import { IFlow } from '../flows/Flow';

export abstract class Page<Permission> {
  protected abstract permissions: Permission[];
  protected appliedFlows: IFlow<Permission>[] = [];
  role: CollaboratorRole<Permission>;

  protected constructor(roleType: COLLABORATOR_ROLE_TYPES, permissions?: Permission[]) {
    this.role = new CollaboratorRole<Permission>(roleType, permissions);
  }

  init(): void {
    this.role.setPermissions(this.permissions);
  }

  applyFlow(flow: IFlow<Permission>): Page<Permission> {
    if (this.appliedFlows.find(f => f.type === flow.type)) {
      return this;
    }

    const permissions = flow.getPermissions(this.role.type);

    if (permissions) {
      const newPermissions = [...this.role.permissions, ...permissions].filter((value, index, self) => self.indexOf(value) !== index);
      this.role.setPermissions(newPermissions);
      this.appliedFlows.push(flow);
    }

    return this;
  }

  roleCan(permission: Permission): boolean {
    return this.role.hasAccess(permission);
  }
}
