import Moment from 'moment/moment';
import moment, { Moment as IMoment } from 'moment/moment';
import { extendMoment } from 'moment-range';
import { dateWithoutTimeFormat } from '../constants/timeFormats';

const rangeMoment = extendMoment(Moment);

const getDefaultNotificationDay = (minCalendarReminderDate: string | null, maxCalendarReminderDate: string | null): null | IMoment => {
  if (!minCalendarReminderDate || !maxCalendarReminderDate) return null;
  const maxDate = moment.utc(moment.utc(maxCalendarReminderDate).format(dateWithoutTimeFormat));
  let minDate = moment.utc(moment.utc(minCalendarReminderDate).format(dateWithoutTimeFormat));

  const startOfMaxMonth = moment.utc(maxCalendarReminderDate).startOf('month');
  const monthRange = maxDate.month() - minDate.month();

  if (monthRange !== 0 && !moment.utc(startOfMaxMonth)?.isSame(moment.utc(maxDate))) {
    minDate = startOfMaxMonth;
  }

  const daysRange: number = rangeMoment.range(moment.utc(minDate), moment.utc(maxDate)).diff('days');

  if (daysRange <= 0) return null;

  if (daysRange > 3) return moment.utc(maxCalendarReminderDate).subtract(3, 'days');

  if (daysRange <= 3) return moment.utc(maxCalendarReminderDate).subtract(daysRange, 'days');

  return undefined as never;
};

export default getDefaultNotificationDay;
