import React, { FC } from 'react';
import UpcomingDateItemLoadingState from '../../UpcomingDateItem/UpcomingDateItemLoadingState';

const UpcomingDatesListLoadingState: FC = () => {
  return (
    <>
      <UpcomingDateItemLoadingState />
      <UpcomingDateItemLoadingState />
      <UpcomingDateItemLoadingState />
    </>
  );
};

export default UpcomingDatesListLoadingState;
