import React, {FC} from 'react';
import styles from './ResultsFilledCard.module.scss';
import ImagePlaceholderIconV1 from "../../../../../../../../components/IconsSvg/ImagePlaceholderIconV1";
import {IResultAsset} from "../../../../../../../../models/entities/asset/ResultAsset";
import {useHistory, useParams} from 'react-router';
import {isImage} from "../../../../../../components/results/utils";
import ResultDropdownButton from "../../../../../../components/results/ResultDropdownButton";

type PropsType = {
  assets: IResultAsset[];
  onUpload: (files: File[]) => void;
};


const ResultsFilledCard: FC<PropsType> = props => {
  const history = useHistory()
  const params = useParams<{ boardId: string }>();

  const firstAsset = props.assets[0];
  const src = firstAsset.location || firstAsset.preview_location || firstAsset.thumbnail_location;

  return (
    <div onClick={() => history.push(`/content-board/${params.boardId}/results`)} className={styles.root}>
      <div className={styles.mediaContainer}>
        <div className={styles.hoverContainer}>
          <p className={styles.hoverText}>View Results</p>
        </div>
        {isImage(props.assets[0].mime_type) ?
          <img
            src={src}
            className={styles.media}
            alt="asset"
          />
          : <video
            className={styles.media}
            src={src}
            controls={false}
            loop={true}
            autoPlay={true}
            muted={true}
          />
        }
      </div>
      <div className={styles.bottom}>
        <div className={styles.bottomLeft}>
          <ImagePlaceholderIconV1 className={styles.bottomLeftIcon}/>
          <p className={styles.bottomLeftText}>Results</p>
        </div>
        <ResultDropdownButton assets={props.assets} onUpload={props.onUpload}/>
      </div>
    </div>
  )
};

export default ResultsFilledCard;
