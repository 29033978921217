import _ from 'lodash';
import { FormikConfig, FormikProps, useFormik } from 'formik';
import { NOTIFICATION_PREFERENCES_GROUP_TYPE } from '../../../../models/entities/notificationPreferences/enum/NotificationPreferencesGroupType';
import { NotificationPreferencesArrayType } from '../../../../models/entities/notificationPreferences/NotificationPreferences';
import { useMemo } from 'react';

export interface INotificationPreferencesTextSettings {
  id: string;
  title: string;
  text: string;
  fieldName: NOTIFICATION_PREFERENCES_GROUP_TYPE;
}

const TEXT_SETTINGS: INotificationPreferencesTextSettings[] = [
  {
    id: _.uniqueId('setup_notification_preferences_'),
    title: 'Activities',
    text: 'Receive notifications about Content boards and Briefs activities such as approving and new content upload.',
    fieldName: NOTIFICATION_PREFERENCES_GROUP_TYPE.ACTIVITIES,
  },
  {
    id: _.uniqueId('setup_notification_preferences_'),
    title: 'Daily and Weekly updates',
    text: 'Receive notifications about weekly or daily activities.',
    fieldName: NOTIFICATION_PREFERENCES_GROUP_TYPE.DAILY_AND_WEEKLY_UPDATES,
  },
  {
    id: _.uniqueId('setup_notification_preferences_'),
    title: 'Chat notifications',
    text: 'Receive notifications about any messages in your Deliverables or Briefs discussion rooms.',
    fieldName: NOTIFICATION_PREFERENCES_GROUP_TYPE.CHAT_NOTIFICATIONS,
  },
];

export type NotificationPreferencesFormikValuesType = {
  notificationPreferences: NOTIFICATION_PREFERENCES_GROUP_TYPE[];
};

export type NotificationPreferencesFormikType = FormikProps<NotificationPreferencesFormikValuesType>;

export type NotificationPreferencesFormSettingsType = {
  textListSettings: INotificationPreferencesTextSettings[];
  formik: NotificationPreferencesFormikType;
};

export type NotificationPreferencesFormikSubmitType = FormikConfig<NotificationPreferencesFormikValuesType>['onSubmit'];

type UseNotificationPreferencesFormSettingsType = (
  onSubmit: NotificationPreferencesFormikSubmitType,
  preferences?: NotificationPreferencesArrayType | null
) => NotificationPreferencesFormSettingsType;

const useNotificationPreferencesFormSettings: UseNotificationPreferencesFormSettingsType = (onSubmit, preferences) => {
  const initialValues: NotificationPreferencesFormikValuesType = useMemo(() => {
    const initial: NotificationPreferencesFormikValuesType = {
      notificationPreferences: [],
    };

    if (!preferences) {
      return initial;
    }

    preferences.forEach(preferenceGroup => {
      if (preferenceGroup.group_enabled) {
        initial.notificationPreferences.push(preferenceGroup.group_type);
      }
    });

    return initial;
  }, [preferences]);

  const formik = useFormik<NotificationPreferencesFormikValuesType>({
    initialValues,
    enableReinitialize: true,
    onSubmit: onSubmit,
  });

  return {
    formik,
    textListSettings: TEXT_SETTINGS,
  };
};

export default useNotificationPreferencesFormSettings;
