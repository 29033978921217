import React, { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from '../../../shared/hooks/reduxHooks';
import ERROR_TYPE from '../../../models/entities/error/ErrorTypes';
import ErrorLimitModal from '../ErrorLimitModal';
import {useHistory} from "react-router-dom";

const LimitAfterSignInModal = () => {
  const history = useHistory();

  const isAuth = useAppSelector(state => !!state.auth.token);
  const currentPlan = useAppSelector(state => state.auth.currentOrganizationPlan);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [wasOpen, setWasOpen] = useState<boolean>(false);
  const [limitType, setLimitType] = useState<ERROR_TYPE>(ERROR_TYPE.STORAGE_LIMIT_ERROR);

  const checkLimits = useCallback(() => {
    if (!currentPlan || history.location.pathname === '/checkout-success') return false;

    if (+currentPlan.storage !== -1 && +currentPlan.storage - currentPlan.usage.storage < 100000) {
      setLimitType(ERROR_TYPE.STORAGE_LIMIT_ERROR);
      setIsOpen(true);
      return true;
    }

    if (
      +currentPlan.content_board_create_limit !== -1 &&
      +currentPlan.content_board_create_limit - currentPlan.usage.content_board_created === 0
    ) {
      setLimitType(ERROR_TYPE.CONTENT_BOARD_CREATE_LIMIT_ERROR);
      setIsOpen(true);
      return true;
    }

    if (
      +currentPlan.content_board_collaborate_limit !== -1 &&
      +currentPlan.content_board_collaborate_limit - currentPlan.usage.content_board_collaborated === 0
    ) {
      setLimitType(ERROR_TYPE.CONTENT_BOARD_COLLABORATE_LIMIT_ERROR);
      setIsOpen(true);
      return true;
    }

    if (
      +currentPlan.campaign_brief_create_limit !== -1 &&
      +currentPlan.campaign_brief_create_limit - currentPlan.usage.campaign_brief_created === 0
    ) {
      setLimitType(ERROR_TYPE.CAMPAIGN_BRIEF_ITEM_CREATE_LIMIT_ERROR);
      setIsOpen(true);
      return true;
    }

    if (
      +currentPlan.campaign_brief_collaborate_limit !== -1 &&
      +currentPlan.campaign_brief_collaborate_limit - currentPlan.usage.campaign_brief_collaborated === 0
    ) {
      setLimitType(ERROR_TYPE.CAMPAIGN_BRIEF_ITEM_COLLABORATE_LIMIT_ERROR);
      setIsOpen(true);
      return true;
    }

    return true;
  }, [currentPlan]);

  useEffect(() => {
    if (isAuth && !wasOpen) {
      const res = checkLimits();
      if (res) {
        setWasOpen(true);
      }
    } else if (!isAuth) {
      setWasOpen(false);
    }
  }, [isAuth, wasOpen, checkLimits, currentPlan]);

  return <ErrorLimitModal isOpen={isOpen} manualType={limitType} onClose={() => setIsOpen(false)} />;
};

export default LimitAfterSignInModal;
