import React, { useState } from 'react';
import styles from './VideoAttachment.module.scss';
import { Modal } from '../../../../../../components/Modals';
import VideoTag from '../../../../../../components/VideoPlayers/VideoTag';

const VideoAttachment = ({ attachment }) => {
  const [modalState, setModalState] = useState(false);
  const videoUrl = attachment.fileUrl || attachment.location;
  const thumbnail = attachment.thumbnail_location;

  return (
    <>
      <div className={styles.root}>
        <img src={thumbnail} className={styles.messageImage} alt={''} />
        <div className={styles.showImageIcon} onClick={() => setModalState(true)}>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 14H0L0.007 7H2V12H7V14ZM13.992 7H12V2H7V0H14L13.992 7Z" fill="#4E3B8B" />
          </svg>
        </div>
      </div>
      <Modal open={modalState} onClose={() => setModalState(false)} maxWidth={'920px'} className={styles.modalRoot}>
        <div className={styles.modalContentWrapper}>
          <VideoTag className={styles.videoBackground} src={videoUrl} />
          <VideoTag className={styles.video} src={videoUrl} controls />
        </div>
        {/* <div className={styles.closeIcon} onClick={() => setModalState(false)}>*/}
        {/*  <CloseIcon />*/}
        {/* </div>*/}
      </Modal>
    </>
  );
};

export default VideoAttachment;
