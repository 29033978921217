import { IBoardListItem } from '../BoardListItem';
import { BOARD_STATUTES_TYPES } from '../enums/boardStatuses';
import { ICollaborator } from '../../collaborator/Collaborator';
import { BOARD_BRIEF_ITEM_TYPES } from '../enums/boardBriefItemType';
import { ITextFile } from '../../file/TextFile';
import { BOARD_DOCUMENT_STATES_TYPE } from '../enums/bordDocumentStatesType';
import { IBoardListItemDTO } from '../dto/BoardListItem';
import { TextFileHydrator } from '../../file/hydrators/TextFileHydrator';

export class BoardListItemHydrator implements IBoardListItem {
  // main info
  id: number;
  name: string;
  status: BOARD_STATUTES_TYPES;
  collaborators: ICollaborator[];
  // creator info
  created_at: string;
  creator_id: string;
  // updater info
  updated_at: null | string;
  updater_id: null | string | number;

  is_archived: boolean;
  // brand info
  brand_name: null | string;
  brand_description: null | string;
  brand_logo_file_id: null | number;
  brand_logo_location: null | string;
  brand_logo_thumbnail_file_id: null | number;
  brand_logo_thumbnail_location: null | string;
  brand_provides_product: null | string;

  // brief and brief item
  campaign_brief_id: number | null;
  campaign_brief_item_id: number | null;
  campaign_brief_item_name: null | string;
  campaign_brief_item_type: BOARD_BRIEF_ITEM_TYPES | null;
  campaign_brief_name: null | string;
  campaign_brief_parent_id: number | null;
  // text info
  key_message: null | string;
  notes: null | string;
  // added info

  briefFile: null | ITextFile;
  contractFile: null | ITextFile;

  // documents states
  states: {
    contract_file_state: BOARD_DOCUMENT_STATES_TYPE;
    brief_file_state: BOARD_DOCUMENT_STATES_TYPE;
    campaign_brief_state: BOARD_DOCUMENT_STATES_TYPE;
  };

  constructor(dto: IBoardListItemDTO) {
    this.id = dto.id;
    this.name = dto.name;
    this.status = dto.status;
    this.collaborators = dto.collaborators;
    this.created_at = dto.created_at;
    this.creator_id = dto.creator_id;
    this.updated_at = dto.updated_at;
    this.updater_id = dto.updater_id;
    this.is_archived = dto.is_archived;
    this.brand_name = dto.brand_name;
    this.brand_description = dto.brand_description;
    this.brand_logo_file_id = dto.brand_logo_file_id;
    this.brand_logo_location = dto.brand_logo_location;
    this.brand_logo_thumbnail_file_id = dto.brand_logo_thumbnail_file_id;
    this.brand_logo_thumbnail_location = dto.brand_logo_thumbnail_location;
    this.brand_provides_product = dto.brand_provides_product;
    this.campaign_brief_id = dto.campaign_brief_id;
    this.campaign_brief_item_id = dto.campaign_brief_item_id;
    this.campaign_brief_item_name = dto.campaign_brief_item_name;
    this.campaign_brief_item_type = dto.campaign_brief_item_type;
    this.campaign_brief_name = dto.campaign_brief_name;
    this.campaign_brief_parent_id = dto.campaign_brief_parent_id;
    this.key_message = dto.key_message;
    this.notes = dto.notes;

    this.briefFile = this.getFile(
      dto.brief_file_id,
      dto.brief_file_name,
      dto.brief_file_extension,
      dto.brief_file_location,
      dto.brief_file_mime_type,
      dto.brief_file_size
    );

    this.contractFile = this.getFile(
      dto.contract_file_id,
      dto.contract_file_name,
      dto.contract_file_extension,
      dto.contract_file_location,
      dto.contract_file_mime_type,
      dto.contract_file_size
    );

    this.states = dto.states;
  }

  private getFile(
    id: string | number | null,
    name: string | null,
    extension: string | null,
    url: string | null,
    mimeType: string | null,
    size: string | number | null
  ) {
    if (!id || !name || !extension || !url || !mimeType || !size) return null;

    return new TextFileHydrator(String(id), name, extension, url, mimeType, size);
  }
}

export default BoardListItemHydrator;
