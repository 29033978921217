import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { confirmSignUp, getInviteDetails } from '../../../../actions/user.actions';
import ErrorModal from '../../../../expert/components/Modal/ErrorModal';
import { clearFailure, signOut } from '../../../../actions/auth.actions';
import ProfileDetailsForm from '../../SignUpNew/ProfileDetailsForm';
import ExpiredBriefInviteModal from '../../../../components/Modals/ExpiredBriefInviteModal';
import dayjs from 'dayjs';
import {ORGANIZATION_DEFAULT_NAME_START} from "../../SignUpNew";

const CompleteProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(history.location.search);
  const urlToken = searchParams.get('inviteToken');
  const inviteType = searchParams.get('inviteType');
  const organizationType = searchParams.get('organizationType');
  const error = useSelector(state => state.auth.failure);
  const inviteDetails = useSelector(state => state.user.invite?.data);
  const dateToday = dayjs();
  const [isSignUpLoading, setIsSignUpLoading] = useState(false);
  const [isOpenExpiredInviteModal, setIsOpenExpiredInviteModal] = useState(dateToday.diff(dayjs(inviteDetails?.expires_at)) > 0);

  useEffect(() => {
    dispatch(getInviteDetails(urlToken, inviteType));
  }, []);

  useEffect(() => {
    setIsOpenExpiredInviteModal(dateToday.diff(dayjs(inviteDetails?.expires_at)) > 0);
  }, [inviteDetails]);

  const goHome = () => {
    dispatch(signOut());
    history.push('/login');
  };

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      phone_number: '64',
      password: '',
      confirm_password: '',
      ...(inviteType !== 'organization_user' && { company_name: '', company_type: organizationType }),
      invite_token: urlToken || '',
      invite_type: inviteType || '',
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required('First name is required'),
      last_name: Yup.string().required('Last name is required'),
      phone_number: Yup.string().required('Phone number name is required'),
      ...(inviteType !== 'organization_user' && {
        // company_name: Yup.string().required('Company name is required'),
        company_type: Yup.string().required('Company type is required'),
      }),
      password: Yup.string().required('Password is required').min(6, 'Password must be at least 6 characters'),
      confirm_password: Yup.string()
        .required('Password is required')
        .min(6, 'Password must be at least 6 characters')
        .oneOf([Yup.ref('password'), null], "Passwords don't match."),
    }),
  });

  const handleSignUp = async isChecked => {
    setIsSignUpLoading(true);
    const data = {
      invite_token: formik.values.invite_token,
      invite_type: formik.values.invite_type,
      first_name: formik.values.first_name,
      last_name: formik.values.last_name,
      phone_number: `+${formik.values.phone_number}`,
      password: formik.values.password,
      marketing_consent: isChecked,
      // confirm_password: values.confirm_password,
      ...(inviteType !== 'organization_user' && {
        organization_name: ORGANIZATION_DEFAULT_NAME_START + new Date().getTime(),
        organization_type: formik.values.company_type,
      }),
    };

    await dispatch(confirmSignUp(data, false, inviteType)).then(() => {
      setIsSignUpLoading(false);
      if (inviteType !== 'organization_user') {
        history.push('/upgrade-plan');
      } else {
        history.push('/');
      }
    });
  };

  const handleCloseErrorModal = () => {
    if (error.status === 401) {
      const params = new URLSearchParams(location.search);
      const inviteToken = params.get('inviteToken');
      history.push(`/?inviteToken=${inviteToken}`);
    }
    dispatch(clearFailure());
  };

  return (
    <>
      <ProfileDetailsForm
        formik={formik}
        onSignUp={handleSignUp}
        invite
        hasCompany={inviteType !== 'organization_user'}
        isSignUpLoading={isSignUpLoading}
      />
      <ErrorModal
        open={Object.keys(error).length > 0}
        onClose={handleCloseErrorModal}
        title={error.status === 401 ? 'In order to accept the invite please login' : 'Oops, something went wrong'}
        text={error.status === 401 ? '' : 'Maybe this invite is already used'}
      />
      {Object.keys(inviteDetails).length > 0 && (
        <ExpiredBriefInviteModal
          open={isOpenExpiredInviteModal}
          inviteDetails={inviteDetails}
          onSubmit={() => goHome()}
          onClose={() => goHome()}
        />
      )}
    </>
  );
};

export default CompleteProfile;
