import React, { FC } from 'react';
import LoadingContainer from '../../../../../../../../../../components/LoadingContainer';

const UpcomingDateItemLoadingState: FC = () => {
  return (
    <li style={{ margin: '11px 0 11px' }}>
      <LoadingContainer width={300} height={13} margin={'0 0 6px'} borderRadius={'5px'} />
      <div style={{ display: 'flex', alignItems: 'center', columnGap: '5px' }}>
        <LoadingContainer width={6} height={6} borderRadius={'100%'} />
        <LoadingContainer width={100} height={10} borderRadius={'5px'} />
      </div>
    </li>
  );
};

export default UpcomingDateItemLoadingState;
