import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useSubscribeChannel from '../../../centrifuge/useSubscribeChannel';
import {
  getUpcomingLiveDate,
  pushBoardDeliverables,
  updateBoardDeliverableItem,
  updateOpenedContentBoard,
} from '../../../actions/ContentBoardActions/contentBoard.actions';
import 'moment-timezone';
import { extendMoment } from 'moment-range';
import Moment from 'moment/moment';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks/reduxHooks';
import { IDeliverableDTO } from '../../../models/entities/deliverable/dto/Deliverable';
import { ISocketBoardDTO } from '../../../models/entities/board/dto/SocketBoard';

const moment = extendMoment(Moment);

enum SUBSCRIBE_ACTIONS {
  CONTENT_BOARD_DELIVERABLE_REFRESH = 'content_board_deliverable_refresh',
  CONTENT_BOARD_REFRESH = 'content_board_refresh',
  CONTENT_BOARD_CREATE_DELIVERABLES_REFRESH = 'content_board_create_deliverables_refresh',
}

type DeliverableRefreshSocketActionType = { data: { action: SUBSCRIBE_ACTIONS.CONTENT_BOARD_DELIVERABLE_REFRESH; data: IDeliverableDTO } };
type ContentBoardRefreshSocketActionType = { data: { action: SUBSCRIBE_ACTIONS.CONTENT_BOARD_REFRESH; data: ISocketBoardDTO } };
type CreateDeliverableRefreshSocketActionType = {
  data: { action: SUBSCRIBE_ACTIONS.CONTENT_BOARD_CREATE_DELIVERABLES_REFRESH; data: { deliverables: IDeliverableDTO[] } };
};

type BoardSocketsActionsType =
  | DeliverableRefreshSocketActionType
  | ContentBoardRefreshSocketActionType
  | CreateDeliverableRefreshSocketActionType;

const useContentBoardSockets = () => {
  const dispatch = useAppDispatch();
  const params: { boardId: string } = useParams();
  const currentOrganizationID: string | number | null = useAppSelector(state => state.auth.currentOrganization?.organization_id ?? null);
  const JWT: string | null = useAppSelector(state => state.auth?.token?.access_token ?? null);
  const boardID = useMemo(() => +params.boardId, [params.boardId]);
  const userId: string | number | null = useAppSelector(state => state.auth.user?.id ?? null);
  const userTimezoneName: string = useAppSelector(state => state.auth.userTimezone?.name || 'Pacific/Yap');

  useSubscribeChannel(boardID, {
    channel: `content_board:${boardID}`,
    events: (ctx: BoardSocketsActionsType) => {
      switch (ctx.data.action) {
        case SUBSCRIBE_ACTIONS.CONTENT_BOARD_REFRESH: {
          dispatch(updateOpenedContentBoard(ctx.data.data));
          break;
        }
        case SUBSCRIBE_ACTIONS.CONTENT_BOARD_DELIVERABLE_REFRESH: {
          dispatch(updateBoardDeliverableItem(ctx.data.data));
          break;
        }
        case SUBSCRIBE_ACTIONS.CONTENT_BOARD_CREATE_DELIVERABLES_REFRESH: {
          const deliverables = ctx.data.data.deliverables;
          const wasCurrentUserUpdate = deliverables?.[0]?.creator_id === userId;
          if (wasCurrentUserUpdate) return;

          const parsedDeliverables = deliverables.map(deliverable => ({
            ...deliverable,
            live_datetime: deliverable?.live_datetime
              ? moment.utc(deliverable.live_datetime).tz(userTimezoneName).format('YYYY-MM-DDTHH:mm:ss')
              : null,
          }));

          dispatch(pushBoardDeliverables(parsedDeliverables));

          const isNewLiveDates = parsedDeliverables.some(deliverable => !!deliverable.live_datetime);
          if (isNewLiveDates) {
            dispatch(getUpcomingLiveDate(boardID));
          }
        }
      }
    },
    opts: { data: { organization_id: currentOrganizationID, token: JWT, content_board_id: boardID } },
    // onError: err => console.error('Centrifuge subscribe error: ', err),
    // onSubscribe: ctx => console.log('+ subscribe board', ctx.channel),
    // onUnsubscribe: ctx => console.log('- unsubscribe board', ctx.channel),
  });
};

export default useContentBoardSockets;
