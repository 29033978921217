import {IAssetMedia} from "../../../../models/entities/asset/AssetMedia";
import {useMemo, useState} from "react";
import {IResultAsset} from "../../../../models/entities/asset/ResultAsset";
import {isImage} from "../../components/results/utils";


function resultAssetToMediaAsset(asset: IResultAsset): IAssetMedia {
  const isOnlyPreview = Boolean(!asset.location && (asset.preview_location || asset.thumbnail_location));
  const isVideo = !isImage(asset.mime_type) && !isOnlyPreview
  return {
    isVideo,
    id: asset.id,
    url: asset.location || asset.preview_location || asset.thumbnail_location || "",
    isVideoProcessing: isVideo && isOnlyPreview
  }
}

export default function useAssetModalData(assetsMapping: Record<string, IResultAsset[]>, openAsset: IResultAsset | null) {
  return useMemo(() => {
    const deliverableId = String(openAsset?.deliverable_id);
    if (!openAsset || !assetsMapping[deliverableId]) return {mediaAssets: [], openedMediaAsset: null, mediaAssetIndex: -1};

    const mediaAssets: IAssetMedia[] = [];
    let mediaAssetIndex: number = -1;
    let openedMediaAsset: IAssetMedia | null = null;

    for (let i = 0; i < assetsMapping[deliverableId].length; i++) {
      const asset = assetsMapping[deliverableId][i];
      const mediaAsset = resultAssetToMediaAsset(asset);
      mediaAssets.push(mediaAsset);
      if (mediaAsset.id == openAsset.id) {
        mediaAssetIndex = i;
        openedMediaAsset = mediaAsset;
      }
    }

    return {
      mediaAssets,
      mediaAssetIndex,
      openedMediaAsset,
    };
  }, [openAsset]);
}
