import React, { useState } from 'react';
import styles from './TabVisualAssets.module.scss';
import { validateImage } from '../../../../../../../shared/utils/helpers';
import VisualBlock from '../../components/VisualBlock';
import { uniqueId } from 'lodash';
import { UseBriefVisualAssetsReturnType } from '../../useBriefVisualAssets';
import { ICreateBoardBriefAsset } from '../../../../../../../models/entities/asset/CreateBoardBriefAsset';
import { INPUT_FILE_ALLOWED_TYPE } from '../../../../../../../shared/constants/tsConstants';
import ButtonWithPurpleRoundedIcon from '../../../../../../../components/Buttons/ButtonWithPurpleRoundedIcon';
import { useMediaQuery } from 'react-responsive';

type PropsType = {
  visualAssets: UseBriefVisualAssetsReturnType['briefFormAssets'];
};

function TabVisualAssets({ visualAssets }: PropsType) {
  const [isError, setIsError] = useState<boolean>(false);
  const MAX_VISUAL_ASSETS = 10;
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

  const handleUploadVisualElements = event => {
    const files = [];
    for (let i = 0; i < event.target.files.length; i++) {
      // @ts-ignore
      files.push(validateImage(event.target.files[i]));
    }
    const elements = [];
    for (let i = 0; i < files.length; i++) {
      if (!files[i]) continue;
      const reader = new FileReader();
      reader.readAsDataURL(files[i]);
      reader.onloadend = () => {
        const newVisualAsset: ICreateBoardBriefAsset = {
          id: uniqueId('new-board-brief-asset_'),
          file: files[i],
          fileUrl: reader.result as string,
          description: '',
        };
        // @ts-ignore
        elements.push(newVisualAsset);
      };
    }

    setTimeout(function () {
      const arr = [...visualAssets.value, ...elements];
      if (arr.length > MAX_VISUAL_ASSETS) {
        arr.length = MAX_VISUAL_ASSETS;
        setIsError(true);
      }
      visualAssets.setValue(arr);
    }, 100);
    setTimeout(() => {
      setIsError(false);
    }, 3000);
  };

  const setDescription = (value, id) => {
    const result = visualAssets.value.map(element => {
      if (element.id === id) {
        return { ...element, description: value };
      }
      return element;
    });
    visualAssets.setValue(result);
  };

  const setFileImage = (file, newFileUrl, id) => {
    const result = visualAssets.value.map(element => {
      if (element.id === id) {
        return { ...element, file: file, fileUrl: newFileUrl };
      }
      return element;
    });
    visualAssets.setValue(result);
  };

  const deleteVisualElement = id => {
    visualAssets.setValue(visualAssets.value.filter(element => element.id !== id));
  };

  return (
    <>
      <p className={styles.subTitle}>Uploading visual content will help creators understand the visual style you’re looking for.</p>
      {visualAssets.value.length > 0 && (
        <div className={styles.visualElementsWrapper}>
          {visualAssets.value.map((visualElement, index) => (
            <div key={index + visualElement.fileUrl}>
              {/* @ts-ignore */}
              <VisualBlock
                imgUrl={visualElement.fileUrl}
                description={visualElement.description}
                setDescription={value => setDescription(value, visualElement.id)}
                setFileImage={(file, newFileUrl) => setFileImage(file, newFileUrl, visualElement.id)}
                deleteVisualElement={() => deleteVisualElement(visualElement.id)}
              />
            </div>
          ))}
        </div>
      )}
      {isError && <span className={styles.error}>Maximum number of visuals 10</span>}
      <ButtonWithPurpleRoundedIcon
        presetIcon="upload"
        className={styles.visualButton}
        disabled={visualAssets.value.length >= 10}
        size={isTablet ? 'medium' : 'large'}
      >
        Upload Visuals
        <input
          type="file"
          multiple
          accept={INPUT_FILE_ALLOWED_TYPE.IMAGE_INPUT_ALLOWED}
          onChange={handleUploadVisualElements}
          className={styles.fileInput}
        />
      </ButtonWithPurpleRoundedIcon>
      <p className={styles.visualNotes}>You can upload up to 10 photos under 1 brief.</p>
    </>
  );
}

export default TabVisualAssets;
