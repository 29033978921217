import React, { useEffect, useState } from 'react';
import MainLayout from '../../components/MainLayout';
import styles from './Organizations.module.scss';
import HeaderOrganizations from './HeaderOrganizations/index';
import ContentOrganizations from './ContentOrganizations/index';
import { useSelector } from 'react-redux';

const Organizations = () => {
  const organizationsAuth = useSelector(state => state.auth.organizations);
  const organizations = useSelector(state => state.auth.organizations);
  const [searchValue, setSearchValue] = useState('');
  const [dataOrganizations, setDataOrganizations] = useState([]);

  useEffect(() => {
    setDataOrganizations(organizationsAuth);
  }, [organizationsAuth]);

  const handleSearch = () => {
    const breakdownElements = organizations?.map(item => item.organization_name.toLowerCase());
    const arrValues = breakdownElements.filter(item => item.includes(searchValue.toLowerCase()));
    const resultArr = [];
    arrValues.forEach(item => {
      organizations.forEach(key => {
        if (key.organization_name.toLowerCase().includes(item)) resultArr.push(key);
      });
    });
    setDataOrganizations(resultArr);
  };

  const clearSearch = () => {
    setSearchValue('');
    setDataOrganizations(organizationsAuth);
  };

  return (
    <MainLayout title="Organizations" contentClassName={styles.layout}>
      <HeaderOrganizations
        handleSearch={handleSearch}
        clearSearch={clearSearch}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      <ContentOrganizations dataOrganizations={dataOrganizations} />
    </MainLayout>
  );
};

export default Organizations;
