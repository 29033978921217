import { DISCUSSION_TYPES } from '../actions/discussion.actions';

const initialState = {
  discussionRooms: [],
  discussionRoomsLoading: true,
  comments: [],
  commentsLoading: true,
  collaboratorsRoom: [],
  collaboratorsRoomLoading: true,
  isExpanded: true,
  roomIndex: 0,
  lastCheckMessagesDate: null,
};

export default function discussionReducer(state = initialState, { type, payload }) {
  switch (type) {
    case DISCUSSION_TYPES.SET_NEW_DISCUSSION_ROOM: {
      return { ...state, discussionRooms: [payload, ...state.discussionRooms] };
    }
    case DISCUSSION_TYPES.GET_DISCUSSION_ROOMS.REQUEST: {
      return { ...initialState, isExpanded: state.isExpanded };
    }
    case DISCUSSION_TYPES.GET_DISCUSSION_ROOMS.SUCCESS: {
      return { ...state, discussionRooms: payload, discussionRoomsLoading: false };
    }
    case DISCUSSION_TYPES.GET_DISCUSSION_ROOMS.FAILURE: {
      return { ...state, discussionRoomsLoading: false };
    }
    case DISCUSSION_TYPES.GET_COMMENTS.REQUEST: {
      return { ...state, comments: [], commentsLoading: true };
    }
    case DISCUSSION_TYPES.GET_COMMENTS.SUCCESS: {
      return { ...state, comments: payload.data, commentsLoading: false };
    }
    case DISCUSSION_TYPES.GET_COMMENTS.FAILURE: {
      return { ...state, commentsLoading: false };
    }
    case DISCUSSION_TYPES.ADD_NEW_COMMENT.SUCCESS: {
      return { ...state, comments: [...state.comments, payload], commentsLoading: false };
    }
    case DISCUSSION_TYPES.REPLACE_COMMENT.SUCCESS: {
      return {
        ...state,
        comments: [...state.comments.filter(comment => comment.id !== payload.replaceable.id), payload.replacement],
        commentsLoading: false,
      };
    }
    case DISCUSSION_TYPES.DELETE_COMMENT.SUCCESS: {
      return { ...state, comments: state.comments.filter(comment => comment.id !== payload.id), commentsLoading: false };
    }
    case DISCUSSION_TYPES.GET_COLLABORATORS.REQUEST: {
      return { ...state, collaboratorsRoomLoading: true };
    }
    case DISCUSSION_TYPES.GET_COLLABORATORS.SUCCESS: {
      return { ...state, collaboratorsRoom: payload.data, collaboratorsRoomLoading: false };
    }
    case DISCUSSION_TYPES.GET_COLLABORATORS.FAILURE: {
      return { ...state, collaboratorsRoomLoading: false };
    }

    case DISCUSSION_TYPES.SET_ROOM_INDEX: {
      return { ...state, roomIndex: payload };
    }

    case DISCUSSION_TYPES.UPDATE_NEW_MESSAGES_DATE: {
      return { ...state, lastCheckMessagesDate: payload };
    }

    case DISCUSSION_TYPES.CLEAR_DISCUSSIONS_STATE: {
      return { ...initialState, isExpanded: state.isExpanded };
    }

    case DISCUSSION_TYPES.SET_DISCUSSIONS_IS_EXPANDED: {
      return { ...state, isExpanded: payload };
    }

    default: {
      return state;
    }
  }
}
