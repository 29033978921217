import React, {FC, useMemo} from 'react';
import SelectDropdownV1 from "../../../../../components/SelectDropdownV1";
import {useAppSelector} from "../../../../../shared/hooks/reduxHooks";
import {IDeliverableOption} from "../../../../../models/entities/board/DeliverableOption";
import styles from './DeliverableOptionsSelect.module.scss';
import cs from "classnames";

type PropsType = {
  selectedId: string | number | null;
  disabled?: boolean;
  onSelect: (id: string | number | null) => void;
  error: boolean;
  className?: string;
  dropDownClassName?: string;
};

const DeliverableOptionsSelect: FC<PropsType> = props => {
  const options = useAppSelector(state => state.results.deliverablesOptions);

  const selectedIndex: number = useMemo(() =>
      options.findIndex(op => op.id === props.selectedId),
    [options, props.selectedId]
  );

  return (
    <SelectDropdownV1
      options={options}
      onSelect={(_, opt: IDeliverableOption) => props.onSelect(opt.id)}
      optionKey={(opt: IDeliverableOption) => opt.id}
      renderOption={(opt: IDeliverableOption) => opt.name}
      selectedId={selectedIndex}
      className={cs(styles.select, {[styles.disabled]: props.disabled}, props.className)}
      dropDownClassName={cs(styles.dropdown, props.dropDownClassName)}
      placeholder={"Select Deliverable *"}
      error={props.error}
    />
  )
};

export default DeliverableOptionsSelect;
