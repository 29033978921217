import CustomError from './CustomError';
import ERROR_TYPE from './ErrorTypes';

export type ServerResponseErrorType = {
  data: {
    type?: ERROR_TYPE;
    message: string;
    status: number;
    success: boolean;
  };
};

export interface IServerErrorProps {
  message: string;
  response: ServerResponseErrorType;
}

export default class ServerError extends CustomError {
  message: string;
  response: ServerResponseErrorType;

  constructor(error: IServerErrorProps) {
    super();
    this.message = error.message;
    this.response = error.response;
  }

  getMessage(): string {
    return this.response.data.message;
  }
}
