import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useClickOutside } from '../../../../shared/hooks/useClickOutside';
import cs from 'classnames';
import styles from './ChangeableColoredStatus.module.scss';
import mixinStyles from '../statuses-colors.module.scss';
import CheckmarkSVG from '../../../IconsSvg/CheckmarkSVG';
import { ReactComponent as TriangleSvg } from '../../../../assets/img/icons/play-white-arrow.svg';

type PropsType<StatusesType extends string[]> = {
  wrapperClass?: string;
  status: StatusesType[number];
  statuses: StatusesType;
  statusConfig: {
    [key in StatusesType[number]]: {
      status: string;
      shortStatus: string;
      text: string;
      colorClass: string;
      dotClass: string;
    };
  };
  canEditStatus: boolean;
  onSelect?: (status: StatusesType[number]) => void;
  shortStatuses?: boolean;
  onDropdownToggle?: (isOpen: boolean) => void;
};

function ChangeableColoredStatus<StatusesType extends string[]>(props: PropsType<StatusesType>): ReturnType<FC> {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false);
  const onCloseDropDown = useCallback(() => setIsDropDownOpen(false), []);
  useClickOutside(containerRef, dropdownRef, onCloseDropDown);

  useEffect(() => {
    if (props.onDropdownToggle) {
      props.onDropdownToggle(isDropDownOpen);
    }
  }, [isDropDownOpen]);

  return (
    <div
      className={cs(styles.root, mixinStyles['color-pink'], props.statusConfig[props.status].colorClass, props.wrapperClass, {
        [styles.active]: isDropDownOpen,
        [styles.disabled]: !props.canEditStatus,
      })}
      onClick={() => props.canEditStatus && setIsDropDownOpen(prev => !prev)}
      ref={containerRef}
    >
      {props.canEditStatus ? <div className={styles.dot} data-element="dot" /> : null}
      <p className={styles.statusText} data-element="status-text">
        {props.shortStatuses ? props.statusConfig[props.status].shortStatus : props.statusConfig[props.status].status}
      </p>
      {props.canEditStatus ? <TriangleSvg className={styles.arrow} data-element="arrow" /> : null}
      <div className={styles.dropDown} ref={dropdownRef} data-element="dropdown">
        {props.statuses.map(status => (
          <div
            key={status}
            className={styles.dropDownItem}
            onClick={() => status !== props.status && props.onSelect && props.onSelect(status)}
          >
            <div className={styles.dotWrapper}>
              <div className={cs(styles.dot, mixinStyles['dot-pink'], props.statusConfig[status].dotClass)} data-element="dot" />
            </div>
            <h6 className={styles.dropDownStatus}>{props.statusConfig[status].status}</h6>
            {props.status === status ? <CheckmarkSVG className={styles.checkMark} /> : null}
            <p className={styles.dropDownText}>{props.statusConfig[status].text}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ChangeableColoredStatus;
