import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const makeStore = () => {
  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

  return store;
};

export type RootState = ReturnType<ReturnType<typeof makeStore>['getState']>;

export default makeStore;
