import React, { FC, PropsWithChildren } from 'react';
import LoadingContainer from '../../../../../../../components/LoadingContainer';

type PropsType = {
  isLoading: boolean;
} & PropsWithChildren;

const SectionDescriptionLoadingWrapper: FC<PropsType> = props => {
  return (
    <>
      {props.isLoading ? (
        <>
          <LoadingContainer height="12px" borderRadius="4px" margin="0 0 8px 0" />
          <LoadingContainer height="12px" borderRadius="4px" margin="0 0 8px 0" />
          <LoadingContainer width="30%" height="12px" borderRadius="4px" />
        </>
      ) : (
        props.children
      )}
    </>
  );
};

export default SectionDescriptionLoadingWrapper;
