import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  approveDeliverableSection,
  clearAssetsToUpdate,
  deleteDeliverableSection,
  ISaveDeliverableSectionData,
  saveDeliverableSection,
  shareAndApproveDeliverableSection,
  shareDeliverableSection,
} from '../../../../actions/deliverablesActions/deliverables.actions';
import { isEqual } from 'lodash';
import { getReorderedAssetsStates, mutateAssetsAfterConverting } from '../helpers';
import { arrayMove } from '@dnd-kit/sortable';
import { DELIVERABLE_CALL_TO_ACTIONS } from '../../../../models/enums/deliverableCallToActions';
import { AssetStateType } from '../../../../models/entities/asset/AssetState';
import { ICreatedAsset } from '../../../../models/entities/asset/createdAsset';
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/reduxHooks';
import { DeliverablesReducerState } from '../../../../reducers/deliverables.reducer';
import { DELIVERABLE_SECTION_TYPES } from '../../../../models/enums/DeliverableSectionTypes';
import { DELIVERABLE_STATUSES } from '../../../../models/enums/DeliverableStatuses';
import { OptionalObjectType } from '../../../../models/utils/OptionalObjectType';

type ContentStateType = {
  call_to_action: DELIVERABLE_CALL_TO_ACTIONS[] | null;
  description: string;
  isChanged: boolean;
};

type AssetsLocalStateType = {
  current: AssetStateType[];
  delete: AssetStateType[];
  update: AssetStateType[];
  create: ICreatedAsset[];
  isDelete: boolean;
  isUpdate: boolean;
  isCreate: boolean;
};

const contentInitialState: ContentStateType = {
  call_to_action: null,
  description: '',
  isChanged: false,
};

export type ContentAssetsStateType = {
  content: {
    onChangeContent: (newState: OptionalObjectType<ContentStateType>) => void;
    shareContent: () => void;
    approveContent: () => void;
    shareAndApproveContent: () => void;
    removeContent: () => void;
    saveContent: () => void;
    status: DELIVERABLE_STATUSES;
    isShared: boolean;
    isApproved: boolean;
  } & ContentStateType;

  contentAssets: {
    onCreate: (asset: ICreatedAsset) => void;
    onUpdate: (asset: AssetStateType) => void;
    onDelete: (asset: AssetStateType) => void;
    onReorder: (sourceId: AssetStateType['id'], destinationId: AssetStateType['id']) => void;
  } & AssetsLocalStateType;

  contentEdit: {
    checkpointContent: () => void;
    restoreContent: () => void;
  };
};

type UseContentStateType = () => ContentAssetsStateType;

const isContentChanged = (nextState: ContentStateType, currentState: ContentStateType | null): boolean => {
  if (!currentState) return false;
  let res = false;
  if (currentState.call_to_action?.[0] !== nextState.call_to_action?.[0]) {
    res = true;
  }
  if (currentState.description !== nextState.description) {
    res = true;
  }
  return res;
};

const assetsInitialState: AssetsLocalStateType = {
  current: [],
  delete: [],
  update: [],
  create: [],
  isDelete: false,
  isUpdate: false,
  isCreate: false,
};

const removeFromArray = <ArrayType extends { id: string | number }, ItemType extends { id: string | number }>(
  array: ArrayType[],
  item: ItemType
): ArrayType[] => {
  return array.filter(i => i.id !== item.id);
};

const isAssetChanged = (array: AssetStateType[], item: AssetStateType): boolean => {
  const mutableAsset = array.find(asset => asset.id === item.id);
  if (!mutableAsset) return false;
  return (
    ((mutableAsset.caption === null ? '' !== item.caption : mutableAsset.caption?.replace(/\r/g, '') !== item.caption) ||
      ('file' in item && !!item.file)) &&
    !isEqual(item, mutableAsset)
  );
};

const useContentState: UseContentStateType = () => {
  const dispatch = useAppDispatch();
  const params = useParams<{ boardId: string; deliverableId: string }>();
  const currentOrgId = useAppSelector<string | number>(state => state.auth.currentOrganization?.organization_id);
  const { openedDeliverable, assetsToUpdate } = useAppSelector<DeliverablesReducerState>(state => state.deliverables);

  // content
  const [content, setContent] = useState<ContentStateType>({ ...contentInitialState });
  const [initContentValue, setInitContentValue] = useState<null | ContentStateType>(null);
  const [savedContent, setSavedContent] = useState<ContentStateType>({ ...contentInitialState });
  // assets
  const [assetsState, setAssetsState] = useState<AssetsLocalStateType>({ ...assetsInitialState });
  const [savedAssets, setSavedAssets] = useState<AssetsLocalStateType>({ ...assetsInitialState });

  useEffect(() => {
    const newContentInitValue: ContentStateType = {
      ...contentInitialState,
      call_to_action: openedDeliverable?.content?.call_to_action || null,
      description: openedDeliverable?.content?.description || '',
    };

    setContent({ ...newContentInitValue });
    setInitContentValue({ ...newContentInitValue });
    setSavedContent({ ...newContentInitValue });

    const assets = openedDeliverable?.content?.assets;

    // assets
    setAssetsState({
      ...assetsInitialState,
      current: assets ? [...assets] : [],
    });
    setSavedAssets({
      ...assetsInitialState,
      current: assets ? [...assets] : [],
    });
  }, [openedDeliverable?.content]);

  const getUpdatedState = (newAssets: AssetStateType[], currentAssets: AssetsLocalStateType): AssetsLocalStateType => {
    const newState = { ...currentAssets };
    newState.current = mutateAssetsAfterConverting(newState.current, newAssets);
    newState.update = mutateAssetsAfterConverting(newState.update, newAssets);
    return newState;
  };

  useEffect(() => {
    if (assetsToUpdate && assetsToUpdate.type === DELIVERABLE_SECTION_TYPES.CONTENT) {
      const updatedAssets = getUpdatedState([assetsToUpdate.asset], assetsState);
      const updatedSavedAssets = getUpdatedState([assetsToUpdate.asset], savedAssets);
      if (updatedAssets) setAssetsState(updatedAssets);
      if (updatedSavedAssets) setSavedAssets(updatedSavedAssets);
      dispatch(clearAssetsToUpdate());
    }
  }, [assetsToUpdate]);

  const onChangeContent = (newState: OptionalObjectType<ContentStateType>) => {
    const nextState = { ...content, ...newState };
    setContent({
      ...nextState,
      isChanged: isContentChanged(nextState, initContentValue),
    });
  };

  const onCreate = (asset: ICreatedAsset): void => {
    setAssetsState(prevState => {
      const newAssetOrder = prevState.current.length + 1;
      const newAsset = { ...asset, display_order: newAssetOrder };

      return {
        ...prevState,
        isCreate: true,
        current: [...prevState.current, newAsset],
        create: [...prevState.create, newAsset],
      };
    });
  };

  const onUpdate = (asset: AssetStateType) => {
    const newAsset = { ...asset };
    const isNewAssetOfCreatedType = 'file' in newAsset && typeof newAsset.id === 'string';
    const isValidUpdateAsset = typeof newAsset.id !== 'string' && isAssetChanged(openedDeliverable?.content?.assets || [], newAsset);
    const newUpdateAssets = [...removeFromArray(assetsState.update, newAsset)];
    if (isValidUpdateAsset) {
      newUpdateAssets.push(newAsset);
    }

    setAssetsState({
      ...assetsState,
      isUpdate: !!newUpdateAssets.length,
      current: assetsState.current.map(obj => {
        if (obj.id === newAsset.id) return newAsset;
        return obj;
      }),
      create: isNewAssetOfCreatedType
        ? assetsState.create.map(obj => {
            if (obj.id === newAsset.id) return newAsset;
            return obj;
          })
        : [...assetsState.create],
      update: newUpdateAssets,
    });
  };

  const onReorder = (sourceId: AssetStateType['id'], destinationId: AssetStateType['id']) => {
    setAssetsState(oldAssetsState => {
      const sourceAssetIndex = oldAssetsState.current.findIndex(({ id }) => id === sourceId);
      const destinationAssetIndex = oldAssetsState.current.findIndex(({ id }) => id === destinationId);
      const reorderedAssets = arrayMove(oldAssetsState.current, sourceAssetIndex, destinationAssetIndex);

      const { updatedReorderedAssetsState, createdReorderedAssetsState, currentReorderedAssetsState } = getReorderedAssetsStates(
        oldAssetsState.update,
        oldAssetsState.create,
        oldAssetsState.current,
        reorderedAssets
      );

      return {
        ...oldAssetsState,
        isUpdate: true,
        current: currentReorderedAssetsState,
        update: updatedReorderedAssetsState,
        create: createdReorderedAssetsState,
      };
    });
  };

  const onDelete = (asset: AssetStateType) => {
    const isValidDeleteAsset = typeof asset.id !== 'string';
    const newDeleteAssets = [...assetsState.delete];
    if (isValidDeleteAsset) {
      newDeleteAssets.push(asset);
    }
    const newCurrentAssets = removeFromArray(assetsState.current, asset);
    const newCreateAssets = removeFromArray(assetsState.create, asset);
    const newUpdateAssets = removeFromArray(assetsState.update, asset);

    const { createdReorderedAssetsState, currentReorderedAssetsState, updatedReorderedAssetsState } = getReorderedAssetsStates(
      newUpdateAssets,
      newCreateAssets,
      newCurrentAssets,
      newCurrentAssets
    );

    setAssetsState({
      ...assetsState,
      isDelete: !!newDeleteAssets.length,
      isCreate: !!newCreateAssets.length,
      isUpdate: !!newUpdateAssets.length,
      current: currentReorderedAssetsState,
      create: createdReorderedAssetsState,
      update: updatedReorderedAssetsState,
      delete: newDeleteAssets,
    });
  };

  const checkpointContent = () => {
    setSavedContent({ ...content });
    setSavedAssets({ ...assetsState });
  };

  const restoreContent = () => {
    setContent({ ...savedContent });
    setAssetsState({ ...savedAssets });
  };

  const shareAndApproveContent = () => {
    if (!openedDeliverable?.content?.id) return;
    dispatch(shareAndApproveDeliverableSection(currentOrgId, +params.boardId, +params.deliverableId, openedDeliverable?.content?.id));
  };

  const approveContent = () => {
    if (!openedDeliverable?.content?.id) return;
    dispatch(approveDeliverableSection(currentOrgId, +params.boardId, +params.deliverableId, openedDeliverable?.content?.id));
  };

  const shareContent = () => {
    if (!openedDeliverable?.content?.id) return;
    dispatch(shareDeliverableSection(currentOrgId, +params.boardId, +params.deliverableId, openedDeliverable?.content?.id));
  };

  const saveContent = () => {
    const data: ISaveDeliverableSectionData = {
      section: {
        payload: {
          type: DELIVERABLE_SECTION_TYPES.CONTENT,
          description: content?.description || null,
          call_to_action: content?.call_to_action || null,
        },
        isChanged: content.isChanged,
      },
    };
    if (assetsState.isUpdate || assetsState.isCreate || assetsState.isDelete) {
      data.assets = {};
      if (assetsState.create.length) {
        data.assets.create = assetsState.create;
        data.assets.current = assetsState.current;
      }
      if (assetsState.update.length) data.assets.update = assetsState.update;
      if (assetsState.delete.length) data.assets.delete = assetsState.delete;
    }
    dispatch(saveDeliverableSection(currentOrgId, +params.boardId, +params.deliverableId, data));
  };

  const removeContent = () => {
    dispatch(deleteDeliverableSection(currentOrgId, +params.boardId, +params.deliverableId, DELIVERABLE_SECTION_TYPES.CONTENT));
  };

  return {
    content: {
      ...content,
      onChangeContent,
      shareContent,
      approveContent,
      shareAndApproveContent,
      removeContent,
      saveContent,
      status: openedDeliverable?.deliverable?.status_content || DELIVERABLE_STATUSES.EMPTY,
      isShared: !!openedDeliverable?.content?.is_shared,
      isApproved: openedDeliverable?.content?.status === DELIVERABLE_STATUSES.APPROVED,
    },
    contentAssets: {
      ...assetsState,
      onCreate,
      onUpdate,
      onDelete,
      onReorder,
    },
    contentEdit: {
      checkpointContent,
      restoreContent,
    },
  };
};

export default useContentState;
