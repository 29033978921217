import React, { FC, useMemo } from 'react';
import SectionHeader from '../../../components/SectionHeader';
import { DeliverablePermission } from '../../../../../../../../models/permissions/enum/DeliverablePermission';
import {
  DeliverableStatusTileSettingsType,
  getDeliverableStatusesTooltips,
} from '../../../components/SectionHeader/statuses/useDeliverableStatusTile';
import { ContentFlowType } from '../../../../../hooks/useDeliverableState';
import { useAppSelector } from '../../../../../../../../shared/hooks/reduxHooks';
import { DeliverablesReducerState } from '../../../../../../../../reducers/deliverables.reducer';
import { DELIVERABLE_STATUSES } from '../../../../../../../../models/enums/DeliverableStatuses';
import { DELIVERABLE_SECTION_TYPES } from '../../../../../../../../models/enums/DeliverableSectionTypes';
import { ToolTipSettings } from '../../../../../../../../shared/hooks/fixedTooltip/useTooltip';
import { IDownloadSettings } from '../../../types';
import QuestionTooltip from '../../../../../../../../components/QuestionTooltip';

type PropsType = {
  contentFlow: ContentFlowType;
  onEdit: () => void;
  isDisplayQuestion: boolean;
  isDisplayEmptyState: boolean;
  isEditingMode: boolean;
  onRemove: (() => void) | null;
};

const ContentHeaderWrapper: FC<PropsType> = ({ contentFlow, onEdit, isDisplayEmptyState, isDisplayQuestion, isEditingMode, onRemove }) => {
  const {
    openedDeliverable,
    deliverableContentUpdateLoading: isPageUpdateLoading,
    pageModel,
  } = useAppSelector<DeliverablesReducerState>(state => state.deliverables);
  const collaborators = useAppSelector(state => state.contentBoard.collaborators);

  const isDataExists: boolean = useMemo(() => {
    return contentFlow.isVisualExists || !!openedDeliverable?.content?.description || !!openedDeliverable?.content?.call_to_action;
  }, [contentFlow?.isVisualExists, openedDeliverable?.content]);

  const approveButtonText: string = useMemo(
    () =>
      pageModel?.roleCan(DeliverablePermission.CAN_APPROVE_SECTION) &&
      (!!contentFlow?.content?.isShared || pageModel?.roleCan(DeliverablePermission.SHARE_AS_APPROVE))
        ? 'Approve'
        : 'Share with Agency/Brand',
    [pageModel, !!contentFlow?.content?.isShared]
  );

  const approveTooltipSettings: ToolTipSettings | null = useMemo(() => {
    return pageModel?.roleCan(DeliverablePermission.CONTENT_APPROVE_BUTTON_TOOLTIP_BA) && !!contentFlow?.content?.isShared
      ? { text: 'Let your content creators know that you love the content. Click Approve !' }
      : null;
  }, [pageModel, !!contentFlow?.content?.isShared]);

  const status: DELIVERABLE_STATUSES = useMemo(
    () => contentFlow?.content?.status || DELIVERABLE_STATUSES.EMPTY,
    [contentFlow?.content?.status]
  );

  const ToolTipTextSettingsContent: DeliverableStatusTileSettingsType = useMemo(() => {
    const deliverableStatusTooltips = getDeliverableStatusesTooltips(pageModel?.role?.type, collaborators);

    return {
      approvedOutlined: true,
      toolTip: deliverableStatusTooltips?.content || null,
    };
  }, [pageModel, collaborators]);

  const downloadSettings: IDownloadSettings = useMemo(
    () => ({
      isDisplay:
        contentFlow?.content?.status !== DELIVERABLE_STATUSES.EMPTY &&
        !!pageModel?.roleCan(DeliverablePermission.CAN_DOWNLOAD_ASSETS) &&
        !!openedDeliverable?.content?.assets.length,
      assets: openedDeliverable?.content?.assets,
    }),
    [pageModel, contentFlow?.content?.status, openedDeliverable]
  );

  return (
    <SectionHeader
      sectionTitle="Content"
      sectionType={DELIVERABLE_SECTION_TYPES.CONTENT}
      isDataExists={isDataExists}
      status={status}
      isDisplayQuestion={isDisplayQuestion}
      questionTooltip={<QuestionTooltip section={DELIVERABLE_SECTION_TYPES.CONTENT} roleType={pageModel?.role?.type} />}
      isShared={!!contentFlow?.content?.isShared}
      onShare={contentFlow.content.shareContent}
      onApprove={contentFlow.content.approveContent}
      onShareAndApprove={contentFlow.content.shareAndApproveContent}
      approveButtonText={approveButtonText}
      statusTileSettings={ToolTipTextSettingsContent}
      isPageUpdateLoading={isPageUpdateLoading}
      isDisabledApprove={isPageUpdateLoading}
      approveButtonTooltipSettings={approveTooltipSettings}
      onEdit={onEdit}
      isDisplayEmptyState={isDisplayEmptyState}
      isEditingMode={isEditingMode}
      onRemove={onRemove}
      downloadSettings={downloadSettings}
    />
  );
};

export default ContentHeaderWrapper;
