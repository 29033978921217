import React, { FC, useCallback, useMemo } from 'react';
import styles from './NotificationPreferencesFormItem.module.scss';
import cs from 'classnames';
import CheckBox from '../../../../CampaignBrief/components/CheckBox';
import { INotificationPreferencesTextSettings, NotificationPreferencesFormikType } from '../../hooks/NotificationPreferencesFormSettings';
import SmallLoader from '../../../../../components/SmallLoader';

type NotificationPreferencesFormItemPropsType = {
  title: string;
  text: string;
  fieldName: INotificationPreferencesTextSettings['fieldName'];
  formik: NotificationPreferencesFormikType;
  isLoading: boolean;
};
const NotificationPreferencesFormItem: FC<NotificationPreferencesFormItemPropsType> = props => {
  const onTogglePreference = useCallback(() => {
    if (props.isLoading) return;
    const formikPreferences = props.formik.values.notificationPreferences;

    if (formikPreferences.includes(props.fieldName)) {
      props.formik.setFieldValue(
        'notificationPreferences',
        formikPreferences.filter(preference => preference !== props.fieldName)
      );
      return;
    }

    props.formik.setFieldValue('notificationPreferences', [...formikPreferences, props.fieldName]);
  }, [props.fieldName, props.formik.values, props.isLoading]);

  const isActive = useMemo(
    () => props.formik.values.notificationPreferences.includes(props.fieldName),
    [props.formik.values, props.fieldName]
  );

  return (
    <div className={cs(styles.root, { [styles.checkedRoot]: isActive })}>
      {!props.isLoading ? <CheckBox isActive={isActive} onClick={onTogglePreference} className={styles.checkBox} /> : null}
      {props.isLoading ? <SmallLoader /> : null}
      <div className={styles.textWrapper}>
        <h4 className={styles.title} onClick={onTogglePreference}>
          {props.title}
        </h4>
        <p className={styles.text}>{props.text}</p>
      </div>
    </div>
  );
};

export default NotificationPreferencesFormItem;
