import { instance } from '../shared/utils/api.config.creator';

export const getAllReleases = async organizationId => {
  const response = await instance.get(`organizations/${organizationId}/releases`);
  return response.data;
};

export const getReleaseUpdates = async (organizationId, releaseId) => {
  const response = await instance.get(`organizations/${organizationId}/releases/${releaseId}/updates`);
  return response.data;
};
