import { FlowsTypes, IFlow } from '../Flow';
import { ContentBoardPermission } from '../../enum/ContentBoardPermission';

export class ContentBoardSharedFlow implements IFlow<ContentBoardPermission> {
  type: FlowsTypes = FlowsTypes.SHARED_FLOW;
  generalPermissions: ContentBoardPermission[] = [];
  getPermissions(): ContentBoardPermission[] | null {
    return this.generalPermissions;
  }
}
