import { PAYMENT_TYPES } from '../actions/payment.action';

const initialState = {
  allPlans: [],
  allPlansByOrgType: [],
  isOpenExtraStorageModal: false,
  loading: false,
  loadingOrgPlans: false,
  failure: {},
  failureOrgPlans: {},
};

const paymentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PAYMENT_TYPES.GET_ALL_PLANS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PAYMENT_TYPES.GET_ALL_PLANS.SUCCESS:
      return {
        ...state,
        loading: false,
        allPlans: payload,
      };
    case PAYMENT_TYPES.GET_ALL_PLANS.FAILURE:
      return {
        ...state,
        loading: false,
        failure: payload,
      };

    case PAYMENT_TYPES.TOGGLE_EXTRA_STORAGE_MODAL:
      return {
        ...state,
        isOpenExtraStorageModal: payload,
      };

    case PAYMENT_TYPES.GET_ALL_ORGANIZATION_TYPE_PLANS.REQUEST:
      return {
        ...state,
        loadingOrgPlans: true,
      };
    case PAYMENT_TYPES.GET_ALL_ORGANIZATION_TYPE_PLANS.SUCCESS:
      return {
        ...state,
        loadingOrgPlans: false,
        allPlansByOrgType: payload,
      };
    case PAYMENT_TYPES.GET_ALL_ORGANIZATION_TYPE_PLANS.FAILURE:
      return {
        ...state,
        loadingOrgPlans: false,
        failureOrgPlans: payload,
      };

    case PAYMENT_TYPES.CLEAR_FAILURE:
      return {
        ...state,
        failure: {},
      };
    default: {
      return state;
    }
  }
};

export default paymentReducer;
