import React, { useEffect, useState } from 'react';
import LinkSVG from '../../expert/components/IconsSvg/LinkSVG';
import styles from './CollaboratorInviteLinkButton.module.scss';
import useTooltip from '../../shared/hooks/fixedTooltip/useTooltip';
import cs from 'classnames';

function CollaboratorInviteLinkButton({ data, copy }) {
  const [isCopied, setIsCopied] = useState(false);
  const { toolTip, onEnter, onLeave } = useTooltip('bottom', { text: 'Copy invite link', className: styles.tooltip });

  const copyInviteLinkHandler = () => {
    const inviteLink = `${window.location.origin}/${data.new_user_id === null ? 'complete-signup' : 'confirm-invite'}?inviteType=${
      data.object_type
    }&organizationType=${data.new_organization_type}&inviteToken=${data.token}`;
    navigator.clipboard.writeText(inviteLink);
    copy.setCopyId(data.id);
    onLeave();
  };

  useEffect(() => {
    if (data.id === copy.id) {
      setIsCopied(true);
      return;
    }

    setIsCopied(false);
  }, [copy.id]);

  return (
    <>
      {isCopied ? (
        <div className={cs(styles.linkButton, styles.copied)}>Copied</div>
      ) : (
        <>
          <div className={styles.linkButton} onClick={copyInviteLinkHandler} onMouseEnter={onEnter} onMouseLeave={onLeave}>
            <LinkSVG /> Link
          </div>
          {toolTip}
        </>
      )}
    </>
  );
}

export default CollaboratorInviteLinkButton;
