import {instanceWithoutAuth, instance, instanceWithForm} from '../shared/utils/api.config.creator';
import {IResultAssetDTO} from "../models/entities/asset/dto/ResultAsset";

export const checkSharedHash = async hash => {
  try {
    const response = await instanceWithoutAuth.get(`/shared-objects/${hash}`);
    return response.data;
  } catch (e) {
    return new Error(e);
  }
};

export const sharedViaEmail = async (hash, data) => {
  try {
    const response = await instance.post(`/shared-objects/${hash}/send-to-email`, data);
    return response.data;
  } catch (e) {
    return new Error(e);
  }
};

export const getContentBoard = async (hash, boardId) => {
  try {
    const response = await instanceWithoutAuth.get(`/shared-objects/${hash}/content-boards/${boardId}`);
    return response.data;
  } catch (e) {
    return new Error(e);
  }
};

export const getBoardCollaborators = async (hash, boardId) => {
  try {
    const response = await instanceWithoutAuth.get(`/shared-objects/${hash}/content-boards/${boardId}/collaborators`);
    return response.data;
  } catch (e) {
    return new Error(e);
  }
};

export const getInvitedBoardCollaborators = async (hash, boardId) => {
  try {
    const response = await instanceWithoutAuth.get(`/shared-objects/${hash}/content-boards/${boardId}/invites?accepted_at=null`);
    return response.data;
  } catch (e) {
    return new Error(e);
  }
};

export const getBoardDeliverables = async (hash, boardId, params) => {
  try {
    const response = await instanceWithoutAuth.get(`/shared-objects/${hash}/content-boards/${boardId}/deliverables`, { params });
    return response.data;
  } catch (e) {
    return new Error(e);
  }
};

export const getBoardDeliverablesAssets = async (hash, boardId) => {
  try {
    const response = await instanceWithoutAuth.get(`/shared-objects/${hash}/content-boards/${boardId}/assets`);
    return response.data;
  } catch (e) {
    return new Error(e);
  }
};

export const getBoardDeliverableItem = async (hash, boardId, deliverableId) => {
  try {
    const response = await instanceWithoutAuth.get(`/shared-objects/${hash}/content-boards/${boardId}/deliverables/${deliverableId}`);
    return response.data;
  } catch (e) {
    return new Error(e);
  }
};

export const getBoardDeliverableItemSection = async (hash, boardId, deliverableId, type) => {
  try {
    const response = await instanceWithoutAuth.get(
      `/shared-objects/${hash}/content-boards/${boardId}/deliverables/${deliverableId}/versions/latest`,
      {
        params: { type },
      }
    );
    return response.data;
  } catch (e) {
    return new Error(e);
  }
};

export const getBoardDeliverableOptions = async (...args) => [];
export const getBoardResultAssets = async (...args) => [];
export const deleteAllResultAssets = async (...args) => [];
export const addResultAsset = async (...args) => new Error("");
export const patchResultAsset = async (...args) => new Error("");
export const requestResults = async (...args) => new Error("");
export const deleteResultAsset = async (...args) => new Error("");
