import { EmptyObject } from 'redux';
type FunctionWithAnyArguments<TypeToReturn> = (...args: any[]) => TypeToReturn;

export enum TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  CLOCK = 'clock',
  CLOCK2 = 'clock2',
}

export interface INotification {
  type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES;
  delay: number;
  title?: string | number | JSX.Element;
  text?: string | number | JSX.Element;
  textCallback?: (callBack?: () => void) => void;
  secondaryButtonData?: {
    text: string | number | JSX.Element;
    action: (callBack?: () => void) => void;
  };
  primaryButtonData?: {
    text: string | number | JSX.Element;
    action: (callBack?: () => void) => void;
  };
  dateString?: string | number | JSX.Element;
  image?: string | number | JSX.Element;
}

export type NotificationConfigObjectType = {
  type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES;
  delay: number;
  title?: string | FunctionWithAnyArguments<string | number | JSX.Element> | number | JSX.Element;
  text?: string | FunctionWithAnyArguments<string | number | JSX.Element> | number | JSX.Element;
  textCallback?: (callBack?: () => void) => void;
  secondaryButtonData?: {
    text: string | FunctionWithAnyArguments<string | number | JSX.Element> | number | JSX.Element;
    action: (callBack?: () => void) => void;
  };
  primaryButtonData?: {
    text: string | FunctionWithAnyArguments<string | number | JSX.Element> | number | JSX.Element;
    action: (callBack?: () => void) => void;
  };
  dateString?: string | FunctionWithAnyArguments<string | number | JSX.Element> | number | JSX.Element;
  image?: string | FunctionWithAnyArguments<string | number | JSX.Element> | number | JSX.Element;
};

export type NotificationSettingsObjectType =
  | {
      title?: Parameters<FunctionWithAnyArguments<string | number | JSX.Element>>;
      text?: Parameters<FunctionWithAnyArguments<string | number | JSX.Element>>;
      secondaryButtonData?: {
        text?: Parameters<FunctionWithAnyArguments<string | number | JSX.Element>>;
      };
      primaryButtonData?: {
        text?: Parameters<FunctionWithAnyArguments<string | number | JSX.Element>>;
      };
      dateString?: Parameters<FunctionWithAnyArguments<string | number | JSX.Element>>;
      image?: Parameters<FunctionWithAnyArguments<string | number | JSX.Element>>;
    }
  | EmptyObject;

export type NotificationsConfigType = Record<string, NotificationConfigObjectType>;
export type NotificationSettingsType = Record<string, NotificationSettingsObjectType>;
