import React from 'react';
import styles from './CreateOrganization.module.scss';
import AuthLayout from '../../../components/AuthLayout';
import InputControl from '../../../expert/components/FormControls/InputControl';
import { useDispatch, useSelector } from 'react-redux';
import { clearFailure, createOrganization } from '../../../actions/organizations.actions';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';
import ErrorModal from '../../../expert/components/Modal/ErrorModal';
import { useHistory } from 'react-router-dom';
import { me } from '../../../actions/auth.actions';
import cs from 'classnames';
import { getUserUsage } from '../../../actions/user.actions';
import OrganizationTypeDropdown from '../../../components/OrganizationTypeDropdown';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TYPES_CAMPAIGNS } from '../../../shared/constants/constants';

const CreateOrganization = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(state => state.user.data);
  const { loading, failure } = useSelector(state => state.organizations);
  const organizations = useSelector(state => state.auth.organizations);
  const isFirstOrganization = !organizations?.length;
  const formik = useFormik({
    initialValues: {
      companyName: '',
      companyType: '',
    },
    validationSchema: Yup.object().shape({
      companyName: Yup.string().required('Company name is required'),
      companyType: Yup.string()
        .oneOf([[TYPES_CAMPAIGNS.BRAND, TYPES_CAMPAIGNS.AGENCY, TYPES_CAMPAIGNS.TALENT_MANAGER, TYPES_CAMPAIGNS.CONTENT_CREATOR]])
        .required('Select a type.'),
    }),
    validateOnMount: true,
  });

  const onCreateCompany = async () => {
    await dispatch(createOrganization(formik.values.companyType, formik.values.companyName, user.email, user.phone_number));
    await dispatch(getUserUsage());
    await dispatch(me());
    history.push('/');
  };

  const handleCancel = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push('/');
    }
  };

  return (
    <AuthLayout hideMenu>
      <div className={styles.root}>
        <span className={styles.title}>Create your own organization</span>
        <InputControl
          label="Company name"
          placeholder="Your company name"
          value={formik.values.companyName}
          onChange={event => formik.setFieldValue('companyName', event.target.value)}
          onBlur={() => formik.setFieldTouched('companyName', true)}
          errorMessage={formik.touched.companyName ? formik.errors.companyName : ''}
          className={styles.input}
        />
        <OrganizationTypeDropdown
          className={styles.organizationTypeInputRoot}
          inputClassName={styles.organizationTypeInput}
          dropdownClassName={styles.organizationTypeInputDropDown}
          errorInputClassName={styles.organizationTypeInputError}
          placeholderClassName={styles.organizationTypeInputErrorPlaceHolder}
          label={'Company type'}
          formik={formik}
          error={formik.touched.companyType && formik.errors.companyType}
          fieldName={'companyType'}
        />
        <div className={cs(styles.buttonsContainer, { [styles.singleButton]: isFirstOrganization })}>
          {!isFirstOrganization && (
            <ButtonCustom onClick={handleCancel} outline>
              {' '}
              Cancel{' '}
            </ButtonCustom>
          )}
          <ButtonCustom onClick={onCreateCompany} loading={loading} disabled={!formik.isValid}>
            {' '}
            Continue{' '}
          </ButtonCustom>
        </div>
      </div>
      <span className={styles.copyright}>© 2021 Otterfish Limited</span>
      <ErrorModal open={!!failure.message} onClose={() => dispatch(clearFailure())} text={failure?.message} />
    </AuthLayout>
  );
};

export default CreateOrganization;
