import React, { FC, useMemo } from 'react';
import styles from './UserColoredIcon.module.scss';
import cs from 'classnames';

type PropsType = {
  className?: string;
  text?: string;
  colorNumber?: number;
};

const iconColors = [
  { background: '#B8FBEC', color: '#479E8B' },
  { background: '#FFA9F6', color: '#A1497E' },
  { background: '#DAD3FF', color: '#776FAA' },
  { background: '#FFECA9', color: '#A18E49' },
];

const getRandomColor = (name: string) => {
  const hashNumber = name
    .split('')
    // @ts-ignore
    .map(char => char.codePointAt())
    // @ts-ignore
    .reduce((sum, num) => sum + num, 0);
  // @ts-ignore
  return iconColors[hashNumber % iconColors?.length];
};

const UserColoredIcon: FC<PropsType> = ({ className, text, colorNumber }) => {
  const randomColor = useMemo(() => {
    if (colorNumber !== undefined) return iconColors[colorNumber % 4];
    if (text) return getRandomColor(text);
  }, [text]);

  return (
    <div className={cs(styles.icon, className)} style={{ backgroundColor: randomColor?.background, color: randomColor?.color }}>
      {text || ''}
    </div>
  );
};

export default UserColoredIcon;
