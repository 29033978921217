export enum OrganizationPermission {
  CAN_SHARE_CONTENT_BOARD = 'CAN_SHARE_CONTENT_BOARD',
  CAN_SET_DELIVERABLES_DUE_DATE = 'CAN_SET_DELIVERABLES_DUE_DATE',
  CAN_SEE_BOARD_CONTRACT_CC_SUB_TITLE = 'CAN_SEE_BOARD_CONTRACT_CC_SUB_TITLE',
  CAN_SEE_BOARD_BRIEF_CC_SUB_TITLE = 'CAN_SEE_BOARD_BRIEF_CC_SUB_TITLE',
  CAN_SEE_BOARD_CONTRACT_BA_TM_SUB_TITLE = 'CAN_SEE_BOARD_CONTRACT_BA_TM_SUB_TITLE',
  CAN_SEE_BOARD_BRIEF_BA_TM_SUB_TITLE = 'CAN_SEE_BOARD_BRIEF_BA_TM_SUB_TITLE',
  CAN_SEE_CAMPAIGN_TAB = 'CAN_SEE_CAMPAIGN_TAB',
  SHOULD_UPGRADE_PLAN_AFTER_LIMIT = 'SHOULD_UPGRADE_PLAN_AFTER_LIMIT',
}
