import React from 'react';
import ConfirmationModal from '../ConfirmationModal';
import ERROR_TYPE from '../../../models/entities/error/ErrorTypes';
import TypedServerError from '../../../models/entities/error/TypedServerError';
import useErrorLimitModalConfig from './useErrorLimitModalConfig';

type PropsType = {
  isOpen: boolean;
  error?: TypedServerError | null;
  manualType?: ERROR_TYPE;
  onClose: () => void;
};

const ErrorLimitModal = ({ isOpen, error, manualType, onClose }: PropsType) => {
  const config = useErrorLimitModalConfig(error, manualType, onClose);

  return (
    <ConfirmationModal
      open={isOpen}
      onClose={() => onClose()}
      iconPreset="sadSmile"
      config={{
        text: config.text,
        subText: config.subText || '',
        submitButton: {
          text: 'Cancel',
          action: () => onClose(),
        },
        cancelButton: {
          text: config?.cta?.text,
          action: config?.cta?.action,
        },
      }}
    />
  );
};

export default ErrorLimitModal;
