import * as authServices from '../services/auth';
import userflow from 'userflow.js';
import lodash from 'lodash';
import { clearLocalStorage } from '../shared/utils/helpers';
import { USER_TYPES } from '../shared/constants/constants';
import { MEMBER_ROLE_IN_ORGANIZATION_TYPES } from '../models/enums/MemberRoleInOrganizationTypes';
import { getUser } from './user.actions';

export const AUTH_TYPES = {
  SIGN_UP: {
    REQUEST: 'SIGN_UP_REQUEST',
    SUCCESS: 'SIGN_UP_SUCCESS',
    FAILURE: 'SIGN_UP_FAILURE',
    CLEANER: 'SIGN_UP_CLEANER',
  },

  CHECK_EMAIL: {
    REQUEST: 'CHECK_EMAIL_REQUEST',
    SUCCESS: 'CHECK_EMAIL_SUCCESS',
  },

  EMAIL_CONFIRMATION: {
    REQUEST: 'EMAIL_CONFORMATION_REQUEST',
    SUCCESS: 'EMAIL_CONFORMATION_SUCCESS',
    FAILURE: 'EMAIL_CONFORMATION_FAILURE',
  },

  LETTER_LINK: {
    REQUEST: 'LETTER_LINK_REQUEST',
    SUCCESS: 'LETTER_LINK_SUCCESS',
    FAILURE: 'LETTER_LINK_FAILURE',
  },

  SIGN_IN: {
    REQUEST: 'SIGN_IN_REQUEST',
    SUCCESS: 'SIGN_IN_SUCCESS',
    FAILURE: 'SIGN_IN_FAILURE',
  },
  SIGN_OUT: {
    REQUEST: 'SIGN_OUT_REQUEST',
    SUCCESS: 'SIGN_OUT_SUCCESS',
    FAILURE: 'SIGN_OUT_FAILURE',
  },
  GET_ME: {
    SET_VALUE: 'ME_SET_VALUE',
    REQUEST: 'GET_ME_REQUEST',
    FAILURE: 'GET_ME_FAILURE',
  },
  FORGOT_PASSWORD: {
    REQUEST: 'FORGOT_PASSWORD_REQUEST',
    SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
    FAILURE: 'FORGOT_PASSWORD_FAILURE',
  },
  SET_CURRENT_ORGANIZATION_PLAN: 'SET_CURRENT_ORGANIZATION_PLAN',
  SET_USER_MODEL: 'SET_USER_MODEL',
  SIGN_UP_EDIT: 'SIGN_UP_EDIT',
  CLEAR_FAILURE: 'CLEAR_FAILURE',
  DELETE_MARK_AS_NEW_USER: 'DELETE_MARK_AS_NEW_USER',
  UPDATE_USER_TIMEZONE: 'UPDATE_USER_TIMEZONE',
  UPDATE_ORGANIZATION_PLAN: 'UPDATE_ORGANIZATION_PLAN',
  RECALCULATE_ROUTE_PERMISSIONS_TRIGGER: 'RECALCULATE_ROUTE_PERMISSIONS_TRIGGER',
};

export const signIn = (email, password) => async (dispatch, getState) => {
  clearLocalStorage();
  try {
    dispatch({
      type: AUTH_TYPES.SIGN_IN.REQUEST,
      payload: {
        email,
      },
    });
    const response = await authServices.signIn(email, password);
    if (!response.success) throw response;

    localStorage.setItem('OTTERFISH_ACCESS_TOKEN', JSON.stringify(response.data[0]));
    localStorage.setItem('OTTERFISH_ACCOUNT_MANAGER', JSON.stringify({ isAccountManager: false }));
    localStorage.setItem('OTTERFISH_SEGMENT_IDENTIFY', 'YES');

    dispatch({
      type: AUTH_TYPES.SIGN_IN.SUCCESS,
      payload: response.data[0],
    });
  } catch (error) {
    dispatch({
      type: AUTH_TYPES.SIGN_IN.FAILURE,
      payload: { ...error }.response.data,
    });
    throw error.response.data;
  }
};

export const signUpViaProvider = (provider, providerParams, type) => async dispatch => {
  try {
    const response = await authServices.signUpViaProvider(provider, providerParams, type);

    if (!response.success) throw response;

    if (response.data[0]?.access_token) {
      localStorage.setItem('OTTERFISH_ACCESS_TOKEN', JSON.stringify(response.data[0]));
      localStorage.setItem('OTTERFISH_ACCOUNT_MANAGER', JSON.stringify({ isAccountManager: false }));
    }

    dispatch({
      type: AUTH_TYPES.SIGN_UP.SUCCESS,
      payload: response.data[0],
    });

    return response.data[0];
  } catch (error) {}
};

export const checkEmail = email => async dispatch => {
  try {
    dispatch({ type: AUTH_TYPES.CHECK_EMAIL.REQUEST });
    const response = await authServices.checkEmailForExisting(email);
    dispatch({ type: AUTH_TYPES.CHECK_EMAIL.SUCCESS });
    return response;
  } catch (error) {
    dispatch({ type: AUTH_TYPES.CHECK_EMAIL.SUCCESS });
    return error.response?.data?.errors?.body[0];
  }
};

export const simplifiedSignUp = (values, utmData) => async dispatch => {
  try {
    dispatch({
      type: AUTH_TYPES.SIGN_UP.REQUEST,
      payload: {
        email: values.email,
      },
    });

    const response = await authServices.signUp(values);

    if (!response.success) throw response;

    if (response.data[0]?.access_token) {
      localStorage.setItem('OTTERFISH_ACCESS_TOKEN', JSON.stringify(response.data[0]));
      localStorage.setItem('OTTERFISH_ACCOUNT_MANAGER', JSON.stringify({ isAccountManager: false }));
      localStorage.setItem('OTTERFISH_SEGMENT_IDENTIFY', 'YES');

      if (utmData.source || utmData.medium || utmData.content) {
        window.analytics.track('user_registered', {
          category: 'User Signup',
          label: 'Owner',
          userId: response.data[0].user_id,
          user_name: `${values.first_name} ${values.last_name}`,
          org_name: values.company_name,
          company_type: values.company_type,
          email: values.email,
          marketing_accepted: values.marketing_consent,
          phone_number: values.phone_number,
          utm_source: utmData.source,
          utm_medium: utmData.medium,
          utm_content: utmData.content,
        });
      } else {
        window.analytics.track('user_registered', {
          category: 'User Signup',
          label: 'Owner',
          userId: response.data[0].user_id,
          user_name: `${values.first_name} ${values.last_name}`,
          org_name: values.company_name,
          company_type: values.company_type,
          email: values.email,
          marketing_accepted: values.marketing_consent,
          phone_number: values.phone_number,
        });
      }
    }

    dispatch({
      type: AUTH_TYPES.SIGN_UP.SUCCESS,
      payload: response.data[0],
    });
    return response;
  } catch (error) {
    dispatch({
      type: AUTH_TYPES.SIGN_UP.FAILURE,
      payload: error.response.data,
    });
  }
};

export const signUp = (values, type) => async dispatch => {
  try {
    dispatch({
      type: AUTH_TYPES.SIGN_UP.REQUEST,
      payload: {
        email: values.email,
      },
    });

    const socialMedia = {};
    if (type === 'influencer') {
      if (values.facebook) socialMedia.facebook = values.facebook;
      if (values.instagram) socialMedia.instagram = values.instagram;
      if (values.twitter) socialMedia.twitter = values.twitter;
      if (values.youtube) socialMedia.youtube = values.youtube;
      if (values.tiktok) socialMedia.tiktok = values.tiktok;
      if (values.twitch) socialMedia.twitch_mixer = values.twitch;
    }

    const data = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      password: values.password,
      target_audience: values.target,
      age_interval_id: values.age,
      phone_number: values.tel || values.phone,
      country_id: values.country,
      company_name: values.company,
      job_title: values.job,
      city_id: values.city,
      budget_interval_id: values.budget,
    };
    const rightData = type === USER_TYPES.ADVERTISER ? data : { ...data, social_media: socialMedia };
    const response = await authServices.signUp(type, rightData);

    if (!response.success) throw response;

    if (response.data[0]?.access_token) {
      localStorage.setItem('OTTERFISH_ACCESS_TOKEN', JSON.stringify(response.data[0]));
      localStorage.setItem('OTTERFISH_ACCOUNT_MANAGER', JSON.stringify({ isAccountManager: false }));
    }

    dispatch({
      type: AUTH_TYPES.SIGN_UP.SUCCESS,
      payload: response.data[0],
    });
    return response.data[0];
  } catch (error) {
    dispatch({
      type: AUTH_TYPES.SIGN_UP.FAILURE,
      payload: error.response.data,
    });
  }
};

export const clearFields = () => async dispatch => {
  return dispatch({
    type: AUTH_TYPES.SIGN_UP.CLEANER,
  });
};

export const resentEmail = email => async dispatch => {
  try {
    dispatch({
      type: AUTH_TYPES.EMAIL_CONFIRMATION.REQUEST,
    });

    const response = await authServices.resentEmail(email);
    if (!response.success) throw response;

    dispatch({
      type: AUTH_TYPES.EMAIL_CONFIRMATION.SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: AUTH_TYPES.EMAIL_CONFIRMATION.FAILURE,
      payload: { ...error }.response.data,
    });
  }
};

export const letterLink = (id, token) => async dispatch => {
  try {
    dispatch({
      type: AUTH_TYPES.LETTER_LINK.REQUEST,
    });

    const response = await authServices.letterLink(id, token);
    if (!response.success) throw response;

    dispatch({
      type: AUTH_TYPES.LETTER_LINK.SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: AUTH_TYPES.LETTER_LINK.FAILURE,
      payload: { ...error }.response.data,
    });
  }
};

export const signOut = () => async (dispatch, getState) => {
  userflow.reset(); // destroy userflow popup

  // const currentOrg = getState().auth.currentOrganization;

  // const updatedTestCreationFlows = JSON.parse(localStorage.getItem('OTTERFISH_TEST_CREATION_DRAFT_V1')) || {};
  // updatedTestCreationFlows[currentOrg?.organization_id] = null;
  // localStorage.setItem('OTTERFISH_TEST_CREATION_DRAFT_V1', JSON.stringify(updatedTestCreationFlows));

  clearLocalStorage();
  dispatch({
    type: AUTH_TYPES.SIGN_OUT.SUCCESS,
  });
  return {
    type: AUTH_TYPES.SIGN_OUT.SUCCESS,
  };
};

export const updateUserTimezone = timezone => ({
  type: AUTH_TYPES.UPDATE_USER_TIMEZONE,
  payload: timezone,
});

export const me = () => async (dispatch, getState) => {
  try {
    dispatch({ type: AUTH_TYPES.GET_ME.REQUEST });
    const data = await authServices.me();

    const timezone = data.data[0].timezone;
    if (timezone) {
      dispatch(updateUserTimezone(timezone));
    }

    const userData = {
      id: data.data[0].user.id,
      email: data.data[0].user.email,
      name: `${data.data[0].user.first_name} ${data.data[0].user.last_name}`,
      created_at: data.data[0].user.created_at,
    };

    // init userflow popup
    userflow.init(process.env.REACT_APP_USERFLOW_TOKEN);
    userflow
      .identify(userData.id, {
        name: userData.name,
        email: userData.id,
        signed_up_at: userData.created_at,
        device_type: window.innerWidth > 800 ? 'desktop' : 'mobile',
      })
      .catch(error => console.error(error));

    if (localStorage.getItem('OTTERFISH_SEGMENT_IDENTIFY')) {
      const ownerOrg = data.data[0].organizations.find(o => o.role === MEMBER_ROLE_IN_ORGANIZATION_TYPES.OWNER);
      window.analytics.identify(userData.id, {
        first_name: data.data[0].user.first_name,
        last_name: data.data[0].user.last_name,
        email: userData.email,
        phone_number: data.data[0].user.phone_number,
        user_type: ownerOrg ? 'Owner' : 'Member',
        timestamp: Date.now(),
      });

      window.analytics.track('user_login', {
        category: 'User Login',
        userId: userData.id,
      });

      localStorage.removeItem('OTTERFISH_SEGMENT_IDENTIFY');
    }

    if (localStorage.getItem('OTTERFISH_CURRENT_ORGANIZATION')) {
      if (!data.data[0].organizations.length) {
        localStorage.removeItem('OTTERFISH_CURRENT_ORGANIZATION');
      }
    }

    localStorage.setItem('OTTERFISH_USER_EMAIL', data.data[0].user.email);
    localStorage.setItem('OTTERFISH_USER', JSON.stringify(userData));
    const currentOrg = getState().auth.currentOrganization;
    if (currentOrg) {
      const updatedOrg = data.data[0].organizations.find(item => item.organization_id === currentOrg.organization_id);

      if (!lodash.isEqual(currentOrg, updatedOrg)) {
        dispatch({
          type: 'SET_CURRENT_ORGANIZATION',
          payload: updatedOrg,
        });
      }
    }

    const user = data.data[0]?.user;
    if (user) {
      dispatch(getUser(user.id));
    }

    dispatch({
      type: AUTH_TYPES.GET_ME.SET_VALUE,
      payload: data.data[0],
    });
    return data.data[0];
  } catch (error) {
    dispatch({ type: AUTH_TYPES.GET_ME.FAILURE });
  }
};

export const readUpdates = () => async (dispatch, getState) => {
  try {
    await authServices.readUpdates();
    dispatch(me());
  } catch (error) {}
};

export const viewedIntroVideo = () => async dispatch => {
  try {
    await authServices.viewedIntroVideo();
    dispatch(me());
  } catch (error) {
    console.error(error);
  }
};

export const getCurrentOrganizationPricingPlan = organizationId => async (dispatch, getState) => {
  try {
    const state = getState();
    const id = organizationId || state?.auth?.currentOrganization?.organization_id;
    const pricingPlanId = state?.auth?.currentOrganization?.pricing_plan_id;
    if (pricingPlanId) {
      const response = await authServices.getPricingPlanByOrganization(id);
      if (response.data.length) {
        dispatch({ type: AUTH_TYPES.SET_CURRENT_ORGANIZATION_PLAN, payload: response.data[0] });
      }
    }
  } catch (e) {
    throw new Error(e);
  }
};

export const updateOrganizationPricingPlan = usage => (dispatch, getState) => {
  const plan = getState().auth.currentOrganizationPlan;
  if (plan) {
    const newPlan = { ...plan };
    newPlan.usage = { ...newPlan.usage, ...usage };
    dispatch({ type: AUTH_TYPES.UPDATE_ORGANIZATION_PLAN, payload: newPlan });
  }
};

export const resetPasswordRequest = email => async (dispatch, getState) => {
  try {
    dispatch({
      type: AUTH_TYPES.FORGOT_PASSWORD.REQUEST,
      payload: {
        email,
      },
    });

    const data = await authServices.resetPasswordRequest(email);

    dispatch({
      type: AUTH_TYPES.FORGOT_PASSWORD.SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: AUTH_TYPES.FORGOT_PASSWORD.FAILURE,
      payload: error.response.data,
    });
    return error;
  }
};

export const sinUpEdit = user => async (dispatch, getState) => {
  try {
    dispatch({
      type: AUTH_TYPES.SIGN_UP_EDIT,
      payload: { ...user },
    });
  } catch (error) {
    dispatch({
      type: AUTH_TYPES.SIGN_UP.FAILURE,
      payload: error.response.data,
    });
  }
};

export const resetPassword = (token, email, password) => async dispatch => {
  try {
    dispatch({
      type: AUTH_TYPES.FORGOT_PASSWORD.REQUEST,
      payload: {
        token,
        email,
        password,
      },
    });

    const data = await authServices.resetPassword(token, email, password);

    dispatch({
      type: AUTH_TYPES.FORGOT_PASSWORD.SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: AUTH_TYPES.FORGOT_PASSWORD.FAILURE,
      payload: error.response.data,
    });
  }
};

export const clearFailure = () => async dispatch => {
  try {
    dispatch({
      type: AUTH_TYPES.CLEAR_FAILURE,
    });
  } catch (e) {}
};

export const deleteMarkAsNewUser = () => async dispatch => {
  dispatch({
    type: AUTH_TYPES.DELETE_MARK_AS_NEW_USER,
  });
};

export const setUserModel = user => async dispatch => dispatch({ type: AUTH_TYPES.SET_USER_MODEL, payload: user });

export const recalculateRoutePermissionsTrigger = () => async dispatch =>
  dispatch({ type: AUTH_TYPES.RECALCULATE_ROUTE_PERMISSIONS_TRIGGER });
