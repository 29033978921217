import React, { FC, useCallback, useMemo, useState } from 'react';
import styles from './LearningSwipeBlur.module.scss';

import PhoneImage from '../../../../../../../../../assets/img/icons/swipePhone.png';
import HandImage from '../../../../../../../../../assets/img/icons/swipeHand.png';

import cs from 'classnames';

const LOCAL_STORAGE_KEY = 'IS_FIRST_TIME_MODAL_VIEW';

const LearningSwipeBlur: FC = () => {
  const isFirstTimeModalView = useMemo(() => {
    const jsonItem = window.localStorage.getItem(LOCAL_STORAGE_KEY);
    if (!jsonItem) return true;

    return JSON.parse(jsonItem) as boolean;
  }, []);

  const [isView, setIsView] = useState(isFirstTimeModalView);

  const onClose = useCallback(() => {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(false));
    setIsView(false);
  }, []);

  return (
    <div className={cs(styles.root, { [styles.none]: !isView })}>
      <img src={PhoneImage} className={styles.phoneImage} alt={'swipe phone'} />
      <img src={HandImage} className={styles.handImage} alt={'swipe hand'} />
      <p className={styles.text}>Swipe left or right to navigate between previews</p>
      <button onClick={onClose} className={styles.button}>
        Got it
      </button>
    </div>
  );
};

export default LearningSwipeBlur;
