import { IViewBriefData } from '../../../../../../models/entities/boardBrief/ViewBrief';
import { useMemo } from 'react';
import _ from 'lodash';
import { ITextConfig } from '../types';

const useTextConfig = (boardBrief: IViewBriefData | null): { textConfig: ITextConfig[]; isEmpty: boolean } => {
  const textConfig = useMemo(() => {
    return [
      {
        id: _.uniqueId('brand_details_text_'),
        title: 'Brand background',
        text: boardBrief?.brand_description || null,
      },
      {
        id: _.uniqueId('brand_details_text_'),
        title: 'Brand guidelines and mandatories',
        text: boardBrief?.notes || null,
      },
      {
        id: _.uniqueId('brand_details_text_'),
        title: 'Key messaging',
        text: boardBrief?.key_message || null,
      },
      {
        id: _.uniqueId('brand_details_text_'),
        title: 'Exclusivity terms',
        text: boardBrief?.exclusivity || null,
      },
      {
        id: _.uniqueId('brand_details_text_'),
        title: 'Brand provides product',
        text: boardBrief?.brand_provides_product || null,
      },
      {
        id: _.uniqueId('brand_details_text_'),
        title: 'Additional info',
        text: boardBrief?.other_infos || null,
      },
    ];
  }, [boardBrief]);

  const isEmpty: boolean = useMemo(() => {
    return (
      !boardBrief?.brand_description?.trim().length &&
      !boardBrief?.notes?.trim().length &&
      !boardBrief?.key_message?.trim().length &&
      !boardBrief?.exclusivity?.trim().length &&
      !boardBrief?.brand_provides_product?.trim().length &&
      !boardBrief?.other_infos?.trim().length
    );
  }, [boardBrief]);

  return { textConfig, isEmpty };
};

export default useTextConfig;
