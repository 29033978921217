import { Organization } from './Organization';
import { OrganizationPermission } from '../enum/OrganizationPermission';
import { ORGANIZATION_TYPES } from '../../enums/OrganizationTypes';

export class OrganizationContentCreator extends Organization {
  type: ORGANIZATION_TYPES = ORGANIZATION_TYPES.CONTENT_CREATOR;
  protected permissions: OrganizationPermission[] = [
    OrganizationPermission.CAN_SEE_BOARD_BRIEF_CC_SUB_TITLE,
    OrganizationPermission.CAN_SEE_BOARD_CONTRACT_CC_SUB_TITLE,
    OrganizationPermission.CAN_SET_DELIVERABLES_DUE_DATE,
    OrganizationPermission.SHOULD_UPGRADE_PLAN_AFTER_LIMIT,
  ];
}
