import React, { FC } from 'react';
import styles from '../BrandNameSection.module.scss';
import LoadingContainer from '../../../../../../components/LoadingContainer';
import { useMediaQuery } from 'react-responsive';

const BrandNameSectionLoadingState: FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 575px)' });

  return (
    <div className={styles.root}>
      <LoadingContainer height={isMobile ? 80 : 100} width={isMobile ? 80 : 100} borderRadius={'4px'} className={styles.brandLogo} />
      <div className={styles.textWrapper}>
        <LoadingContainer width={isMobile ? 120 : 200} height={22} />
        <LoadingContainer width={55} height={22} />
      </div>
    </div>
  );
};

export default BrandNameSectionLoadingState;
