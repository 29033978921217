import React from 'react';
import styles from './BlurBlockBackground.module.scss';
import cs from 'classnames';

type PropsType = {
  children: JSX.Element;
  className?: string;
};

const BlurBlockBackground = React.forwardRef<HTMLDivElement | null, PropsType>(({ children, className }, ref) => {
  return (
    <div ref={ref} className={cs(styles.root, className)}>
      {children}
    </div>
  );
});

export default BlurBlockBackground;
