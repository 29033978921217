import React, { FC, PropsWithChildren } from 'react';
import styles from './HeaderColumn.module.scss';
import cs from 'classnames';

type PropsType = {
  columnName?: string;
  type: 'column' | 'group';
  className?: string;
} & PropsWithChildren;

const HeaderColumn: FC<PropsType> = ({ children, columnName, type = 'column', className }) => {
  return (
    <div className={cs(styles.root, className, { [styles.group]: type === 'group' })}>
      {columnName && <span>{columnName}</span>}
      {children}
    </div>
  );
};

export default HeaderColumn;
