import React, { FC } from 'react';
import styles from './NavigationItem.module.scss';
import cs from 'classnames';
import NavigationItemLoadingState from './LoadingState';

type PropsType = {
  title: string;
  id: string;
  isActive: boolean;
  onClick: () => void;
  isLoading: boolean;
};

const NavigationItem: FC<PropsType> = props => {
  return props.isLoading ? (
    <NavigationItemLoadingState />
  ) : (
    <li onClick={props.onClick} className={cs(styles.root, { [styles.active]: props.isActive })}>
      <div className={styles.dot} />
      <a href={`#${props.id}`} onClick={e => e.preventDefault()} className={styles.text}>
        {props.title}
      </a>
    </li>
  );
};

export default NavigationItem;
