import React, { FC, useMemo, useState } from 'react';
import DeliverableAssetModal from './components/DeliverableAssetModal';
import ConfirmDeleteVisualAssetModal from '../../../../../components/Modals/ConfirmDeleteVisualAssetModal';
import LoaderModal from '../../../../../expert/components/Modal/LoaderModal';
import { useDispatch } from 'react-redux';
import ErrorModal from '../../../../../expert/components/Modal/ErrorModal';
import { useMediaQuery } from 'react-responsive/src';
import useDownloadAsset from '../../../../../shared/hooks/useDownloadAsset';
import { clearDeliverablesErrors } from '../../../../../actions/deliverablesActions/deliverables.actions';
import { AssetStateType } from '../../../../../models/entities/asset/AssetState';
import { clearContentBoardErrors } from '../../../../../actions/ContentBoardActions/contentBoard.actions';
import { AssetViewSettings } from '../../hooks/useAssetsViewSettings';
import { useAppSelector } from '../../../../../shared/hooks/reduxHooks';
import { DeliverablesReducerState } from '../../../../../reducers/deliverables.reducer';
import StorageLimitModal from '../../../../../components/Modals/ErrorLimitModal';
import TypedServerError from '../../../../../models/entities/error/TypedServerError';
import {DeliverablePermission} from "../../../../../models/permissions/enum/DeliverablePermission";

type ModalsContainerProps = {
  openAssetModal: boolean;
  setOpenAssetModal: (isOpenAssetModal: boolean) => void;
  onDeleteAsset: (asset: AssetStateType | null) => void;
  viewSettings: AssetViewSettings;
};

const ModalsContainer: FC<ModalsContainerProps> = ({ openAssetModal, setOpenAssetModal, onDeleteAsset, viewSettings }) => {
  const { currentMediaAssets, currentMediaAsset, currentAsset, openedAssetIndex, setOpenedAssetId } = viewSettings;
  const dispatch = useDispatch();
  const {
    approveLoading,
    deliverableItemUpdateError,
    approveError,
    deliverableConceptUpdateError,
    deliverableContentUpdateError,
    openedDeliverable,
  } = useAppSelector<DeliverablesReducerState>(state => state.deliverables);
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const pageModel = useAppSelector(state => state.deliverables.pageModel);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const onCloseAssetModal = () => {
    setOpenAssetModal(false);
    setOpenedAssetId(null);
  };

  const onAssetModalDeleteAction = () => {
    setOpenConfirmModal(true);
    setOpenAssetModal(false);
  };

  const onSubmitDelete = () => {
    onDeleteAsset(currentAsset);
    onCloseConfirm();
  };

  const onCloseConfirm = () => {
    setOpenConfirmModal(false);
    setOpenedAssetId(null);
  };

  const { isAssetLoading, downloadAsset } = useDownloadAsset();

  const onDownloadAsset = () => {
    if (isAssetLoading || !currentAsset || !('location' in currentAsset)) return;
    downloadAsset(currentAsset.location, currentAsset.extension);
  };

  const typedErrorMessage = useMemo(() => {
    if (deliverableConceptUpdateError instanceof TypedServerError) {
      return deliverableConceptUpdateError;
    } else if (deliverableContentUpdateError instanceof TypedServerError) {
      return deliverableContentUpdateError;
    }
    return null;
  }, [deliverableConceptUpdateError, deliverableContentUpdateError]);

  return (
    <>
      <ErrorModal
        open={
          !!approveError ||
          !!deliverableItemUpdateError ||
          (!typedErrorMessage && (!!deliverableConceptUpdateError?.getMessage() || !!deliverableContentUpdateError?.getMessage()))
        }
        text={
          approveError ||
          deliverableItemUpdateError ||
          (!typedErrorMessage && (deliverableConceptUpdateError?.getMessage() || deliverableContentUpdateError?.getMessage())) ||
          ''
        }
        onClose={() => {
          dispatch(clearDeliverablesErrors());
          dispatch(clearContentBoardErrors());
        }}
      />
      <StorageLimitModal
        isOpen={Boolean(typedErrorMessage)}
        error={typedErrorMessage}
        onClose={() => dispatch(clearDeliverablesErrors())}
      />
      <LoaderModal open={approveLoading} />
      <DeliverableAssetModal
        open={openAssetModal}
        mediaAssets={currentMediaAssets}
        onClose={onCloseAssetModal}
        onDelete={onAssetModalDeleteAction}
        isDisabledDelete
        onDownloadAsset={onDownloadAsset}
        isMobile={isMobile}
        isAssetLoading={isAssetLoading}
        setOpenedAssetId={setOpenedAssetId}
        openedAsset={currentAsset || {}}
        openedAssetIndex={openedAssetIndex}
        isVideoPost={openedDeliverable?.deliverable?.placement === 'video_post'}
        openedMediaAsset={currentMediaAsset}
        canDownload={Boolean(pageModel?.roleCan(DeliverablePermission.CAN_DOWNLOAD_ASSETS))}
      />
      <ConfirmDeleteVisualAssetModal open={openConfirmModal} onSubmit={onSubmitDelete} onClose={onCloseConfirm} />
    </>
  );
};

export default ModalsContainer;
