import { Redirect, Route } from 'react-router-dom';
import React from 'react';
import { useAppSelector } from '../../../shared/hooks/reduxHooks';
import { AuthorizedUser } from '../../../models/permissions/users/AuthorizedUser';
import { Guest } from '../../../models/permissions/users/Guest';

export default ({ children, allowGuests, ...rest }) => {
  const userModel = useAppSelector(state => state.auth.userModel);
  const currentOrg = useAppSelector(state => state.auth.currentOrganization);
  const currentOrglocalStorage = JSON.parse(localStorage.getItem('OTTERFISH_CURRENT_ORGANIZATION') || '{}');

  if ((!userModel || userModel instanceof AuthorizedUser) && (!!currentOrg || currentOrglocalStorage.organization_id)) {
    return <Route {...rest} render={() => children} />;
  }

  if (allowGuests && userModel instanceof Guest) {
    return <Route {...rest} render={() => children} />;
  }

  return (
    <Route
      {...rest}
      render={({ location }) => (
        <Redirect
          to={{
            pathname: '/no-organizations',
            state: { from: location },
          }}
        />
      )}
    />
  );
};
