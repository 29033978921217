import React, { useEffect, useRef } from 'react';
import styles from './UserDropdownMenu.module.scss';
import { ReactComponent as ArrowRight } from '../../../../assets/img/icons/purple-arrow-right-thin.svg';
import OrganizationsList from '../OrganizationsList';
import { NavLink, useHistory } from 'react-router-dom';
import LogoutSVG from '../../../../expert/components/IconsSvg/LogoutSVG';
import { useMediaQuery } from 'react-responsive/src';
import { signOut } from '../../../../actions/auth.actions';
import { useDispatch, useSelector } from 'react-redux';
import { createCustomerPortal } from '../../../../actions/stripe.actions';
import cs from 'classnames';
import useDynamicResize from './useDynamicResize';
import dayjs from 'dayjs';
import UserColoredIcon from '../../../UserColoredIcon';
import { MEMBER_ROLE_IN_ORGANIZATION_TYPES } from '../../../../models/enums/MemberRoleInOrganizationTypes';

const UserDropdownMenu = ({ user, onClose }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 575.98px)' });
  const history = useHistory();
  const dispatch = useDispatch();
  const dropdownMenuRef = useRef();
  const dynamicStyles = useDynamicResize(dropdownMenuRef);
  const organizations = useSelector(state => state.auth.organizations);
  const currentOrg = useSelector(state => state.auth.currentOrganization);
  const plan = useSelector(state => state.auth.currentOrganizationPlan);
  const isDisabledBilling =
    (!currentOrg?.pricing_plan_id && !currentOrg?.is_free_pricing_plan) || currentOrg?.role !== MEMBER_ROLE_IN_ORGANIZATION_TYPES.OWNER;
  const isDisabledLinks = !organizations?.length;
  const stripe = useSelector(state => state.auth.currentOrganization?.stripe);
  const subscriptionStatus = stripe?.subscription_status;

  const onLogoutClick = () => {
    dispatch(signOut());
    history.push('/login');
  };

  const onOutOfDropdownClick = e => onClose(e, dropdownMenuRef);

  useEffect(() => {
    document.addEventListener('mousedown', onOutOfDropdownClick);
    return () => document.removeEventListener('mousedown', onOutOfDropdownClick);
  }, []);

  const redirectToStripe = async () => {
    if (isDisabledBilling) return;

    if (plan?.is_free) {
      history.push('/upgrade-plan');
      return;
    }

    dispatch(createCustomerPortal(currentOrg?.organization_id, '/organization/plan')).then(response => {
      window.location.replace(response.data[0].url);
    });
  };

  const onRouteLinkClick = e => {
    if (isDisabledLinks) {
      e.preventDefault();
    }
  };

  const getStatus = () => {
    if (subscriptionStatus === 'active') {
      if (stripe?.subscription_cancel_at) {
        return `Cancels ${dayjs(stripe.subscription_cancel_at).format('MMM D')}`;
      } else {
        return 'Active';
      }
    } else if (subscriptionStatus === 'trialing') {
      if (stripe?.subscription_trial_end) {
        return `Trial ends ${dayjs(stripe.subscription_trial_end).format('MMM D')}`;
      } else {
        return 'Trial';
      }
    } else if (subscriptionStatus === 'past_due') {
      return 'Payment failed';
    } else if (subscriptionStatus === 'canceled') {
      return 'Canceled';
    } else if (subscriptionStatus === 'incomplete' || subscriptionStatus === 'incomplete_expired') {
      return 'Subscription has issues';
    }
    return '';
  };

  return (
    <>
      {isMobile && <div className={styles.blur}></div>}

      <div className={styles.dropdownMenu} ref={dropdownMenuRef} style={dynamicStyles}>
        <div className={styles.profileSection}>
          <span className={styles.title}>My profile</span>
          <div className={styles.content} onClick={() => history.push('/profile')}>
            <div className={styles.contentAvatar}>
              {user.photo_location ? (
                <img src={user.photo_location} alt="avatar" />
              ) : (
                <UserColoredIcon text={user?.first_name?.slice(0, 1)?.toUpperCase() + user?.last_name?.slice(0, 1)?.toUpperCase()} />
              )}
            </div>
            <div className={styles.name}>
              <span>
                {user.first_name} {user.last_name}
              </span>
              <div className={styles.status}>{getStatus()}</div>
            </div>
            <div className={styles.btn}>
              <span className={styles.arrowRight}>
                <ArrowRight />
              </span>
            </div>
          </div>
        </div>

        <div className={styles.organizationSection}>
          {/* <div className={styles.title}>*/}
          {/*  <span className={styles.text}>My Organizations:</span>*/}
          {/*  <NavLink to={'/organizations'} className={styles.linkOrg}>*/}
          {/*    See all*/}
          {/*  </NavLink>*/}
          {/* </div>*/}

          <OrganizationsList organizations={organizations} currentOrg={currentOrg} />
        </div>

        <div className={styles.linksSection}>
          <NavLink
            to={'/organization/details'}
            className={cs(styles.linkItem, { [styles.disabled]: isDisabledLinks })}
            onClick={onRouteLinkClick}
          >
            Business Profile
          </NavLink>
          <NavLink
            to={'/organization/members'}
            className={cs(styles.linkItem, { [styles.disabled]: isDisabledLinks })}
            onClick={onRouteLinkClick}
          >
            Members
          </NavLink>
          <NavLink
            to={'/organization/plan'}
            className={cs(styles.linkItem, { [styles.disabled]: isDisabledLinks })}
            onClick={onRouteLinkClick}
          >
            Plan Usage
          </NavLink>
          <div className={cs(styles.linkItem, { [styles.disabled]: isDisabledBilling || isDisabledLinks })} onClick={redirectToStripe}>
            Billing
          </div>
          <a href="https://otterfish.com/privacy-policy/" className={styles.linkItem} target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
        </div>

        <div className={styles.logout} onClick={onLogoutClick}>
          <div className={styles.icon}>
            <LogoutSVG />
          </div>
          <div className={styles.text}>Log out</div>
        </div>
      </div>
    </>
  );
};

export default UserDropdownMenu;
