import React, {ComponentProps, FC, useEffect} from 'react';
import styles from './UploadResultsModal.module.scss';
import {ModalMedium} from "../../../../../components/Modals";
import UploadResultsForm from "../UploadResultsForm";
import {useAppDispatch, useAppSelector} from "../../../../../shared/hooks/reduxHooks";
import {getDeliverablesOptions} from "../../../../../actions/ResultsActions/results.actions";
import {useParams} from "react-router-dom";

type PropsType = {
  onClose: ComponentProps<typeof ModalMedium>['onClose'];
  isOpen: ComponentProps<typeof ModalMedium>['open'];
  files: File[];
};

const UploadResultsModal: FC<PropsType> = props => {
  const dispatch = useAppDispatch();
  const params = useParams() as {boardId: string};
  const userModel = useAppSelector(state => state.auth.userModel);
  const currentOrganizationID = useAppSelector(state => state.auth.currentOrganization?.organization_id);

  useEffect(() => {
    if (!currentOrganizationID || !params.boardId || !userModel) return;
    dispatch(getDeliverablesOptions(params.boardId));
  }, [params.boardId, userModel, currentOrganizationID])

  return (
    <ModalMedium classNames={styles.root} open={props.isOpen} onClose={props.onClose}>
      <h3 className={styles.title}>Upload your results</h3>
      <UploadResultsForm onSubmit={props.onClose} onCancel={props.onClose} files={props.files} isOpen={props.isOpen}/>
    </ModalMedium>
  )
};

export default UploadResultsModal;
