import React, { FC, useCallback } from 'react';
import styles from './UpcomingDatesCard.module.scss';
import { IDeliverable } from '../../../../../../../models/entities/deliverable/Deliverable';
import ButtonLightPurpleWhite from '../../../../../../../components/Buttons/ButtonLightPurpleWhite';
import LoadingButton from '../../../../../../../components/LoadingButton';
import RoundedWhiteCard from '../../../../../../../components/Cards/RoundedWhiteCard';
import { ReactComponent as PurpleClockSVG } from '../../../../../../../assets/img/icons/PurpleClock.svg';
import UpcomingDatesList from './components/UpcomingDatesList';
import UpcomingDatesEmptyState from './components/UpcomingDatesEmptyState';

type PropsType = {
  isLoading?: boolean;
  onOpenModal?: () => void;
  deliverables: null | IDeliverable[];
};

const UpcomingDatesCard: FC<PropsType> = props => {
  const renderHeaderRightView: () => JSX.Element | null = useCallback(() => {
    if (!props.onOpenModal) return null;

    if (props.isLoading) {
      return <LoadingButton>View all</LoadingButton>;
    }

    if (Boolean(props.deliverables?.length)) {
      return (
        <ButtonLightPurpleWhite size={'small'} className={styles.viewAllButton} designVersion={'v2'} onClick={props.onOpenModal}>
          View all
        </ButtonLightPurpleWhite>
      );
    }

    return null;
  }, [props.isLoading, props.deliverables, props.onOpenModal]);

  return (
    <RoundedWhiteCard
      bodyClass={styles.root}
      HeaderIcon={PurpleClockSVG}
      headerTitle={'Upcoming Live Dates'}
      renderHeaderRight={renderHeaderRightView}
    >
      {props.isLoading || props.deliverables?.length ? (
        <UpcomingDatesList isLoading={props.isLoading} deliverables={props.deliverables} />
      ) : (
        <UpcomingDatesEmptyState />
      )}
    </RoundedWhiteCard>
  );
};

export default UpcomingDatesCard;
