import React, { FC, ReactElement } from 'react';
import styles from './SimplePreview.module.scss';
import cs from 'classnames';
import { ReactComponent as EmptyImage } from '../../../assets/img/icons/not_uploaded_icon.svg';
import PostCarousel from '../InstagramPreview/PostCarousel';
import { IAssetMedia } from '../../../models/entities/asset/AssetMedia';
import { MEDIA_ORIENTATIONS } from '../../../models/enums/ImageOrientations';
import VideoTag from '../../VideoPlayers/VideoTag';

type SimplePreviewPropsType = {
  preview: IAssetMedia['url'][] | IAssetMedia['url'] | IAssetMedia[];
  callToAction: string | null;
  primaryText?: string | ReactElement;
  isPlaceholder?: boolean;
  orientation?: MEDIA_ORIENTATIONS;
  isVideoPreview?: boolean;
};

const SimplePreview: FC<SimplePreviewPropsType> = ({
  preview,
  callToAction,
  primaryText,
  isPlaceholder,
  orientation = MEDIA_ORIENTATIONS.PORTRAIT,
  isVideoPreview = false,
}) => {
  const getPreview = () => {
    if (!preview || !preview?.length) {
      return (
        <div className={styles.emptyImage}>
          <EmptyImage />
          <span>{isVideoPreview ? 'No video yet' : 'No image yet'}</span>
        </div>
      );
    }

    if (Array.isArray(preview)) {
      return <PostCarousel assets={preview} orientation={orientation} />;
    }

    if (!isVideoPreview) {
      return <img className={styles[orientation]} src={preview} alt="asset" />;
    }

    return (
      <>
        {isPlaceholder ? (
          <img className={styles.placeholderImage} src={preview} alt="Video is converting" />
        ) : (
          <VideoTag className={styles[orientation]} src={preview} controls autoPlay loop muted />
        )}
      </>
    );
  };

  return (
    <div className={styles.root}>
      <div className={cs(styles.videoContainer, styles[orientation])}>{getPreview()}</div>
      <div className={styles.textContainer}>
        <div className={styles.text}>{primaryText}</div>
        {callToAction && (
          <div className={styles.callToAction}>
            <span>{callToAction}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SimplePreview;
