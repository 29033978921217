import React from 'react';
import styles from './ConfirmDeleteVisualAssetModal.module.scss';
import { Modal } from '..';
import GreenRedButton from '../../Buttons/GreenRedButton';
import ButtonLightPurpleWhite from '../../Buttons/ButtonLightPurpleWhite';

const ConfirmDeleteVisualAssetModalContent = ({ onSubmit, onClose, text = 'Delete the visual?' }) => {
  return (
    <div className={styles.root}>
      <div className={styles.title}>{text}</div>
      <div className={styles.navigationBtns}>
        <ButtonLightPurpleWhite size="large" designVersion="v3" onClick={onClose}>
          Cancel
        </ButtonLightPurpleWhite>
        <GreenRedButton color="red" size="large" onClick={onSubmit}>
          Yes, delete
        </GreenRedButton>
      </div>
    </div>
  );
};

export default props => {
  return (
    <Modal borderRadius="10px" className={styles.modalRoot} {...props} maxWidth={395}>
      <ConfirmDeleteVisualAssetModalContent {...props} />
    </Modal>
  );
};
