import React, { useEffect, useState } from 'react';
import styles from './ProfileDetailsForm.module.scss';
import Button from '../../../../expert/components/Buttons/ButtonCustom';
import TextField from '../../../../components/FormControls/TextField';
import { NavLink, useHistory } from 'react-router-dom';
import ErrorModal from '../../../../expert/components/Modal/ErrorModal';
import { useDispatch, useSelector } from 'react-redux';
import { clearFailure } from '../../../../actions/auth.actions';
import { onClickEnter } from '../../../../shared/utils/helpers';
import LeftPartSignUp from '../../../../components/AuthTwoSideLayout/LeftPartSignUp';
import AuthTwoSideLayout from '../../../../components/AuthTwoSideLayout';
import Checkbox from '../../../../components/FormControls/Checkbox';
import cs from 'classnames';
import { ReactComponent as EyeClosedIcon } from '../../../../assets/img/icons/eye_closed.svg';
import { ReactComponent as EyeIcon } from '../../../../assets/img/icons/eye.svg';
import { getIndustries } from '../../../../actions/config.actions';
import ReCAPTCHA from 'react-google-recaptcha';
import PhoneNumberField from '../../../../components/FormControls/PhoneNumberField';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useMediaQuery } from 'react-responsive/src';
import { TYPES_CAMPAIGNS_OBJECTS } from '../../../../shared/constants/constants';
import SelectDropdownV1 from '../../../../components/SelectDropdownV1';
import WarningCard from '../../../ContentApproval/createPages/CreateBoardWrapper/CreateBoard/components/WarningCard';

const ProfileDetailsForm = ({ onSignUp, formik, isSignUpLoading, invite, hasCompany = true }) => {
  const { touched, errors, setFieldValue, values, setTouched } = formik;
  const history = useHistory();
  const dispatch = useDispatch();
  const authError = useSelector(state => state.auth.failure);
  const loading = useSelector(state => state.auth.signUp.loading);
  const [checked, setChecked] = useState(false);
  const [checkedPolicy, setCheckedPolicy] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const isValidNUmber = isValidPhoneNumber(`+${values.phone_number}`);
  const isError = touched.phone_number && errors.phone_number;
  const isMobile = useMediaQuery({ query: '(max-width: 575px)' });
  const isLaptop = useMediaQuery({ query: '(max-width: 991px)' });
  const [isTouched, setIsTouched] = useState(false);
  const selectElement = document.querySelector('.selected-flag');
  const formikValue = formik.values.phone_number;
  let codeSelect = 0;

  const onCaptchaChange = value => {
    if (value) {
      setIsCaptchaVerified(true);
    } else {
      setIsCaptchaVerified(false);
    }
  };

  const handleShowPassword = isPassword => {
    if (isPassword) {
      if (!formik.values.password) return;
      setShowPassword(state => !state);
    } else {
      if (!formik.values.confirm_password) return;
      setShowConfirmPassword(state => !state);
    }
  };

  const onSingUpByEnter = e => {
    if (!formik.isValid) return;
    onClickEnter(onSignUp, e);
  };

  useEffect(() => {
    dispatch(getIndustries());
  }, []);

  const saveURL = () => {
    localStorage.setItem('PREVIOUS_URL', window.location.pathname);
  };

  const onValueChange = phoneNumber => {
    setFieldValue('phone_number', phoneNumber);
    setTouched({ ...touched, phone_number: true });
  };

  if (selectElement?.title) {
    const el = selectElement?.title;
    let numEl = '';
    for (const index in el) {
      if (parseInt(el[index])) {
        numEl += el[index];
      }
    }
    codeSelect = parseInt(numEl);
    if (codeSelect === +formikValue) {
      delete formik.touched.phone_number;
    }
  }

  const showError = () => {
    if (isError) return errors.phone_number;
    if (codeSelect === +formikValue && !codeSelect) return 'Phone number name is required';
    if (!isValidNUmber) return 'Please make sure you have the correct country code and phone number here';
  };

  return (
    <AuthTwoSideLayout leftPartContent={<LeftPartSignUp />} isLaptop={isLaptop}>
      <div className={styles.root}>
        {/* <div className={styles.logo}>*/}
        {/*  <LogoSVG className={styles.logoSvg} />*/}
        {/* </div>*/}
        <div className={styles.content}>
          <div className={styles.title}>Sign up as</div>

          <div className={styles.organizationDropdownWrapper}>
            <SelectDropdownV1
              disabled={invite}
              className={styles.dropdown}
              options={TYPES_CAMPAIGNS_OBJECTS}
              placeholder="Type of organization"
              optionKey={item => item?.name}
              renderOption={item => item?.name}
              onSelect={item => formik.setFieldValue('company_type', TYPES_CAMPAIGNS_OBJECTS[item].type)}
              selectedId={TYPES_CAMPAIGNS_OBJECTS.findIndex(item => item.type === formik.values.company_type)}
              renderDropdownFooter={() => (
                <WarningCard
                  className={styles.warningCard}
                  warningText={
                    <div className={styles.infoBlank}>
                      Your organization type decides level of permissions while working on Otterfish. To know more about permissions click
                      here{' '}
                      <span onClick={() => window.open('https://help.otterfish.com/knowledge/organization-permissions', '_blank')?.focus()}>
                        click here
                      </span>
                    </div>
                  }
                />
              )}
            />
          </div>

          <div className={styles.subTitle}>Organization admin details</div>
          <div className={styles.doubleField}>
            <div className={styles.half}>
              <TextField
                wrapperClassName={styles.inputWrapper}
                className={styles.input}
                name="first_name"
                value={formik.values.first_name}
                placeholder="First name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                isValid={!(formik.touched.first_name && formik.errors.first_name)}
              />
              {/* <div className={styles.errorMessage}>{formik.touched.first_name && formik.errors.first_name}</div>*/}
            </div>
            <div className={styles.half}>
              <TextField
                wrapperClassName={styles.inputWrapper}
                className={styles.input}
                name="last_name"
                value={formik.values.last_name}
                placeholder="Last name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                isValid={!(formik.touched.last_name && formik.errors.last_name)}
              />
              {/* <div className={styles.errorMessage}>{formik.touched.last_name && formik.errors.last_name}</div>*/}
            </div>
          </div>
          {!invite && (
            <div className={styles.passwordField}>
              <TextField
                wrapperClassName={styles.inputWrapper}
                className={cs(styles.textField, styles.input)}
                name="email"
                value={formik.values.email}
                placeholder="Your email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                isValid={!(formik.touched.email && formik.errors.email)}
                onKeyDown={onSingUpByEnter}
              />
              {/* <div className={styles.errorMessage}>{formik.touched.email && formik.errors.email}</div>*/}
            </div>
          )}
          {/* {hasCompany && (*/}
          {/*  <div className={styles.doubleField}>*/}
          {/*    <div className={styles.half}>*/}
          {/*      <TextField*/}
          {/*        name="company_name"*/}
          {/*        value={formik.values.company_name}*/}
          {/*        placeholder="Company name"*/}
          {/*        onBlur={formik.handleBlur}*/}
          {/*        onChange={formik.handleChange}*/}
          {/*        isValid={!(formik.touched.company_name && formik.errors.company_name)}*/}
          {/*      />*/}
          {/*      <div className={styles.errorMessage}>{formik.touched.company_name && formik.errors.company_name}</div>*/}
          {/*    </div>*/}
          {/*    <div className={styles.half}>*/}
          {/*      <SelectDropdown*/}
          {/*        name="company_type"*/}
          {/*        options={TYPES_CAMPAIGNS_OBJECTS}*/}
          {/*        placeholder="Company type"*/}
          {/*        optionKey={item => item?.name}*/}
          {/*        renderOption={item => item?.name}*/}
          {/*        className={styles.dropdown}*/}
          {/*        onSelected={item => formik.setFieldValue('company_type', TYPES_CAMPAIGNS_OBJECTS[item].type)}*/}
          {/*        selectedId={TYPES_CAMPAIGNS_OBJECTS.findIndex(item => item.type === formik.values.company_type)}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/* )}*/}
          {!isMobile && (
            <PhoneNumberField
              className={styles.numberInput}
              wrapperClassName={styles.numberInputWrapper}
              formik={formik}
              onValueChange={onValueChange}
              isError={isError}
              isValidNUmber={isValidNUmber}
              showError={showError}
              formikValue={formikValue}
              setIsTouched={() => setIsTouched(true)}
              isTouched={isTouched}
              codeSelect={codeSelect}
              name="phone_number"
            />
          )}
          <div className={styles.passwordField}>
            <TextField
              wrapperClassName={styles.inputWrapper}
              className={styles.input}
              name="password"
              value={formik.values.password}
              placeholder="Enter password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type={showPassword ? 'text' : 'password'}
              isValid={!(formik.touched.password && formik.errors.password)}
            />
            <div className={cs(styles.eyeIcon, { [styles.disabledEye]: !formik.values.password })} onClick={() => handleShowPassword(true)}>
              {showPassword ? <EyeClosedIcon /> : <EyeIcon />}
            </div>
            <div className={styles.errorMessage}>{formik.touched.password && formik.errors.password}</div>
          </div>
          <div className={styles.passwordField}>
            <TextField
              wrapperClassName={styles.inputWrapper}
              className={styles.input}
              name="confirm_password"
              value={formik.values.confirm_password}
              placeholder="Confirm password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type={showConfirmPassword ? 'text' : 'password'}
              isValid={!(formik.touched.confirm_password && formik.errors.confirm_password)}
            />
            <div
              className={cs(styles.eyeIcon, { [styles.disabledEye]: !formik.values.confirm_password })}
              onClick={() => handleShowPassword(false)}
            >
              {showConfirmPassword ? <EyeClosedIcon /> : <EyeIcon />}
            </div>
            <div className={styles.errorMessage}>{formik.touched.confirm_password && formik.errors.confirm_password}</div>
          </div>
          {isMobile && (
            <PhoneNumberField
              className={styles.numberInput}
              wrapperClassName={styles.numberInputWrapper}
              formik={formik}
              onValueChange={onValueChange}
              isError={isError}
              isValidNUmber={isValidNUmber}
              showError={showError}
              formikValue={formikValue}
              setIsTouched={() => setIsTouched(true)}
              isTouched={isTouched}
              codeSelect={codeSelect}
              name="phone_number"
            />
          )}
          <div className={styles.captcha}>
            <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_KEY} onChange={onCaptchaChange} />
          </div>
          <div className={styles.navigationButtons}>
            <div className={styles.privacyPolicy}>
              <Checkbox
                checked={checked}
                onChange={() => setChecked(!checked)}
                className={cs(styles.checkbox, { [styles.checkboxBorder]: checked })}
              />
              <span>I agree to receive product updates, promotional offers and other important communications from Otterfish</span>
            </div>
            <div className={styles.privacyPolicy}>
              <Checkbox
                className={cs(styles.checkbox, { [styles.checkboxBorder]: checkedPolicy })}
                checked={checkedPolicy}
                onChange={() => setCheckedPolicy(!checkedPolicy)}
              />
              <span>
                I agree with the
                <NavLink to={'/terms-of-service/'} onClick={saveURL} exact={false}>
                  Otterfish's Terms & Conditions
                </NavLink>
                and
                <NavLink to={'/privacy-policy/'} onClick={saveURL} exact={false}>
                  Privacy policy
                </NavLink>
              </span>
            </div>
            <Button
              className={styles.signUpButton}
              disabled={isSignUpLoading || !formik.isValid || !isCaptchaVerified || !checkedPolicy || !!showError()}
              onClick={() => onSignUp(checked)}
              loading={loading}
            >
              Sign Up
            </Button>
            <div className={styles.redirect}>
              <span>Already have an account?</span>
              <span onClick={() => history.push('/login')}>Sign in</span>
            </div>
          </div>
        </div>
        <ErrorModal
          open={!!authError.message}
          text={authError?.errors?.body?.[0]?.message}
          onClose={() => {
            dispatch(clearFailure());
          }}
          title={'Oops, something went wrong.'}
        />
      </div>
    </AuthTwoSideLayout>
  );
};

export default ProfileDetailsForm;
