import { ORGANIZATION_TYPES } from '../../enums/OrganizationTypes';
import { OrganizationBrand } from '../organization/OrganizationBrand';
import { OrganizationAgency } from '../organization/OrganizationAgency';
import { OrganizationContentCreator } from '../organization/OrganizationContentCreator';
import { OrganizationTalentManager } from '../organization/OrganizationTalentManager';
import { OrganizationAdvertiser } from '../organization/OrganizationAdvertiser';
import { OrganizationInfluencer } from '../organization/OrganizationInfluencer';

export const organizationModelFactory = type => {
  switch (type) {
    case ORGANIZATION_TYPES.BRAND:
      return new OrganizationBrand();
    case ORGANIZATION_TYPES.AGENCY:
      return new OrganizationAgency();
    case ORGANIZATION_TYPES.CONTENT_CREATOR:
      return new OrganizationContentCreator();
    case ORGANIZATION_TYPES.TALENT_MANAGER:
      return new OrganizationTalentManager();
    case ORGANIZATION_TYPES.ADVERTISER:
      return new OrganizationAdvertiser();
    case ORGANIZATION_TYPES.INFLUENCER:
      return new OrganizationInfluencer();
  }

  throw new Error('Incorrect organization type!');
};
