import React, { FC, memo, useEffect, useState } from 'react';
import styles from './SubTabAudience.module.scss';
import cs from 'classnames';
import SelectDropdown from '../../../../../../../../expert/components/FormControls/SelectDropdown';
import { ReactComponent as Checked } from '../../../../../../../../assets/img/icons/checked_.svg';
import TagBlock from '../../../../../../../CampaignBrief/components/TagBlock';
import { useMediaQuery } from 'react-responsive/src';
import CheckboxButton from '../../../../../../../CampaignBrief/components/CheckboxButton';
import { areEqualFormikState } from '../../../../../../../../shared/utils/typescriptHelpers';
import { FormikProps } from 'formik';
import useFormikDebouncedState from '../../../../../../../../shared/hooks/useFormikDebouncedState';

const GENDER = {
  ALL: 'all',
  MALE: 'male',
  FEMALE: 'female',
};

const ages = new Array(53);
ages[0] = 13; // first element = 13
for (let i = 1; i < ages.length; i++) {
  ages[i] = ages[i - 1] + 1; // fill array
}

type PropsType = {
  formik: FormikProps<any>;
};

const propsAreEqual: Parameters<typeof memo<FC<PropsType>>>[1] = (prevProps, nextProps) => {
  return areEqualFormikState(
    ['ageMin', 'ageMax', 'country', 'city', 'interests', 'showTargetAudience', 'gender'],
    prevProps.formik,
    nextProps.formik
  );
};

const StepCreatorAudience = memo(({ formik }: PropsType) => {
  const [minAgeIndex, setMinAgeIndex] = useState(ages.findIndex(item => item == formik.values.ageMin));
  const [maxAgeIndex, setMaxAgeIndex] = useState(ages.findIndex(item => item == formik.values.ageMax));

  const [country, setCountry] = useFormikDebouncedState<string[]>(
    (value: string[]) => formik.setFieldValue('country', value),
    formik.values.country
  );

  const [city, setCity] = useFormikDebouncedState<string[]>((value: string[]) => formik.setFieldValue('city', value), formik.values.city);

  const [interests, setInterests] = useFormikDebouncedState<string[]>(
    (value: string[]) => formik.setFieldValue('interests', value),
    formik.values.interests
  );

  useEffect(() => {
    setMinAgeIndex(ages.findIndex(item => item == formik.values.ageMin));
  }, [formik.values.ageMin]);

  useEffect(() => {
    setMaxAgeIndex(ages.findIndex(item => item == formik.values.ageMax));
  }, [formik.values.ageMax]);

  const onSelectMin = index => {
    const minValue = ages[index];
    const maxValue = ages[maxAgeIndex];
    if (minValue > maxValue) {
      formik.setFieldValue('ageMax', minValue);
    }
    setMinAgeIndex(index);
    formik.setFieldValue('ageMin', minValue);
  };

  const onSelectMax = index => {
    const minValue = ages[minAgeIndex];
    const maxValue = ages[index];
    if (minValue > maxValue) {
      formik.setFieldValue('ageMin', maxValue);
    }
    setMaxAgeIndex(index);
    formik.setFieldValue('ageMax', maxValue);
  };

  const addCountryHandler = value => {
    if (country.indexOf(value) === -1) {
      setCountry([...formik.values.country, value]);
    }
  };

  const removeCountryHandler = value => {
    setCountry(formik.values.country.filter(tag => value != tag));
  };

  const addCityHandler = value => {
    if (city.indexOf(value) === -1) {
      setCity([...formik.values.city, value]);
    }
  };

  const removeCityHandler = value => {
    setCity(formik.values.city.filter(tag => value != tag));
  };

  const addInterestsHandler = value => {
    if (interests.indexOf(value) === -1) {
      setInterests([...formik.values.interests, value]);
    }
  };

  const removeInterestsHandler = value => {
    setInterests(formik.values.interests.filter(tag => value != tag));
  };

  const setTargetAudienceBlock = () => {
    formik.setFieldValue('showTargetAudience', true);
  };

  const removeTargetAudienceBlock = () => {
    formik.setFieldValue('showTargetAudience', false);
    formik.setFieldValue('ageMax', null);
    formik.setFieldValue('ageMin', null);
    formik.setFieldValue('gender', '');
    formik.setFieldValue('city', []);
    formik.setFieldValue('country', []);
    formik.setFieldValue('interests', []);
  };

  return (
    <div>
      <h2>Do you have a preferred target Audience?</h2>
      <div className={styles.root}>
        <div>
          <p>This will help identify who the best content creators will be for your Campaign</p>
          <div className={styles.checkboxBlock}>
            {/* @ts-ignore */}
            <CheckboxButton
              onClick={setTargetAudienceBlock}
              isActive={formik.values.showTargetAudience}
              item={'Yes'}
              className={styles.checkboxHeading}
            />
            {/* @ts-ignore */}
            <CheckboxButton
              onClick={removeTargetAudienceBlock}
              isActive={!formik.values.showTargetAudience}
              item={'No'}
              className={styles.checkboxHeading}
            />
          </div>
        </div>
        {formik.values.showTargetAudience && (
          <>
            <div>
              <h3>Age</h3>
              <div className={styles.ageContainer}>
                <SelectDropdown
                  // @ts-ignore
                  text={'Min'}
                  options={ages}
                  selectedId={minAgeIndex}
                  onSelected={onSelectMin}
                  renderOption={age => (age == 65 ? '65+' : age)}
                  optionKey={age => age}
                  className={styles.selectDropdown}
                  inputClassName={styles.inputClassName}
                />
                <SelectDropdown
                  // @ts-ignore
                  text={'Max'}
                  options={ages}
                  selectedId={maxAgeIndex}
                  onSelected={onSelectMax}
                  optionKey={age => (age == 65 ? '65+' : age)}
                  className={styles.selectDropdown}
                  inputClassName={styles.inputClassName}
                />
              </div>
            </div>

            <div>
              <h3>Gender</h3>
              <div className={styles.checkboxSection}>
                <span className={styles.socialHeading} onClick={() => formik.setFieldValue('gender', GENDER.ALL)}>
                  <span className={cs(styles.checkboxButton, { [styles.active]: formik.values.gender == GENDER.ALL })}>
                    <span className={styles.checkboxIcon}>
                      <Checked />
                    </span>
                    Both
                  </span>
                </span>
                <span className={styles.socialHeading} onClick={() => formik.setFieldValue('gender', GENDER.MALE)}>
                  <span className={cs(styles.checkboxButton, { [styles.active]: formik.values.gender == GENDER.MALE })}>
                    <span className={styles.checkboxIcon}>
                      <Checked />
                    </span>
                    Male
                  </span>
                </span>
                <span className={styles.socialHeading} onClick={() => formik.setFieldValue('gender', GENDER.FEMALE)}>
                  <span className={cs(styles.checkboxButton, { [styles.active]: formik.values.gender == GENDER.FEMALE })}>
                    <span className={styles.checkboxIcon}>
                      <Checked />
                    </span>
                    Female
                  </span>
                </span>
              </div>
            </div>
            <div>
              <h3>Location</h3>
              <div className={styles.tagsBlock}>
                {/* @ts-ignore */}
                <TagBlock
                  tags={country}
                  addTag={addCountryHandler}
                  removeTag={removeCountryHandler}
                  placeholder={'Country'}
                  isDisplayButtonAdd
                />
                {/* @ts-ignore */}
                <TagBlock tags={city} addTag={addCityHandler} removeTag={removeCityHandler} placeholder={'City'} isDisplayButtonAdd />
              </div>
            </div>
            <div className={styles.oneTagBlock}>
              <h3>Add interests and behaviors</h3>
              {/* @ts-ignore */}
              <TagBlock
                tags={interests}
                addTag={addInterestsHandler}
                removeTag={removeInterestsHandler}
                placeholder={'For example, New Home Buyers, Sport Enthusiasts etc.'}
                isDisplayButtonAdd
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}, propsAreEqual);

export default StepCreatorAudience;
