import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks/reduxHooks';
import { useEffect, useMemo } from 'react';
import { getContentBoard } from '../../../actions/ContentBoardActions/contentBoard.actions';
import { getAllMembers } from '../../../actions/organizations.actions';
import { Guest } from '../../../models/permissions/users/Guest';

const MAX_COLLABORATORS_COUNT = 100;

const useBoardCreator = (): boolean | null => {
  const params = useParams<{ boardId?: string }>();
  const userModel = useAppSelector(state => state.auth.userModel);
  const { openedBoard, boardLoading } = useAppSelector(state => state.contentBoard);
  const { data: members, loading: membersLoading } = useAppSelector<{ data: any[]; loading: boolean }>(
    state => state.organizations?.allMembers
  );
  const currentOrgId = useAppSelector<string | number | null>(state => state.auth?.currentOrganization?.organization_id);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!params.boardId || !currentOrgId) return;

    if (+params.boardId != openedBoard?.id) {
      dispatch(getContentBoard(params.boardId));
    }
  }, [openedBoard?.id, params?.boardId, currentOrgId]);

  useEffect(() => {
    if (!currentOrgId) return;
    if (!members.length && !membersLoading) {
      dispatch(getAllMembers(currentOrgId, 1, MAX_COLLABORATORS_COUNT));
    }
  }, [currentOrgId, members, membersLoading]);

  const isBoardCreator: boolean | null = useMemo(() => {
    if (
      !params?.boardId ||
      !openedBoard ||
      !members?.length ||
      boardLoading ||
      +params?.boardId != openedBoard?.id ||
      userModel instanceof Guest
    )
      return null;

    return members.some(member => member.user_id == openedBoard.creator_id);
  }, [params?.boardId, openedBoard, members]);

  return isBoardCreator;
};

export default useBoardCreator;
