import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './MobileAssetPreview.module.scss';
import LearningSwipeBlur from './components/LearningSwipeBlur';
import CenteredButtonVideo from '../../../../../../../components/VideoPlayers/CenteredButtonVideo';
import VideoConvertingPlaceholderImage from '../../../../../../../assets/img/video-converting-placeholder.png';
import MediaDueType from '../MediaDueType';
import { IAssetMedia } from '../../../../../../../models/entities/asset/AssetMedia';
import { AssetStateType } from '../../../../../../../models/entities/asset/AssetState';
import VideoTag from '../../../../../../../components/VideoPlayers/VideoTag';

type MobileAssetPreviewProps = {
  assets: IAssetMedia[];
  setOpenedAssetId: (assetId: AssetStateType['id']) => void;
  openedSlideIndex: number;
};

const MobileModalAssetPreview: FC<MobileAssetPreviewProps> = ({ assets, setOpenedAssetId, openedSlideIndex }) => {
  const renderImageView = (url: string, onLoad: () => void) => (
    <>
      <img onLoad={onLoad} className={styles.media} src={url} alt="asset" />
      <img className={styles.imageBackground} src={url} alt="asset" />
    </>
  );

  const renderVideoView = (isActive: boolean, url: string, onLoad: () => void) => (
    <>
      <VideoTag className={styles.imageBackground} muted loop src={url} />
      <CenteredButtonVideo loop src={url} isActive={isActive} onLoadedDataCapture={onLoad} />
    </>
  );

  const renderVideoProcessingView = () => <img className={styles.processing} src={VideoConvertingPlaceholderImage} alt={''} />;

  return (
    <>
      {assets.length > 1 ? <LearningSwipeBlur /> : null}
      <Swiper
        initialSlide={openedSlideIndex}
        slidesPerView={1}
        className={styles.root}
        onActiveIndexChange={swiper => {
          setOpenedAssetId(assets[swiper.activeIndex].id);
        }}
      >
        {assets.map((asset, idx) => {
          const { url, isVideo, isVideoProcessing } = asset;
          return (
            <SwiperSlide key={idx}>
              {({ isActive }) => (
                <div className={styles.mediaWrapper}>
                  <MediaDueType
                    isVideo={isVideo}
                    isVideoProcessing={isVideoProcessing}
                    renderImageView={(onLoad: () => void) => renderImageView(url, onLoad)}
                    renderVideoView={(onLoad: () => void) => renderVideoView(isActive, url, onLoad)}
                    renderVideoProcessingView={renderVideoProcessingView}
                    loaderClassName={styles.loader}
                  />
                </div>
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};
export default MobileModalAssetPreview;
