import React from 'react';
import styles from './CollaboratorsAvatar.module.scss';
import cs from 'classnames';

type PropsType = {
  name: string;
  imageUrl: string;
  size?: number;
  secondary?: boolean;
};

const CollaboratorsAvatar = ({ name, imageUrl, size = 34, secondary = false }: PropsType) => {
  const nameArray: string[] = name.split(' ');
  let nameSymbols: string = '';
  for (const nameArrayElement of nameArray) {
    nameSymbols += nameArrayElement[0] || '';
  }
  nameSymbols = nameSymbols.substr(0, 2);

  return (
    <>
      {imageUrl && (
        <img
          style={{ width: size, height: size, minWidth: size }}
          className={cs(styles.collaborator, { [styles.secondary]: secondary })}
          src={imageUrl}
          alt={'collaborator'}
        />
      )}
      {!imageUrl && (
        <span
          style={{ width: size, height: size, minWidth: size, fontSize: size / 3 }}
          className={cs(styles.collaborator, { [styles.secondary]: secondary })}
        >
          {nameSymbols}
        </span>
      )}
    </>
  );
};

export default CollaboratorsAvatar;
