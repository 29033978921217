import React, { useEffect, useState } from 'react';
import styles from './CollaboratorDropdownInvitedCell.module.scss';
import { TYPES_CAMPAIGNS_TEXTS } from '../../shared/constants/constants';
import { FaTelegramPlane } from 'react-icons/fa';
import CheckedSVG from '../../expert/components/IconsSvg/CheckedSVG';
import TrashSVG from '../../components/IconsSvg/TrashSVG';
import Loader from '../../expert/components/Loader';
import useTooltip from '../../shared/hooks/fixedTooltip/useTooltip';
import CollaboratorInviteLinkButton from '../../components/CollaboratorInviteLinkButton';
import cs from 'classnames';

const CollaboratorDropdownInvitedCell = ({ collaborator, onDelete, onResend, copy }) => {
  const [resendLoading, setResendLoading] = useState(false);
  const { toolTip, onEnter, onLeave } = useTooltip('bottom', { text: 'Delete invite', className: styles.tooltip });

  const handleDelete = () => {
    onDelete();
  };

  const handleResend = () => {
    onResend();
    setResendLoading(true);
    setTimeout(() => {
      setResendLoading(false);
    }, 1000);
  };

  useEffect(() => {
    return () => copy.setCopyId(null);
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.group}>
        <div className={styles.icon}>
          <FaTelegramPlane />
        </div>
        <div className={styles.nameContainer}>
          <span>{collaborator.email}</span>
          <span>
            Invite sent <CheckedSVG />
            {!resendLoading && (
              <span className={styles.resendButton} onClick={handleResend}>
                Resend
              </span>
            )}
            {resendLoading && <Loader className={styles.loader} />}
          </span>
        </div>
      </div>
      <div className={styles.hideElement}>-</div>
      <div className={cs(styles.group, styles.forTablet)}>
        <div className={styles.hideElement}>{TYPES_CAMPAIGNS_TEXTS[collaborator?.new_organization_type]}</div>
        <div style={{ display: 'flex', gap: 10 }}>
          <CollaboratorInviteLinkButton data={collaborator} copy={copy} />
          <div className={styles.deleteButton} onClick={handleDelete} onMouseEnter={onEnter} onMouseLeave={onLeave}>
            <TrashSVG />
            {toolTip}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollaboratorDropdownInvitedCell;
