import React from 'react';
import { ReactComponent as Icon0 } from '../../../../assets/img/icons/menu-icons/my-templates-icon.svg';
import { ReactComponent as Icon1 } from '../../../../assets/img/icons/menu-icons/my-uploads-icon.svg';
import { ReactComponent as Icon2 } from '../../../../assets/img/icons/menu-icons/my-creatives-icon.svg';
import { ReactComponent as Icon3 } from '../../../../assets/img/icons/menu-icons/smart-ads-icon.svg';
import { ReactComponent as Icon4 } from '../../../../assets/img/icons/menu-icons/winner-timelines-icon.svg';
import { ReactComponent as Icon5 } from '../../../../assets/img/icons/menu-icons/dashboard-icon.svg';
import { ReactComponent as Icon6 } from '../../../../assets/img/icons/menu-icons/meta-icon.svg';
import { ReactComponent as Icon7 } from '../../../../assets/img/icons/menu-icons/generate-creatives-icon.svg';
import { ReactComponent as Icon8 } from '../../../../assets/img/icons/menu-icons/launch-smart-ads-icon.svg';
import { ReactComponent as Icon9 } from '../../../../assets/img/icons/menu-icons/report-icon.svg';
import { ReactComponent as Icon10 } from '../../../../assets/img/icons/triangle.svg';
import { ReactComponent as Icon11 } from '../../../../assets/img/icons/menu-icons/campaigns.svg';
import DoneListSVG from '../../../../expert/components/IconsSvg/DoneListSVG';

export const MyTemplatesIcon = ({ className }) => <Icon0 className={className} />;
export const MyUploadsIcon = ({ className }) => <Icon1 className={className} />;
export const MyCreativesIcon = ({ className }) => <Icon2 className={className} />;
export const SmartAdsIcon = ({ className }) => <Icon3 className={className} />;
export const WinnerTimelinesIcon = ({ className }) => <Icon4 className={className} />;
export const DashboardIcon = ({ className }) => <Icon5 className={className} />;
export const FacebookPlatformIcon = ({ className }) => <Icon6 className={className} />;
export const GenerateCreativesIcon = ({ className }) => <Icon7 className={className} />;
export const LaunchSmartAdsIcon = ({ className }) => <Icon8 className={className} />;
export const ReportIcon = ({ className }) => <Icon9 className={className} />;
export const ReportTriangleIcon = ({ className }) => <Icon10 className={className} />;
export const CampaignsIcon = ({ className }) => <Icon11 className={className} />;
export const ContentApprovalIcon = ({ className }) => <DoneListSVG className={className} />;
