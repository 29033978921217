import CustomError from './CustomError';

export interface IDefaultErrorProps {
  message: string;
}

export default class DefaultError extends CustomError {
  message: string;

  constructor(error: IDefaultErrorProps) {
    super();
    this.message = error.message;
  }

  getMessage() {
    return this.message;
  }
}
