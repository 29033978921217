import { useEffect } from 'react';
import {
  clearContentBoardErrors,
  getBoardCollaborators,
  getContentBoard,
} from '../../../../actions/ContentBoardActions/contentBoard.actions';
import {
  clearDeliverablesErrors,
  clearOpenedDeliverableItem,
  getBoardDeliverableItem,
  setDeliverablePageModel,
} from '../../../../actions/deliverablesActions/deliverables.actions';
import { useHistory, useParams } from 'react-router-dom';
import useConceptState from './useConceptState';
import useContentState from './useContentState';
import { AuthorizedUser } from '../../../../models/permissions/users/AuthorizedUser';
import { PageModelService } from '../../../../models/permissions/services/PageModelService';
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/reduxHooks';
import { User } from '../../../../models/permissions/users/User';
import { AssetStateType } from '../../../../models/entities/asset/AssetState';
import { clearBoardBriefState, getBoardBrief } from '../../../../actions/BoardBriefActions/boardBriefActions';

export type ConceptFlowType = ReturnType<typeof useConceptState> & {
  isVisualExists: boolean;
  isSectionChanged: boolean;
};

export type ContentFlowType = ReturnType<typeof useContentState> & {
  isVisualExists: boolean;
  isSectionChanged: boolean;
};

type UseDeliverableStateType = () => {
  conceptFlow: ConceptFlowType;
  contentFlow: ContentFlowType;
  onDeleteAnyAsset: (asset: AssetStateType | null) => void;
};

const useDeliverableState: UseDeliverableStateType = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const params = useParams<{ boardId: string; deliverableId: string }>();
  const currentOrg = useAppSelector<any>(state => state.auth.currentOrganization);
  const userModel = useAppSelector<User>(state => state.auth.userModel);
  const openedDeliverable = useAppSelector(state => state.deliverables.openedDeliverable);
  const deliverableItemError = useAppSelector(state => state.deliverables.deliverableItemError);
  const collaborators = useAppSelector(state => state.contentBoard.collaborators);

  const { concept, conceptAssets, conceptEdit } = useConceptState();
  const { content, contentAssets, contentEdit } = useContentState();

  useEffect(() => {
    if (!currentOrg && userModel && userModel instanceof AuthorizedUser) return;
    dispatch(clearBoardBriefState());
    dispatch(getContentBoard(params.boardId));
    dispatch(getBoardDeliverableItem(params.boardId, params.deliverableId));
    dispatch(getBoardCollaborators(params.boardId));
    return () => {
      dispatch(clearOpenedDeliverableItem());
    };
  }, [currentOrg, params, userModel]);

  useEffect(() => {
    if (collaborators?.length && userModel) {
      const newPageModel = new PageModelService(userModel).getDeliverablePageModel(collaborators);
      if (newPageModel) {
        dispatch(setDeliverablePageModel(newPageModel));
      }
    }
  }, [collaborators, currentOrg, userModel]);

  useEffect(() => {
    if (deliverableItemError) {
      dispatch(clearContentBoardErrors());
      dispatch(clearDeliverablesErrors());
      history.push('/content-approval');
    }
  }, [deliverableItemError]);

  const onDeleteAnyAsset = (asset: AssetStateType | null): void => {
    if (!asset) return;
    const deleteFunctionsByTypes = {
      deliverable: contentAssets.onDelete,
      visual_concept: conceptAssets.onDelete,
    };
    const deleteFunction = deleteFunctionsByTypes[asset?.type];
    if (deleteFunction) {
      deleteFunction(asset);
    }
  };

  const conceptFlow: ConceptFlowType = {
    concept,
    conceptAssets,
    conceptEdit,
    isVisualExists: !!openedDeliverable?.concept?.assets?.length,
    isSectionChanged: conceptAssets.isCreate || conceptAssets.isUpdate || conceptAssets.isDelete || concept.isChanged,
  };

  const contentFlow: ContentFlowType = {
    content,
    contentAssets,
    contentEdit,
    isVisualExists: !!openedDeliverable?.content?.assets?.length,
    isSectionChanged: contentAssets.isCreate || contentAssets.isUpdate || contentAssets.isDelete || content.isChanged,
  };

  return {
    conceptFlow,
    contentFlow,
    onDeleteAnyAsset,
  };
};

export default useDeliverableState;
