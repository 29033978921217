import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import makeStore, { RootState } from '../../store';

export type AppDispatch = ReturnType<typeof makeStore>['dispatch'];

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useThunkDispatch: () => <Return = any>(...args: Parameters<AppDispatch>) => Promise<Return> = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
