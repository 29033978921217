import React, { useMemo } from 'react';
import styles from './FileInformationTooltip.module.scss';
import ButtonLightPurpleWhite from '../../../../Buttons/ButtonLightPurpleWhite';
import cs from 'classnames';
import { formatBytes } from '../../../../../shared/utils/helpers';
import dayjs from 'dayjs';
import moment from 'moment';
import EditorComponent from '../../../../EditorComponent';

const ONE_HOUR_IN_SECONDS = 3600;
const ONE_MINUTE_IN_SECONDS = 60;
const ONE_SECOND_IN_MILLISECONDS = 1000;

const FileInformationTooltip = ({ asset, onDelete, onReplace, isEditingMode, isGreenTag }) => {
  const assetDuration = asset?.duration;

  const duration = useMemo(() => {
    if (!assetDuration) return;

    if (assetDuration > ONE_HOUR_IN_SECONDS) {
      return moment.utc(asset.duration * ONE_SECOND_IN_MILLISECONDS).format('HH:mm:ss');
    }

    if (assetDuration > ONE_MINUTE_IN_SECONDS) {
      return moment.utc(asset.duration * ONE_SECOND_IN_MILLISECONDS).format('mm:ss');
    }

    return `${assetDuration} sec`;
  }, [asset]);

  return (
    <>
      {asset?.filename && <div className={styles.fileName}>{asset?.filename}</div>}

      <div className={cs(styles.info, { [styles.green]: isEditingMode || isGreenTag })}>
        {asset?.extension && <div>{asset?.extension.toUpperCase()}</div>}
        {asset?.size && <div>{formatBytes(asset?.size, 0)}</div>}
        {assetDuration && <div>{duration}</div>}
      </div>

      {asset?.created_at && (
        <div className={styles.date}>
          <span>Added:</span>
          {dayjs(asset?.created_at).format('DD:MM:YYYY HH:mm')}
        </div>
      )}

      {asset?.description?.length ? (
        <EditorComponent
          wrapperClassName={styles.descriptionTextWrapper}
          readOnly={true}
          editorClassName={styles.descriptionText}
          initialValue={asset?.description}
        />
      ) : null}

      {isEditingMode && (
        <div className={styles.buttonsContainer}>
          <ButtonLightPurpleWhite className={styles.actionButton} designVersion="v2" presetIcon="replace" size="medium" onClick={onReplace}>
            Replace
          </ButtonLightPurpleWhite>
          <ButtonLightPurpleWhite
            className={styles.actionButton}
            designVersion="v2"
            presetIcon="deleteCart"
            size="medium"
            onClick={onDelete}
          >
            Delete
          </ButtonLightPurpleWhite>
        </div>
      )}
    </>
  );
};

export default FileInformationTooltip;
