import React, { FC, useEffect, useMemo } from 'react';
import styles from './InstagramStoryPreview.module.scss';
import Stories from 'react-insta-stories';
import { ReactComponent as EmptyImage } from '../../../assets/img/icons/not_uploaded_icon.svg';
import VideoRenderer from './renderers/VideoRenderer';
import ImageRenderer from './renderers/ImageRenderer';
import { IAssetMediaStorySlider } from '../../../models/entities/asset/AssetMediaStorySlider';

/**
 * @description
 * Provide {assets} array with objects, with different CTA in each object. Or provide {callToAction} for all pages.
 * Provide {currentIndex}, {setIndex} and {onStoryEnd} to manage slides externally. If not provided, default controls will be used.
 * @param {Array<{url?: string, cta?: string, copy?: string}>} assets
 * @param {string} callToAction
 * @param {string} pageName
 * @param {number} currentIndex
 * @param setIndex
 * @param {(index: number, story: object) => void} onStoryEnd
 * @param {boolean} disableDefaultControls
 * @param {boolean} isPreviewText
 */

type InstagramStoryPreviewProps = {
  assets: IAssetMediaStorySlider[];
  callToAction: string | null;
  pageName?: string;
  currentIndex: number;
  setIndex: (index: number) => void;
  onStoryEnd?: (...unknown) => void;
  disableDefaultControls?: boolean;
  isPreviewText: boolean;
};

const InstagramStoryPreview: FC<InstagramStoryPreviewProps> = ({
  assets,
  callToAction,
  pageName,
  currentIndex,
  setIndex,
  onStoryEnd = () => {},
  disableDefaultControls = false,
  isPreviewText = false,
}) => {
  const stories = useMemo(
    () =>
      assets.map(item => {
        const story = {
          pageName,
          url: item.url,
          type: item.mime_type.match(/^\w*/g)?.[0],
          copy: isPreviewText ? item.copy : null,
          cta: callToAction,
        };
        return story;
      }),
    [assets]
  );

  useEffect(() => {
    if (stories.length - 1 < currentIndex) {
      setIndex(0);
    }
  }, [stories]);

  return (
    <div className={styles.root}>
      {stories?.length ? (
        <Stories
          renderers={[VideoRenderer, ImageRenderer]}
          preventDefault={disableDefaultControls || !currentIndex}
          currentIndex={currentIndex > stories.length - 1 ? 0 : currentIndex}
          loop
          stories={stories || []}
          onStoryEnd={onStoryEnd}
          defaultInterval={14000}
          width="100%"
          height="100%"
        />
      ) : (
        <div className={styles.emptyImage}>
          <EmptyImage />
          <span>No image yet</span>
        </div>
      )}
    </div>
  );
};

export default InstagramStoryPreview;
