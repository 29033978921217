import React from 'react';
import dayjs from 'dayjs';
import GenericCell from '../GenericCell';
import { NOTIFICATIONS_TEXTS, NOTIFICATIONS_TYPES } from './constants';
import DateSeparator from '../DateSeparator';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import moment from 'moment';
import { DELIVERABLE_FLOWS } from '../../../../pages/ContentApproval/DeliverableItem/constants';
import { TYPES_CAMPAIGNS } from '../../../../shared/constants/constants';
import EmptyCellItem from '../EmptyCellItem';

dayjs.extend(isToday);
dayjs.extend(isYesterday);

const SEPARATOR_DATE_FORMAT = 'dddd, MMM D';

const getSeparatorsMap = data => {
  const separators = new Map();

  for (const [index, item] of data.entries()) {
    const date = item.created_at;
    const dateFormatted = dayjs(date).format(SEPARATOR_DATE_FORMAT);

    if (dayjs(date).isToday()) {
      if (index === 0) {
        separators.set(dateFormatted, { index: 0, separatorJSX: <DateSeparator key={'Today'} text={'Today'} /> });
      }
      continue;
    }

    if (dayjs(date).isYesterday()) {
      if (!separators.has(dateFormatted)) {
        separators.set(dateFormatted, { index: index, separatorJSX: <DateSeparator key={'Yesterday'} text={'Yesterday'} /> });
      }
      continue;
    }

    if (!separators.has(dateFormatted)) {
      separators.set(dateFormatted, { index: index, separatorJSX: <DateSeparator key={dateFormatted} text={dateFormatted} /> });
    }
  }

  return separators;
};

const checkAndSetSeparator = (separators, notification, index, array) => {
  const separatorData = separators.get(dayjs(notification.created_at).format(SEPARATOR_DATE_FORMAT));
  if (separatorData && separatorData.index === index) {
    array.push(separatorData.separatorJSX);
  }
};

const formatCreatedDate = date => dayjs(date).format('hh:mm A');

export const getNotificationsCells = (data, functions, variables) => {
  const separators = getSeparatorsMap(data);
  const cellsArray = [];
  let wasTodayNotifications = false;

  data.forEach((item, index) => {
    if (dayjs(item.created_at).isToday()) {
      wasTodayNotifications = true;
    }
    switch (item.type) {
      case NOTIFICATIONS_TYPES.DISCUSSION_ROOM_CREATE: {
        checkAndSetSeparator(separators, item, index, cellsArray);

        cellsArray.push(
          <GenericCell
            key={index}
            unread={!item.is_read}
            textWithJSX={<NotificationTextBySettings action={item.type} data={item?.payload} />}
            timestamp={`${formatCreatedDate(item.created_at)}`}
            onClick={() => functions.onNotificationClick(item.id)}
          />
        );
        break;
      }
      case NOTIFICATIONS_TYPES.CONTENT_BOARD_INVITE:
        checkAndSetSeparator(separators, item, index, cellsArray);
        cellsArray.push(
          <GenericCell
            key={index}
            unread={!item.is_read}
            textWithJSX={<NotificationTextBySettings action={item.type} data={item?.payload} />}
            timestamp={`${formatCreatedDate(item.created_at)}`}
            onClick={() => functions.onNotificationClick(item.id)}
            action1={
              item.payload?.acceptedAt
                ? null
                : {
                    text: 'Accept',
                    onClick: () => {
                      const link = item.payload?.confirmLink?.match(/\/confirm-invite.*/g)[0];
                      if (link) {
                        functions.changeRoute(link);
                      }
                      functions.closeModal();
                    },
                  }
            }
          />
        );
        break;
      case NOTIFICATIONS_TYPES.CAMPAIGN_BRIEF_ITEM_INVITE:
        checkAndSetSeparator(separators, item, index, cellsArray);
        cellsArray.push(
          <GenericCell
            key={index}
            unread={!item.is_read}
            textWithJSX={<NotificationTextBySettings action={item.type} data={item?.payload} />}
            timestamp={`${formatCreatedDate(item.created_at)}`}
            onClick={() => functions.onNotificationClick(item.id)}
            action1={
              item.payload?.acceptedAt
                ? null
                : {
                    text: 'Accept',
                    onClick: () => {
                      const link = item.payload?.confirmLink?.match(/\/confirm-invite.*/g)[0];
                      if (link) {
                        functions.changeRoute(link);
                      }
                      functions.closeModal();
                    },
                  }
            }
          />
        );
        break;
      case NOTIFICATIONS_TYPES.CONTENT_BOARD_CREATE_FROM_CREATOR_BRIEF:
        checkAndSetSeparator(separators, item, index, cellsArray);
        cellsArray.push(
          <GenericCell
            key={index}
            unread={!item.is_read}
            textWithJSX={<NotificationTextBySettings action={item.type} data={item?.payload} />}
            timestamp={`${formatCreatedDate(item.created_at)}`}
            onClick={() => functions.onNotificationClick(item.id)}
          />
        );
        break;
      case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_UPDATE_NAME:
        checkAndSetSeparator(separators, item, index, cellsArray);
        cellsArray.push(
          <GenericCell
            key={index}
            unread={!item.is_read}
            textWithJSX={
              <NotificationTextBySettings action={item.type} data={item?.payload} settings={{ onClose: functions.closeModal }} />
            }
            timestamp={`${formatCreatedDate(item.created_at)}`}
            onClick={() => functions.onNotificationClick(item.id)}
          />
        );
        break;
      case NOTIFICATIONS_TYPES.CONTENT_BOARD_CHANGE_APPROVER:
        checkAndSetSeparator(separators, item, index, cellsArray);
        cellsArray.push(
          <GenericCell
            key={index}
            unread={!item.is_read}
            textWithJSX={<NotificationTextBySettings action={item.type} data={item?.payload} />}
            timestamp={`${formatCreatedDate(item.created_at)}`}
            onClick={() => functions.onNotificationClick(item.id)}
          />
        );
        break;
      case NOTIFICATIONS_TYPES.CONTENT_BOARD_CREATE_DELIVERABLES: {
        checkAndSetSeparator(separators, item, index, cellsArray);

        cellsArray.push(
          <GenericCell
            key={index}
            unread={!item.is_read}
            textWithJSX={
              <NotificationTextBySettings action={item.type} data={item?.payload} settings={{ onClose: functions.closeModal }} />
            }
            timestamp={`${formatCreatedDate(item.created_at)}`}
            onClick={() => functions.onNotificationClick(item.id)}
          />
        );
        break;
      }
      case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_VERSION_CONCEPT_EDIT: {
        checkAndSetSeparator(separators, item, index, cellsArray);
        const { role, rolesFlow } = getRoleAndFlow(item?.payload, variables?.userId);
        cellsArray.push(
          <GenericCell
            key={index}
            unread={!item.is_read}
            textWithJSX={
              <NotificationTextBySettings
                action={item.type}
                data={item?.payload}
                settings={{ role, rolesFlow, onClose: functions.closeModal }}
              />
            }
            timestamp={`${formatCreatedDate(item.created_at)}`}
            onClick={() => functions.onNotificationClick(item.id)}
            action1={{
              text: 'View Content',
              onClick: () => {
                functions.changeRoute(
                  `/content-board/${item?.payload?.contentBoardDeliverable?.content_board_id}/deliverables/${item?.payload?.contentBoardDeliverable?.id}`
                );
                functions.closeModal();
              },
            }}
          />
        );
        break;
      }
      case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_VERSION_CONTENT_EDIT: {
        checkAndSetSeparator(separators, item, index, cellsArray);
        const { role, rolesFlow } = getRoleAndFlow(item?.payload, variables?.userId);
        cellsArray.push(
          <GenericCell
            key={index}
            unread={!item.is_read}
            textWithJSX={
              <NotificationTextBySettings
                action={item.type}
                data={item?.payload}
                settings={{ role, rolesFlow, onClose: functions.closeModal }}
              />
            }
            timestamp={`${formatCreatedDate(item.created_at)}`}
            onClick={() => functions.onNotificationClick(item.id)}
            action1={{
              text: 'View Content',
              onClick: () => {
                functions.changeRoute(
                  `/content-board/${item?.payload?.contentBoardDeliverable?.content_board_id}/deliverables/${item?.payload?.contentBoardDeliverable?.id}`
                );
                functions.closeModal();
              },
            }}
          />
        );
        break;
      }
      case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_VERSION_CONCEPT_SHARE:
        checkAndSetSeparator(separators, item, index, cellsArray);

        cellsArray.push(
          <GenericCell
            key={index}
            unread={!item.is_read}
            textWithJSX={
              <NotificationTextBySettings action={item.type} data={item?.payload} settings={{ onClose: functions.closeModal }} />
            }
            timestamp={`${formatCreatedDate(item.created_at)}`}
            onClick={() => functions.onNotificationClick(item.id)}
          />
        );
        break;
      case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_VERSION_CONTENT_SHARE:
        checkAndSetSeparator(separators, item, index, cellsArray);

        cellsArray.push(
          <GenericCell
            key={index}
            unread={!item.is_read}
            textWithJSX={
              <NotificationTextBySettings action={item.type} data={item?.payload} settings={{ onClose: functions.closeModal }} />
            }
            timestamp={`${formatCreatedDate(item.created_at)}`}
            onClick={() => functions.onNotificationClick(item.id)}
          />
        );
        break;
      case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_VERSION_CONCEPT_APPROVE:
        checkAndSetSeparator(separators, item, index, cellsArray);

        cellsArray.push(
          <GenericCell
            key={index}
            unread={!item.is_read}
            textWithJSX={
              <NotificationTextBySettings action={item.type} data={item?.payload} settings={{ onClose: functions.closeModal }} />
            }
            timestamp={`${formatCreatedDate(item.created_at)}`}
            onClick={() => functions.onNotificationClick(item.id)}
          />
        );
        break;
      case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_VERSION_CONTENT_APPROVE:
        checkAndSetSeparator(separators, item, index, cellsArray);

        cellsArray.push(
          <GenericCell
            key={index}
            unread={!item.is_read}
            textWithJSX={
              <NotificationTextBySettings action={item.type} data={item?.payload} settings={{ onClose: functions.closeModal }} />
            }
            timestamp={`${formatCreatedDate(item.created_at)}`}
            onClick={() => functions.onNotificationClick(item.id)}
          />
        );
        break;
      case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_UPDATE_LIVE_DATETIME: {
        checkAndSetSeparator(separators, item, index, cellsArray);
        const timezoneDate = functions.convertUtcDateToDateByTimezone(item.payload?.contentBoardDeliverable?.live_datetime);
        const newLiveDate = timezoneDate?.format('hh:mm [on] A DD/MM/YYYY');

        cellsArray.push(
          <GenericCell
            key={index}
            unread={!item.is_read}
            textWithJSX={
              <NotificationTextBySettings
                action={item.type}
                data={item?.payload}
                settings={{ newLiveDate, onClose: functions.closeModal }}
              />
            }
            timestamp={`${formatCreatedDate(item.created_at)}`}
            onClick={() => functions.onNotificationClick(item.id)}
          />
        );
        break;
      }
      case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_CONCEPT_REMINDER: {
        checkAndSetSeparator(separators, item, index, cellsArray);
        const timezoneDate = functions.convertUtcDateToDateByTimezone(item.payload?.contentBoardDeliverable?.concept_due_datetime);
        const dueDate = timezoneDate?.format('hh:mm A [on] DD/MM/YYYY');

        cellsArray.push(
          <GenericCell
            key={index}
            unread={!item.is_read}
            textWithJSX={
              <NotificationTextBySettings action={item.type} data={item?.payload} settings={{ dueDate, onClose: functions.closeModal }} />
            }
            timestamp={`${formatCreatedDate(item.created_at)}`}
            onClick={() => functions.onNotificationClick(item.id)}
          />
        );
        break;
      }
      case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_CONTENT_REMINDER: {
        checkAndSetSeparator(separators, item, index, cellsArray);
        const timezoneDate = functions.convertUtcDateToDateByTimezone(item.payload?.contentBoardDeliverable?.content_due_datetime);
        const dueDate = timezoneDate?.format('hh:mm A [on] DD/MM/YYYY');

        cellsArray.push(
          <GenericCell
            key={index}
            unread={!item.is_read}
            textWithJSX={
              <NotificationTextBySettings action={item.type} data={item?.payload} settings={{ dueDate, onClose: functions.closeModal }} />
            }
            timestamp={`${formatCreatedDate(item.created_at)}`}
            onClick={() => functions.onNotificationClick(item.id)}
          />
        );
        break;
      }
      case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_REMIND_TODAY_CONCEPT_DUE_DATETIME:
        checkAndSetSeparator(separators, item, index, cellsArray);
        cellsArray.push(
          <GenericCell
            key={index}
            unread={!item.is_read}
            textWithJSX={
              <NotificationTextBySettings action={item.type} data={item?.payload} settings={{ onClose: functions.closeModal }} />
            }
            timestamp={`${formatCreatedDate(item.created_at)}`}
            onClick={() => functions.onNotificationClick(item.id)}
          />
        );
        break;
      case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_REMIND_1DAY_BEFORE_CONCEPT_DUE_DATETIME: {
        checkAndSetSeparator(separators, item, index, cellsArray);
        const timezoneDate = functions.convertUtcDateToDateByTimezone(item.payload?.contentBoardDeliverable?.concept_due_datetime);
        const dueDate = timezoneDate?.format('DD/MM/YYYY');

        cellsArray.push(
          <GenericCell
            key={index}
            unread={!item.is_read}
            textWithJSX={
              <NotificationTextBySettings action={item.type} data={item?.payload} settings={{ dueDate, onClose: functions.closeModal }} />
            }
            timestamp={`${formatCreatedDate(item.created_at)}`}
            onClick={() => functions.onNotificationClick(item.id)}
          />
        );
        break;
      }
      case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_REMIND_2DAYS_BEFORE_CONCEPT_DUE_DATETIME: {
        checkAndSetSeparator(separators, item, index, cellsArray);
        const timezoneDate = functions.convertUtcDateToDateByTimezone(item.payload?.contentBoardDeliverable?.concept_due_datetime);
        const dueDate = timezoneDate?.format('DD/MM/YYYY');

        cellsArray.push(
          <GenericCell
            key={index}
            unread={!item.is_read}
            textWithJSX={
              <NotificationTextBySettings action={item.type} data={item?.payload} settings={{ dueDate, onClose: functions.closeModal }} />
            }
            timestamp={`${formatCreatedDate(item.created_at)}`}
            onClick={() => functions.onNotificationClick(item.id)}
          />
        );
        break;
      }
      case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_REMIND_TODAY_CONTENT_DUE_DATETIME:
        checkAndSetSeparator(separators, item, index, cellsArray);
        cellsArray.push(
          <GenericCell
            key={index}
            unread={!item.is_read}
            textWithJSX={
              <NotificationTextBySettings action={item.type} data={item?.payload} settings={{ onClose: functions.closeModal }} />
            }
            timestamp={`${formatCreatedDate(item.created_at)}`}
            onClick={() => functions.onNotificationClick(item.id)}
          />
        );
        break;
      case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_REMIND_1DAY_BEFORE_CONTENT_DUE_DATETIME: {
        checkAndSetSeparator(separators, item, index, cellsArray);
        const timezoneDate = functions.convertUtcDateToDateByTimezone(item.payload?.contentBoardDeliverable?.content_due_datetime);
        const dueDate = timezoneDate?.format('DD/MM/YYYY');

        cellsArray.push(
          <GenericCell
            key={index}
            unread={!item.is_read}
            textWithJSX={
              <NotificationTextBySettings action={item.type} data={item?.payload} settings={{ dueDate, onClose: functions.closeModal }} />
            }
            timestamp={`${formatCreatedDate(item.created_at)}`}
            onClick={() => functions.onNotificationClick(item.id)}
          />
        );
        break;
      }
      case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_REMIND_2DAYS_BEFORE_CONTENT_DUE_DATETIME: {
        checkAndSetSeparator(separators, item, index, cellsArray);
        const timezoneDate = functions.convertUtcDateToDateByTimezone(item.payload?.contentBoardDeliverable?.content_due_datetime);
        const dueDate = timezoneDate?.format('DD/MM/YYYY');

        cellsArray.push(
          <GenericCell
            key={index}
            unread={!item.is_read}
            textWithJSX={
              <NotificationTextBySettings action={item.type} data={item?.payload} settings={{ dueDate, onClose: functions.closeModal }} />
            }
            timestamp={`${formatCreatedDate(item.created_at)}`}
            onClick={() => functions.onNotificationClick(item.id)}
          />
        );
        break;
      }
      case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_REMIND_TODAY_LIVE_DATETIME: {
        checkAndSetSeparator(separators, item, index, cellsArray);
        const timezoneDate = functions.convertUtcDateToDateByTimezone(item.payload?.contentBoardDeliverable?.live_datetime);
        const liveDate = timezoneDate?.format('hh:mm A');
        cellsArray.push(
          <GenericCell
            key={index}
            unread={!item.is_read}
            textWithJSX={
              <NotificationTextBySettings action={item.type} data={item?.payload} settings={{ liveDate, onClose: functions.closeModal }} />
            }
            timestamp={`${formatCreatedDate(item.created_at)}`}
            onClick={() => functions.onNotificationClick(item.id)}
          />
        );
        break;
      }
      case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_REMIND_1DAY_BEFORE_LIVE_DATETIME: {
        checkAndSetSeparator(separators, item, index, cellsArray);
        const timezoneDate = functions.convertUtcDateToDateByTimezone(item.payload?.contentBoardDeliverable?.live_datetime);
        const liveDate = timezoneDate?.format('hh:mm A');
        cellsArray.push(
          <GenericCell
            key={index}
            unread={!item.is_read}
            textWithJSX={
              <NotificationTextBySettings action={item.type} data={item?.payload} settings={{ liveDate, onClose: functions.closeModal }} />
            }
            timestamp={`${formatCreatedDate(item.created_at)}`}
            onClick={() => functions.onNotificationClick(item.id)}
          />
        );
        break;
      }
      case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_REMIND_2DAYS_BEFORE_LIVE_DATETIME: {
        checkAndSetSeparator(separators, item, index, cellsArray);
        const timezoneDate = functions.convertUtcDateToDateByTimezone(item.payload?.contentBoardDeliverable?.live_datetime);
        const liveDate = timezoneDate?.format('hh:mm A');
        cellsArray.push(
          <GenericCell
            key={index}
            unread={!item.is_read}
            textWithJSX={
              <NotificationTextBySettings action={item.type} data={item?.payload} settings={{ liveDate, onClose: functions.closeModal }} />
            }
            timestamp={`${formatCreatedDate(item.created_at)}`}
            onClick={() => functions.onNotificationClick(item.id)}
          />
        );
        break;
      }
    }
  });
  if (!wasTodayNotifications) {
    cellsArray.unshift(<EmptyCellItem />);
    cellsArray.unshift(<DateSeparator key={'Today'} text={'Today'} />);
  }
  return cellsArray;
};

const NotificationTextBySettings = ({ action, ...props }) => {
  const Text = NOTIFICATIONS_TEXTS[action];
  return <Text {...props} />;
};

const toastsDelay = 3000;
export const handleNotificationsSocketsAction = (ctx, { notificationHandler, convertUtcDateToDateByTimezone, changeRoute, userId }) => {
  const action = ctx.data.action;
  const data = ctx.data.data;
  const dateNow = convertUtcDateToDateByTimezone(moment.utc()).format('MMM Mo [at] hh:mm A');

  switch (action) {
    case NOTIFICATIONS_TYPES.DISCUSSION_ROOM_CREATE: {
      notificationHandler({
        type: 'info',
        title: 'Discussion',
        textCallback: () => <NotificationTextBySettings action={action} data={data} />,
        delay: toastsDelay,
        dateString: dateNow,
      });
      break;
    }
    case NOTIFICATIONS_TYPES.CONTENT_BOARD_INVITE: {
      notificationHandler({
        type: 'info',
        title: 'Invitation to Collaborate',
        textCallback: () => <NotificationTextBySettings action={action} data={data} />,
        image: data?.senderOrganization?.thumbnail_location,
        primaryButtonData: {
          text: 'Accept',
          action: onClose => {
            const link = data?.confirmLink?.match(/\/confirm-invite.*/g)[0];
            if (link) {
              changeRoute(link);
            }
            onClose();
          },
        },
        secondaryButtonData: {
          text: 'Reject',
          action: onClose => onClose(),
        },
        delay: toastsDelay,
        dateString: dateNow,
      });
      break;
    }
    case NOTIFICATIONS_TYPES.CAMPAIGN_BRIEF_ITEM_INVITE: {
      notificationHandler({
        type: 'info',
        title: 'Invitation to Collaborate',
        textCallback: () => <NotificationTextBySettings action={action} data={data} />,
        image: data?.senderOrganization?.thumbnail_location,
        primaryButtonData: {
          text: 'Accept',
          action: onClose => {
            const link = data?.confirmLink?.match(/\/confirm-invite.*/g)[0];
            if (link) {
              changeRoute(link);
            }
            onClose();
          },
        },
        secondaryButtonData: {
          text: 'Reject',
          action: onClose => onClose(),
        },
        delay: toastsDelay,
        dateString: dateNow,
      });
      break;
    }
    case NOTIFICATIONS_TYPES.CONTENT_BOARD_CREATE_FROM_CREATOR_BRIEF: {
      notificationHandler({
        type: 'info',
        title: 'Content Board Update',
        textCallback: () => <NotificationTextBySettings action={action} data={data} />,
        delay: toastsDelay,
        dateString: dateNow,
      });
      break;
    }
    case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_UPDATE_NAME: {
      notificationHandler({
        type: 'info',
        title: 'Content Board Update',
        textCallback: onClose => <NotificationTextBySettings action={action} data={data} settings={{ onClose }} />,
        delay: toastsDelay,
        dateString: dateNow,
      });
      break;
    }
    case NOTIFICATIONS_TYPES.CONTENT_BOARD_CHANGE_APPROVER: {
      notificationHandler({
        type: 'info',
        title: 'Content Board Update',
        image: data?.senderUser?.photo_thumbnail_location,
        textCallback: () => <NotificationTextBySettings action={action} data={data} />,
        delay: toastsDelay,
        dateString: dateNow,
      });
      break;
    }
    case NOTIFICATIONS_TYPES.CONTENT_BOARD_CREATE_DELIVERABLES: {
      notificationHandler({
        type: 'info',
        title: 'Content Board Update',
        textCallback: onClose => <NotificationTextBySettings action={action} data={data} settings={{ onClose }} />,
        delay: toastsDelay,
        dateString: dateNow,
      });
      break;
    }
    case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_VERSION_CONCEPT_EDIT: {
      const { role, rolesFlow } = getRoleAndFlow(data, userId);

      notificationHandler({
        type: 'info',
        title: 'Content Board Update',
        textCallback: onClose => <NotificationTextBySettings action={action} data={data} settings={{ role, rolesFlow, onClose }} />,
        primaryButtonData: {
          text: 'View Content',
          action: onClose => {
            changeRoute(
              `/content-board/${data?.contentBoardDeliverable?.content_board_id}/deliverables/${data?.contentBoardDeliverable?.id}`
            );
            onClose();
          },
        },
        delay: toastsDelay,
        dateString: dateNow,
      });
      break;
    }
    case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_VERSION_CONTENT_EDIT: {
      const { role, rolesFlow } = getRoleAndFlow(data, userId);
      notificationHandler({
        type: 'info',
        title: 'Content Board Update',
        textCallback: onClose => <NotificationTextBySettings action={action} data={data} settings={{ role, rolesFlow, onClose }} />,
        primaryButtonData: {
          text: 'View Content',
          action: onClose => {
            changeRoute(
              `/content-board/${data?.contentBoardDeliverable?.content_board_id}/deliverables/${data?.contentBoardDeliverable?.id}`
            );
            onClose();
          },
        },
        delay: toastsDelay,
        dateString: dateNow,
      });
      break;
    }
    case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_VERSION_CONCEPT_SHARE: {
      notificationHandler({
        type: 'info',
        title: 'Content Board Update',
        textCallback: onClose => <NotificationTextBySettings action={action} data={data} settings={{ onClose }} />,
        delay: toastsDelay,
        dateString: dateNow,
      });
      break;
    }
    case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_VERSION_CONTENT_SHARE: {
      notificationHandler({
        type: 'info',
        title: 'Content Board Update',
        textCallback: onClose => <NotificationTextBySettings action={action} data={data} settings={{ onClose }} />,
        delay: toastsDelay,
        dateString: dateNow,
      });
      break;
    }
    case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_VERSION_CONCEPT_APPROVE: {
      notificationHandler({
        type: 'success',
        title: 'Content Board Update',
        textCallback: onClose => <NotificationTextBySettings action={action} data={data} settings={{ onClose }} />,
        delay: toastsDelay,
        dateString: dateNow,
      });
      break;
    }
    case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_VERSION_CONTENT_APPROVE: {
      notificationHandler({
        type: 'success',
        title: 'Content Board Update',
        textCallback: onClose => <NotificationTextBySettings action={action} data={data} settings={{ onClose }} />,
        delay: toastsDelay,
        dateString: dateNow,
      });
      break;
    }
    case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_UPDATE_LIVE_DATETIME: {
      const timezoneDate = convertUtcDateToDateByTimezone(data?.contentBoardDeliverable?.live_datetime);
      const newLiveDate = timezoneDate?.format('hh:mm A [on] DD/MM/YYYY');
      notificationHandler({
        type: 'info',
        title: 'Content Board Update',
        textCallback: onClose => <NotificationTextBySettings action={action} data={data} settings={{ newLiveDate, onClose }} />,
        delay: toastsDelay,
        dateString: dateNow,
      });
      break;
    }
    case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_CONCEPT_REMINDER: {
      const timezoneDate = convertUtcDateToDateByTimezone(data?.contentBoardDeliverable?.concept_due_datetime);
      const dueDate = timezoneDate?.format('hh:mm A [on] DD/MM/YYYY');
      notificationHandler({
        type: 'clock',
        title: 'Content Board Update',
        textCallback: onClose => <NotificationTextBySettings action={action} data={data} settings={{ dueDate, onClose }} />,
        delay: toastsDelay,
        dateString: dateNow,
      });
      break;
    }
    case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_CONTENT_REMINDER: {
      const timezoneDate = convertUtcDateToDateByTimezone(data?.contentBoardDeliverable?.content_due_datetime);
      const dueDate = timezoneDate?.format('hh:mm A [on] DD/MM/YYYY');
      notificationHandler({
        type: 'clock',
        title: 'Content Board Update',
        textCallback: onClose => <NotificationTextBySettings action={action} data={data} settings={{ dueDate, onClose }} />,
        delay: toastsDelay,
        dateString: dateNow,
      });
      break;
    }
    case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_REMIND_TODAY_CONCEPT_DUE_DATETIME: {
      notificationHandler({
        type: 'clock',
        title: 'Content Board Update',
        textCallback: onClose => <NotificationTextBySettings action={action} data={data} settings={{ onClose }} />,
        delay: toastsDelay,
        dateString: dateNow,
      });
      break;
    }
    case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_REMIND_1DAY_BEFORE_CONCEPT_DUE_DATETIME: {
      const timezoneDate = convertUtcDateToDateByTimezone(data?.contentBoardDeliverable?.concept_due_datetime);
      const dueDate = timezoneDate?.format('DD/MM/YYYY');
      notificationHandler({
        type: 'clock2',
        title: 'Content Board Update',
        textCallback: onClose => <NotificationTextBySettings action={action} data={data} settings={{ dueDate, onClose }} />,
        delay: toastsDelay,
        dateString: dateNow,
      });
      break;
    }
    case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_REMIND_2DAYS_BEFORE_CONCEPT_DUE_DATETIME: {
      const timezoneDate = convertUtcDateToDateByTimezone(data?.contentBoardDeliverable?.concept_due_datetime);
      const dueDate = timezoneDate?.format('DD/MM/YYYY');
      notificationHandler({
        type: 'clock2',
        title: 'Content Board Update',
        textCallback: onClose => <NotificationTextBySettings action={action} data={data} settings={{ dueDate, onClose }} />,
        delay: toastsDelay,
        dateString: dateNow,
      });
      break;
    }
    case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_REMIND_TODAY_CONTENT_DUE_DATETIME: {
      notificationHandler({
        type: 'clock',
        title: 'Content Board Update',
        textCallback: onClose => <NotificationTextBySettings action={action} data={data} settings={{ onClose }} />,
        delay: toastsDelay,
        dateString: dateNow,
      });
      break;
    }
    case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_REMIND_1DAY_BEFORE_CONTENT_DUE_DATETIME: {
      const timezoneDate = convertUtcDateToDateByTimezone(data?.contentBoardDeliverable?.content_due_datetime);
      const dueDate = timezoneDate?.format('DD/MM/YYYY');
      notificationHandler({
        type: 'clock2',
        title: 'Content Board Update',
        textCallback: onClose => <NotificationTextBySettings action={action} data={data} settings={{ dueDate, onClose }} />,
        delay: toastsDelay,
        dateString: dateNow,
      });
      break;
    }
    case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_REMIND_2DAYS_BEFORE_CONTENT_DUE_DATETIME: {
      const timezoneDate = convertUtcDateToDateByTimezone(data?.contentBoardDeliverable?.content_due_datetime);
      const dueDate = timezoneDate?.format('DD/MM/YYYY');
      notificationHandler({
        type: 'clock2',
        title: 'Content Board Update',
        textCallback: onClose => <NotificationTextBySettings action={action} data={data} settings={{ dueDate, onClose }} />,
        delay: toastsDelay,
        dateString: dateNow,
      });
      break;
    }
    case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_REMIND_TODAY_LIVE_DATETIME: {
      const timezoneDate = convertUtcDateToDateByTimezone(data?.contentBoardDeliverable?.live_datetime);
      const liveDate = timezoneDate?.format('hh:mm A');
      notificationHandler({
        type: 'clock',
        title: 'Content Board Update',
        textCallback: onClose => <NotificationTextBySettings action={action} data={data} settings={{ liveDate, onClose }} />,
        delay: toastsDelay,
        dateString: dateNow,
      });
      break;
    }
    case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_REMIND_1DAY_BEFORE_LIVE_DATETIME: {
      const timezoneDate = convertUtcDateToDateByTimezone(data?.contentBoardDeliverable?.live_datetime);
      const liveDate = timezoneDate?.format('hh:mm A');
      notificationHandler({
        type: 'clock2',
        title: 'Content Board Update',
        textCallback: onClose => <NotificationTextBySettings action={action} data={data} settings={{ liveDate, onClose }} />,
        delay: toastsDelay,
        dateString: dateNow,
      });
      break;
    }
    case NOTIFICATIONS_TYPES.CONTENT_BOARD_DELIVERABLE_REMIND_2DAYS_BEFORE_LIVE_DATETIME: {
      const timezoneDate = convertUtcDateToDateByTimezone(data?.contentBoardDeliverable?.live_datetime);
      const liveDate = timezoneDate?.format('hh:mm A');
      notificationHandler({
        type: 'clock2',
        title: 'Content Board Update',
        textCallback: onClose => <NotificationTextBySettings action={action} data={data} settings={{ liveDate, onClose }} />,
        delay: toastsDelay,
        dateString: dateNow,
      });
      break;
    }
  }
};

export const getSectionEditNotificationTextEnd = (role, rolesFlow) => {
  const textEndSettings = {
    [DELIVERABLE_FLOWS.CC_BA]: {
      [TYPES_CAMPAIGNS.AGENCY]: 'and is waiting for you to approve',
      [TYPES_CAMPAIGNS.BRAND]: 'and is waiting for you to approve',
      [TYPES_CAMPAIGNS.TALENT_MANAGER]: '',
      [TYPES_CAMPAIGNS.CONTENT_CREATOR]: '',
    },
    [DELIVERABLE_FLOWS.CC_TM_BA]: {
      [TYPES_CAMPAIGNS.TALENT_MANAGER]: 'for your review',
      [TYPES_CAMPAIGNS.CONTENT_CREATOR]: '',
    },
  };

  return textEndSettings[rolesFlow][role];
};

const getRoleAndFlow = (data, userId) => {
  const collaborators = data?.contentBoardDeliverable?.collaborators || [];
  const myRole = collaborators.find(c => +c.approver_id === +userId)?.collaborator_type;
  const isCurrentFlowWithTalent =
    collaborators.find(collaborator => collaborator.collaborator_type === TYPES_CAMPAIGNS.TALENT_MANAGER) &&
    collaborators.find(collaborator => collaborator.collaborator_type === TYPES_CAMPAIGNS.CONTENT_CREATOR);
  return { role: myRole, rolesFlow: isCurrentFlowWithTalent ? DELIVERABLE_FLOWS.CC_TM_BA : DELIVERABLE_FLOWS.CC_BA };
};
