import React, {FC, useCallback, useState} from 'react';
import styles from './BoardInfoSection.module.scss';
import UpcomingDatesCard from './components/UpcomingDatesCard';
import AddBriefModal from './components/AddBriefModal';
import {useMediaQuery} from 'react-responsive';
import {useAppSelector} from '../../../../../shared/hooks/reduxHooks';
import BriefDocumentCard from './components/BriefCard';
import UpcomingDatesModal from './components/UpcomingDatesModal';
import {IDeliverable} from '../../../../../models/entities/deliverable/Deliverable';
import ResultsCard from "./components/results/ResultsCard";
import UploadResultsModal from "../../../components/results/UploadResultsModal";

const BoardInfoSection: FC = () => {
  const [isOpenAddBriefModal, setIsOpenAddBriefModal] = useState<boolean>(false);
  const [isOpenUpcomingDatesModal, setIsOpenUpcomingDatesModal] = useState<boolean>(false);
  const [isOpenUploadResultsModal, setIsOpenUploadResultsModal] = useState<boolean>(false);
  const [uploadFiles, setUploadFiles] = useState<File[]>([]);
  const boardLoading = useAppSelector(state => state.contentBoard.boardLoading);
  const upcomingLiveDatesDeliverablesLoading = useAppSelector(state => state.contentBoard.upcomingLiveDatesDeliverablesLoading);
  const deliverables = useAppSelector<IDeliverable[] | null>(state => state.contentBoard?.upcomingLiveDatesDeliverables || null);
  const isTablet: boolean = useMediaQuery({ query: '(max-width: 768px)' });

  const onOpenAddBriefModal = useCallback(() => setIsOpenAddBriefModal(true), []);
  const onCloseAddBriefModal = useCallback(() => setIsOpenAddBriefModal(false), []);

  const onOpenUpcomingDatesModal = useCallback(() => setIsOpenUpcomingDatesModal(true), []);
  const onCloseUpcomingDatesModal = useCallback(() => setIsOpenUpcomingDatesModal(false), []);

  const onOpenUploadResultsModal = useCallback((files: File[]) => {
    setUploadFiles(files);
    setIsOpenUploadResultsModal(true);
  }, []);
  const onCloseUploadResultsModal = useCallback(() => {
    setIsOpenUploadResultsModal(false);
    setUploadFiles([]);
  }, []);

  return (
    <div className={styles.root}>
      {/*<ContractDocumentCard isLoading={boardLoading} />*/}
      <ResultsCard loading={boardLoading} onUpload={onOpenUploadResultsModal}/>
      <BriefDocumentCard isLoading={boardLoading} onAddBriefClick={onOpenAddBriefModal} />
      {!isTablet && (
        <>
          <UpcomingDatesCard
            deliverables={deliverables}
            onOpenModal={onOpenUpcomingDatesModal}
            isLoading={upcomingLiveDatesDeliverablesLoading}
          />
          <UpcomingDatesModal deliverables={deliverables} isOpen={isOpenUpcomingDatesModal} onClose={onCloseUpcomingDatesModal} />
        </>
      )}
      <AddBriefModal isOpen={isOpenAddBriefModal} onClose={onCloseAddBriefModal} />
      <UploadResultsModal files={uploadFiles} onClose={onCloseUploadResultsModal} isOpen={isOpenUploadResultsModal}/>
    </div>
  );
};

export default BoardInfoSection;
