import React from 'react';
import styles from './IconMobileSearch.module.scss';
import { ReactComponent as IconCountFilters } from '../../assets/img/icons/countSearch.svg';
import SearchSVG from '../../expert/components/IconsSvg/SearchSVG';

const IconMobileSearch = ({ count, setShowFiltersMobile }) => {
  return (
    <div className={styles.iconSearch} onClick={() => setShowFiltersMobile()}>
      <SearchSVG />
      {count !== 0 && (
        <div className={styles.countSearch}>
          <IconCountFilters />
          <span>{count}</span>
        </div>
      )}
    </div>
  );
};

export default IconMobileSearch;
