import ServerError, { IServerErrorProps, ServerResponseErrorType } from './ServerError';
import DefaultError from './DefaultError';
import TypedServerError from './TypedServerError';
import CustomError from './CustomError';

export default class ErrorHandler {
  private readonly error: { message: string; response?: ServerResponseErrorType };

  constructor(error: Error) {
    this.error = error;
  }

  getError(): CustomError {
    if (this.error?.response) {
      if (this.error.response.data?.type) {
        return new TypedServerError(this.error as IServerErrorProps);
      }
      return new ServerError(this.error as IServerErrorProps);
    } else {
      return new DefaultError(this.error);
    }
  }

  getMessage() {
    return this.getError().getMessage();
  }
}
