import React, {ComponentProps, FC, MouseEventHandler, ReactElement, ReactNode, SVGProps, useMemo} from 'react';
import styles from './ButtonLightPurpleWhite.module.scss';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';
import cs from 'classnames';
import PlusSVG from '../../../expert/components/IconsSvg/PlusSVG';
import {ReactComponent as VisualsIcon} from '../../../assets/img/icons/visuals-icon.svg';
import {ReactComponent as DeleteIcon} from '../../../assets/img/icons/trash.svg';
import {ReactComponent as EditIcon} from '../../../assets/img/icons/edit_pen.svg';
import {ReactComponent as FeedbackIcon} from '../../../assets/img/icons/feedback-icon.svg';
import {ReactComponent as ViewIcon} from '../../../assets/img/icons/viewEye.svg';
import {ReactComponent as ReplaceIcon} from '../../../assets/img/icons/replace.svg';
import {ReactComponent as ShareIcon} from '../../../assets/img/icons/share-icon.svg';
import {ReactComponent as UploadIcon} from '../../../assets/img/icons/upload-purple-icon.svg';
import {ReactComponent as Upload2Icon} from '../../../assets/img/icons/share.svg';
import {ReactComponent as PlayIcon} from '../../../assets/img/icons/play_icon.svg';
import {ReactComponent as Upload3SVG} from '../../../assets/img/icons/upload.svg';
import {ReactComponent as Plus2SVG} from '../../../assets/img/icons/plusInCircle.svg';
import {ReactComponent as DownloadSVG} from '../../../assets/img/icons/download.svg';

type PropsType = {
  className?: string;
  children?: JSX.Element | ReactElement | ReactNode | string | null;
  disabled?: boolean;
  Icon?: Element | null | JSX.Element | (() => JSX.Element) | FC<{
    size?: number
  } & unknown> | FC<SVGProps<SVGSVGElement>>;
  presetIcon?: keyof typeof presetIcons | null;
  size?: 'large' | 'medium' | 'small';
  designVersion?: 'v1' | 'v2' | 'v3';
  onClick?: MouseEventHandler<HTMLButtonElement>;
} & Omit<ComponentProps<typeof ButtonCustom>, 'size'>;

const presetIcons = {
  plus: {
    icon: PlusSVG,
    style: styles.plusIcon,
  },
  image: {
    icon: VisualsIcon,
    style: styles.imageIcon,
  },
  deleteCart: {
    icon: DeleteIcon,
    style: styles.deleteCartIcon,
  },
  editPencil: {
    icon: EditIcon,
    style: styles.editPencilIcon,
  },
  feedback: {
    icon: FeedbackIcon,
    style: styles.feedbackIcon,
  },
  view: {
    icon: ViewIcon,
    style: styles.plusIcon,
  },
  replace: {
    icon: ReplaceIcon,
    style: styles.replaceIcon,
  },
  share: {
    icon: ShareIcon,
    style: styles.shareIcon,
  },
  upload: {
    icon: UploadIcon,
    style: styles.uploadIcon,
  },
  upload2: {
    icon: Upload2Icon,
    style: styles.upload2Icon,
  },
  play: {
    icon: PlayIcon,
    style: styles.playIcon,
  },
  upload3: {
    icon: Upload3SVG,
    style: styles.upload3Icon,
  },
  plus2: {
    icon: Plus2SVG,
    style: styles.plus2Icon,
  },
  download: {
    icon: DownloadSVG,
    style: styles.downloadIcon,
  }
};

const ButtonLightPurpleWhite: FC<PropsType> = ({
                                                 className,
                                                 children,
                                                 disabled,
                                                 Icon,
                                                 presetIcon = '',
                                                 size = 'large',
                                                 designVersion = 'v1',
                                                 onClick,
                                                 ...props
                                               }) => {
  const iconFromPreset = presetIcon ? presetIcons[presetIcon] : null;

  const IconComponent = useMemo(() => {
    if (Icon) return Icon;
    else if (iconFromPreset) return iconFromPreset.icon;
    return null;
  }, [Icon, presetIcon]);

  const handleClick = event => {
    if (!disabled && onClick) onClick(event);
  };

  return (
    <ButtonCustom
      className={cs(
        styles.root,
        styles[size],
        styles[designVersion],
        {[styles.squareFormat]: !children},
        {[styles.disabled]: disabled},
        !Icon && iconFromPreset ? iconFromPreset.style : null,
        className
      )}
      Icon={IconComponent}
      onClick={handleClick}
      {...props}
    >
      {children}
    </ButtonCustom>
  );
};

export default ButtonLightPurpleWhite;
