import {DeliverablesSortDTOType} from '../../models/entities/board/dto/DeliverablesSort';
import {IDeliverableDTO} from '../../models/entities/deliverable/dto/Deliverable';
import {OptionalObjectType} from '../../models/utils/OptionalObjectType';
import {IBoard} from '../../models/entities/board/Board';
import {IBoardCollaboratorDTO} from '../../models/entities/board/dto/BoardCollaborator';
import {BoardCollaboratorHydrator} from '../../models/entities/board/hydrators/BoardCollaboratorHydrator';
import {BoardInvitedCollaboratorHydrator} from '../../models/entities/board/hydrators/BoardInvitedCollaboratorHydrator';
import {IBoardInvitedCollaboratorDTO} from '../../models/entities/board/dto/BoardInvitedCollaborator';
import {DeliverableHydrator} from '../../models/entities/deliverable/hydrators/DeliverableHydrator';
import {ErrorOnDeliverablesCreationType} from '../../models/entities/board/ErrorOnDeliverablesCreation';
import {IDeliverable} from '../../models/entities/deliverable/Deliverable';
import {IBoardAssetDTO} from '../../models/entities/board/dto/BoardAsset';
import {BoardAssetHydrator} from '../../models/entities/board/hydrators/BoardAssetHydrator';
import {
  CONTENT_BOARD_ACTIONS_TYPES,
  ContentBoardActionsTypes,
  ContentBoardSyncThunkReturnType,
  ContentBoardThunkReturnType,
} from './types';
import {ICreateDeliverableFromBoardDTO} from '../../models/entities/deliverable/dto/CreateDeliverableFromBoard';
import * as contentApprovalServices from '../../services/contentApproval';
import {addResultAsset, AddResultAssetType} from '../../services/contentApproval';
import {User} from '../../models/permissions/users/User';
import {IUpdateBoardCollaboratorDTO} from '../../models/entities/board/dto/UpdateBoardCollaborator';
import {TOAST_NOTIFICATION_TYPES} from '../../shared/constants/toastNotificationsData';
import {mutateItemInList} from '../../pages/ContentApproval/DeliverableItem/helpers';
import notificationDataConfig from '../../shared/utils/notificationDataConfig';
import {createNotification} from '../toastNotificationActions/toastNotification.actions';
import {DeliverablesSortType} from '../../models/entities/board/DeliverablesSortType';
import {SharedRequestsProxyService} from '../../models/permissions/services/SharedRequestsService';
import {AxiosError} from 'axios';
import {IInviteBoardCollaboratorDTO} from '../../models/entities/board/dto/InviteBoardCollaborator';
import BoardHydrator from '../../models/entities/board/hydrators/BoardHydrator';
import {IBoardInvitedCollaborator} from '../../models/entities/board/BoardInvitedCollaborator';
import {InviteCollaboratorsFormikType} from '../../components/Modals/InviteCollaboratorsModal';
import {Page} from '../../models/permissions/pages/Page';
import {ContentBoardPermission} from '../../models/permissions/enum/ContentBoardPermission';
import {TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES} from '../../models/Notifications';
import {ISocketBoardDTO} from '../../models/entities/board/dto/SocketBoard';
import {normalizeBoardSocketsDto} from '../../models/entities/board/normalizers/normalizeBoardSocketsDto';
import ErrorHandler from '../../models/entities/error/ErrorHandler';
import TypedServerError from '../../models/entities/error/TypedServerError';
import CustomError from '../../models/entities/error/CustomError';
import {DeliverableOptionHydrator} from '../../models/entities/board/hydrators/DeliverableOptionHydrator';
import {ResultAssetHydrator} from '../../models/entities/asset/hydrators/ResultAssetHydrator';
import {IResultAsset} from "../../models/entities/asset/ResultAsset";

export const createDeliverables =
  (organizationId: string | number, boardId: string | number, data: ICreateDeliverableFromBoardDTO[]): ContentBoardThunkReturnType =>
    async dispatch => {
      try {
        dispatch({type: CONTENT_BOARD_ACTIONS_TYPES.CREATE_DELIVERABLES_REQUEST});
        await contentApprovalServices.createDeliverables(organizationId, +boardId, data);

        dispatch({type: CONTENT_BOARD_ACTIONS_TYPES.CREATE_DELIVERABLES_SUCCESS});

        const notification = notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.DELIVERABLES_CREATED, {});
        dispatch(createNotification(notification));
      } catch (e) {
        if (e && typeof e === 'object' && 'response' in e) {
          const axiosError = e as AxiosError;
          dispatch({
            type: CONTENT_BOARD_ACTIONS_TYPES.CREATE_DELIVERABLES_FAILURE,
            payload: axiosError.response?.data.errors.body as ErrorOnDeliverablesCreationType[],
          });

          const notification = notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.ERROR_ON_CREATE_DELIVERABLES, {});
          dispatch(createNotification(notification));
        }
      }
    };

export const clearContentBoardErrors = (): ContentBoardActionsTypes => ({
  type: CONTENT_BOARD_ACTIONS_TYPES.CLEAR_ERRORS,
});

export const clearContentBoard = (): ContentBoardActionsTypes => ({
  type: CONTENT_BOARD_ACTIONS_TYPES.CLEAR_OPENED_CONTENT_BOARD,
});

export const getContentBoard =
  (boardId: string | number): ContentBoardThunkReturnType =>
    async (dispatch, getState) => {
      const userModel = getState().auth.userModel;
      const getContentBoard = new SharedRequestsProxyService(userModel).getRequestFunction('getContentBoard');
      if (!getContentBoard) {
        return;
      }

      try {
        dispatch({type: CONTENT_BOARD_ACTIONS_TYPES.GET_CONTENT_BOARD_REQUEST});

        const response = await getContentBoard(+boardId);

        const contentBoard = new BoardHydrator(response.data[0]);

        dispatch({
          type: CONTENT_BOARD_ACTIONS_TYPES.GET_CONTENT_BOARD_SUCCESS,
          payload: contentBoard,
        });
      } catch (e) {
        if (e instanceof Error) {
          dispatch({type: CONTENT_BOARD_ACTIONS_TYPES.GET_CONTENT_BOARD_FAILURE, payload: e.message});
        }
      }
    };

export const getBoardCollaborators =
  (boardId: string | number): ContentBoardThunkReturnType =>
    async (dispatch, getState) => {
      const userModel: User = getState().auth.userModel;
      const getBoardCollaborators = new SharedRequestsProxyService(userModel).getRequestFunction('getBoardCollaborators');
      if (!getBoardCollaborators) {
        return;
      }

      try {
        dispatch({type: CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_COLLABORATORS_REQUEST});

        const params = {};
        const response: { data: IBoardCollaboratorDTO[] } = await getBoardCollaborators(+boardId, params);

        dispatch({
          type: CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_COLLABORATORS_SUCCESS,
          payload: response.data.map(collaborator => new BoardCollaboratorHydrator(collaborator)),
        });
      } catch (e) {
        if (e instanceof Error) {
          dispatch({type: CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_COLLABORATORS_FAILURE, payload: e.message});
        }
      }
    };

export const updateBoardCollaborators =
  (organizationId: string | number, boardId: string | number, params: IUpdateBoardCollaboratorDTO): ContentBoardThunkReturnType =>
    async dispatch => {
      await contentApprovalServices.updateBoardCollaborators(organizationId, boardId, params);
      dispatch(getBoardCollaborators(boardId));
    };

export const deleteBoardCollaborator =
  (organizationID: string | number, boardId: string | number, collaboratorOrganizationId: string | number): ContentBoardThunkReturnType =>
    async dispatch => {
      dispatch({type: CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_COLLABORATORS_REQUEST});
      contentApprovalServices.deleteBoardCollaborator(organizationID, boardId, collaboratorOrganizationId).then(response => {
        dispatch(getBoardCollaborators(boardId));
      });
    };

export const getInvitedBoardCollaborators =
  (boardId: string | number): ContentBoardThunkReturnType =>
    async (dispatch, getState) => {
      const userModel: User = getState().auth.userModel;
      const getBoardCollaborators = new SharedRequestsProxyService(userModel).getRequestFunction('getInvitedBoardCollaborators');
      if (!getBoardCollaborators) {
        return;
      }

      dispatch({type: CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_INVITED_COLLABORATORS_REQUEST});
      try {
        const response: { data: IBoardInvitedCollaboratorDTO[] } = await getBoardCollaborators(boardId);
        dispatch({
          type: CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_INVITED_COLLABORATORS_SUCCESS,
          payload: response.data.map(collaborator => new BoardInvitedCollaboratorHydrator(collaborator)),
        });
      } catch (e) {
        if (e instanceof Error) {
          dispatch({type: CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_INVITED_COLLABORATORS_FAILURE, payload: e.message});
        }
      }
    };

export const deleteInvitedBoardCollaborator =
  (organizationID: string | number, boardId: string | number, invitedId: string | number): ContentBoardThunkReturnType =>
    async dispatch => {
      dispatch({type: CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_INVITED_COLLABORATORS_REQUEST});
      contentApprovalServices.deleteInvitedBoardCollaborator(organizationID, boardId, invitedId).then(response => {
        dispatch(getInvitedBoardCollaborators(boardId));
      });
    };

export type InviteDataType = IBoardInvitedCollaborator | InviteCollaboratorsFormikType;

export const inviteBoardCollaborator =
  (organizationID: string | number, boardId: string | number, inviteData: InviteDataType): ContentBoardThunkReturnType =>
    async (dispatch, getState) => {
      const board = getState().contentBoard.openedBoard;
      const userId: string | number = getState().auth.user.id;

      const inviteDataDto: IInviteBoardCollaboratorDTO = {
        email: inviteData.email,
        organization_type: 'organizationType' in inviteData ? inviteData.organizationType.type : inviteData.new_organization_type,
        custom_message: inviteData.custom_message || null,
      };

      contentApprovalServices
        .inviteBoardCollaborator(organizationID, boardId, inviteDataDto)
        .then(inviteResp => {
          window.analytics.track('content_board_invite_sent', {
            category: 'Invite',
            label: board?.name,
            userId: userId,
            invite_id: inviteResp.data[0].id,
            content_board_id: board?.id,
            company_type: 'organizationType' in inviteData ? inviteData.organizationType.type : inviteData.new_organization_type,
            email: inviteData.email,
          });
          dispatch(getInvitedBoardCollaborators(boardId));
        })
        .catch(e => {
          if (e instanceof Error) {
            const error = e as AxiosError;

            const notification = notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.INVITE_COLLABORATOR_ERROR, {
              text: [error.response?.data.message],
            });

            dispatch(createNotification(notification));
          }
        });
    };

export const getBoardDeliverables =
  (
    boardId: string | number,
    page?: number,
    pageSize?: number,
    sort?: DeliverablesSortType,
    searchFilter: string = ''
  ): ContentBoardThunkReturnType =>
    async (dispatch, getState) => {
      const userModel: User = getState().auth.userModel;
      const getBoardDeliverables = new SharedRequestsProxyService(userModel).getRequestFunction('getBoardDeliverables');
      if (!getBoardDeliverables) {
        return;
      }

      try {
        dispatch({type: CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_DELIVERABLES_REQUEST});

        const params: DeliverablesSortDTOType = {};
        if (searchFilter) params.search_filter = searchFilter;
        if (page) params.page = page;
        if (pageSize) params.page_size = pageSize;
        if (sort?.order_by && sort?.order_type) {
          params.order_by = sort.order_by;
          params.order_type = sort.order_type;
        }

        const response: {
          data: IDeliverableDTO[];
          total_count: number;
          total_approved: number
        } = await getBoardDeliverables(
          +boardId,
          params
        );

        dispatch({
          type: CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_DELIVERABLES_SUCCESS,
          payload: response.data.map(deliverable => new DeliverableHydrator(deliverable)),
          totalCount: response.total_count,
          approvedCount: response.total_approved,
        });
      } catch (e) {
        if (e instanceof Error) {
          dispatch({type: CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_DELIVERABLES_FAILURE, payload: e.message});
        }
      }
    };

export const updateOpenedContentBoard =
  (socketBoardDTO: ISocketBoardDTO): ContentBoardSyncThunkReturnType =>
    (dispatch, getState) => {
      const oldBoard = getState().contentBoard.openedBoard;
      if (!oldBoard) return;

      dispatch({
        type: CONTENT_BOARD_ACTIONS_TYPES.UPDATE_OPENED_CONTENT_BOARD,
        payload: new BoardHydrator(normalizeBoardSocketsDto(oldBoard, socketBoardDTO)),
      });
    };

export const updateBoardDeliverableItem =
  (deliverable: IDeliverableDTO): ContentBoardThunkReturnType =>
    async (dispatch, getState) => {
      const deliverables: IDeliverable[] = getState().contentBoard.deliverables;
      if (deliverables.length) {
        const {isChanged, newList} = mutateItemInList(deliverables, deliverable) as {
          isChanged: boolean;
          newList: IDeliverable[];
        };
        if (isChanged) {
          dispatch({type: CONTENT_BOARD_ACTIONS_TYPES.UPDATE_BOARD_DELIVERABLE_ITEM, payload: newList});
        }
      }
    };

export const deleteDeliverableItem =
  (
    organizationId: number | string,
    boardId: number | string,
    deliverableId: number | string,
    page: number,
    pageSize: number,
    sort: DeliverablesSortType
  ): ContentBoardThunkReturnType =>
    async dispatch => {
      dispatch({type: CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_DELIVERABLES_REQUEST});
      try {
        await contentApprovalServices.deleteDeliverableItem(organizationId, boardId, deliverableId);
        dispatch(getBoardDeliverables(boardId, page, pageSize, sort));
        dispatch(createNotification(notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.DELETE_DELIVERABLE_ITEM, {})));
      } catch (e) {
        if (e instanceof Error) {
          dispatch({type: CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_DELIVERABLES_FAILURE, payload: e.message});
          dispatch(
            createNotification({
              type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.ERROR,
              title: 'Something went wrong. Please try deleting your deliverable again.',
              delay: 5000,
            })
          );
        }
      }
    };

export const duplicateDeliverableItem =
  (organizationId: string | number, boardId: string | number, deliverableId: string | number): ContentBoardThunkReturnType =>
    async dispatch => {
      dispatch({type: CONTENT_BOARD_ACTIONS_TYPES.DUPLICATE_DELIVERABLE_ITEM_REQUEST});
      try {
        const response: { data: IDeliverableDTO[] } = await contentApprovalServices.duplicateDeliverableItem(
          organizationId,
          boardId,
          deliverableId
        );
        const deliverableCopy = new DeliverableHydrator(response.data[0]);
        dispatch({
          type: CONTENT_BOARD_ACTIONS_TYPES.DUPLICATE_DELIVERABLE_ITEM_SUCCESS,
          payload: {deliverable: deliverableCopy, afterId: deliverableId},
        });
        dispatch(createNotification(notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.DUPLICATE_DELIVERABLE_ITEM, {})));
      } catch (e) {
        if (e instanceof Error) {
          dispatch({type: CONTENT_BOARD_ACTIONS_TYPES.DUPLICATE_DELIVERABLE_ITEM_FAILURE});
          dispatch(
            createNotification({
              type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.ERROR,
              title: 'Something went wrong. Please try duplicating your deliverable again.',
              delay: 5000,
            })
          );
        }
      }
    };

export const getBoardDeliverablesAssets =
  (boardId: number | string): ContentBoardThunkReturnType =>
    async (dispatch, getState) => {
      const userModel: User = getState().auth.userModel;
      const getBoardDeliverablesAssets = new SharedRequestsProxyService(userModel).getRequestFunction('getBoardDeliverablesAssets');
      if (!getBoardDeliverablesAssets) {
        return;
      }

      dispatch({type: CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_DELIVERABLES_ASSETS_REQUEST});
      try {
        const response: { data: IBoardAssetDTO[] } = await getBoardDeliverablesAssets(boardId);
        dispatch({
          type: CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_DELIVERABLES_ASSETS_SUCCESS,
          payload: response.data.map(asset => new BoardAssetHydrator(asset)),
        });
      } catch (e) {
        if (e instanceof Error) {
          dispatch({type: CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_DELIVERABLES_ASSETS_FAILURE, payload: e.message});
        }
      }
    };

export const getUpcomingLiveDate =
  (boardId: string | number): ContentBoardThunkReturnType =>
    async (dispatch, getState) => {
      const userModel = getState().auth.userModel;
      const getBoardDeliverables = new SharedRequestsProxyService(userModel).getRequestFunction('getBoardDeliverables');
      if (!getBoardDeliverables) return;

      try {
        dispatch({type: CONTENT_BOARD_ACTIONS_TYPES.GET_UPCOMING_LIVE_DATES_DELIVERABLES_REQUEST});

        const params = {upcoming_live_dates: true};

        const response: { data: IDeliverableDTO[] } = await getBoardDeliverables(+boardId, params);

        dispatch({
          type: CONTENT_BOARD_ACTIONS_TYPES.GET_UPCOMING_LIVE_DATES_DELIVERABLES_SUCCESS,
          payload: response.data.map(deliverable => new DeliverableHydrator(deliverable)),
        });
      } catch (e) {
        if (e instanceof Error) {
          dispatch({type: CONTENT_BOARD_ACTIONS_TYPES.GET_UPCOMING_LIVE_DATES_DELIVERABLES_FAILURE});
        }
      }
    };

export const patchContentBoard =
  (
    organizationId: string | number,
    boardId: string | number,
    data: OptionalObjectType<IBoard> | FormData,
    successNotificationType = TOAST_NOTIFICATION_TYPES.BOARD_CHANGES_SAVE_SUCCESS
  ): ContentBoardThunkReturnType =>
    async dispatch =>
      new Promise(async (resolve, reject) => {
        dispatch({type: CONTENT_BOARD_ACTIONS_TYPES.PATCH_OPENED_CONTENT_BOARD_REQUEST});

        try {
          await contentApprovalServices.updateContentBoard(organizationId, boardId, data).catch(error => {
            throw error;
          });

          dispatch({type: CONTENT_BOARD_ACTIONS_TYPES.PATCH_OPENED_CONTENT_BOARD_SUCCESS, loading: false});
          dispatch(getContentBoard(boardId));
          const notification = notificationDataConfig.getNotificationData(successNotificationType, {});
          dispatch(createNotification(notification));
          resolve();
        } catch (e) {
          const error = e instanceof CustomError ? e : new ErrorHandler(e as Error).getError();
          dispatch({type: CONTENT_BOARD_ACTIONS_TYPES.PATCH_OPENED_CONTENT_BOARD_FAILURE});
          const notification = notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.BOARD_CHANGES_SAVE_ERROR, {
            text: error.getMessage(),
          });
          if (!(error instanceof TypedServerError)) {
            dispatch(createNotification(notification));
          }
          reject(error);
        }
      });

export const updateBoardDocument =
  (
    organizationId: string | number,
    boardId: string | number,
    key: 'brief_file' | 'contract_file' | 'campaign_brief_item_id',
    value: null | File,
    successNotificationType?: TOAST_NOTIFICATION_TYPES
  ): ContentBoardThunkReturnType =>
    async dispatch =>
      new Promise((resolve, reject) => {
        const fetchData = new FormData();
        // @ts-ignore
        fetchData.append(key, value);

        dispatch(patchContentBoard(organizationId, boardId, fetchData, successNotificationType)).then(resolve).catch(reject);
      });

export const pushBoardDeliverables = (deliverables: IDeliverableDTO[]): ContentBoardActionsTypes => ({
  type: CONTENT_BOARD_ACTIONS_TYPES.PUSH_DELIVERABLES_TO_BOARD,
  payload: deliverables.map(deliverable => new DeliverableHydrator(deliverable)),
});

export const setContentBoardPageModel = (page: Page<ContentBoardPermission>): ContentBoardActionsTypes => ({
  type: CONTENT_BOARD_ACTIONS_TYPES.SET_CONTENT_BOARD_PAGE_MODEL,
  payload: page,
});
