import { UserPermission } from '../enum/UserPermission';
import { Guest } from './Guest';

export class AuthorizedGuest extends Guest {
  permissions: UserPermission[] = [
    UserPermission.CAN_SEE_HEADER_DASHBOARD_BUTTON,
    UserPermission.CAN_SEE_HEADER_DESKTOP_FULL_LOGO,
    UserPermission.CAN_GET_BOARD_DATA,
    UserPermission.CAN_GET_BOARD_COLLABORATORS_DATA,
    UserPermission.CAN_GET_BOARD_DELIVERABLES_DATA,
    UserPermission.CAN_GET_ALL_BOARD_DELIVERABLES_ASSETS,
    UserPermission.CAN_GET_DELIVERABLE_ITEM_DATA,
    UserPermission.CAN_GET_DELIVERABLE_ITEM_SECTION_DATA,
    UserPermission.CAN_SEE_BOARD_INFO_BANNER,
    UserPermission.CAN_SEE_PURPLE_BANNER,
  ];
}
