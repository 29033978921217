import React, { FC } from 'react';
import styles from './UpcomingDatesList.module.scss';
import UpcomingDateItem from '../UpcomingDateItem';
import { IDeliverable } from '../../../../../../../../../models/entities/deliverable/Deliverable';
import UpcomingDatesListLoadingState from './UpcomingDatesListLoadingState';

type PropsType = {
  deliverables?: IDeliverable[] | null;
  isLoading?: boolean;
};

const UpcomingDatesList: FC<PropsType> = props => {
  return (
    <ul className={styles.root}>
      {props.isLoading ? <UpcomingDatesListLoadingState /> : null}
      {props?.deliverables?.map(deliverable => (
        <UpcomingDateItem key={deliverable.id} deliverable={deliverable} />
      ))}
    </ul>
  );
};

export default UpcomingDatesList;
