import React, { FC, memo, useEffect } from 'react';
import styles from '../SubTabOtherTerms.module.scss';
import CheckboxButton from '../../../../../../../../CampaignBrief/components/CheckboxButton';
import cs from 'classnames';
import { PLATFORMS } from '../../../../../../../../../models/enums/Platforms';
import CheckBox from '../../../../../../../../CampaignBrief/components/CheckBox';
import FacebookSVG from '../../../../../../../../../components/IconsSvg/FacebookSVG';
import InstagramSVG from '../../../../../../../../../components/IconsSvg/InstagramSVG';
import TikTokSVG from '../../../../../../../../../components/IconsSvg/TikTokSVG';
import YoutubeSVG from '../../../../../../../../../components/IconsSvg/YoutubeSVG';
import BlogPostSVG from '../../../../../../../../../components/IconsSvg/BlogPostSVG';
import Dropdown from '../../../../../../../../../components/FormControls/Dropdown';
import Option from '../../../../../../../../CampaignBrief/components/Option';
import { FormikProps } from 'formik';
import { areEqualFormikState } from '../../../../../../../../../shared/utils/typescriptHelpers';

const categories = [
  {
    key: 1,
    value: '1 month',
  },
  {
    key: 3,
    value: '3 months',
  },
  {
    key: 6,
    value: '6 months',
  },
  {
    key: 9,
    value: '9 months',
  },
  {
    key: 12,
    value: '1 year',
  },
  {
    key: 99,
    value: '1+ year',
  },
];

type PropsType = {
  formik: FormikProps<any>;
};

const propsAreEqual: Parameters<typeof memo<FC<PropsType>>>[1] = (prevProps, nextProps) => {
  return areEqualFormikState(['repostingPlatform', 'showSocial', 'repostingDuration'], prevProps.formik, nextProps.formik);
};

const SubTabRepurposeRights = memo(({ formik }: PropsType) => {
  useEffect(() => {
    formik.setFieldValue('showSocial', !!formik.values.repostingPlatform.length);
  }, [formik.values.repostingPlatform.length]);

  useEffect(() => {
    if (!formik.values.showSocial) {
      formik.setFieldValue('repostingDuration', 1);
    }
  }, [formik.values.showSocial]);

  const handleChangePlatform = value => {
    if (formik.values.repostingPlatform.includes(value)) {
      const res = formik.values.repostingPlatform.filter(item => item != value);
      formik.setFieldValue('repostingPlatform', [...res]);
    } else {
      formik.setFieldValue('repostingPlatform', [...formik.values.repostingPlatform, value]);
    }
  };

  const enablePlatforms = () => {
    formik.setFieldValue('repostingDuration', 1);
    formik.setFieldValue('showSocial', true);
  };

  const disablePlatforms = () => {
    formik.setFieldValue('repostingDuration', 1);
    formik.setFieldValue('repostingPlatform', []);
    formik.setFieldValue('showSocial', false);
  };

  return (
    <div className={styles.repurposeRightsBlock}>
      <h3 className={styles.title}>Do you require usage/repurpose rights?</h3>
      <p style={{ marginBottom: 0 }}>This would allow you to reshare the content on to your own brand social channels.</p>

      <div className={styles.checkboxButtonBlock}>
        {/* @ts-ignore */}
        <CheckboxButton onClick={enablePlatforms} isActive={formik.values.showSocial} item={'Yes'} className={styles.checkboxButton} />
        {/* @ts-ignore */}
        <CheckboxButton onClick={disablePlatforms} isActive={!formik.values.showSocial} item={'No'} className={styles.checkboxButton} />
      </div>

      {formik.values.showSocial && (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
            <h3 className={styles.subTitle}>Choose the platform you need the repurpose rights for</h3>
            <div className={styles.socialSectionBlock}>
              <div className={cs(styles.socialSection, { [styles.socialHeadingError]: !!formik.errors.repostingPlatform })}>
                <div className={styles.socialHeading} onClick={() => handleChangePlatform(PLATFORMS.FACEBOOK)}>
                  {/* @ts-ignore */}
                  <CheckBox isActive={formik.values.repostingPlatform.includes(PLATFORMS.FACEBOOK)} />
                  <div className={styles.socialIconWrapper}>
                    <FacebookSVG />
                  </div>
                  <h3>Facebook</h3>
                </div>
              </div>

              <div className={cs(styles.socialSection, { [styles.socialHeadingError]: !!formik.errors.repostingPlatform })}>
                <div className={styles.socialHeading} onClick={() => handleChangePlatform(PLATFORMS.INSTAGRAM)}>
                  {/* @ts-ignore */}
                  <CheckBox isActive={formik.values.repostingPlatform.includes(PLATFORMS.INSTAGRAM)} />
                  <div className={styles.socialIconWrapper}>
                    <InstagramSVG />
                  </div>
                  <h3>Instagram</h3>
                </div>
              </div>

              <div className={cs(styles.socialSection, { [styles.socialHeadingError]: !!formik.errors.repostingPlatform })}>
                <div className={styles.socialHeading} onClick={() => handleChangePlatform(PLATFORMS.TIKTOK)}>
                  {/* @ts-ignore */}
                  <CheckBox isActive={formik.values.repostingPlatform.includes(PLATFORMS.TIKTOK)} />

                  <div className={styles.socialIconWrapper}>
                    <TikTokSVG />
                  </div>
                  <h3>TikTok</h3>
                </div>
              </div>

              <div className={cs(styles.socialSection, { [styles.socialHeadingError]: !!formik.errors.repostingPlatform })}>
                <div className={styles.socialHeading} onClick={() => handleChangePlatform(PLATFORMS.YOUTUBE)}>
                  {/* @ts-ignore */}
                  <CheckBox isActive={formik.values.repostingPlatform.includes(PLATFORMS.YOUTUBE)} />
                  <div className={styles.socialIconWrapper}>
                    <YoutubeSVG />
                  </div>
                  <h3>YouTube</h3>
                </div>
              </div>

              <div className={cs(styles.socialSection, { [styles.socialHeadingError]: !!formik.errors.repostingPlatform })}>
                <div className={styles.socialHeading} onClick={() => handleChangePlatform(PLATFORMS.BLOG_POST)}>
                  {/* @ts-ignore */}
                  <CheckBox isActive={formik.values.repostingPlatform.includes(PLATFORMS.BLOG_POST)} />
                  <div className={styles.socialIconWrapper}>
                    <BlogPostSVG />
                  </div>
                  <h3>Blog Post</h3>
                </div>
              </div>
            </div>
          </div>

          {/* @ts-ignore */}
          {formik.errors.repostingPlatform && <div className={styles.socialError}>{formik.errors.repostingPlatform}</div>}

          <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
            <h3 className={styles.subTitle}>For how long do you need the content repurpose rights for?</h3>
            <div style={{ width: 140 }}>
              {/* @ts-ignore */}
              <Dropdown
                className={styles.dropdown}
                items={categories}
                getId={i => i.key}
                getDisplayValue={i => i.value}
                renderItem={value => <Option value={value} />}
                selectedItem={categories.find(item => item.key == formik.values.repostingDuration)}
                onSelect={i => formik.setFieldValue('repostingDuration', i.key)}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}, propsAreEqual);

export default SubTabRepurposeRights;
