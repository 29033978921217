import { FlowsTypes, IFlow } from '../Flow';
import { DeliverablePermission } from '../../enum/DeliverablePermission';

export class DeliverableSharedFlow implements IFlow<DeliverablePermission> {
  type: FlowsTypes = FlowsTypes.SHARED_FLOW;
  generalPermissions: DeliverablePermission[] = [DeliverablePermission.ONLY_CC_CAN_VIEW, DeliverablePermission.ONLY_BA_CAN_VIEW];
  getPermissions(): DeliverablePermission[] | null {
    return this.generalPermissions;
  }
}
