import React, {FC} from 'react';
import styles from './LoadingState.module.scss';
import LoadingContainer from "../../../../components/LoadingContainer";


const LoadingState: FC = () => {
  return (
    <div className={styles.root}>
      <div className={styles.card}>
        <div className={styles.top}>
          <div className={styles.topLeft}>
            <LoadingContainer width={24} height={24} borderRadius={'100%'}/>
            <LoadingContainer width={150} height={24} borderRadius={'10px'}/>
          </div>
        </div>
        <div className={styles.grid}>
          <LoadingContainer className={styles.gridItem} borderRadius={'5px'}/>
          <LoadingContainer className={styles.gridItem} borderRadius={'5px'}/>
          <LoadingContainer className={styles.gridItem} borderRadius={'5px'}/>
          <LoadingContainer className={styles.gridItem} borderRadius={'5px'}/>
        </div>
      </div>

      <div className={styles.card}>
        <div className={styles.top}>
          <div className={styles.topLeft}>
            <LoadingContainer width={24} height={24} borderRadius={'100%'}/>
            <LoadingContainer width={150} height={24} borderRadius={'10px'}/>
          </div>
        </div>
        <div className={styles.grid}>
          <LoadingContainer className={styles.gridItem} borderRadius={'5px'}/>
          <LoadingContainer className={styles.gridItem} borderRadius={'5px'}/>
          <LoadingContainer className={styles.gridItem} borderRadius={'5px'}/>
          <LoadingContainer className={styles.gridItem} borderRadius={'5px'}/>
        </div>
      </div>

      <div className={styles.card}>
        <div className={styles.top}>
          <div className={styles.topLeft}>
            <LoadingContainer width={24} height={24} borderRadius={'100%'}/>
            <LoadingContainer width={150} height={24} borderRadius={'10px'}/>
          </div>
        </div>
        <div className={styles.grid}>
          <LoadingContainer className={styles.gridItem} borderRadius={'5px'}/>
          <LoadingContainer className={styles.gridItem} borderRadius={'5px'}/>
          <LoadingContainer className={styles.gridItem} borderRadius={'5px'}/>
          <LoadingContainer className={styles.gridItem} borderRadius={'5px'}/>
        </div>
      </div>
    </div>
  )
};

export default LoadingState;
