import React, { FC } from 'react';
import styles from './AddDeliverablesModalFooter.module.scss';
import cs from 'classnames';

type AddDeliverableModalFooterProps = {
  onClear: (...unknown) => void;
  onSubmit: (...unknown) => void;
  canSubmit: boolean;
};

const AddDeliverableModalFooter: FC<AddDeliverableModalFooterProps> = ({ onClear, onSubmit, canSubmit }) => {
  return (
    <div className={styles.root}>
      <div className={cs(styles.button, styles.cancelButton)} onClick={onClear}>
        Cancel
      </div>
      <div
        className={cs(styles.button, styles.submitButton, { [styles.activeSubmitButton]: canSubmit })}
        onClick={canSubmit ? onSubmit : () => {}}
      >
        Add Deliverables
      </div>
    </div>
  );
};

export default AddDeliverableModalFooter;
