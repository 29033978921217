import React, { useEffect, useState } from 'react';
import * as releasesService from '../../../services/releases';
import { useSelector } from 'react-redux';

const useReleases = () => {
  const [allReleases, setAllReleases] = useState(null);
  const [currentRelease, setCurrentRelease] = useState(null);
  const [updates, setUpdates] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const currentOrganizationID = useSelector(state => state.auth.currentOrganization?.organization_id);

  useEffect(() => {
    if (!currentOrganizationID) return;
    getReleasesData(currentOrganizationID);
  }, [currentOrganizationID]);

  const getReleasesData = async () => {
    setIsLoading(true);
    try {
      const res = await releasesService.getAllReleases(currentOrganizationID);
      setAllReleases(res.data);
      if (res.data.length) {
        const lastRelease = res.data.reduce((maxRelease, release) => {
          if (!maxRelease || maxRelease.id < release.id) {
            maxRelease = release;
          }
          return maxRelease;
        }, null);
        await setReleaseAndUpdates(lastRelease);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setError(e);
    }
  };

  const setReleaseAndUpdates = async release => {
    if (!currentOrganizationID) return;
    setIsLoading(true);
    try {
      let settingRelease = release;
      if (allReleases) {
        settingRelease = allReleases.find(rel => rel.id === release.id);
      }
      const updates = await releasesService.getReleaseUpdates(currentOrganizationID, release.id);
      setCurrentRelease(settingRelease);
      setUpdates(updates.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setError(e);
    }
  };

  return { allReleases, currentRelease, updates, isLoading, error, setReleaseAndUpdates };
};

export default useReleases;
