import React, { FC, PropsWithChildren, ReactNode, useCallback, useMemo } from 'react';
import styles from './SectionVisualsWrapper.module.scss';
import useInfoBannerState from '../../../../hooks/useInfoBannerState';
import CounterV1 from '../../../../../../../components/CounterV1';
import LocalBanner from '../../../../../../../components/LocalBanner';
import AddVisualsButton from '../../../buttons/AddVisualsButton';
import HeaderLoadingWrapper from './components/HeaderLoadingWrapper';
import ListLoadingWrapper from './components/ListLoadingWrapper';
import cs from 'classnames';
import DownloadAllButton from '../../../../../components/DownloadAllButton';
import SectionSubTitle from '../../ContentSection/components/SectionSubTitle';
import { useMediaQuery } from 'react-responsive/src';
import { useDispatch } from 'react-redux';
import { createNotification } from '../../../../../../../actions/toastNotificationActions/toastNotification.actions';
import notificationDataConfig from '../../../../../../../shared/utils/notificationDataConfig';
import { DELIVERABLE_SECTION_TYPES } from '../../../../../../../models/enums/DeliverableSectionTypes';
import { TOAST_NOTIFICATION_TYPES } from '../../../../../../../shared/constants/toastNotificationsData';
import { INPUT_FILE_ALLOWED_TYPE } from '../../../../../../../shared/constants/tsConstants';
import { ConceptFlowType, ContentFlowType } from '../../../../hooks/useDeliverableState';
import { IDownloadSettings } from '../../types';

type PropsType = {
  assetsFlow: ConceptFlowType['conceptAssets'] | ContentFlowType['contentAssets'];
  isPageLoading: boolean;
  isPageUpdateLoading: boolean;
  isEditingMode: boolean;
  maxVisualsLength?: number;
  subTitle?: string;
  questionTooltipText?: string;
  infoBannerText?: string;
  fileTypes?: INPUT_FILE_ALLOWED_TYPE[];
  isViewMore: boolean;
  setIsShowAllVisual?: (value: boolean) => void;
  isShowAllVisual: boolean;
  downloadButton?: IDownloadSettings;
  sectionSubTitle?: string;
  isConcept?: boolean;
} & PropsWithChildren;

const SectionVisualsWrapper: FC<PropsType> = ({
  children,
  assetsFlow,
  isPageLoading,
  isPageUpdateLoading,
  isEditingMode,
  maxVisualsLength = 10,
  subTitle,
  questionTooltipText,
  infoBannerText,
  fileTypes = [INPUT_FILE_ALLOWED_TYPE.IMAGE_INPUT_ALLOWED, INPUT_FILE_ALLOWED_TYPE.VIDEO_INPUT_ALLOWED],
  isViewMore,
  setIsShowAllVisual,
  isShowAllVisual,
  downloadButton,
  sectionSubTitle,
  isConcept = false,
}) => {
  const dispatch = useDispatch();
  const { current, onCreate } = assetsFlow;

  const isDisplayAddButton = current.length < maxVisualsLength && isEditingMode;
  const isDisplayHeader = isEditingMode || isPageUpdateLoading;
  const { isInfoBannerOpen, onClose } = useInfoBannerState(current, isEditingMode);

  const isDesktopLandscape = useMediaQuery({
    query: '(min-width: 992px) and (orientation: landscape)',
  });

  const currentChildren: JSX.Element | ReactNode[] = useMemo(() => {
    if (!children || typeof children !== 'object' || !('props' in children)) return null;
    return isConcept ? children?.props?.children : children?.props?.children?.props?.children;
  }, [isConcept, children]);

  const withVisuals: boolean = useMemo(
    () => (Array.isArray(currentChildren) && currentChildren.length > 0) || (!Array.isArray(currentChildren) && !!currentChildren),
    [currentChildren]
  );

  const renderVisuals = useCallback(() => {
    if (isDesktopLandscape || isEditingMode || isShowAllVisual || (Array.isArray(currentChildren) && currentChildren?.length <= 3))
      return children;

    if (Array.isArray(currentChildren) && currentChildren.length > 3) {
      return (
        <>
          {currentChildren.slice(0, 3)}
          <div
            className={cs(styles.otherVisual, { [styles.isShowMore]: isViewMore })}
            onClick={isDisplayHeader ? () => {} : () => setIsShowAllVisual && setIsShowAllVisual(true)}
          >
            +{currentChildren.length - 3} Visual
          </div>
        </>
      );
    }
  }, [withVisuals, isEditingMode, isShowAllVisual, isDisplayHeader, isViewMore, currentChildren]);

  const onResultActions = useMemo(() => {
    return {
      success: () => {
        dispatch(createNotification(notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.DOWNLOAD_ALL_ASSETS_SUCCESS, {})));
      },
      partiallyFailed: () => {
        dispatch(
          createNotification(notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.DOWNLOAD_ALL_ASSETS_PARTIALLY_FAILED, {}))
        );
      },
      allFailed: () => {
        dispatch(createNotification(notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.DOWNLOAD_ALL_ASSETS_FAILED, {})));
      },
    };
  }, []);

  return (
    <div className={cs(styles.root, { [styles.isShowMore]: isViewMore && !isDisplayHeader })}>
      <HeaderLoadingWrapper isLoading={isPageLoading}>
        {isDisplayHeader && (
          <div className={styles.header}>
            <div className={styles.headerGroup}>
              <div className={styles.title}>{subTitle}</div>
              {/* {questionTooltipText?.length && <QuestionTooltip text={questionTooltipText} />}*/}
            </div>
            <CounterV1 currentCount={current.length} totalCount={maxVisualsLength} />
          </div>
        )}
        {withVisuals && !isEditingMode && !isPageUpdateLoading && (sectionSubTitle || downloadButton?.isDisplay) && (
          <div className={styles.header}>
            {sectionSubTitle && <SectionSubTitle subTitle={sectionSubTitle} />}
            <div />
            {isDesktopLandscape && downloadButton?.isDisplay && (
              <DownloadAllButton
                designVersion="v2"
                className={styles.downloadBtn}
                assets={downloadButton.assets || []}
                onResult={onResultActions}
              />
            )}
          </div>
        )}
      </HeaderLoadingWrapper>

      {!isPageLoading && infoBannerText?.length && (
        <LocalBanner type="info" isOpen={isInfoBannerOpen} onClose={onClose}>
          {infoBannerText}
        </LocalBanner>
      )}
      <ListLoadingWrapper isLoading={isPageLoading}>
        {withVisuals && (
          <div
            className={cs(
              styles.assetsBlock,
              { [styles.viewLess]: !isDesktopLandscape && !isViewMore },
              { [styles.assetsBlockConcept]: isConcept }
            )}
          >
            {renderVisuals()}
          </div>
        )}

        {isDisplayAddButton && (
          <AddVisualsButton
            assetType={isConcept ? DELIVERABLE_SECTION_TYPES.CONCEPT : DELIVERABLE_SECTION_TYPES.CONTENT}
            maxVisualsLength={maxVisualsLength}
            size={isDesktopLandscape ? 'small' : 'large'}
            fileTypes={fileTypes}
            onAddAsset={onCreate}
            currentVisualsCount={current.length}
          />
        )}
      </ListLoadingWrapper>
    </div>
  );
};

export default SectionVisualsWrapper;
