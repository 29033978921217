import React, { useRef, useState } from 'react';
import styles from './Avatar.module.scss';
import cs from 'classnames';
import { ReactComponent as TriangleIcon } from '../../assets/img/icons/triangle.svg';
import UserDropdownMenu from './components/UserDropdownMenu';
import UserColoredIcon from '../UserColoredIcon';

const Avatar = ({ user, withArrow }) => {
  const avatarRef = useRef();
  const [isActive, setIsActive] = useState(false);
  const handleClose = (e, dropdownRef) => {
    const avatarCurrent = avatarRef.current;
    const dropdownCurrent = dropdownRef.current;
    if (!avatarCurrent || !dropdownCurrent) {
      return;
    }
    if (!avatarCurrent.contains(e.target) && !dropdownCurrent.contains(e.target)) {
      setIsActive(false);
    }
  };

  return (
    <>
      <div className={styles.avatarContainer} ref={avatarRef}>
        <div className={cs(styles.avatarButton, { [styles.active]: isActive })} onClick={() => setIsActive(!isActive)}>
          <div className={cs(styles.avatar)}>
            {user.photo_location ? (
              <img src={user.photo_location} alt="avatar" />
            ) : (
              <UserColoredIcon text={user?.first_name?.slice(0, 1).toUpperCase() + user?.last_name?.slice(0, 1).toUpperCase()} />
            )}
          </div>
          {withArrow && (
            <div className={styles.arrowBtn}>
              <TriangleIcon className={cs(styles.arrow, { [styles.transform]: isActive })} />
            </div>
          )}
        </div>

        {isActive && <UserDropdownMenu user={user} onClose={handleClose} />}
      </div>
    </>
  );
};

export default Avatar;
