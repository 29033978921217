import { PREFERRED_ADD_DECLARATION_TYPES } from '../enums/AddDeclarationsTypes';
import { IBoardBriefFormikValues } from '../BoardBriefFormikValues';
import dayjs from 'dayjs';
import { IUpdateBoardBrief } from '../UpdateBoardBrief';
import { IUpdateBoardBriefDTO } from '../dto/UpdateBoardBriefDTO';
import { IUpdateBoardBriefItemDTO } from '../dto/UpdateBoardBriefItemDTO';

export default class UpdateBoardBriefHydrator implements IUpdateBoardBrief {
  brief: IUpdateBoardBriefDTO;
  briefItem: IUpdateBoardBriefItemDTO;

  constructor(briefFormikValues: IBoardBriefFormikValues) {
    this.brief = {
      // brand_name: briefFormikValues.brandName || null,
      brand_description: briefFormikValues.brandBackground || null,
      brand_provides_product: briefFormikValues.showBrandProvidesProduct ? briefFormikValues.brandProvidesProduct : null,
      notes: briefFormikValues.notes || null,
    };
    // if (briefFormikValues.brandLogo.file) {
    //   this.brief['brand_logo'] = briefFormikValues.brandLogo.file;
    // }
    this.briefItem = {
      name: briefFormikValues.itemName,
      key_message: briefFormikValues.messaging || null,
      objective: JSON.stringify(briefFormikValues.campaignObjective),
      date_start: briefFormikValues.date_start ? dayjs(briefFormikValues.date_start).format('YYYY-MM-DD') : null,
      date_end: briefFormikValues.date_end ? dayjs(briefFormikValues.date_end).format('YYYY-MM-DD') : null,
      live_date_start: briefFormikValues.date_post ? dayjs(briefFormikValues.date_post).format('YYYY-MM-DD') : null,
      target_audience: JSON.stringify({
        location: {
          country: briefFormikValues.country,
          city: briefFormikValues.city,
        },
        gender: briefFormikValues.gender ? [briefFormikValues.gender] : [],
        age: {
          min: briefFormikValues.ageMin, // Number 13-65, 65 = 65+
          max: briefFormikValues.ageMax, // Number 13-65
        },
        interests_and_behaviours: briefFormikValues.interests,
      }),
      exclusivity: briefFormikValues.exclusivity,
      reposting_platform: briefFormikValues.repostingPlatform?.length ? JSON.stringify(briefFormikValues.repostingPlatform) : null,
      reposting_duration: briefFormikValues.repostingPlatform?.length ? briefFormikValues.repostingDuration : null,
      hashtags: JSON.stringify(briefFormikValues.hashtags),
      social_tagging: JSON.stringify(briefFormikValues.socialTagging),
      total_budget: briefFormikValues.total_budget || null,
      boosting_budget: briefFormikValues.boosting_budget || null,
      boosting_duration: briefFormikValues.boosting_duration || null,
      boosting_manager: !!briefFormikValues.boosting_manager?.length ? JSON.stringify(briefFormikValues.boosting_manager) : null,
      total_budget_currency: briefFormikValues.total_budget_currency,
      boosting_budget_currency: briefFormikValues.boosting_budget_currency,
      preferred_ad_declaration: briefFormikValues.adInCaption
        ? PREFERRED_ADD_DECLARATION_TYPES.AD_IN_CAPTION
        : PREFERRED_ADD_DECLARATION_TYPES.PAID_PARTNERSHIP_TOOL,
      other_infos: briefFormikValues.other_infos || null,
    };
  }
}
