import React from 'react';
import styles from './CellLoader.module.scss';
import LoadingContainer from '../../../LoadingContainer';

const CellLoader = () => {
  return (
    <div className={styles.root}>
      <LoadingContainer width="34px" height="34px" borderRadius="5px" />
      <div className={styles.col}>
        <LoadingContainer width="299px" height="14px" borderRadius="5px" />
        <LoadingContainer width="132px" height="14px" borderRadius="5px" />
      </div>
    </div>
  );
};

export default CellLoader;
