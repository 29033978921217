import React, {FC} from 'react';
import styles from './ResultDropdownButton.module.scss';
import ThreeDotsButton from "../../../../../components/ThreeDotsButton";
import useResultAssetGeneralActions from "../../../hooks/useResultAssetGeneralActions";
import {useResultsActionsOptions} from "./useResultsActionsOptions";
import {IResultAsset} from "../../../../../models/entities/asset/ResultAsset";

type PropsType = {
  onUpload: (files: File[]) => void;
  assets: IResultAsset[];
};

const ResultDropdownButton: FC<PropsType> = props => {
  const {onDownload, onDelete, confirmDeleteModal} = useResultAssetGeneralActions(props.assets);
  const options = useResultsActionsOptions(onDownload, onDelete, props.onUpload);

  return (
    <>
      <ThreeDotsButton
        className={styles.actionsButton}
        dropdownClassName={styles.actionDropdown}
        options={options}
      />
      {confirmDeleteModal}
    </>
  )
};

export default ResultDropdownButton;
