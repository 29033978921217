import React, { createContext } from 'react';
import { PublicationContext } from 'centrifuge';

export type OrganizationSocketsContextValueType = {
  createRoomHandler: ((ctx: PublicationContext) => void) | null;
  deliverableConvertedHandler: ((ctx: PublicationContext) => void) | null;
};

export const OrganizationContextDefaultValue: OrganizationSocketsContextValueType = {
  createRoomHandler: null,
  deliverableConvertedHandler: null,
};

export const OrganizationSocketsContext = createContext(OrganizationContextDefaultValue);
