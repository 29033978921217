import React from 'react';
import styles from './CardOrganizations.module.scss';
import ButtonCustom from '../../../../expert/components/Buttons/ButtonCustom';
import PlanProgressBar from '../../../../components/PlanProgressBar';
import { ReactComponent as IconUsers } from '../../../../assets/img/icons/users.svg';
import { ReactComponent as IconWarning } from '../../../../assets/img/icons/triangle_warning.svg';
import cs from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as PurpleStar } from '../../../../assets/img/icons/purple_star.svg';
import { useHistory } from 'react-router';
import { useMediaQuery } from 'react-responsive/src';
import { MEMBER_ROLE_IN_ORGANIZATION_TYPES } from '../../../../models/enums/MemberRoleInOrganizationTypes';

const CardOrganizations = ({ organization, nameIndustry, plans }) => {
  const dispatch = useDispatch();
  const stripe = useSelector(state => state.auth.currentOrganization?.stripe);
  const plan = useSelector(state => state.auth.currentOrganizationPlan);
  const subscriptionStatus = stripe?.subscription_status;
  const isOwner = organization.role === MEMBER_ROLE_IN_ORGANIZATION_TYPES.OWNER;
  const history = useHistory();
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

  const showWarning =
    organization.pricing_plan_id &&
    (subscriptionStatus === 'canceled' ||
      subscriptionStatus === 'past_due' ||
      subscriptionStatus === 'incomplete' ||
      subscriptionStatus === 'incomplete_expired');

  const isValidPlan = organization?.has_valid_subscription && !showWarning;

  const warningText = () => {
    if (!subscriptionStatus && plan?.name) {
      return plan?.name;
    }

    switch (subscriptionStatus) {
      case 'canceled':
        return isTablet ? 'Canceled' : 'Subscription canceled';
      case 'past_due':
        return 'Renew payment has failed';
      case 'incomplete':
        return 'Initial payment attempt has failed';
      case 'incomplete_expired':
        return 'First invoice is not paid within 23 hours';
      default:
        return isTablet ? 'Canceled' : 'Subscription canceled';
    }
  };

  const handleSwitch = org => {
    if (org) {
      localStorage.removeItem('OTTERFISH_CURRENT_ORGANIZATION');
      localStorage.setItem('OTTERFISH_CURRENT_ORGANIZATION', JSON.stringify(org));
      dispatch({
        type: 'SET_CURRENT_ORGANIZATION',
        payload: org,
      });
      history.push('/organization/details');
    }
  };

  return (
    <div className={styles.card}>
      <div className={styles.firstBlock}>
        <div className={styles.starBlock}>
          {isOwner && <PurpleStar />}
          <div className={styles.name}>{organization.organization_name}</div>
        </div>
        <div className={styles.infoOrg}>
          {nameIndustry && (
            <div className={styles.industryBlock}>
              <div className={styles.brand}>
                <p>{nameIndustry}</p>
              </div>
            </div>
          )}
          <div className={cs(styles.plan, { [styles.planWarning]: !isValidPlan && !plan?.is_free })}>
            {isValidPlan || plan?.is_free ? <div className={styles.circle}></div> : <IconWarning />}
            <p className={styles.planText}>
              {isValidPlan || plan?.is_free ? plan?.name : warningText()}
            </p>
          </div>
        </div>
      </div>
      {/* <div className={styles.centerBlock}>*/}
      {/*  <IconUsers />*/}
      {/*  <div className={styles.users}>{organization.organization_member_count}</div>*/}
      {/*  <div className={styles.orgProgressBar}>*/}
      {/*    <PlanProgressBar current={organization.organization_member_count} of={organization.organization_member_count} />*/}
      {/*  </div>*/}
      {/* </div>*/}
      <div className={styles.btnBlock}>
        <ButtonCustom outline onClick={() => handleSwitch(organization)}>
          Manage
        </ButtonCustom>
      </div>
    </div>
  );
};

export default CardOrganizations;
