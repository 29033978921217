import {
  IStateToastNotification,
  TOAST_NOTIFICATION_ACTION_TYPES,
  ToastNotificationsActionsType,
} from '../actions/toastNotificationActions/types';

const testNotifications = [
  {
    type: 'success',
    title: 'Add an action',
    text:
      'Add an action toast notification for using everywhere in the system Add an action toast notification' +
      ' for using everywhere in the system Add an action toast notification for using everywhere in the system',
    dateString: 'data',
    primaryButtonData: {
      text: 'primary Button ',
      action: callback => {
        callback();
      },
    },
    secondaryButtonData: {
      text: ' secondary Button ',
      action: callback => {
        callback();
      },
    },
  },
  {
    type: 'error',
    title: 'Error',
    text: 'Add an action toast notification for using everywhere in the system Add an action toast notification',
    primaryButtonData: {
      text: 'primary Button ',
      action: callback => {
        callback();
      },
    },
    secondaryButtonData: {
      text: ' secondary Button ',
      action: callback => {
        callback();
      },
    },
  },
];

export type ToastNotificationReducerState = {
  notification: IStateToastNotification[];
};

const initialState: ToastNotificationReducerState = {
  notification: [
    // ...testNotifications
  ],
};

export default (
  state: ToastNotificationReducerState = initialState,
  { type, payload }: ToastNotificationsActionsType
): ToastNotificationReducerState => {
  switch (type) {
    case TOAST_NOTIFICATION_ACTION_TYPES.CREATE_NOTIFICATIONS: {
      return {
        ...state,
        notification: [payload, ...state.notification],
      };
    }
    case TOAST_NOTIFICATION_ACTION_TYPES.DELETE_NOTIFICATIONS: {
      return {
        ...state,
        notification: state.notification.filter(n => n.id !== payload.id),
      };
    }
    default: {
      return state;
    }
  }
};
