import React from 'react';
import styles from './CustomText.module.scss';
import cs from 'classnames';
import Text from './formatters/index';

const CustomText = ({ render, className }) => {
  return <div className={cs(styles.root, className)}>{render(Text)}</div>;
};

export default CustomText;
