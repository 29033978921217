import React, { FC } from 'react';
import styles from '../ContentHeader.module.scss';
import LoadingContainer from '../../../../../../components/LoadingContainer';
import { useMediaQuery } from 'react-responsive';
import UserPlusSVG from '../../../../../../components/IconsSvg/UserPlusSVG';
import LoadingButton from '../../../../../../components/LoadingButton';
import loadingStyles from './HeaderLoadingState.module.scss';
import { ReactComponent as ShareIcon } from '../../../../../../assets/img/icons/share-icon.svg';
import { OrganizationPermission } from '../../../../../../models/permissions/enum/OrganizationPermission';
import { useAppSelector } from '../../../../../../shared/hooks/reduxHooks';

const HeaderLoadingState: FC = () => {
  const currentOrgModel = useAppSelector<any>(state => state.auth.currentOrganizationModel);
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div className={styles.root}>
      <div className={styles.headerInfo}>
        <LoadingContainer borderRadius={'5px'} className={styles.brandImage} width={isTablet ? 50 : 83} height={isTablet ? 50 : 83} />
        <LoadingContainer
          height={22}
          width={isTablet ? '100%' : 350}
          animationDelay={`1s`}
          borderRadius={'5px'}
          className={styles.boardNameWrapper}
        />
        <LoadingContainer
          height={12}
          margin={'10px 0 0'}
          width={100}
          animationDelay={`1s`}
          borderRadius={'5px'}
          className={styles.boardText}
        />
      </div>
      {
        <div className={styles.headerActions}>
          {currentOrgModel?.hasAccess(OrganizationPermission.CAN_SHARE_CONTENT_BOARD) && (
            <LoadingButton className={loadingStyles.collaboratorButton} Icon={ShareIcon}>
              Share
            </LoadingButton>
          )}
          <div className={loadingStyles.collaboratorsWrapper}>
            <div className={loadingStyles.collaboratorSquaresWrapper}>
              <LoadingContainer
                className={loadingStyles.collaboratorSquare}
                margin={'0 0 0 -6px'}
                width={34}
                height={34}
                borderRadius={'3px'}
                minWidth={34}
              />
              <LoadingContainer
                className={loadingStyles.collaboratorSquare}
                margin={'0 0 0 -6px'}
                width={34}
                height={34}
                borderRadius={'3px'}
                minWidth={34}
              />
              <LoadingContainer
                className={loadingStyles.collaboratorSquare}
                margin={'0 0 0 -6px'}
                width={34}
                height={34}
                borderRadius={'3px'}
                minWidth={34}
              />
            </div>
            <LoadingButton className={loadingStyles.collaboratorButton} Icon={UserPlusSVG}></LoadingButton>
          </div>
          <LoadingContainer height={34} width={'100%'} borderRadius={'5px'} animationDelay={`1.5s`} className={styles.boardStatus} />
        </div>
      }
    </div>
  );
};

export default HeaderLoadingState;
