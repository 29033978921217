import React, {FC, ReactNode} from 'react';
import styles from './DeliverableCard.module.scss';
import DeliverablePlatform from "../../../../components/Cells/DeliverableCell/DeliverablePlatform";
import {PLATFORMS} from "../../../../models/enums/Platforms";
import {PLACEMENTS} from "../../../../models/enums/Placements";
import {getFormattedPlacement} from "../../../../shared/utils/helpers";
import {ReactComponent as EditIcon} from '../../../../assets/img/icons/edit_pen.svg'

type PropsType = {
  platform: PLATFORMS
  placement: PLACEMENTS;
  deliverableName: string;
  content?: ReactNode;
};

const DeliverableCard: FC<PropsType> = props => {
  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <div className={styles.topLeft}>
          <DeliverablePlatform platform={props.platform} customText={getFormattedPlacement(props.placement)} className={styles.topLeftText}/>
          <p className={styles.topLeftText}>
            {props.deliverableName}
          </p>
        </div>
        {/*<EditIcon className={styles.editIcon} />*/}
      </div>
      {props.content}
    </div>
  )
};

export default DeliverableCard;
