import React, { FC, SVGAttributes } from 'react';

type PropsType = {
  fill?: SVGAttributes<SVGPathElement>['fill'];
  size?: number;
};

const PlusSVG: FC<PropsType> = ({ fill }): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none" viewBox="0 0 28 28">
      <path fill={fill || '#fff'} d="M22 15.143h-6.857V22h-2.286v-6.857H6v-2.286h6.857V6h2.286v6.857H22v2.286z" />
    </svg>
  );
};

export default PlusSVG;
