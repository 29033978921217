import { IResultAsset } from '../ResultAsset';
import { IResultAssetDTO } from '../dto/ResultAsset';
import { PLACEMENTS } from '../../../enums/Placements';
import { PLATFORMS } from '../../../enums/Platforms';

export class ResultAssetHydrator implements IResultAsset {
  content_board_id: number;
  duration: null | number;
  extension: string;
  file_id: number;
  filename: string;
  id: number | string;
  location: string;
  mime_type: string;
  preview_location: string;
  size: string;
  thumbnail_location: string;
  deliverable_id: number | string;
  deliverable_name: string;
  deliverable_placement: PLACEMENTS;
  deliverable_platform: PLATFORMS;
  created_at: string;

  constructor(dto: IResultAssetDTO) {
    this.content_board_id = dto.content_board_id;
    this.duration = dto.duration;
    this.extension = dto.extension;
    this.file_id = dto.file_id;
    this.filename = dto.filename;
    this.id = dto.id;
    this.location = dto.location;
    this.mime_type = dto.mime_type;
    this.preview_location = dto.preview_location;
    this.size = dto.size;
    this.thumbnail_location = dto.thumbnail_location;
    this.deliverable_id = dto.content_board_deliverable_id;
    this.deliverable_name = dto.content_board_deliverable_name;
    this.deliverable_placement = dto.content_board_deliverable_placement;
    this.deliverable_platform = dto.content_board_deliverable_platform;
    this.created_at = dto.created_at;
  }
}
