import React, { FC, useMemo } from 'react';
import styles from './CardInfoIcon.module.scss';
import { ReactComponent as InfoIcon } from '../../../../../../../../assets/img/icons/info-icon.svg';
import { useAppSelector } from '../../../../../../../../shared/hooks/reduxHooks';
import useBoardCreator from '../../../../../../hooks/useBoardCreator';
import { COLLABORATOR_ROLE_TYPES } from '../../../../../../../../models/permissions/collaborator/CollaboratorRole';
import useTooltip, { TOOLTIP_POSITIONS } from '../../../../../../../../shared/hooks/fixedTooltip/useTooltip';
import { ContentBoardPermission } from '../../../../../../../../models/permissions/enum/ContentBoardPermission';
import { DOCUMENT_CARD_VIEW_TYPES } from '../../../types/DocumentCardViewTypes';

type PropsType = {
  documentType: DOCUMENT_CARD_VIEW_TYPES;
};

const CardInfoIcon: FC<PropsType> = props => {
  const pageModel = useAppSelector(state => state.contentBoard?.pageModel || null);
  const isBoardCreator = useBoardCreator();

  const tooltipText = useMemo(() => {
    const currentDocumentTypeText = props.documentType === DOCUMENT_CARD_VIEW_TYPES.CONTRACT ? 'contract' : 'Brief';

    if (isBoardCreator) {
      return `Only you and the creator you invite
              will be able to view the ${currentDocumentTypeText} even if you
              invite another member to the board.
              This ensures that the confidential information is kept
              between you and your content creator`;
    } else if (pageModel?.roleCan(ContentBoardPermission.CAN_SEE_FILE_CARD_INFO_TOOLTIP)) {
      switch (pageModel?.role.type) {
        case COLLABORATOR_ROLE_TYPES.CONTENT_CREATOR:
        case COLLABORATOR_ROLE_TYPES.BRAND:
        case COLLABORATOR_ROLE_TYPES.TALENT_MANAGER:
        case COLLABORATOR_ROLE_TYPES.AGENCY:
          return 'Only you and the collaborator working directly with you \n' + `will be able to view the ${currentDocumentTypeText}`;
      }
    }
  }, [isBoardCreator, pageModel]);

  const { toolTip, onEnter, onLeave } = useTooltip(TOOLTIP_POSITIONS.TOP, {
    text: tooltipText,
    className: styles.tooltip,
  });

  const isDisplayTooltip = useMemo(() => {
    return isBoardCreator || pageModel?.roleCan(ContentBoardPermission.CAN_SEE_FILE_CARD_INFO_TOOLTIP);
  }, [isBoardCreator, pageModel]);

  return isDisplayTooltip ? (
    <div className={styles.infoIcon} onClick={e => e.stopPropagation()}>
      <InfoIcon />
      {toolTip}
      <div
        className={styles.tooltipHandlersWrapper}
        onMouseEnter={e => {
          e.stopPropagation();
          onEnter(e);
        }}
        onMouseLeave={onLeave}
      />
    </div>
  ) : null;
};

export default CardInfoIcon;
