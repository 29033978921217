import React, { useState } from 'react';
import styles from './LatestNewsModal.module.scss';
import cs from 'classnames';
import { Modal as ReactModal } from 'react-responsive-modal';
import { CloseIcon } from '../../../expert/components/IconsSvg/ReactIcons/ReactIcons';
import useReleases from './useReleases';
import ReleasesPage from './components/ReleasesPage';
import ReleaseNewsPage from './components/ReleaseNewsPage';

const LatestNews = props => {
  const { allReleases, currentRelease, updates, isLoading, setReleaseAndUpdates } = useReleases();
  const [isReleaseOpen, setIsReleaseOpen] = useState(false);

  const onReleaseClick = release => {
    setReleaseAndUpdates(release);
    setIsReleaseOpen(true);
  };

  const handleTellUsClick = () => {
    props.handleOpenTellUs();
    props.onClose();
  };

  return (
    <div className={styles.latestNewsContainer}>
      {!isReleaseOpen ? (
        <ReleasesPage releases={allReleases} onReleaseClick={onReleaseClick} isLoading={isLoading} handleTellUsClick={handleTellUsClick} />
      ) : (
        <ReleaseNewsPage release={currentRelease} updates={updates} isLoading={isLoading} goBack={() => setIsReleaseOpen(false)} />
      )}
    </div>
  );
};

const LatestNewsModal = props => {
  const modalStyles = {
    overlay: {
      backdropFilter: 'blur(3px)',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      padding: '0',
    },
    modal: {
      position: 'absolute',
      top: '0px',
      right: '0px',
      background: '#fff',
      padding: 0,
      borderRadius: '20px 0 0 20px',
      maxWidth: props.maxWidth || '500px',
      width: '100%',
      height: '100vh',
      boxShadow: ' 0 0 20px rgba(0, 0, 0, 0.1)',
    },
    closeButton: {
      display: 'none',
    },
  };
  return (
    <ReactModal center open={props.open} onClose={props.onClose} styles={modalStyles}>
      <div className={cs(styles.root, props.className)}>
        <button onClick={props.onClose} className={styles.close} disabled={props.loading}>
          <CloseIcon size={30} />
        </button>
        <LatestNews {...props} />
      </div>
    </ReactModal>
  );
};

export default LatestNewsModal;
