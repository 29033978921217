import { User } from '../../models/permissions/users/User';
import { SharedRequestsProxyService } from '../../models/permissions/services/SharedRequestsService';
import { DeliverableOptionHydrator } from '../../models/entities/board/hydrators/DeliverableOptionHydrator';
import { ResultAssetHydrator } from '../../models/entities/asset/hydrators/ResultAssetHydrator';
import { AddResultAssetType } from '../../services/contentApproval';
import { IResultAsset } from '../../models/entities/asset/ResultAsset';
import notificationDataConfig from '../../shared/utils/notificationDataConfig';
import { TOAST_NOTIFICATION_TYPES } from '../../shared/constants/toastNotificationsData';
import { createNotification } from '../toastNotificationActions/toastNotification.actions';
import { RESULTS_ACTIONS_TYPES, ResultsActionsTypes, ResultsThunkReturnType } from './types';
import { IUpdateResultAssetDTO } from '../../models/entities/asset/dto/updateResultAsset';

export const getDeliverablesOptions =
  (boardId: string | number): ResultsThunkReturnType =>
  async (dispatch, getState) => {
    const userModel: User = getState().auth.userModel;
    const getBoardDeliverableOptions = new SharedRequestsProxyService(userModel).getRequestFunction('getBoardDeliverableOptions');
    if (!getBoardDeliverableOptions) return;
    try {
      dispatch({ type: RESULTS_ACTIONS_TYPES.GET_DELIVERABLES_OPTIONS_REQUEST });
      const result = await getBoardDeliverableOptions(boardId);
      const options = result.data.map(option => new DeliverableOptionHydrator(option));
      dispatch({ type: RESULTS_ACTIONS_TYPES.GET_DELIVERABLES_OPTIONS_SUCCESS, payload: options });
    } catch (e) {
      if (e instanceof Error) {
        dispatch({ type: RESULTS_ACTIONS_TYPES.GET_DELIVERABLES_OPTIONS_FAILURE });
      }
    }
  };

export const getResultAssets =
  (boardId: string | number, params?: { page_size?: number }): ResultsThunkReturnType =>
  async (dispatch, getState) => {
    const userModel: User = getState().auth.userModel;
    const getBoardResultAssets = new SharedRequestsProxyService(userModel).getRequestFunction('getBoardResultAssets');
    if (!getBoardResultAssets) return;
    try {
      dispatch({ type: RESULTS_ACTIONS_TYPES.GET_RESULT_ASSETS_REQUEST });
      const result = await getBoardResultAssets(boardId, params);
      const assets = result.data.map(asset => new ResultAssetHydrator(asset));
      dispatch({ type: RESULTS_ACTIONS_TYPES.GET_RESULT_ASSETS_SUCCESS, payload: assets });
    } catch (e) {
      if (e instanceof Error) {
        dispatch({ type: RESULTS_ACTIONS_TYPES.GET_RESULT_ASSETS_FAILURE });
      }
    }
  };

export const deleteAllResultAssets =
  (boardId: string | number): ResultsThunkReturnType =>
  async (dispatch, getState) => {
    const userModel: User = getState().auth.userModel;
    const deleteAllResultAssets = new SharedRequestsProxyService(userModel).getRequestFunction('deleteAllResultAssets');
    if (!deleteAllResultAssets) return;

    const assets = getState().results.resultAssets;
    try {
      dispatch({ type: RESULTS_ACTIONS_TYPES.DELETE_ALL_RESULT_ASSETS_REQUEST });
      await deleteAllResultAssets(boardId);
      dispatch({ type: RESULTS_ACTIONS_TYPES.DELETE_ALL_RESULT_ASSETS_SUCCESS });
    } catch (e) {
      if (e instanceof Error) {
        dispatch({ type: RESULTS_ACTIONS_TYPES.DELETE_ALL_RESULT_ASSETS_FAILURE, payload: assets });
      }
    }
  };

export const uploadResultAssets =
  (
    boardId: string | number,
    data: AddResultAssetType[]
  ): ResultsThunkReturnType<{
    failed: File[];
    assets: { asset: IResultAsset; file: File }[];
  }> =>
  async (dispatch, getState): Promise<{ failed: File[]; assets: { asset: IResultAsset; file: File }[] }> => {
    const userModel: User = getState().auth.userModel;
    const addResultAsset = new SharedRequestsProxyService(userModel).getRequestFunction('addResultAsset');
    if (!addResultAsset) return { failed: data.map(({ files }) => files), assets: [] };
    dispatch({ type: RESULTS_ACTIONS_TYPES.UPLOAD_RESULT_ASSETS_REQUEST });

    const promiseArr = data.map(item => addResultAsset(boardId, item));

    return Promise.allSettled(promiseArr).then(resArr => {
      const errorFiles: File[] = [];
      const assetsDataArr: { asset: IResultAsset; file: File }[] = [];
      const assetsArr: IResultAsset[] = [];

      const getErrorNotification = (message: string) =>
        notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.FILE_TYPE_ERROR_WITH_CUSTOM_MESSAGE, { text: [message] });

      const getSuccessNotification = (filename: string) =>
        notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.RESULT_ASSET_UPLOAD_SUCCESS, { text: [filename] });

      for (let i = 0; i < resArr.length; i++) {
        const res = resArr[i];
        if (res.status !== 'rejected') {
          const newAsset = new ResultAssetHydrator(res.value[0]);
          assetsDataArr.push({ file: data[i].files, asset: newAsset });
          assetsArr.push(newAsset);
          setTimeout(() => dispatch(createNotification(getSuccessNotification(data[i].files.name))), i * 150);

          continue;
        }
        errorFiles.push(data[i].files);
        setTimeout(() => dispatch(createNotification(getErrorNotification(res.reason?.response?.data?.message))), i * 150);
      }
      dispatch({ type: RESULTS_ACTIONS_TYPES.UPLOAD_RESULT_ASSETS_SUCCESS, payload: assetsArr });

      return { failed: errorFiles, assets: assetsDataArr };
    });
  };

export const updateResultAsset =
  (boardId: string | number, assetId: string | number, data: IUpdateResultAssetDTO): ResultsThunkReturnType<void | IResultAsset> =>
  async (dispatch, getState): Promise<void | IResultAsset> => {
    const userModel: User = getState().auth.userModel;
    const patchResultAsset = new SharedRequestsProxyService(userModel).getRequestFunction('patchResultAsset');
    if (!patchResultAsset) return;
    dispatch({ type: RESULTS_ACTIONS_TYPES.PATCH_RESULT_ASSET_REQUEST });

    try {
      const result = await patchResultAsset(boardId, assetId, data);
      const newAsset = new ResultAssetHydrator(result[0]);
      dispatch({ type: RESULTS_ACTIONS_TYPES.PATCH_RESULT_ASSET_SUCCESS, payload: newAsset });
      return newAsset;
    } catch (e) {
      if (e instanceof Error) {
        dispatch({ type: RESULTS_ACTIONS_TYPES.PATCH_RESULT_ASSET_FAILURE });
      }
    }
  };

export const requestResult =
  (boardId: string | number): ResultsThunkReturnType =>
  async (dispatch, getState): Promise<void> => {
    const userModel: User = getState().auth.userModel;
    const requestResults = new SharedRequestsProxyService(userModel).getRequestFunction('requestResults');
    if (!requestResults) return;
    dispatch({ type: RESULTS_ACTIONS_TYPES.REQUEST_RESULTS_REQUEST });

    try {
      await requestResults(boardId);
      dispatch({ type: RESULTS_ACTIONS_TYPES.REQUEST_RESULTS_SUCCESS });
    } catch (e) {
      if (e instanceof Error) {
        dispatch({ type: RESULTS_ACTIONS_TYPES.REQUEST_RESULTS_FAILURE });
      }
    }
  };

export const deleteResultAsset =
  (boardId: string | number, asset: IResultAsset): ResultsThunkReturnType =>
  async (dispatch, getState): Promise<void> => {
    const userModel: User = getState().auth.userModel;
    const deleteResult = new SharedRequestsProxyService(userModel).getRequestFunction('deleteResultAsset');

    if (!deleteResult) return;
    dispatch({ type: RESULTS_ACTIONS_TYPES.DELETE_RESULT_ASSET_REQUEST, payload: asset });

    try {
      await deleteResult(boardId, asset.id);
      dispatch({ type: RESULTS_ACTIONS_TYPES.DELETE_RESULT_ASSET_SUCCESS });
    } catch (e) {
      if (e instanceof Error) {
        dispatch({ type: RESULTS_ACTIONS_TYPES.DELETE_RESULT_ASSET_FAILURE, payload: asset });
      }
    }
  };

export const clearResultsState = (): ResultsActionsTypes => ({ type: RESULTS_ACTIONS_TYPES.CLEAR_RESULTS_STATE });
