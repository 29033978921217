import * as contentApprovalServices from '../../services/contentApproval';
import { ContentApprovalFiltersType } from '../../services/contentApproval';
import * as boardBriefServices from '../../services/BoardBriefService';
import { mutateItemInList } from '../../pages/ContentApproval/DeliverableItem/helpers';
import BoardHydrator from '../../models/entities/board/hydrators/BoardHydrator';
import notificationDataConfig from '../../shared/utils/notificationDataConfig';
import { TOAST_NOTIFICATION_TYPES } from '../../shared/constants/toastNotificationsData';
import { createNotification } from '../toastNotificationActions/toastNotification.actions';
import { getContentBoard } from '../ContentBoardActions/contentBoard.actions';
import ErrorHandler from '../../models/entities/error/ErrorHandler';
import {
  CONTENT_APPROVAL_ACTION_TYPES,
  ContentApprovalActionTypes,
  ContentApprovalSyncThunkReturnType,
  ContentApprovalThunkReturnType,
} from './types';
import { OptionalObjectType } from '../../models/utils/OptionalObjectType';
import { IBoardDTO } from '../../models/entities/board/dto/Board';
import { ICreateBoardDTO } from '../../models/entities/board/dto/CreateBoard';
import { IInviteBoardCollaboratorDTO } from '../../models/entities/board/dto/InviteBoardCollaborator';
import { ICreateDeliverableFromBoardDTO } from '../../models/entities/deliverable/dto/CreateDeliverableFromBoard';
import { ICreateBoardBrief } from '../../models/entities/boardBrief/CreateBoardBrief';
import { ICreateBoardBriefAsset } from '../../models/entities/asset/CreateBoardBriefAsset';
import { ICampaignDTO } from '../../models/entities/boardBrief/dto/Campaign';
import { IBriefItemDTO } from '../../models/entities/boardBrief/dto/BriefItem';
import { IBoardListItem } from '../../models/entities/board/BoardListItem';
import BoardListItemHydrator from '../../models/entities/board/hydrators/BoardListItemHydrator';
import { IBoardListItemDTO } from '../../models/entities/board/dto/BoardListItem';

export const setContentApprovalTab = (switcher: 'boards' | 'archived'): ContentApprovalActionTypes => ({
  type: CONTENT_APPROVAL_ACTION_TYPES.SET_CONTENT_APPROVAL_TAB_SWITCHER,
  payload: switcher,
});

export const getContentBoards =
  (organizationId: string | number, params: ContentApprovalFiltersType = {}): ContentApprovalSyncThunkReturnType =>
  (dispatch, getState) => {
    dispatch({ type: CONTENT_APPROVAL_ACTION_TYPES.GET_CONTENT_BOARDS_REQUEST });

    const boardsParams = params.is_archived ? { is_archived: false } : { ...params, is_archived: false };
    const archivedParams = params.is_archived ? { ...params, is_archived: true } : { is_archived: true };

    const promiseArr = [
      contentApprovalServices.getContentBoards(organizationId, boardsParams),
      contentApprovalServices.getContentBoards(organizationId, archivedParams),
    ];

    Promise.allSettled(promiseArr).then(results => {
      if (
        results[0].status === 'rejected' ||
        results[1].status === 'rejected' ||
        results[0].value instanceof Error ||
        results[1].value instanceof Error
      ) {
        dispatch({ type: CONTENT_APPROVAL_ACTION_TYPES.GET_CONTENT_BOARDS_FAILURE });
        return;
      }

      const boards = results[0].value;
      const archived = results[1].value;

      const isSearchFiltersEmpty = !params.search_filter && !params.status && !params.approver_id && !params.date_start && !params.date_end;

      if (!boards.data.length && !archived.data.length && isSearchFiltersEmpty) {
        dispatch({ type: CONTENT_APPROVAL_ACTION_TYPES.SET_IS_ALL_BOARD_TYPES_EMPTY, payload: true });
      } else if (isSearchFiltersEmpty) {
        dispatch({ type: CONTENT_APPROVAL_ACTION_TYPES.SET_IS_ALL_BOARD_TYPES_EMPTY, payload: false });
      }

      let currentBoards: IBoardListItem[];
      if (params.is_archived) {
        currentBoards = archived.data.map(board => new BoardListItemHydrator(board));
        if (getState().contentApproval.contentApprovalTabSwitcher === 'boards') {
          dispatch(setContentApprovalTab('archived'));
        }
      } else {
        currentBoards = boards.data.map(board => new BoardListItemHydrator(board));
      }

      dispatch({
        type: CONTENT_APPROVAL_ACTION_TYPES.GET_CONTENT_BOARDS_SUCCESS,
        payload: currentBoards,
        totalCount: boards.total_count,
        archivedTotalCount: archived.total_count,
      });
    });
  };

export const updateContentBoard =
  (
    organizationId: string | number,
    boardId: string | number,
    data: OptionalObjectType<IBoardDTO> | FormData
  ): ContentApprovalThunkReturnType<true | void> =>
  async dispatch => {
    dispatch({ type: CONTENT_APPROVAL_ACTION_TYPES.GET_CONTENT_BOARDS_REQUEST });
    try {
      await contentApprovalServices.updateContentBoard(organizationId, boardId, data);
      await dispatch(getContentBoard(boardId));
      dispatch({ type: CONTENT_APPROVAL_ACTION_TYPES.GET_CONTENT_BOARDS_REQUEST, loading: false });
      return true;
    } catch (e) {
      dispatch({ type: CONTENT_APPROVAL_ACTION_TYPES.GET_CONTENT_BOARDS_FAILURE });
    }
  };

export const archiveContentBoard =
  (organizationId: string | number, boardId: string | number): ContentApprovalThunkReturnType<true | void> =>
  async dispatch => {
    dispatch({ type: CONTENT_APPROVAL_ACTION_TYPES.GET_CONTENT_BOARDS_REQUEST });
    try {
      await contentApprovalServices.archiveBoard(organizationId, boardId);
      dispatch({ type: CONTENT_APPROVAL_ACTION_TYPES.GET_CONTENT_BOARDS_REQUEST, loading: false });
      return true;
    } catch (e) {
      dispatch({ type: CONTENT_APPROVAL_ACTION_TYPES.GET_CONTENT_BOARDS_FAILURE });
    }
  };

export const unzipContentBoard =
  (organizationId: string | number, boardId: string | number): ContentApprovalThunkReturnType<true | void> =>
  async dispatch => {
    dispatch({ type: CONTENT_APPROVAL_ACTION_TYPES.GET_CONTENT_BOARDS_REQUEST });
    try {
      await contentApprovalServices.unzipBoard(organizationId, boardId);
      dispatch({ type: CONTENT_APPROVAL_ACTION_TYPES.GET_CONTENT_BOARDS_REQUEST, loading: false });
      return true;
    } catch (e) {
      dispatch({ type: CONTENT_APPROVAL_ACTION_TYPES.GET_CONTENT_BOARDS_FAILURE });
    }
  };

export type CreateBoardDataType = {
  boardData: ICreateBoardDTO;
  inviteData?: IInviteBoardCollaboratorDTO;
  deliverables: ICreateDeliverableFromBoardDTO[];
};

export type CreateBriefDataType =
  | {
      briefData: ICreateBoardBrief;
      visualElements: ICreateBoardBriefAsset[];
    }
  | undefined;

export const createBoard =
  (
    organizationID: string | number,
    createBoardData: CreateBoardDataType,
    createBriefData: CreateBriefDataType
  ): ContentApprovalThunkReturnType =>
  dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: CONTENT_APPROVAL_ACTION_TYPES.CREATE_BOARD_REQUEST });

        const createBoard = (campaignId?: string | number, briefId?: number) => {
          if (briefId) {
            createBoardData.boardData.campaign_brief_item_id = briefId;
          }

          contentApprovalServices
            .createBoard(organizationID, createBoardData.boardData)
            .then(res => {
              if (!res) return;
              const boardId = res.data[0].id;
              const boardName = res.data[0].name;

              window.analytics.track('create_board', {
                category: 'content board',
                label: boardName,
                userId: res.data[0].creator_id,
                company_type: createBoardData.boardData.initiator_organization_type,
                creator_brief_id: null,
                campaign_brief_id: null,
                content_board_id: res.data[0].id,
                source: 'stand_alone',
                status: 'in progress',
              });

              if (boardId) {
                const requestCount = (createBoardData.inviteData ? 1 : 0) + +!!createBoardData.deliverables.length;
                let responseCount = 0;

                const checkResponse = (inviteResp?: Error | { data: { id: string | number }[] }, trackEvent?: string) => {
                  if (trackEvent) {
                    window.analytics.track('content_board_invite_sent', {
                      category: 'Invite',
                      label: boardName,
                      userId: res.data[0].creator_id,
                      invite_id: inviteResp instanceof Error ? '' : inviteResp?.data?.[0].id,
                      content_board_id: res.data[0].id,
                      company_type: createBoardData?.inviteData?.organization_type,
                      email: createBoardData?.inviteData?.email,
                    });
                  }
                  if (requestCount) {
                    responseCount++;
                  }
                  if (requestCount === responseCount) {
                    dispatch({ type: CONTENT_APPROVAL_ACTION_TYPES.CREATE_BOARD_SUCCESS });
                    // @ts-ignore
                    resolve(res.data[0]);
                  }
                };

                if (createBoardData.inviteData) {
                  contentApprovalServices
                    .inviteBoardCollaborator(organizationID, boardId, createBoardData.inviteData)
                    .then(inviteResp => checkResponse(inviteResp, 'invite'))
                    .catch(checkResponse);
                }

                if (createBoardData.deliverables.length) {
                  contentApprovalServices
                    .createDeliverables(organizationID, +boardId, createBoardData.deliverables)
                    .then(checkResponse)
                    .catch(checkResponse);
                }

                if (requestCount === 0) {
                  checkResponse();
                }
              }
            })
            .catch(e => {
              if (campaignId && briefId) {
                boardBriefServices.deleteBoardBrief(organizationID, campaignId, briefId);
              }
              dispatch({ type: CONTENT_APPROVAL_ACTION_TYPES.CREATE_BOARD_FAILURE });
              reject(e);
            });
        };

        if (createBriefData) {
          const createBrief = async (): Promise<{ briefItemData: ICampaignDTO; addedItem: IBriefItemDTO }> =>
            new Promise(async (resolve, reject) => {
              boardBriefServices
                .createBoardBrief(organizationID, createBriefData.briefData)
                .then(async response => {
                  const briefItemData = response.data[0].campaign_brief;
                  const addedItem = response.data[0].campaign_brief_item;
                  if (briefItemData.id) {
                    if (createBriefData.visualElements.length) {
                      await boardBriefServices
                        .addBoardBriefVisualAssets(organizationID, briefItemData.id, createBriefData.visualElements)
                        .catch(e => {
                          boardBriefServices.deleteBoardBrief(organizationID, briefItemData.id, addedItem.id);
                          dispatch({ type: CONTENT_APPROVAL_ACTION_TYPES.CREATE_BOARD_FAILURE });
                          reject(e);
                        });
                    }
                  }
                  resolve({ briefItemData, addedItem });
                })
                .catch(e => {
                  dispatch({ type: CONTENT_APPROVAL_ACTION_TYPES.CREATE_BOARD_FAILURE });
                  reject(e);
                });
            });

          createBrief()
            .then(res => createBoard(res.briefItemData.id, res.addedItem.id))
            .catch(reject);
        } else {
          createBoard();
        }
      } catch (e) {
        if (e instanceof Error) {
          dispatch({ type: CONTENT_APPROVAL_ACTION_TYPES.CREATE_BOARD_FAILURE });
          reject(new ErrorHandler(e).getError());
        }
      }
    });
  };

export const updateContentApprovalListBoard =
  (contentBoard: IBoardListItemDTO): ContentApprovalSyncThunkReturnType =>
  (dispatch, getState) => {
    const boards = getState().contentApproval.boards;
    if (boards.length) {
      const { isChanged, newList } = mutateItemInList(boards, new BoardListItemHydrator(contentBoard));
      if (isChanged) {
        dispatch({ type: CONTENT_APPROVAL_ACTION_TYPES.UPDATE_CONTENT_APPROVAL_LIST_BOARD, payload: newList });
      }
    }
  };

export const clearContentBoardsList = (): ContentApprovalActionTypes => ({
  type: CONTENT_APPROVAL_ACTION_TYPES.UPDATE_CONTENT_APPROVAL_LIST_BOARD,
  payload: [],
});

export const duplicateContentBoard =
  (organizationId: string | number, boardId: string | number): ContentApprovalThunkReturnType =>
  async dispatch => {
    dispatch({ type: CONTENT_APPROVAL_ACTION_TYPES.DUPLICATE_BOARD_REQUEST });

    try {
      const dto = await contentApprovalServices.duplicateContentBoard(organizationId, boardId);

      const newBoard = new BoardHydrator(dto);
      // @ts-ignore
      dispatch({ type: CONTENT_APPROVAL_ACTION_TYPES.DUPLICATE_BOARD_SUCCESS, payload: { afterId: boardId, board: newBoard } });
    } catch (e) {
      if (e instanceof Error) {
        const notification = notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.DUPLICATE_BOARD_FAILED, {});
        dispatch(createNotification(notification));
        dispatch({ type: CONTENT_APPROVAL_ACTION_TYPES.DUPLICATE_BOARD_FAILURE });
      }
    }
  };

export const deleteContentBoard =
  (organizationId: string | number, boardId: string | number, params: ContentApprovalFiltersType): ContentApprovalThunkReturnType =>
  async dispatch => {
    dispatch({ type: CONTENT_APPROVAL_ACTION_TYPES.DUPLICATE_BOARD_REQUEST });

    try {
      await contentApprovalServices.deleteContentBoard(organizationId, boardId);

      dispatch(getContentBoards(organizationId, params));

      const notification = notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.DELTE_BOARD_SUCCESS, {});
      dispatch(createNotification(notification));
    } catch (e) {
      if (e instanceof Error) {
        const notification = notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.DELETE_BOARD_FAILED, {});
        dispatch(createNotification(notification));
        dispatch({ type: CONTENT_APPROVAL_ACTION_TYPES.DUPLICATE_BOARD_FAILURE });
      }
    }
  };
