import React, { useCallback, useMemo, useState } from 'react';
import { generateDeliverableConceptWithAI } from '../../../../../../services/contentApproval';
import { useAppSelector } from '../../../../../../shared/hooks/reduxHooks';
import { useParams } from 'react-router';
import { DeliverablesReducerState } from '../../../../../../reducers/deliverables.reducer';
import ErrorHandler from '../../../../../../models/entities/error/ErrorHandler';

const useGenerateConceptWithAI = isEditingMode => {
  const organizationId = useAppSelector(state => state.auth.currentOrganization?.organization_id);
  const isPageUpdateLoading = useAppSelector<DeliverablesReducerState['deliverableConceptUpdateLoading']>(
    state => state.deliverables.deliverableConceptUpdateLoading
  );
  const openedBoard = useAppSelector(state => state.contentBoard.openedBoard);
  const params = useParams();

  const [isGenerateLoading, setIsGenerateLoading] = useState<boolean>(false);

  const isGenerateAvailable = useMemo(() => {
    return (
      isEditingMode &&
      !!openedBoard &&
      (openedBoard.campaign_brief_id || openedBoard.campaign_brief_item_id || openedBoard.briefFile) &&
      !isPageUpdateLoading
    );
  }, [isEditingMode, isPageUpdateLoading, openedBoard, isPageUpdateLoading]);

  const generate = useCallback(
    async callback => {
      if (organizationId && params.boardId && params.deliverableId) {
        setIsGenerateLoading(true);
        await generateDeliverableConceptWithAI(organizationId, params.boardId, params.deliverableId)
          .then(response => {
            const message = response?.data?.response?.replace('\n\n', '');
            callback(message);
          })
          .catch(err => {
            throw new ErrorHandler(err).getError();
          })
          .finally(() => {
            setIsGenerateLoading(false);
          });
      }
    },
    [organizationId, params]
  );

  return { generate, isGenerateLoading, isGenerateAvailable };
};

export default useGenerateConceptWithAI;
