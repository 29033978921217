import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSubscribeChannel from '../../centrifuge/useSubscribeChannel';
import { createNotification } from '../../actions/toastNotificationActions/toastNotification.actions';
import { handleNotificationsSocketsAction } from '../../components/Modals/NotificationsModal/utils/functions';
import { updateNotifications } from '../../actions/notifications.actions';
import useUserTimezone from './useUserTimezone';
import { useHistory } from 'react-router-dom';
import { updateOrganizationPricingPlan } from '../../actions/auth.actions';
import { OrganizationSocketsContext } from '../../contexts/organizationContext';

const ORGANIZATION_ACTIONS = {
  STORAGE_UPDATE: 'storage_updated',
  DISCUSSION_ROOM_CREATE: 'discussion_room_create',
  CONTENT_BOARD_DELIVERABLE_VERSION_ASSETS_CONVERTED: 'content_board_deliverable_version_assets_converted',
};

const useGlobalSockets = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const JWT = useSelector(state => state.auth?.token?.access_token);
  const currentOrganizationID = useSelector(state => state.auth?.currentOrganization?.organization_id);
  const plan = useSelector(state => state.auth.currentOrganizationPlan);
  const userId = useSelector(state => state.auth.user.id);
  const context = useContext(OrganizationSocketsContext);

  const notificationHandler = toastSettings => {
    dispatch(createNotification(toastSettings));
    dispatch(updateNotifications());
  };

  const changeRoute = path => {
    history.push(path);
  };

  const { convertUtcDateToDateByTimezone } = useUserTimezone();

  useSubscribeChannel(userId, {
    channel: `user:${userId}`,
    events: ctx => {
      handleNotificationsSocketsAction(ctx, { notificationHandler, convertUtcDateToDateByTimezone, changeRoute, userId: userId });
    },
    opts: { data: { token: JWT, user_id: userId } },
    // onError: err => console.error('Centrifuge subscribe error: ', err),
    // onSubscribe: ctx => console.log('+ user', ctx.channel),
    // onUnsubscribe: ctx => console.log('- user', ctx.channel),
  });

  useSubscribeChannel(
    currentOrganizationID,
    {
      channel: `organization:${currentOrganizationID}`,
      events: ctx => {
        switch (ctx.data.action) {
          case ORGANIZATION_ACTIONS.STORAGE_UPDATE: {
            dispatch(updateOrganizationPricingPlan(ctx.data.data.usage));
            break;
          }
          case ORGANIZATION_ACTIONS.DISCUSSION_ROOM_CREATE: {
            context?.createRoomHandler(ctx);
            break;
          }
          case ORGANIZATION_ACTIONS.CONTENT_BOARD_DELIVERABLE_VERSION_ASSETS_CONVERTED: {
            context?.deliverableConvertedHandler(ctx);
            break;
          }
        }
      },
      opts: { data: { organization_id: currentOrganizationID, token: JWT } },
      // onError: err => console.error('Centrifuge subscribe error: ', err),
      // onSubscribe: ctx => console.log('+ Organization', ctx.channel),
      // onUnsubscribe: ctx => console.log('- Organization', ctx.channel),
    },
    !plan?.id
  );
};

export default useGlobalSockets;
