import React, { FC, useRef } from 'react';
import styles from './BrandDetails.module.scss';
import TextNavigationSection from './components/TextNavigationSection';
import TextSection from './components/TextSection';
import useAnchorLinks from './hooks/useAnchorLinks';
import { useMediaQuery } from 'react-responsive';
import cs from 'classnames';
import { IViewBriefData } from '../../../../../models/entities/boardBrief/ViewBrief';
import useTextConfig from './hooks/useTextConfig';
import BrandDetailsEmptyState from './components/BrandDetailsEmptyState';

type PropsType = {
  brief: IViewBriefData | null;
  className?: string;
  isLoading: boolean;
  isEditDisplay: boolean;
  onEdit: () => void;
};

const BrandDetails: FC<PropsType> = props => {
  const isMediumDevice = useMediaQuery({ query: '(max-width: 1200px)' });
  const textListRef = useRef<null | HTMLUListElement>(null);

  const { textConfig, isEmpty } = useTextConfig(props.brief);
  const { activeId, changeActiveId } = useAnchorLinks(textConfig, textListRef);

  if (isEmpty && !props.isLoading) {
    return <BrandDetailsEmptyState textConfig={textConfig} isEditDisplay={props.isEditDisplay} onEdit={props.onEdit} />;
  }

  return (
    <div className={cs(styles.root, props.className)}>
      <h3 className={styles.title}>Brand messaging and terms</h3>
      {!isMediumDevice ? (
        <TextNavigationSection
          isLoading={props.isLoading}
          changeActiveId={changeActiveId}
          activeId={activeId}
          textConfig={textConfig}
          className={styles.textNavigation}
        />
      ) : null}
      <TextSection isLoading={props.isLoading} textConfig={textConfig} className={styles.textSection} ref={textListRef} />
    </div>
  );
};

export default BrandDetails;
