import React, { useRef, useState } from 'react';
import styles from './SortButtons.module.scss';
import { ReactComponent as TriangleSvg } from '../../assets/img/icons/play-white-arrow.svg';
import { ReactComponent as SortArrowUpSvg } from '../../assets/img/icons/sort-arrow-up.svg';
import cs from 'classnames';
import { useClickOutside } from '../../shared/hooks/useClickOutside';

const SortButtons = ({ name, orderBy, isActive, onSort }) => {
  const refTriggerButton = useRef(null);
  const refDropdown = useRef(null);

  const [dropdownState, setDropdownState] = useState(false);

  const handleSortClick = orderType => {
    if (isActive(orderType)) {
      onSort('', '');
    } else {
      onSort(orderBy, orderType);
    }
    setDropdownState(false);
  };

  useClickOutside(refTriggerButton, refDropdown, () => setDropdownState(false));

  return (
    <div className={styles.sortIconsWrapper}>
      <div
        ref={refTriggerButton}
        className={cs(styles.sortButton, { [styles.active]: dropdownState })}
        onClick={() => setDropdownState(true)}
      >
        <div className={styles.label}>{name}</div>
        <div className={styles.arrows}>
          <TriangleSvg className={cs({ [styles.active]: isActive('asc') })} />
          <TriangleSvg className={cs({ [styles.active]: isActive('desc') })} />
        </div>
      </div>

      {dropdownState && (
        <div ref={refDropdown} className={styles.dropdown}>
          <div className={styles.dropdownItem} onClick={() => handleSortClick('asc')}>
            <div className={styles.sortArrowUp}>
              <SortArrowUpSvg />
            </div>
            <p>Sort Ascending</p>
          </div>
          <div className={styles.dropdownItem} onClick={() => handleSortClick('desc')}>
            <div className={styles.sortArrowDown}>
              <SortArrowUpSvg />
            </div>
            <p>Sort Descending</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SortButtons;
