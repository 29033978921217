import React, { FC, PropsWithChildren } from 'react';
import styles from './HeaderLoadingWrapper.module.scss';
import LoadingContainer from '../../../../../../../../../components/LoadingContainer';
import LoadingContainerWrapper from '../../../../../../../../../components/LoadingContainerWrapper';

type PropsType = {
  isLoading: boolean;
} & PropsWithChildren;

const HeaderLoadingWrapper: FC<PropsType> = props => {
  return (
    <>
      {props.isLoading ? (
        <>
          <div className={styles.root}>
            <LoadingContainer height="16px" width="80px" borderRadius="3px" />
            <LoadingContainerWrapper height="26px" width="75px" borderRadius="4px" background="#F7F8F9">
              <LoadingContainer height="12px" width="55px" borderRadius="3px" />
            </LoadingContainerWrapper>
          </div>
        </>
      ) : (
        props.children
      )}
    </>
  );
};

export default HeaderLoadingWrapper;
