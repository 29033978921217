import React from 'react';
import styles from './Bold.module.scss';
import cs from 'classnames';

/**
 * @param {string} classname
 * @param {string} color css format color
 * @param {'black' | 'purple'} presetColor preset name color
 * @constructor
 */
const Bold = ({ children, color, presetColor = 'black', className }) => {
  const inlineStyles = {};
  if (color) inlineStyles.color = color;
  return (
    <span className={cs(styles.root, styles[presetColor], className)} style={inlineStyles}>
      {children}
    </span>
  );
};

export default Bold;
