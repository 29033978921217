import BriefTextCard from '../../components/BriefTextCard';
import TextRow from '../../components/TextRow';
import { IViewBriefData } from '../../../../../../../models/entities/boardBrief/ViewBrief';
import React, { FC, memo, useMemo } from 'react';
import TextContainer from '../../components/TextContainer';
import TagsList from '../../components/TagsList';

type PropsType = {
  isLoading: boolean;
} & IViewBriefData['target_audience'];

const AudienceCard: FC<PropsType> = memo(props => {
  const ageText = useMemo(() => `${props?.age.min || 'Not set'} - ${props?.age.max || 'Not set'}`, [props.age]);
  const genderText = useMemo(() => props?.gender.map(item => item[0].toUpperCase() + item.slice(1)).join(', '), [props.age]);
  const locationTags = useMemo(() => {
    if (!props.location) return null;

    const result: string[] = [];

    if (props.location.country?.length) {
      result.push(...props.location.country);
    }

    if (props.location.county?.length) {
      result.push(...props.location.county);
    }
    if (props.location.city?.length) {
      result.push(...props.location.city);
    }

    return result;
  }, [props.location]);

  return (
    <BriefTextCard headerTitle={'Audience'}>
      <TextRow title={'Age'}>
        <TextContainer text={ageText} isLoading={props.isLoading} />
      </TextRow>
      <TextRow title={'Gender'}>
        <TextContainer isLoading={props.isLoading} text={genderText} />
      </TextRow>
      <TextRow title={'Location'}>
        <TagsList tags={locationTags} isLoading={props.isLoading} />
      </TextRow>
      <TextRow title={'Interests and behaviors'}>
        <TagsList tags={props.interests_and_behaviours} isLoading={props.isLoading} />
      </TextRow>
    </BriefTextCard>
  );
});

export default AudienceCard;
