import React, { ComponentProps, FC } from 'react';
import styles from './UpcomingDatesModal.module.scss';
import { ModalMedium } from '../../../../../../../components/Modals';
import { IDeliverable } from '../../../../../../../models/entities/deliverable/Deliverable';
import UpcomingDatesCard from '../UpcomingDatesCard';

type PropsType = {
  deliverables: IDeliverable[] | null;
  onClose: ComponentProps<typeof ModalMedium>['onClose'];
  isOpen: ComponentProps<typeof ModalMedium>['open'];
};

const UpcomingDatesModal: FC<PropsType> = props => {
  return (
    <ModalMedium
      closeBtnClassName={styles.closeBtn}
      classNames={styles.root}
      open={props.isOpen}
      onClose={props.onClose}
      maxWidth={'670px'}
    >
      <UpcomingDatesCard deliverables={props.deliverables} />
    </ModalMedium>
  );
};

export default UpcomingDatesModal;
