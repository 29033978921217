import { instance } from '../shared/utils/api.config.creator';
import { IUpdateNotificationPreferencesDTO } from '../models/entities/notificationPreferences/dto/UpdateNotificationPreferences';
import { NotificationPreferencesDTOArrayType } from '../models/entities/notificationPreferences/dto/NotificationPreferences';
import { IUpdateNotificationPreferencesGroupDTO } from '../models/entities/notificationPreferences/dto/UpdateNotificationPreferencesGroup';

export const getNotificationPreferences = async (userId: number | string): Promise<NotificationPreferencesDTOArrayType> => {
  const result = await instance.get<NotificationPreferencesDTOArrayType>(`/users/${userId}/notification-settings`);
  return result.data;
};

export const updateNotificationPreferences = async (userId: number | string, data: IUpdateNotificationPreferencesDTO[]): Promise<void> => {
  await instance.post(`/users/${userId}/notification-settings`, data);
};

export const updateNotificationPreferencesGroup = async (
  userId: number | string,
  data: IUpdateNotificationPreferencesGroupDTO[]
): Promise<NotificationPreferencesDTOArrayType> => {
  const result = await instance.post<NotificationPreferencesDTOArrayType>(`/users/${userId}/notification-settings-group`, data);

  return result.data;
};
