import React, { FC } from 'react';
import LoadingContainer from '../../../../../../components/LoadingContainer';
import { useMediaQuery } from 'react-responsive';

type PropsType = {
  index: number;
  isTablet: boolean;
  hideActions: boolean;
};

const CellRowLoadingState: FC<PropsType> = props => {
  const isSmallNotebook = useMediaQuery({ query: '(max-width: 1100px)' });

  return props.isTablet ? (
    <>
      {props.index === 0 ? (
        <div
          style={{
            height: '48px',
            padding: '15px',
            backgroundColor: 'white',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: props.index === 0 ? '10px 10px 0 0' : '0',
            marginBottom: '1px',
          }}
        >
          <div style={{ display: 'flex', columnGap: '10px', marginBottom: '11px', height: '100%', alignItems: 'center' }}>
            <LoadingContainer width={'87px'} height={'18px'} animationDelay={`${-1 + props.index * 0.1}s`} />
            <LoadingContainer height={'28px'} width={'35px'} animationDelay={`${-1 + props.index * 0.1}s`} />
          </div>
        </div>
      ) : null}
      <div
        style={{
          backgroundColor: 'white',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: '15px',
          borderRadius: props.index === 0 ? '10px 10px 0 0' : '0',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '45px 1fr 40px',
              gridColumnGap: '10px',
              gridTemplateRows: 'repeat(2, 22.5px)',
              width: '100%',
            }}
          >
            <div style={{ gridRow: '1 / 3' }}>
              <LoadingContainer width={'45px'} height={'45px'} animationDelay={`${-1 + props.index * 0.2}s`} />
            </div>
            <LoadingContainer width={'90%'} height={'20px'} animationDelay={`${-1 + props.index * 0.2}s`} />
            {!props.hideActions ? (
              <LoadingContainer width={'30px'} height={'30px'} borderRadius="4px" animationDelay={`${-1 + props.index * 0.2}s`} />
            ) : (
              <div />
            )}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <LoadingContainer width={'80px'} height={'20px'} animationDelay={`${-1 + props.index * 0.3}s`} />
              <LoadingContainer width={'170px'} height={'20px'} animationDelay={`${-1 + props.index * 0.3}s`} />
            </div>
          </div>
          <div style={{ display: 'grid', width: '100%', gridTemplateColumns: 'repeat(2, 50%)' }}>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridRowGap: '7px' }}>
              <div style={{ gridRow: '1 / 2', gridColumn: '1 / 2' }}>
                <LoadingContainer width={'100%'} height={'15px'} animationDelay={`${-1 + props.index * 0.4}s`} />
              </div>
              <div style={{ gridRow: '2 / 3', gridColumn: '1 / 4' }}>
                <LoadingContainer width={'100%'} height={'24px'} animationDelay={`${-1 + props.index * 0.4}s`} />
              </div>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridRowGap: '7px' }}>
              <div style={{ gridRow: '1 / 2', gridColumn: '1 / 2' }}>
                <LoadingContainer width={'100%'} height={'15px'} animationDelay={`${-1 + props.index * 0.4}s`} />
              </div>
              <div style={{ gridRow: '2 / 3', gridColumn: '1 / 4' }}>
                <LoadingContainer width={'100%'} height={'24px'} animationDelay={`${-1 + props.index * 0.4}s`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div
      style={{
        display: 'grid',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '60px',
        gridTemplateColumns: isSmallNotebook
          ? 'minmax(110px, 2fr) minmax(100px, 1fr) minmax(120px, 1fr) 133px 133px 30px'
          : `minmax(200px, 3fr) minmax(100px, 2fr) minmax(120px, 1fr) 133px 133px ${props.hideActions ? 0 : '60px'}`,
        gap: '10px',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
        <LoadingContainer width="34px" height="34px" borderRadius="5px" animationDelay={`${-1 + props.index * 0.1}s`} />
        <LoadingContainer width="100%" height="18px" borderRadius="5px" animationDelay={`${-1 + props.index * 0.2}s`} />
      </div>
      <LoadingContainer width="100px" height="18px" borderRadius="5px" animationDelay={`${-1 + props.index * 0.3}s`} />
      <LoadingContainer width="100px" height="28px" borderRadius="5px" animationDelay={`${-1 + props.index * 0.5}s`} />
      <LoadingContainer width="133px" height="28px" borderRadius="5px" animationDelay={`${-1 + props.index * 0.5}s`} />
      <LoadingContainer width="133px" height="28px" borderRadius="5px" animationDelay={`${-1 + props.index * 0.6}s`} />
      {!props.hideActions && (
        <LoadingContainer width="30px" height="30px" borderRadius="5px" animationDelay={`${-1 + props.index * 0.7}s`} />
      )}
    </div>
  );
};

export default CellRowLoadingState;
