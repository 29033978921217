import React, { useEffect, useState } from 'react';
import { AssetStateType } from '../../../../models/entities/asset/AssetState';

type UseInfoBannerStateType = (assets: AssetStateType[], isEditingMode: boolean) => { isInfoBannerOpen: boolean; onClose: () => void };

const useInfoBannerState: UseInfoBannerStateType = (assets, isEditingMode) => {
  const [infoBannerSettings, setInfoBannerSettings] = useState<{ isOpen: boolean; shouldOpenAgain: boolean }>({
    isOpen: false,
    shouldOpenAgain: true,
  });

  useEffect(() => {
    if (assets.length && assets.every(asset => 'file' in asset) && infoBannerSettings.shouldOpenAgain) {
      setInfoBannerSettings({ isOpen: true, shouldOpenAgain: false });
    }
  }, [assets]);

  useEffect(() => {
    if (!isEditingMode) {
      setInfoBannerSettings({ isOpen: false, shouldOpenAgain: false });
    }
  }, [isEditingMode]);

  const onClose = () => {
    setInfoBannerSettings({ ...infoBannerSettings, isOpen: false });
  };

  return { isInfoBannerOpen: infoBannerSettings.isOpen, onClose };
};

export default useInfoBannerState;
