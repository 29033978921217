import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ORGANIZATION_TYPES } from '../../../../../models/enums/OrganizationTypes';
import { emailValidation } from '../../../../CreateTest/utils';
import { IBoardBriefFormikValues, LogoFileObjectType } from '../../../../../models/entities/boardBrief/BoardBriefFormikValues';
import { IDeliverableFormData } from '../../../ContentBoard/components/AddDeliverablesModal/hooks/useDeliverablesFormData';
import { COLLABORATOR_ROLE_TYPES } from '../../../../../models/permissions/collaborator/CollaboratorRole';
import { OptionalObjectType } from '../../../../../models/utils/OptionalObjectType';

export interface ICreateBoardFormikValues {
  boardName: string;
  initiatorOrganizationType: COLLABORATOR_ROLE_TYPES | '';
  inviteEmail: string;
  inviteOrganizationType: COLLABORATOR_ROLE_TYPES | '';
  inviteMessage: string;
  createdBrief: IBoardBriefFormikValues | null;
  deliverables: IDeliverableFormData[];
  briefFile: null | File;
  contractFile: null | File;
  brandLogo: LogoFileObjectType;
  brandName: string | null;
}

const initialFormikState: ICreateBoardFormikValues = {
  boardName: '',
  // brand
  brandLogo: { file: null, fileUrl: null },
  brandName: '',
  // collaborators
  initiatorOrganizationType: '',
  inviteEmail: '',
  inviteOrganizationType: '',
  inviteMessage: '',
  // brief
  createdBrief: null,
  briefFile: null,

  contractFile: null,

  deliverables: [],
};

const useCreateBoardFormik = (values?: OptionalObjectType<ICreateBoardFormikValues>) =>
  useFormik<ICreateBoardFormikValues>({
    initialValues: { ...initialFormikState, ...values },
    validationSchema: Yup.object().shape({
      boardName: Yup.string().required('Board name is required'),
      brandName: Yup.string().trim().max(200, 'Maximum length of 200 characters exceeded').required('Brand name is required'),
      initiatorOrganizationType: Yup.string().oneOf(Object.values(ORGANIZATION_TYPES)).required('Select a type.'),
      inviteEmail: Yup.string().test('validate-email', emailValidation),
      inviteOrganizationType: Yup.string().when('inviteEmail', {
        is: inviteEmail => inviteEmail && inviteEmail.length > 0,
        then: Yup.string().oneOf(Object.values(ORGANIZATION_TYPES)).required('Select a type.'),
      }),
    }),
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: () => {},
  });

export default useCreateBoardFormik;
