import React, { useEffect } from 'react';
import styles from './TabBriefDetails.module.scss';
import RadioButtons from '../../RadioButtons';
import CalendarDropdownOld from '../../../../../expert/components/FormControls/CalendarDropdownOld';
import CoolIcon from '../../../../../components/IconsSvg/CoolIconSVG';
import TextField from '../../../../../components/FormControls/TextField';
import Dropdown from '../../../../../components/FormControls/Dropdown';
import CheckboxButton from '../../CheckboxButton';
import SubTabAudience from './SubTabAudience';
import ContactSVG from '../../../../../components/IconsSvg/ContactSVG';
import SubTabOtherTerms from './SubTabOtherTerms';
import ListSVG from '../../../../../components/IconsSvg/ListSVG';
import SubTabSuggestedContent from './SubTabSuggestedContent';
import LampSVG from '../../../../../components/IconsSvg/LampSVG';
import Option from '../../Option';
import TagBlock from '../../TagBlock';
import HashtagSVG from '../../../../../components/IconsSvg/HashtagSVG';
import DeliverablesPlatforms from '../../../../../components/DeliverablesPlatforms';
import { useMediaQuery } from 'react-responsive/src';
import EditorStyledField from '../../../../../components/EditorStyledField';

const campaignObjectiveValues = [
  {
    key: 'awareness',
    title: 'Awareness',
    description:
      'Generate interest in your product or service. Increasing brand awareness is about telling people what makes your brand or product valuable.',
  },
  {
    key: 'engagement',
    title: 'Engagement',
    description:
      'Reach people more likely to engage with your post. Engagement includes likes, comments and shares but can also include offers claimed from your Page',
  },
  {
    key: 'sales_leads',
    title: 'Sales/Leads',
    description:
      'Collect leads for your business. Create ads that collect info from people interested in your product, such as sign-ups for newsletters, or have people give your business a call.',
  },
];

const manageBoostingValues = [
  {
    key: 'brand/agency',
    value: ['brand', 'agency'],
    title: 'Brand/Agency',
  },
  {
    key: 'talent_agency/content_creator',
    value: ['talent_agency', 'content_creator'],
    title: 'Talent agency/Content creator',
  },
];

const categories = ['AUD', 'CAD', 'EUR', 'USD'];

function TabBriefDetails({ formik, deliverablesFormik, disableSuggestedCreators = false }) {
  useEffect(() => {
    const isShow =
      !!formik.values.boosting_manager.length ||
      !!formik.values.boosting_duration ||
      !!formik.values.boosting_budget ||
      !!formik.values.live_date_start ||
      !!formik.values.live_date_end;
    formik.setFieldValue('showBoosting', isShow);
  }, [
    formik.values.boosting_manager,
    formik.values.boosting_duration,
    formik.values.boosting_budget,
    formik.values.live_date_start,
    formik.values.live_date_end,
  ]);

  useEffect(() => {
    if (formik.values.showBoosting) {
      formik.setFieldValue('boosting_manager', ['brand', 'agency']);
    }
  }, [formik.values.showBoosting]);

  const setBoostingBlock = () => {
    formik.setFieldValue('showBoosting', true);
  };

  const removeBoostingBlock = () => {
    formik.setFieldValue('showBoosting', false);
    formik.setFieldValue('boosting_manager', []);
    formik.setFieldValue('boosting_duration', undefined);
    formik.setFieldValue('boosting_budget', undefined);
  };

  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const setCampaignObjective = item => {
    if (formik.values.campaignObjective.includes(item.key)) {
      const res = formik.values.campaignObjective.filter(value => value != item.key);
      formik.setFieldValue('campaignObjective', [...res]);
    } else {
      formik.values.campaignObjective.push(item.key);
      formik.setFieldValue('campaignObjective', [...formik.values.campaignObjective]);
    }
  };

  const setManageBoosting = item => {
    formik.setFieldValue('boosting_manager', item.value);
  };

  const selectSocial = () => {
    formik.setFieldValue('tagsCategory', '');
  };

  const addHashtagsHandler = value => {
    if (formik.values.hashtags.indexOf('#' + value) === -1) {
      if (value.charAt(0) === '#') {
        formik.setFieldValue('hashtags', [...formik.values.hashtags, value]);
      } else {
        formik.setFieldValue('hashtags', [...formik.values.hashtags, '#' + value]);
      }
    }
  };

  const addSocialTaggingHandler = value => {
    if (formik.values.socialTagging.indexOf('@' + value) === -1) {
      if (value.charAt(0) === '@') {
        formik.setFieldValue('socialTagging', [...formik.values.socialTagging, value]);
      } else {
        formik.setFieldValue('socialTagging', [...formik.values.socialTagging, '@' + value]);
      }
    }
  };

  const removeTagHandler = value => {
    switch (value.charAt(0)) {
      case '#':
        formik.setFieldValue(
          'hashtags',
          formik.values.hashtags.filter(tag => value !== tag)
        );
        break;
      case '@':
        formik.setFieldValue(
          'socialTagging',
          formik.values.socialTagging.filter(tag => value !== tag)
        );
        break;
      default:
        formik.setFieldValue(
          'callToActions',
          formik.values.callToActions.filter(tag => value !== tag)
        );
        break;
    }
  };

  const onChangeBudget = e => {
    if (e.target.value >= 0) {
      e.target.value = Math.trunc(e.target.value);
      if (e.target.value > 1000000000) {
        e.target.value = 1000000000;
      }
      return formik.handleChange(e);
    }
    e.target.value = 0;
    return formik.handleChange(e);
  };

  const subTabsData = [
    {
      title: 'Do you have a preferred target Audience?',
      content: <SubTabAudience formik={formik} />,
      icon: <ContactSVG />,
    },
    {
      content: <SubTabOtherTerms formik={formik} />,
      icon: <ListSVG />,
    },
  ];

  if (!disableSuggestedCreators) {
    subTabsData.unshift({
      title: 'Suggested content creators',
      content: <SubTabSuggestedContent formik={formik} />,
      icon: <LampSVG />,
    });
  }

  return (
    <>
      <div>
        <h3>Name</h3>
        <TextField
          name={'itemName'}
          placeholder={'Name'}
          className={styles.input}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.itemName}
          error={formik.errors.itemName}
        />
      </div>
      <div>
        <h3>What is the key messaging?</h3>
        <EditorStyledField
          toolbarClassName={styles.editorToolbar}
          wrapperClassName={styles.editorWrapperClassName}
          editorClassName={styles.editorClassName}
          initialValue={formik.values.messaging}
          onChange={text => formik.setFieldValue('messaging', text)}
          onBlur={formik.handleBlur}
          placeholder={
            'Share any key messages you want to convey about the brand or product. Tip: Bullet points help to convey the message clearly.'
          }
          error={formik.errors.messaging}
        />
      </div>
      <div>
        <h3>What is your campaign Objective?</h3>
        <RadioButtons items={campaignObjectiveValues} activeItems={formik.values.campaignObjective} setItem={setCampaignObjective} />
      </div>

      <div className={styles.line} />

      <div className={styles.subTabWrapper}>
        <div className={styles.subTabHeader} style={{ marginLeft: 0, marginRight: 0 }}>
          <span className={styles.iconWrapper}>
            <HashtagSVG />
          </span>
          <h2>{'Deliverables'}</h2>
        </div>
      </div>

      <DeliverablesPlatforms formik={deliverablesFormik} parentSelectSocial={selectSocial} />

      <TagBlock
        title={'Hashtags'}
        tags={formik.values.hashtags}
        addTag={addHashtagsHandler}
        removeTag={removeTagHandler}
        placeholder={!isMobile ? 'Please provide the hashtags you want to be featured with your content.' : 'Please provide the hashtags'}
        className={styles.tagBlock}
        isDisplayButtonAdd
        disableSpace
      />
      <TagBlock
        title={'Social handles'}
        tags={formik.values.socialTagging}
        addTag={addSocialTaggingHandler}
        removeTag={removeTagHandler}
        placeholder={
          !isMobile ? 'Please provide the social handles you want to be featured with your content.' : 'Please provide the social handles'
        }
        className={styles.tagBlock}
        isDisplayButtonAdd
        disableSpace
      />

      <div className={styles.line} />
      <div>
        <h3>Preferred Ad Declaration</h3>
        <p>How would you like your content creator to declare the content when posting</p>
        <CheckboxButton
          onClick={() => formik.setFieldValue('adInCaption', false)}
          isActive={!formik.values.adInCaption}
          item={'Paid Partnership'}
          paragraph={'Paid partnership tag appears at the top of the content your content creator post'}
          className={styles.checkboxHeading}
        />

        <CheckboxButton
          onClick={() => formik.setFieldValue('adInCaption', true)}
          isActive={formik.values.adInCaption}
          item={'Ad in Caption'}
          paragraph={'#AD included in post copy and on static or video asset to adhere to requirements.'}
          className={styles.checkboxHeading}
        />
      </div>
      <div className={styles.line} />
      <h2 style={{ marginBottom: 0 }}>Budget and duration</h2>
      <div>
        <h3>Campaign Duration</h3>
        <div className={styles.campaignDurationBlock}>
          <div className={styles.dateFieldWrapper}>
            <CalendarDropdownOld
              dataFormat={'D MMMM YYYY'}
              value={formik.values.date_start}
              onChange={date => {
                if (formik.values.date_end && formik.values.date_end < date) {
                  formik.setFieldValue('date_end', null);
                }
                formik.setFieldValue('date_start', date);
              }}
              className={styles.datePicker}
              withIcon
            />
          </div>
          <div className={styles.calendarArrowWrapper}>
            <CoolIcon />
          </div>
          <div className={styles.dateFieldWrapper}>
            <CalendarDropdownOld
              dataFormat={'D MMMM YYYY'}
              value={formik.values.date_end}
              error={formik.errors.date_end}
              minDate={formik.values.date_start}
              onChange={date => {
                formik.setFieldValue('date_end', date);
              }}
              className={styles.datePicker}
              withIcon
            />
          </div>
        </div>
      </div>

      <div>
        <h3>What is your overall Campaign Budget?</h3>
        <p>
          This is the overall budget for your campaign including the cost of all talent in your campaign brief as well as any talent
          management fees.
        </p>
        <div className={styles.budgetBlock}>
          <Dropdown
            noValuePlaceholder={'NZD'}
            className={styles.dropdown}
            items={categories}
            getId={i => i}
            getDisplayValue={i => i}
            placeholder="Select an item"
            renderItem={i => <Option value={i} />}
            selectedItem={formik.values.total_budget_currency}
            onSelect={i => formik.setFieldValue('total_budget_currency', i)}
            noValueAvailable
          />
          <div className={styles.budgetInputWrapper}>
            <TextField
              name={'total_budget'}
              type={'number'}
              min={0}
              placeholder={'0'}
              onChange={e => {
                onChangeBudget(e);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.total_budget || ''}
              className={styles.budgetInput}
            />
          </div>
        </div>
      </div>
      <div className={styles.boostingSection}>
        <h3>Would you require your content to be boosted?</h3>
        <div className={styles.checkboxBlock}>
          <CheckboxButton
            onClick={setBoostingBlock}
            isActive={formik.values.showBoosting}
            item={'Yes'}
            className={styles.checkboxHeading}
          />
          <CheckboxButton
            onClick={removeBoostingBlock}
            isActive={!formik.values.showBoosting}
            item={'No'}
            className={styles.checkboxHeading}
          />
        </div>
        {formik.values.showBoosting && (
          <div className={styles.showBoostingSection}>
            <div className={styles.showBoostingSubSection}>
              <h3>What is your boosting duration?</h3>
              <div className={styles.boostingDuration}>
                <TextField
                  name={'boosting_duration'}
                  type={'number'}
                  min={1}
                  placeholder={'0'}
                  onChange={e => {
                    onChangeBudget(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.boosting_duration || ''}
                  error={formik.errors.boosting_duration}
                  wrapperClassName={styles.boosting}
                />
                <span style={{ lineHeight: '40px' }}>Weeks</span>
              </div>
            </div>

            <div className={styles.showBoostingSubSection}>
              <h3>Who will manage your boosting?</h3>
              <RadioButtons
                items={manageBoostingValues}
                activeItems={[formik.values.boosting_manager.join('/')]}
                setItem={setManageBoosting}
                className={styles.manageBoosting}
              />
            </div>

            <div className={styles.showBoostingSubSection}>
              <h3>What is your boosting budget?</h3>
              <p>This is money you’d like to allocate across all the platforms to boost the content produced</p>
              <div className={styles.budgetBlock}>
                <Dropdown
                  noValuePlaceholder={'NZD'}
                  className={styles.dropdown}
                  items={categories}
                  getId={i => i}
                  getDisplayValue={i => i}
                  placeholder="Select an item"
                  renderItem={i => <Option value={i} />}
                  selectedItem={formik.values.total_budget_currency} // intended same key to change both dropdowns at once
                  onSelect={i => formik.setFieldValue('total_budget_currency', i)}
                  noValueAvailable
                />
                <div className={styles.budgetInputWrapper}>
                  <TextField
                    name={'boosting_budget'}
                    type={'number'}
                    min={1}
                    placeholder={'0'}
                    onChange={e => {
                      onChangeBudget(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.boosting_budget || ''}
                    error={formik.errors.boosting_budget}
                    className={styles.budgetInput}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={styles.liveDataSection}>
          <h3>What is a tentative go live date for the first content?</h3>
          <div className={styles.dateFieldWrapper} style={{ maxWidth: isMobile ? '100%' : 320, width: '100%', position: 'relative' }}>
            <CalendarDropdownOld
              dataFormat={'D MMMM YYYY'}
              value={formik.values.date_post}
              onChange={date => formik.setFieldValue('date_post', date)}
              className={styles.datePicker}
              withIcon
            />
          </div>
        </div>
      </div>
      {subTabsData.map((item, index) => (
        <React.Fragment key={item.title + index}>
          <div className={styles.line} />
          <div>
            {item.title && <h2>{item.title}</h2>}
            {item.content}
          </div>
        </React.Fragment>
      ))}
    </>
  );
}

export default TabBriefDetails;
