import { TOAST_NOTIFICATION_TYPES } from '../../../../../../shared/constants/toastNotificationsData';

export const contractToastNotificationConfig = {
  replace: TOAST_NOTIFICATION_TYPES.CONTRACT_REPLACE_SUCCESS,
  delete: TOAST_NOTIFICATION_TYPES.CONTRACT_DELETE_SUCCESS,
  upload: TOAST_NOTIFICATION_TYPES.CONTRACT_UPLOAD_SUCCESS,
};

export const boardBriefToastNotificationConfig = {
  replace: TOAST_NOTIFICATION_TYPES.BOARD_BRIEF_REPLACE_SUCCESS,
  delete: TOAST_NOTIFICATION_TYPES.BOARD_BRIEF_DELETE_SUCCESS,
  upload: TOAST_NOTIFICATION_TYPES.BOARD_BRIEF_UPLOAD_SUCCESS,
};
