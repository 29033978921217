import { IFileData } from './useFileUpload';
import { IFile } from '../../../models/entities/file/File';
import useHiddenFileInput from './useHiddenFileInput';
import useDownloadAsset from '../../../shared/hooks/useDownloadAsset';
import { useCallback } from 'react';
import useFileUploadWithLoading, { IPreloadingFile } from './useFileUploadWithLoading';
import useValidateFileInputAccept from './useValidateFileInputAccept';

const useFileActionHandlersWithLoading = (
  handleAction: (value: IFileData | null) => void,
  file?: IFile | null,
  inputSettings?: Parameters<typeof useHiddenFileInput>[1],
  onError?: (e: string) => void
): {
  onDelete: () => void;
  onDownload: () => void;
  onFileUpload: () => void;
  renderInput: () => JSX.Element;
  isUploading: boolean;
  loadingPercentageProgress: number;
  preloadingFile: IPreloadingFile | null;
} => {
  const { onLoadEnd, isUploading, preloadingFile, loadingPercentageProgress } = useFileUploadWithLoading({
    handleCreate: handleAction,
  });
  const validateFileUpload = useValidateFileInputAccept(inputSettings?.accept, onLoadEnd, onError);
  const { renderInput, clickFileInput: onFileUpload } = useHiddenFileInput(validateFileUpload, inputSettings);

  const { downloadAsset } = useDownloadAsset(file?.name ? () => file?.name : null);

  const onDelete = useCallback(() => {
    handleAction(null);
  }, [handleAction]);

  const onDownload = useCallback(() => {
    if (!file?.url || !file?.extension) return;
    downloadAsset(file.url, file.extension);
  }, [file?.extension, file?.url, downloadAsset]);

  return {
    onDelete,
    onDownload,
    onFileUpload,
    renderInput,
    isUploading,
    preloadingFile,
    loadingPercentageProgress,
  };
};

export default useFileActionHandlersWithLoading;
