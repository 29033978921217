import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import Routes from './Routes';
import { getCurrentOrganizationPricingPlan, me } from './actions/auth.actions';
import { getAdIntegrations } from './actions/ad_integration.actions';
import AppScripts from './components/AppScripts';
import { getAllPlans, getAllPlansByOrganizationId } from './actions/payment.action';
import { getUserUsage, updateUser } from './actions/user.actions';
import useGlobalSockets from './shared/hooks/useGlobalSockets';
import useChangeUserModel from './shared/hooks/useChangeUserModel';
import * as configServices from './services/config';
import moment from 'moment';
import { MEMBER_ROLE_IN_ORGANIZATION_TYPES } from './models/enums/MemberRoleInOrganizationTypes';
import { ORGANIZATION_DEFAULT_NAME_START} from './pages/Auth/SignUpNew';
import AppModals from './components/AppModals';

const App = () => {
  // Google analytics code start
  ReactGA.initialize('UA-199543875-1', {
    debug: false,
  });
  // Google analytics code end

  const dispatch = useDispatch();
  const history = useHistory();
  const isAuthenticated = useSelector(state => !!state.auth.token);
  const recalculateRoutePermissionsTrigger = useSelector(state => !!state.auth.recalculateRoutePermissionsTrigger);
  const appCentrifuge = useSelector(state => state.centrifuge.appCentrifuge);
  const JWT = useSelector(state => state.auth?.token?.access_token);
  const organizations = useSelector(state => state.auth.organizations);
  const currentOrgRedux = useSelector(state => state.auth.currentOrganization);
  const currentPlan = useSelector(state => state.auth.currentOrganizationPlan);
  const user = useSelector(state => state.auth.user);
  const loc = useLocation();
  const currentOrglocalStorage = JSON.parse(localStorage.getItem('OTTERFISH_CURRENT_ORGANIZATION'));
  const preselectedPlan = useSelector(state => state.auth.preselectPlan);

  useChangeUserModel();

  // SET TIMEZONE IF IT NOT EXISTS
  useEffect(() => {
    if (user?.timezone_id === null) {
      configServices.getTimezones({ search_filter: moment.tz.guess() }).then(response => {
        const timezone = response.data[0];
        if (timezone) {
          dispatch(updateUser(user.id, { email: user.email, timezone_id: timezone.id })).then(() => {
            dispatch(me());
          });
        }
      });
    }
  }, [user]);

  // SET ORG TO LOCAL STORAGE
  useEffect(() => {
    if (currentOrgRedux) {
      localStorage.setItem('OTTERFISH_CURRENT_ORGANIZATION', JSON.stringify(currentOrgRedux));
    }
  }, [currentOrgRedux]);

  useEffect(() => {
    if (!isAuthenticated || !organizations || !organizations.length) {
      return;
    }

    if (!currentOrglocalStorage || !currentOrgRedux) {
      const org = currentOrgRedux?.has_valid_subscription ? currentOrgRedux : currentOrglocalStorage;

      if (org?.has_valid_subscription && org?.role === MEMBER_ROLE_IN_ORGANIZATION_TYPES.OWNER) {
        dispatch({
          type: 'SET_CURRENT_ORGANIZATION',
          payload: org,
        });
        return;
      }

      const ownerOrg = organizations.find(o => o.role === MEMBER_ROLE_IN_ORGANIZATION_TYPES.OWNER);

      const validOrg = organizations.find(o => o.pricing_plan_id);

      let newOrg;
      if (localStorage.getItem('OTTERFISH_CURRENT_ORGANIZATION')) {
        newOrg = JSON.parse(localStorage.getItem('OTTERFISH_CURRENT_ORGANIZATION'));
      } else {
        newOrg = ownerOrg || validOrg || organizations[0];
      }
      dispatch({
        type: 'SET_CURRENT_ORGANIZATION',
        payload: newOrg,
      });
    }
  });

  // When changing auth or organization this will request all data
  useEffect(() => {
    const isCompleteSignUp = loc.pathname.includes('/complete-signup');
    if (isAuthenticated && !isCompleteSignUp) {
      dispatch(me());
      if (currentOrgRedux) {
        dispatch(getAllPlans());
        dispatch(getAdIntegrations());
        if (currentOrgRedux) {
          dispatch(getAllPlansByOrganizationId(currentOrgRedux.organization_id));
          dispatch(getCurrentOrganizationPricingPlan(currentOrgRedux.organization_id));
          if (currentOrgRedux.pricing_plan_id) {
            if (user.pricing_plan_id) {
              dispatch(getUserUsage());
            }
          }
        }
      }
    } else if (isAuthenticated && currentOrgRedux && isCompleteSignUp) {
      dispatch(getAllPlans());
      dispatch(getAllPlansByOrganizationId(currentOrgRedux.organization_id));
      dispatch(getCurrentOrganizationPricingPlan(currentOrgRedux.organization_id));
    }
  }, [isAuthenticated, currentOrgRedux]);

  // REDIRECTS
  useEffect(() => {
    if (isAuthenticated && organizations != null) {
      if (organizations.length > 0) {
        const ownerOrg = organizations.find(o => o.role === MEMBER_ROLE_IN_ORGANIZATION_TYPES.OWNER);
        const validOrg = organizations.find(o => o.pricing_plan_id);

        const newOrg = ownerOrg || validOrg || organizations[0];
        const userHasPlan = newOrg.pricing_plan_id;
        const permittedRoutesWithoutPlan = ['/upgrade-plan', '/checkout-success', '/confirm-invite'];
        // redirect to choose plan page
        if (!userHasPlan && !permittedRoutesWithoutPlan.includes(loc.pathname)) {
          history.push(`/upgrade-plan${preselectedPlan ? '#' + preselectedPlan : ''}`);
          return;
        }

        // redirect to complete organization
        const permittedRoutesWithoutOrganizationName = ['/complete-organization', '/upgrade-plan', '/checkout-success'];
        if (newOrg.organization_name.startsWith(ORGANIZATION_DEFAULT_NAME_START) && !permittedRoutesWithoutOrganizationName.includes(loc.pathname)) {
          history.push(`/complete-organization`);
        }
      } else {
        const permittedRoutesWithoutOrg = ['/no-organizations', '/profile', '/create-organization', '/confirm-invite'];
        // redirect to the no organization page
        if (!permittedRoutesWithoutOrg.includes(loc.pathname)) {
          history.push('/no-organizations');
        }
      }
    }
  }, [organizations, currentPlan, isAuthenticated, recalculateRoutePermissionsTrigger]);

  // CENTRIFUGE SETUP
  useEffect(() => {
    if (isAuthenticated && JWT) {
      appCentrifuge.connect(JWT);
    } else {
      appCentrifuge.disconnect();
    }
  }, [isAuthenticated, JWT, appCentrifuge]);

  useGlobalSockets();

  return (
    <>
      <AppModals />
      <Switch>
        <Route path="/" render={() => <Routes />} />
      </Switch>
      <AppScripts />
    </>
  );
};

export default App;
