import { useCallback, useMemo } from 'react';
import ERROR_TYPE from '../../../models/entities/error/ErrorTypes';
import { toggleOpenExtraStorageModal } from '../../../actions/payment.action';
import { OrganizationPermission } from '../../../models/permissions/enum/OrganizationPermission';
import { MEMBER_ROLE_IN_ORGANIZATION_TYPES } from '../../../models/enums/MemberRoleInOrganizationTypes';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks/reduxHooks';
import { useHistory } from 'react-router-dom';

const useErrorLimitModalConfig = (error, manualType, onClose) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const currentOrg = useAppSelector(state => state.auth.currentOrganization);
  const currentOrgModel = useAppSelector(state => state.auth.currentOrganizationModel);
  const currentPlan = useAppSelector(state => state.auth.currentOrganizationPlan);

  const isUpgradeCTA = useMemo(() => {
    let shouldUpgrade = currentOrgModel?.hasAccess(OrganizationPermission.SHOULD_UPGRADE_PLAN_AFTER_LIMIT);
    shouldUpgrade = shouldUpgrade && currentPlan?.name === 'Scale Plan' ? false : shouldUpgrade;

    return shouldUpgrade;
  }, [currentOrgModel, currentPlan]);

  const isMember = useMemo(() => {
    return currentOrg?.role === MEMBER_ROLE_IN_ORGANIZATION_TYPES.MEMBER;
  }, [currentOrg?.role]);

  const createAndCollaborateLimitsTextCTA = useMemo(() => {
    return isUpgradeCTA ? (isMember ? 'Ok' : 'Upgrade') : 'Talk to Sales';
  }, [isUpgradeCTA, isMember]);

  const createAndCollaborateLimitsActionCTA = useCallback(() => {
    if (isUpgradeCTA) {
      if (!isMember) {
        history.push('/upgrade-plan');
      }
    } else {
      window.open('https://otterfish.com/get-in-touch/', '_blank');
    }
    onClose();
  }, [onClose, isMember, isUpgradeCTA]);

  const storageLimitTextCTA = useMemo(() => {
    return currentPlan?.is_free ? (isUpgradeCTA && !isMember ? 'Upgrade' : 'Ok') : 'Buy Storage';
  }, [isUpgradeCTA, isMember, currentPlan?.is_free]);

  const storageLimitActionCTA = useCallback(() => {
    if (currentPlan?.is_free) {
      if (isUpgradeCTA && !isMember) {
        history.push('/upgrade-plan');
      }
    } else {
      dispatch(toggleOpenExtraStorageModal(true));
    }
    onClose();
  }, [onClose, isMember, isUpgradeCTA, currentPlan?.is_free]);

  return useMemo(() => {
    if (!error && !manualType) return {};
    const type = manualType ? manualType : error ? error.getType() : null;
    const salesTeamText =
      "You've reached the limit on your Scale Plan, don't worry - upgrading is easy! Contact our team to upgrade your plan";
    const upgradePlanText =
      "You've reached the limit on your Starter Plan, don't worry - upgrading is easy! Click on Upgrade and choose the plan that suits you.\n" +
      '\n';
    const storageLimitText = currentPlan?.is_free
      ? upgradePlanText
      : 'Looks like you have reached your storage limit. You can always buy more storage.';

    switch (type) {
      case ERROR_TYPE.STORAGE_LIMIT_ERROR: {
        return {
          text: 'Uh Oh!!',
          subText: storageLimitText,
          cta: {
            text: storageLimitTextCTA,
            action: storageLimitActionCTA,
          },
        };
      }
      case ERROR_TYPE.CONTENT_BOARD_COLLABORATE_LIMIT_ERROR: {
        return {
          text: isUpgradeCTA ? upgradePlanText : salesTeamText,
          cta: {
            text: createAndCollaborateLimitsTextCTA,
            action: createAndCollaborateLimitsActionCTA,
          },
        };
      }
      case ERROR_TYPE.CAMPAIGN_BRIEF_ITEM_COLLABORATE_LIMIT_ERROR: {
        return {
          text: isUpgradeCTA ? upgradePlanText : salesTeamText,
          cta: {
            text: createAndCollaborateLimitsTextCTA,
            action: createAndCollaborateLimitsActionCTA,
          },
        };
      }
      case ERROR_TYPE.CONTENT_BOARD_CREATE_LIMIT_ERROR: {
        return {
          text: isUpgradeCTA ? upgradePlanText : salesTeamText,
          cta: {
            text: createAndCollaborateLimitsTextCTA,
            action: createAndCollaborateLimitsActionCTA,
          },
        };
      }
      case ERROR_TYPE.CAMPAIGN_BRIEF_ITEM_CREATE_LIMIT_ERROR: {
        return {
          text: isUpgradeCTA ? upgradePlanText : salesTeamText,
          cta: {
            text: createAndCollaborateLimitsTextCTA,
            action: createAndCollaborateLimitsActionCTA,
          },
        };
      }
      default: {
        return {
          text: error && error.getMessage(),
          cta: {
            text: 'Ok',
            action: () => {
              onClose();
            },
          },
        };
      }
    }
  }, [
    error,
    currentOrg,
    currentOrgModel,
    currentPlan,
    isUpgradeCTA,
    createAndCollaborateLimitsActionCTA,
    createAndCollaborateLimitsTextCTA,
    manualType,
  ]);
};

export default useErrorLimitModalConfig;
