import React, { FC, useCallback, useMemo, useState } from 'react';
import styles from './ContentSection.module.scss';
import SectionWrapper from '../components/SectionWrapper';
import SectionEmptyState from '../components/SectionEmptyState';
import ContentHeaderWrapper from './components/ContentHeaderWrapper';
import InnerContent from './components/InnerContent';
import { DeliverablePermission } from '../../../../../../models/permissions/enum/DeliverablePermission';
import { useAppSelector } from '../../../../../../shared/hooks/reduxHooks';
import { DeliverablesReducerState } from '../../../../../../reducers/deliverables.reducer';
import { ConceptFlowType, ContentFlowType } from '../../../hooks/useDeliverableState';
import { AssetStateType } from '../../../../../../models/entities/asset/AssetState';
import { IAssetMedia } from '../../../../../../models/entities/asset/AssetMedia';
import BlurConfirmPopup from '../../../../components/BlurConfirmPopup';
import { EMPTY_CONTENT_STATE_TEXT_CONFIG } from '../constants/emptyStateTextsConfig';
import ButtonWithPurpleRoundedIcon from '../../../../../../components/Buttons/ButtonWithPurpleRoundedIcon';
import useGenerateContentWithAI from './useGenerateContentWithAI';
import usePlatformPlacementSettings from '../../../hooks/usePlatformPlacementSettings';

type PropsType = {
  contentFlow: ContentFlowType;
  conceptFlow: ConceptFlowType;
  openAsset: (asset: AssetStateType | IAssetMedia) => void;
};

const ContentSection: FC<PropsType> = ({ contentFlow, conceptFlow, openAsset }) => {
  const isPageLoading = useAppSelector<DeliverablesReducerState['deliverableLoading']>(state => state.deliverables.deliverableLoading);
  const pageModel = useAppSelector<DeliverablesReducerState['pageModel']>(state => state.deliverables.pageModel);

  const [isEditingMode, setIsEditingMode] = useState<boolean>(false);
  const [isOpenConfirmRemove, setIsOpenConfirmRemove] = useState<boolean>(false);
  const [isOpenConfirmCancel, setIsOpenConfirmCancel] = useState<boolean>(false);

  const isContentEmpty: boolean =
    !contentFlow.content.description && !contentFlow.content.call_to_action && !contentFlow.contentAssets.current?.length;

  const isDisplayEmptyState: boolean =
    (isContentEmpty && !isEditingMode && !isPageLoading) ||
    !!(pageModel?.roleCan(DeliverablePermission.DISPLAY_SECTION_EMPTY_STATE) && !contentFlow.content.isShared && !isPageLoading);

  const onOpenConfirmRemoveAccept = useCallback(() => {
    setIsOpenConfirmRemove(false);
    setIsEditingMode(false);
    contentFlow.content.removeContent();
  }, [contentFlow]);

  const onOpenConfirmRemoveDiscard = useCallback(() => {
    setIsOpenConfirmRemove(false);
  }, []);

  const onOpenConfirmCancelAccept = useCallback(() => {
    setIsOpenConfirmCancel(false);
    setIsEditingMode(false);
    contentFlow.contentEdit.restoreContent();
  }, [contentFlow]);

  const onOpenConfirmDiscard = useCallback(() => {
    setIsOpenConfirmCancel(false);
  }, []);

  const onEdit = () => {
    contentFlow.contentEdit.checkpointContent();
    setIsEditingMode(true);
  };

  const canRemove: boolean = useMemo(() => {
    return !!pageModel?.roleCan(DeliverablePermission.CAN_REMOVE_SECTION);
  }, [pageModel]);

  const onRemove = () => {
    setIsOpenConfirmRemove(true);
  };

  const onAddContentClick = () => {
    contentFlow.contentEdit.checkpointContent();
    setIsEditingMode(true);
  };

  return (
    <SectionWrapper>
      <div className={styles.root}>
        <ContentHeaderWrapper
          contentFlow={contentFlow}
          onEdit={onEdit}
          isDisplayQuestion={isEditingMode || !isContentEmpty}
          isDisplayEmptyState={isDisplayEmptyState}
          isEditingMode={isEditingMode}
          onRemove={canRemove ? onRemove : null}
        />

        {isDisplayEmptyState && !isPageLoading && (
          <SectionEmptyState
            onAddConcept={onAddContentClick}
            text={EMPTY_CONTENT_STATE_TEXT_CONFIG[pageModel?.role.type || ''] || ''}
            buttonText="Add Content"
          />
        )}

        {!isDisplayEmptyState && (
          <InnerContent
            isEditingMode={isEditingMode}
            contentFlow={contentFlow}
            conceptFlow={conceptFlow}
            openAsset={openAsset}
            setIsEditingMode={setIsEditingMode}
            // setIsOpenConfirmRemove={setIsOpenConfirmRemove}
            setIsOpenConfirmCancel={setIsOpenConfirmCancel}
            onEdit={onEdit}
            onRemove={canRemove ? onRemove : null}
          />
        )}
      </div>
      <BlurConfirmPopup
        isOpen={isOpenConfirmRemove}
        onConfirm={onOpenConfirmRemoveAccept}
        onDiscard={onOpenConfirmRemoveDiscard}
        scrollBackPosition={'start'}
        popUpText={'Hang on! Are you sure you want to remove the content? This will remove it for all the collaborators.'}
        primaryBtnText={'Yes, remove'}
        secondaryBtnText={'Discard'}
        scrollOnlyWhenViewPortLess
      />
      <BlurConfirmPopup
        isOpen={isOpenConfirmCancel}
        onConfirm={onOpenConfirmCancelAccept}
        onDiscard={onOpenConfirmDiscard}
        scrollBackPosition={'end'}
        popUpText={'All your changes will be lost. Are you sure you want to cancel?'}
        primaryBtnText={'Confirm'}
        secondaryBtnText={'Discard'}
        scrollOnlyWhenViewPortLess
      />
    </SectionWrapper>
  );
};

export default ContentSection;
