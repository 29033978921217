import React, { FC } from 'react';
import styles from './TextNavigationSection.module.scss';
import cs from 'classnames';
import { ITextConfig } from '../../types';
import NavigationItem from './components/NavigationItem';

type PropsType = {
  className?: string;
  textConfig: ITextConfig[];
  activeId: ITextConfig['id'];
  changeActiveId: (id: ITextConfig['id']) => void;
  isLoading: boolean;
};
const TextNavigationSection: FC<PropsType> = props => {
  return (
    <ul className={cs(props.className, styles.root)}>
      {props.textConfig.map(item => {
        if (!item.text?.trim().length && !props.isLoading) {
          return null;
        }
        return (
          <NavigationItem
            isLoading={props.isLoading}
            onClick={() => props.changeActiveId(item.id)}
            isActive={props.activeId === item.id}
            id={item.id}
            title={item.title}
            key={item.id}
          />
        );
      })}
    </ul>
  );
};

export default TextNavigationSection;
