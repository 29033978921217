import React, { ChangeEvent, FC, memo, useCallback } from 'react';
import styles from './TabCollaborators.module.scss';
import TextField from '../../../../../../../components/FormControls/TextField';
import { FormikProps } from 'formik';
import { ICreateBoardFormikValues } from '../../useCreateBoardFormik';
import OrganizationTypeDropdown from '../../../../../../../components/OrganizationTypeDropdown';
import EditorStyledField from '../../../../../../../components/EditorStyledField';
import { areEqualFormikState } from '../../../../../../../shared/utils/typescriptHelpers';
import useFormikDebouncedState from '../../../../../../../shared/hooks/useFormikDebouncedState';

type PropsType = {
  formik: FormikProps<ICreateBoardFormikValues>;
};

const propsAreEqual: Parameters<typeof memo<FC<PropsType>>>[1] = (prevProps, nextProps) => {
  return areEqualFormikState(
    ['inviteMessage', 'inviteEmail', 'initiatorOrganizationType', 'inviteOrganizationType'],
    prevProps.formik,
    nextProps.formik
  );
};

const TabCollaborators: FC<PropsType> = memo(({ formik }) => {
  const [inviteMessage, setInviteMessage] = useFormikDebouncedState<string>(
    (value: string) => formik.setFieldValue('inviteMessage', value),
    formik.values.inviteMessage
  );
  const [inviteEmail, setInviteEmail] = useFormikDebouncedState<string>(
    (value: string) => formik.setFieldValue('inviteEmail', value),
    formik.values.inviteEmail
  );

  const onToggleDropDown = useCallback(open => {
    if (open == null) return;
    const pageRoot = document.getElementById('create_board_page_root')!;

    pageRoot.style.overflow = open ? 'hidden' : 'auto';
  }, []);

  const onChangeInviteText = useCallback((text: string) => {
    setInviteMessage(text);
  }, []);

  const onChangeInviteEmail = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setInviteEmail(event.target.value.toLowerCase());
  }, []);

  return (
    <>
      <div>
        <h3>
          1.&nbsp;&nbsp;I am a <span>(Required)</span>
        </h3>
        <OrganizationTypeDropdown
          inputClassName={styles.organizationTypeInput}
          placeholderClassName={styles.organizationTypeInputPlaceHolder}
          onToggleDropDown={onToggleDropDown}
          formik={formik}
          fieldName="initiatorOrganizationType"
          error={formik.touched.initiatorOrganizationType && formik.errors.initiatorOrganizationType}
        />
      </div>
      <div>
        <h3>
          2.&nbsp;&nbsp;I want to collaborate with <span>(Optional)</span>
        </h3>
        <div className={styles.row}>
          <TextField
            name={'inviteEmail'}
            placeholder={'Type Email to invite'}
            className={styles.input}
            onChange={onChangeInviteEmail}
            onBlur={formik.handleBlur}
            value={inviteEmail?.trim()}
            error={formik.touched.inviteEmail ? formik.errors.inviteEmail : undefined}
          />
          <OrganizationTypeDropdown
            inputClassName={styles.organizationTypeInput}
            placeholderClassName={styles.organizationTypeInputPlaceHolder}
            onToggleDropDown={onToggleDropDown}
            formik={formik}
            fieldName="inviteOrganizationType"
            error={formik.touched.inviteOrganizationType && formik.errors.inviteOrganizationType}
          />
        </div>

        <div className={styles.infoText}>
          Want to add internal team members to your account?{' '}
          <span onClick={() => window.open('https://help.otterfish.com/knowledge/inviting-a-user-to-my-account', '_blank')}>
            Click here
          </span>
        </div>

        <EditorStyledField
          // @ts-ignore
          toolbarClassName={styles.editorToolbar}
          wrapperClassName={styles.editorWrapper}
          editorClassName={styles.editorInput}
          initialValue={inviteMessage}
          onChange={onChangeInviteText}
          placeholder="Hey there! Make sure your collaborator knows why you're inviting them by adding a message here, it will appear as a part of their invite email. You can also add any notes that may help in onboarding them to  your Content Board"
          onBlur={formik.handleBlur}
          error={!!formik.touched.inviteMessage ? formik.errors.inviteMessage : undefined}
        />
      </div>
    </>
  );
}, propsAreEqual);

export default TabCollaborators;
