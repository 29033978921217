/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';

export default ({ children, ...rest }) => {
  const location = useLocation();
  const isAuthenticated = useSelector(state => !!state.auth.token);
  const isCompleteSignUp = location.pathname.includes('/complete-signup');
  const isCompleteOrganization = location.pathname.includes('/complete-organization');

  return (
    <Route
      {...rest}
      render={() => (!isAuthenticated || isCompleteSignUp || isCompleteOrganization ? children : <Redirect to={'/content-approval'} />)}
    />
  );
};
