import { User } from '../users/User';
import { Page } from '../pages/Page';
import { IFlow } from '../flows/Flow';
import { DeliverablePermission } from '../enum/DeliverablePermission';
import { DeliverableItemPage } from '../pages/DeliverableItemPage';
import { AuthorizedUser } from '../users/AuthorizedUser';
import { Guest } from '../users/Guest';
import { DeliverableSharedFlow } from '../flows/deliverable/DeliverableSharedFlow';
import { DeliverableCCTMBBFlow } from '../flows/deliverable/DeliverableCCTMBBFlow';
import { DeliverableCCTMFlow } from '../flows/deliverable/DeliverableCCTMFlow';
import { DeliverableCCBBFlow } from '../flows/deliverable/DeliverableCCBBFlow';
import { COLLABORATOR_ROLE_TYPES } from '../collaborator/CollaboratorRole';
import { ContentBoardPage } from '../pages/ContentBoardPage';
import { ContentBoardSharedFlow } from '../flows/contentBoard/ContentBoardSharedFlow';
import { ContentBoardPermission } from '../enum/ContentBoardPermission';
import { DefaultBoardFlow } from '../flows/contentBoard/DefaultBoardFlow';

type CollaboratorType = {
  organization_id: number;
  type: COLLABORATOR_ROLE_TYPES;
};

export class PageModelService {
  userModel: User;

  constructor(userModel: User) {
    this.userModel = userModel;
  }

  getContentBoardPageModel(collaborators: CollaboratorType[]): Page<ContentBoardPermission> | null {
    if (this.userModel instanceof AuthorizedUser && this.userModel.organizationId) {
      const currentCollaborator = collaborators.find(c => c.organization_id === (this.userModel as AuthorizedUser).organizationId);
      if (currentCollaborator) {
        return new ContentBoardPage(currentCollaborator.type).applyFlow(new DefaultBoardFlow());
      }
    }

    if (this.userModel instanceof Guest) {
      return new ContentBoardPage(this.userModel.sharedData.payload.collaboratorRole).applyFlow(new ContentBoardSharedFlow());
    }

    return null;
  }

  getDeliverablePageModel(collaborators: CollaboratorType[]): Page<DeliverablePermission> | null {
    if (!collaborators?.length) {
      return null;
    }

    const flow = PageModelService.getCurrentDeliverablePageFlow(collaborators);

    if (this.userModel instanceof AuthorizedUser && this.userModel.organizationId) {
      const currentCollaborator = collaborators.find(c => c.organization_id === (this.userModel as AuthorizedUser).organizationId);
      if (currentCollaborator) {
        return new DeliverableItemPage(currentCollaborator.type).applyFlow(flow);
      }
    }

    if (this.userModel instanceof Guest) {
      return new DeliverableItemPage(this.userModel.sharedData.payload.collaboratorRole)
        .applyFlow(flow)
        .applyFlow(new DeliverableSharedFlow());
    }

    return null;
  }

  static getCurrentDeliverablePageFlow(collaborators: CollaboratorType[]): IFlow<DeliverablePermission> {
    const isCurrentFlowWithTalent =
      collaborators.find(c => c.type === COLLABORATOR_ROLE_TYPES.TALENT_MANAGER) &&
      collaborators.find(c => c.type === COLLABORATOR_ROLE_TYPES.CONTENT_CREATOR);

    if (!isCurrentFlowWithTalent) {
      return new DeliverableCCBBFlow();
    }

    const isBAExists = collaborators.find(c => c.type === COLLABORATOR_ROLE_TYPES.BRAND || c.type === COLLABORATOR_ROLE_TYPES.AGENCY);

    if (isBAExists) {
      return new DeliverableCCTMBBFlow();
    }
    return new DeliverableCCTMFlow();
  }
}
