import React, { CSSProperties, FC, PropsWithChildren, ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import cs from 'classnames';
import styles from './SingleDatePicker.module.scss';
import '../../customCalendarStyle.scss';
import CalendarWrapper, { MODALS_VERSION } from '../CalendarWrapper';
import { Calendar, CalendarProps } from 'react-multi-date-picker';
import { useClickOutside } from '../../../../../shared/hooks/useClickOutside';
import { useMediaQuery } from 'react-responsive/src';

type PropsType = {
  renderInputComponent: (value: { expanded: boolean; onClick: () => void }) => ReactNode | JSX.Element;
  modalVersion: MODALS_VERSION;
  date?: string | null;
  onCloseModal: () => void;
  onChangeDate: CalendarProps['onChange'];
  calendarMinDate?: string | null;
  calendarMaxDate?: string | null;
  mapCalendarDays: CalendarProps['mapDays'];
  expanded: boolean;
  setExpanded: (value: boolean | ((value: boolean) => boolean)) => void;
  maxModalWidth?: CSSProperties['maxWidth'];
  calendarRootClassName?: string;
  calendarClassName?: string;
} & PropsWithChildren;

const SingleDatePicker: FC<PropsType> = props => {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const dataPickerRef = useRef<HTMLDivElement | null>(null);
  const closeModal = useCallback(() => props.setExpanded(false), []);
  const isNotebook = useMediaQuery({ query: '(max-width: 1200px)' });

  const onClickOutside = useCallback(() => {
    if (props.modalVersion === MODALS_VERSION.ONLY_MODAL || isNotebook) return;
    closeModal();
  }, [props.modalVersion, isNotebook, closeModal]);

  useClickOutside(rootRef, rootRef, onClickOutside);

  useEffect(() => {
    if (dataPickerRef.current) {
      if (dataPickerRef.current?.getBoundingClientRect().left < -500) {
        dataPickerRef.current.style.right = dataPickerRef.current?.getBoundingClientRect().left - 20 + 'px';
      }
      if (dataPickerRef.current?.getBoundingClientRect().right > 450) {
        dataPickerRef.current.style.right = '0';
      } else {
        dataPickerRef.current.style.left = '0';
      }
    }
  }, [props.expanded]);

  const calendarMaxDate = useMemo(() => (props.calendarMaxDate ? new Date(props.calendarMaxDate) : undefined), [props.calendarMaxDate]);
  const calendarMinDate = useMemo(() => (props.calendarMinDate ? new Date(props.calendarMinDate) : undefined), [props.calendarMinDate]);
  const calendarDate = useMemo(() => (props.date ? new Date(props.date) : undefined), [props.date]);

  return (
    <div className={cs(styles.root)} ref={rootRef}>
      {props.renderInputComponent({
        expanded: props.expanded,
        onClick: () => props.setExpanded(prev => !prev),
      })}
      {props.expanded && (
        <CalendarWrapper
          maxWidth={props.maxModalWidth}
          modalVersion={props.modalVersion}
          expanded={props.expanded}
          onCloseModal={props.onCloseModal}
        >
          <div
            className={cs(
              styles.calendarRoot,
              { [styles.desktopCalendarModalRoot]: props.modalVersion === MODALS_VERSION.ONLY_MODAL && !isNotebook },
              props.calendarRootClassName
            )}
            ref={dataPickerRef}
          >
            <Calendar
              onChange={props.onChangeDate}
              minDate={calendarMinDate}
              maxDate={calendarMaxDate}
              mapDays={props.mapCalendarDays}
              value={calendarDate}
              className={cs(styles.calendar, 'custom-react-multi-date-picker', props.calendarClassName)}
            >
              {props.children}
            </Calendar>
          </div>
        </CalendarWrapper>
      )}
    </div>
  );
};

export default SingleDatePicker;
