import { IViewBriefData } from '../ViewBrief';
import { ICampaignDTO } from '../dto/Campaign';
import { IBriefItemDTO } from '../dto/BriefItem';
import { PLATFORMS } from '../../../enums/Platforms';
import { ITargetAudience } from '../TargetAudience';
import { CURRENCIES_TYPES } from '../../../enums/Currencies';
import { PREFERRED_ADD_DECLARATION_TYPES } from '../enums/AddDeclarationsTypes';

export class ViewBriefHydrator implements IViewBriefData {
  id: number;
  briefItemId: number;

  type: 'content_board_brief' | 'creator_brief';

  name: string | null;
  brand_name: string | null;
  brand_description: string | null;
  brand_logo: string | null;

  brand_provides_product: string | null;

  item_name: string | null;
  objective: string[] | null;

  date_start: string | null;
  date_end: string | null;
  live_date_start: string | null;
  live_date_end: string | null;

  suggested_creators: string | null;
  suggested_creators_description: string | null;

  reposting_platform: PLATFORMS[] | null;
  reposting_duration: number | null;

  target_audience: ITargetAudience | null;

  hashtags: null | string[];
  social_tagging: null | string[];

  total_budget_currency: CURRENCIES_TYPES | null;
  total_budget: number | null;
  boosting_budget_currency: CURRENCIES_TYPES | null;
  boosting_budget: number | null;
  preferred_ad_declaration: PREFERRED_ADD_DECLARATION_TYPES | null;

  boosting_manager: string[] | null;
  boosting_duration: number | null;

  notes: string | null;
  exclusivity: string | null;
  key_message: string | null;
  other_infos: string | null;

  constructor(boardBrief: ICampaignDTO, boardBriefItem: IBriefItemDTO) {
    this.id = boardBrief.id;
    this.briefItemId = boardBriefItem.id;

    this.type = boardBriefItem.type;

    this.name = boardBriefItem.name;
    this.brand_name = boardBrief.brand_name;
    this.brand_description = boardBrief.brand_description;
    this.brand_logo = boardBrief.brand_logo_thumbnail_location || boardBrief.brand_logo_location;

    this.brand_provides_product = boardBrief.brand_provides_product;
    this.item_name = boardBriefItem.name;

    this.objective = boardBriefItem.objective;

    this.date_start = boardBriefItem.date_start;
    this.date_end = boardBriefItem.date_end;
    this.live_date_start = boardBriefItem.live_date_start;
    this.live_date_end = boardBriefItem.live_date_end;

    this.suggested_creators = boardBriefItem.suggested_creators;
    this.suggested_creators_description = boardBriefItem.suggested_creators_description;

    this.reposting_platform = boardBriefItem.reposting_platform;
    this.reposting_duration = boardBriefItem.reposting_duration;

    this.target_audience = boardBriefItem.target_audience;

    this.hashtags = boardBriefItem.hashtags;
    this.social_tagging = boardBriefItem.social_tagging;

    this.total_budget_currency = boardBriefItem.total_budget_currency;
    this.total_budget = boardBriefItem.total_budget;
    this.boosting_budget_currency = boardBriefItem.boosting_budget_currency;
    this.boosting_budget = boardBriefItem.boosting_budget;

    this.preferred_ad_declaration = boardBriefItem.preferred_ad_declaration;

    this.boosting_manager = boardBriefItem.boosting_manager;
    this.boosting_duration = boardBriefItem.boosting_duration;

    this.notes = boardBrief.notes;
    this.exclusivity = boardBriefItem.exclusivity;
    this.key_message = boardBriefItem.key_message;
    this.other_infos = boardBriefItem.other_infos;
  }
}
