import React, { FC } from 'react';
import styles from './BrandDetailsEmptyState.module.scss';
import ButtonLightPurpleWhite from '../../../../../../../components/Buttons/ButtonLightPurpleWhite';
import { ITextConfig } from '../../types';

type PropsType = {
  textConfig: ITextConfig[];
  isEditDisplay: boolean;
  onEdit: () => void;
};
const BrandDetailsEmptyState: FC<PropsType> = props => {
  return (
    <div className={styles.root}>
      <ul className={styles.headingList}>
        {props.textConfig.map(item => (
          <li key={item.id} className={styles.headingItem}>
            <div className={styles.dot} />
            {item.title}
          </li>
        ))}
      </ul>
      <h3 className={styles.title}>No Brand messaging and terms yet.</h3>
      <p className={styles.descriptionText}>
        You have no messaging and terms attached to this brief yet. Click Edit Brief to add anything you need
      </p>
      {!!props.onEdit && props.isEditDisplay && (
        <ButtonLightPurpleWhite designVersion={'v2'} presetIcon={'editPencil'} onClick={props.onEdit}>
          Edit Brief
        </ButtonLightPurpleWhite>
      )}
    </div>
  );
};

export default BrandDetailsEmptyState;
