import React from 'react';
import styles from './CheckboxButton.module.scss';
import cs from 'classnames';

function CheckboxButton({ onClick, item, isActive, className, wrapperClassName = '', paragraph }) {
  return (
    <div onClick={onClick} className={cs(styles.wrapper, wrapperClassName)}>
      <h3 className={cs(styles.checkboxButton, { [styles.active]: isActive }, { [className]: className })}>{item}</h3>

      {paragraph && <p className={styles.radioParagraph}>{paragraph}</p>}
    </div>
  );
}

export default CheckboxButton;
