import { IBoardCollaborator } from '../BoardCollaborator';
import { ORGANIZATION_TYPES } from '../../../enums/OrganizationTypes';
import { COLLABORATOR_ROLE_TYPES } from '../../../permissions/collaborator/CollaboratorRole';
import { IBoardCollaboratorDTO } from '../dto/BoardCollaborator';

export class BoardCollaboratorHydrator implements IBoardCollaborator {
  approver_first_name: string;
  approver_id: string;
  approver_last_name: string;
  content_board_id: number;
  created_at: string;
  creator_id: string;
  email: string;
  is_archived: boolean;
  organization_id: number;
  organization_name: string;
  organization_type: ORGANIZATION_TYPES;
  type: COLLABORATOR_ROLE_TYPES;
  updated_at: null | string;
  updater_id: null | string;

  constructor(dto: IBoardCollaboratorDTO) {
    this.approver_first_name = dto.approver_first_name;
    this.approver_id = dto.approver_id;
    this.approver_last_name = dto.approver_last_name;
    this.content_board_id = dto.content_board_id;
    this.created_at = dto.created_at;
    this.creator_id = dto.creator_id;
    this.email = dto.email;
    this.is_archived = dto.is_archived;
    this.organization_id = dto.organization_id;
    this.organization_name = dto.organization_name;
    this.organization_type = dto.organization_type;
    this.type = dto.type;
    this.updated_at = dto.updated_at;
    this.updater_id = dto.updater_id;
  }
}
