import React, { FC } from 'react';
import styles from './DeliverablePlatform.module.scss';
import FacebookSVG from '../../../IconsSvg/FacebookSVG';
import InstagramSVG from '../../../IconsSvg/InstagramSVG';
import cs from 'classnames';
import { FaYoutube } from 'react-icons/fa';
import { SiTiktok } from 'react-icons/si';
import { PLATFORMS } from '../../../../models/enums/Platforms';
import BlogPostSVG from '../../../IconsSvg/BlogPostSVG';

type PropsType = {
  platform?: PLATFORMS | null;
  isTablet?: boolean;
  className?: string;
  withoutText?: boolean;
  customText?: string | null;
};

const DeliverablePlatform: FC<PropsType> = ({ platform, isTablet, className, withoutText = false, customText }) => {
  const getPlatformContent = () => {
    switch (platform) {
      case PLATFORMS.FACEBOOK:
        return (
          <>
            <FacebookSVG />
            {!isTablet && !withoutText && (customText || 'Facebook')}
          </>
        );
      case PLATFORMS.INSTAGRAM:
        return (
          <>
            <InstagramSVG />
            {!isTablet && !withoutText && (customText || 'Instagram')}
          </>
        );
      case PLATFORMS.YOUTUBE:
        return (
          <>
            <FaYoutube className={styles.youtube} />
            {!isTablet && !withoutText && (customText || 'Youtube')}
          </>
        );
      case PLATFORMS.TIKTOK:
        return (
          <>
            <SiTiktok />
            {!isTablet && !withoutText && (customText || 'Tiktok')}
          </>
        );
      case PLATFORMS.BLOG_POST:
        return (
          <>
            <BlogPostSVG />
            {!isTablet && !withoutText && (customText || 'Blog post')}
          </>
        );
      default:
        return <span className={styles.gray}>No platform</span>;
    }
  };

  return <div className={cs(styles.root, className)}>{getPlatformContent()}</div>;
};

export default DeliverablePlatform;
