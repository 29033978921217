import React, { FC, memo, useEffect } from 'react';
import { FormikProps } from 'formik';
import { areEqualFormikState } from '../../../../../../../../shared/utils/typescriptHelpers';
import styles from '../TabBriefDetails.module.scss';
import CalendarDropdown from '../../../../../../../../expert/components/FormControls/CalendarDropdown';
import CoolIcon from '../../../../../../../../components/IconsSvg/CoolIconSVG';
import Dropdown from '../../../../../../../../components/FormControls/Dropdown';
import Option from '../../../../../../../CampaignBrief/components/Option';
import TextField from '../../../../../../../../components/FormControls/TextField';
import CheckboxButton from '../../../../../../../CampaignBrief/components/CheckboxButton';
import RadioButtons from '../../../../../../../CampaignBrief/components/RadioButtons';
import { useMediaQuery } from 'react-responsive/src';
import { MODALS_VERSION } from '../../../../../../../../components/BriefsAndBoardsFlows/LiveDueDate/components/CalendarWrapper';
import SingleDateCalendar from '../../../../../../../../components/calendars/SingleDateCalendar';
import moment from 'moment';
import { dateWithoutTimeFormat } from '../../../../../../../../components/BriefsAndBoardsFlows/LiveDueDate/constants/timeFormats';

const manageBoostingValues = [
  {
    key: 'brand/agency',
    value: ['brand', 'agency'],
    title: 'Brand/Agency',
  },
  {
    key: 'talent_agency/content_creator',
    value: ['talent_agency', 'content_creator'],
    title: 'Talent agency/Content creator',
  },
];

enum Categories {
  AUD = 'AUD',
  CAD = 'CAD',
  EUR = 'EUR',
  USD = 'USD',
}

type PropsType = {
  formik: FormikProps<any>;
};

const propsAreEqual: Parameters<typeof memo<FC<PropsType>>>[1] = (prevProps, nextProps) => {
  return areEqualFormikState(
    [
      'date_start',
      'date_end',
      'total_budget_currency',
      'total_budget',
      'showBoosting',
      'boosting_manager',
      'boosting_duration',
      'boosting_budget',
      'date_post',
    ],
    prevProps.formik,
    nextProps.formik
  );
};

const SubTabBudgetAndDuration = memo(({ formik }: PropsType) => {
  useEffect(() => {
    const isShow =
      !!formik.values.boosting_manager.length ||
      !!formik.values.boosting_duration ||
      !!formik.values.boosting_budget ||
      !!formik.values.live_date_start ||
      !!formik.values.live_date_end;
    formik.setFieldValue('showBoosting', isShow);
  }, [
    formik.values.boosting_manager,
    formik.values.boosting_duration,
    formik.values.boosting_budget,
    formik.values.live_date_start,
    formik.values.live_date_end,
  ]);

  useEffect(() => {
    if (formik.values.showBoosting) {
      formik.setFieldValue('boosting_manager', ['brand', 'agency']);
    }
  }, [formik.values.showBoosting]);

  const setBoostingBlock = () => {
    formik.setFieldValue('showBoosting', true);
  };

  const removeBoostingBlock = () => {
    formik.setFieldValue('showBoosting', false);
    formik.setFieldValue('boosting_manager', []);
    formik.setFieldValue('boosting_duration', undefined);
    formik.setFieldValue('boosting_budget', undefined);
  };

  const isMobile: boolean = useMediaQuery({ query: '(max-width: 576px)' });

  const setManageBoosting = item => {
    formik.setFieldValue('boosting_manager', item.value);
  };

  const onChangeBudget = e => {
    if (e.target.value >= 0) {
      e.target.value = Math.trunc(e.target.value);
      if (e.target.value > 1000000000) {
        e.target.value = 1000000000;
      }
      return formik.handleChange(e);
    }
    e.target.value = 0;
    return formik.handleChange(e);
  };

  return (
    <div className={styles.root}>
      <h2 style={{ marginBottom: 0 }}>Budget and duration</h2>
      <div>
        <h3>Campaign Duration</h3>
        <div className={styles.campaignDurationBlock}>
          <div className={styles.dateFieldWrapper}>
            <SingleDateCalendar
              date={formik.values.date_start}
              // @ts-ignore
              renderInputComponent={({ onClick }) => <CalendarDropdown withIcon date={formik.values.date_start} onClick={onClick} />}
              calendarMaxDate={moment.utc(formik.values.date_end).subtract(1, 'day').format(dateWithoutTimeFormat)}
              modalVersion={MODALS_VERSION.HYBRID}
              calendarRootClassName={styles.datePicker}
              onChangeDate={date => formik.setFieldValue('date_start', date)}
              parseToFormat={'YYYY-MM-DD'}
            />
          </div>
          <div className={styles.calendarArrowWrapper}>
            <CoolIcon />
          </div>
          <div className={styles.dateFieldWrapper}>
            <SingleDateCalendar
              date={formik.values.date_end}
              // @ts-ignore
              renderInputComponent={({ onClick }) => <CalendarDropdown withIcon date={formik.values.date_end} onClick={onClick} />}
              calendarMinDate={moment.utc(formik.values.date_start).add(1, 'day').format(dateWithoutTimeFormat)}
              modalVersion={MODALS_VERSION.HYBRID}
              calendarRootClassName={styles.datePicker}
              onChangeDate={date => formik.setFieldValue('date_end', date)}
              parseToFormat={'YYYY-MM-DD'}
            />
          </div>
        </div>
      </div>

      <div>
        <h3>What is your campaign budget?</h3>
        <p>This is the budget that you have set aside for the content creator to work on your campaign deliverables</p>
        <div className={styles.budgetBlock}>
          {/* @ts-ignore */}
          <Dropdown
            noValuePlaceholder={'NZD'}
            className={styles.dropdown}
            items={Object.values(Categories)}
            getId={i => i}
            getDisplayValue={i => i}
            placeholder="Select an item"
            renderItem={i => <Option value={i} />}
            selectedItem={formik.values.total_budget_currency}
            onSelect={i => formik.setFieldValue('total_budget_currency', i)}
            noValueAvailable
          />
          <div className={styles.budgetInputWrapper}>
            <TextField
              name={'total_budget'}
              type={'number'}
              min={0}
              placeholder={'0'}
              onChange={e => {
                onChangeBudget(e);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.total_budget || ''}
              className={styles.budgetInput}
            />
          </div>
        </div>
      </div>

      <div className={styles.boostingSection}>
        <h3 style={{ margin: 0 }}>Do you need boosting?</h3>
        <div className={styles.checkboxBlock}>
          {/* @ts-ignore */}
          <CheckboxButton
            onClick={setBoostingBlock}
            isActive={formik.values.showBoosting}
            item={'Yes'}
            className={styles.checkboxHeading}
          />
          {/* @ts-ignore */}
          <CheckboxButton
            onClick={removeBoostingBlock}
            isActive={!formik.values.showBoosting}
            item={'No'}
            className={styles.checkboxHeading}
          />
        </div>
        {formik.values.showBoosting && (
          <div className={styles.showBoostingSection}>
            <div className={styles.showBoostingSubSection}>
              <h3>Who will manage your boosting?</h3>
              <RadioButtons
                items={manageBoostingValues}
                activeItems={[formik.values.boosting_manager.join('/')]}
                setItem={setManageBoosting}
                className={styles.manageBoosting}
              />
            </div>

            <div className={styles.showBoostingSubSection}>
              <h3>What is your boosting duration?</h3>
              <div className={styles.boostingDuration}>
                <TextField
                  name={'boosting_duration'}
                  type={'number'}
                  min={1}
                  placeholder={'0'}
                  onChange={e => {
                    onChangeBudget(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.boosting_duration || ''}
                  // @ts-ignore
                  error={formik.errors.boosting_duration}
                  wrapperClassName={styles.boosting}
                />
                <span style={{ lineHeight: '40px' }}>Weeks</span>
              </div>
            </div>

            <div className={styles.showBoostingSubSection}>
              <h3>What is your boosting budget?</h3>
              <p>This is money you’d like to allocate across all the platforms to boost the content produced</p>
              <div className={styles.budgetBlock}>
                {/* @ts-ignore */}
                <Dropdown
                  noValuePlaceholder={'NZD'}
                  className={styles.dropdown}
                  items={Object.values(Categories)}
                  getId={i => i}
                  getDisplayValue={i => i}
                  placeholder="Select an item"
                  renderItem={i => <Option value={i} />}
                  selectedItem={formik.values.total_budget_currency} // intended same key to change both dropdowns at once
                  onSelect={i => formik.setFieldValue('total_budget_currency', i)}
                  noValueAvailable
                />
                <div className={styles.budgetInputWrapper}>
                  <TextField
                    name={'boosting_budget'}
                    type={'number'}
                    min={1}
                    placeholder={'0'}
                    onChange={e => {
                      onChangeBudget(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.boosting_budget || ''}
                    // @ts-ignore
                    error={formik.errors.boosting_budget}
                    className={styles.budgetInput}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={styles.liveDataSection}>
          <h3>What is a tentative go live date for the first content?</h3>
          <div className={styles.dateFieldWrapper} style={{ maxWidth: isMobile ? '100%' : 180, width: '100%', position: 'relative' }}>
            <SingleDateCalendar
              date={formik.values.date_post}
              // @ts-ignore
              renderInputComponent={({ onClick }) => <CalendarDropdown withIcon date={formik.values.date_post} onClick={onClick} />}
              modalVersion={MODALS_VERSION.HYBRID}
              calendarRootClassName={styles.datePicker}
              onChangeDate={date => formik.setFieldValue('date_post', date)}
              parseToFormat={'YYYY-MM-DD'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}, propsAreEqual);

export default SubTabBudgetAndDuration;
