import React, { FC, memo, useMemo, useState } from 'react';
import styles from './AddDeliverablesPlacement.module.scss';
import PlusSVG from '../../../../../../../expert/components/IconsSvg/PlusSVG';
import { getFormattedPlacement } from '../../../../../../../shared/utils/helpers';
import AddDeliverablesPlacementCell from '../AddDeliverablesPlacementCell';
import cs from 'classnames';
import CheckmarkSVG from '../../../../../../../components/IconsSvg/CheckmarkSVG';
import { FormikErrors, FormikProps, FormikTouched } from 'formik';
import {
  DeliverableFormikStateType,
  DeliverablesFormSettingsDataType,
  IDeliverableFormData,
  UseDeliverablesFormHandlersType,
} from '../../hooks/useDeliverablesFormData';

type PropsType = {
  handlers: UseDeliverablesFormHandlersType;
  formik: FormikProps<DeliverableFormikStateType>;
  platformName: keyof DeliverablesFormSettingsDataType;
  placementsName: DeliverablesFormSettingsDataType[keyof DeliverablesFormSettingsDataType][number];
  canEditDueDates: boolean;
  canEditNotifications: boolean;
};

const AddDeliverablesPlacement: FC<PropsType> = memo(
  ({ formik, platformName, placementsName, handlers, canEditDueDates, canEditNotifications }) => {
    const { currentDeliverablesErrors, currentDeliverablesTouched, currentDeliverables } = useMemo(() => {
      const currentDeliverablesErrors: (FormikErrors<IDeliverableFormData> | null)[] = [];
      const currentDeliverablesTouched: (FormikTouched<IDeliverableFormData> | null)[] = [];
      const currentDeliverables: IDeliverableFormData[] = [];

      formik.values.deliverables.forEach((deliverable, index) => {
        const isCurrentPlatformAndPlacement = deliverable.placement === placementsName && deliverable.platform === platformName;

        const currentError = formik.errors.deliverables?.[index];

        const changedCurrentError =
          typeof currentError === 'string' || !currentError || !Object.keys(currentError).length ? null : currentError;

        if (!isCurrentPlatformAndPlacement) return;
        currentDeliverables.push(deliverable);
        currentDeliverablesErrors.push(changedCurrentError);
        currentDeliverablesTouched.push(formik.touched?.deliverables?.[index] ?? null);
      });

      return {
        currentDeliverablesErrors,
        currentDeliverablesTouched,
        currentDeliverables,
      };
    }, [formik, platformName, placementsName]);

    const [isOpen, setIsOpen] = useState<boolean>(!!currentDeliverables.length);

    const deleteAllPlacementDeliverables = () => {
      handlers.deleteAllPlacementDeliverables(platformName, placementsName);
      setIsOpen(false);
    };

    const updatedHandlers = useMemo(() => {
      const deleteDeliverable = (...args: Parameters<typeof handlers.deleteDeliverable>) => {
        handlers.deleteDeliverable(...args);
        if (currentDeliverables.length === 1) {
          setIsOpen(false);
        }
      };

      return {
        ...handlers,
        deleteDeliverable,
      };
    }, [currentDeliverables, isOpen, handlers]);

    const onRootClick = () => {
      if (!isOpen) {
        setIsOpen(true);
        handlers.createDeliverable(platformName, placementsName);
      }
    };

    return (
      <div style={{ display: 'grid' }}>
        <div className={cs(styles.root, { [styles.activeRoot]: isOpen })} onClick={onRootClick}>
          <div className={styles.leftSideWrapper}>
            <div className={cs({ [styles.plusIconWrapper]: !isOpen, [styles.checkedIconWrapper]: isOpen })}>
              {isOpen ? <CheckmarkSVG /> : <PlusSVG fill={undefined} />}
            </div>
            <div className={styles.postTitle}>{getFormattedPlacement(placementsName)}</div>
            {!!currentDeliverables.length && isOpen && <div className={styles.deliverablesCount}>{currentDeliverables.length}</div>}
          </div>
          <div className={styles.rightSideWrapper}>
            {isOpen && !!currentDeliverables.length && (
              <div className={styles.deleteAllButton} onClick={deleteAllPlacementDeliverables}>
                Delete all
              </div>
            )}
          </div>
        </div>
        {isOpen && (
          <AddDeliverablesPlacementCell
            canEditNotifications={canEditNotifications}
            canEditDueDates={canEditDueDates}
            platformName={platformName}
            placementsName={placementsName}
            currentDeliverables={currentDeliverables}
            deliverableErrors={currentDeliverablesErrors}
            deliverableTouched={currentDeliverablesTouched}
            handlers={updatedHandlers}
          />
        )}
      </div>
    );
  }
);

export default AddDeliverablesPlacement;
