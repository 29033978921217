/* eslint-disable camelcase */
import * as userServices from '../services/users';
import { getInvitedMembers } from './organizations.actions';
import { me, signOut } from './auth.actions';
import CustomError from '../models/entities/error/CustomError';
import ErrorHandler from '../models/entities/error/ErrorHandler';

export const USER_TYPES = {
  GET_USER: {
    REQUEST: 'GET_USER_REQUEST',
    SUCCESS: 'GET_USER_SUCCESS',
    FAILURE: 'GET_USER_FAILURE',
  },
  UPDATE_USER: {
    REQUEST: 'UPDATE_USER_REQUEST',
    SUCCESS: 'UPDATE_USER_SUCCESS',
    FAILURE: 'UPDATE_USER_FAILURE',
  },
  DELETE_USER_PHOTO: {
    REQUEST: 'DELETE_USER_PHOTO_REQUEST',
    SUCCESS: 'DELETE_USER_PHOTO_SUCCESS',
    FAILURE: 'DELETE_USER_PHOTO_FAILURE',
  },
  INVITE_MEMBER: {
    REQUEST: 'INVITE_MEMBER_REQUEST',
    SUCCESS: 'INVITE_MEMBER_SUCCESS',
    FAILURE: 'INVITE_MEMBER_FAILURE',
  },
  INVITE_CONFIRM: {
    REQUEST: 'INVITE_CONFIRM_REQUEST',
    SUCCESS: 'INVITE_CONFIRM_SUCCESS',
    FAILURE: 'INVITE_CONFIRM_FAILURE',
  },
  GET_USERS: {
    REQUEST: 'GET_USERS_REQUEST',
    SUCCESS: 'GET_USERS_SUCCESS',
    FAILURE: 'GET_USERS_FAILURE',
  },
  GET_ORGANIZATIONS_USERS: {
    SUCCESS: 'GET_ORGANIZATIONS_USERS_SUCCESS',
  },
  GET_INVITE_DETAILS: {
    REQUEST: 'GET_INVITE_DETAILS_REQUEST',
    SUCCESS: 'GET_INVITE_DETAILS_SUCCESS',
    FAILURE: 'GET_INVITE_DETAILS_FAILURE',
  },
  ACCEPT_INVITE: {
    REQUEST: 'ACCEPT_INVITE_REQUEST',
    SUCCESS: 'ACCEPT_INVITE_SUCCESS',
    FAILURE: 'ACCEPT_INVITE_FAILURE',
  },
  GET_PRICING_PLAN: {
    REQUEST: 'GET_PRICING_PLAN_REQUEST',
    SUCCESS: 'GET_PRICING_PLAN_SUCCESS',
    FAILURE: 'GET_PRICING_PLAN_FAILURE',
  },
  UPDATE_PRICING_PLAN: {
    REQUEST: 'UPDATE_PRICING_PLAN_REQUEST',
    SUCCESS: 'UPDATE_PRICING_PLAN_SUCCESS',
    FAILURE: 'UPDATE_PRICING_PLAN_FAILURE',
  },
  GET_USER_USAGE: {
    REQUEST: 'GET_USER_USAGE_REQUEST',
    SUCCESS: 'GET_USER_USAGE_SUCCESS',
    FAILURE: 'GET_USER_USAGE_FAILURE',
  },
  CLEAR_FAILURE: 'CLEAR_FAILURE',
  DELETE_MARK_AS_NEW: {
    SUCCESS: 'DELETE_MARK_AS_NEW_SUCCESS',
  },
};

export const getUser = id => async dispatch => {
  try {
    dispatch({
      type: USER_TYPES.GET_USER.REQUEST,
    });

    const res = await userServices.getUsers(id);

    dispatch({
      type: USER_TYPES.GET_USER.SUCCESS,
      payload: res.data[0],
    });
    return res.data[0];
  } catch (error) {
    dispatch({
      type: USER_TYPES.GET_USER.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getUsers = () => async dispatch => {
  try {
    dispatch({
      type: USER_TYPES.GET_USERS.REQUEST,
    });

    const res = await userServices.getUsers();

    dispatch({
      type: USER_TYPES.GET_USERS.SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: USER_TYPES.GET_USERS.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getOrganizationUsers = organizationID => dispatch => {
  userServices.getOrganizationUsers(organizationID).then(response => {
    dispatch({ type: USER_TYPES.GET_ORGANIZATIONS_USERS.SUCCESS, payload: response.data });
  });
};

export const patchUser = (id, data) => async dispatch => {
  try {
    dispatch({
      type: USER_TYPES.UPDATE_USER.REQUEST,
    });

    const formData = new FormData();

    for (const prop in data) {
      formData.append(prop, data[prop]);
    }

    const response = await userServices.updateUser(id, formData);

    dispatch({
      type: USER_TYPES.UPDATE_USER.SUCCESS,
      payload: response.data[0],
    });
    return response.data[0];
  } catch (error) {
    dispatch({
      type: USER_TYPES.UPDATE_USER.FAILURE,
      payload: error.response.data,
    });
  }
};

export const updateUser = (id, data) => async dispatch => {
  try {
    dispatch({
      type: USER_TYPES.UPDATE_USER.REQUEST,
    });

    const formData = new FormData();
    if (data.file && data.fileName) {
      const blob = await fetch(data.file).then(r => r.blob());
      formData.append('photo', blob, data.fileName);
    }
    formData.append('email', data.email);

    if (data.firstName) formData.append('first_name', data.firstName);
    if (data.lastName) formData.append('last_name', data.lastName);
    if (data.phoneNumber) formData.append('phone_number', data.phoneNumber);
    if (data.birth_date) formData.append('birth_date', data.birth_date);
    if (data.gen) formData.append('gender', data.gen);
    if (data.timezone_id) formData.append('timezone_id', data.timezone_id);
    if (data.newPassword) formData.append('password', data.newPassword);

    const response = await userServices.updateUser(id, formData);
    dispatch({
      type: USER_TYPES.UPDATE_USER.SUCCESS,
      payload: response.data[0],
    });
    return response.data[0];
  } catch (error) {
    dispatch({
      type: USER_TYPES.UPDATE_USER.FAILURE,
      payload: error.response.data,
    });
  }
};

export const removeProfilePicture = id => async dispatch => {
  try {
    dispatch({
      type: USER_TYPES.DELETE_USER_PHOTO.REQUEST,
    });

    const response = await userServices.removeUserPhoto(id);
    dispatch({
      type: USER_TYPES.DELETE_USER_PHOTO.SUCCESS,
      payload: response.data[0],
    });
    return response.data[0];
  } catch (error) {
    dispatch({
      type: USER_TYPES.DELETE_USER_PHOTO.FAILURE,
      payload: error.response.data,
    });
  }
};

export const inviteMember = members => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_TYPES.INVITE_MEMBER.REQUEST,
    });
    const currentOrg = getState().auth.currentOrganization; // JSON.parse(localStorage.getItem('OTTERFISH_CURRENT_ORGANIZATION'));

    const inviteData = {
      organization_id: currentOrg?.organization_id,
      emails: members,
    };
    const data = await userServices.inviteMember(inviteData);

    dispatch(getInvitedMembers(currentOrg.organization_id));
    dispatch({
      type: USER_TYPES.INVITE_MEMBER.SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: USER_TYPES.INVITE_MEMBER.FAILURE,
      payload: error.response.data,
    });
  }
};

export const confirmSignUp = (member_data, isNewMember, inviteType) => async (dispatch, getState) => {
  const org = getState().auth.currentOrganization;
  try {
    dispatch({
      type: USER_TYPES.INVITE_CONFIRM.REQUEST,
    });

    dispatch(signOut());

    const data = await userServices.inviteConfirm(member_data);
    localStorage.setItem('OTTERFISH_ACCESS_TOKEN', JSON.stringify(data.data[0]));
    localStorage.setItem('OTTERFISH_ACCOUNT_MANAGER', JSON.stringify({ isAccountManager: false }));
    localStorage.setItem('OTTERFISH_SEGMENT_IDENTIFY', 'YES');

    let userType = 'Collaborator';
    if (inviteType === 'organization_user') {
      userType = 'Member';
    }

    window.analytics.track('user_registered', {
      category: 'User Signup',
      label: userType,
      userId: data.data[0].user_id,
      user_name: `${member_data.first_name} ${member_data.last_name}`,
      ...(member_data &&
        inviteType !== 'organization_user' && {
          org_name: member_data.organization_name,
          company_type: member_data.organization_type,
        }),
      ...(org &&
        inviteType === 'organization_user' && {
          org_name: org.organization_name,
          company_type: org.organization_type,
        }),
      ...(inviteType === 'campaign_brief_item' &&
        data.data[0].parent_id === 0 && { campaign_brief_id: data.data[0].campaign_brief_item_id }),
      ...(inviteType === 'campaign_brief_item' &&
        data.data[0].parent_id !== 0 && { creator_brief_id: data.data[0].campaign_brief_item_id }),
      ...(inviteType === 'content_board' && { content_board_id: data.data[0].content_board_id }),
      email: member_data.email,
      marketing_accepted: member_data.marketing_consent,
      phone_number: member_data.phone_number,
    });

    await dispatch(me());

    dispatch({
      type: USER_TYPES.INVITE_CONFIRM.SUCCESS,
      payload: data.data[0],
      isNewMember: isNewMember,
    });
    return data.data[0];
  } catch (error) {
    dispatch(signOut());
    dispatch({
      type: USER_TYPES.INVITE_CONFIRM.FAILURE,
      payload: error.response.data,
    });
  }
};

export const deleteMarkAsNew = () => async dispatch => {
  dispatch({
    type: USER_TYPES.DELETE_MARK_AS_NEW.SUCCESS,
  });
};

export const getInviteDetails = (inviteToken, inviteType) => async dispatch => {
  try {
    dispatch({
      type: USER_TYPES.GET_INVITE_DETAILS.REQUEST,
    });

    const data = await userServices.getInviteDetails(inviteToken, inviteType);

    dispatch({
      type: USER_TYPES.GET_INVITE_DETAILS.SUCCESS,
      payload: data.data[0],
    });
    return data.data[0];
  } catch (error) {
    dispatch({
      type: USER_TYPES.GET_INVITE_DETAILS.FAILURE,
      payload: error.response.data,
    });
  }
};

export const acceptInvite = (userId, inviteToken, inviteType, organizationId) => async dispatch => {
  try {
    dispatch({
      type: USER_TYPES.ACCEPT_INVITE.REQUEST,
    });
    let data;
    if (inviteType === 'organization_user') {
      data = await userServices.acceptInvite(userId, inviteToken, inviteType).catch(e => {
        throw e;
      });
    } else {
      data = await userServices.acceptInvite(userId, inviteToken, inviteType, organizationId).catch(e => {
        throw e;
      });
    }

    dispatch({
      type: USER_TYPES.ACCEPT_INVITE.SUCCESS,
      payload: data.data[0],
    });
    return data.data[0];
  } catch (e) {
    const error = e instanceof CustomError ? e : new ErrorHandler(e).getError();
    dispatch({
      type: USER_TYPES.ACCEPT_INVITE.FAILURE,
      payload: error.getMessage(),
    });
    throw error;
  }
};

export const getUserUsage = () => async (dispatch, getState) => {
  try {
    const user = getState().auth.user;
    dispatch({
      type: USER_TYPES.GET_PRICING_PLAN.REQUEST,
    });

    const pricingPlan = await userServices.getPricingPlanForUser(user.id);

    dispatch({
      type: USER_TYPES.GET_USER_USAGE.SUCCESS,
      payload: {
        pricingPlan,
      },
    });
  } catch (error) {
    dispatch({
      type: USER_TYPES.GET_USER_USAGE.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getPricingPlanForUser = () => async (dispatch, getState) => {
  try {
    const user = getState().auth.user;
    const currentOrg = getState().auth.currentOrganization;
    dispatch({
      type: USER_TYPES.GET_PRICING_PLAN.REQUEST,
    });

    const pricingPlan = await userServices.getPricingPlanForUser(user.id, currentOrg.organization_id);

    dispatch({
      type: USER_TYPES.GET_PRICING_PLAN.SUCCESS,
      payload: {
        pricingPlan,
      },
    });
  } catch (error) {
    dispatch({
      type: USER_TYPES.GET_PRICING_PLAN.FAILURE,
      payload: error.response?.data,
    });
  }
};

export const updatePricingPlanForUser = (userId, newPlanId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_TYPES.UPDATE_PRICING_PLAN.REQUEST,
    });
    const user = getState().auth.user;
    const pricingPlan = await userServices.updatePricingPlanForUser(userId || user.id, newPlanId);

    dispatch({
      type: USER_TYPES.UPDATE_PRICING_PLAN.SUCCESS,
      payload: {},
    });

    return pricingPlan;
  } catch (error) {
    dispatch({
      type: USER_TYPES.UPDATE_PRICING_PLAN.FAILURE,
      payload: error.response.data,
    });
    return error.response.data;
  }
};

export const clearFailure = () => async dispatch => {
  try {
    dispatch({
      type: USER_TYPES.CLEAR_FAILURE,
    });
  } catch (e) {
    // nothing
  }
};
