import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import styles from './ColorPicker.module.scss';
import Option from '../../components/Option';

const ColorPicker = props => {
  const { config, currentState, expanded, onChange, onExpandEvent, translations } = props;
  const [currentStyleTab, setCurrentStyleTab] = useState('bgcolor');

  const onChangeHandler = color => onChange(currentStyleTab, color);
  const changeCurrentStyleColor = () => setCurrentStyleTab('color');
  const changeCurrentStyleBgColor = () => setCurrentStyleTab('bgcolor');

  useEffect(() => {
    if (expanded) changeCurrentStyleBgColor();
  }, [expanded]);

  const renderModal = () => {
    const { popupClassName, colors } = config;
    const { color, bgColor } = currentState;
    const currentSelectedColor = currentStyleTab === 'color' ? color : bgColor;

    return (
      <div className={cs('rdw-colorpicker-modal', styles.modalRoot, popupClassName)} onClick={e => e.stopPropagation()}>
        <span className="rdw-colorpicker-modal-header">
          <span
            className={cs('rdw-colorpicker-modal-style-label', {
              'rdw-colorpicker-modal-style-label-active': currentStyleTab === 'bgcolor',
            })}
            onClick={changeCurrentStyleBgColor}
          >
            {translations['components.controls.colorpicker.background']}
          </span>
          <span
            className={cs('rdw-colorpicker-modal-style-label', {
              'rdw-colorpicker-modal-style-label-active': currentStyleTab === 'color',
            })}
            onClick={changeCurrentStyleColor}
          >
            {translations['components.controls.colorpicker.text']}
          </span>
        </span>
        <span className="rdw-colorpicker-modal-options">
          {colors.map((color, idx) => (
            <Option
              value={color}
              key={idx}
              className="rdw-colorpicker-option"
              activeClassName="rdw-colorpicker-option-active"
              active={currentSelectedColor === color}
              onClick={onChangeHandler}
            >
              <span className="rdw-colorpicker-cube" style={{ backgroundColor: color }} />
            </Option>
          ))}
        </span>
        {currentSelectedColor && (
          <span className={styles.clear} onClick={() => onChangeHandler('')}>
            Remove
          </span>
        )}
      </div>
    );
  };

  return (
    <div
      className="rdw-colorpicker-wrapper"
      aria-haspopup="true"
      aria-expanded={expanded}
      aria-label="rdw-color-picker"
      title={config.title || translations['components.controls.colorpicker.colorpicker']}
    >
      <Option onClick={onExpandEvent} className={cs(config.className)}>
        <img src={config.icon} alt="" />
      </Option>
      {expanded ? renderModal() : null}
    </div>
  );
};

export default ColorPicker;
