import React, { FC, useState } from 'react';
import styles from './CollaboratorsCount.module.scss';
import CollaboratorsTooltip from '../../../../WhiteTooltips/CollaboratorsTooltip';
import { ICollaborator } from '../../../../../models/entities/collaborator/Collaborator';

type PropsType = {
  collaborators: ICollaborator[];
  displayableCollaboratorsNumber: number;
};

const CollaboratorsCount: FC<PropsType> = props => {
  const [hover, setHover] = useState<boolean>(false);

  return (
    <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className={styles.counterIcon}>
      <CollaboratorsTooltip withoutToolTip={!hover} collaborators={props.collaborators}>
        <div>+{props.collaborators.length - props.displayableCollaboratorsNumber}</div>
      </CollaboratorsTooltip>
    </div>
  );
};

export default CollaboratorsCount;
