import React, {FC, useCallback} from 'react';
import DeliverableAssetModal from "../../DeliverableItem/components/ModalsContainer/components/DeliverableAssetModal";
import {IResultAsset} from "../../../../models/entities/asset/ResultAsset";
import useAssetModalData from "./useAssetModalData";
import useDownloadAsset from "../../../../shared/hooks/useDownloadAsset";
import {useMediaQuery} from "react-responsive/src";
import {useAppDispatch} from "../../../../shared/hooks/reduxHooks";
import {useParams} from "react-router-dom";
import {deleteResultAsset} from "../../../../actions/ResultsActions/results.actions";
import useCanEditResult from "../hooks/useCanEditResult";

type PropsType = {
  assets: IResultAsset[];
  openAsset: IResultAsset | null;
  setOpenAsset: (asset: IResultAsset | null) => void;
  assetsMapping: Record<string, IResultAsset[]>;
};

const AssetModal: FC<PropsType> = props => {
  const {mediaAssets, mediaAssetIndex, openedMediaAsset} = useAssetModalData(props.assetsMapping, props.openAsset);
  const {isAssetLoading, downloadAsset} = useDownloadAsset();
  const isMobile = useMediaQuery({query: '(max-width: 576px)'});
  const dispatch = useAppDispatch();
  const params = useParams() as { boardId: string };
  const canEdit = useCanEditResult();

  const setOpenAssetId = useCallback((id: string | number) => {
    const assetToOpen = props.assets.find(asset => asset.id == id) || null;
    props.setOpenAsset(assetToOpen);
  }, [props.assets]);

  const onDownloadAsset = useCallback(() => {
    if (!props.openAsset || !props.openAsset.location.trim()) return;
    downloadAsset(props.openAsset.location, props.openAsset.extension);
  }, [props.openAsset]);

  const onDelete = useCallback(
    () => {
      if (!params.boardId || !props.openAsset) return;
      dispatch(deleteResultAsset(params.boardId, props.openAsset))
      props.setOpenAsset(null);
    },
    [params.boardId, props.openAsset],
  );

  const closeModal = useCallback(() => props.setOpenAsset(null), [props.setOpenAsset]);
  return (
    <DeliverableAssetModal
      open={props.openAsset !== null}
      onClose={closeModal}
      isAssetLoading={isAssetLoading}
      setOpenedAssetId={setOpenAssetId}
      openedAsset={props.openAsset || {}}
      mediaAssets={mediaAssets}
      openedAssetIndex={mediaAssetIndex}
      openedMediaAsset={openedMediaAsset}
      isDisabledDelete={!canEdit}
      onDownloadAsset={onDownloadAsset}
      onDelete={onDelete}
      isMobile={isMobile}
      canDownload={true}
    />
  )
};

export default AssetModal;
