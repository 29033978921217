import React, { FC, useState } from 'react';
import styles from './DeliverableItem.module.scss';
import Preview from './components/previews/Preview';
import useDeliverableState from './hooks/useDeliverableState';
import ModalsContainer from './components/ModalsContainer';
import { useMediaQuery } from 'react-responsive';
import { getCallToActionLabelByValue } from './helpers';
import ConceptSection from './components/sections/ConceptSection';
import ContentSection from './components/sections/ContentSection';
import HeaderSection from './components/sections/HeaderSection/Index';
import useDeliverableSockets from './hooks/useDeliverableSockets';
import useAssetsViewSettings from './hooks/useAssetsViewSettings';
import { AssetStateType } from '../../../models/entities/asset/AssetState';
import { IAssetMedia } from '../../../models/entities/asset/AssetMedia';
import GuestUserPurpleBanner from '../components/GuestUserPurpleBanner';

const DeliverableItem: FC = () => {
  const [openAssetModal, setOpenAssetModal] = useState<boolean>(false);
  const isTablet: boolean = useMediaQuery({ query: '(max-width: 768px)' });

  const { conceptFlow, contentFlow, onDeleteAnyAsset } = useDeliverableState();

  const assetsViewSettings = useAssetsViewSettings(conceptFlow.conceptAssets.current, contentFlow.contentAssets.current);

  useDeliverableSockets();

  const onOpenAsset = (asset: AssetStateType | IAssetMedia): void => {
    assetsViewSettings.setOpenedAssetId(asset.id);
    setOpenAssetModal(true);
  };

  return (
    <>
      <div className={styles.root}>
        <GuestUserPurpleBanner />
        <HeaderSection />
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <ConceptSection conceptFlow={conceptFlow} openAsset={onOpenAsset} />
            <ContentSection contentFlow={contentFlow} conceptFlow={conceptFlow} openAsset={onOpenAsset} />
          </div>
          {!isTablet && (
            <div className={styles.preview}>
              <Preview
                content={contentFlow.content}
                assets={contentFlow.contentAssets.current}
                callToAction={getCallToActionLabelByValue(contentFlow?.content?.call_to_action?.[0] || null)}
                mediaAssets={assetsViewSettings.contentMediaAssets}
                initialIndex={0}
              />
            </div>
          )}
        </div>
        <ModalsContainer
          openAssetModal={openAssetModal}
          setOpenAssetModal={setOpenAssetModal}
          viewSettings={assetsViewSettings}
          onDeleteAsset={onDeleteAnyAsset}
        />
      </div>
    </>
  );
};

export default DeliverableItem;
