import { IBoard } from '../Board';
import { ISocketBoardDTO } from '../dto/SocketBoard';
import { IBoardDTO } from '../dto/Board';
import { BOARD_DOCUMENT_STATES_TYPE } from '../enums/bordDocumentStatesType';

export const normalizeBoardSocketsDto = (board: IBoard, boardDto: ISocketBoardDTO): IBoardDTO => {
  const newBoard: IBoardDTO = { ...boardDto, states: board.states, permissions: board.permissions };

  if ((board.states.brief_file_state === BOARD_DOCUMENT_STATES_TYPE.EXIST) !== !!boardDto.brief_file_id) {
    newBoard.states.brief_file_state = boardDto.brief_file_id ? BOARD_DOCUMENT_STATES_TYPE.EXIST : BOARD_DOCUMENT_STATES_TYPE.EMPTY;
  }

  if (!board.permissions.brief_file_access) {
    newBoard.brief_file_id = null;
  }

  if ((board.states.contract_file_state === BOARD_DOCUMENT_STATES_TYPE.EXIST) !== !!boardDto.contract_file_id) {
    newBoard.states.contract_file_state = boardDto.contract_file_id ? BOARD_DOCUMENT_STATES_TYPE.EXIST : BOARD_DOCUMENT_STATES_TYPE.EMPTY;
  }

  if (!board.permissions.contract_file_access) {
    newBoard.contract_file_id = null;
  }

  if ((board.states.campaign_brief_state === BOARD_DOCUMENT_STATES_TYPE.EXIST) !== !!boardDto.campaign_brief_id) {
    newBoard.states.campaign_brief_state = boardDto.campaign_brief_id ? BOARD_DOCUMENT_STATES_TYPE.EXIST : BOARD_DOCUMENT_STATES_TYPE.EMPTY;
  }

  if (!board.permissions.campaign_brief_access) {
    newBoard.campaign_brief_id = null;
    newBoard.campaign_brief_item_id = null;
    newBoard.campaign_brief_item_name = null;
    newBoard.campaign_brief_item_type = null;
    newBoard.campaign_brief_parent_id = null;
  }

  return newBoard;
};
