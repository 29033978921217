import React, { useState, useEffect, ReactElement } from 'react';
import styles from './Header.module.scss';
import { ReactComponent as LogoSVG } from '../../assets/img/icons/otterfish_smal_logo.svg';
import Menu from '../Menu';
import { getUsers } from '../../actions/user.actions';
import cs from 'classnames';
import Avatar from '../Avatar';
import KnowledgeDropMenu from './KnowledgeDropMenu';
import { createCustomerPortal } from '../../actions/stripe.actions';
import { ReactComponent as TriangleIcon } from '../../assets/img/icons/play-white-arrow.svg';
import NotificationsDropMenu from './NotificationsDropMenu';
import { UserPermission } from '../../models/permissions/enum/UserPermission';
import FullLogoSVG from '../../expert/components/IconsSvg/LogoSVG';
import ButtonCustom from '../../expert/components/Buttons/ButtonCustom';
import { useHistory } from 'react-router';
import useSetUserModel from '../../shared/hooks/useSetUserModel';
import { Guest } from '../../models/permissions/users/Guest';
import GreenRedButton from '../Buttons/GreenRedButton';
import { MEMBER_ROLE_IN_ORGANIZATION_TYPES } from '../../models/enums/MemberRoleInOrganizationTypes';
import { useMediaQuery } from 'react-responsive/src';
import { useAppDispatch, useAppSelector, useThunkDispatch } from '../../shared/hooks/reduxHooks';

type PropsType = {
  extended?: boolean;
  title?: string | ReactElement | null;
  renderContent?: () => string | ReactElement | null;
  hideMenu?: boolean;
  isFlow?: boolean;
  backButtonAction?: (e: any) => void;
};

const Header = ({ extended, title, renderContent, hideMenu, isFlow = false, backButtonAction }: PropsType) => {
  const user = useAppSelector(state => state.user.data);
  const stripe = useAppSelector(state => state.auth.currentOrganization?.stripe);
  const userModel = useAppSelector(state => state.auth.userModel);
  const currentPlan = useAppSelector(state => state.auth.currentOrganizationPlan);
  const currentOrg = useAppSelector(state => state.auth.currentOrganization);
  const dispatch = useAppDispatch();
  const thunkDispatch = useThunkDispatch();
  const history = useHistory();
  const [orgMenuOpen, setOrgMenuOpen] = useState(false);
  const subscriptionStatus = stripe?.subscription_status;
  const showRenewButton = stripe?.subscription_cancel_at !== null;
  const showManageBillingButton =
    subscriptionStatus === 'past_due' || subscriptionStatus === 'incomplete' || subscriptionStatus === 'incomplete_expired';
  const showMobileSideBar = useAppSelector(state => state.sidebarMenu.showMobileSideBar);
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const showUpgradePlanButton = !!currentPlan?.is_free && currentOrg.role !== MEMBER_ROLE_IN_ORGANIZATION_TYPES.MEMBER;

  const setNewUserModel = useSetUserModel();

  useEffect(() => {
    if (user.is_admin) dispatch(getUsers());
  }, [user]);

  const onToggle = () => {
    dispatch({ type: 'TOGGLE_MOBILE_SIDEBAR', payload: !showMobileSideBar });
  };

  const handleCloseMenu = () => {
    dispatch({ type: 'TOGGLE_MOBILE_SIDEBAR', payload: false });
  };

  const redirectToStripe = async () => {
    thunkDispatch(createCustomerPortal(currentOrg?.organization_id, '/my-plan')).then(response => {
      window.location.replace(response.data[0].url);
    });
  };

  return (
    <header className={cs(styles.header, { [styles.horizontal]: extended })}>
      {extended ? (
        <div className={styles.horizontal}>
          <div className={styles.headerContent}>
            {userModel?.hasAccess(UserPermission.CAN_SEE_HEADER_DESKTOP_FULL_LOGO) && (
              <span
                className={styles.fullLogo}
                onClick={() => {
                  history.push('/content-approval');
                  setNewUserModel(null, true);
                }}
              >
                <FullLogoSVG />
              </span>
            )}
            <div className={styles.pageName}>
              {backButtonAction && (
                <div className={styles.backButton} onClick={backButtonAction}>
                  <TriangleIcon />
                </div>
              )}
              {title && <span className={cs(styles.title, { [styles.flowTitle]: isFlow })}>{title}</span>}
              {renderContent && renderContent()}
            </div>
            {userModel?.hasAccess(UserPermission.CAN_SEE_HEADER_SIGN_UP_BUTTON) && (
              <div className={styles.guestActionButtonsContainer}>
                <ButtonCustom className={styles.button} onClick={() => history.push('/sign-up')}>
                  Sign Up
                </ButtonCustom>
              </div>
            )}
            {userModel?.hasAccess(UserPermission.CAN_SEE_HEADER_DASHBOARD_BUTTON) && (
              <div className={styles.guestActionButtonsContainer}>
                <ButtonCustom
                  className={styles.button}
                  onClick={() => {
                    history.push('/content-approval');
                    setNewUserModel(null, true);
                  }}
                >
                  Dashboard
                </ButtonCustom>
              </div>
            )}
            {userModel?.hasAccess(UserPermission.CAN_SEE_TOP_MENU_USER_ACTIONS) && (
              <div className={styles.userContainer}>
                {showRenewButton && (
                  <span className={styles.link} onClick={() => redirectToStripe()}>
                    Renew plan
                  </span>
                )}
                {showManageBillingButton && (
                  <span className={styles.link} onClick={() => redirectToStripe()}>
                    Manage Billing
                  </span>
                )}
                {showUpgradePlanButton && (
                  <GreenRedButton
                    className={styles.upgradePlanButton}
                    color="green"
                    size="large"
                    onClick={() => history.push('/upgrade-plan')}
                  >
                    Upgrade plan
                  </GreenRedButton>
                )}
                <NotificationsDropMenu />
                <KnowledgeDropMenu />
                <Avatar user={user} withArrow />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.wrapper}>
          <div
            className={styles.logo}
            onClick={() => {
              history.push('/content-approval');
              if (userModel instanceof Guest) {
                setNewUserModel(null, true);
              }
            }}
          >
            <LogoSVG />
          </div>
          {userModel?.hasAccess(UserPermission.CAN_SEE_HEADER_SIGN_UP_BUTTON) && (
            <div className={styles.guestActionButtonsContainer}>
              <ButtonCustom className={styles.button} onClick={() => history.push('/sign-up')}>
                Sign Up
              </ButtonCustom>
            </div>
          )}
          {userModel?.hasAccess(UserPermission.CAN_SEE_HEADER_DASHBOARD_BUTTON) && (
            <div className={styles.guestActionButtonsContainer}>
              <ButtonCustom
                className={styles.button}
                onClick={() => {
                  history.push('/content-approval');
                  setNewUserModel(null, true);
                }}
              >
                Dashboard
              </ButtonCustom>
            </div>
          )}
          {userModel?.hasAccess(UserPermission.CAN_SEE_TOP_MENU_USER_ACTIONS) && (
            <>
              {!hideMenu && (
                <div className={styles.rightSide}>
                  <div className={cs(styles.userContainer, { [styles.active]: orgMenuOpen })} onClick={() => setOrgMenuOpen(true)}>
                    {showUpgradePlanButton && (
                      <GreenRedButton
                        className={styles.upgradePlanButton}
                        color="green"
                        size={isMobile ? 'small' : 'medium'}
                        onClick={() => history.push('/upgrade-plan')}
                      >
                        Upgrade plan
                      </GreenRedButton>
                    )}
                    <NotificationsDropMenu />
                    <Avatar user={user} withArrow />
                  </div>
                  <div className={styles.headerMenus}>
                    <div className={cs(styles.menuBtn, { [styles.openedMenu]: showMobileSideBar })} onClick={onToggle}>
                      <div className={styles.bigLine} />
                      <div className={styles.smallLine} />
                    </div>
                  </div>
                </div>
              )}
              <Menu isOpen={showMobileSideBar} handleCloseMenu={handleCloseMenu} isMobile />
            </>
          )}
        </div>
      )}
    </header>
  );
};

export default Header;
