import {ActionsDropdownV1Option} from "../../../../../components/ActionsDropdownV1";
import UploadArrowSVG from "../../../../../components/IconsSvg/UploadArrowSVG";
import cs from "classnames";
import styles from "./ResultDropdownButton.module.scss";
import {DeleteIcon, DownloadIcon} from "../../../../../expert/components/IconsSvg/ReactIcons/ReactIcons";
import React, {useRef} from "react";
import {useAppSelector} from "../../../../../shared/hooks/reduxHooks";
import {ContentBoardPermission} from "../../../../../models/permissions/enum/ContentBoardPermission";
import useMultipleFilesUpload from "../../../hooks/useMultipleFilesUpload";
import {UPLOAD_RESULTS_FORM_INPUT_PROPS} from "../UploadResultsForm";

export function useResultsActionsOptions(onDownload: () => void, onDelete: () => void, onUpload: (files: File[]) => void): ActionsDropdownV1Option[] {
  const pageModel = useAppSelector(state => state.contentBoard.pageModel);
  const closeDropdownFunctionRef = useRef(() => {/* mock */});

  const handleUpload = (files: File[]) => {
    onUpload(files);
    closeDropdownFunctionRef.current();
  }

  const {onFileUpload, renderInput} = useMultipleFilesUpload(handleUpload, 10, UPLOAD_RESULTS_FORM_INPUT_PROPS);


  let options: ActionsDropdownV1Option[] = [
  {
    text: 'Download All',
      icon: <DownloadIcon className={cs(styles.icon, styles.downloadIcon)}/>,
    onClick: (e, close) => {
      e.stopPropagation();
      close();
      onDownload();
    },
      className: styles.button
  },
]

  if (pageModel?.roleCan(ContentBoardPermission.CAN_EDIT_RESULTS)) {
    options = [{
      onClick: (e, close) => {
        e.stopPropagation();
        closeDropdownFunctionRef.current = close;
        onFileUpload();
      },
      text: "Upload Results",
      additionalElement: <>{renderInput()}</>,
      icon: <UploadArrowSVG className={cs(styles.icon, styles.uploadIcon)}/>,
      className: styles.button
    },
      ...options,
      {
        text: 'Delete All',
        icon: <DeleteIcon className={cs(styles.icon, styles.deleteIcon)}/>,
        onClick: (e, close) => {
          e.stopPropagation();
          onDelete();
          close();
        },
        className: cs(styles.button, styles.deleteButton)
      },
    ]
  }

  return options
}
