import { IBriefAsset } from '../BriefAsset';
import { ASSET_STATUSES } from '../enums/AssetStatuses';
import { IBriefAssetDTO } from '../dto/BriefAsset';

export class BriefAssetHydrator implements IBriefAsset {
  campaign_brief_id: number;
  created_at: string;
  creator_id: string;
  description: null | string;
  extension: string;
  file_id: number;
  id: number;
  mime_type: string;
  preview_file_id: null | string | number;
  status: ASSET_STATUSES;
  thumbnail_file_id: null | string | number;
  updated_at: null | string;
  updater_id: null | string;
  url: string | null;
  isVideo: boolean;
  isVideoProcessing: boolean;
  size: string;
  filename: string;
  duration: number | null;

  constructor(asset: IBriefAssetDTO) {
    this.id = asset.id;
    this.campaign_brief_id = asset.campaign_brief_id;
    this.created_at = asset.created_at;
    this.status = asset.status;
    this.creator_id = asset.creator_id;
    this.description = asset.description;
    this.extension = asset.extension;
    this.file_id = asset.file_id;
    this.mime_type = asset.mime_type;
    this.preview_file_id = asset.preview_file_id;
    this.thumbnail_file_id = asset.thumbnail_file_id;
    this.updated_at = asset.updated_at;
    this.updater_id = asset.updater_id;
    this.isVideo = /^video/g.test(asset.mime_type);
    this.isVideoProcessing = this.isVideo && this.status === ASSET_STATUSES.PROCESSING;
    this.size = asset.size;
    this.filename = asset.filename;
    this.duration = asset.duration;
    this.url = asset.location || asset.preview_location || asset.thumbnail_location;
  }
}
