import React, { FC, useMemo } from 'react';
import styles from './EditedByInfo.module.scss';
import UserColoredIcon from '../UserColoredIcon';
import { TYPES_CAMPAIGNS_TEXTS } from '../../shared/constants/constants';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import useUserTimezone from '../../shared/hooks/useUserTimezone';
import moment from 'moment';
import 'moment-timezone';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

type PropsType = {
  collaborator: any;
  approvedAt: string | null;
  updatedAt: string;
  isApproved: boolean;
};

const EditedByInfo: FC<PropsType> = ({ collaborator, approvedAt, updatedAt, isApproved }) => {
  const { userTimezone } = useUserTimezone();

  const statusText = isApproved ? 'Approved' : 'Edited';

  const date = useMemo(() => {
    const editDate = isApproved ? approvedAt : updatedAt;
    return editDate
      ? moment(editDate).from(
          moment()
            .tz(userTimezone?.name || moment.tz.guess())
            .format('YYYY-MM-DDTHH:mm:ss')
        )
      : null;
  }, [approvedAt, userTimezone, updatedAt]);

  if (!collaborator) return null;

  return (
    <div className={styles.root}>
      <div>
        <UserColoredIcon
          text={collaborator?.approver_first_name?.slice(0, 1).toUpperCase() + collaborator?.approver_last_name?.slice(0, 1).toUpperCase()}
        />
      </div>
      <div className={styles.column}>
        <div className={styles.edited}>
          {statusText} {date} by
        </div>
        <div className={styles.name}>
          {collaborator?.approver_first_name} {collaborator?.approver_last_name} ({TYPES_CAMPAIGNS_TEXTS[collaborator.type]})
        </div>
      </div>
    </div>
  );
};

export default EditedByInfo;
