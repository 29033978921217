import React, { useEffect, useState } from 'react';
import styles from './NoOrganizationState.module.scss';
import MainLayout from '../../components/MainLayout';
import { ReactComponent as PensiveMan } from '../../assets/img/icons/pensive_man.svg';
import ButtonCustom from '../../expert/components/Buttons/ButtonCustom';
import PlusSVG from '../../expert/components/IconsSvg/PlusSVG';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import OrganizationDropdown from '../../components/OrganizationDropdown';
import { useLocation } from 'react-router';

const NoOrganizationState = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const organisations = useSelector(state => state.organizations.data);
  const user = useSelector(state => state.user.data);
  const allOrganizations = useSelector(state => state.auth.organizations ?? []);
  const currentOrg = useSelector(state => state.auth.currentOrganization);
  const removedOrg = organisations?.filter(item => !allOrganizations.map(i => i.organization_id).includes(item.id))[0];
  const hasOtherOrg = allOrganizations.length;
  const [isOpenMenu, setIsOpenMenu] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (currentOrg) history.push(location.state?.from || '/');
  }, [currentOrg]);

  const handleSwitchToClick = org => {
    if (match.path.startsWith('/create-test/creative')) {
      history.push('/tests');
    }

    localStorage.removeItem('OTTERFISH_CURRENT_ORGANIZATION');
    localStorage.setItem('OTTERFISH_CURRENT_ORGANIZATION', JSON.stringify(org));
    dispatch({
      type: 'SET_CURRENT_ORGANIZATION',
      payload: org,
    });
    setIsOpenMenu(false);
  };

  return (
    <MainLayout>
      <div className={styles.root}>
        <PensiveMan />
        <span className={styles.subtitle}>You are no longer a part of {removedOrg?.name || 'any'} organization</span>
        <span className={styles.text}>
          Your plan was cancelled but we have saved your data for now.
          <br />
          Subscribe to a plan that fits you best and enjoy the full power of Otterfish
        </span>
        {hasOtherOrg ? (
          <ButtonCustom outline clearBg onClick={() => setIsOpenMenu(true)}>
            Switch to another organization
          </ButtonCustom>
        ) : (
          <ButtonCustom Icon={PlusSVG} onClick={() => history.push('/create-organization')}>
            Create your own organization
          </ButtonCustom>
        )}
      </div>
      {isOpenMenu && (
        <OrganizationDropdown
          className={styles.organizationMenu}
          organizations={allOrganizations}
          isAdmin={user?.is_admin}
          isOpen={isOpenMenu}
          onClose={() => setIsOpenMenu(false)}
          currentOrganization={currentOrg}
          onSwitchOrg={handleSwitchToClick}
          handleConfig={() => history.push('/organization/details')}
        />
      )}
    </MainLayout>
  );
};

export default NoOrganizationState;
