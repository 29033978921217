import React, {FC} from 'react';

type PropsType = {
  className?: string;
}

const ReloadSVG: FC<PropsType> = props => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
         width="30.000000pt" height="30.000000pt" viewBox="0 0 30.000000 30.000000"
         preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,30.000000) scale(0.100000,-0.100000)"
         fill="#000000" stroke="none">
        <path d="M93 250 c-34 -21 -63 -66 -63 -100 0 -54 65 -120 118 -120 36 0 81
28 102 63 21 35 26 67 10 67 -5 0 -10 -11 -10 -24 0 -14 -13 -38 -31 -55 -42
-43 -98 -43 -140 -2 -63 64 -20 171 69 171 40 0 69 -20 57 -40 -4 -6 4 -10 19
-10 21 0 26 5 26 26 0 14 -4 22 -10 19 -5 -3 -23 1 -39 10 -40 20 -69 19 -108
-5z"/>
      </g>
    </svg>
  );
};

export default ReloadSVG;
