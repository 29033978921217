import React, { FC, ReactNode } from 'react';
import styles from './TextRow.module.scss';

type PropsType = {
  title: string;
  children?: JSX.Element | ReactNode;
};

const TextRow: FC<PropsType> = props => {
  return (
    <>
      <p className={styles.rowTitle}>{props.title}</p>
      <div className={styles.rowContent}>{props.children || 'No information yet'}</div>
    </>
  );
};

export default TextRow;
