import React, { FC, useMemo, useRef } from 'react';
import cs from 'classnames';
import useTooltip, { TOOLTIP_POSITIONS } from '../../../../../shared/hooks/fixedTooltip/useTooltip';
import styles from './LiveDueDateHeader.module.scss';
import 'moment-timezone';
// @ts-ignore
import { ReactComponent as AlarmSVG } from '../../../../../assets/img/icons/alarm.svg';
import TextColorDueDate from '../../../../TextColorDueDate';
import { useTimeRelativeToNow } from '../../../../../shared/hooks/useTimeRelativeToNow';
import moment from 'moment';
import { useAppSelector } from '../../../../../shared/hooks/reduxHooks';
import useUserTimezone from '../../../../../shared/hooks/useUserTimezone';

type LiveDueDateHeaderProps = {
  title: string;
  tooltipTextData?: {
    emptyState: () => string;
    filledStateToday: (date: moment.Moment) => string;
    filledStatePassed: (date: moment.Moment) => string;
    filledState: (date: moment.Moment) => string;
  };
  date: string | null;
  onClick: () => void;
  expanded: boolean;
  isLoading: boolean;
  withReminder: boolean;
  dateClassName?: string;
  titleClassName?: string;
  wrapperClassName?: string;
  errorClassName?: string;
  error?: string | null;
};

const LiveDueDateHeader: FC<LiveDueDateHeaderProps> = ({
  title,
  tooltipTextData,
  date,
  onClick,
  expanded,
  isLoading,
  dateClassName,
  titleClassName,
  wrapperClassName,
  errorClassName,
  withReminder,
  error,
}) => {
  const rootRef = useRef(null);
  const styleColor = 'default';

  const { isPastedDate, isTodayDate, isUpcomingDate } = useTimeRelativeToNow(date);
  const { openedDeliverable } = useAppSelector(state => state.deliverables);

  const { convertUtcDateToDateByTimezone } = useUserTimezone();

  const tooltipText = useMemo(() => {
    if (!tooltipTextData) return '';
    if (isLoading) return 'Please wait updating date';

    if (date && isPastedDate) {
      return tooltipTextData.filledStatePassed(convertUtcDateToDateByTimezone(date)!);
    }
    if (date && isTodayDate) {
      return tooltipTextData.filledStateToday(convertUtcDateToDateByTimezone(date)!);
    }
    if (date) {
      return tooltipTextData.filledState(convertUtcDateToDateByTimezone(date)!);
    }
    return tooltipTextData.emptyState();
  }, [tooltipTextData, date, isLoading, isTodayDate, isPastedDate, convertUtcDateToDateByTimezone]);

  const { onEnter, onLeave, toolTip } = useTooltip(TOOLTIP_POSITIONS.BOTTOM, { text: tooltipText, className: styles.tooltip });

  const getDateString = date && convertUtcDateToDateByTimezone(date)!.format('DD MMM hh:mm A');

  return (
    <div className={cs(styles.root)} ref={rootRef}>
      <div
        onClick={onClick}
        className={cs(styles.headerBlock, wrapperClassName)}
        onMouseEnter={tooltipTextData && onEnter}
        onMouseLeave={tooltipTextData && onLeave}
      >
        <div className={styles.dateHeader}>
          <span className={titleClassName}>{title}</span>
          <div className={cs(styles.dateWrapper, styles[styleColor])}>
            <div className={styles.text}>
              {withReminder && <AlarmSVG className={styles.alarmIcon} />}{' '}
              <TextColorDueDate
                date={date}
                contentStatus={openedDeliverable?.deliverable?.status_content}
                isUpcomingDate={isUpcomingDate}
                isPastedDate={isPastedDate}
                className={dateClassName}
              >
                {date ? getDateString : 'Not set'}
              </TextColorDueDate>
            </div>
          </div>
        </div>
        {!!error?.length && <div className={errorClassName}>{error}</div>}
        {!expanded && tooltipTextData && toolTip}
      </div>
    </div>
  );
};

export default LiveDueDateHeader;
