import React, { FC } from 'react';
import styles from './BriefCardsSection.module.scss';
import cs from 'classnames';
import { IBriefAsset } from '../../../../../models/entities/asset/BriefAsset';
import { IViewBriefData } from '../../../../../models/entities/boardBrief/ViewBrief';
import TagsCard from './cards/TagsCard';
import CampaignDetailsCard from './cards/CampaignDetailsCard';
import BoostingCard from './cards/BoostingCard';
import AudienceCard from './cards/AudienceCard';
import VisualsCard from './cards/VisualsCard';

type PropsType = {
  className?: string;
  isLoading: boolean;
  brief: IViewBriefData | null;
  visualAssets: IBriefAsset[];
};

const BriefCardsSection: FC<PropsType> = props => {
  return (
    <div className={cs(styles.root, props.className)}>
      <TagsCard
        hashtags={props.brief?.hashtags ?? null}
        social_tagging={props.brief?.social_tagging ?? null}
        reposting_duration={props.brief?.reposting_duration ?? null}
        reposting_platform={props.brief?.reposting_platform ?? null}
        isLoading={props.isLoading}
      />
      <CampaignDetailsCard
        preferred_ad_declaration={props.brief?.preferred_ad_declaration ?? null}
        date_start={props.brief?.date_start ?? null}
        date_end={props.brief?.date_end ?? null}
        objective={props.brief?.objective ?? null}
        total_budget_currency={props.brief?.total_budget_currency ?? null}
        total_budget={props.brief?.total_budget ?? null}
        isLoading={props.isLoading}
      />
      <BoostingCard
        boosting_budget={props.brief?.boosting_budget ?? null}
        boosting_budget_currency={props.brief?.boosting_budget_currency ?? null}
        boosting_duration={props.brief?.boosting_duration ?? null}
        boosting_manager={props.brief?.boosting_manager ?? null}
        isLoading={props.isLoading}
      />
      <AudienceCard
        isLoading={props.isLoading}
        age={
          props.brief?.target_audience?.age ?? {
            min: null,
            max: null,
          }
        }
        interests_and_behaviours={props.brief?.target_audience?.interests_and_behaviours ?? null}
        gender={props.brief?.target_audience?.gender ?? []}
        location={props.brief?.target_audience?.location ?? { city: [], county: [], country: [] }}
      />
      <VisualsCard isLoading={props.isLoading} assets={props.visualAssets} />
    </div>
  );
};

export default BriefCardsSection;
