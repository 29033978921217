import { useCallback, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { PLATFORMS } from '../../../../../../models/enums/Platforms';
import { PLACEMENTS } from '../../../../../../models/enums/Placements';
import { ICreateDeliverableFromBoardDTO } from '../../../../../../models/entities/deliverable/dto/CreateDeliverableFromBoard';
import { FormikConfig, FormikErrors, FormikProps, FormikTouched, useFormik } from 'formik';
import * as Yup from 'yup';

const DELIVERABLES_SETTINGS_DATA = {
  [PLATFORMS.FACEBOOK]: [PLACEMENTS.STORY_POST, PLACEMENTS.STATIC_POST, PLACEMENTS.VIDEO_POST, PLACEMENTS.REEL],
  [PLATFORMS.INSTAGRAM]: [
    PLACEMENTS.STORY_POST,
    PLACEMENTS.STATIC_POST,
    PLACEMENTS.VIDEO_POST,
    PLACEMENTS.REEL,
    PLACEMENTS.CAROUSEL,
    PLACEMENTS.LIVE,
    PLACEMENTS.HIGHLIGHT,
  ],
  [PLATFORMS.TIKTOK]: [PLACEMENTS.VIDEO_POST],
  [PLATFORMS.YOUTUBE]: [PLACEMENTS.VIDEO_POST],
  [PLATFORMS.BLOG_POST]: [PLACEMENTS.STATIC_POST, PLACEMENTS.VIDEO_POST],
};
export type DeliverablesFormSettingsDataType = typeof DELIVERABLES_SETTINGS_DATA;

export interface IDeliverableFormData extends ICreateDeliverableFromBoardDTO {
  id: string;
}

export type DeliverableFormikStateType = {
  deliverables: IDeliverableFormData[];
};

export type UseDeliverablesFormHandlersType = {
  createDeliverable: (platformName: IDeliverableFormData['platform'], placementName: IDeliverableFormData['placement']) => void;
  deleteDeliverable: (deliverableId: IDeliverableFormData['id']) => void;
  deleteAllPlatformDeliverables: (platformName: IDeliverableFormData['platform']) => void;
  deleteAllPlacementDeliverables: (
    platformName: IDeliverableFormData['platform'],
    placementName: IDeliverableFormData['placement']
  ) => void;
  deleteAllDeliverables: () => void;
  setDeliverableField: (
    deliverableId: IDeliverableFormData['id'],
    dataToUpdate: { [Key in keyof ICreateDeliverableFromBoardDTO]?: ICreateDeliverableFromBoardDTO[Key] }
  ) => void;
  setDeliverableTouched: (deliverableId: IDeliverableFormData['id'], touchKey: keyof ICreateDeliverableFromBoardDTO) => void;
  setErrorByIndex: (index: number, error: { [k in keyof IDeliverableFormData]?: IDeliverableFormData[k] }) => void;
};

export type UseDeliverablesFormDataType = (settings: {
  initialValue?: IDeliverableFormData[];
  onSubmit: (data: ICreateDeliverableFromBoardDTO[]) => void;
}) => {
  formik: FormikProps<DeliverableFormikStateType>;
  deliverablesFormSettings: DeliverablesFormSettingsDataType;
  canSubmit: boolean;
  handlers: UseDeliverablesFormHandlersType;
};

Yup.addMethod(Yup.object, 'uniqueProperty', function (propertyName: string, message: string) {
  return this.test('unique', message, function (value) {
    if (!value || !value[propertyName]) return true;

    const { path, parent } = this;
    const array = [...parent];
    const currentIndex = array.indexOf(value);

    array.splice(currentIndex, 1);

    if (array.some(option => option[propertyName] === value[propertyName])) {
      throw this.createError({
        path: `${path}.${propertyName}`,
        message,
      });
    }

    return true;
  });
});

Yup.addMethod(Yup.string, 'after', function (propertyName: string, message: string) {
  return this.test('after_field_date', message, function (value?: string) {
    if (!value || !this.parent?.[propertyName]) return true;
    const currentDate = moment(value);
    const comparisonDate = moment(this.parent[propertyName]);

    return currentDate.isAfter(comparisonDate);
  });
});

Yup.addMethod(Yup.string, 'before', function (propertyName: string, message: string) {
  return this.test('before_field_date', message, function (value?: string) {
    if (!value || !this.parent?.[propertyName]) return true;
    const currentDate = moment(value);
    const comparisonDate = moment(this.parent[propertyName]);

    return currentDate.isBefore(comparisonDate);
  });
});

const getNewTouchedAndErrorsState = (
  index: number,
  prevErrors: FormikErrors<DeliverableFormikStateType>,
  prevTouched: FormikTouched<DeliverableFormikStateType>
): {
  touched: FormikTouched<DeliverableFormikStateType>;
  errors: FormikErrors<DeliverableFormikStateType>;
} => {
  if (!prevErrors.deliverables?.[index] || typeof prevErrors.deliverables === 'string') {
    return {
      errors: prevErrors,
      touched: prevTouched,
    };
  }

  const newFormikTouched = prevTouched.deliverables ? [...prevTouched.deliverables] : [];
  const newFormikErrors = [...prevErrors.deliverables] as FormikErrors<IDeliverableFormData>[];

  newFormikTouched.splice(index, 1, {});
  newFormikErrors.splice(index, 1, {});

  return {
    touched: { deliverables: newFormikTouched },
    errors: { deliverables: newFormikErrors },
  };
};

const useDeliverablesFormData: UseDeliverablesFormDataType = settings => {
  const onSubmit: FormikConfig<DeliverableFormikStateType>['onSubmit'] = useCallback(
    (values: DeliverableFormikStateType): void => {
      const ICreateDeliverableFromBoardDTOs: ICreateDeliverableFromBoardDTO[] = values.deliverables.map(deliverable => {
        return {
          name: deliverable.name,
          platform: deliverable.platform,
          placement: deliverable.placement,
          live_datetime: deliverable.live_datetime,
          concept_due_datetime: deliverable.concept_due_datetime,
          content_due_datetime: deliverable.content_due_datetime,
          concept_reminder_datetime: deliverable.concept_reminder_datetime,
          content_reminder_datetime: deliverable.content_reminder_datetime,
        };
      });

      settings.onSubmit(ICreateDeliverableFromBoardDTOs);
    },
    [settings.onSubmit]
  );

  const formik = useFormik<DeliverableFormikStateType>({
    initialValues: {
      deliverables: settings?.initialValue || [],
    },
    validationSchema: Yup.object().shape({
      deliverables: Yup.array().of(
        Yup.object()
          .shape({
            id: Yup.string(),
            name: Yup.string().trim().required('Name is required'),
            platform: Yup.string().required(),
            placement: Yup.string().required(),
            live_datetime: Yup.string()
              .after('content_due_datetime', 'Live date should be after content date')
              .after('concept_due_datetime', 'Live date should be after concept date')
              .nullable(),
            concept_due_datetime: Yup.string()
              .before('content_due_datetime', 'Concept date should be before content date')
              .before('live_datetime', 'Concept date should be before live date')
              .nullable(),
            content_due_datetime: Yup.string()
              .before('live_datetime', 'Content date should be before live date')
              .after('concept_due_datetime', 'Content date should be after concept date')
              .nullable(),
            concept_reminder_datetime: Yup.string()
              .before('concept_due_datetime', 'concept reminder date should be before concept date')
              .nullable(),
            content_reminder_datetime: Yup.string()
              .before('content_due_datetime', 'content reminder date should be before content date')
              .nullable(),
          })
          .uniqueProperty('name', 'Name must be unique')
      ),
    }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: onSubmit,
  });

  const setAllFormikFields = (
    deliverablesState: IDeliverableFormData[],
    deliverablesErrors: FormikErrors<DeliverableFormikStateType>,
    formikTouched: FormikTouched<DeliverableFormikStateType>
  ): void => {
    formik.setFieldValue('deliverables', deliverablesState);
    formik.setErrors(deliverablesErrors);
    formik.setTouched(formikTouched);
  };

  const createDeliverable = useCallback(
    (platformName: IDeliverableFormData['platform'], placementName: IDeliverableFormData['placement']): void => {
      const newDeliverable: IDeliverableFormData = {
        id: _.uniqueId('create_deliverable_'),
        name: '',
        platform: platformName,
        placement: placementName,
        live_datetime: null,
        concept_due_datetime: null,
        content_due_datetime: null,
        content_reminder_datetime: null,
        concept_reminder_datetime: null,
      };

      formik.setFieldValue('deliverables', [...formik.values.deliverables, newDeliverable]);
    },
    [formik.values.deliverables, formik.errors.deliverables, formik.touched.deliverables]
  );

  const deleteDeliverable = useCallback(
    (deliverableId: IDeliverableFormData['id']): void => {
      let deliverableIndex = -1;
      const newDeliverables = formik.values.deliverables.filter((deliverable, index) => {
        if (deliverable.id === deliverableId) {
          deliverableIndex = index;
          return false;
        }

        return true;
      });

      const { errors, touched } = getNewTouchedAndErrorsState(deliverableIndex, formik.errors, formik.touched);
      setAllFormikFields(newDeliverables, errors, touched);
    },
    [formik.values.deliverables, formik.errors.deliverables, formik.touched.deliverables]
  );

  const deleteAllPlatformDeliverables = useCallback(
    (platformName: IDeliverableFormData['platform']): void => {
      let newErrors: FormikErrors<DeliverableFormikStateType> = formik.errors;
      let newTouched: FormikTouched<DeliverableFormikStateType> = formik.touched;

      const newDeliverables = formik.values.deliverables.filter((deliverable, index) => {
        if (deliverable.platform === platformName) {
          const { errors, touched } = getNewTouchedAndErrorsState(index, newErrors, newTouched);
          newErrors = errors;
          newTouched = touched;
          return false;
        }

        return true;
      });
      setAllFormikFields(newDeliverables, newErrors, newTouched);
    },
    [formik.values.deliverables, formik.errors.deliverables, formik.touched.deliverables]
  );

  const deleteAllPlacementDeliverables = useCallback(
    (platformName: IDeliverableFormData['platform'], placementName: IDeliverableFormData['placement']): void => {
      let newErrors: FormikErrors<DeliverableFormikStateType> = formik.errors;
      let newTouched: FormikTouched<DeliverableFormikStateType> = formik.touched;

      const newDeliverablesState = formik.values.deliverables.filter((deliverable, index) => {
        if (deliverable.platform === platformName && deliverable.placement === placementName) {
          const { errors, touched } = getNewTouchedAndErrorsState(index, newErrors, newTouched);
          newErrors = errors;
          newTouched = touched;
          return false;
        }

        return true;
      });

      setAllFormikFields(newDeliverablesState, newErrors, newTouched);
    },
    [formik.values.deliverables, formik.touched.deliverables, formik.errors.deliverables]
  );

  const deleteAllDeliverables = useCallback((): void => {
    setAllFormikFields([], { deliverables: [] }, { deliverables: [] });
  }, []);

  const setDeliverableField = useCallback(
    (
      deliverableId: IDeliverableFormData['id'],
      dataToUpdate: { [Key in keyof ICreateDeliverableFromBoardDTO]?: ICreateDeliverableFromBoardDTO[Key] }
    ): void => {
      const newDeliverablesState = formik.values.deliverables.map(deliverable => {
        if (deliverableId !== deliverable.id) return { ...deliverable };

        return { ...deliverable, ...dataToUpdate };
      });
      formik.setFieldValue('deliverables', newDeliverablesState);
    },
    [formik.values.deliverables]
  );

  const setDeliverableTouched = useCallback(
    (deliverableId: IDeliverableFormData['id'], touchKey: keyof ICreateDeliverableFromBoardDTO): void => {
      const deliverableIndex = formik.values.deliverables.findIndex(deliverable => deliverable.id === deliverableId);
      const prevDeliverablesTouched = formik.touched.deliverables || [];
      const newDeliverablesTouched = [...prevDeliverablesTouched];
      newDeliverablesTouched[deliverableIndex] = {
        ...prevDeliverablesTouched[deliverableIndex],
        [touchKey]: true,
      };

      formik.setTouched({ deliverables: newDeliverablesTouched });
    },
    [formik.touched.deliverables, formik.values.deliverables]
  );

  const setErrorByIndex = useCallback(
    (index: number, error: { [k in keyof IDeliverableFormData]?: IDeliverableFormData[k] }): void => {
      if (!Object.keys(error).length) return;

      const prevErrors = typeof formik.errors.deliverables === 'string' || !formik.errors.deliverables ? [] : formik.errors.deliverables;

      for (let i = 0; i <= index; i++) {
        if (!prevErrors[i]) {
          prevErrors[i] = {};
        }
      }

      const newDeliverablesErrors = prevErrors.map((deliverableError, i) => {
        if (i !== index) return deliverableError;
        return {
          ...deliverableError,
          ...error,
        };
      });

      formik.setErrors({ deliverables: newDeliverablesErrors });
    },
    [formik.errors]
  );

  const canSubmit = useMemo(() => (formik.dirty || !!formik.values.deliverables.length) && formik.isValid, [formik.dirty, formik.isValid]);

  return {
    formik,
    handlers: {
      createDeliverable,
      deleteDeliverable,
      deleteAllPlatformDeliverables,
      deleteAllPlacementDeliverables,
      deleteAllDeliverables,
      setDeliverableField,
      setDeliverableTouched,
      setErrorByIndex,
    },
    canSubmit,
    deliverablesFormSettings: DELIVERABLES_SETTINGS_DATA,
  };
};

export default useDeliverablesFormData;
