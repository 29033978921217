import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { briefPageType } from '../../briefPageType';
import { getBriefItem } from '../../../../services/brief';
import { TYPES_CAMPAIGNS } from '../../../../shared/constants/constants';

const BriefsBreadcrumbs = ({ pageType, currentBrief, currentBriefItem, BreadcrumbsComponent, config = null }) => {
  const currentOrganizationID = useSelector(state => state.auth.currentOrganization?.organization_id);
  const history = useHistory();
  const match = useRouteMatch();
  const [briefName, setBriefName] = useState(null);

  useEffect(() => {
    if (currentBriefItem) {
      const currentCollaborator = currentBriefItem.collaborators.find(
        collaborator => collaborator.organization_id == currentOrganizationID
      );
      const isContentCreatorCollaborator = currentCollaborator.organization_type == TYPES_CAMPAIGNS.CONTENT_CREATOR;

      if (!isContentCreatorCollaborator && pageType === briefPageType.CREATOR_BRIEF_ITEM) {
        getBriefItem(currentOrganizationID, match.params.campaignID, match.params.briefItemID).then(response => {
          if (response.data[0]) {
            setBriefName(response.data[0].name || '');
          }
        });
      }
    }
  }, [currentBriefItem]);

  const items = useMemo(() => {
    const array = [
      {
        name: 'Campaigns',
        callback() {
          history.push(`/campaign`);
        },
      },
    ];

    if (currentBrief) {
      array.push({
        name: 'Campaign overview: ' + currentBrief.name,
        callback() {
          history.push(`/campaign/${match.params.campaignID}`);
        },
      });
    }

    if (pageType === briefPageType.CREATOR_BRIEF_ITEM && briefName !== null) {
      array.push({
        name: 'Campaign brief: ' + briefName || 'unnamed brief',
        callback() {
          history.push(`/campaign/${match.params.campaignID}/campaign-brief/${match.params.briefItemID}`);
        },
      });
    }

    if (currentBriefItem) {
      array.push({
        name: `${pageType === briefPageType.CREATOR_BRIEF_ITEM ? 'Creator' : 'Campaign'} brief: ${
          currentBriefItem.name || 'unnamed brief'
        }`,
        callback() {},
      });
    }

    return array;
  }, [briefName, match, currentBrief, currentBriefItem, pageType, history]);

  return <BreadcrumbsComponent config={config} items={items} />;
};

export default BriefsBreadcrumbs;
