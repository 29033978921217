import React from 'react';
import styles from './ClearFilterCross.module.scss';
import { ReactComponent as CrossIcon } from '../../../../assets/img/icons/close.svg';

const ClearFilterCross = () => {
  return (
    <span className={styles.root}>
      <CrossIcon />
    </span>
  );
};

export default ClearFilterCross;
