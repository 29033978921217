import React, {FC} from 'react';
import styles from './EmptyResultsCard.module.scss';
import ImagePlaceholderIconV1 from "../../../../../../../../components/IconsSvg/ImagePlaceholderIconV1";
import ButtonCustom from "../../../../../../../../expert/components/Buttons/ButtonCustom";
import UploadArrowSvg from "../../../../../../../../components/IconsSvg/UploadArrowSVG";
import {useAppSelector} from "../../../../../../../../shared/hooks/reduxHooks";
import {ContentBoardPermission} from "../../../../../../../../models/permissions/enum/ContentBoardPermission";
import {useHistory, useParams} from "react-router";
import useMultipleFilesUpload from "../../../../../../hooks/useMultipleFilesUpload";
import {UPLOAD_RESULTS_FORM_INPUT_PROPS} from "../../../../../../components/results/UploadResultsForm";

type PropsType = {
    onUpload: (files: File[]) => void;
};

const EmptyResultsCard: FC<PropsType> = props => {
  const pageModel = useAppSelector(state => state.contentBoard.pageModel);
  const history = useHistory()
  const params = useParams<{ boardId: string }>();
  const {onFileUpload, renderInput} = useMultipleFilesUpload(props.onUpload, 10, UPLOAD_RESULTS_FORM_INPUT_PROPS);

  return (
    <div className={styles.root} onClick={() => history.push(`/content-board/${params.boardId}/results`)}>
      <ImagePlaceholderIconV1 className={styles.emptyIcon}/>
      <h5 className={styles.emptyTitle}>No results uploaded yet</h5>
      {pageModel?.roleCan(ContentBoardPermission.CAN_EDIT_RESULTS) ?
        <ButtonCustom
            onClick={(e) => {
              e.stopPropagation();
              onFileUpload()
            }}
            className={styles.button}
            Icon={UploadArrowSvg}
            iconClassName={styles.uploadIcon}
        >
        {renderInput()}
        Upload Results
      </ButtonCustom>
        : null
      }
    </div>
  )
};

export default EmptyResultsCard;
