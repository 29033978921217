import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './CreateBoard.module.scss';
import MainLayout from '../../../../../components/MainLayout';
import FormTab from '../../components/FormTab';
import SuitcaseSVG from '../../../../../components/IconsSvg/SuitcaseSVG';
import DocumentSVG from '../../../../../expert/components/IconsSvg/DocumentSVG';
import { ReactComponent as DocSVG } from '../../../../../assets/img/icons/doc-icon.svg';
import { useHistory } from 'react-router-dom';
import HashtagSVG from '../../../../../components/IconsSvg/HashtagSVG';
import TabCollaborators from './tabs/TabCollaborators';
import TabAddBrief from './tabs/TabAddBrief';
import { FormikProps } from 'formik';
import { ICreateBoardFormikValues } from './useCreateBoardFormik';
import TabMainName from '../../tabs/TabMainName';
import { useAppSelector, useThunkDispatch } from '../../../../../shared/hooks/reduxHooks';
import {
  createBoard,
  CreateBoardDataType,
  CreateBriefDataType,
} from '../../../../../actions/ContentApprovalActions/contentApproval.actions';
import LoaderModal from '../../../../../expert/components/Modal/LoaderModal';
import BoardSubHeader from './components/BoardSubHeader';
import TabAddContract from './tabs/TabAddContract';
import CreateBoardBriefHydrator from '../../../../../models/entities/boardBrief/hydrators/CreateBoardBriefHydrator';
import { ICreateBoardBriefAsset } from '../../../../../models/entities/asset/CreateBoardBriefAsset';
import { IDeliverableFormData } from '../../../ContentBoard/components/AddDeliverablesModal/hooks/useDeliverablesFormData';
import { OrganizationPermission } from '../../../../../models/permissions/enum/OrganizationPermission';
import TabAddDeliverables from './tabs/TabAddDeliverables';
import MainBrandData from '../../components/MainBrandData';
import ErrorModal from '../../../../../expert/components/Modal/ErrorModal';
import FormLoadingState from '../CreateBoardBrief/components/FormLoadingState';
import WarningCard from './components/WarningCard';
import CustomError from '../../../../../models/entities/error/CustomError';
import StorageLimitModal from '../../../../../components/Modals/ErrorLimitModal';
import TypedServerError from '../../../../../models/entities/error/TypedServerError';
import { COLLABORATOR_ROLE_TYPES } from '../../../../../models/permissions/collaborator/CollaboratorRole';

type CreateBoardProps = {
  formik: FormikProps<ICreateBoardFormikValues>;
  openCreateBriefPage: () => void;
  savedDeliverables: React.MutableRefObject<IDeliverableFormData[]>;
  saveDeliverablesState: (data: IDeliverableFormData[]) => void;
  briefVisualAssets: ICreateBoardBriefAsset[];
  onDeleteBrief: () => void;
  onEditBrief: () => void;
  isLoading?: boolean;
  isDuplicate?: boolean;
};

const CreateBoard: FC<CreateBoardProps> = ({
  formik,
  openCreateBriefPage,
  savedDeliverables,
  saveDeliverablesState,
  briefVisualAssets,
  onEditBrief,
  onDeleteBrief,
  isLoading = false,
  isDuplicate = false,
}): React.ReactElement => {
  const thunkDispatch = useThunkDispatch();
  const history = useHistory();
  const currentOrganizationID = useAppSelector<any>(state => state.auth.currentOrganization?.organization_id);
  const currentOrganizationModel = useAppSelector<any>(state => state.auth.currentOrganizationModel);
  const createBoardLoading = useAppSelector<any>(state => state.contentApproval.createBoardLoading);
  const brief = useAppSelector(state => state.boardBrief.boardBrief);
  const [isDeliverablesValid, setIsDeliverablesValid] = useState<boolean>(true);
  const [deliverablesData, setDeliverablesData] = useState<IDeliverableFormData[]>(savedDeliverables.current);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [storageLimitError, setStorageLimitError] = useState<TypedServerError | null>(null);

  const isBoardBrief: boolean = useMemo(() => {
    if (!brief) return false;

    return brief.type === 'content_board_brief';
  }, [brief]);

  useEffect(() => {
    saveDeliverablesState([...deliverablesData]);
  }, [deliverablesData]);

  const briefSubHeaderText = useMemo(() => {
    if (currentOrganizationModel?.hasAccess(OrganizationPermission.CAN_SEE_BOARD_BRIEF_CC_SUB_TITLE)) {
      return 'Only you and the collaborator working directly with you will be able to view the brief';
    }
    if (currentOrganizationModel?.hasAccess(OrganizationPermission.CAN_SEE_BOARD_BRIEF_BA_TM_SUB_TITLE)) {
      return 'Uploading a brief means only you and the content creator will be able to view the brief. You can add the brief after the board has been created.';
    }
    return '';
  }, [currentOrganizationModel]);

  const contractSubHeaderText = useMemo(() => {
    if (currentOrganizationModel?.hasAccess(OrganizationPermission.CAN_SEE_BOARD_CONTRACT_CC_SUB_TITLE)) {
      return 'Only you and the collaborator working directly with you will be able to view the contract';
    }
    if (currentOrganizationModel?.hasAccess(OrganizationPermission.CAN_SEE_BOARD_CONTRACT_BA_TM_SUB_TITLE)) {
      return 'Uploading a contract means only you and the content creator will be able to view the contract. You can add the contract after the board has been created.';
    }
    return '';
  }, [currentOrganizationModel]);

  const PageTitle: JSX.Element = useMemo(() => {
    return <span className={styles.pageTitle}>Content approval</span>;
  }, []);

  const onCancel = useCallback(() => {
    history.push('/content-approval');
  }, []);

  const submitHandler = useCallback(() => {
    const createBoardData: CreateBoardDataType = {
      boardData: {
        name: formik.values.boardName,
        brand_name: formik.values.brandName,
        initiator_organization_type: formik.values.initiatorOrganizationType as COLLABORATOR_ROLE_TYPES,
      },
      deliverables: deliverablesData.map(deliverable => {
        return {
          name: deliverable.name,
          platform: deliverable.platform,
          placement: deliverable.placement,
          live_datetime: deliverable.live_datetime,
          concept_due_datetime: deliverable.concept_due_datetime,
          content_due_datetime: deliverable.content_due_datetime,
          concept_reminder_datetime: deliverable.concept_reminder_datetime,
          content_reminder_datetime: deliverable.content_reminder_datetime,
        };
      }),
    };

    if (formik.values.brandLogo.file) {
      createBoardData.boardData['brand_logo'] = formik.values.brandLogo.file;
    }

    if (formik.values.contractFile) {
      createBoardData.boardData['contract_file'] = formik.values.contractFile;
    }
    if (formik.values.briefFile) {
      createBoardData.boardData['brief_file'] = formik.values.briefFile;
    }

    if (formik.values.inviteEmail && formik.values.inviteOrganizationType) {
      createBoardData.inviteData = {
        email: formik.values.inviteEmail,
        organization_type: formik.values.inviteOrganizationType,
        custom_message: formik.values.inviteMessage || null,
      };
    }

    const briefFormikData = formik.values.createdBrief;
    let createBriefData: CreateBriefDataType;

    if (briefFormikData && !formik.values.briefFile) {
      createBriefData = {
        briefData: new CreateBoardBriefHydrator(briefFormikData),
        visualElements: briefVisualAssets,
      };
    }

    thunkDispatch(createBoard(currentOrganizationID, createBoardData, createBriefData))
      .then(board => {
        localStorage.setItem(
          'boardBackRoute',
          JSON.stringify({
            routes: [
              {
                route: '/content-approval',
                routeName: 'Content Approval',
              },
            ],
            boardId: board.id,
          })
        );
        history.push(`/content-board/${board.id}`);
      })
      .catch((error: CustomError) => {
        if (error instanceof TypedServerError) {
          setStorageLimitError(error);
        } else {
          setSubmitError(error.getMessage());
        }
      });
  }, [formik.values, deliverablesData, currentOrganizationID, briefVisualAssets]);

  return (
    // @ts-ignore
    <MainLayout title={PageTitle} contentClassName={styles.root}>
      <BoardSubHeader
        formikErrors={formik.errors}
        deliverablesError={!isDeliverablesValid ? 'Deliverables' : null}
        onSubmit={submitHandler}
        onCancel={onCancel}
        isDisabledSubmit={!isDeliverablesValid || !formik.isValid || createBoardLoading}
        title={'Create content board'}
        text={'Build a board to have the creator brief, contract, communication and deliverables approved in one place.'}
      />

      <div id={'create_board_page_root'} className={styles.pageContent}>
        {isLoading ? (
          <FormLoadingState />
        ) : (
          <>
            <FormTab title="Board" icon={<DocSVG />}>
              <TabMainName formik={formik} placeholder="Board name" title="What is your board name?" field="boardName" />
              <MainBrandData formik={formik} />
            </FormTab>

            <FormTab
              title="Add a brief for the content creator"
              subHeader={briefSubHeaderText}
              icon={<DocumentSVG className={styles.documentSVG} />}
              optionalText="(Optional)"
            >
              <TabAddBrief
                isBoardBrief={isBoardBrief}
                formik={formik}
                openCreateBriefPage={openCreateBriefPage}
                onEditBrief={onEditBrief}
                onDeleteBrief={onDeleteBrief}
              />
              {isDuplicate &&
              ((formik.values.briefFile && !formik.touched.briefFile) || (formik.values.createdBrief && !formik.touched.createdBrief)) ? (
                <WarningCard warningText="The attached brief is copied from the previous board. Do make sure to make required changes to brief before inviting a collaborator to the board" />
              ) : null}
            </FormTab>

            {/*<FormTab*/}
            {/*  title="Add a Contract for the content creator"*/}
            {/*  subHeader={contractSubHeaderText}*/}
            {/*  icon={<DocumentSVG className={styles.documentSVG} />}*/}
            {/*  optionalText="(Optional)"*/}
            {/*>*/}
            {/*  <TabAddContract formik={formik} />*/}
            {/*  {isDuplicate && formik.values.contractFile && !formik.touched.contractFile ? (*/}
            {/*    <WarningCard warningText="The attached contract is copied from the previous board. Do make sure to make required changes to contract before inviting a collaborator to the board" />*/}
            {/*  ) : null}*/}
            {/*</FormTab>*/}

            <FormTab
              title="Invite a collaborator to join your content board."
              subHeader={
                'Invite a collaborator to join your content board. Once invited they will receive an email notification to signup for free'
              }
              icon={<SuitcaseSVG className={styles.suitcaseSVG} />}
              optionalText="(Optional)"
            >
              <TabCollaborators formik={formik} />
            </FormTab>

            <FormTab
              title="Choose Deliverables"
              subHeader={'Add one or more deliverables.  You can also add and remove deliverables after the board has been created.'}
              icon={<HashtagSVG className={styles.hashtagSVG} />}
            >
              <TabAddDeliverables
                initialValue={savedDeliverables.current}
                onValuesWereChanged={setDeliverablesData}
                setIsValid={setIsDeliverablesValid}
              />
            </FormTab>
          </>
        )}
      </div>
      <LoaderModal open={createBoardLoading} />
      <StorageLimitModal isOpen={Boolean(storageLimitError)} error={storageLimitError} onClose={() => setStorageLimitError(null)} />
      <ErrorModal open={!!submitError} onClose={() => setSubmitError(null)} text={submitError} />
    </MainLayout>
  );
};

export default CreateBoard;
