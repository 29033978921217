import React, { FC } from 'react';
import styles from './TagsList.module.scss';
import TextContainer from '../TextContainer';
import Tag from '../Tag';
import TagsListLoadingState from './TagsListLoadingState';

type PropsType = {
  tags: string[] | null;
  isLoading: boolean;
};

const TagsList: FC<PropsType> = props => {
  if (props.isLoading) {
    return (
      <div className={styles.root}>
        <TagsListLoadingState />
      </div>
    );
  }

  return (
    <div className={styles.root}>
      {props.tags?.length ? props.tags?.map((tag, index) => <Tag text={tag} key={index} />) : <TextContainer />}
    </div>
  );
};

export default TagsList;
