import React, { FC, useCallback, useMemo, useState } from 'react';
import FilledDocumentCard from '../FilledDocumentCard';
import { DOCUMENT_CARD_VIEW_TYPES } from '../../types/DocumentCardViewTypes';
import EmptyDocumentCard from '../EmptyDocumentCard';
import { useAppDispatch, useAppSelector, useThunkDispatch } from '../../../../../../../shared/hooks/reduxHooks';
import { useMediaQuery } from 'react-responsive/src';
import { ReactComponent as BriefSVG } from '../../../../../../../assets/img/icons/GrayFile.svg';
import styles from './BriefCard.module.scss';
import { TEXT_FILE_TYPES } from '../../../../../../../models/entities/file/enums/TextFileTypes';
import { updateBoardDocument } from '../../../../../../../actions/ContentBoardActions/contentBoard.actions';
import { BOARD_BRIEF_ITEM_TYPES } from '../../../../../../../models/entities/board/enums/boardBriefItemType';
import useOpenEditBriefPage from '../../../../../createPages/EditBoardBrief/useOpenEditBriefPage';
import FilledDocumentStateLoadingState from '../FilledDocumentCard/FilledDocumentCardLoadingState';
import { ReactComponent as OtterfishBriefSVG } from '../../../../../../../assets/img/icons/OtterfishBrief.svg';
import OtterfishFile from '../../../../../../../assets/img/icons/otterfishFile.png';
import { IFileData } from '../../../../../hooks/useFileUpload';
import useFileActionHandlers from '../../../../../hooks/useFileActionHandlers';
import { TOAST_NOTIFICATION_TYPES } from '../../../../../../../shared/constants/toastNotificationsData';
import { boardBriefToastNotificationConfig } from '../../constants/notificationTypes';
import { ContentBoardPermission } from '../../../../../../../models/permissions/enum/ContentBoardPermission';
import { BOARD_DOCUMENT_STATES_TYPE } from '../../../../../../../models/entities/board/enums/bordDocumentStatesType';
import TypedServerError from '../../../../../../../models/entities/error/TypedServerError';
import CustomError from '../../../../../../../models/entities/error/CustomError';
import StorageLimitModal from '../../../../../../../components/Modals/ErrorLimitModal';
import ButtonWithPurpleRoundedIcon from '../../../../../../../components/Buttons/ButtonWithPurpleRoundedIcon';
import notificationDataConfig from '../../../../../../../shared/utils/notificationDataConfig';
import { createNotification } from '../../../../../../../actions/toastNotificationActions/toastNotification.actions';

type PropsType = {
  onAddBriefClick: () => void;
  isLoading: boolean;
};

const BriefDocumentCard: FC<PropsType> = props => {
  const isTablet: boolean = useMediaQuery({ query: '(max-width: 768px)' });
  const dispatch = useAppDispatch();
  const thunkDispatch = useThunkDispatch();
  const currentOrgId = useAppSelector<string | number | null>(state => state.auth?.currentOrganization?.organization_id || null);
  const board = useAppSelector(state => state.contentBoard.openedBoard);
  const [storageLimitError, setStorageLimitError] = useState<TypedServerError | null>(null);
  const pageModel = useAppSelector(state => state.contentBoard?.pageModel || null);

  const isOnlyViewMode = useMemo(() => {
    if (!pageModel) return false;

    return pageModel.roleCan(ContentBoardPermission.BOARD_DOCUMENTS_ONLY_VIEW_MODE);
  }, [pageModel]);

  const hasAccessToBrief = useMemo(() => {
    if (!board) return false;

    if (board.states.brief_file_state === BOARD_DOCUMENT_STATES_TYPE.EXIST) return board.permissions.brief_file_access;

    if (board.states.campaign_brief_state === BOARD_DOCUMENT_STATES_TYPE.EXIST) return board.permissions.campaign_brief_access;

    return board.permissions.brief_file_access;
  }, [board]);

  const changeBriefFile = useCallback(
    (value: IFileData | null) => {
      if (!board?.id || !currentOrgId) return;

      let toastNotificationType: TOAST_NOTIFICATION_TYPES;

      if (!value) {
        toastNotificationType = boardBriefToastNotificationConfig.delete;
      } else if (!board.briefFile && !board.campaign_brief_item_id && value) {
        toastNotificationType = boardBriefToastNotificationConfig.upload;
      } else {
        toastNotificationType = boardBriefToastNotificationConfig.replace;
      }

      thunkDispatch(updateBoardDocument(currentOrgId, board.id, 'brief_file', value?.file || null, toastNotificationType)).catch(
        (error: CustomError) => {
          if (error instanceof TypedServerError) {
            setStorageLimitError(error);
          }
        }
      );
    },
    [board, currentOrgId]
  );

  const isEmpty = useMemo(() => {
    if (!board) return true;
    return (
      board.states.brief_file_state === BOARD_DOCUMENT_STATES_TYPE.EMPTY &&
      board.states.campaign_brief_state === BOARD_DOCUMENT_STATES_TYPE.EMPTY
    );
  }, [board?.briefFile, board?.campaign_brief_id]);

  const documentViewType: null | DOCUMENT_CARD_VIEW_TYPES = useMemo(() => {
    if (props.isLoading) return DOCUMENT_CARD_VIEW_TYPES.OTTERFISH_BRIEF;

    if (isEmpty) return null;

    if (board?.states.brief_file_state !== BOARD_DOCUMENT_STATES_TYPE.EXIST) return DOCUMENT_CARD_VIEW_TYPES.OTTERFISH_BRIEF;

    if (board?.briefFile?.type === TEXT_FILE_TYPES.PDF) return DOCUMENT_CARD_VIEW_TYPES.PDF;

    return DOCUMENT_CARD_VIEW_TYPES.WORD;
  }, [board, props.isLoading, isEmpty]);

  const hasActions: boolean = useMemo(() => {
    return board?.campaign_brief_item_type === BOARD_BRIEF_ITEM_TYPES.CONTENT_BOARD_BRIEF || Boolean(board?.briefFile);
  }, [board?.campaign_brief_item_type, board?.briefFile]);

  const documentUrl = useMemo(() => {
    if (!board) return '';
    if (board.briefFile) {
      return board?.briefFile?.url;
    }

    if (!board.campaign_brief_item_type) return '';

    switch (board.campaign_brief_item_type) {
      case BOARD_BRIEF_ITEM_TYPES.CAMPAIGN_BRIEF:
        return `/campaign/${board.campaign_brief_id}/campaign-brief/${board.campaign_brief_parent_id}/`;
      case BOARD_BRIEF_ITEM_TYPES.CREATOR_BRIEF:
        return `/campaign/${board.campaign_brief_id}/campaign-brief/${board.campaign_brief_parent_id}/creator-brief/${board.campaign_brief_item_id}`;
      case BOARD_BRIEF_ITEM_TYPES.CONTENT_BOARD_BRIEF:
        return `/content-board/${board?.id}/brief`;
      default: {
        return board.campaign_brief_item_type as never;
      }
    }
  }, [board]);

  const documentName = useMemo(
    () => board?.briefFile?.name.replace(new RegExp(`.${board?.briefFile?.extension}`, 'g'), '') || board?.campaign_brief_item_name || '',
    [board]
  );

  const {
    onDelete: onDeleteFile,
    onDownload,
    onFileUpload,
    renderInput,
  } = useFileActionHandlers(
    changeBriefFile,
    board?.briefFile || null,
    {
      accept: '.pdf, .doc, .docx',
    },
    fileExtension => {
      const notification = notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.FILE_TYPE_ERROR_BRIEF_FILE, {
        text: [fileExtension],
      });
      dispatch(createNotification(notification));
    }
  );

  const onDelete = useCallback(() => {
    if (!documentViewType) return;
    if (documentViewType !== DOCUMENT_CARD_VIEW_TYPES.OTTERFISH_BRIEF) {
      onDeleteFile();
      return;
    }
    if (!currentOrgId || !board?.id) return;
    dispatch(updateBoardDocument(currentOrgId, board.id, 'campaign_brief_item_id', null, boardBriefToastNotificationConfig.delete));
  }, [documentViewType, onDeleteFile, currentOrgId, board?.id]);

  const onReplace = useCallback(() => onFileUpload(), [onFileUpload]);

  const { onEditClick } = useOpenEditBriefPage(board);

  const renderButtonSection = useCallback(() => {
    if (!hasAccessToBrief) return null;

    return (
      <ButtonWithPurpleRoundedIcon size={isTablet ? 'small' : 'medium'} presetIcon="plus" onClick={props.onAddBriefClick}>
        Add Brief
      </ButtonWithPurpleRoundedIcon>
    );
  }, [props.onAddBriefClick, isTablet, hasAccessToBrief, board]);

  if (props.isLoading) {
    return (
      <FilledDocumentStateLoadingState
        className={styles.mobileRoot}
        isLoading={props.isLoading}
        documentImage={OtterfishFile}
        isTablet={isTablet}
        image={OtterfishBriefSVG}
      />
    );
  }

  return (
    <>
      {renderInput()}
      {isEmpty || !documentViewType ? (
        <EmptyDocumentCard
          className={styles.mobileRoot}
          title={isTablet ? 'No brief' : 'No Brief yet'}
          fileImage={BriefSVG}
          renderButtonSection={renderButtonSection}
        />
      ) : (
        <FilledDocumentCard
          className={styles.mobileRoot}
          isOnlyView={isOnlyViewMode}
          isOnlyPreview={!hasAccessToBrief}
          isLoading={props.isLoading}
          hasActions={hasActions}
          documentType={documentViewType}
          onDelete={onDelete}
          onDownload={onDownload}
          onReplace={onReplace}
          onEdit={onEditClick}
          documentUrl={documentUrl}
          documentName={documentName}
          hoverText={!hasAccessToBrief ? 'You do not have permission to view the brief.' : 'View Brief'}
          cardTypeText={'Brief'}
        />
      )}
      <StorageLimitModal isOpen={Boolean(storageLimitError)} error={storageLimitError} onClose={() => setStorageLimitError(null)} />
    </>
  );
};

export default BriefDocumentCard;
