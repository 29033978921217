import React from 'react';
import styles from './AssetPlaceholderByFileType.module.scss';
import cs from 'classnames';
import VideoPlaceholderIcon from '../IconsSvg/VideoPlaceHolderSVG';
import ImagePlaceholderIcon from '../IconsSvg/ImagePlaceholderIcon';

const AssetPlaceholderByFileType = ({ className, fileType, isWhite = false }) => {
  return (
    <div className={cs(styles.root, className)}>
      {/^video/g.test(fileType) ? <VideoPlaceholderIcon isWhite={isWhite} /> : <ImagePlaceholderIcon isWhite={isWhite} />}
    </div>
  );
};

export default AssetPlaceholderByFileType;
