import React from 'react';
import { ReactComponent as CalendarIcon } from '../../../../assets/img/icons/grayCalendar.svg';
import cs from 'classnames';
import styles from './CalendarDropdown.module.scss';
import dayjs from 'dayjs';

const CalendarDropdown = ({ placeholder, date, className, disabled, dataFormat, withIcon, error, onClick }) => {
  return (
    <div className={cs(styles.root, className)}>
      <div
        onClick={onClick}
        className={cs(styles.date, { [styles.empty]: !date }, { [styles.disabled]: disabled }, { [styles.isError]: !!error })}
      >
        {date ? (
          dayjs(date).format(dataFormat ? dataFormat : 'DD/MM/YYYY')
        ) : (
          <span className={styles.placeholder}>{placeholder || dayjs().format('DD MMMM YYYY')}</span>
        )}
        {withIcon && <CalendarIcon className={styles.calendarIcon} />}
      </div>
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default CalendarDropdown;
