import { Page } from './Page';
import { COLLABORATOR_ROLE_TYPES } from '../collaborator/CollaboratorRole';
import { ContentBoardPermission } from '../enum/ContentBoardPermission';
import { roleConstantPermissionsFactory } from '../factories/ContentBoardPageFactories';

export class ContentBoardPage extends Page<ContentBoardPermission> {
  protected permissions: ContentBoardPermission[] = [
    ContentBoardPermission.CAN_SEE_HEADER_INFO_AND_ACTIONS,
    ContentBoardPermission.CAN_EDIT_BOARD_NAME,
    ContentBoardPermission.CAN_SEE_DELIVERABLE_ACTIONS,
    ContentBoardPermission.CAN_CREATE_NEW_DELIVERABLE,
    ContentBoardPermission.CAN_DOWNLOAD_ALL_DELIVERABLES_ASSETS,
    ContentBoardPermission.BOARD_DOCUMENTS_ONLY_VIEW_MODE,
    ContentBoardPermission.CAN_SEE_FILE_CARD_INFO_TOOLTIP,
    ContentBoardPermission.CAN_EDIT_RESULTS,
    ContentBoardPermission.CAN_REQUEST_RESULTS,
  ];

  constructor(roleType: COLLABORATOR_ROLE_TYPES) {
    super(roleType, roleConstantPermissionsFactory(roleType));
    this.init();
  }
}
