import React, { FC, memo } from 'react';
import { validateImage } from '../../../../../shared/utils/helpers';
import { FormikProps } from 'formik';
import TextField from '../../../../../components/FormControls/TextField';
import styles from './MainBrandData.module.scss';
import useFormikDebouncedState from '../../../../../shared/hooks/useFormikDebouncedState';
import { ReactComponent as PlusIcon } from '../../../../../assets/img/icons/plus.svg';
import purpleStar from '../../../../../assets/img/purpleStar.png';
import { areEqualFormikState } from '../../../../../shared/utils/typescriptHelpers';
import { INPUT_FILE_ALLOWED_TYPE } from '../../../../../shared/constants/tsConstants';
import ButtonLightPurpleWhite from '../../../../../components/Buttons/ButtonLightPurpleWhite';
import { useMediaQuery } from 'react-responsive/src';

type PropsType = {
  formik: FormikProps<any>;
};

const propsAreEqual: Parameters<typeof memo<FC<PropsType>>>[1] = (prevProps, nextProps) => {
  return areEqualFormikState(['brandLogo', 'brandName'], prevProps.formik, nextProps.formik);
};

const MainBrandData = memo(({ formik }: PropsType) => {
  const isTablet: boolean = useMediaQuery({ query: '(max-width: 768px)' });
  const [brandName, setBrandName] = useFormikDebouncedState<string>(
    (value: string) => formik.setFieldValue('brandName', value),
    formik.values.brandName
  );

  const handleLogoUpload = event => {
    const reader = new FileReader();
    const file = validateImage(event.target.files[0]);
    if (!file) return;
    reader.onloadend = () => {
      formik.setFieldValue('brandLogo', { file: file, fileUrl: reader.result });
    };
    reader.readAsDataURL(file);
  };

  return (
    <div>
      <h3>What is your brand?</h3>
      <div className={styles.logoContentWrapper}>
        <div className={styles.logoWrapper}>
          {formik.values.brandLogo.fileUrl ? (
            <img className={styles.brandImage} src={formik.values.brandLogo.fileUrl} alt={formik.values.brandLogo.file.name} />
          ) : (
            <div className={styles.placeholder}>
              <img className={styles.placeholderImage} src={purpleStar} alt={'logo placeholder'} />
              <p className={styles.placeholderText}>Brand logo</p>
            </div>
          )}
          {isTablet && formik.values.brandLogo.fileUrl ? null : (
            <div className={styles.hoverState}>
              <PlusIcon />
              <span className={styles.hoverText}>{'Replace it'}</span>
              <input
                type="file"
                accept={INPUT_FILE_ALLOWED_TYPE.IMAGE_INPUT_ALLOWED}
                onChange={handleLogoUpload}
                className={styles.fileInput}
              />
            </div>
          )}
        </div>
        {formik.values.brandLogo.fileUrl ? (
          <div className={styles.logoActions}>
            <div className={styles.editButtonWrapper}>
              <ButtonLightPurpleWhite presetIcon={'replace'} className={styles.logoActionButton} />
              <input
                type="file"
                accept={INPUT_FILE_ALLOWED_TYPE.IMAGE_INPUT_ALLOWED}
                onChange={handleLogoUpload}
                className={styles.fileInput}
              />
            </div>
            <ButtonLightPurpleWhite
              onClick={() => formik.setFieldValue('brandLogo', { file: null, fileUrl: null })}
              presetIcon={'deleteCart'}
              className={styles.logoActionButton}
            />
          </div>
        ) : null}
      </div>
      <TextField
        className={styles.brandName}
        name="brandName"
        placeholder="Brand name"
        onChange={e => setBrandName(e.target.value)}
        onBlur={formik.handleBlur}
        value={brandName}
        // @ts-ignore
        error={formik.touched.brandName ? formik.errors.brandName : undefined}
      />
    </div>
  );
}, propsAreEqual);

export default MainBrandData;
