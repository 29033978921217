import { instance, instanceWithForm } from '../shared/utils/api.config.creator';
import ErrorHandler from '../models/entities/error/ErrorHandler';
import { ICreateBoardBriefAsset } from '../models/entities/asset/CreateBoardBriefAsset';
import { ICampaignDTO } from '../models/entities/boardBrief/dto/Campaign';
import { IBriefItemDTO } from '../models/entities/boardBrief/dto/BriefItem';

export const getBoardBrief = async (organizationId: string | number, boardId: string | number, briefId: string | number) => {
  const res = await instance.get(`organizations/${organizationId}/content-boards/${boardId}/campaign-briefs/${briefId}`);

  return res.data;
};

export const getBoardBriefItem = async (
  organizationId: string | number,
  boardId: string | number,
  briefId: string | number,
  briefItemId: string | number
) => {
  const res = await instance.get(
    `organizations/${organizationId}/content-boards/${boardId}/campaign-briefs/${briefId}/items/${briefItemId}`
  );

  return res.data;
};

export const getBoardBriefAssets = async (organizationId: string | number, boardId: string | number, briefId: string | number) => {
  const res = await instance.get(`organizations/${organizationId}/content-boards/${boardId}/campaign-briefs/${briefId}/visual-assets`);

  return res.data;
};

export const createBoardBrief = async (
  organizationId: string | number,
  data: any
): Promise<{ data: { campaign_brief: ICampaignDTO; campaign_brief_item: IBriefItemDTO }[] }> => {
  try {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    const response = await instanceWithForm.post<{ data: { campaign_brief: ICampaignDTO; campaign_brief_item: IBriefItemDTO }[] }>(
      `/organizations/${organizationId}/content-boards/campaign-briefs`,
      formData
    );
    return response.data;
  } catch (e: any) {
    throw new ErrorHandler(e).getError();
  }
};

export const deleteBoardBrief = async (organizationId, campaignId, briefId) => {
  const res = await instance.delete(
    `/organizations/${organizationId}/content-boards/campaign-briefs/${campaignId}/campaign-brief-items/${briefId}`
  );
  return res.data;
};

export const updateBoardBrief = async (organizationId: string | number, boardId: string | number, briefId: string | number, data: any) => {
  try {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    const response = await instanceWithForm.patch(
      `/organizations/${organizationId}/content-boards/${boardId}/campaign-briefs/${briefId}`,
      formData
    );
    return response.data;
  } catch (e: any) {
    return new ErrorHandler(e).getError();
  }
};

export const updateBoardBriefItem = async (
  organizationId: string | number,
  boardId: string | number,
  briefId: string | number,
  briefItemId: string | number,
  data: any
) => {
  try {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    const response = await instanceWithForm.patch(
      `/organizations/${organizationId}/content-boards/${boardId}/campaign-briefs/${briefId}/items/${briefItemId}`,
      formData
    );
    return response.data;
  } catch (e: any) {
    return new ErrorHandler(e).getError();
  }
};

export const addBoardBriefVisualAssets = async (
  organizationId: string | number,
  campaignId: string | number,
  visualElements: ICreateBoardBriefAsset[]
) => {
  try {
    const formData = new FormData();
    const description = {};

    for (let i = 0; i < visualElements.length; i++) {
      const asset = visualElements[i];
      if (!asset.file) continue;
      const newFileName = i + '_' + asset.file.name;
      formData.append('files', asset.file, newFileName);
      if (asset.description) {
        description[newFileName] = asset.description;
      }
    }

    formData.append('descriptions', JSON.stringify(description));

    return await instanceWithForm.post(
      `/organizations/${organizationId}/content-boards/campaign-briefs/${campaignId}/visual-assets`,
      formData
    );
  } catch (e) {
    if (e instanceof Error) {
      throw new ErrorHandler(e).getError();
    }
  }
};

export const addToExistingBoardBriefVisualAssets = async (
  organizationId: string | number,
  boardId: string | number,
  campaignId: string | number,
  visualElements: { file: File; description: string }[]
) => {
  try {
    const formData = new FormData();
    const description = {};

    for (let i = 0; i < visualElements.length; i++) {
      const asset = visualElements[i];
      const newFileName = i + '_' + asset.file.name;
      formData.append('files', asset.file, newFileName);
      if (asset.description) {
        description[newFileName] = asset.description;
      }
    }

    formData.append('descriptions', JSON.stringify(description));

    const response = await instanceWithForm.post(
      `/organizations/${organizationId}/content-boards/${boardId}/campaign-briefs/${campaignId}/visual-assets`,
      formData
    );

    return response;
  } catch (e: any) {
    throw new ErrorHandler(e).getError();
  }
};

export const updateBoardBriefVisualAsset = async (
  organizationId: string | number,
  boardId: string | number,
  campaignId: string | number,
  asset: { id: string | number; file: File | null; description: string }
) => {
  try {
    const formData = new FormData();
    if (asset.file) {
      formData.append('file', asset.file);
    }
    formData.append('description', asset.description);

    return await instanceWithForm.patch(
      `/organizations/${organizationId}/content-boards/${boardId}/campaign-briefs/${campaignId}/visual-assets/${asset.id}`,
      formData
    );
  } catch (e: any) {
    return new ErrorHandler(e).getError();
  }
};

export const deleteBoardBriefVisualAsset = async (
  organizationId: string | number,
  boardId: string | number,
  campaignId: string | number,
  assetId: string | number
) => {
  try {
    return await instance.delete(
      `/organizations/${organizationId}/content-boards/${boardId}/campaign-briefs/${campaignId}/visual-assets/${assetId}`
    );
  } catch (e: any) {
    return new ErrorHandler(e).getError();
  }
};
