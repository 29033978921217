import React, { ForwardedRef, VideoHTMLAttributes } from 'react';

type VideoTagPropsType = VideoHTMLAttributes<HTMLVideoElement>;

const VideoTag = React.forwardRef(
  ({ children, controlsList = 'nodownload', ...props }: VideoTagPropsType, ref: ForwardedRef<HTMLVideoElement>) => {
    return (
      <video ref={ref} {...props} controlsList={controlsList}>
        {children}
      </video>
    );
  }
);

export default VideoTag;
