import { AuthorizedUser } from '../../../models/permissions/users/AuthorizedUser';
import {
  clearContentBoard,
  clearContentBoardErrors,
  getBoardCollaborators,
  getContentBoard,
  getInvitedBoardCollaborators,
  getUpcomingLiveDate,
  setContentBoardPageModel,
} from '../../../actions/ContentBoardActions/contentBoard.actions';
import { clearDeliverablesErrors } from '../../../actions/deliverablesActions/deliverables.actions';
import { PageModelService } from '../../../models/permissions/services/PageModelService';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks/reduxHooks';
import { User } from '../../../models/permissions/users/User';
import { clearBoardBriefState } from '../../../actions/BoardBriefActions/boardBriefActions';

export type BoardSetupSettingsType = {
  withInvitedCollaborators?: boolean;
  withUpcomingLiveDate?: boolean;
};

const useBoardSetup = (setting?: BoardSetupSettingsType) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const params = useParams<{ boardId?: string }>();
  const currentOrganizationID = useAppSelector(state => state.auth.currentOrganization?.organization_id);
  const userModel = useAppSelector<User | null>(state => state.auth.userModel);
  const { boardError, collaborators } = useAppSelector(state => state.contentBoard);

  useEffect(() => {
    if (!currentOrganizationID && userModel && userModel instanceof AuthorizedUser) {
      return;
    }
    dispatch(clearBoardBriefState());
    dispatch(getContentBoard(params.boardId));
    dispatch(getBoardCollaborators(params.boardId));
    setting?.withInvitedCollaborators && dispatch(getInvitedBoardCollaborators(params.boardId));
    setting?.withUpcomingLiveDate && dispatch(getUpcomingLiveDate(params.boardId));
    return () => {
      dispatch(clearContentBoard());
    };
  }, [currentOrganizationID, userModel]);

  useEffect(() => {
    if (boardError) {
      dispatch(clearDeliverablesErrors());
      dispatch(clearContentBoardErrors());
      history.push('/content-approval');
    }
  }, [boardError]);

  useEffect(() => {
    if (collaborators?.length && userModel) {
      const newPageModel = new PageModelService(userModel).getContentBoardPageModel(collaborators);
      if (newPageModel) {
        dispatch(setContentBoardPageModel(newPageModel));
      }
    }
  }, [collaborators, userModel, currentOrganizationID]);
};

export default useBoardSetup;
