import React from 'react';
import { useMediaQuery } from 'react-responsive/src';
import BreadcrumbsDropdown from '../BreadcrumbsDropdown';
import Breadcrumb from '../Breadcrumb';
import { BreadcrumbRoute } from '../../../../models/entities/breadcrumbs/BreadcrumbRoute';
import { MouseEventHandler, MutableRefObject } from 'react';

type PropsType = {
  items: BreadcrumbRoute[];
  onClick: (callback: (event: MouseEvent) => void, index: number) => ((event: MouseEvent) => void) | void;
  rootRef: MutableRefObject<HTMLElement | null>;
};

const Mobile = (props: PropsType) => {
  const isMobile: boolean = useMediaQuery({
    query: '(max-width: 699px)',
  });

  return isMobile ? (
    <React.Fragment>
      {props.items.length > 1 && (
        <>
          <BreadcrumbsDropdown pointAmount={3} items={props.items.slice(0, props.items.length - 1)} onClick={props.onClick} />
          <Breadcrumb
            isFirstItem={false}
            onClick={props.onClick}
            item={props.items[props.items.length - 1]}
            index={props.items.length - 1}
            rootRef={props.rootRef}
            // @ts-ignore
            itemsLength={props.items.length}
          />
        </>
      )}
    </React.Fragment>
  ) : null;
};

export default Mobile;
