import React, { FC, ReactElement } from 'react';
import styles from './ConfirmationModal.module.scss';
import { ModalSmall } from '../../../expert/components/Modal';
import { ReactComponent as WarningIcon } from '../../../assets/img/icons/triangle-warning-icon-sharp.svg';
import { ReactComponent as SadSmileIcon } from '../../../assets/img/icons/sad-smile-icon.svg';
import ButtonWithPurpleRoundedIcon from '../../Buttons/ButtonWithPurpleRoundedIcon';

enum IconPreset {
  warning = 'warning',
  sadSmile = 'sadSmile',
}

type ConfirmationProps = {
  config: {
    text?: string | ReactElement;
    subText?: string | ReactElement;
    icon?: ReactElement;
    cancelButton: {
      text: string;
      action: () => void;
    };
    submitButton: {
      text: string;
      action: () => void;
    };
  };
  iconPreset?: keyof IconPreset;
};

const iconsPresents: { [key in IconPreset]: ReactElement } = {
  warning: <WarningIcon />,
  sadSmile: <SadSmileIcon />,
};

const Confirmation: FC<ConfirmationProps> = ({ config, iconPreset }) => {
  return (
    <div className={styles.root}>
      {config?.icon ? config?.icon : !!iconPreset && iconsPresents[iconPreset]}
      <div className={styles.content}>{config.text}</div>
      <div className={styles.subContent}>{config.subText}</div>
      <div className={styles.footer}>
        <ButtonWithPurpleRoundedIcon className={styles.button} size={'medium'} onClick={config.cancelButton.action}>
          {config.cancelButton.text}
        </ButtonWithPurpleRoundedIcon>
        <ButtonWithPurpleRoundedIcon className={styles.button} size={'medium'} onClick={config.submitButton.action}>
          {config.submitButton.text}
        </ButtonWithPurpleRoundedIcon>
      </div>
    </div>
  );
};

const ConfirmationModal = props => {
  return (
    <ModalSmall {...props} className={styles.modalRoot} borderRadius="10px" maxWidth="450px">
      <Confirmation {...props} />
    </ModalSmall>
  );
};

export default ConfirmationModal;
