import React, { useEffect, useState } from 'react';
import styles from './SubTabAudience.module.scss';
import cs from 'classnames';
import SelectDropdown from '../../../../../../expert/components/FormControls/SelectDropdown';
import { ReactComponent as Checked } from '../../../../../../assets/img/icons/checked_.svg';
import TagBlock from '../../../TagBlock';
import { useMediaQuery } from 'react-responsive/src';
import CheckboxButton from '../../../CheckboxButton';

const GENDER = {
  ALL: 'all',
  MALE: 'male',
  FEMALE: 'female',
};

const ages = new Array(53);
ages[0] = 13; // first element = 13
for (let i = 1; i < ages.length; i++) {
  ages[i] = ages[i - 1] + 1; // fill array
}

function StepCreatorAudience({ formik }) {
  const [minAgeIndex, setMinAgeIndex] = useState(ages.findIndex(item => item == formik.values.ageMin));
  const [maxAgeIndex, setMaxAgeIndex] = useState(ages.findIndex(item => item == formik.values.ageMax));
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  useEffect(() => {
    setMinAgeIndex(ages.findIndex(item => item == formik.values.ageMin));
  }, [formik.values.ageMin]);

  useEffect(() => {
    setMaxAgeIndex(ages.findIndex(item => item == formik.values.ageMax));
  }, [formik.values.ageMax]);

  const onSelectMin = index => {
    const minValue = ages[index];
    const maxValue = ages[maxAgeIndex];
    if (minValue > maxValue) {
      formik.setFieldValue('ageMax', minValue);
    }
    setMinAgeIndex(index);
    formik.setFieldValue('ageMin', minValue);
  };

  const onSelectMax = index => {
    const minValue = ages[minAgeIndex];
    const maxValue = ages[index];
    if (minValue > maxValue) {
      formik.setFieldValue('ageMin', maxValue);
    }
    setMaxAgeIndex(index);
    formik.setFieldValue('ageMax', maxValue);
  };

  const addCountryHandler = value => {
    if (formik.values.country.indexOf(value) === -1) {
      formik.setFieldValue('country', [...formik.values.country, value]);
    }
  };

  const removeCountryHandler = value => {
    formik.setFieldValue(
      'country',
      formik.values.country.filter(tag => value != tag)
    );
  };

  const addCityHandler = value => {
    if (formik.values.city.indexOf(value) === -1) {
      formik.setFieldValue('city', [...formik.values.city, value]);
    }
  };

  const removeCityHandler = value => {
    formik.setFieldValue(
      'city',
      formik.values.city.filter(tag => value != tag)
    );
  };

  const addInterestsHandler = value => {
    if (formik.values.interests.indexOf(value) === -1) {
      formik.setFieldValue('interests', [...formik.values.interests, value]);
    }
  };

  const removeInterestsHandler = value => {
    formik.setFieldValue(
      'interests',
      formik.values.interests.filter(tag => value != tag)
    );
  };

  const setTargetAudienceBlock = () => {
    formik.setFieldValue('showTargetAudience', true);
  };

  const removeTargetAudienceBlock = () => {
    formik.setFieldValue('showTargetAudience', false);
    formik.setFieldValue('ageMax', null);
    formik.setFieldValue('ageMin', null);
    formik.setFieldValue('gender', '');
    formik.setFieldValue('city', []);
    formik.setFieldValue('country', []);
    formik.setFieldValue('interests', []);
  };

  return (
    <div className={styles.root}>
      <div>
        <p>This will help identify who the best content creators will be for your Campaign</p>
        <div className={styles.checkboxBlock}>
          <CheckboxButton
            onClick={setTargetAudienceBlock}
            isActive={formik.values.showTargetAudience}
            item={'Yes'}
            className={styles.checkboxHeading}
          />
          <CheckboxButton
            onClick={removeTargetAudienceBlock}
            isActive={!formik.values.showTargetAudience}
            item={'No'}
            className={styles.checkboxHeading}
          />
        </div>
      </div>
      {formik.values.showTargetAudience && (
        <>
          <div>
            <h3>Age</h3>
            <div className={styles.ageContainer}>
              <SelectDropdown
                text={'Min'}
                options={ages}
                selectedId={minAgeIndex}
                onSelected={onSelectMin}
                renderOption={age => (age == 65 ? '65+' : age)}
                optionKey={age => age}
                className={styles.selectDropdown}
                inputClassName={styles.inputClassName}
              />
              <SelectDropdown
                text={'Max'}
                options={ages}
                selectedId={maxAgeIndex}
                onSelected={onSelectMax}
                optionKey={age => (age == 65 ? '65+' : age)}
                className={styles.selectDropdown}
                inputClassName={styles.inputClassName}
              />
            </div>
          </div>

          <div>
            <h3>Gender</h3>
            <div className={styles.checkboxSection}>
              <span className={styles.socialHeading} onClick={() => formik.setFieldValue('gender', GENDER.ALL)}>
                <span className={cs(styles.checkboxButton, { [styles.active]: formik.values.gender == GENDER.ALL })}>
                  <span className={styles.checkboxIcon}>
                    <Checked />
                  </span>
                  Both
                </span>
              </span>
              <span className={styles.socialHeading} onClick={() => formik.setFieldValue('gender', GENDER.MALE)}>
                <span className={cs(styles.checkboxButton, { [styles.active]: formik.values.gender == GENDER.MALE })}>
                  <span className={styles.checkboxIcon}>
                    <Checked />
                  </span>
                  Male
                </span>
              </span>
              <span className={styles.socialHeading} onClick={() => formik.setFieldValue('gender', GENDER.FEMALE)}>
                <span className={cs(styles.checkboxButton, { [styles.active]: formik.values.gender == GENDER.FEMALE })}>
                  <span className={styles.checkboxIcon}>
                    <Checked />
                  </span>
                  Female
                </span>
              </span>
            </div>
          </div>
          <div>
            <h3>Location</h3>
            <div className={styles.tagsBlock}>
              <TagBlock
                tags={formik.values.country}
                addTag={addCountryHandler}
                removeTag={removeCountryHandler}
                placeholder={'Country'}
                isDisplayButtonAdd
              />
              <TagBlock
                tags={formik.values.city}
                addTag={addCityHandler}
                removeTag={removeCityHandler}
                placeholder={'City'}
                isDisplayButtonAdd
              />
            </div>
          </div>
          <div className={styles.oneTagBlock}>
            <h3>Add interests and behaviors</h3>
            <TagBlock
              tags={formik.values.interests}
              addTag={addInterestsHandler}
              removeTag={removeInterestsHandler}
              placeholder={!isMobile && 'For example, New Home Buyers, Sport Enthusiasts etc.'}
              className={styles.tagBlock}
              isDisplayButtonAdd
            />
          </div>
        </>
      )}
    </div>
  );
}

export default StepCreatorAudience;
