import {IDeliverableOption} from "../models/entities/board/DeliverableOption";
import {IResultAsset} from "../models/entities/asset/ResultAsset";
import {RESULTS_ACTIONS_TYPES, ResultsActionsTypes} from "../actions/ResultsActions/types";


export type ResultsStateType = {
  deliverablesOptions: IDeliverableOption[];
  deliverablesOptionsLoading: boolean;
  resultAssets: IResultAsset[];
  resultAssetsLoading: boolean;
  deleteAllResultAssetsLoading: boolean;
  uploadResultAssetsLoading: boolean;
  patchResultAssetLoading: boolean;
  deleteResultAssetLoading: boolean;
  requestResultsLoading: boolean;
  resultsRequested: boolean;
};

const initialState: ResultsStateType = {
  deliverablesOptions: [],
  deliverablesOptionsLoading: false,
  resultAssets: [],
  resultAssetsLoading: false,
  deleteAllResultAssetsLoading: false,
  uploadResultAssetsLoading: false,
  patchResultAssetLoading: false,
  deleteResultAssetLoading: false,
  requestResultsLoading: false,
  resultsRequested: false,
}

export default function resultsReducer(state = initialState, action: ResultsActionsTypes): ResultsStateType {
  switch (action.type) {
    case RESULTS_ACTIONS_TYPES.GET_DELIVERABLES_OPTIONS_REQUEST:
      return {...state, deliverablesOptionsLoading: true};
    case RESULTS_ACTIONS_TYPES.GET_DELIVERABLES_OPTIONS_SUCCESS:
      return {...state, deliverablesOptionsLoading: false, deliverablesOptions: action.payload};
    case RESULTS_ACTIONS_TYPES.GET_DELIVERABLES_OPTIONS_FAILURE:
      return {...state, deliverablesOptionsLoading: false};

    case RESULTS_ACTIONS_TYPES.GET_RESULT_ASSETS_REQUEST:
      return {...state, resultAssetsLoading: true};
    case RESULTS_ACTIONS_TYPES.GET_RESULT_ASSETS_SUCCESS:
      return {...state, resultAssetsLoading: false, resultAssets: action.payload};
    case RESULTS_ACTIONS_TYPES.GET_RESULT_ASSETS_FAILURE:
      return {...state, resultAssetsLoading: false};

    case RESULTS_ACTIONS_TYPES.DELETE_ALL_RESULT_ASSETS_REQUEST:
      return {...state, deleteAllResultAssetsLoading: true, resultAssets: []};
    case RESULTS_ACTIONS_TYPES.DELETE_ALL_RESULT_ASSETS_SUCCESS:
      return {...state, deleteAllResultAssetsLoading: false};
    case RESULTS_ACTIONS_TYPES.DELETE_ALL_RESULT_ASSETS_FAILURE:
      return {...state, deleteAllResultAssetsLoading: false, resultAssets: action.payload};

    case RESULTS_ACTIONS_TYPES.UPLOAD_RESULT_ASSETS_REQUEST:
      return {...state, uploadResultAssetsLoading: true};
    case RESULTS_ACTIONS_TYPES.UPLOAD_RESULT_ASSETS_SUCCESS:
      return {...state, uploadResultAssetsLoading: false, resultAssets: [...state.resultAssets, ...action.payload]};
    case RESULTS_ACTIONS_TYPES.UPLOAD_RESULT_ASSETS_FAILURE:
      return {...state, uploadResultAssetsLoading: false};

    case RESULTS_ACTIONS_TYPES.PATCH_RESULT_ASSET_REQUEST:
      return {...state, patchResultAssetLoading: true};
    case RESULTS_ACTIONS_TYPES.PATCH_RESULT_ASSET_SUCCESS:
      return {
        ...state,
        patchResultAssetLoading: false,
        resultAssets: state.resultAssets
          .map(asset => asset.id === action.payload.id ? action.payload : asset)
      };
    case RESULTS_ACTIONS_TYPES.PATCH_RESULT_ASSET_FAILURE:
      return {...state, patchResultAssetLoading: false};

    case RESULTS_ACTIONS_TYPES.DELETE_RESULT_ASSET_REQUEST:
      return {...state, deleteResultAssetLoading: true, resultAssets: state.resultAssets.filter(asset => asset.id != action.payload.id)};
    case RESULTS_ACTIONS_TYPES.DELETE_RESULT_ASSET_SUCCESS:
      return {...state, deleteResultAssetLoading: false,};
    case RESULTS_ACTIONS_TYPES.DELETE_RESULT_ASSET_FAILURE:
      return {...state, deleteResultAssetLoading: false, resultAssets: [...state.resultAssets, action.payload]};


    case RESULTS_ACTIONS_TYPES.REQUEST_RESULTS_REQUEST:
      return {...state, requestResultsLoading: true};
    case RESULTS_ACTIONS_TYPES.REQUEST_RESULTS_SUCCESS:
      return {...state, requestResultsLoading: false, resultsRequested: true};
    case RESULTS_ACTIONS_TYPES.REQUEST_RESULTS_FAILURE:
      return {...state, requestResultsLoading: false};

    case RESULTS_ACTIONS_TYPES.CLEAR_RESULTS_STATE:
     return initialState;
    default:
      return {...state}
  }

}
