import React, {FC, ReactNode} from 'react';
import styles from './topBar.module.scss';
import ButtonLightPurpleWhite from "../../../../components/Buttons/ButtonLightPurpleWhite";
import {DownloadIcon} from "../../../../expert/components/IconsSvg/ReactIcons/ReactIcons";
import useResultAssetGeneralActions from "../../hooks/useResultAssetGeneralActions";
import {useAppSelector} from "../../../../shared/hooks/reduxHooks";
import useCanEditResult from "../hooks/useCanEditResult";
import ResultDropdownButton from "../../components/results/ResultDropdownButton";
import {useMediaQuery} from "react-responsive/src";
import useMultipleFilesUpload from "../../hooks/useMultipleFilesUpload";
import {UPLOAD_RESULTS_FORM_INPUT_PROPS} from "../../components/results/UploadResultsForm";

type PropsType = {
  onUpload: (files: File[]) => void;
  loading: boolean;
};

const TopBar: FC<PropsType> = props => {
  const canEdit = useCanEditResult();
  const assets = useAppSelector(state => state.results.resultAssets);
  const {onDelete, onDownload, confirmDeleteModal} = useResultAssetGeneralActions(assets);
  const isTablet = useMediaQuery({query: '(max-width: 768px)'});
  const {onFileUpload, renderInput} = useMultipleFilesUpload(props.onUpload, 10, UPLOAD_RESULTS_FORM_INPUT_PROPS);

  const getContent = (): ReactNode => {
    if (props.loading) return null;
    if (isTablet) return <ResultDropdownButton onUpload={props.onUpload} assets={assets}/>;

    return (
      <>
        <ButtonLightPurpleWhite
          designVersion="v3"
          Icon={DownloadIcon}
          iconClassName={styles.downloadIcon}
          size="medium"
          onClick={onDownload}
        >
          Download All
        </ButtonLightPurpleWhite>

        {canEdit ?
          <>
            <ButtonLightPurpleWhite designVersion="v3" presetIcon="upload" size="medium"
                                    onClick={onFileUpload}>
              {renderInput()}
              Upload Results
            </ButtonLightPurpleWhite>

            <ButtonLightPurpleWhite designVersion="v3" presetIcon="deleteCart" size="medium" onClick={onDelete}>
              Delete All
            </ButtonLightPurpleWhite>
          </>
          : null
        }
      </>
    )
  }


  return (
    <>
      <div className={styles.root}>
        <h3 className={styles.title}>Results</h3>

        <div className={styles.right}>
          {getContent()}
        </div>
      </div>
      {confirmDeleteModal}
    </>
  )
};

export default TopBar;
