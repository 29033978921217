import React, { FC } from 'react';
import LoadingContainer from '../../../../../../components/LoadingContainer';
import usePlatformPlacementSettings from '../../../hooks/usePlatformPlacementSettings';
import { AssetStateType } from '../../../../../../models/entities/asset/AssetState';
import { useAppSelector } from '../../../../../../shared/hooks/reduxHooks';
import { IAssetMedia } from '../../../../../../models/entities/asset/AssetMedia';
import { DeliverablesReducerState } from '../../../../../../reducers/deliverables.reducer';

type PreviewProps = {
  assets: AssetStateType[];
  mediaAssets: IAssetMedia[];
  content: any;
  callToAction: string | null;
  initialIndex?: number;
};

const Preview: FC<PreviewProps> = ({ assets, content, callToAction, initialIndex, mediaAssets }) => {
  const { openedDeliverable, deliverableLoading } = useAppSelector<DeliverablesReducerState>(state => state.deliverables);
  const { settings } = usePlatformPlacementSettings();

  return (
    <>
      {(deliverableLoading || !openedDeliverable?.deliverable) && <LoadingContainer height="480px" borderRadius="5px" />}
      {!deliverableLoading && openedDeliverable?.deliverable && !!settings && (
        <>
          {settings.renderPreviewComponent({
            assets,
            callToAction,
            initialIndex,
            description: content?.description,
            mediaAssets,
          })}
        </>
      )}
    </>
  );
};

export default Preview;
