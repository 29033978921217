import React, { FC, useMemo } from 'react';
import styles from './FileInformation.module.scss';
import cs from 'classnames';
import AssetPlaceholderByFileType from '../../components/AssetPlaceholderByFileType';
import { AssetStateType } from '../../models/entities/asset/AssetState';

const FILE_EXTENSION_REGEX = /(?:\.([^.]+))?$/;

type PropsType = {
  className?: string;
  withPlaceholder?: boolean;
  withText?: boolean;
  isWhiteTextStyle?: boolean;
  asset: AssetStateType;
};

const FileInformation: FC<PropsType> = ({ className, withPlaceholder = false, withText = true, isWhiteTextStyle = false, asset }) => {
  const extension = useMemo(() => {
    if ('file' in asset) {
      return '.' + FILE_EXTENSION_REGEX?.exec(asset.file.name)?.[1] || 'qwe';
    }

    return asset.mime_type;
  }, [asset]);

  const name = useMemo(() => {
    if ('file' in asset) {
      return asset.file.name.replace(extension, '');
    }

    return asset.filename;
  }, [asset, extension]);

  const size = useMemo(() => {
    if ('file' in asset) {
      return asset.file.size;
    }

    return asset.size;
  }, [asset]);

  return (
    <div className={cs(styles.root, { [styles.whiteText]: isWhiteTextStyle }, className)}>
      {withPlaceholder && <AssetPlaceholderByFileType fileType={extension} className={styles.placeholder} isWhite={isWhiteTextStyle} />}
      {withText && (
        <div className={styles.text}>
          <div className={styles.name}>
            <span>{name}</span>
            {asset?.filename ? <span>{extension}</span> : null}
          </div>
          <div className={styles.size}>{size}</div>
        </div>
      )}
    </div>
  );
};

export default FileInformation;
