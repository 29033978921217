/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React from 'react';
import MobileMenu from './MobileMenu';
import DesktopMenu from './DesktopMenu';

type PropsType = {
  isOpen: boolean;
  isMobile?: boolean;
  handleCloseMenu?: (e: any) => void;
  classNames?: string;
};

const Menu = ({ isOpen, isMobile = false, handleCloseMenu, classNames }: PropsType) => {
  return (
    <>
      {isMobile ? (
        <MobileMenu isOpen={isOpen} handleCloseMenu={handleCloseMenu} classNames={classNames} />
      ) : (
        <DesktopMenu isOpen={isOpen} handleCloseMenu={handleCloseMenu} classNames={classNames} />
      )}
    </>
  );
};

export default Menu;
