import React, { cloneElement, useMemo, useRef, useState } from 'react';
import styles from './VisualPopover.module.scss';

import {
  arrow,
  autoUpdate,
  flip,
  FloatingFocusManager,
  limitShift,
  offset,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import { mergeRefs } from 'react-merge-refs';

const VisualPopover = ({ children, render, placement, isDisabled = false }) => {
  const [open, setOpen] = useState(false);
  const arrowRef = useRef(null);

  const {
    x,
    y,
    reference,
    floating,
    strategy,
    context,
    middlewareData: { arrow: { x: arrowX } = {} },
  } = useFloating({
    open,
    onOpenChange: (value) => {
      if (isDisabled) {
        return;
      }
      setOpen(value);
    },
    middleware: [
      offset(({ placement }) => ({
        mainAxis: placement === 'bottom' ? -5 : 10,
      })),
      flip(),
      shift({
        limiter: limitShift(),
        padding: 5,
      }),
      arrow({ element: arrowRef }),
    ],
    placement,
    whileElementsMounted: autoUpdate,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {
      handleClose: safePolygon({ restMs: 0 }),
    }),
    useRole(context),
    useDismiss(context),
  ]);

  const ref = useMemo(() => mergeRefs([reference, children.ref]), [reference, children]);

  const arrowStyle = useMemo(() => {
    if (placement === 'bottom' || placement === 'top') {
      return { left: arrowX != null ? `${arrowX}px` : '', top: y > 0 ? '-5px' : '', bottom: y <= 0 ? '-5px' : '' };
    }

    return {
      right: placement === 'left' ? '-5px' : '',
      left: placement === 'right' ? '-5px' : '',
      top: '50%',
      transform: 'translateY(-50%) rotate(45deg)',
    };
  }, [placement, arrowX, y]);

  return (
    <>
      {cloneElement(children, getReferenceProps({ ref, ...children.props }))}
      {open && (
        <FloatingFocusManager context={context} modal={false} order={['reference', 'content']} returnFocus={false}>
          <div
            ref={floating}
            className={styles.popover}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            {...getFloatingProps()}
          >
            {render({ close: () => setOpen(false) })}
            <div className={styles.arrow} ref={arrowRef} style={arrowStyle} />
          </div>
        </FloatingFocusManager>
      )}
    </>
  );
};

export default VisualPopover;
