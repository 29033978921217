import React from 'react';
import styles from './DropdownCollaboratorCell.module.scss';
import { TYPES_CAMPAIGNS_TEXTS } from '../../../../../../shared/constants/constants';
import { useSelector } from 'react-redux';
import UserColoredIcon from '../../../../../../components/UserColoredIcon';
import cs from 'classnames';
import { useMediaQuery } from 'react-responsive/src';

const TableHeader = ({ isTablet }) => {
  return isTablet ? (
    <div className={styles.root}>
      <span style={{ textAlign: 'left' }}>Collaborator</span>
      <span>Brief approval</span>
    </div>
  ) : (
    <div className={styles.root}>
      <span>Collaborator</span>
      <span>Organization</span>
      <span>Role</span>
    </div>
  );
};

const DropdownCollaboratorCell = ({ collaborator, tableHeader }) => {
  const user = useSelector(state => state.user);
  const isInitiator = false;
  const isCurrentUser = user.data.id === +collaborator?.approver_id;
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

  if (tableHeader) return <TableHeader isTablet={isTablet} />;
  return (
    <div className={styles.root}>
      <div className={styles.group}>
        <UserColoredIcon
          text={collaborator.approver_first_name.slice(0, 1).toUpperCase() + collaborator.approver_last_name.slice(0, 1).toUpperCase()}
        />
        <div className={styles.overflow}>
          {collaborator.approver_first_name} {collaborator.approver_last_name} {isCurrentUser && '(You)'}
        </div>
      </div>
      {!isTablet && (
        <>
          <div className={styles.overflow}>{collaborator.organization_name}</div>
          <div>
            {TYPES_CAMPAIGNS_TEXTS[collaborator.type]}{' '}
            {isInitiator && (
              <>
                / <span className={styles.gray}>Initiator</span>
              </>
            )}
          </div>
        </>
      )}
      {collaborator?.approved_at !== undefined && (
        <div className={cs(styles.status, { [styles.blue]: !!collaborator.approved_at }, { [styles.purple]: !collaborator.approved_at })}>
          {collaborator.approved_at ? 'Approved' : 'Pending approval'}
        </div>
      )}
    </div>
  );
};

export default DropdownCollaboratorCell;
