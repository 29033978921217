import ERROR_TYPE from './ErrorTypes';
import ServerError, { IServerErrorProps } from './ServerError';

interface ITypedServerError {
  type: ERROR_TYPE;
}

export default class TypedServerError extends ServerError implements ITypedServerError {
  type: ERROR_TYPE;

  constructor(error: IServerErrorProps) {
    super(error);
    this.type = error.response.data.type as ERROR_TYPE;
  }

  getType(): ERROR_TYPE | null {
    return this.type;
  }
}
