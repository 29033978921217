import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './ChoosePlanModal.module.scss';
import { Modal, ModalMedium } from '..';
import PlanCard from '../../Cards/PlanCard';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearFailure, getAllPlans } from '../../../actions/payment.action';
import { me } from '../../../actions/auth.actions';
import { createCheckoutSession } from '../../../actions/stripe.actions';
import Loader from '../../../expert/components/Loader';
import { loadStripe } from '@stripe/stripe-js';
import { ReactComponent as OtterfishLogo } from '../../../assets/img/icons/otterfish_smal_logo.svg';
import ErrorModal from '../../../expert/components/Modal/ErrorModal';
import LoaderModal from '../../../expert/components/Modal/LoaderModal';
import { clearFailure as orgClearFailure } from '../../../actions/organizations.actions';
import { useMediaQuery } from 'react-responsive/src';
import { getPricingPlanForUser, getUserUsage, updatePricingPlanForUser } from '../../../actions/user.actions';
import { MEMBER_ROLE_IN_ORGANIZATION_TYPES } from '../../../models/enums/MemberRoleInOrganizationTypes';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const redirectToStripe = async sessionId => {
  const stripe = await stripePromise;
  stripe.redirectToCheckout({
    sessionId: sessionId,
  });
};

export const ChoosePlanModalContent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const plans = useSelector(state => state.payment.allPlans);
  const loading = useSelector(state => state.payment.loading);
  const failure = useSelector(state => state.payment.failure);
  const orgFail = useSelector(state => state.organizations.failure);
  const currentOrg = useSelector(state => state.auth.currentOrganization);
  const user = useSelector(state => state.auth.user);
  const stripe = useSelector(state => state.auth.currentOrganization?.stripe);
  const isAuthenticated = useSelector(state => !!state.auth.token);
  const preselect = history.location.hash.slice(1);
  const [updatingPlan, setUpdatingPlan] = useState(false);

  useEffect(() => {
    localStorage.removeItem('OTTERFISH_SELECTED_BILLING_PLAN');
    localStorage.removeItem('OTTERFISH_SELECTED_BILLING_ADDRESS');
    localStorage.removeItem('OTTERFISH_CREDIT_CARD_DETAILS');
    dispatch(getAllPlans());
    dispatch(me());
  }, []);

  const goBack = () => {
    if (document.referrer.includes(window.location.host)) {
      if (isAuthenticated) history.goBack();
      else history.push('/login');
    } else history.push('/');
  };

  const specialPlan = {
    name: 'Enterprise / Agency',
    id: 1005,
    white_label: 1,
    team_members: -1,
    organizations: -1,
    storage: -1,
    creative_template_sets: -1,
    special_support: 1,
  };

  const handleSelect = async planId => {
    if (planId === 1005) {
      const win = window.open('https://help.otterfish.com/knowledge/kb-tickets/new');
      if (win != null) {
        win.focus();
      }
    } else {
      localStorage.removeItem('OTTERFISH_CURRENT_ORGANIZATION');
      setUpdatingPlan(true);
      if (user.has_valid_subscription) {
        try {
          const response = await dispatch(updatePricingPlanForUser(user.id, planId));
          setUpdatingPlan(false);
          if (response.success) {
            setUpdatingPlan(true);
            await setTimeout(async () => {
              await dispatch(me());
              await dispatch(getPricingPlanForUser());
              await dispatch(getUserUsage());
              history.push('/organization/plan');
            }, 2500);
          }
        } catch {
          setUpdatingPlan(false);
        }
      } else {
        dispatch(createCheckoutSession(user.id,'/connect-facebook', '/upgrade-plan')).then(response => {
          redirectToStripe(response.data[0].id);
          setUpdatingPlan(false);
        });
      }
    }
  };

  return (
    <div className={styles.root}>
      <OtterfishLogo />
      <div className={styles.title}>
        <span>You reached the limit of your free tests</span>
        <div className={styles.smallText}>Upgrade your plan to create more tests</div>
      </div>
      <div className={styles.container}>
        {loading ? (
          <Loader />
        ) : (
          <div className={styles.planContainer}>
            {plans.map(plan => {
              if (plan.id > 1001)
                return (
                  <>
                    <PlanCard
                      plan={plan}
                      key={plan.id}
                      popular={!preselect && 1002}
                      preselect={preselect}
                      onSelect={handleSelect}
                      disabled={
                        user?.pricing_plan_id === plan.id ||
                        currentOrg?.role.toLowerCase() !== MEMBER_ROLE_IN_ORGANIZATION_TYPES.OWNER ||
                        (plan.id === 1001 && user?.pricing_plan_id > 1001)
                      }
                      isCurrentPlan={user?.pricing_plan_id === plan.id}
                      trialAvailable={!stripe?.subscription_status && plan.trial_days && plan.trial_days !== 0}
                      isModal={true}
                    />
                  </>
                );
            })}
            <PlanCard plan={specialPlan} onSelect={handleSelect} />
          </div>
        )}
      </div>
      <ErrorModal
        open={!!failure?.message}
        title={'Oops, something went wrong.'}
        text={failure?.message}
        onClose={() => {
          dispatch(clearFailure());
          goBack();
        }}
      />
      <ErrorModal
        open={!!orgFail?.message}
        title={'Oops, something went wrong.'}
        text={orgFail?.message}
        onClose={() => {
          dispatch(orgClearFailure());
          goBack();
        }}
      />
      {updatingPlan ? <LoaderModal open /> : null}
    </div>
  );
};

const ChoosePlanModal = props => {
  const isSmallTablet = useMediaQuery({ query: '(max-width: 769px)' });
  return (
    <ModalMedium {...props} maxWidth={isSmallTablet ? '400px' : '893px'}>
      <ChoosePlanModalContent {...props} />
    </ModalMedium>
  );
};

ChoosePlanModal.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  submitText: PropTypes.string,
  ...Modal.propTypes,
};

export default ChoosePlanModal;
