import React from 'react';

const EmojiSadVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="50" viewBox="0 0 49 50" fill="none">
      <g clip-path="url(#clip0_2661_58656)">
        <path
          d="M24.3396 49.9942C37.782 49.9942 48.6792 39.0907 48.6792 25.6406C48.6792 12.1906 37.782 1.28711 24.3396 1.28711C10.8972 1.28711 0 12.1906 0 25.6406C0 39.0907 10.8972 49.9942 24.3396 49.9942Z"
          fill="url(#paint0_linear_2661_58656)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M37.626 24.2982C38.6412 24.6951 39.1424 25.8399 38.7454 26.8551C38.3157 27.954 37.5562 28.8929 36.5712 29.5426C35.5958 30.186 34.4467 30.5144 33.2791 30.4837C31.2024 30.4783 29.7885 29.6142 28.9198 28.6346C28.5052 28.1671 28.2377 27.7013 28.0713 27.3467C27.9873 27.1678 27.9266 27.0121 27.8842 26.8911C27.8629 26.8304 27.8461 26.7778 27.833 26.7348C27.8265 26.7133 27.821 26.6941 27.8163 26.6774L27.8099 26.6542L27.8071 26.644L27.8059 26.6392L27.8053 26.637C27.805 26.6359 27.8047 26.6348 29.7144 26.1363L27.8047 26.6348C27.5294 25.58 28.1613 24.5019 29.216 24.2266C30.2545 23.9556 31.3157 24.564 31.6109 25.5896C31.6152 25.6016 31.626 25.6298 31.6445 25.6692C31.6873 25.7605 31.7609 25.889 31.8731 26.0155C32.0536 26.219 32.4264 26.5379 33.3049 26.5364C33.3273 26.5363 33.3496 26.5367 33.372 26.5374C33.7356 26.5491 34.0939 26.4479 34.3978 26.2475C34.7017 26.047 34.9363 25.7571 35.0691 25.4176C35.466 24.4024 36.6108 23.9012 37.626 24.2982Z"
          fill="#161419"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.6972 24.2982C20.7124 24.6952 21.2135 25.84 20.8165 26.8551C20.3872 27.9528 19.6289 28.8909 18.6455 29.5404C17.6712 30.1839 16.5233 30.513 15.3566 30.4837C13.2792 30.4787 11.8649 29.6144 10.996 28.6346C10.5814 28.1671 10.3139 27.7013 10.1474 27.3467C10.0635 27.1678 10.0028 27.0121 9.96036 26.8911C9.9391 26.8304 9.92224 26.7778 9.90922 26.7348C9.90271 26.7133 9.89713 26.6941 9.89243 26.6774L9.88602 26.6542L9.88329 26.644L9.88204 26.6392L9.88144 26.637C9.88115 26.6359 9.88086 26.6348 11.7906 26.1363L9.88086 26.6348C9.60559 25.58 10.2374 24.5019 11.2921 24.2266C12.3306 23.9556 13.3919 24.564 13.687 25.5896C13.6914 25.6016 13.7021 25.6298 13.7206 25.6692C13.7635 25.7605 13.837 25.889 13.9493 26.0155C14.1297 26.219 14.5026 26.5379 15.3811 26.5364C15.4026 26.5363 15.4241 26.5367 15.4455 26.5373C15.8087 26.5486 16.1665 26.4471 16.47 26.2467C16.7734 26.0462 17.0076 25.7567 17.1402 25.4175C17.5372 24.4023 18.682 23.9012 19.6972 24.2982Z"
          fill="#161419"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.879 40.3376C19.8774 40.339 19.8757 40.3404 19.8741 40.3417L19.8741 40.3417C19.873 40.3426 19.873 40.3426 19.874 40.3418L19.8763 40.3399L19.878 40.3385L19.879 40.3376ZM19.8923 40.3273L19.8741 40.3417C19.3173 40.8031 18.4917 40.7289 18.0264 40.1741C17.5594 39.6173 17.6322 38.7874 18.189 38.3204L19.0345 39.3284C18.189 38.3204 18.1896 38.3199 18.1902 38.3194L18.1915 38.3183L18.1945 38.3158L18.2016 38.3099L18.2211 38.2941C18.2362 38.2819 18.2559 38.2662 18.28 38.2475C18.3282 38.2102 18.3941 38.1606 18.4772 38.1017C18.643 37.9841 18.8782 37.8285 19.1773 37.6584C19.774 37.3192 20.6354 36.9166 21.7156 36.6482C23.9095 36.1031 26.925 36.1354 30.3195 38.2051C30.94 38.5834 31.1363 39.3931 30.758 40.0135C30.3797 40.634 29.57 40.8303 28.9496 40.452C26.2062 38.7793 23.9217 38.8116 22.3501 39.2021C21.5476 39.4015 20.9098 39.7006 20.478 39.9461C20.2629 40.0684 20.1015 40.1759 19.9994 40.2484C19.9484 40.2845 19.9125 40.3117 19.8923 40.3273Z"
          fill="#5B0600"
        />
        <path
          d="M7.75358 0.206434C7.75358 0.206434 12.3368 7.27713 13.6391 10.9943C14.9111 14.6357 14.0328 18.6408 10.6105 20.3984C9.79459 20.8171 8.90419 21.0708 7.99021 21.1451C7.07623 21.2194 6.15657 21.1128 5.28377 20.8314C4.41097 20.55 3.60213 20.0993 2.90346 19.5051C2.2048 18.9108 1.62998 18.1846 1.21187 17.368C0.15692 15.3074 0.419396 13.0095 1.21187 11.0095C3.11482 6.18118 7.09234 0.221585 7.09234 0.221585C7.13773 0.133027 7.21612 0.0658915 7.31057 0.0347036C7.40503 0.00351566 7.50796 0.0107801 7.5971 0.0549176C7.6639 0.087681 7.71866 0.140706 7.75358 0.206434Z"
          fill="url(#paint1_linear_2661_58656)"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_2661_58656" x1="3.25571" y1="37.8225" x2="45.4355" y2="13.4848" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EBB34D" />
          <stop offset="0.03" stop-color="#ECB64D" />
          <stop offset="0.18" stop-color="#EEC04F" />
          <stop offset="0.55" stop-color="#F1CC51" />
          <stop offset="1" stop-color="#F3D652" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2661_58656"
          x1="7.31948"
          y1="21.166"
          x2="7.31948"
          y2="-0.000641222"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#77AAF2" />
          <stop offset="0.35" stop-color="#8FBAF4" />
          <stop offset="0.74" stop-color="#A2C7F5" />
          <stop offset="1" stop-color="#A9CCF6" />
        </linearGradient>
        <clipPath id="clip0_2661_58656">
          <rect width="48.6842" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmojiSadVG;
