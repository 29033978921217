import React, { FC, useMemo } from 'react';
import styles from './UpcomingDateItem.module.scss';
import DeliverablePlatform from '../../../../../../../../../components/Cells/DeliverableCell/DeliverablePlatform';
import TextColorDueDate from '../../../../../../../../../components/TextColorDueDate';
import { useTimeRelativeToNow } from '../../../../../../../../../shared/hooks/useTimeRelativeToNow';
import { IDeliverable } from '../../../../../../../../../models/entities/deliverable/Deliverable';
import { useMediaQuery } from 'react-responsive/src';
import moment from 'moment';

type PropsType = {
  deliverable: IDeliverable;
};
const dateFormat = 'D MMM YYYY';
const UpcomingDateItem: FC<PropsType> = props => {
  const { isPastedDate, isUpcomingDate } = useTimeRelativeToNow(props.deliverable.live_datetime);
  const liveDateView = useMemo(() => moment(props.deliverable?.live_datetime).format(dateFormat), [props.deliverable?.live_datetime]);
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <li className={styles.root}>
      <TextColorDueDate
        baseClassName={styles.baseLiveDate}
        isPastedDate={isPastedDate}
        isUpcomingDate={isUpcomingDate}
        className={styles.liveDate}
        date={props.deliverable.live_datetime}
      >
        {liveDateView}
      </TextColorDueDate>
      <p className={styles.deliverableName}>{props.deliverable.name}</p>
      {/* @ts-ignore */}
      <DeliverablePlatform className={styles.platform} platform={props.deliverable.platform} isTablet={isTablet} />
    </li>
  );
};

export default UpcomingDateItem;
