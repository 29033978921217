import React, { useEffect, useState } from 'react';
import OrganizationDetailsForm from './OrganizationDetailsForm';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { emailValidation } from '../CreateTest/utils';
import { MEMBER_ROLE_IN_ORGANIZATION_TYPES } from '../../models/enums/MemberRoleInOrganizationTypes';
import { useAppDispatch, useAppSelector, useThunkDispatch } from '../../shared/hooks/reduxHooks';
import { Redirect, useHistory } from 'react-router';
import { getAllMembers, updateOrganization } from '../../actions/organizations.actions';
import { clearFailure, inviteMember } from '../../actions/user.actions';
import { me, recalculateRoutePermissionsTrigger } from '../../actions/auth.actions';
import { ORGANIZATION_DEFAULT_NAME_START } from '../Auth/SignUpNew';

type MemberInviteType = { id: string; email: string; type: string };

export type CompleteOrganizationPageFormikValues = {
  company_name: string;
  inviteTeamMembers: Array<MemberInviteType>;
};

const CompleteOrganizationContent = () => {
  const thunkDispatch = useThunkDispatch();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const currentOrg = useAppSelector(state => state.auth.currentOrganization);
  const [completeLoading, setCompleteLoading] = useState(false);

  // @ts-ignore
  const formik = useFormik<CompleteOrganizationPageFormikValues>({
    initialValues: {
      company_name: '',
      inviteTeamMembers: [],
    },
    validationSchema: Yup.object().shape({
      company_name: Yup.string().required('Company name is required'),
      inviteTeamMembers: Yup.array(
        Yup.object().shape({
          id: Yup.string().required(),
          email: Yup.string().nullable().test('validate-email', emailValidation),
          type: Yup.string().when('email', {
            is: inviteEmail => inviteEmail && inviteEmail.length > 0,
            then: Yup.string().oneOf(Object.values(MEMBER_ROLE_IN_ORGANIZATION_TYPES)).required('Select a type.'),
          }),
        })
      ),
    }),
    validateOnMount: true,
  });

  useEffect(() => {
    return () => {
      // When returning back through the browser, access rights to routes are not recalculated. Therefore, we recalculate them manually here so that it would be impossible to leave the page.
      dispatch(recalculateRoutePermissionsTrigger());
    };
  }, []);

  const submitEnd = async () => {
    await thunkDispatch(getAllMembers(currentOrg?.organization_id, 1, 100));
    await thunkDispatch(me());
    setCompleteLoading(false);
    history.push('/');
  };

  const onSubmit = async () => {
    setCompleteLoading(true);

    thunkDispatch(updateOrganization(currentOrg?.organization_id, { name: formik.values.company_name })).then(() => {
      if (formik.values.inviteTeamMembers.filter(member => !!member.email && !!member.type).length) {
        thunkDispatch(inviteMember(formik.values.inviteTeamMembers.map(member => ({ email: member.email, role: member.type })))).then(
          () => {
            dispatch(clearFailure());
            submitEnd();
          }
        );
      } else {
        submitEnd();
      }
    });
  };

  return <OrganizationDetailsForm formik={formik} onSubmit={onSubmit} isSubmitLoading={completeLoading} />;
};

const CompleteOrganizationPage = () => {
  const currentOrg = useAppSelector(state => state.auth.currentOrganization);
  if (!currentOrg) return null;
  if (!currentOrg?.organization_name.startsWith(ORGANIZATION_DEFAULT_NAME_START)) {
    return <Redirect to="/" />;
  }
  return <CompleteOrganizationContent />;
};

export default CompleteOrganizationPage;
