import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';
import {IBoard} from '../../models/entities/board/Board';
import {IBoardCollaborator} from '../../models/entities/board/BoardCollaborator';
import {IBoardInvitedCollaborator} from '../../models/entities/board/BoardInvitedCollaborator';
import {IDeliverable} from '../../models/entities/deliverable/Deliverable';
import {ErrorOnDeliverablesCreationType} from '../../models/entities/board/ErrorOnDeliverablesCreation';
import {IBoardAsset} from '../../models/entities/board/BoardAsset';
import {RootState} from '../../store';
import {Page} from '../../models/permissions/pages/Page';
import {ContentBoardPermission} from '../../models/permissions/enum/ContentBoardPermission';

export enum CONTENT_BOARD_ACTIONS_TYPES {
  GET_CONTENT_BOARD_REQUEST = 'GET_CONTENT_BOARD_REQUEST',
  GET_CONTENT_BOARD_SUCCESS = 'GET_CONTENT_BOARD_SUCCESS',
  GET_CONTENT_BOARD_FAILURE = 'GET_CONTENT_BOARD_FAILURE',

  GET_BOARD_COLLABORATORS_REQUEST = 'GET_BOARD_COLLABORATORS_REQUEST',
  GET_BOARD_COLLABORATORS_SUCCESS = 'GET_BOARD_COLLABORATORS_SUCCESS',
  GET_BOARD_COLLABORATORS_FAILURE = 'GET_BOARD_COLLABORATORS_FAILURE',

  GET_BOARD_INVITED_COLLABORATORS_REQUEST = 'GET_BOARD_INVITED_COLLABORATORS_REQUEST',
  GET_BOARD_INVITED_COLLABORATORS_SUCCESS = 'GET_BOARD_INVITED_COLLABORATORS_SUCCESS',
  GET_BOARD_INVITED_COLLABORATORS_FAILURE = 'GET_BOARD_INVITED_COLLABORATORS_FAILURE',

  GET_BOARD_DELIVERABLES_REQUEST = 'GET_BOARD_DELIVERABLES_REQUEST',
  GET_BOARD_DELIVERABLES_SUCCESS = 'GET_BOARD_DELIVERABLES_SUCCESS',
  GET_BOARD_DELIVERABLES_FAILURE = 'GET_BOARD_DELIVERABLES_FAILURE',

  CREATE_DELIVERABLES_REQUEST = 'CREATE_DELIVERABLES_REQUEST',
  CREATE_DELIVERABLES_SUCCESS = 'CREATE_DELIVERABLES_SUCCESS',
  CREATE_DELIVERABLES_FAILURE = 'CREATE_DELIVERABLES_FAILURE',

  GET_BOARD_DELIVERABLES_ASSETS_REQUEST = 'GET_BOARD_DELIVERABLES_ASSETS_REQUEST',
  GET_BOARD_DELIVERABLES_ASSETS_SUCCESS = 'GET_BOARD_DELIVERABLES_ASSETS_SUCCESS',
  GET_BOARD_DELIVERABLES_ASSETS_FAILURE = 'GET_BOARD_DELIVERABLES_ASSETS_FAILURE',

  DUPLICATE_DELIVERABLE_ITEM_REQUEST = 'DUPLICATE_DELIVERABLE_ITEM_REQUEST',
  DUPLICATE_DELIVERABLE_ITEM_SUCCESS = 'DUPLICATE_DELIVERABLE_ITEM_SUCCESS',
  DUPLICATE_DELIVERABLE_ITEM_FAILURE = 'DUPLICATE_DELIVERABLE_ITEM_FAILURE',

  GET_UPCOMING_LIVE_DATES_DELIVERABLES_REQUEST = 'GET_UPCOMING_LIVE_DATES_DELIVERABLES_REQUEST',
  GET_UPCOMING_LIVE_DATES_DELIVERABLES_SUCCESS = 'GET_UPCOMING_LIVE_DATES_DELIVERABLES_SUCCESS',
  GET_UPCOMING_LIVE_DATES_DELIVERABLES_FAILURE = 'GET_UPCOMING_LIVE_DATES_DELIVERABLES_FAILURE',

  PATCH_OPENED_CONTENT_BOARD_REQUEST = 'PATCH_OPENED_CONTENT_BOARD_REQUEST',
  PATCH_OPENED_CONTENT_BOARD_SUCCESS = 'PATCH_OPENED_CONTENT_BOARD_SUCCESS',
  PATCH_OPENED_CONTENT_BOARD_FAILURE = 'PATCH_OPENED_CONTENT_BOARD_FAILURE',

  PUSH_DELIVERABLES_TO_BOARD = 'PUSH_DELIVERABLES_TO_BOARD',
  CLEAR_OPENED_CONTENT_BOARD = 'CLEAR_OPENED_CONTENT_BOARD',
  UPDATE_OPENED_CONTENT_BOARD = 'UPDATE_OPENED_CONTENT_BOARD',
  UPDATE_BOARD_DELIVERABLE_ITEM = 'UPDATE_BOARD_DELIVERABLE_ITEM',
  SET_CONTENT_BOARD_PAGE_MODEL = 'SET_CONTENT_BOARD_PAGE_MODEL',
  CLEAR_ERRORS = 'CLEAR_ERRORS',
}

type GetContentBoardRequestActionType = Action<CONTENT_BOARD_ACTIONS_TYPES.GET_CONTENT_BOARD_REQUEST>;
type GetContentBoardSuccessActionType = Action<CONTENT_BOARD_ACTIONS_TYPES.GET_CONTENT_BOARD_SUCCESS> & {
  payload: IBoard
};
type GetContentBoardFailureActionType = Action<CONTENT_BOARD_ACTIONS_TYPES.GET_CONTENT_BOARD_FAILURE> & {
  payload: string
};

type GetBoardCollaboratorsRequestActionType = Action<CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_COLLABORATORS_REQUEST>;
type GetBoardCollaboratorsSuccessActionType = Action<CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_COLLABORATORS_SUCCESS> & {
  payload: IBoardCollaborator[];
};
type GetBoardCollaboratorsFailureActionType = Action<CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_COLLABORATORS_FAILURE> & {
  payload: string
};

type GetBoardInvitedCollaboratorsRequestActionType = Action<CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_INVITED_COLLABORATORS_REQUEST>;
type GetBoardInvitedCollaboratorsSuccessActionType =
  Action<CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_INVITED_COLLABORATORS_SUCCESS>
  & {
  payload: IBoardInvitedCollaborator[];
};
type GetBoardInvitedCollaboratorsFailureActionType =
  Action<CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_INVITED_COLLABORATORS_FAILURE>
  & {
  payload: string;
};

type GetBoardDeliverablesRequestActionType = Action<CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_DELIVERABLES_REQUEST>;
type GetBoardDeliverablesSuccessActionType = Action<CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_DELIVERABLES_SUCCESS> & {
  payload: IDeliverable[];
  totalCount: number;
  approvedCount: number;
};
type GetBoardDeliverablesFailureActionType = Action<CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_DELIVERABLES_FAILURE> & {
  payload: string
};

type CreateDeliverablesRequestActionType = Action<CONTENT_BOARD_ACTIONS_TYPES.CREATE_DELIVERABLES_REQUEST>;
type CreateDeliverablesSuccessActionType = Action<CONTENT_BOARD_ACTIONS_TYPES.CREATE_DELIVERABLES_SUCCESS>;
type CreateDeliverablesFailureActionType = Action<CONTENT_BOARD_ACTIONS_TYPES.CREATE_DELIVERABLES_FAILURE> & {
  payload: ErrorOnDeliverablesCreationType[];
};

type GetBoardDeliverablesAssetsRequestActionType = Action<CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_DELIVERABLES_ASSETS_REQUEST>;
type GetBoardDeliverablesAssetsSuccessActionType =
  Action<CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_DELIVERABLES_ASSETS_SUCCESS>
  & {
  payload: IBoardAsset[];
};
type GetBoardDeliverablesAssetsFailureActionType =
  Action<CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_DELIVERABLES_ASSETS_FAILURE>
  & {
  payload: string;
};

type DuplicateDeliverableItemRequestActionType = Action<CONTENT_BOARD_ACTIONS_TYPES.DUPLICATE_DELIVERABLE_ITEM_REQUEST>;
type DuplicateDeliverableItemSuccessActionType =
  Action<CONTENT_BOARD_ACTIONS_TYPES.DUPLICATE_DELIVERABLE_ITEM_SUCCESS>
  & {
  payload: { deliverable: IDeliverable; afterId: string | number };
};
type DuplicateDeliverableItemFailureActionType = Action<CONTENT_BOARD_ACTIONS_TYPES.DUPLICATE_DELIVERABLE_ITEM_FAILURE>;

type GetUpcomingLiveDatesDeliverablesRequestActionType = Action<CONTENT_BOARD_ACTIONS_TYPES.GET_UPCOMING_LIVE_DATES_DELIVERABLES_REQUEST>;
type GetUpcomingLiveDatesDeliverablesSuccessActionType =
  Action<CONTENT_BOARD_ACTIONS_TYPES.GET_UPCOMING_LIVE_DATES_DELIVERABLES_SUCCESS> & { payload: IDeliverable[] };
type GetUpcomingLiveDatesDeliverablesFailureActionType = Action<CONTENT_BOARD_ACTIONS_TYPES.GET_UPCOMING_LIVE_DATES_DELIVERABLES_FAILURE>;

type PatchOpenedContentBoardRequestActionType = Action<CONTENT_BOARD_ACTIONS_TYPES.PATCH_OPENED_CONTENT_BOARD_REQUEST>;
type PatchOpenedContentBoardSuccessActionType =
  Action<CONTENT_BOARD_ACTIONS_TYPES.PATCH_OPENED_CONTENT_BOARD_SUCCESS>
  & {
  loading?: boolean;
};
type PatchOpenedContentBoardFailureActionType = Action<CONTENT_BOARD_ACTIONS_TYPES.PATCH_OPENED_CONTENT_BOARD_FAILURE>;

type PushDeliverablesToBoardActionType = Action<CONTENT_BOARD_ACTIONS_TYPES.PUSH_DELIVERABLES_TO_BOARD> & {
  payload: IDeliverable[]
};
type ClearOpenedContentBoardActionType = Action<CONTENT_BOARD_ACTIONS_TYPES.CLEAR_OPENED_CONTENT_BOARD>;
type UpdateOpenedContentBoardActionType = Action<CONTENT_BOARD_ACTIONS_TYPES.UPDATE_OPENED_CONTENT_BOARD> & {
  payload: IBoard
};
type UpdateBoardDeliverableItemActionType = Action<CONTENT_BOARD_ACTIONS_TYPES.UPDATE_BOARD_DELIVERABLE_ITEM> & {
  payload: IDeliverable[]
};
type SetContentBoardPageModelActionType = Action<CONTENT_BOARD_ACTIONS_TYPES.SET_CONTENT_BOARD_PAGE_MODEL> & {
  payload: Page<ContentBoardPermission>;
};
type ClearErrorsActionType = Action<CONTENT_BOARD_ACTIONS_TYPES.CLEAR_ERRORS>;

export type ContentBoardActionsTypes =
  | GetContentBoardRequestActionType
  | GetContentBoardSuccessActionType
  | GetContentBoardFailureActionType
  | GetBoardCollaboratorsRequestActionType
  | GetBoardCollaboratorsSuccessActionType
  | GetBoardCollaboratorsFailureActionType
  | GetBoardInvitedCollaboratorsRequestActionType
  | GetBoardInvitedCollaboratorsSuccessActionType
  | GetBoardInvitedCollaboratorsFailureActionType
  | GetBoardDeliverablesRequestActionType
  | GetBoardDeliverablesSuccessActionType
  | GetBoardDeliverablesFailureActionType
  | CreateDeliverablesRequestActionType
  | CreateDeliverablesSuccessActionType
  | CreateDeliverablesFailureActionType
  | GetBoardDeliverablesAssetsRequestActionType
  | GetBoardDeliverablesAssetsSuccessActionType
  | GetBoardDeliverablesAssetsFailureActionType
  | DuplicateDeliverableItemRequestActionType
  | DuplicateDeliverableItemSuccessActionType
  | DuplicateDeliverableItemFailureActionType
  | GetUpcomingLiveDatesDeliverablesRequestActionType
  | GetUpcomingLiveDatesDeliverablesSuccessActionType
  | GetUpcomingLiveDatesDeliverablesFailureActionType
  | PatchOpenedContentBoardRequestActionType
  | PatchOpenedContentBoardSuccessActionType
  | PatchOpenedContentBoardFailureActionType
  | PushDeliverablesToBoardActionType
  | ClearOpenedContentBoardActionType
  | UpdateOpenedContentBoardActionType
  | UpdateBoardDeliverableItemActionType
  | SetContentBoardPageModelActionType
  | ClearErrorsActionType;

export type ContentBoardThunkReturnType<ReturnType = void> = ThunkAction<Promise<ReturnType>, RootState, null, ContentBoardActionsTypes>;
export type ContentBoardSyncThunkReturnType<ReturnType = void> = ThunkAction<ReturnType, RootState, null, ContentBoardActionsTypes>;
