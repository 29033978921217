import React, {FC, useCallback} from "react";
import {useAppDispatch, useAppSelector} from "../../../../shared/hooks/reduxHooks";
import useTooltip, {TOOLTIP_POSITIONS} from "../../../../shared/hooks/fixedTooltip/useTooltip";
import styles from "./EmptyState.module.scss";
import {requestResult} from "../../../../actions/ResultsActions/results.actions";
import ButtonCustom from "../../../../expert/components/Buttons/ButtonCustom";
import {useParams} from "react-router-dom";

const EmptyStateRequest: FC = () => {
  const loading = useAppSelector(state => state.results.requestResultsLoading);
  const params = useParams() as {boardId: string};
  const dispatch = useAppDispatch();


  const {onEnter, toolTip, onLeave} = useTooltip(TOOLTIP_POSITIONS.BOTTOM,
    {text: "Clicking on this button will notify content creators to upload their results", className: styles.tooltip}
  );

  const onRequest = useCallback(() => {
    if (!params.boardId) return;
    dispatch(requestResult(params.boardId))
  }, [params.boardId]);

  return (
    <div className={styles.root}>
      <p className={styles.text}>
        This is where your content creators will upload the results in the form of screenshots. You can also send a
        request to your content creators to ask them to upload the results
      </p>
      <ButtonCustom
        loading={loading}
        className={styles.button}
        onClick={onRequest}
        onMouseEnter={onEnter}
        onMouseLeave={onLeave}
      >
        Ask for results
        {toolTip}
      </ButtonCustom>
    </div>
  )
}

export default EmptyStateRequest;
