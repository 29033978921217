import React from 'react';
import styles from './HeaderOrganizations.module.scss';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';
import { ReactComponent as PlusIcon } from '../../../assets/img/icons/plusIcon.svg';
import { ReactComponent as SearchIcon } from '../../../assets/img/icons/search.svg';
import SearchBar from '../../../components/SearchBar';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

const Header = ({ handleSearch, clearSearch, searchValue, setSearchValue }) => {
  const history = useHistory();
  const allPlans = useSelector(state => state.payment.allPlans);
  const planId = useSelector(state => state.auth.currentOrganization?.pricing_plan_id);
  const currentPlan = allPlans.find(item => item.id === planId);
  const organizations = useSelector(state => state.auth.organizations);
  const showButton = organizations?.length < currentPlan?.team_members || planId === 1000;

  return (
    <div className={styles.main}>
      <div className={styles.description}>Organizations</div>
      <div className={styles.searchBlock}>
        <SearchIcon className={styles.searchIcon} />
        <SearchBar
          className={styles.searchOrganizations}
          placeholder={'Search'}
          designVersion="V2.1"
          searchValue={searchValue}
          onChangeSearchValue={value => setSearchValue(value)}
          onClear={clearSearch}
          onSubmit={handleSearch}
          disabled={!organizations?.length}
        />
      </div>
      {showButton && !!organizations?.length && (
        <ButtonCustom outline className={styles.customStile} onClick={() => history.push('/create-organization')}>
          <PlusIcon />
          Add Organization
        </ButtonCustom>
      )}
    </div>
  );
};

export default Header;
