import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ModalMedium } from '../../../../../components/Modals';
import styles from './AddDeliverableModal.module.scss';
import HashtagSVG from '../../../../../components/IconsSvg/HashtagSVG';
import { useAppDispatch, useAppSelector, useThunkDispatch } from '../../../../../shared/hooks/reduxHooks';
import { createDeliverables, getUpcomingLiveDate } from '../../../../../actions/ContentBoardActions/contentBoard.actions';
import { useParams } from 'react-router';
import AddDeliverableModalFooter from './components/AddDeliverablesModalFooter';
import AddDeliverablesPlatforms from './components/AddDeliverablesPlatforms';
import { ICreateDeliverableFromBoardDTO } from '../../../../../models/entities/deliverable/dto/CreateDeliverableFromBoard';
import { IDeliverableFormData, UseDeliverablesFormHandlersType } from './hooks/useDeliverablesFormData';
import { FormikProps } from 'formik';
import { ContentBoardPermission } from '../../../../../models/permissions/enum/ContentBoardPermission';
import useBoardCreator from '../../../hooks/useBoardCreator';
import { COLLABORATOR_ROLE_TYPES } from '../../../../../models/permissions/collaborator/CollaboratorRole';

type AddDeliverableModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmitted: () => void;
};

const AddDeliverablesModal: FC<AddDeliverableModalProps> = ({ isOpen, onClose, onSubmitted }) => {
  const dispatch = useAppDispatch();
  const thunkDispatch = useThunkDispatch();
  const params = useParams<{ boardId: string }>();
  const currentOrg = useAppSelector<any>(state => state.auth.currentOrganization);
  const errors = useAppSelector(state => state.contentBoard.createDeliverablesErrorData);
  const isCreatingLoading = useAppSelector(state => state.contentBoard.creatingDeliverablesLoading);
  const pageModel = useAppSelector(state => state.contentBoard.pageModel || null);
  const [submitted, setSubmitted] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [deliverablesLength, setDeliverablesLength] = useState(0);
  const formRef = useRef<(UseDeliverablesFormHandlersType & { submit: FormikProps<IDeliverableFormData>['submitForm'] }) | null>(null);

  const isBoardCreator = useBoardCreator();

  const isContentCreatorCreatedBoard = useMemo(() => {
    if (isBoardCreator === null || !pageModel) return false;

    return pageModel.role.type === COLLABORATOR_ROLE_TYPES.CONTENT_CREATOR && isBoardCreator;
  }, [isBoardCreator, pageModel]);

  const canEditDueDates = useMemo(() => {
    if (!pageModel) return false;

    return pageModel.roleCan(ContentBoardPermission.CAN_EDIT_LIVE_DUE_DATE) || isContentCreatorCreatedBoard;
  }, [pageModel, isContentCreatorCreatedBoard]);

  const canEditNotifications = useMemo(() => {
    if (isBoardCreator === null || !pageModel) return false;

    return isBoardCreator || pageModel.roleCan(ContentBoardPermission.CAN_EDIT_REMINDER_DATE);
  }, [pageModel, isBoardCreator]);

  const onSubmit = useCallback(
    (deliverables: ICreateDeliverableFromBoardDTO[]) => {
      thunkDispatch(createDeliverables(currentOrg.organization_id, params.boardId, deliverables)).then(() => {
        if (deliverables.some(deliverable => !!deliverable.live_datetime)) dispatch(getUpcomingLiveDate(params.boardId));
      });
      setSubmitted(true);
    },
    [currentOrg?.organization_id, params.boardId]
  );

  useEffect(() => {
    if (submitted && !errors.length && !isCreatingLoading) {
      onSubmitted();
      setSubmitted(false);
      onClear();
    }
  }, [errors, isCreatingLoading, submitted]);

  const onClear = useCallback(() => {
    onClose();
    formRef.current?.deleteAllDeliverables();
  }, [onClose]);

  const onChangeDeliverables = useCallback((deliverables: IDeliverableFormData[]) => {
    setDeliverablesLength(deliverables.length);
  }, []);

  return (
    <ModalMedium onClose={onClear} open={isOpen} maxWidth={canEditDueDates ? 900 : 600} classNames={styles.modal}>
      <div className={styles.root}>
        <div className={styles.header}>
          <div className={styles.headerContent}>
            <div className={styles.headerContentIconWrapper}>
              <HashtagSVG />
            </div>
            <h3 className={styles.headerContentTitle}>Add Deliverables</h3>
          </div>
        </div>
        <div className={styles.body}>
          <AddDeliverablesPlatforms
            canEditNotifications={canEditNotifications}
            ref={formRef}
            onSubmit={onSubmit}
            setIsValid={setCanSubmit}
            onValuesWereChanged={onChangeDeliverables}
            canEditDueDates={canEditDueDates}
          />
        </div>
      </div>
      <AddDeliverableModalFooter
        canSubmit={canSubmit && !!deliverablesLength}
        onClear={onClear}
        onSubmit={() => formRef.current?.submit()}
      />
    </ModalMedium>
  );
};

export default AddDeliverablesModal;
