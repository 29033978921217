import { INotification } from '../../models/Notifications';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../store';

export enum TOAST_NOTIFICATION_ACTION_TYPES {
  CREATE_NOTIFICATIONS = 'CREATE_NOTIFICATIONS',
  DELETE_NOTIFICATIONS = 'DELETE_NOTIFICATIONS',
}

export interface IStateToastNotification extends INotification {
  id: string;
}

type createNotificationsType = Action<TOAST_NOTIFICATION_ACTION_TYPES.CREATE_NOTIFICATIONS> & { payload: IStateToastNotification };
type deleteNotificationsType = Action<TOAST_NOTIFICATION_ACTION_TYPES.DELETE_NOTIFICATIONS> & { payload: IStateToastNotification };

export type ToastNotificationsActionsType = createNotificationsType | deleteNotificationsType;

export type ToastTonitificationThunkReturnType<ReturnType = void> = ThunkAction<
  Promise<ReturnType>,
  RootState,
  null,
  ToastNotificationsActionsType
>;
