import React, { FC, useCallback, useMemo, useState } from 'react';
import cs from 'classnames';
import styles from './DueDatePickerInputsSection.module.scss';
import TextField from '../../../../FormControls/TextField';
import SelectDropdown from '../../../../../expert/components/FormControls/SelectDropdown';
import { ReactComponent as ClockIcon } from '../../../../../assets/img/icons/purple_clock.svg';
import { ReactComponent as CalendarIcon } from '../../../../../assets/img/icons/calendarIcon.svg';
import { ReactComponent as CrossIcon } from '../../../../../assets/img/icons/close.svg';
import ButtonCustom from '../../../../../expert/components/Buttons/ButtonCustom';
import useTooltip, { TOOLTIP_POSITIONS } from '../../../../../shared/hooks/fixedTooltip/useTooltip';
import moment from 'moment';
import timeArray, { maxTimeIndex } from '../../constants/timeArray';
import { inputFormat } from '../../constants/timeFormats';
import ButtonLightPurpleWhite from '../../../../Buttons/ButtonLightPurpleWhite';

type PropsType = {
  mainDateTitle?: string;
  mainInputPlaceHolder?: string;
  disableReminder?: boolean;
  mainDate: string | null;
  reminderDate?: string | null;
  activeDateInput: 'main' | 'reminder';
  onChangeActiveDateInput: (value: 'main' | 'reminder') => void;
  minMainTimeIndex: number | null;
  maxMainTimeIndex: number | null;
  mainTimeIndex: number | null;
  minReminderTimeIndex: number | null;
  maxReminderTimeIndex: number | null;
  reminderTimeIndex: number | null;
  onClearHandler: () => void;
  onSubmitHandler: () => void;
  reminderDateText?: string;
  reminderRecipientName?: string;
  onClearMainDate: () => void;
  onClearReminderDate: () => void;
  onClearMainTime: () => void;
  onClearReminderTime: () => void;
  onChangeMainTime: (index: number) => void;
  onChangeReminderTime: (index: number) => void;
  onAddReminder?: () => void;
  onAddMainTime?: () => void;
};

const DueDatePickerInputsSection: FC<PropsType> = props => {
  const [withMainTime, setWithMainTime] = useState<boolean>(!!props.mainDate);
  const [isReminderActive, setIsReminderActive] = useState<boolean>(!!props.reminderDate && !props.disableReminder);
  const [withReminderTime, setWithReminderTime] = useState<boolean>(props.reminderTimeIndex !== null && !props.disableReminder);
  const [isTimeInputActive, setIsTimeInputActive] = useState<boolean>(false);

  const changeActiveDateInput = (inputType: 'main' | 'reminder') => props.onChangeActiveDateInput(inputType);
  const getTimeIndex = (index: number | null, minIndex: number | null) => {
    if (!index) return 0;
    if (!minIndex) return index || 0;
    return index - minIndex;
  };

  const mainTimeIndex = useMemo(
    () => getTimeIndex(props.mainTimeIndex, props.minMainTimeIndex),
    [props.minMainTimeIndex, props.mainTimeIndex]
  );
  const reminderTimeIndex = useMemo(
    () => getTimeIndex(props.reminderTimeIndex, props.minReminderTimeIndex),
    [props.reminderTimeIndex, props.minReminderTimeIndex]
  );

  const {
    toolTip: mainDateTooltip,
    onEnter: onEnterMainDateTime,
    onLeave: onLeaveMainDateTime,
  } = useTooltip(TOOLTIP_POSITIONS.BOTTOM, { text: 'Add Time', className: styles.tooltip });

  const {
    toolTip: reminderTooltip,
    onEnter: onEnterReminderTime,
    onLeave: onLeaveReminderTime,
  } = useTooltip(TOOLTIP_POSITIONS.BOTTOM, { text: 'Add Time', className: styles.tooltip });

  const onClearMainTime = useCallback(() => {
    setWithMainTime(false);
    props.onClearMainTime();
  }, [props.onClearMainTime]);

  const onClearReminderTime = useCallback(() => {
    setWithReminderTime(false);
    props.onClearReminderTime();
  }, [props.onClearReminderTime]);

  const onClearMainDate = useCallback(() => {
    setWithMainTime(false);
    setIsReminderActive(false);
    setWithReminderTime(false);
    changeActiveDateInput('main');
    props.onClearMainDate();
  }, [props.onClearMainDate]);

  const onClearReminderDate = useCallback(() => {
    setWithReminderTime(false);
    setWithReminderTime(false);
    props.onClearReminderDate();
  }, [props.onClearReminderDate]);

  const onAddReminder = useCallback(() => {
    setIsReminderActive(true);
    changeActiveDateInput('reminder');
    props.onAddReminder && props.onAddReminder();
  }, [props.onAddReminder]);

  const onToggleTimeDropdown = useCallback((value: boolean, input: 'main' | 'reminder') => {
    if (value) {
      changeActiveDateInput(input);
    }

    setIsTimeInputActive(value);
  }, []);

  const onSetMainTime = useCallback(
    (index: number) => {
      const parsedIndex = props.minMainTimeIndex ? index + props.minMainTimeIndex : index;
      props.onChangeMainTime(parsedIndex);
    },
    [props.minMainTimeIndex, props.onChangeMainTime]
  );

  const onSetReminderTime = useCallback(
    (index: number) => {
      const parsedIndex = props.minReminderTimeIndex ? index + props.minReminderTimeIndex : index;
      props.onChangeReminderTime(parsedIndex);
    },
    [props.minReminderTimeIndex, props.onChangeReminderTime]
  );

  return (
    <div className={cs(styles.dateBlock)}>
      <div className={styles.dateInputSection}>
        <div className={styles.dateInputHeader}>
          <div className={cs(styles.dateIconWrapper, styles.mainDateIcon)}>
            <CalendarIcon />
          </div>
          <p className={styles.dateTitle}>{props.mainDateTitle}</p>
        </div>
        <div className={cs(styles.dateInputsContainer, { [styles.timeSet]: withMainTime })}>
          <div
            className={cs(styles.dateInputWrapper, styles.mainDateDot, {
              [styles.dot]: !!props.mainDate,
            })}
          >
            <TextField
              readOnly
              name={'mainDate'}
              placeholder={props.mainInputPlaceHolder}
              className={cs(styles.input, styles.dueDateInput, {
                [styles.activeMainDateInput]: props.activeDateInput === 'main' && !isTimeInputActive,
                [styles.inputsPlaceholder]: !props.mainDate,
              })}
              value={props.mainDate ? moment.utc(props.mainDate).format(inputFormat) : ''}
              onFocus={() => changeActiveDateInput('main')}
              icon={<CrossIcon className={cs(styles.crossIcon, { [styles.crossIconHidden]: !props.mainDate })} onClick={onClearMainDate} />}
            />
          </div>
          {!withMainTime && (
            <span
              className={cs(styles.clockIcon, styles.mainDateClockIcon)}
              onMouseEnter={onEnterMainDateTime}
              onMouseLeave={onLeaveMainDateTime}
              onClick={() => {
                setWithMainTime(true);
                props.onAddMainTime && props.onAddMainTime();
              }}
            >
              <ClockIcon />
              {mainDateTooltip}
            </span>
          )}
          {withMainTime && (
            <div>
              <SelectDropdown
                // @ts-ignore
                isOnStartOpen={!props.mainTimeIndex && !props.mainDate}
                options={timeArray.slice(
                  props.minMainTimeIndex || 0,
                  props.maxMainTimeIndex !== null ? props.maxMainTimeIndex + 1 : maxTimeIndex
                )}
                selectedId={mainTimeIndex}
                onSelected={onSetMainTime}
                onClear={onClearMainTime}
                optionKey={time => time}
                className={styles.timeSelect}
                inputClassName={styles.timeSelectHead}
                activeInputClassName={styles.activeMainDateInput}
                dropdownClassName={styles.timeSelectDropDown}
                placeholderClassName={styles.inputsPlaceholder}
                IconArrow={() => null}
                IconCross={props.mainTimeIndex !== null ? CrossIcon : null}
                onToggleDropdown={isOpen => onToggleTimeDropdown(isOpen, 'main')}
              />
            </div>
          )}
        </div>
      </div>

      {!props.disableReminder && props.mainDate && (
        <>
          {!isReminderActive && (
            <ButtonLightPurpleWhite className={styles.addReminderButton} onClick={onAddReminder} presetIcon={'plus'} designVersion={'v2'}>
              Add reminder for creator
            </ButtonLightPurpleWhite>
          )}
          {isReminderActive && (
            <div className={styles.dateInputSection}>
              <div className={styles.dateInputHeader}>
                <div className={cs(styles.dateIconWrapper, styles.reminderDateIcon)}>
                  <CalendarIcon />
                </div>
                <p className={styles.dateTitle}>{props.reminderDateText}</p>
              </div>
              <div
                className={cs(styles.dateInputsContainer, {
                  [styles.timeSet]: withReminderTime,
                })}
              >
                <div className={cs(styles.dateInputWrapper, styles.reminderDateDot, { [styles.dot]: !!props.reminderDate })}>
                  <TextField
                    value={props.reminderDate ? moment(props.reminderDate).format(inputFormat) : ''}
                    readOnly
                    name={'reminderDate'}
                    placeholder={'Pick Reminder Date'}
                    className={cs(styles.input, styles.dueDateInput, {
                      [styles.activeReminderInput]: props.activeDateInput === 'reminder' && !isTimeInputActive,
                      [styles.inputsPlaceholder]: !props.mainDate,
                    })}
                    onFocus={() => {
                      changeActiveDateInput('reminder');
                    }}
                    icon2={
                      <CrossIcon
                        className={cs(styles.crossIcon, { [styles.crossIconHidden]: !props.reminderDate })}
                        onClick={onClearReminderDate}
                      />
                    }
                  />
                </div>
                {!withReminderTime && (
                  <span
                    className={cs(styles.clockIcon, styles.reminderClockIcon)}
                    onMouseEnter={onEnterReminderTime}
                    onMouseLeave={onLeaveReminderTime}
                    onClick={() => setWithReminderTime(true)}
                  >
                    <ClockIcon />
                    <div>{reminderTooltip}</div>
                  </span>
                )}
                {withReminderTime && (
                  <SelectDropdown
                    options={timeArray.slice(
                      props.minReminderTimeIndex || 0,
                      props.maxReminderTimeIndex !== null ? props.maxReminderTimeIndex + 1 : maxTimeIndex
                    )}
                    selectedId={reminderTimeIndex}
                    onSelected={onSetReminderTime}
                    optionKey={time => time}
                    className={styles.timeSelect}
                    inputClassName={styles.timeSelectHead}
                    activeInputClassName={styles.activeReminderInput}
                    dropdownClassName={styles.timeSelectDropDown}
                    placeholderClassName={styles.inputsPlaceholder}
                    isOnStartOpen={!props.reminderTimeIndex && !props.reminderDate}
                    IconArrow={() => null}
                    IconCross={props.reminderTimeIndex !== null ? CrossIcon : null}
                    onClear={onClearReminderTime}
                    onToggleDropdown={isOpen => onToggleTimeDropdown(isOpen, 'reminder')}
                  />
                )}
              </div>
              {isReminderActive && props.reminderRecipientName && (
                <p className={styles.reminderNotificationText}>
                  Email reminder will be sent to <span className={styles.reminderNotificationTextName}>{props.reminderRecipientName}</span>
                </p>
              )}
            </div>
          )}
        </>
      )}

      <div className={styles.footer}>
        <div className={styles.buttons}>
          <ButtonCustom onClick={props.onClearHandler} outline className={styles.clearButton}>
            Remove All
          </ButtonCustom>
          <ButtonCustom onClick={props.onSubmitHandler}>{'Save'}</ButtonCustom>
        </div>
      </div>
    </div>
  );
};

export default DueDatePickerInputsSection;
