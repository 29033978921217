import React, { ReactElement, useEffect, useState } from 'react';
import FacebookPreview from '../../../../components/PreviewCard/FacebookPreview';
import PreviewStorySlider from '../../../../components/PreviewStorySlider';
import InstagramPreview from '../../../../components/PreviewCard/InstagramPreview';
import SimplePreview from '../../../../components/PreviewCard/SimplePreview';
import FormattedText from '../../../CampaignBrief/CampaignBriefPage/components/FormattedText';
import { PLATFORMS } from '../../../../models/enums/Platforms';
import { PLACEMENTS } from '../../../../models/enums/Placements';
import { useAppSelector } from '../../../../shared/hooks/reduxHooks';
import { AssetStateType } from '../../../../models/entities/asset/AssetState';
import { IAssetMedia } from '../../../../models/entities/asset/AssetMedia';
import { MEDIA_ORIENTATIONS } from '../../../../models/enums/ImageOrientations';
import { IAssetMediaStorySlider } from '../../../../models/entities/asset/AssetMediaStorySlider';
import { IDeliverable } from '../../../../models/entities/deliverable/Deliverable';
import { IConceptSection } from '../../../../models/entities/deliverableSections/ConceptSection';
import { IContentSection } from '../../../../models/entities/deliverableSections/ContentSection';
import { INPUT_FILE_ALLOWED_TYPE } from '../../../../shared/constants/tsConstants';

type AvailablePlatforms = PLATFORMS[];
type AvailablePlacements = {
  [PLATFORMS.FACEBOOK]: [PLACEMENTS.STATIC_POST, PLACEMENTS.VIDEO_POST, PLACEMENTS.STORY_POST, PLACEMENTS.REEL];
  [PLATFORMS.INSTAGRAM]: [
    PLACEMENTS.STATIC_POST,
    PLACEMENTS.VIDEO_POST,
    PLACEMENTS.STORY_POST,
    PLACEMENTS.REEL,
    PLACEMENTS.CAROUSEL,
    PLACEMENTS.HIGHLIGHT,
    PLACEMENTS.LIVE
  ];
  [PLATFORMS.YOUTUBE]: [PLACEMENTS.VIDEO_POST];
  [PLATFORMS.TIKTOK]: [PLACEMENTS.VIDEO_POST];
  [PLATFORMS.BLOG_POST]: [PLACEMENTS.STATIC_POST, PLACEMENTS.VIDEO_POST];
};

export type PreviewSettings = {
  visualsMaxCount: number;
  fileTypes: INPUT_FILE_ALLOWED_TYPE[];
  isStoryTile: boolean;
  isVerticalView: boolean;
  isCallToAction: boolean;
  renderPreviewComponent: (props: {
    assets: AssetStateType[];
    callToAction: string | null;
    description?: string;
    initialIndex?: number;
    mediaAssets: IAssetMedia[];
  }) => ReactElement;
};

type PreviewSettingsConfig = {
  [PLATFORMS.FACEBOOK]: {
    [PLACEMENTS.STATIC_POST]: PreviewSettings;
    [PLACEMENTS.REEL]: PreviewSettings;
    [PLACEMENTS.STORY_POST]: PreviewSettings;
    [PLACEMENTS.VIDEO_POST]: PreviewSettings;
  };
  [PLATFORMS.INSTAGRAM]: {
    [PLACEMENTS.STATIC_POST]: PreviewSettings;
    [PLACEMENTS.REEL]: PreviewSettings;
    [PLACEMENTS.STORY_POST]: PreviewSettings;
    [PLACEMENTS.VIDEO_POST]: PreviewSettings;
    [PLACEMENTS.LIVE]: PreviewSettings;
    [PLACEMENTS.CAROUSEL]: PreviewSettings;
    [PLACEMENTS.HIGHLIGHT]: PreviewSettings;
  };
  [PLATFORMS.YOUTUBE]: {
    [PLACEMENTS.VIDEO_POST]: PreviewSettings;
  };
  [PLATFORMS.TIKTOK]: {
    [PLACEMENTS.VIDEO_POST]: PreviewSettings;
  };
  [PLATFORMS.BLOG_POST]: {
    [PLACEMENTS.VIDEO_POST]: PreviewSettings;
    [PLACEMENTS.STATIC_POST]: PreviewSettings;
  };
};

const createPreviewStorySliderItem = (mediaAsset: IAssetMedia, assets: AssetStateType[]): IAssetMediaStorySlider => {
  const asset = assets.find(asset => asset.id === mediaAsset.id)!;
  return {
    ...mediaAsset,
    mime_type: mediaAsset.isVideoProcessing ? 'image/png' : asset.mime_type,
    copy: <FormattedText text={asset?.caption || ''} withoutCollapse />,
  };
};

const createPreviewUrl = (asset: IAssetMedia): IAssetMedia['url'] => {
  return asset.url;
};

const settingsConfig: PreviewSettingsConfig = {
  facebook: {
    static_post: {
      visualsMaxCount: 10,
      fileTypes: [INPUT_FILE_ALLOWED_TYPE.IMAGE_INPUT_ALLOWED],
      isStoryTile: false,
      isVerticalView: false,
      isCallToAction: true,
      renderPreviewComponent: ({ callToAction, description, mediaAssets }) => (
        <FacebookPreview
          callToAction={callToAction}
          primaryText={<FormattedText text={description} withoutCollapse />}
          preview={mediaAssets.map(createPreviewUrl)}
          orientation={MEDIA_ORIENTATIONS.SQUARE}
        />
      ),
    },
    video_post: {
      visualsMaxCount: 10,
      fileTypes: [INPUT_FILE_ALLOWED_TYPE.VIDEO_INPUT_ALLOWED],
      isStoryTile: false,
      isVerticalView: false,
      isCallToAction: true,
      renderPreviewComponent: ({ callToAction, description, mediaAssets }) => {
        return (
          <FacebookPreview
            preview={mediaAssets.filter(item => item.url)}
            callToAction={callToAction}
            primaryText={<FormattedText text={description} withoutCollapse />}
            isVideoPreview={true}
            orientation={MEDIA_ORIENTATIONS.SQUARE}
          />
        );
      },
    },
    story_post: {
      visualsMaxCount: 10,
      fileTypes: [INPUT_FILE_ALLOWED_TYPE.IMAGE_INPUT_ALLOWED, INPUT_FILE_ALLOWED_TYPE.VIDEO_INPUT_ALLOWED],
      isStoryTile: true,
      isVerticalView: true,
      isCallToAction: true,
      renderPreviewComponent: ({ assets, callToAction, initialIndex, mediaAssets }) => (
        <PreviewStorySlider
          callToAction={callToAction}
          assets={mediaAssets.map(mediaAsset => createPreviewStorySliderItem(mediaAsset, assets)).filter(item => item.url)}
          initialIndex={initialIndex}
        />
      ),
    },
    reel: {
      visualsMaxCount: 1,
      fileTypes: [INPUT_FILE_ALLOWED_TYPE.VIDEO_INPUT_ALLOWED],
      isStoryTile: true,
      isVerticalView: false,
      isCallToAction: true,
      renderPreviewComponent: ({ mediaAssets, callToAction, description }) => {
        const asset = mediaAssets[0];
        return (
          <SimplePreview
            orientation={MEDIA_ORIENTATIONS.PORTRAIT}
            preview={asset?.url || ''}
            isPlaceholder={asset?.isVideoProcessing || false}
            callToAction={callToAction}
            primaryText={<FormattedText text={description} withoutCollapse />}
            isVideoPreview={true}
          />
        );
      },
    },
  },
  instagram: {
    static_post: {
      visualsMaxCount: 10,
      fileTypes: [INPUT_FILE_ALLOWED_TYPE.IMAGE_INPUT_ALLOWED],
      isStoryTile: false,
      isVerticalView: false,
      isCallToAction: true,
      renderPreviewComponent: ({ callToAction, description, mediaAssets }) => (
        <InstagramPreview
          preview={mediaAssets.filter(item => item.url)}
          callToAction={callToAction}
          primaryText={<FormattedText text={description} withoutCollapse />}
          orientation={MEDIA_ORIENTATIONS.SQUARE}
        />
      ),
    },
    carousel: {
      visualsMaxCount: 10,
      fileTypes: [INPUT_FILE_ALLOWED_TYPE.VIDEO_INPUT_ALLOWED, INPUT_FILE_ALLOWED_TYPE.IMAGE_INPUT_ALLOWED],
      isStoryTile: false,
      isVerticalView: false,
      isCallToAction: true,
      renderPreviewComponent: ({ callToAction, description, mediaAssets }) => (
        <InstagramPreview
          preview={mediaAssets.filter(item => item.url)}
          callToAction={callToAction}
          primaryText={<FormattedText text={description} withoutCollapse />}
          orientation={MEDIA_ORIENTATIONS.SQUARE}
        />
      ),
    },
    video_post: {
      visualsMaxCount: 10,
      fileTypes: [INPUT_FILE_ALLOWED_TYPE.VIDEO_INPUT_ALLOWED],
      isStoryTile: false,
      isVerticalView: false,
      isCallToAction: true,
      renderPreviewComponent: ({ callToAction, description, mediaAssets }) => {
        return (
          <InstagramPreview
            orientation={MEDIA_ORIENTATIONS.SQUARE}
            preview={mediaAssets.filter(item => item.url)}
            callToAction={callToAction}
            primaryText={<FormattedText text={description} withoutCollapse />}
          />
        );
      },
    },
    story_post: {
      visualsMaxCount: 10,
      fileTypes: [INPUT_FILE_ALLOWED_TYPE.IMAGE_INPUT_ALLOWED, INPUT_FILE_ALLOWED_TYPE.VIDEO_INPUT_ALLOWED],
      isStoryTile: true,
      isVerticalView: true,
      isCallToAction: true,
      renderPreviewComponent: ({ assets, callToAction, initialIndex, mediaAssets }) => (
        <PreviewStorySlider
          callToAction={callToAction}
          assets={mediaAssets.map(mediaAsset => createPreviewStorySliderItem(mediaAsset, assets)).filter(item => item.url)}
          initialIndex={initialIndex}
        />
      ),
    },
    highlight: {
      visualsMaxCount: 10,
      fileTypes: [INPUT_FILE_ALLOWED_TYPE.IMAGE_INPUT_ALLOWED, INPUT_FILE_ALLOWED_TYPE.VIDEO_INPUT_ALLOWED],
      isStoryTile: true,
      isVerticalView: true,
      isCallToAction: true,
      renderPreviewComponent: ({ assets, callToAction, initialIndex, mediaAssets }) => (
        <PreviewStorySlider
          callToAction={callToAction}
          assets={mediaAssets.map(mediaAsset => createPreviewStorySliderItem(mediaAsset, assets)).filter(item => item.url)}
          initialIndex={initialIndex}
        />
      ),
    },
    live: {
      visualsMaxCount: 1,
      fileTypes: [INPUT_FILE_ALLOWED_TYPE.VIDEO_INPUT_ALLOWED],
      isStoryTile: true,
      isVerticalView: false,
      isCallToAction: false,
      renderPreviewComponent: ({ mediaAssets, callToAction, description }) => {
        const asset = mediaAssets[0];

        return (
          <SimplePreview
            orientation={MEDIA_ORIENTATIONS.PORTRAIT}
            preview={asset?.url || ''}
            isPlaceholder={asset?.isVideoProcessing || false}
            callToAction={callToAction}
            primaryText={<FormattedText text={description} withoutCollapse />}
            isVideoPreview={true}
          />
        );
      },
    },
    reel: {
      visualsMaxCount: 1,
      fileTypes: [INPUT_FILE_ALLOWED_TYPE.VIDEO_INPUT_ALLOWED],
      isStoryTile: true,
      isVerticalView: false,
      isCallToAction: true,
      renderPreviewComponent: ({ mediaAssets, callToAction, description }) => {
        const asset = mediaAssets[0];
        return (
          <SimplePreview
            orientation={MEDIA_ORIENTATIONS.PORTRAIT}
            preview={asset?.url || ''}
            isPlaceholder={asset?.isVideoProcessing || false}
            callToAction={callToAction}
            primaryText={<FormattedText text={description} withoutCollapse />}
            isVideoPreview={true}
          />
        );
      },
    },
  },
  youtube: {
    video_post: {
      visualsMaxCount: 10,
      fileTypes: [INPUT_FILE_ALLOWED_TYPE.VIDEO_INPUT_ALLOWED],
      isStoryTile: false,
      isVerticalView: false,
      isCallToAction: true,
      renderPreviewComponent: ({ callToAction, description, mediaAssets }) => {
        return (
          <SimplePreview
            orientation={MEDIA_ORIENTATIONS.LANDSCAPE}
            preview={mediaAssets.filter(item => item.url)}
            callToAction={callToAction}
            primaryText={<FormattedText text={description} withoutCollapse />}
            isVideoPreview={true}
          />
        );
      },
    },
  },
  tiktok: {
    video_post: {
      visualsMaxCount: 10,
      fileTypes: [INPUT_FILE_ALLOWED_TYPE.VIDEO_INPUT_ALLOWED],
      isStoryTile: true,
      isVerticalView: false,
      isCallToAction: true,
      renderPreviewComponent: ({ callToAction, description, mediaAssets }) => {
        return (
          <SimplePreview
            orientation={MEDIA_ORIENTATIONS.PORTRAIT}
            preview={mediaAssets.filter(item => item.url)}
            callToAction={callToAction}
            primaryText={<FormattedText text={description} withoutCollapse />}
            isVideoPreview={true}
          />
        );
      },
    },
  },
  blog_post: {
    video_post: {
      visualsMaxCount: 1,
      fileTypes: [INPUT_FILE_ALLOWED_TYPE.VIDEO_INPUT_ALLOWED],
      isStoryTile: false,
      isVerticalView: false,
      isCallToAction: false,
      renderPreviewComponent: ({ callToAction, description, mediaAssets }) => {
        const asset = mediaAssets[0];
        return (
          <SimplePreview
            orientation={MEDIA_ORIENTATIONS.LANDSCAPE}
            preview={asset ? asset.url : ''}
            callToAction={callToAction}
            primaryText={<FormattedText text={description} withoutCollapse />}
            isVideoPreview={true}
          />
        );
      },
    },
    static_post: {
      visualsMaxCount: 1,
      fileTypes: [INPUT_FILE_ALLOWED_TYPE.IMAGE_INPUT_ALLOWED],
      isStoryTile: false,
      isVerticalView: false,
      isCallToAction: false,
      renderPreviewComponent: ({ callToAction, description, mediaAssets }) => {
        const asset = mediaAssets[0];

        return (
          <SimplePreview
            preview={asset ? asset.url : ''}
            callToAction={callToAction}
            primaryText={<FormattedText text={description} withoutCollapse />}
            orientation={MEDIA_ORIENTATIONS.LANDSCAPE}
            isVideoPreview={asset?.isVideo}
          />
        );
      },
    },
  },
};

const availablePlatforms: AvailablePlatforms = [
  PLATFORMS.FACEBOOK,
  PLATFORMS.INSTAGRAM,
  PLATFORMS.YOUTUBE,
  PLATFORMS.TIKTOK,
  PLATFORMS.BLOG_POST,
];
const availablePlacements: AvailablePlacements = {
  [PLATFORMS.FACEBOOK]: [PLACEMENTS.STATIC_POST, PLACEMENTS.VIDEO_POST, PLACEMENTS.STORY_POST, PLACEMENTS.REEL],
  [PLATFORMS.INSTAGRAM]: [
    PLACEMENTS.STATIC_POST,
    PLACEMENTS.VIDEO_POST,
    PLACEMENTS.STORY_POST,
    PLACEMENTS.REEL,
    PLACEMENTS.CAROUSEL,
    PLACEMENTS.HIGHLIGHT,
    PLACEMENTS.LIVE,
  ],
  [PLATFORMS.YOUTUBE]: [PLACEMENTS.VIDEO_POST],
  [PLATFORMS.TIKTOK]: [PLACEMENTS.VIDEO_POST],
  [PLATFORMS.BLOG_POST]: [PLACEMENTS.STATIC_POST, PLACEMENTS.VIDEO_POST],
};

const usePlatformPlacementSettings = () => {
  const openedDeliverable = useAppSelector<{
    deliverable?: IDeliverable | null;
    concept?: IConceptSection | null;
    content?: IContentSection | null;
  } | null>(state => state.deliverables.openedDeliverable);
  const [settings, setSettings] = useState<PreviewSettings | null>(null);

  useEffect(() => {
    if (openedDeliverable?.deliverable && openedDeliverable?.deliverable.platform && openedDeliverable?.deliverable.placement) {
      if (
        !availablePlatforms.includes(openedDeliverable?.deliverable.platform) ||
        !availablePlacements[openedDeliverable?.deliverable.platform].some(
          placement => placement === openedDeliverable?.deliverable?.placement
        )
      ) {
        return;
      }

      setSettings(settingsConfig[openedDeliverable?.deliverable.platform][openedDeliverable?.deliverable.placement]);
    }
  }, [openedDeliverable]);

  return { settings };
};

export default usePlatformPlacementSettings;
