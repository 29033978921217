import { BOARD_BRIEF_ACTION_TYPES, BoardBriefActionsType, BoardBriefThunkReturnType } from './types';
import * as boardBriefService from '../../services/BoardBriefService';
import * as boardBriefServices from '../../services/BoardBriefService';
import { ViewBriefHydrator } from '../../models/entities/boardBrief/hydrators/ViewBriefHydrator';
import { ICampaignDTO } from '../../models/entities/boardBrief/dto/Campaign';
import { IBriefItemDTO } from '../../models/entities/boardBrief/dto/BriefItem';
import { BriefAssetHydrator } from '../../models/entities/asset/hydrators/BriefAssetHydrator';
import { IBriefAsset } from '../../models/entities/asset/BriefAsset';
import { IBriefAssetDTO } from '../../models/entities/asset/dto/BriefAsset';
import { updateContentBoard } from '../ContentApprovalActions/contentApproval.actions';
import { getContentBoard } from '../ContentBoardActions/contentBoard.actions';
import { UpdateBoardBriefVisualAssetsType } from '../../pages/ContentApproval/createPages/CreateBoardWrapper/CreateBoardBrief/useBriefVisualAssets';
import { IUpdateBoardBriefDTO } from '../../models/entities/boardBrief/dto/UpdateBoardBriefDTO';
import { IUpdateBoardBriefItemDTO } from '../../models/entities/boardBrief/dto/UpdateBoardBriefItemDTO';
import CustomError from '../../models/entities/error/CustomError';
import ErrorHandler from '../../models/entities/error/ErrorHandler';

export const getBoardBrief =
  (
    organizationId: string | number,
    boardId: string | number,
    briefId: string | number,
    briefItemId: string | number
  ): BoardBriefThunkReturnType =>
  async dispatch => {
    dispatch({ type: BOARD_BRIEF_ACTION_TYPES.GET_BOARD_BRIEF_REQUEST });

    const promises: Promise<any>[] = [];
    promises.push(Promise.resolve(boardBriefService.getBoardBrief(organizationId, boardId, briefId)));
    promises.push(Promise.resolve(boardBriefService.getBoardBriefItem(organizationId, boardId, briefId, briefItemId)));

    Promise.allSettled(promises)
      .then(results => {
        for (const result of results) {
          if (result.status === 'rejected' || result.value instanceof Error) {
            dispatch({ type: BOARD_BRIEF_ACTION_TYPES.GET_BOARD_BRIEF_FAILURE, payload: 'Something went wrong...' });
            return;
          }
        }
        const boardBriefDTO: ICampaignDTO = (results[0] as PromiseFulfilledResult<{ data: ICampaignDTO }>).value.data[0];
        const boardBriefItemDTO: IBriefItemDTO = (results[1] as PromiseFulfilledResult<{ data: IBriefItemDTO }>).value.data[0];

        const boardBrief = new ViewBriefHydrator(boardBriefDTO, boardBriefItemDTO);

        dispatch({ type: BOARD_BRIEF_ACTION_TYPES.GET_BOARD_BRIEF_SUCCESS, payload: boardBrief });
      })
      .catch(e => {
        if (e instanceof Error) {
          dispatch({ type: BOARD_BRIEF_ACTION_TYPES.GET_BOARD_BRIEF_FAILURE, payload: 'Something went wrong...' });
        }
      });
  };

export const getBoardBriefAsset =
  (organizationId: string | number, boardId: string | number, briefId: string | number): BoardBriefThunkReturnType =>
  async dispatch => {
    dispatch({ type: BOARD_BRIEF_ACTION_TYPES.GET_BOARD_BRIEF_ASSETS_REQUEST });

    try {
      const response = await boardBriefService.getBoardBriefAssets(organizationId, boardId, briefId);

      const boardBriefAssetsDTOs: IBriefAssetDTO[] = response.data;

      const boardBriefAssets: IBriefAsset[] = boardBriefAssetsDTOs.map(assetDTO => new BriefAssetHydrator(assetDTO));

      dispatch({ type: BOARD_BRIEF_ACTION_TYPES.GET_BOARD_BRIEF_ASSETS_SUCCESS, payload: boardBriefAssets });
    } catch (e) {
      if (e instanceof Error) {
        dispatch({ type: BOARD_BRIEF_ACTION_TYPES.GET_BOARD_BRIEF_ASSETS_FAILURE });
      }
    }
  };

export const createBoardBrief =
  (organizationId: string | number, boardId: string | number, data: any): BoardBriefThunkReturnType<boolean | undefined> =>
  async dispatch =>
    new Promise(async (resolve, reject) => {
      dispatch({ type: BOARD_BRIEF_ACTION_TYPES.CREATE_BOARD_BRIEF_REQUEST });

      try {
        const response = await boardBriefServices
          .createBoardBrief(organizationId, data.briefData)
          .then(async response => {
            const briefItemData = response.data[0].campaign_brief;
            const addedItem = response.data[0].campaign_brief_item;

            if (briefItemData.id) {
              if (data.visualElements.length) {
                await boardBriefServices
                  .addBoardBriefVisualAssets(organizationId, briefItemData.id, data.visualElements)
                  .catch((error: CustomError) => {
                    boardBriefServices.deleteBoardBrief(organizationId, briefItemData.id, addedItem.id);
                    dispatch({ type: BOARD_BRIEF_ACTION_TYPES.CREATE_BOARD_BRIEF_FAILURE, payload: error.getMessage() });
                    reject(error);
                    throw error;
                  });
              }
            }
            return response;
          })
          .catch((error: CustomError) => {
            dispatch({ type: BOARD_BRIEF_ACTION_TYPES.CREATE_BOARD_BRIEF_FAILURE, payload: error.getMessage() });
            reject(error);
            throw error;
          });

        const briefItemData = response.data[0].campaign_brief_item;
        if (briefItemData) {
          await dispatch(updateContentBoard(organizationId, boardId, { campaign_brief_item_id: briefItemData.id }));
        }
        dispatch({ type: BOARD_BRIEF_ACTION_TYPES.CREATE_BOARD_BRIEF_SUCCESS });
        dispatch(getContentBoard(boardId));
        resolve(true);
      } catch (e) {
        const error = e instanceof CustomError ? e : new ErrorHandler(e as Error).getError();
        dispatch({
          type: BOARD_BRIEF_ACTION_TYPES.CREATE_BOARD_BRIEF_FAILURE,
          payload: error.getMessage(),
        });
        reject(e);
      }
    });

export const updateBoardBrief =
  (
    organizationId: string | number,
    boardId: string | number,
    briefId: string | number,
    briefItemId: string | number,
    data: { briefData: IUpdateBoardBriefDTO; briefItemData: IUpdateBoardBriefItemDTO; visualElements: UpdateBoardBriefVisualAssetsType }
  ): BoardBriefThunkReturnType =>
  async dispatch =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: BOARD_BRIEF_ACTION_TYPES.UPDATE_BOARD_BRIEF_REQUEST });
        const { toCreate, toUpdate, toDelete } = data.visualElements;

        const promises: Promise<any>[] = [];
        promises.push(Promise.resolve(boardBriefService.updateBoardBrief(organizationId, boardId, briefId, data.briefData)));
        promises.push(
          Promise.resolve(boardBriefService.updateBoardBriefItem(organizationId, boardId, briefId, briefItemId, data.briefItemData))
        );

        if (toCreate.length) {
          const assets = toCreate.map(asset => ({ file: asset.file as File, description: asset.description }));
          promises.push(Promise.resolve(boardBriefService.addToExistingBoardBriefVisualAssets(organizationId, boardId, briefId, assets)));
        }
        if (toUpdate.length) {
          const assets = toUpdate.map(asset => ({ id: asset.id, file: asset.file, description: asset.description }));
          for (const asset of assets) {
            promises.push(Promise.resolve(boardBriefService.updateBoardBriefVisualAsset(organizationId, boardId, briefId, asset)));
          }
        }
        if (toDelete.length) {
          for (const asset of toDelete) {
            promises.push(Promise.resolve(boardBriefService.deleteBoardBriefVisualAsset(organizationId, boardId, briefId, asset.id)));
          }
        }

        await Promise.allSettled(promises).then(results => {
          for (const result of results) {
            if (result.status === 'rejected' || result.value instanceof Error) {
              dispatch({ type: BOARD_BRIEF_ACTION_TYPES.UPDATE_BOARD_BRIEF_FAILURE, payload: 'Something went wrong...' });
              reject((result as PromiseRejectedResult).reason);
              return;
            }
          }
          // @ts-ignore
          resolve(true);
          dispatch({ type: BOARD_BRIEF_ACTION_TYPES.UPDATE_BOARD_BRIEF_SUCCESS });
        });
      } catch (e: any) {
        const error = e instanceof CustomError ? e : new ErrorHandler(e).getError();
        dispatch({ type: BOARD_BRIEF_ACTION_TYPES.UPDATE_BOARD_BRIEF_FAILURE, payload: 'Something went wrong...' });
        reject(error);
      }
    });

export const clearBoardBriefErrors = (): BoardBriefActionsType => ({ type: BOARD_BRIEF_ACTION_TYPES.CLEAR_BOARD_BRIEF_ERRORS });

export const clearBoardBriefState = (): BoardBriefActionsType => ({ type: BOARD_BRIEF_ACTION_TYPES.CLEAR_BOARD_BRIEF_STATE });
