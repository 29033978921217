import React, { FC, useEffect } from 'react';
import styles from './DeliverablesApprovedLabel.module.scss';
import { clearContentBoard, getBoardDeliverables } from '../../../../../actions/ContentBoardActions/contentBoard.actions';
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks/reduxHooks';

type PropsType = {
  boardId: string | number;
};

const DeliverablesApprovedLabel: FC<PropsType> = props => {
  const dispatch = useAppDispatch();
  const deliverablesApprovedCount = useAppSelector(state => state.contentBoard.deliverablesApprovedCount);
  const deliverablesTotalCount = useAppSelector(state => state.contentBoard.deliverablesTotalCount);
  const currentOrg = useAppSelector(state => state.auth.currentOrganization);

  useEffect(() => {
    // get board only if boardId not empty
    if (currentOrg && props.boardId) {
      dispatch(getBoardDeliverables(props.boardId));
    }

    return () => {
      if (props.boardId) {
        dispatch(clearContentBoard());
      }
    };
  }, [currentOrg]);

  return (
    <div className={styles.counterContainer}>
      <span>Approved</span>
      <span className={styles.counter}>
        {deliverablesApprovedCount}/{deliverablesTotalCount}
      </span>
    </div>
  );
};

export default DeliverablesApprovedLabel;
