import React, { FC } from 'react';
import styles from './VisualsCardEmptyState.module.scss';

const VisualsCardEmptyState: FC = () => {
  return (
    <div className={styles.root}>
      <h5 className={styles.title}>There are no visuals yet</h5>
    </div>
  );
};

export default VisualsCardEmptyState;
