import { NOTIFICATIONS_TYPES } from '../actions/notifications.actions';
import * as _ from 'lodash';

const initialState = {
  isOpen: false,
  selectedType: null,
  data: [],
  totalCount: 0,
  totalUnread: 0,
  loading: false,
};

export default (state = initialState, { type, payload, totalCount, totalUnread }) => {
  switch (type) {
    case NOTIFICATIONS_TYPES.SET_IS_OPEN_NOTIFICATIONS: {
      return { ...state, isOpen: payload };
    }

    case NOTIFICATIONS_TYPES.GET_NOTIFICATIONS.REQUEST: {
      return { ...state, loading: true };
    }

    case NOTIFICATIONS_TYPES.GET_NOTIFICATIONS.SUCCESS: {
      return {
        ...state,
        data: _.unionBy(state.data, payload, 'id'),
        totalCount: totalCount,
        totalUnread: state.selectedType ? state.totalUnread : totalUnread,
        loading: false,
      };
    }

    case NOTIFICATIONS_TYPES.GET_NOTIFICATIONS.FAILURE: {
      return { ...state, loading: false, failure: payload };
    }

    case NOTIFICATIONS_TYPES.ADD_NEW_NOTIFICATIONS: {
      const newNotifications = [...state.data];
      newNotifications.unshift(...payload);
      return { ...state, data: newNotifications };
    }

    case NOTIFICATIONS_TYPES.CHANGE_UNREAD: {
      return { ...state, totalUnread: payload.isReplace ? payload.changeNumber : state.totalUnread + payload.changeNumber };
    }

    case NOTIFICATIONS_TYPES.MARK_NOTIFICATIONS: {
      return {
        ...state,
        data: state.data.map(notification => {
          if (payload.includes(notification.id)) {
            return { ...notification, is_read: true };
          }
          return notification;
        }),
        totalUnread: state.totalUnread - payload.length,
      };
    }

    case NOTIFICATIONS_TYPES.SET_NOTIFICATIONS_SELECTED_TYPE:
      return { ...state, selectedType: payload };

    case NOTIFICATIONS_TYPES.CLEAR_DATA: {
      return { ...initialState, isOpen: state.isOpen, selectedType: state.selectedType, totalUnread: state.totalUnread };
    }
    default: {
      return state;
    }
  }
};
