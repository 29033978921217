import React, { FC, useCallback, useMemo, useState } from 'react';
import styles from './ConceptSection.module.scss';
import SectionWrapper from '../components/SectionWrapper';
import SectionEmptyState from '../components/SectionEmptyState';
import ConceptHeaderWrapper from './components/ConceptHeaderWrapper';
import InnerConcept from './components/InnerConcept';
import { DeliverablePermission } from '../../../../../../models/permissions/enum/DeliverablePermission';
import BlurConfirmPopup from '../../../../components/BlurConfirmPopup';
import { ConceptFlowType } from '../../../hooks/useDeliverableState';
import { IAssetMedia } from '../../../../../../models/entities/asset/AssetMedia';
import { AssetStateType } from '../../../../../../models/entities/asset/AssetState';
import { useAppSelector } from '../../../../../../shared/hooks/reduxHooks';
import { DeliverablesReducerState } from '../../../../../../reducers/deliverables.reducer';
import { EMPTY_CONCEPT_STATE_TEXT_CONFIG } from '../constants/emptyStateTextsConfig';
import ButtonWithPurpleRoundedIcon from '../../../../../../components/Buttons/ButtonWithPurpleRoundedIcon';
import useGenerateConceptWithAI from './useGenerateConceptWithAI';

type PropsType = {
  conceptFlow: ConceptFlowType;
  openAsset: (asset: AssetStateType | IAssetMedia) => void;
};

const ConceptSection: FC<PropsType> = props => {
  const isPageLoading = useAppSelector<DeliverablesReducerState['deliverableLoading']>(state => state.deliverables.deliverableLoading);
  const pageModel = useAppSelector<DeliverablesReducerState['pageModel']>(state => state.deliverables.pageModel);
  const [isEditingMode, setIsEditingMode] = useState<boolean>(false);
  const [isOpenConfirmRemove, setIsOpenConfirmRemove] = useState<boolean>(false);
  const [isOpenConfirmCancel, setIsOpenConfirmCancel] = useState<boolean>(false);
  const [isCreateConceptWithGenerateAI, setIsCreateConceptWithGenerateAI] = useState(false);

  const isConceptEmpty: boolean = !props.conceptFlow.concept.description && !props.conceptFlow.conceptAssets.current?.length;

  const [isViewMore, setIsViewMore] = useState<boolean>(isConceptEmpty);

  const isDisplayEmptyState = Boolean(
    (isConceptEmpty && !isEditingMode && !isPageLoading) ||
      (pageModel?.roleCan(DeliverablePermission.DISPLAY_SECTION_EMPTY_STATE) && !props.conceptFlow.concept.isShared && !isPageLoading)
  );

  const onViewMoreClick = () => {
    setIsViewMore(!isViewMore);
  };

  const onOpenConfirmRemoveAccept = useCallback(() => {
    setIsOpenConfirmRemove(false);
    setIsEditingMode(false);
    props.conceptFlow.concept.removeConcept();
  }, [props.conceptFlow]);

  const onOpenConfirmRemoveDiscard = useCallback(() => {
    setIsOpenConfirmRemove(false);
  }, []);

  const onOpenConfirmCancelAccept = useCallback(() => {
    setIsOpenConfirmCancel(false);
    setIsEditingMode(false);
    props.conceptFlow.conceptEdit.restoreConcept();
  }, [props.conceptFlow]);

  const onOpenConfirmDiscard = useCallback(() => {
    setIsOpenConfirmCancel(false);
  }, []);

  const onEdit = () => {
    props.conceptFlow.conceptEdit.checkpointConcept();
    setIsViewMore(true);
    setIsEditingMode(true);
  };

  const onRemove = () => {
    setIsOpenConfirmRemove(true);
    setIsViewMore(true);
  };

  const { isGenerateAvailable } = useGenerateConceptWithAI(true);

  const canRemove: boolean = useMemo(() => {
    return !!pageModel?.roleCan(DeliverablePermission.CAN_REMOVE_SECTION);
  }, [pageModel]);

  const onAddConceptClick = () => {
    props.conceptFlow.conceptEdit.checkpointConcept();
    setIsEditingMode(true);
    setIsCreateConceptWithGenerateAI(false);
  };

  const onGenerateClick = () => {
    props.conceptFlow.conceptEdit.checkpointConcept();
    setIsEditingMode(true);
    setIsCreateConceptWithGenerateAI(true);
  };

  return (
    <SectionWrapper>
      <div className={styles.root}>
        <ConceptHeaderWrapper
          conceptFlow={props.conceptFlow}
          isDisplayQuestion={isEditingMode || !isConceptEmpty}
          onEdit={onEdit}
          onRemove={canRemove ? onRemove : null}
          isEditingMode={isEditingMode}
          isDisplayEmptyState={isDisplayEmptyState}
        />

        {isDisplayEmptyState && !isPageLoading && (
          <SectionEmptyState
            onAddConcept={onAddConceptClick}
            text={EMPTY_CONCEPT_STATE_TEXT_CONFIG[pageModel?.role.type || ''] || ''}
            buttonText="Add Concept"
            additionalButtons={
              <>
                {pageModel?.roleCan(DeliverablePermission.DISPLAY_ADD_SECTION_BUTTON) && (
                  <ButtonWithPurpleRoundedIcon
                    className={styles.button}
                    presetIcon={'plus'}
                    size={'medium'}
                    onClick={onGenerateClick}
                    disabled={!isGenerateAvailable}
                    tooltipText={
                      !isGenerateAvailable ? 'Upload or add a brief to the board to generate concept ideas with Otterfish AI' : ''
                    }
                  >
                    Generate ideas with AI
                  </ButtonWithPurpleRoundedIcon>
                )}
              </>
            }
          />
        )}

        {!isDisplayEmptyState && (
          <InnerConcept
            isConceptEmpty={isConceptEmpty}
            setIsCreateConceptWithGenerateAI={setIsCreateConceptWithGenerateAI}
            isEditingMode={isEditingMode}
            conceptFlow={props.conceptFlow}
            openAsset={props.openAsset}
            setIsEditingMode={setIsEditingMode}
            setIsOpenConfirmCancel={setIsOpenConfirmCancel}
            onViewMoreClick={onViewMoreClick}
            isViewMore={isViewMore}
            onEdit={onEdit}
            onRemove={canRemove ? onRemove : null}
            setIsViewMore={setIsViewMore}
            isCreateConceptWithGenerateAI={isCreateConceptWithGenerateAI}
          />
        )}
      </div>
      <BlurConfirmPopup
        isOpen={isOpenConfirmRemove}
        onConfirm={onOpenConfirmRemoveAccept}
        onDiscard={onOpenConfirmRemoveDiscard}
        popUpText={'Hang on! Are you sure you want to remove the concept? This will remove it for all the collaborators.'}
        primaryBtnText={'Yes, remove'}
        secondaryBtnText={'Discard'}
        scrollOnlyWhenViewPortLess
      />
      <BlurConfirmPopup
        isOpen={isOpenConfirmCancel}
        onConfirm={onOpenConfirmCancelAccept}
        onDiscard={onOpenConfirmDiscard}
        scrollBackPosition={'end'}
        popUpText={'All your changes will be lost. Are you sure you want to cancel?'}
        primaryBtnText={'Confirm'}
        secondaryBtnText={'Discard'}
        scrollOnlyWhenViewPortLess
      />
    </SectionWrapper>
  );
};

export default ConceptSection;
