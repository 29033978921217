import React, { FC, useEffect, useMemo } from 'react';
import { getBoardDeliverablesAssets } from '../../../../../actions/ContentBoardActions/contentBoard.actions';
import { createNotification } from '../../../../../actions/toastNotificationActions/toastNotification.actions';
import DownloadAllButton from '../../../components/DownloadAllButton';
import notificationDataConfig from '../../../../../shared/utils/notificationDataConfig';
import { AuthorizedUser } from '../../../../../models/permissions/users/AuthorizedUser';
import { TOAST_NOTIFICATION_TYPES } from '../../../../../shared/constants/toastNotificationsData';
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks/reduxHooks';
import { User } from '../../../../../models/permissions/users/User';

type PropsType = {
  className?: string;
};

const DeliverablesDownloadAll: FC<PropsType> = props => {
  const dispatch = useAppDispatch();
  const currentOrganizationID: string | number | null = useAppSelector(state => state.auth.currentOrganization?.organization_id ?? null);
  const userModel: User = useAppSelector(state => state.auth.userModel);
  const { boardAssets, boardAssetsLoading, openedBoard } = useAppSelector(state => state.contentBoard);

  useEffect(() => {
    if (!openedBoard || (!currentOrganizationID && userModel && userModel instanceof AuthorizedUser)) return;
    dispatch(getBoardDeliverablesAssets(openedBoard.id));
  }, [currentOrganizationID, openedBoard, userModel]);

  const onResultActions = useMemo(() => {
    return {
      success: () => {
        dispatch(createNotification(notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.DOWNLOAD_ALL_ASSETS_SUCCESS, {})));
      },
      partiallyFailed: () => {
        dispatch(
          createNotification(notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.DOWNLOAD_ALL_ASSETS_PARTIALLY_FAILED, {}))
        );
      },
      allFailed: () => {
        dispatch(createNotification(notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.DOWNLOAD_ALL_ASSETS_FAILED, {})));
      },
    };
  }, []);

  return (
    <DownloadAllButton
      className={props.className}
      assets={boardAssets}
      isDisplay={!boardAssetsLoading && !!boardAssets?.length}
      onResult={onResultActions}
      size={'medium'}
      designVersion={'v2'}
    />
  );
};

export default DeliverablesDownloadAll;
