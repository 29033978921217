import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/reduxHooks';
import { IBoard } from '../../../../models/entities/board/Board';
import { useEffect, useMemo, useState } from 'react';
import { IBoardBriefFormikValues } from '../../../../models/entities/boardBrief/BoardBriefFormikValues';
import { ICreateBoardFormikValues } from '../CreateBoardWrapper/CreateBoard/useCreateBoardFormik';
import useBoardSetup, { BoardSetupSettingsType } from '../../hooks/useBoardSetup';
import { clearBoardBriefState, getBoardBrief, getBoardBriefAsset } from '../../../../actions/BoardBriefActions/boardBriefActions';
import { BoardBriefFormikValuesHydrator } from '../../../../models/entities/boardBrief/hydrators/BoardBriefFormikValuesHydrator';
import { BoardFormikValuesHydrator } from '../../../../models/entities/board/hydrators/BoardFormikValuesHydrator';
import { DeliverablesFormHydrator } from '../../../../models/entities/deliverable/hydrators/DeliverablesFormHydrator';
import { useParams } from 'react-router';
import { clearContentBoard, getBoardDeliverables } from '../../../../actions/ContentBoardActions/contentBoard.actions';
import setAsyncNewBriefFormAssets from '../utils/setAsyncNewBriefFormAssets';
import { ICreateBoardBriefAsset } from '../../../../models/entities/asset/CreateBoardBriefAsset';

const boardSetupSettings: BoardSetupSettingsType = {
  withUpcomingLiveDate: false,
  withInvitedCollaborators: false,
};

const MAX_DELIVERABLES_COUNT = 500;

type UseSetupBoardFilledFormType = () => {
  briefFormikValues: IBoardBriefFormikValues | null;
  boardFormikValues: ICreateBoardFormikValues;
  briefFormAssets: ICreateBoardBriefAsset[];
} | null;

const useSetupBoardFilledForm: UseSetupBoardFilledFormType = () => {
  const { boardId }: { boardId?: string } = useParams();
  const userTimezoneName = useAppSelector<string>(state => state.auth.userTimezone?.name || 'Pacific/Yap');
  const organizationId = useAppSelector<string | number | null>(state => state.auth.currentOrganization?.organization_id ?? null);
  const openedBoard = useAppSelector(state => state.contentBoard.openedBoard);
  const deliverables = useAppSelector(state => state.contentBoard.deliverables);
  const deliverablesLoading = useAppSelector(state => state.contentBoard.deliverablesLoading);
  const { boardBrief, boardBriefAssets, boardBriefAssetsLoading, boardBriefLoading } = useAppSelector(state => state.boardBrief);
  const dispatch = useAppDispatch();

  const [briefFormikValues, setBriefFormikValues] = useState<IBoardBriefFormikValues | null>(null);
  const [boardFormikValues, setBoardFormikValues] = useState<ICreateBoardFormikValues | null>(null);
  const [briefFormAssets, setBriefFormAssets] = useState<ICreateBoardBriefAsset[]>([]);

  useBoardSetup(boardSetupSettings);

  useEffect(() => {
    if (!openedBoard?.campaign_brief_id || !openedBoard.campaign_brief_item_id || !boardId || !organizationId) return;
    dispatch(getBoardBrief(organizationId, boardId, openedBoard?.campaign_brief_id, openedBoard?.campaign_brief_item_id));
    dispatch(getBoardBriefAsset(organizationId, boardId, openedBoard?.campaign_brief_id));
  }, [openedBoard?.campaign_brief_item_id, openedBoard?.campaign_brief_id, organizationId, boardId]);

  useEffect(function clearReduxState() {
    return () => {
      dispatch(clearBoardBriefState());
      dispatch(clearContentBoard());
    };
  }, []);

  useEffect(() => {
    if (!boardId) return;
    dispatch(getBoardDeliverables(boardId, 1, MAX_DELIVERABLES_COUNT));
  }, [boardId, organizationId]);

  const setupFormsValues = () => {
    if (!openedBoard || deliverablesLoading || !organizationId || (openedBoard.campaign_brief_id && !boardBrief) || boardBriefAssetsLoading)
      return;

    let formikBoardBrief: IBoardBriefFormikValues | null = null;

    if (boardBrief) {
      formikBoardBrief = new BoardBriefFormikValuesHydrator(boardBrief);
    }

    const boardData: IBoard = { ...openedBoard, name: `${openedBoard.name} (Copy)` };

    const formDeliverables = deliverables.map(deliverable => new DeliverablesFormHydrator(deliverable, userTimezoneName));

    const formikBoard = new BoardFormikValuesHydrator(boardData, organizationId, formDeliverables, userTimezoneName, formikBoardBrief);

    const promiseArr: [Promise<ICreateBoardBriefAsset[]>, Promise<PromiseSettledResult<void>[]>] = [
      setAsyncNewBriefFormAssets(boardBriefAssets),
      formikBoard.setBoardFiles(openedBoard),
    ];

    Promise.allSettled(promiseArr).then(values => {
      setBriefFormikValues(formikBoardBrief);
      setBoardFormikValues(formikBoard);

      if (values[0].status === 'rejected') return;
      setBriefFormAssets(values[0].value);
    });
  };

  useEffect(() => {
    setupFormsValues();
  }, [
    openedBoard,
    organizationId,
    deliverables,
    boardBrief,
    boardId,
    boardBriefAssets,
    deliverablesLoading,
    boardBriefAssetsLoading,
    boardBriefLoading,
    userTimezoneName,
  ]);

  const returnValue = useMemo(() => {
    if (!boardFormikValues || (boardBriefAssetsLoading && openedBoard?.campaign_brief_id)) return null;

    return {
      briefFormikValues,
      boardFormikValues,
      briefFormAssets,
    };
  }, [briefFormikValues, boardFormikValues, boardBriefAssets, boardBriefAssetsLoading, openedBoard?.campaign_brief_id]);

  return returnValue;
};

export default useSetupBoardFilledForm;
