import React, { useEffect, useState } from 'react';
import styles from './DiscussionCreateModal.module.scss';
import ButtonCustom from '../../Buttons/ButtonCustom';
import { Modal } from '../../../../components/Modals';
import TextField from '../../../../components/FormControls/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import UserList from './Components/UserList';

const DiscussionCreateModal = ({ onClose, modalStatus, createRoom, availableOrganizations }) => {
  const formik = useFormik({
    initialValues: {
      name_room: '',
      organizationsIds: [],
    },
    validationSchema: Yup.object().shape({
      name_room: Yup.string().required('Name is a required field'),
      organizationsIds: Yup.array().min(2, 'Please select a collaborator').required('Please select a collaborator'),
    }),
    validateOnMount: false,
  });

  const [shouldErrorBeRendered, setShouldErrorBeRendered] = useState(true);

  useEffect(() => {
    if (modalStatus) {
      setShouldErrorBeRendered(true);
    }
  }, [modalStatus]);

  const clearFormErrorMessages = () => {
    formik.setTouched({}, false);
    formik.setErrors({});
    setShouldErrorBeRendered(false);
  };

  const handleSubmit = () => {
    createRoom(formik.values.name_room, [...formik.values.organizationsIds]);
    formik.setFieldValue('organizationsIds', [], false);
    formik.setFieldValue('name_room', '', false);
    clearFormErrorMessages();
  };

  const handleClose = () => {
    onClose();
    formik.setFieldValue('name_room', '', false);
    clearFormErrorMessages();
  };

  return (
    <Modal className={styles.root} onClose={handleClose} open={modalStatus}>
      <div className={styles.descriptions}>Create new discussion</div>
      <div className={styles.inputContainer}>
        <TextField
          type={'text'}
          name="name_room"
          placeholder={'Name new discussion'}
          className={styles.createInput}
          value={formik.values.name_room}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name_room && shouldErrorBeRendered && formik.errors.name_room}
        />
      </div>
      <UserList formik={formik} availableOrganizations={availableOrganizations} />
      {formik.errors.organizationsIds && shouldErrorBeRendered && (
        <div className={styles.errorContainer}>{formik.errors.organizationsIds}</div>
      )}

      <div className={styles.createButtons}>
        <ButtonCustom
          onClick={handleSubmit}
          disabled={!formik.values.name_room || formik.values.organizationsIds.length === 0}
          parentWidth
          clearBg
          className={styles.submitButton}
        >
          Create
        </ButtonCustom>
      </div>
    </Modal>
  );
};

export default DiscussionCreateModal;
