import React, { useEffect, useState } from 'react';
import styles from './PeriodSwitcher.module.scss';
import cs from 'classnames';
import {PRICING_PLAN_PERIOD} from "../../../../models/entities/pricingPlans/enum/PricingPlanPeriod";

const configTexts: { [key in PRICING_PLAN_PERIOD]: JSX.Element } = {
  [PRICING_PLAN_PERIOD.MONTHLY]: <>Monthly billing</>,
  [PRICING_PLAN_PERIOD.YEARLY]: (
    <>
      Annual <span className={styles.greenText}>(Save up to 20%)</span>
    </>
  ),
};

type PropsType = {
  initialValue: PRICING_PLAN_PERIOD;
  onChange: (tab: PRICING_PLAN_PERIOD) => void;
};

const PeriodSwitcher = ({ onChange, initialValue }: PropsType) => {
  const [tab, setTab] = useState<PRICING_PLAN_PERIOD>(initialValue);

  useEffect(() => {
    onChange(tab);
  }, [tab]);

  return (
    <div className={styles.root}>
      {Object.values(PRICING_PLAN_PERIOD).map(_tab => (
        <div key={_tab} className={cs(styles.tab, { [styles.active]: tab === _tab })} onClick={() => setTab(_tab)}>
          {configTexts[_tab]}
        </div>
      ))}
    </div>
  );
};

export default PeriodSwitcher;
