import {Action} from "redux";
import {IResultAsset} from "../../models/entities/asset/ResultAsset";
import {IDeliverableOption} from "../../models/entities/board/DeliverableOption";
import {ThunkAction} from "redux-thunk";
import {RootState} from "../../store";


export enum RESULTS_ACTIONS_TYPES {
  GET_DELIVERABLES_OPTIONS_REQUEST = 'GET_DELIVERABLES_OPTIONS_REQUEST',
  GET_DELIVERABLES_OPTIONS_SUCCESS = 'GET_DELIVERABLES_OPTIONS_SUCCESS',
  GET_DELIVERABLES_OPTIONS_FAILURE = 'GET_DELIVERABLES_OPTIONS_FAILURE',

  GET_RESULT_ASSETS_REQUEST = 'GET_RESULT_ASSETS_REQUEST',
  GET_RESULT_ASSETS_SUCCESS = 'GET_RESULT_ASSETS_SUCCESS',
  GET_RESULT_ASSETS_FAILURE = 'GET_RESULT_ASSETS_FAILURE',

  DELETE_ALL_RESULT_ASSETS_REQUEST = 'DELETE_ALL_RESULT_ASSETS_REQUEST',
  DELETE_ALL_RESULT_ASSETS_SUCCESS = 'DELETE_ALL_RESULT_ASSETS_SUCCESS',
  DELETE_ALL_RESULT_ASSETS_FAILURE = 'DELETE_ALL_RESULT_ASSETS_FAILURE',

  UPLOAD_RESULT_ASSETS_REQUEST = 'UPLOAD_RESULT_ASSETS_REQUEST',
  UPLOAD_RESULT_ASSETS_SUCCESS = 'UPLOAD_RESULT_ASSETS_SUCCESS',
  UPLOAD_RESULT_ASSETS_FAILURE = 'UPLOAD_RESULT_ASSETS_FAILURE',

  PATCH_RESULT_ASSET_REQUEST = 'PATCH_RESULT_ASSET_REQUEST',
  PATCH_RESULT_ASSET_SUCCESS = 'PATCH_RESULT_ASSET_SUCCESS',
  PATCH_RESULT_ASSET_FAILURE = 'PATCH_RESULT_ASSET_FAILURE',

  DELETE_RESULT_ASSET_REQUEST = 'DELETE_RESULT_ASSET_REQUEST',
  DELETE_RESULT_ASSET_SUCCESS = 'DELETE_RESULT_ASSET_SUCCESS',
  DELETE_RESULT_ASSET_FAILURE = 'DELETE_RESULT_ASSET_FAILURE',

  REQUEST_RESULTS_REQUEST = 'REQUEST_RESULTS_REQUEST',
  REQUEST_RESULTS_SUCCESS = 'REQUEST_RESULTS_SUCCESS',
  REQUEST_RESULTS_FAILURE = 'REQUEST_RESULTS_FAILURE',

  CLEAR_RESULTS_STATE = 'CLEAR_RESULTS_STATE'
}

type GetDeliverableOptionsRequestType = Action<RESULTS_ACTIONS_TYPES.GET_DELIVERABLES_OPTIONS_REQUEST>;
type GetDeliverableOptionsSuccessType = Action<RESULTS_ACTIONS_TYPES.GET_DELIVERABLES_OPTIONS_SUCCESS> & {
  payload: IDeliverableOption[];
};
type GetDeliverableOptionsFailureType = Action<RESULTS_ACTIONS_TYPES.GET_DELIVERABLES_OPTIONS_FAILURE>;


type GetResultAssetsRequestType = Action<RESULTS_ACTIONS_TYPES.GET_RESULT_ASSETS_REQUEST>;
type GetResultAssetsSuccessType = Action<RESULTS_ACTIONS_TYPES.GET_RESULT_ASSETS_SUCCESS> & { payload: IResultAsset[] };
type GetResultAssetsFailureType = Action<RESULTS_ACTIONS_TYPES.GET_RESULT_ASSETS_FAILURE>;

type DeleteAllResultAssetsRequestType = Action<RESULTS_ACTIONS_TYPES.DELETE_ALL_RESULT_ASSETS_REQUEST>;
type DeleteAllResultAssetsSuccessType = Action<RESULTS_ACTIONS_TYPES.DELETE_ALL_RESULT_ASSETS_SUCCESS>;
type DeleteAllResultAssetsFailureType = Action<RESULTS_ACTIONS_TYPES.DELETE_ALL_RESULT_ASSETS_FAILURE> & {
  payload: IResultAsset[]
};

type UploadResultAssetsRequestType = Action<RESULTS_ACTIONS_TYPES.UPLOAD_RESULT_ASSETS_REQUEST>;
type UploadResultAssetsSuccessType = Action<RESULTS_ACTIONS_TYPES.UPLOAD_RESULT_ASSETS_SUCCESS> & {payload: IResultAsset[]};
type UploadResultAssetsFailureType = Action<RESULTS_ACTIONS_TYPES.UPLOAD_RESULT_ASSETS_FAILURE>;

type PatchResultAssetRequestType = Action<RESULTS_ACTIONS_TYPES.PATCH_RESULT_ASSET_REQUEST>;
type PatchResultAssetSuccessType = Action<RESULTS_ACTIONS_TYPES.PATCH_RESULT_ASSET_SUCCESS> & { payload: IResultAsset };
type PatchResultAssetFailureType = Action<RESULTS_ACTIONS_TYPES.PATCH_RESULT_ASSET_FAILURE>;

type DeleteResultAssetRequestType = Action<RESULTS_ACTIONS_TYPES.DELETE_RESULT_ASSET_REQUEST> & { payload: IResultAsset };
type DeleteResultAssetSuccessType = Action<RESULTS_ACTIONS_TYPES.DELETE_RESULT_ASSET_SUCCESS>;
type DeleteResultAssetFailureType = Action<RESULTS_ACTIONS_TYPES.DELETE_RESULT_ASSET_FAILURE> & { payload: IResultAsset };

type RequestResultRequestType = Action<RESULTS_ACTIONS_TYPES.REQUEST_RESULTS_REQUEST>;
type RequestResultSuccessType = Action<RESULTS_ACTIONS_TYPES.REQUEST_RESULTS_SUCCESS>;
type RequestResultFailureType = Action<RESULTS_ACTIONS_TYPES.REQUEST_RESULTS_FAILURE>;

type ClearResultsStateType = Action<RESULTS_ACTIONS_TYPES.CLEAR_RESULTS_STATE>;

export type ResultsActionsTypes =
  | GetDeliverableOptionsRequestType
  | GetDeliverableOptionsSuccessType
  | GetDeliverableOptionsFailureType
  | GetResultAssetsRequestType
  | GetResultAssetsSuccessType
  | GetResultAssetsFailureType
  | DeleteAllResultAssetsRequestType
  | DeleteAllResultAssetsSuccessType
  | DeleteAllResultAssetsFailureType
  | UploadResultAssetsRequestType
  | UploadResultAssetsSuccessType
  | UploadResultAssetsFailureType
  | PatchResultAssetRequestType
  | PatchResultAssetSuccessType
  | PatchResultAssetFailureType
  | DeleteResultAssetRequestType
  | DeleteResultAssetSuccessType
  | DeleteResultAssetFailureType
  | RequestResultRequestType
  | RequestResultSuccessType
  | RequestResultFailureType
  | ClearResultsStateType;

export type ResultsThunkReturnType<ReturnType = void> = ThunkAction<Promise<ReturnType>, RootState, null, ResultsActionsTypes>;
