import React from 'react';

const MessageIcon = ({ onClick }) => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g onClick={() => onClick()}>
      <circle cx="32" cy="32" r="32" fill="#4E3B8B" />
      <g transform="translate(16, 16)">
        <path
          d="M2.23906 24.163C0.250341 20.8105 -0.446127 16.8473 0.280442 13.0177C1.00701 9.18799 3.10664 5.75527 6.18505 3.3641C9.26347 0.972935 13.1089 -0.212195 16.9993 0.0312544C20.8897 0.274704 24.5574 1.92999 27.3137 4.68627C30.07 7.44256 31.7253 11.1103 31.9687 15.0006C32.2122 18.891 31.0271 22.7365 28.6359 25.8149C26.2448 28.8933 22.8121 30.993 18.9824 31.7195C15.1527 32.4461 11.1895 31.7497 7.83702 29.761L7.83706 29.7608L2.30866 31.3404C2.07993 31.4057 1.83789 31.4087 1.60761 31.349C1.37734 31.2894 1.16721 31.1692 0.999001 31.001C0.830793 30.8328 0.710628 30.6227 0.650959 30.3924C0.591289 30.1621 0.594286 29.9201 0.659639 29.6913L2.23918 24.1629L2.23906 24.163Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <filter id="filter0_d_5181_14882" x="0" y="0" width="144" height="144" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="15" />
        <feGaussianBlur stdDeviation="20" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0745098 0 0 0 0 0.0392157 0 0 0 0 0.180392 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5181_14882" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5181_14882" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default MessageIcon;
