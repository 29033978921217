import React, { ForwardedRef, ReactElement } from 'react';
import cs from 'classnames';
import styles from './ActionsDropdownV1.module.scss';

export type ActionsDropdownV1Option = {
  text: string;
  additionalElement?: ReactElement;
  icon?: ReactElement;
  className?: string;
  onClick: (event: React.MouseEvent<HTMLDivElement>, close: () => void) => void;
};

type PropsType = {
  className?: string;
  options: ActionsDropdownV1Option[];
  isOpen: boolean;
  onClose: () => void;
  renderOptions?: (opt: ActionsDropdownV1Option, idx: number, close: () => void) => ReactElement;
};

const ActionsDropdownV1 = React.forwardRef((props: PropsType, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <>
      {props.isOpen && (
        <div className={cs(styles.dropdown, props.className)} ref={ref}>
          {props.options &&
            !props.renderOptions &&
            props.options.map((opt, idx) => (
              <div
                key={opt.text + idx}
                className={cs(styles.dropdownItem, opt.className ?? '')}
                onClick={event => {
                  opt.onClick(event, props.onClose);
                }}
              >
                {opt.icon && (
                  <span className={styles.itemIcon} data-field="icon">
                    {opt.icon}
                  </span>
                )}
                <span className={styles.itemText} data-field="text">
                  {opt.text}
                  {opt.additionalElement}
                </span>
              </div>
            ))}
          {props.renderOptions && props.options.map((opt, idx) => props.renderOptions && props.renderOptions(opt, idx, props.onClose))}
        </div>
      )}
    </>
  );
});
export default ActionsDropdownV1;
