import React from 'react';
import styles from './EmptyCellItem.module.scss';

const EmptyCellItem = () => {
  return (
    <div className={styles.root}>
      <p className={styles.text}>No activity.</p>
    </div>
  );
};

export default EmptyCellItem;
