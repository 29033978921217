export enum MEMBER_ROLE_IN_ORGANIZATION_TYPES {
  OWNER = 'owner',
  MEMBER = 'member',
}

export const MEMBER_ROLE_IN_ORGANIZATION_TEXTS = {
  [MEMBER_ROLE_IN_ORGANIZATION_TYPES.OWNER]: 'Admin',
  [MEMBER_ROLE_IN_ORGANIZATION_TYPES.MEMBER]: 'Team member',
};

// for board
export const MEMBER_ROLE_IN_ORGANIZATION_OBJECTS = [
  { name: MEMBER_ROLE_IN_ORGANIZATION_TEXTS[MEMBER_ROLE_IN_ORGANIZATION_TYPES.OWNER], type: MEMBER_ROLE_IN_ORGANIZATION_TYPES.OWNER },
  { name: MEMBER_ROLE_IN_ORGANIZATION_TEXTS[MEMBER_ROLE_IN_ORGANIZATION_TYPES.MEMBER], type: MEMBER_ROLE_IN_ORGANIZATION_TYPES.MEMBER },
];
