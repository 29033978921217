import { instance } from '../shared/utils/api.config.creator';

export const signIn = async (email, password) => {
  const response = await instance.post('auth/login', { email, password });
  return response.data;
};

export const checkEmailForExisting = async email => {
  const response = await instance.post(`users/check-email`, { email: email });
  return response.data;
};

export const signUp = async rightData => {
  const response = await instance.post(`register`, rightData);
  return response.data;
};

export const signUpViaProvider = async (provider, providerParams, type) => {
  const response = await instance.post(`register/via-provider`, {
    type,
    provider,
    provider_params: providerParams,
  });
  return response.data;
};

export const resentEmail = async data => {
  const response = await instance.post('/register/resend-confirmation-email', data);
  return response.data;
};

export const letterLink = async (id, token) => {
  const response = await instance.post(`/users/${id}/confirm`, token);
  return response.data;
};

export const me = async () => {
  const response = await instance.get('auth/me');
  return response.data;
};

export const getPricingPlanByOrganization = async organizationId => {
  const response = await instance.get(`/organizations/${organizationId}/pricing-plan`);
  return response.data;
};

export const readUpdates = async () => {
  const response = await instance.post('auth/me/read-updates');
  return response.data;
};

export const viewedIntroVideo = async () => {
  const response = await instance.post('auth/me/view-video');
  return response.data;
};

export const resetPasswordRequest = async email => {
  const response = await instance.post('/auth/request-reset-password', { email });
  return response.data;
};

export const resetPassword = async (reset_password_token, email, password) => {
  const response = await instance.post('/auth/reset-password', { reset_password_token, email, password });
  return response.data;
};
