import React, { FC } from 'react';
import { ReactComponent as UploadSVG } from '../../../assets/img/icons/upload.svg';
import { ReactComponent as PlusSVG } from '../../../assets/img/icons/plusInCircle.svg';
import styles from './ButtonWithPurpleRoundedIcon.module.scss';
import cs from 'classnames';
import useTooltip, { TOOLTIP_POSITIONS } from '../../../shared/hooks/fixedTooltip/useTooltip';

const presetIcons = {
  plus: {
    icon: PlusSVG,
  },
  upload: {
    icon: UploadSVG,
  },
};

type PropsType = {
  className?: string;
  size?: 'large' | 'medium' | 'small';
  presetIcon?: keyof typeof presetIcons;
  disabled?: boolean;
  tooltipText?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
} & Omit<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'className'>;

const ButtonWithPurpleRoundedIcon: FC<PropsType> = ({
  presetIcon,
  onClick,
  size,
  className,
  tooltipText,
  disabled,
  children,
  ...buttonProps
}) => {
  const Icon = presetIcon && presetIcons[presetIcon].icon;

  const { toolTip, onEnter, onLeave } = useTooltip(TOOLTIP_POSITIONS.BOTTOM, { text: tooltipText, className: styles.tooltip });

  return (
    <button
      {...buttonProps}
      className={cs(styles.root, className, { [styles.disabled]: disabled }, styles[size || 'medium'])}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
        if (disabled) return;
        onClick && onClick(event);
      }}
    >
      {Icon && (
        <div className={styles.circle}>
          <Icon className={styles.icon} />
        </div>
      )}
      <span className={styles.text}>{children}</span>
      {tooltipText && toolTip}
    </button>
  );
};

export default ButtonWithPurpleRoundedIcon;
