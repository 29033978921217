import { useEffect, useState } from 'react';

const useDebouncedValue = <ValueType>(delay: number = 300, initialState: ValueType): [ValueType, ValueType, (value: ValueType) => void] => {
  const [value, setValue] = useState<ValueType>(initialState);
  const [debounced, setDebounced] = useState<ValueType>(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebounced(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);

  return [debounced, value, setValue];
};

export default useDebouncedValue;
