import { IBoard } from '../../../../models/entities/board/Board';
import { useHistory } from 'react-router';
import { useCallback } from 'react';
import useBoardCreator from '../../hooks/useBoardCreator';

const useOpenEditBriefPage = (board: IBoard | null): { onEditClick: () => void; isEditDisplay: boolean } => {
  const history = useHistory();

  const isBoardCreator = useBoardCreator();

  const onEditClick = useCallback(() => {
    if (!board) return;
    history.push(`/content-board/${board.id}/edit-brief?cb=${board.campaign_brief_id}&cbi=${board.campaign_brief_item_id}`);
  }, [board]);

  return { onEditClick, isEditDisplay: !!board && !!isBoardCreator };
};

export default useOpenEditBriefPage;
