import React, { ChangeEvent, FC, memo, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../../../../../../shared/hooks/reduxHooks';
import styles from './AddDeliverablesInputRow.module.scss';
import cs from 'classnames';
import TrashSVG from '../../../../../../../components/IconsSvg/TrashSVG';
import LiveDueDateHeader from '../../../../../../../components/BriefsAndBoardsFlows/LiveDueDate/components/LiveDueDateHeader';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment/moment';
import LiveDueDate from '../../../../../../../components/BriefsAndBoardsFlows/LiveDueDate';
import { IDeliverableFormData, UseDeliverablesFormHandlersType } from '../../hooks/useDeliverablesFormData';
import { FormikErrors, FormikTouched } from 'formik';
import useFormikDebouncedState from '../../../../../../../shared/hooks/useFormikDebouncedState';
import { MODALS_VERSION } from '../../../../../../../components/BriefsAndBoardsFlows/LiveDueDate/components/CalendarWrapper';
import getInitialDeliverableName from '../../utils/getInitialDeliverableName';

type DeliverableInputRowProps = {
  handlers: UseDeliverablesFormHandlersType;
  deliverable: IDeliverableFormData;
  deliverableError: FormikErrors<IDeliverableFormData> | null;
  deliverableTouched: FormikTouched<IDeliverableFormData> | null;
  index: number;
  canEditDueDates: boolean;
  canEditNotifications: boolean;
};

type CustomInputProps = {
  value: null | string;
  onClick: () => void;
  error: string | null;
};

enum INPUT_TYPES {
  NAME = 'name',
}

const namePlaceHolder = 'Name your deliverable ';
const datePlaceHolder = 'Not set';

const AddDeliverablesInputRow: FC<DeliverableInputRowProps> = memo(
  ({
    deliverableError,
    deliverableTouched,
    deliverable,
    index,
    handlers: { setDeliverableField, deleteDeliverable, setDeliverableTouched },
    canEditDueDates,
    canEditNotifications,
  }) => {
    const [focusedInput, setFocusedInput] = useState<INPUT_TYPES | null>(null);
    const [isChangedName, setIsChangedName] = useState(false);

    const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

    const [deliverableName, setDeliverableName] = useFormikDebouncedState<string>((value: string) => {
      setDeliverableField(deliverable.id, { name: value });
    }, deliverable.name);

    useEffect(
      function changeInitialName() {
        if (!isChangedName) {
          const newDeliverableName = getInitialDeliverableName(deliverable.platform, deliverable.placement, index + 1);
          setDeliverableName(newDeliverableName);
        }
      },
      [isChangedName, deliverable, index]
    );

    const TimeInputsData = [
      {
        disableRemainder: !canEditNotifications,
        initialMainDate: deliverable.concept_due_datetime,
        title: 'Concept Due:',
        mainInputPlaceHolder: 'Pick Concept Due',
        minMainDate: null,
        initialReminderDate: deliverable.concept_reminder_datetime,
        maxMainDate: deliverable.content_due_datetime || deliverable.live_datetime,
        onSave: (date: string | null, reminderDate: string | null) => {
          setDeliverableField(deliverable.id, {
            concept_due_datetime: date,
            concept_reminder_datetime: reminderDate || null,
          });
        },
        onClear: () => {
          setDeliverableField(deliverable.id, { concept_due_datetime: null, concept_reminder_datetime: null });
        },
        renderInputComponent: (props: any) =>
          isTablet ? (
            <LiveDueDateHeader
              {...props}
              titleClassName={styles.timeInputTitle}
              dateClassName={styles.timeInputDate}
              error={deliverableError?.concept_due_datetime || deliverableError?.concept_reminder_datetime}
              value={deliverable.concept_due_datetime}
              wrapperClassName={cs(styles.timeInputContainer, {
                [styles.timeInputsErrorContainer]:
                  !!deliverableError?.concept_due_datetime || !!deliverableError?.concept_reminder_datetime,
              })}
              errorClassName={styles.timeInputsErrorText}
            />
          ) : (
            <CustomTimeInput
              {...props}
              error={deliverableError?.concept_due_datetime || deliverableError?.concept_reminder_datetime}
              value={deliverable.concept_due_datetime}
            />
          ),
      },
      {
        disableRemainder: !canEditNotifications,
        initialMainDate: deliverable.content_due_datetime,
        initialReminderDate: deliverable.content_reminder_datetime,
        title: 'Live Date',
        mainInputPlaceHolder: 'Pick Live Date',
        minMainDate: deliverable.concept_due_datetime,
        maxMainDate: deliverable.live_datetime,
        onSave: (date: string | null, reminderDate: string | null) => {
          setDeliverableField(deliverable.id, {
            content_due_datetime: date,
            content_reminder_datetime: reminderDate || null,
          });
        },
        onClear: () => {
          setDeliverableField(deliverable.id, { content_due_datetime: null, content_reminder_datetime: null });
        },
        renderInputComponent: (props: any) =>
          isTablet ? (
            <LiveDueDateHeader
              error={deliverableError?.content_due_datetime || deliverableError?.content_reminder_datetime}
              titleClassName={styles.timeInputTitle}
              dateClassName={styles.timeInputDate}
              wrapperClassName={cs(styles.timeInputContainer, {
                [styles.timeInputsErrorContainer]:
                  !!deliverableError?.content_due_datetime || !!deliverableError?.content_reminder_datetime,
              })}
              errorClassName={styles.timeInputsErrorText}
              {...props}
              value={deliverable.content_due_datetime}
            />
          ) : (
            <CustomTimeInput
              error={deliverableError?.content_due_datetime || deliverableError?.content_reminder_datetime}
              {...props}
              value={deliverable.content_due_datetime}
            />
          ),
      },
      {
        disableRemainder: true,
        initialMainDate: deliverable.live_datetime,
        title: 'Live Date',
        mainInputPlaceHolder: 'Pick Live Date',
        minMainDate: deliverable.content_due_datetime || deliverable.concept_due_datetime,
        maxMainDate: null,
        onSave: (date: string | null) => setDeliverableField(deliverable.id, { live_datetime: date }),
        onClear: () => setDeliverableField(deliverable.id, { live_datetime: null }),
        renderInputComponent: (props: any) =>
          isTablet ? (
            <LiveDueDateHeader
              error={deliverableError?.live_datetime}
              {...props}
              value={deliverable.live_datetime}
              titleClassName={styles.timeInputTitle}
              dateClassName={styles.timeInputDate}
              wrapperClassName={cs(styles.timeInputContainer, { [styles.timeInputsErrorContainer]: !!deliverableError?.live_datetime })}
              errorClassName={styles.timeInputsErrorText}
            />
          ) : (
            <CustomTimeInput error={deliverableError?.live_datetime} {...props} value={deliverable.live_datetime} />
          ),
      },
    ];
    const dateInputs = canEditDueDates
      ? TimeInputsData.map((inputData, i) => (
          <LiveDueDate modalVersion={MODALS_VERSION.ONLY_MODAL} canEdit reminderDateText="Reminder" {...inputData} key={i} />
        ))
      : null;

    return !isTablet ? (
      <>
        <div className={styles.cellNumber}>#{index + 1}</div>
        <div
          className={cs(styles.inputWrapper, {
            [styles.inputWrapperError]: deliverableTouched?.name && !!deliverableError?.name,
          })}
        >
          <input
            name="deliverableName"
            type="text"
            onBlur={() => {
              setDeliverableTouched(deliverable.id, 'name');
            }}
            className={styles.input}
            value={deliverableName}
            placeholder={namePlaceHolder}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setIsChangedName(true);
              !deliverableTouched && setDeliverableTouched(deliverable.id, 'name');
              setDeliverableName(e.target.value);
            }}
          />
          {deliverableTouched?.name && !!deliverableError?.name && <div className={styles.inputError}>{deliverableError?.name}</div>}
        </div>
        {dateInputs}
        <div className={styles.trashIconButton} onClick={() => deleteDeliverable(deliverable.id)}>
          <TrashSVG />
        </div>
      </>
    ) : (
      <div className={styles.deliverableWrapper}>
        <div
          className={cs(styles.nameInputWrapper, {
            [styles.focusedNameInput]: focusedInput === INPUT_TYPES.NAME,
            [styles.error]: !!deliverableError?.name && deliverableTouched?.name,
          })}
        >
          <div className={styles.cellNumber}>#{index + 1}</div>
          <input
            name="deliverableName"
            type="text"
            className={styles.nameInput}
            value={deliverableName}
            placeholder={namePlaceHolder}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setIsChangedName(true);
              !deliverableTouched && setDeliverableTouched(deliverable.id, 'name');
              setDeliverableName(e.target.value);
            }}
            onFocus={() => setFocusedInput(INPUT_TYPES.NAME)}
            onBlur={() => {
              setDeliverableTouched(deliverable.id, 'name');
              setFocusedInput(null);
            }}
          />
        </div>
        <div className={styles.trashIconButton} onClick={() => deleteDeliverable(deliverable.id)}>
          <TrashSVG />
        </div>
        {deliverableTouched?.name && !!deliverableError?.name?.length && <div className={styles.inputError}>{deliverableError?.name}</div>}
        <div className={styles.timeInputsWrapper}>{dateInputs}</div>
      </div>
    );
  }
);

const CustomTimeInput: FC<CustomInputProps> = ({ value, onClick, error }) => {
  const userTimezone = useAppSelector(state => state.auth.userTimezone);
  const userTimeZoneName = useMemo(() => (userTimezone === 'Pacific/Yap' ? moment.tz.guess() : userTimezone.name), [userTimezone]);

  const wrappedData = value ? moment.utc(value).tz(userTimeZoneName) : null;
  const dateString = wrappedData && wrappedData.format('DD MMM hh:mm A');

  return (
    <div className={cs(styles.inputWrapper, { [styles.inputWrapperError]: !!error })} onClick={onClick}>
      <input
        name="deliverableLiveDate"
        type="text"
        className={styles.input}
        value={dateString || ''}
        placeholder={datePlaceHolder}
        onChange={() => {}}
        disabled={true}
      />
      <div className={styles.inputError}>{error}</div>
    </div>
  );
};

export default AddDeliverablesInputRow;
