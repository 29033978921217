import * as notificationsServices from '../services/notifications';

export const NOTIFICATIONS_TYPES = {
  GET_NOTIFICATIONS: {
    REQUEST: 'GET_NOTIFICATIONS_REQUEST',
    SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
    FAILURE: 'GET_NOTIFICATIONS_FAILURE',
  },
  ADD_NEW_NOTIFICATIONS: 'ADD_NEW_NOTIFICATIONS',
  SET_IS_OPEN_NOTIFICATIONS: 'SET_IS_OPEN_NOTIFICATIONS',
  MARK_NOTIFICATIONS: 'MARK_NOTIFICATIONS',
  CHANGE_UNREAD: 'CHANGE_UNREAD',
  SET_NOTIFICATIONS_SELECTED_TYPE: 'SET_NOTIFICATIONS_SELECTED_TYPE',
  CLEAR_DATA: 'CLEAR_DATA',
};

export const setIsOpenNotifications = isOpen => ({ type: NOTIFICATIONS_TYPES.SET_IS_OPEN_NOTIFICATIONS, payload: isOpen });

export const getNotificationsList = (needReset, objectType, searchString, page, pageSize) => async (dispatch, getState) => {
  try {
    if (needReset) dispatch({ type: NOTIFICATIONS_TYPES.CLEAR_DATA });

    dispatch({
      type: NOTIFICATIONS_TYPES.GET_NOTIFICATIONS.REQUEST,
    });
    const params = {};
    if (objectType) params.object_type = objectType;
    if (searchString) params.search_filter = searchString;
    if (page) params.page = page;
    if (pageSize) params.page_size = pageSize;
    const res = await notificationsServices.getNotificationsList(params);

    dispatch({
      type: NOTIFICATIONS_TYPES.GET_NOTIFICATIONS.SUCCESS,
      payload: res.data,
      totalCount: res.total_count,
      totalUnread: res.total_unread,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: NOTIFICATIONS_TYPES.GET_NOTIFICATIONS.FAILURE,
      payload: error?.response?.data,
    });
  }
};

export const updateNotifications = () => async (dispatch, getState) => {
  try {
    const notifications = getState().notifications;
    dispatch(changeUnread(1));

    if (!notifications.isOpen) {
      return;
    }
    const response = await notificationsServices.getNotificationsList({ page: 1, page_size: 10 });
    const currentNotifications = notifications.data;
    const lastNotifications = response.data;

    let newNotification = null;
    if (currentNotifications.length) {
      const currentFirstIndex = lastNotifications.findIndex(notification => notification.id === currentNotifications[0].id);
      newNotification = [lastNotifications[currentFirstIndex - 1]];
    } else {
      newNotification = [lastNotifications[lastNotifications.length - 1]];
    }

    if (!notifications.selectedType || newNotification.object_type === notifications.selectedType) {
      dispatch({
        type: NOTIFICATIONS_TYPES.ADD_NEW_NOTIFICATIONS,
        payload: newNotification,
      });
    }
  } catch (e) {
    console.error(e);
  }
};

export const changeUnread = (changeNumber, isReplace) => ({
  type: NOTIFICATIONS_TYPES.CHANGE_UNREAD,
  payload: { changeNumber, isReplace },
});

export const markReadNotifications = ids => async dispatch => {
  try {
    dispatch({ type: NOTIFICATIONS_TYPES.MARK_NOTIFICATIONS, payload: ids });
    await notificationsServices.markRead({ ids });
  } catch (e) {
    console.error(e);
  }
};

export const setNotificationsSelectedType = type => ({ type: NOTIFICATIONS_TYPES.SET_NOTIFICATIONS_SELECTED_TYPE, payload: type });

export const clearNotificationsList = () => async dispatch => {
  dispatch({
    type: NOTIFICATIONS_TYPES.CLEAR_DATA,
  });
};
