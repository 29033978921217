import React, { useCallback, useEffect, useRef, useState } from 'react';
import cs from 'classnames';
import { addDays, addMonths, addYears } from 'date-fns';
import { DateRangePicker, defaultStaticRanges } from 'react-date-range';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';
import ArrowDownSVG from '../../../expert/components/IconsSvg/ArrowDownSVG';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../customCalendarStyles.scss';
import styles from './CalendarRange.module.scss';
import { useMediaQuery } from 'react-responsive/src';

function CalendarRange({ onSubmit, onCancel, buttonText, className, inputClassName, IconArrow, IconCross, forMobile }) {
  const rootRef = useRef(null);
  const dataPickerRef = useRef(null);
  const isLaptop = useMediaQuery({ query: '(max-width: 991px)' });

  const [expanded, setExpanded] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const applyHandler = () => {
    onSubmit(state[0]);
    setExpanded(false);
  };

  const cancelHandler = () => {
    onCancel();
    setExpanded(false);
  };

  const handleClickOutside = useCallback(
    e => {
      if (!rootRef.current.contains(e.target)) {
        setExpanded(false);
      }
    },
    [rootRef]
  );

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  // change default Yesterday range
  defaultStaticRanges[1].range = () => ({
    startDate: addDays(new Date(), -1),
    endDate: new Date(),
  });

  const defaultIsSelected = defaultStaticRanges[0].isSelected;

  useEffect(() => {
    if (dataPickerRef.current && isLaptop) {
      dataPickerRef.current.style.right = 'auto';
    }

    if (dataPickerRef.current && !isLaptop) {
      if (dataPickerRef.current?.getBoundingClientRect().left < -500) {
        dataPickerRef.current.style.right = dataPickerRef.current?.getBoundingClientRect().left - 20 + 'px';
      }
      if (dataPickerRef.current?.getBoundingClientRect().right > 1000) {
        dataPickerRef.current.style.right = 0;
      } else {
        dataPickerRef.current.style.right = dataPickerRef.current?.getBoundingClientRect().left - 290 + 'px';
      }
    }
  }, [expanded]);

  return (
    <div className={cs(styles.root, className)} ref={rootRef}>
      <ButtonCustom
        onClick={() => setExpanded(!expanded)}
        className={cs(styles.buttonDropdown, inputClassName, { [styles.active]: expanded })}
        Icon={IconArrow || ArrowDownSVG}
        SecondIcon={buttonText ? IconCross : null}
        onSecondIconHandler={
          onCancel
            ? () => {
                onCancel();
                setExpanded(false);
              }
            : null
        }
      >
        {buttonText || 'Creation date'}
      </ButtonCustom>
      {expanded && (
        <div className={styles.dropdownRoot} ref={dataPickerRef}>
          <DateRangePicker
            onChange={item => setState([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            className={forMobile && styles.calendar}
            months={2}
            ranges={state}
            direction="horizontal"
            preventSnapRefocus={true}
            calendarFocus="backwards"
            hasCustomRendering={true}
            maxDate={new Date()}
            inputRanges={[]}
            weekStartsOn={0}
            staticRanges={[
              ...defaultStaticRanges,
              {
                label: 'Last 3 Months',
                range: () => ({
                  startDate: addMonths(new Date(), -3),
                  endDate: new Date(),
                }),
                isSelected: defaultIsSelected,
              },
              {
                label: 'Last 6 Months',
                range: () => ({
                  startDate: addMonths(new Date(), -6),
                  endDate: new Date(),
                }),
                isSelected: defaultIsSelected,
              },
              {
                label: 'Last 1 Year',
                range: () => ({
                  startDate: addYears(new Date(), -1),
                  endDate: new Date(),
                }),
                isSelected: defaultIsSelected,
              },
            ]}
          />

          <div className={styles.footer}>
            <div className={styles.buttons}>
              <ButtonCustom onClick={cancelHandler} outline>
                {'Cancel'}
              </ButtonCustom>
              <ButtonCustom onClick={applyHandler}>{'Apply'}</ButtonCustom>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CalendarRange;
