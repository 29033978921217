import React from 'react';
import styles from './ChapterItem.module.scss';
import cs from 'classnames';
import useTooltip from '../../../../../../shared/hooks/fixedTooltip/useTooltip';
import Reminder from '../Reminder';

const ChapterItem = ({ Icon, text, tooltipText, isBeta, isActive, onClick, isMinimized, iconStyleKey, isContinue, disabled }) => {
  const { toolTip, onEnter, onLeave } = useTooltip('right', { text: tooltipText, className: styles.tooltip });

  const handleClick = () => {
    if (disabled) return;
    onClick();
  };

  return (
    <li
      className={cs(
        styles.chapterListItem,
        { [styles.disabled]: disabled },
        { [styles.active]: isActive },
        { [styles.minimized]: isMinimized }
      )}
      onClick={handleClick}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
    >
      <div className={styles.itemContent}>
        <span className={styles.itemLogo}>
          <Icon className={styles[iconStyleKey]} />
          {isContinue && <Reminder />}
        </span>
        <div className={styles.itemText}>
          {text}
          {isBeta && <span className={styles.itemAdditional}>Beta</span>}
        </div>
      </div>
      {toolTip}
    </li>
  );
};

export default ChapterItem;
