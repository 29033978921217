import React, { FC, memo, useCallback, useMemo } from 'react';
import styles from './TabAddBrief.module.scss';
import { ICreateBoardFormikValues } from '../../useCreateBoardFormik';
import { FormikProps } from 'formik';
import AddedFileOrBrief from '../../../../components/AddedFileOrBrief';
import { IFileData } from '../../../../../hooks/useFileUpload';
import { areEqualFormikState } from '../../../../../../../shared/utils/typescriptHelpers';
import useFileActionHandlersWithLoading from '../../../../../hooks/useFileActionHandlersWithLoading';
import AddDocumentButton from '../../components/AddDocumentButton';
import { useAppDispatch } from '../../../../../../../shared/hooks/reduxHooks';
import notificationDataConfig from '../../../../../../../shared/utils/notificationDataConfig';
import { TOAST_NOTIFICATION_TYPES } from '../../../../../../../shared/constants/toastNotificationsData';
import { createNotification } from '../../../../../../../actions/toastNotificationActions/toastNotification.actions';

type PropsType = {
  formik: FormikProps<ICreateBoardFormikValues>;
  openCreateBriefPage: () => void;
  onEditBrief: () => void;
  onDeleteBrief: () => void;
  isBoardBrief: boolean;
};

const propsAreEqual: Parameters<typeof memo<FC<PropsType>>>[1] = (prevProps, nextProps) => {
  return areEqualFormikState(['briefFile', 'createdBrief'], prevProps.formik, nextProps.formik);
};

const TabAddBrief: FC<PropsType> = memo(props => {
  const dispatch = useAppDispatch();
  const isBriefAdded = useMemo(() => {
    return Boolean(props.formik.values.createdBrief || props.formik.values.briefFile);
  }, [props.formik?.values.createdBrief, props.formik.values.briefFile]);

  const onAddBriefFile = useCallback(
    (value: null | IFileData) => {
      props.formik.setFieldTouched('briefFile');
      props.formik.setFieldValue('briefFile', value?.file || null);
    },
    [props.formik]
  );

  const { onFileUpload, onDelete, renderInput, isUploading, preloadingFile, loadingPercentageProgress } = useFileActionHandlersWithLoading(
    onAddBriefFile,
    null,
    { accept: '.pdf, .doc, .docx' },
    fileExtension => {
      const notification = notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.FILE_TYPE_ERROR_BRIEF_FILE, {
        text: [fileExtension],
      });
      dispatch(createNotification(notification));
    }
  );

  const onDeleteBrief = useMemo(() => {
    if (props.formik.values.briefFile) {
      return () => {
        props.formik.setFieldTouched('briefFile');
        onDelete();
      };
    }

    return () => {
      props.onDeleteBrief();
      props.formik.setFieldTouched('createdBrief');
    };
  }, [props.onDeleteBrief, props.formik.values.briefFile, onDelete]);

  const onEdit = useCallback(() => {
    props.formik.setFieldTouched('createdBrief');

    props.onEditBrief();
  }, [props.onEditBrief]);

  return (
    <div>
      {renderInput()}

      {isBriefAdded || isUploading ? (
        <AddedFileOrBrief
          type="brief"
          isUploading={isUploading}
          preloadingFile={preloadingFile}
          loadingPercentageProgress={loadingPercentageProgress}
          onChangeFile={onFileUpload}
          value={props.formik?.values.createdBrief || props.formik.values.briefFile}
          onEdit={onEdit}
          onDelete={onDeleteBrief}
          isBoardBrief={props.isBoardBrief}
        />
      ) : null}

      {!isBriefAdded && !isUploading && (
        <div className={styles.buttonsContainer}>
          <AddDocumentButton presetIcon="upload" onClick={onFileUpload}>
            Upload PDF or Word file
          </AddDocumentButton>

          <AddDocumentButton
            presetIcon="plus"
            onClick={() => {
              props.formik.setFieldTouched('createdBrief');
              props.openCreateBriefPage();
            }}
          >
            Create an Otterfish Brief
          </AddDocumentButton>
        </div>
      )}
    </div>
  );
}, propsAreEqual);

export default TabAddBrief;
