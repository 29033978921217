import {useAppSelector} from "../../../../shared/hooks/reduxHooks";
import {ContentBoardPermission} from "../../../../models/permissions/enum/ContentBoardPermission";

export default function useCanEditResult(): boolean {
  const pageModel = useAppSelector(state => state.contentBoard.pageModel);

  if (!pageModel) return false;

  return pageModel.roleCan(ContentBoardPermission.CAN_EDIT_RESULTS);
}
