import React, { forwardRef, ReactElement, useRef, useState } from 'react';
import styles from './ThreeDotsButton.module.scss';
import { ReactComponent as ThreeDotsIcon } from '../../assets/img/icons/threeDots.svg';
import cs from 'classnames';
import { useClickOutside } from '../../shared/hooks/useClickOutside';
import ActionsDropdownV1, { ActionsDropdownV1Option } from '../ActionsDropdownV1';

type PropsType = {
  className?: string;
  dropdownClassName?: string;
  options: ActionsDropdownV1Option[];
  renderOptions?: (option: ActionsDropdownV1Option, idx: number, onClose: () => void) => ReactElement;
};

const ThreeDotsButton = forwardRef<HTMLDivElement | null, PropsType>(
  ({ className, dropdownClassName, options, renderOptions }, buttonRef) => {
    const ignoreRef = useRef<HTMLDivElement | null>(null);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const [isActive, setIsActive] = useState<boolean>(false);

    const onClose = () => setIsActive(false);
    const onOpen = event => {
      event.stopPropagation();
      setIsActive(!isActive);
    };

    useClickOutside(dropdownRef, ignoreRef, () => onClose());

    return (
      <div className={cs(styles.root, className)} ref={ignoreRef}>
        <div className={cs(styles.button, { [styles.active]: isActive })} ref={buttonRef} onClick={onOpen}>
          <ThreeDotsIcon />
        </div>

        <ActionsDropdownV1
          className={dropdownClassName}
          isOpen={isActive}
          onClose={onClose}
          options={options}
          renderOptions={renderOptions}
        />
      </div>
    );
  }
);

export default ThreeDotsButton;
