import React, { useEffect, useRef } from 'react';
import { Guest } from '../../models/permissions/users/Guest';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useSetUserModel from './useSetUserModel';

const useChangeUserModel = () => {
  const isAuthenticated = useSelector(state => !!state.auth.token);
  const userModel = useSelector(state => state.auth.userModel);
  const loc = useLocation();

  const userModelRef = useRef(userModel);

  const setNewUserModel = useSetUserModel();

  useEffect(() => {
    userModelRef.current = userModel;
  }, [userModel, loc]);

  useEffect(() => {
    setNewUserModel();
  }, [isAuthenticated]);

  useEffect(() => {
    const handlePopstate = e => {
      if (userModelRef.current instanceof Guest && !userModelRef.current?.isContainRoute(e.target.location.pathname)) {
        setNewUserModel(null, true);
      }
    };
    window.addEventListener('popstate', handlePopstate);
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [setNewUserModel]);
};

export default useChangeUserModel;
