import { useCallback } from 'react';
import useFileUpload, { IFileData } from './useFileUpload';
import useHiddenFileInput from './useHiddenFileInput';
import useDownloadAsset from '../../../shared/hooks/useDownloadAsset';
import { IFile } from '../../../models/entities/file/File';
import useValidateFileInputAccept from './useValidateFileInputAccept';

const useFileActionHandlers = (
  handleAction: (value: IFileData | null) => void,
  file?: IFile | null,
  inputSettings?: Parameters<typeof useHiddenFileInput>[1],
  onError?: (errorExtension: string) => void,
): {
  onDelete: () => void;
  onDownload: () => void;
  onFileUpload: () => void;
  renderInput: () => JSX.Element;
} => {
  const handleFileUpload = useFileUpload({ handleCreate: handleAction });
  const validateFileUpload = useValidateFileInputAccept(inputSettings?.accept, handleFileUpload, onError);
  const { renderInput, clickFileInput: onFileUpload } = useHiddenFileInput(validateFileUpload, inputSettings);

  const { downloadAsset } = useDownloadAsset(file?.name ? () => file?.name : null);

  const onDelete = useCallback(() => {
    handleAction(null);
  }, [handleAction]);

  const onDownload = useCallback(() => {
    if (!file?.url || !file?.extension) return;
    downloadAsset(file.url, file.extension);
  }, [file?.extension, file?.url, downloadAsset]);

  return {
    onDelete,
    onDownload,
    onFileUpload,
    renderInput,
  };
};

export default useFileActionHandlers;
