import React, { ComponentProps, FC, useCallback } from 'react';
import RoundedWhiteCard from '../../../../../../../components/Cards/RoundedWhiteCard';
import styles from './BriefMediaCard.module.scss';
import { ReactComponent as VisualsIcon } from '../../../../../../../assets/img/icons/visuals-icon.svg';

type PropsType = {
  visualsLength: number;
} & Omit<ComponentProps<typeof RoundedWhiteCard>, 'headerClass' | 'renderHeaderLeft' | 'rootClass'>;

const BriefMediaCard: FC<PropsType> = props => {
  const renderHeaderLeft = useCallback(() => {
    return props.visualsLength ? <div className={styles.visualsNumber}>{props.visualsLength}</div> : null;
  }, [props.visualsLength]);

  return (
    <RoundedWhiteCard
      rootClass={styles.root}
      renderHeaderLeft={renderHeaderLeft}
      HeaderIcon={VisualsIcon}
      headerClass={styles.header}
      {...props}
      bodyClass={styles.body}
    />
  );
};

export default BriefMediaCard;
