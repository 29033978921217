import React, { FC } from 'react';
import LoadingContainer from '../../../../../../../../components/LoadingContainer';

const TagsListLoadingState: FC = () => {
  return (
    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', columnGap: '10px', width: '100%' }}>
      <LoadingContainer height={16} width={'100%'} />
      <LoadingContainer height={16} width={'100%'} />
      <LoadingContainer height={16} width={'100%'} />
    </div>
  );
};

export default TagsListLoadingState;
