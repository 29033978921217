import { IBoardInvitedCollaborator } from '../BoardInvitedCollaborator';
import { COLLABORATOR_ROLE_TYPES } from '../../../permissions/collaborator/CollaboratorRole';
import { IBoardInvitedCollaboratorDTO } from '../dto/BoardInvitedCollaborator';

export class BoardInvitedCollaboratorHydrator implements IBoardInvitedCollaborator {
  accepted_at: null | string;
  custom_message: null | string;
  email: string;
  expires_at: string;
  id: number;
  invited_at: string;
  inviter_first_name: string;
  inviter_id: number;
  inviter_last_name: string;
  inviter_organization_id: number;
  inviter_organization_name: string;
  new_first_name: null | string;
  new_last_name: null | string;
  new_organization_id: null | string | number;
  new_organization_name: null | string;
  new_organization_type: COLLABORATOR_ROLE_TYPES;
  new_user_id: string | number;
  object_id: number;
  object_type: 'content_board';
  token: string;

  constructor(dto: IBoardInvitedCollaboratorDTO) {
    this.accepted_at = dto.accepted_at;
    this.custom_message = dto.custom_message;
    this.email = dto.email;
    this.expires_at = dto.expires_at;
    this.id = dto.id;
    this.invited_at = dto.invited_at;
    this.inviter_first_name = dto.inviter_first_name;
    this.inviter_id = dto.inviter_id;
    this.inviter_last_name = dto.inviter_last_name;
    this.inviter_organization_id = dto.inviter_organization_id;
    this.inviter_organization_name = dto.inviter_organization_name;
    this.new_first_name = dto.new_first_name;
    this.new_last_name = dto.new_last_name;
    this.new_organization_id = dto.new_organization_id;
    this.new_organization_name = dto.new_organization_name;
    this.new_organization_type = dto.new_organization_type;
    this.new_user_id = dto.new_user_id;
    this.object_id = dto.object_id;
    this.object_type = dto.object_type;
    this.token = dto.token;
  }
}
