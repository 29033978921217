import moment from 'moment';

const timeFormat = 'hh:mm A';

const timeArray: string[] = (function () {
  const dt = new Date(1970, 0, 1);
  const rc: string[] = [];
  while (dt.getDate() === 1) {
    rc.push(moment(dt).format(timeFormat));
    dt.setMinutes(dt.getMinutes() + 15);
  }
  return rc;
})();

export const maxTimeIndex = 95;
export const minTimeIndex = 0;
export const DEFAULT_TIME_INDEX = 36; // 9:00 AM

export default timeArray;
