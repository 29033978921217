import React, { FC, memo, useCallback, useMemo, useRef, useState } from 'react';
import styles from './BoardSubHeader.module.scss';
import { FormikErrors } from 'formik';
import { ICreateBoardFormikValues } from '../../useCreateBoardFormik';
import ErrorsInformationTooltip from '../ErrorsInformationTooltip';
import useFormikErrorsArray from '../../../../hooks/useFormikErrorsArray';
import BorderedButton from '../../../../../../../components/Buttons/WhiteBorderedButton';

type PropsType = {
  onSubmit?: () => void;
  onCancel?: () => void;
  title?: string;
  text?: string;
  isDisabledSubmit?: boolean;
  formikErrors?: FormikErrors<ICreateBoardFormikValues>;
  deliverablesError: string | null;
};

const ErrorsTextSettings = {
  boardName: 'Board name',
  brandName: 'Brand name',
  initiatorOrganizationType: 'Your organization type',
  inviteEmail: 'Collaborator email',
  inviteOrganizationType: 'Collaborator organization type',
};

const BoardSubHeader: FC<PropsType> = memo(props => {
  const [isErrorTooltipOpen, setIsErrorTooltipOpen] = useState<boolean>(true);
  const [isTriedToSubmit, setIsTriedToSubmit] = useState<boolean>(false);
  const ignoreRef = useRef<HTMLButtonElement>(null);

  const onCloseErrorTooltip = useCallback(() => {
    setIsErrorTooltipOpen(false);
  }, []);

  const formikErrorsArray: string[] = useFormikErrorsArray(props.formikErrors || null, ErrorsTextSettings);

  const formErrors = useMemo(() => {
    const newErrors = [...formikErrorsArray];
    if (props.deliverablesError) {
      newErrors.push(props.deliverablesError);
    }
    return newErrors;
  }, [formikErrorsArray, props.deliverablesError]);

  const onSubmit = useCallback(() => {
    setIsErrorTooltipOpen(true);
    if (!isTriedToSubmit) setIsTriedToSubmit(true);

    if (!props.isDisabledSubmit && props.onSubmit) props.onSubmit();
  }, [isTriedToSubmit, props.onSubmit, props.isDisabledSubmit]);

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.title}>{props.title}</div>
        <div className={styles.text}>{props.text}</div>
      </div>
      <div className={styles.navigationButtons}>
        {props.onCancel && (
          <BorderedButton size="large" designVersion={'transparent'} onClick={props.onCancel}>
            Cancel
          </BorderedButton>
        )}
        <div className={styles.submitButtonContainer}>
          {props.onSubmit && (
            <BorderedButton
              // withDisabledClass={!!formikErrorsArray.length || !!props.deliverablesError}
              size="large"
              designVersion={'white'}
              ref={ignoreRef}
              className={styles.submitButton}
              onClick={onSubmit}
            >
              Complete Board
            </BorderedButton>
          )}
          {(formikErrorsArray.length || props.deliverablesError) && isTriedToSubmit ? (
            <ErrorsInformationTooltip
              isOpen={isErrorTooltipOpen}
              onClose={onCloseErrorTooltip}
              ref={ignoreRef}
              className={styles.errorTooltip}
              message="Before you add the board, please fill in required fields"
              errors={formErrors}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
});

export default BoardSubHeader;
