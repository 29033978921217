import React, { FC } from 'react';
import styles from './PlatformsList.module.scss';
import TagsListLoadingState from '../TagsList/TagsListLoadingState';
import TextContainer from '../TextContainer';
import DeliverablePlatform from '../../../../../../../components/Cells/DeliverableCell/DeliverablePlatform';
import { PLATFORMS } from '../../../../../../../models/enums/Platforms';

type PropsType = {
  platforms: PLATFORMS[] | null;
  text?: string;
  isLoading?: boolean;
};

const PlatformsList: FC<PropsType> = props => {
  return (
    <div className={styles.root}>
      {props.isLoading ? <TagsListLoadingState /> : null}
      {!props.isLoading && props.platforms?.length
        ? props.platforms?.map((platform, index) => (
            <DeliverablePlatform className={styles.platformsContainer} platform={platform} customText={props.text} key={index} />
          ))
        : null}
      {!props.isLoading && !props.platforms?.length ? <TextContainer /> : null}
    </div>
  );
};

export default PlatformsList;
