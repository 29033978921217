import React, { ChangeEvent, FC, memo, useCallback } from 'react';
import TextField from '../../../../../components/FormControls/TextField';
import { FormikProps } from 'formik';
import { areEqualFormikState } from '../../../../../shared/utils/typescriptHelpers';
import useFormikDebouncedState from '../../../../../shared/hooks/useFormikDebouncedState';

type TabBoardPropsType = {
  className?: string;
  title: string;
  placeholder: string;
  field: string;
  formik: FormikProps<any>;
};

const propsAreEqual: Parameters<typeof memo<FC<TabBoardPropsType>>>[1] = (prevProps, nextProps) => {
  return areEqualFormikState([nextProps.field], prevProps.formik, nextProps.formik);
};

const TabMainName: FC<TabBoardPropsType> = memo(({ className, formik, field, placeholder, title }) => {
  const [value, setValue] = useFormikDebouncedState<string>((value: string) => formik.setFieldValue(field, value), formik.values[field]);

  const onChangeName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  return (
    <div>
      <h3>{title}</h3>
      <TextField
        className={className}
        name={field}
        placeholder={placeholder}
        onChange={onChangeName}
        onBlur={formik.handleBlur}
        value={value}
        error={formik.touched[field] ? (formik.errors[field] as string | undefined) : undefined}
      />
    </div>
  );
}, propsAreEqual);

export default TabMainName;
