import React, { FC } from 'react';
import styles from './BoardBriefHeader.module.scss';
import ButtonLightPurpleWhite from '../../../../../components/Buttons/ButtonLightPurpleWhite';
import { ReactComponent as EditIcon } from '../../../../../assets/img/icons/edit_pen.svg';
import cs from 'classnames';
import LoadingContainer from '../../../../../components/LoadingContainer';

type PropsType = {
  briefName?: string | null;
  className?: string;
  isLoading: boolean;
  onEdit: () => void;
  isEditDisplay: boolean;
};

const BoardBriefHeader: FC<PropsType> = props => {
  return (
    <div className={cs(styles.root, props.className)}>
      {props.isLoading ? (
        <LoadingContainer minWidth={180} maxWidth={350} width={'20%'} height={22} />
      ) : (
        <h1 className={styles.title}>{props.briefName}</h1>
      )}

      {!!props.onEdit && props.isEditDisplay && (
        // @ts-ignore
        <ButtonLightPurpleWhite className={styles.editButton} designVersion={'v2'} size={'medium'} Icon={EditIcon} onClick={props.onEdit}>
          Edit
        </ButtonLightPurpleWhite>
      )}
    </div>
  );
};

export default BoardBriefHeader;
