import FileInformationTooltip from './components/FileInformationTooltip';
import React, { FC, ReactElement, ReactNode } from 'react';
import { IBriefAsset } from '../../../models/entities/asset/BriefAsset';
import VisualPopover from '../VisualPopover';
import { AssetStateType } from '../../../models/entities/asset/AssetState';
import {IResultAsset} from "../../../models/entities/asset/ResultAsset";

type PropsType = {
  placement: 'bottom' | 'left' | 'right' | 'top';
  asset: IBriefAsset | AssetStateType | IResultAsset;
  isEditingMode: boolean;
  isGreenTag?: boolean;
  isDisabled?: boolean;
  children: JSX.Element | ReactElement | ReactNode;
  onReplace?: (close: () => void) => void;
  onDelete?: (close: () => void) => void;
};

const VisualTileTooltipWrapper: FC<PropsType> = props => {
  return (
    <VisualPopover
      placement={props.placement}
      isDisabled={props.isDisabled}
      render={({ close }) => (
        <FileInformationTooltip
          asset={props.asset}
          isEditingMode={props.isEditingMode}
          isGreenTag={props.isGreenTag}
          onDelete={() => props.onDelete && props.onDelete(close)}
          onReplace={() => props.onReplace && props.onReplace(close)}
        />
      )}
    >
      {props.children}
    </VisualPopover>
  );
};
export default VisualTileTooltipWrapper;
