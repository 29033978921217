import React, { FC, ReactElement, ReactNode } from 'react';
import LoadingContainerWrapper from '../../../../../../../../../components/LoadingContainerWrapper';
import LoadingContainer from '../../../../../../../../../components/LoadingContainer';

type PropsType = {
  children?: JSX.Element | ReactElement | ReactNode | string | null;
  isLoading?: boolean;
};
const ButtonsLoaderWrapper: FC<PropsType> = ({ children, isLoading }) => {
  return (
    <>
      {isLoading ? (
        <>
          <LoadingContainerWrapper height="30px" width="91px" borderRadius="4px" columnGap="5px" background="#F7F8F9">
            <LoadingContainer height="16px" width="16px" borderRadius="3px" />
            <LoadingContainer height="16px" width="50px" borderRadius="3px" />
          </LoadingContainerWrapper>
          <LoadingContainerWrapper height="30px" width="100px" borderRadius="4px" background="#F7F8F9">
            <LoadingContainer height="16px" width="80px" borderRadius="3px" />
          </LoadingContainerWrapper>
        </>
      ) : (
        children
      )}
    </>
  );
};

export default ButtonsLoaderWrapper;
