import React, { FC, useCallback } from 'react';
import ContentApprovalEmptyHeader from '../ContentApprovalEmptyHeader';
import ContentApprovalEmptyContent from '../ContentApprovalEmptyContent';
import styles from './ContentApprovalEmptyPage.module.scss';
import { ORGANIZATION_TYPES } from '../../../../../models/enums/OrganizationTypes';
import WatchDemoBriefsModal from '../../../../../components/Modals/WatchDemoBriefsModal';
import useWatchDemoHandlers from '../../hooks/useWatchDemoHandlers';

type PropsType = {
  onCreateBoardClick: (route: string) => void;
  createBoardRoute: string;
  organizationType: ORGANIZATION_TYPES;
};

const ContentApprovalEmptyPage: FC<PropsType> = props => {
  const onCreateBoardClick = useCallback(
    () => props.onCreateBoardClick(props.createBoardRoute),
    [props.createBoardRoute, props.onCreateBoardClick]
  );

  const { isOpenWatchDemoModal, closeWatchDemoModal, onPauseAction, onPlayAction, openWatchDemoModal } = useWatchDemoHandlers();

  return (
    <>
      <ContentApprovalEmptyHeader organizationType={props.organizationType} />
      <ContentApprovalEmptyContent
        onWatchDemoClick={openWatchDemoModal}
        rootClassName={styles.emptyContentRoot}
        isArchive={false}
        onCreateBoardClick={onCreateBoardClick}
      />
      <WatchDemoBriefsModal
        pageType="contentApproval"
        buttonText="New Board"
        buttonLink={'/content-approval/create'}
        buttonAction={onCreateBoardClick}
        open={isOpenWatchDemoModal}
        onClose={closeWatchDemoModal}
        onPause={onPauseAction}
        onPlay={onPlayAction}
      />
    </>
  );
};

export default ContentApprovalEmptyPage;
