import { UserPermission } from '../enum/UserPermission';
import { User } from './User';
import { COLLABORATOR_ROLE_TYPES } from '../collaborator/CollaboratorRole';

export type AllowedRouteType = { route: string; path: string };
type SharedDataPayloadType = { collaboratorRole: COLLABORATOR_ROLE_TYPES };

export interface ISharedData {
  id: number;
  object_id: number;
  object_type: string;
  hash: string;
  link: string;
  payload: SharedDataPayloadType;
  creator_id: string;
  created_at: Date;
  updater_id?: any;
  updated_at?: any;
  object_name: string;
}

export abstract class Guest extends User {
  abstract permissions: UserPermission[];
  sharedData: ISharedData;
  allowedRoutes: AllowedRouteType[] = [];

  constructor(sharedData: ISharedData, allowedRoutes: AllowedRouteType[]) {
    super();
    this.sharedData = sharedData;
    this.allowedRoutes = allowedRoutes;
  }

  isAllowRoute(route: string): boolean {
    return !!this.allowedRoutes.find(item => item.route === route);
  }

  isContainRoute(route: string): boolean {
    return this.allowedRoutes.some(item => new RegExp(`${item.path}`).test(route));
  }
}
