import React from 'react';
import styles from './LoadingState.module.scss';
import LoadingContainer from '../../../../../components/LoadingContainer';
import { useMediaQuery } from 'react-responsive/src';

const LoadingState = ({ index }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  return (
    <div className={styles.root}>
      <LoadingContainer
        width={isMobile ? '28px' : '35px'}
        height={isMobile ? '28px' : '35px'}
        borderRadius={'50%'}
        margin={'0 10px 0 0'}
        animationDelay={`${-2 + index * 0.1}s`}
      />
      <div className={styles.lines}>
        <LoadingContainer
          width={'66%'}
          height={'11px'}
          margin={isMobile ? '0 0 5px 0' : '0 30px 0 0'}
          animationDelay={`${-2 + index * 0.15}s`}
        />
        <LoadingContainer
          width={'70px'}
          height={'11px'}
          margin={'0 30px 0 0'}
          animationDelay={`${-2 + index * 0.2}s`}
          className={styles.status}
        />
        <LoadingContainer width={'40%'} height={'8px'} margin={'0 30px 0 0'} animationDelay={`${-2 + index * 0.25}s`} />
      </div>
      <LoadingContainer height={isMobile ? '20px' : '34px'} width={'20%'} animationDelay={`${-2 + index * 0.3}s`} />
    </div>
  );
};

export default LoadingState;
