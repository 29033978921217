import { IBoardBriefFormikValues, LogoFileObjectType } from '../../boardBrief/BoardBriefFormikValues';
import { IDeliverableFormData } from '../../../../pages/ContentApproval/ContentBoard/components/AddDeliverablesModal/hooks/useDeliverablesFormData';
import { COLLABORATOR_ROLE_TYPES } from '../../../permissions/collaborator/CollaboratorRole';
import { ICreateBoardFormikValues } from '../../../../pages/ContentApproval/createPages/CreateBoardWrapper/CreateBoard/useCreateBoardFormik';
import { IBoard } from '../Board';

export class BoardFormikValuesHydrator implements ICreateBoardFormikValues {
  boardName: string;
  initiatorOrganizationType: COLLABORATOR_ROLE_TYPES | '';
  inviteEmail: string;
  inviteOrganizationType: COLLABORATOR_ROLE_TYPES | '';
  inviteMessage: string;
  createdBrief: IBoardBriefFormikValues | null;
  deliverables: IDeliverableFormData[];
  briefFile: null | File;
  contractFile: null | File;
  brandLogo: LogoFileObjectType;
  brandName: string | null;

  constructor(
    board: IBoard,
    organizationId: string | number,
    deliverables: IDeliverableFormData[],
    userTimezoneName: string,
    boardBriefFormikValues?: IBoardBriefFormikValues | null
  ) {
    this.boardName = board.name;
    this.initiatorOrganizationType = board.collaborators.find(
      collaborator => collaborator.organization_id == organizationId
    )!.collaborator_type;
    this.inviteEmail = '';
    this.inviteOrganizationType = '';
    this.inviteMessage = '';
    this.createdBrief = boardBriefFormikValues ?? null;
    this.deliverables = deliverables;
    this.briefFile = null;
    this.contractFile = null;
    this.brandLogo = { file: null, fileUrl: null };
    this.brandName = board.brand_name;
  }

  async setBoardFiles(board: IBoard): Promise<PromiseSettledResult<void>[]> {
    const promiseArr: Promise<void>[] = [];
    if (board.briefFile) {
      promiseArr.push(
        fetch(board.briefFile.url)
          .then(res => res.blob())
          .then(blob => {
            this.briefFile = new File([blob], board.briefFile?.name || 'brief', { type: board.briefFile?.mimeType });
          })
      );
    }

    if (board.contractFile) {
      promiseArr.push(
        fetch(board.contractFile.url)
          .then(res => res.blob())
          .then(blob => {
            this.contractFile = new File([blob], board.contractFile?.name || 'contract', { type: board.contractFile?.mimeType });
          })
      );
    }

    if (board.brand_logo_location) {
      promiseArr.push(
        fetch(board.brand_logo_location)
          .then(res => res.blob())
          .then(blob => {
            const file = new File([blob], board.briefFile?.name || 'brief', { type: blob.type });

            this.brandLogo = {
              file,
              fileUrl: board.brand_logo_location,
            };
          })
      );
    }

    return await Promise.allSettled(promiseArr);
  }
}
