import React, { FC, ReactElement, useState } from 'react';
import Loader from '../../../../../../../expert/components/Loader';

export type MediaDueTypeProps = {
  isVideo: boolean;
  isVideoProcessing: boolean;
  renderImageView: (onLoad: () => void) => ReactElement;
  renderVideoView: (onLoad: () => void) => ReactElement;
  renderVideoProcessingView: () => ReactElement;
  loaderClassName: string;
};

const MediaDueType: FC<MediaDueTypeProps> = ({
  isVideo,
  isVideoProcessing,
  renderVideoProcessingView,
  renderImageView,
  renderVideoView,
  loaderClassName,
}) => {
  const [isMediaLoading, setIsMediaLoading] = useState(true);

  const onLoad = () => {
    setIsMediaLoading(false);
  };

  if (isVideo && isVideoProcessing) return renderVideoProcessingView();

  if (isVideo)
    return (
      <>
        {isMediaLoading && (
          <Loader className={loaderClassName} mainColor={undefined} fillColor={undefined} accentColor={undefined} textContent={undefined} />
        )}
        {renderVideoView(onLoad)}
      </>
    );

  return (
    <>
      {isMediaLoading && (
        <Loader className={loaderClassName} mainColor={undefined} fillColor={undefined} accentColor={undefined} textContent={undefined} />
      )}
      {renderImageView(onLoad)}
    </>
  );
};

export default MediaDueType;
