import React, { memo, ReactElement, ReactNode } from 'react';
import styles from './TextField.module.scss';
import cs from 'classnames';

type PropsType = {
  name?: string;
  wrapperClassName?: string;
  className?: string;
  isValid?: boolean;
  type?: JSX.IntrinsicElements['input']['type'];
  error?: string | false;
  icon?: JSX.Element | ReactNode | ReactElement | null;
  icon2?: JSX.Element | ReactNode | ReactElement | null;
} & JSX.IntrinsicElements['input'];

export default memo(
  React.forwardRef<HTMLInputElement, PropsType>(
    ({ type = 'text', wrapperClassName, className, isValid = true, error, icon, icon2, ...other }, ref) => {
      return (
        <span className={cs(styles.inputContainer, wrapperClassName)}>
          <input type={type} ref={ref} className={cs(styles.TextField, { [styles.error]: !isValid || error }, className)} {...other} />
          {icon}
          {icon2}
          {error && <span className={styles.error}>{error}</span>}
        </span>
      );
    }
  )
);
