import React from 'react';
import styles from './StorageProgressBar.module.scss';
import cs from 'classnames';

type PropsType = {
  current: number;
  of: number;
};

const StorageProgressBar = ({ current, of }: PropsType) => {
  const defaultTrack = Number(of) !== -1 ? (current / of) * 100 : 15;
  return (
    <div className={styles.root}>
      <div className={styles.progressBar}>
        <div className={cs(styles.track)} style={{ width: `${defaultTrack}%`, display: `${!defaultTrack ? 'none' : 'flex'}` }} />
      </div>
    </div>
  );
};

export default StorageProgressBar;
