import React, { FC } from 'react';
import LoadingContainer from '../../../../../../../../../components/LoadingContainer';
import { useMediaQuery } from 'react-responsive';

const VisualsCardLoadingState: FC = () => {
  const isMediumDevice = useMediaQuery({ query: '(max-width: 1200px)' });

  return (
    <>
      <LoadingContainer width={'100%'} height={100} borderRadius={'4px'} />
      <LoadingContainer width={'100%'} height={100} borderRadius={'4px'} />
      <LoadingContainer width={'100%'} height={100} borderRadius={'4px'} />

      <LoadingContainer width={'100%'} height={100} borderRadius={'4px'} />
      <LoadingContainer width={'100%'} height={100} borderRadius={'4px'} />
      <LoadingContainer width={'100%'} height={100} borderRadius={'4px'} />

      <LoadingContainer width={'100%'} height={100} borderRadius={'4px'} />
      <LoadingContainer width={'100%'} height={100} borderRadius={'4px'} />
      {isMediumDevice ? null : <LoadingContainer width={'100%'} height={100} borderRadius={'4px'} />}
    </>
  );
};

export default VisualsCardLoadingState;
