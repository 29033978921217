import React, { FC } from 'react';
import styles from './BrandNameSection.module.scss';
import cs from 'classnames';
import TMP_IMG from '../../../../../assets/img/brandPlaceholder.svg';
import BrandNameSectionLoadingState from './LoadingState';

type PropsType = {
  className?: string;
  brandLogo?: string | null;
  brandName?: string | null;
  isLoading: boolean;
};

const BrandNameSection: FC<PropsType> = props => {
  if (props.isLoading) {
    return <BrandNameSectionLoadingState />;
  }
  return (
    <div className={cs(styles.root, props.className)}>
      <img className={styles.brandLogo} src={props.brandLogo || TMP_IMG} alt="brand logo" />
      <div className={styles.textWrapper}>
        <h3 className={styles.brandName}>{props.brandName}</h3>
        <p className={styles.brandText}>Brand</p>
      </div>
    </div>
  );
};

export default BrandNameSection;
