import React from 'react';
import styles from './LoadingOrganizations.module.scss';
import LoadingContainer from '../../../components/LoadingContainer';
import { useMediaQuery } from 'react-responsive/src';

const LoadingOrganizations = ({ index }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 575px)' });
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div className={styles.card}>
      <div className={styles.firstBlock}>
        <LoadingContainer
          width={isMobile ? '40%' : '226px'}
          height={'20px'}
          margin={'0 0 18px 0'}
          borderRadius={'10px'}
          backgroundColor={'#E9E9F9'}
          animationDelay={`${0.2 + index * 0.2}s`}
        />
        <div>
          <LoadingContainer
            width={isMobile ? '50%' : '135px'}
            height={'26px'}
            margin={'0 10px 0 0'}
            borderRadius={'20px'}
            backgroundColor={'#E9E9F9'}
            animationDelay={`${0.2 + index * 0.2}s`}
          />
          <LoadingContainer
            width={isMobile ? '50%' : '126px'}
            height={'26px'}
            borderRadius={'20px'}
            backgroundColor={'#E9E9F9'}
            animationDelay={`${0.2 + index * 0.2}s`}
          />
        </div>
      </div>
      {!isTablet && (
        <div>
          <LoadingContainer
            width={'226px'}
            height={'24px'}
            borderRadius={'10px'}
            backgroundColor={'#E9E9F9'}
            animationDelay={`${0.2 + index * 0.2}s`}
          />
        </div>
      )}
      <div className={styles.lastBlock}>
        <LoadingContainer
          width={isTablet ? '100%' : '84px'}
          height={isTablet ? '30px' : '40px'}
          borderRadius={'4px'}
          backgroundColor={'#E9E9F9'}
          animationDelay={`${0.2 + index * 0.2}s`}
        />
      </div>
    </div>
  );
};

export default LoadingOrganizations;
