import React, {
  forwardRef,
  MouseEventHandler,
  MutableRefObject,
  PropsWithChildren,
  ReactNode,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import styles from './DotsDropdownButton.module.scss';
import { useClickOutside } from '../../shared/hooks/useClickOutside';
import cs from 'classnames';

type PropsType = {
  buttonClassName?: string;
  customSVG?: null | ReactNode | JSX.Element;
  renderDropdown?: (refList: MutableRefObject<HTMLDivElement | null>, close: () => void) => null | ReactNode | JSX.Element;
  onOpenAction?: () => void;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  ignoreRef: MutableRefObject<HTMLDivElement | null>;
} & PropsWithChildren;

const DotsDropdownButton = forwardRef<(() => void) | null, PropsType>(
  (
    {
      ignoreRef,
      buttonClassName,
      customSVG = null,
      renderDropdown = () => null,
      onOpenAction = () => {},
      children,
      onMouseEnter,
      onMouseLeave,
    },
    toggleDropDownRef
  ) => {
    const refList = useRef<HTMLDivElement | null>(null);
    const refButton = useRef(null);
    const [isListOpen, setIsListOpen] = useState<boolean>(false);

    useImperativeHandle(toggleDropDownRef, () => () => setIsListOpen(prev => !prev), [isListOpen]);

    useClickOutside(refList, ignoreRef || refButton, () => setIsListOpen(false));

    const onClickHandler: MouseEventHandler<HTMLDivElement> = event => {
      event.stopPropagation();
      if (!isListOpen) {
        onOpenAction();
      }
      setIsListOpen(prev => !prev);
    };

    return (
      <div className={styles.root}>
        <div
          className={cs(buttonClassName, styles.dots)}
          ref={refButton}
          onClick={onClickHandler}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {customSVG ? (
            customSVG
          ) : (
            <>
              <div className={styles.dot} />
              <div className={styles.dot} />
              <div className={styles.dot} />
            </>
          )}
          {children}
        </div>

        {isListOpen && renderDropdown(refList, () => setIsListOpen(false))}
      </div>
    );
  }
);

export default DotsDropdownButton;
