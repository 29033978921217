import { DELIVERABLE_CALL_TO_ACTIONS_TEXTS } from './constants';
import { TYPES_CAMPAIGNS } from '../../../shared/constants/constants';
import { DELIVERABLE_CALL_TO_ACTIONS as DELIVERABLE_CALL_TO_ACTIONS_TYPE } from '../../../models/entities/deliverable/enums/DeliverableCallToActions';
import { ASSET_STATUSES } from '../../../models/entities/asset/enums/AssetStatuses';
import { AssetStateType } from '../../../models/entities/asset/AssetState';
import { IAsset } from '../../../models/entities/asset/Asset';
import { ICreatedAsset } from '../../../models/entities/asset/createdAsset';

export const getCallToActionLabelByValue = (value: DELIVERABLE_CALL_TO_ACTIONS_TYPE | null): string | null => {
  const cta = DELIVERABLE_CALL_TO_ACTIONS_TEXTS.find(cta => cta.value === value);
  if (cta) {
    return cta.label;
  }
  return null;
};

export const isRoleBrandAgency = role => [TYPES_CAMPAIGNS.BRAND, TYPES_CAMPAIGNS.AGENCY].includes(role);

export const mutateAssetsAfterConverting = (currentAssets: AssetStateType[], newAssets: AssetStateType[]) => {
  return currentAssets.map(asset => {
    if (asset.status === ASSET_STATUSES.PROCESSING && !('file' in asset)) {
      const newAsset = newAssets.find(nA => nA.id === asset.id && nA.status === ASSET_STATUSES.ACTIVE) as IAsset | undefined;
      if (newAsset) {
        const mutableAsset = { ...asset };
        mutableAsset.preview_location = newAsset.preview_location;
        mutableAsset.status = newAsset.status;
        mutableAsset.preview_file_id = newAsset.preview_file_id;
        return mutableAsset;
      }
    }
    return asset;
  });
};

export const isEqual = <FirstObjectType extends object, SecondObjectType extends object>(
  obj1: FirstObjectType,
  obj2: SecondObjectType,
  ignoreFields: string[] = [] as string[],
  onlyDeepIgnoreArrayFields?: Record<string, string[]>
) => {
  return (
    private_isEqual(obj1, obj2, ignoreFields, onlyDeepIgnoreArrayFields) &&
    private_isEqual(obj2, obj1, ignoreFields, onlyDeepIgnoreArrayFields)
  );
};

function isObject(object) {
  return object != null && typeof object === 'object';
}

/**
 * @return true when all properties from the object1 exist in the object2 and they are semantic equals
 */
function private_isEqual(obj1: object, obj2: object, ignoreFields: string[], onlyDeepIgnoreArrayFields?: Record<string, string[]>) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (ignoreFields.includes(key)) {
      continue;
    }
    const val1 = obj1[key];
    const val2 = obj2[key];
    const areArray = Array.isArray(val1) && Array.isArray(val2);
    const areObjects = isObject(val1) && isObject(val2);

    if (areArray && val1.length === val2.length) {
      let allIgnoreFields = [...ignoreFields];
      if (onlyDeepIgnoreArrayFields && onlyDeepIgnoreArrayFields[key]) {
        allIgnoreFields = onlyDeepIgnoreArrayFields[key];
      }
      for (let i = 0; i < val1.length; i++) {
        if (!isEqual(val1[i], val2[i], allIgnoreFields)) {
          return false;
        }
      }
    } else if (areObjects && !isEqual(val1, val2, ignoreFields)) {
      return false;
    } else if (!areObjects && !areArray && val1 !== val2) {
      return false;
    }
  }

  return true;
}

export const mutateItemInList = <ItemType extends { id: string | number }>(list: ItemType[], item: ItemType) => {
  let isChanged = false;
  const newList = list.map(listItem => {
    if (listItem.id === item.id) {
      isChanged = true;
      return Object.assign(listItem, item);
    }
    return listItem;
  });
  return { newList, isChanged };
};

export const getReorderedAssetsStates = (
  oldAssetsUpdateState: AssetStateType[],
  oldAssetsCreateState: ICreatedAsset[],
  oldAssetsCurrentState: AssetStateType[],
  reorderedAssets: AssetStateType[]
): {
  currentReorderedAssetsState: AssetStateType[];
  updatedReorderedAssetsState: AssetStateType[];
  createdReorderedAssetsState: ICreatedAsset[];
} => {
  const currentReorderedAssetsState: AssetStateType[] = [];
  const updatedReorderedAssetsState: AssetStateType[] = [];
  const createdReorderedAssetsState: ICreatedAsset[] = [];

  reorderedAssets.forEach((asset, index) => {
    const isAssetReordered = asset.id !== oldAssetsCurrentState[index]?.id;
    const isAssetInCreateState = oldAssetsCreateState.some(item => item.id === asset.id);
    const isAssetInUpdated = oldAssetsUpdateState.some(item => item.id === asset.id);

    if ((isAssetReordered || isAssetInUpdated) && !isAssetInCreateState) {
      updatedReorderedAssetsState.push({ ...asset, display_order: index + 1 });
    }

    if (isAssetInCreateState) {
      createdReorderedAssetsState.push({ ...(asset as ICreatedAsset), display_order: index + 1 });
    }

    currentReorderedAssetsState.push({ ...asset, display_order: index + 1 });
  });

  return {
    currentReorderedAssetsState,
    updatedReorderedAssetsState,
    createdReorderedAssetsState,
  };
};
