import React, { FC, SVGProps } from 'react';
import styles from './EmptyDocumentCard.module.scss';
import cs from 'classnames';

type PropsType = {
  className?: string;
  renderButtonSection: (className?: string) => JSX.Element | null;
  fileImage: FC<SVGProps<SVGSVGElement>>;
  title: string;
};

const EmptyDocumentCard: FC<PropsType> = props => {
  return (
    <div className={cs(styles.root, props.className)}>
      <props.fileImage className={styles.fileImage} />
      <h5 className={styles.title}>{props.title}</h5>
      {props.renderButtonSection()}
    </div>
  );
};

export default EmptyDocumentCard;
