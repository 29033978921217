import React, { FC } from 'react';
import styles from './TextContainer.module.scss';
import cs from 'classnames';
import TextContainerLoadingState from './TextContainerLoadingState';

type PropsType = {
  text?: string | null;
  isLoading?: boolean;
};

const TextContainer: FC<PropsType> = props => {
  const text: string | null = props.text?.trim() || null;

  if (props.isLoading) {
    return <TextContainerLoadingState />;
  }

  return (
    <div className={styles.root}>
      {text ? <p className={styles.text}>{text}</p> : <p className={cs(styles.text, styles.noInformation)}>{'No information yet'}</p>}
    </div>
  );
};

export default TextContainer;
