import React, { useState } from 'react';
import styles from './ShareLinkModal.module.scss';
import { Modal } from '../index';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { emailValidation } from '../../../pages/CreateTest/utils';
import TextField from '../../FormControls/TextField';
import TextArea from '../../Inputs/TextArea';
import LightPurpleButton from '../../../pages/CampaignBrief/CampaignBriefPage/components/LightPurpleButton';
import { ReactComponent as PlaneIcon } from '../../../assets/img/icons/plane.svg';
import LinkSVG from '../../../expert/components/IconsSvg/LinkSVG';
import { ReactComponent as CheckedIcon } from '../../../assets/img/icons/green-ok-icon-2.svg';
import { useDispatch, useSelector } from 'react-redux';
import * as contentApprovalServices from '../../../services/contentApproval';
import * as sharedServices from '../../../services/shared';
import { useParams } from 'react-router-dom';
import { createNotification } from '../../../actions/toastNotificationActions/toastNotification.actions';
import NotificationDataConfig from '../../../shared/utils/notificationDataConfig';
import Loader from '../../../expert/components/Loader';
import { TOAST_NOTIFICATION_TYPES } from '../../../shared/constants/toastNotificationsData';

const ModalContent = ({ onClose }) => {
  const dispatch = useDispatch();
  const currentOrg = useSelector(state => state.auth.currentOrganization);
  const params = useParams();
  const [isCopied, setIsCopied] = useState(false);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      custom_message: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().test('validate-email', emailValidation).required('Email is required'),
    }),
  });

  const handleSubmit = () => {
    setLoading(true);
    contentApprovalServices.getContentBoardShareLink(currentOrg.organization_id, params.boardId).then(res => {
      sharedServices
        .sharedViaEmail(res.data[0].hash, {
          emails: [{ email: formik.values.email }],
          custom_message: formik.values.custom_message || null,
        })
        .then(() => {
          dispatch(
            createNotification(NotificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.EMAIL_WITH_SHARED_LINK_SEND_SUCCESS, {}))
          );
        })
        .catch(() => {
          dispatch(
            createNotification(NotificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.EMAIL_WITH_SHARED_LINK_SEND_FAILED, {}))
          );
        })
        .finally(() => {
          setLoading(false);
          onClose();
        });
    });
  };

  const handleCopy = () => {
    contentApprovalServices
      .getContentBoardShareLink(currentOrg.organization_id, params.boardId)
      .then(res => {
        let link = res.data[0].link;
        if (window.location.hostname === 'localhost') {
          link = link.replace(/^.*\/shared/g, `${window.location.origin}/shared`);
        }
        navigator.clipboard.writeText(link);
        setIsCopied(true);
        dispatch(createNotification(NotificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.SHARED_LINK_COPIED_SUCCESS, {})));
      })
      .catch(() => {
        dispatch(createNotification(NotificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.SHARED_LINK_COPIED_FAILED, {})));
      });
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>Share board</div>
      <TextField
        name={'email'}
        placeholder={'Enter email'}
        wrapperClassName={styles.inputWrapper}
        className={styles.input}
        onChange={event => formik.setFieldValue('email', event.target.value.toLowerCase())}
        value={formik.values.email}
        error={formik.errors.email}
      />
      <TextArea
        placeholder={"Leave a message here. We'll send a URL to view the board without the need to signup"}
        name={'custom_message'}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.custom_message}
        error={formik.errors.custom_message}
        className={styles.messageTextArea}
      />
      <div className={styles.btnBlock}>
        {isCopied ? (
          <div className={styles.copyLinkButton}>
            <CheckedIcon className={styles.checked} />
            <span>Copied</span>
          </div>
        ) : (
          <div className={styles.copyLinkButton} onClick={handleCopy}>
            <LinkSVG />
            <span>Copy link</span>
          </div>
        )}
        <LightPurpleButton
          onClick={() => handleSubmit()}
          className={styles.actionButton}
          disabled={!formik.isValid || !formik.values.email || loading}
        >
          Send
          {loading ? <Loader className={styles.loader} /> : <PlaneIcon />}
        </LightPurpleButton>
      </div>
    </div>
  );
};

const ShareLinkModal = ({ ...props }) => {
  return (
    <Modal className={styles.modal} {...props} maxWidth="380px" borderRadius="10px">
      <ModalContent {...props} />
    </Modal>
  );
};

export default ShareLinkModal;
