import React, { FC, memo } from 'react';
import { FormikProps } from 'formik';
import { areEqualFormikState } from '../../../../../../../shared/utils/typescriptHelpers';
import styles from './TabBriefDetails.module.scss';
import SubTabHashtags from './SubTabHashtags';
import SubTabAudience from './SubTabAudience';
import SubTabOtherTerms from './SubTabOtherTerms';
import SubTabKeyMessage from './SubTabKeyMessage';
import SubTabPrefferedAdDeclaration from './SubTabPrefferedAdDeclaration';
import SubTabBudgetAndDuration from './SubTabBudgetAndDuration';
import SubTabCampaignObjective from './SubTabCampaignObjective';

type PropsType = {
  formik: FormikProps<any>;
};

const propsAreEqual: Parameters<typeof memo<FC<PropsType>>>[1] = (prevProps, nextProps) => {
  return areEqualFormikState(
    [
      'messaging',
      'campaignObjective',
      'adInCaption',
      'date_start',
      'date_end',
      'total_budget_currency',
      'total_budget',
      'showBoosting',
      'boosting_manager',
      'boosting_duration',
      'boosting_budget',
      'date_post',
      'ageMin',
      'ageMax',
      'country',
      'city',
      'interests',
      'showTargetAudience',
      'gender',
      'hashtags',
      'socialTagging',
      'repostingPlatform',
      'showSocial',
      'showExclusivity',
      'exclusivity',
      'repostingDuration',
      'other_infos',
    ],
    prevProps.formik,
    nextProps.formik
  );
};

const TabBriefDetails = memo(({ formik }: PropsType) => {
  return (
    <>
      <SubTabKeyMessage formik={formik} />
      <SubTabCampaignObjective formik={formik} />
      <div className={styles.line} />
      <SubTabPrefferedAdDeclaration formik={formik} />
      <div className={styles.line} />
      <SubTabBudgetAndDuration formik={formik} />
      <div className={styles.line} />
      <SubTabAudience formik={formik} />
      <div className={styles.line} />
      <SubTabHashtags formik={formik} />
      <div className={styles.line} />
      <SubTabOtherTerms formik={formik} />
    </>
  );
}, propsAreEqual);

export default TabBriefDetails;
