import { Action } from 'redux';
import { IDeliverable } from '../../models/entities/deliverable/Deliverable';
import { IConceptSection } from '../../models/entities/deliverableSections/ConceptSection';
import { IContentSection } from '../../models/entities/deliverableSections/ContentSection';
import { IAsset } from '../../models/entities/asset/Asset';
import { DELIVERABLE_SECTION_TYPES } from '../../models/enums/DeliverableSectionTypes';
import { DeliverableItemPage } from '../../models/permissions/pages/DeliverableItemPage';
import { Page } from '../../models/permissions/pages/Page';
import { DeliverablePermission } from '../../models/permissions/enum/DeliverablePermission';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../store';
import CustomError from '../../models/entities/error/CustomError';

export enum DELIVERABLES_ACTION_TYPES {
  GET_BOARD_DELIVERABLE_ITEM_REQUEST = 'GET_BOARD_DELIVERABLE_ITEM_REQUEST',
  GET_BOARD_DELIVERABLE_ITEM_SUCCESS = 'GET_BOARD_DELIVERABLE_ITEM_SUCCESS',
  GET_BOARD_DELIVERABLE_ITEM_FAILURE = 'GET_BOARD_DELIVERABLE_ITEM_FAILURE',
  UPDATE_DELIVERABLE_ITEM_REQUEST = 'UPDATE_DELIVERABLE_ITEM_REQUEST',
  UPDATE_DELIVERABLE_ITEM_SUCCESS = 'UPDATE_DELIVERABLE_ITEM_SUCCESS',
  UPDATE_DELIVERABLE_ITEM_FAILURE = 'UPDATE_DELIVERABLE_ITEM_FAILURE',
  UPDATE_DELIVERABLE_CONCEPT_REQUEST = 'UPDATE_DELIVERABLE_CONCEPT_REQUEST',
  UPDATE_DELIVERABLE_CONCEPT_SUCCESS = 'UPDATE_DELIVERABLE_CONCEPT_SUCCESS',
  UPDATE_DELIVERABLE_CONCEPT_FAILURE = 'UPDATE_DELIVERABLE_CONCEPT_FAILURE',
  UPDATE_DELIVERABLE_CONTENT_REQUEST = 'UPDATE_DELIVERABLE_CONTENT_REQUEST',
  UPDATE_DELIVERABLE_CONTENT_SUCCESS = 'UPDATE_DELIVERABLE_CONTENT_SUCCESS',
  UPDATE_DELIVERABLE_CONTENT_FAILURE = 'UPDATE_DELIVERABLE_CONTENT_FAILURE',
  APPROVE_DELIVERABLE_REQUEST = 'APPROVE_DELIVERABLE_REQUEST',
  APPROVE_DELIVERABLE_SUCCESS = 'APPROVE_DELIVERABLE_SUCCESS',
  APPROVE_DELIVERABLE_FAILURE = 'APPROVE_DELIVERABLE_FAILURE',
  GET_DELIVERABLE_APPROVALS_REQUEST = 'GET_DELIVERABLE_APPROVALS_REQUEST',
  GET_DELIVERABLE_APPROVALS_SUCCESS = 'GET_DELIVERABLE_APPROVALS_SUCCESS',
  GET_DELIVERABLE_APPROVALS_FAILURE = 'GET_DELIVERABLE_APPROVALS_FAILURE',
  CLEAR_OPENED_DELIVERABLE_ITEM = 'CLEAR_OPENED_DELIVERABLE_ITEM',
  UPDATE_OPENED_DELIVERABLE_ITEM = 'UPDATE_OPENED_DELIVERABLE_ITEM',
  UPDATE_DELIVERABLE_SECTION_ASSETS = 'UPDATE_DELIVERABLE_SECTION_ASSETS',
  SET_DELIVERABLE_PAGE_MODEL = 'SET_DELIVERABLE_PAGE_MODEL',
  CLEAR_ASSETS_TO_UPDATE = 'CLEAR_ASSETS_TO_UPDATE',
  CLEAR_ERRORS = 'CLEAR_ERRORS',
}

export type UpdateDeliverableItemDataType =
  | Pick<IDeliverable, 'content_due_datetime' | 'content_reminder_datetime'>
  | Pick<IDeliverable, 'concept_due_datetime' | 'content_reminder_datetime'>
  | Pick<IDeliverable, 'live_datetime'>
  | Pick<IDeliverable, 'name'>;

export type ClearDeliverablesErrorsActionType = {
  type: DELIVERABLES_ACTION_TYPES.CLEAR_ERRORS;
};

export type GetBoardDeliverableItemRequestActionType = Action<DELIVERABLES_ACTION_TYPES.GET_BOARD_DELIVERABLE_ITEM_REQUEST>;

export type GetBoardDeliverableItemSuccessActionType = Action<DELIVERABLES_ACTION_TYPES.GET_BOARD_DELIVERABLE_ITEM_SUCCESS> & {
  payload: {
    deliverable: IDeliverable;
    concept: IConceptSection | null;
    content: IContentSection | null;
  };
};

export type GetBoardDeliverableItemFailureActionType = Action<DELIVERABLES_ACTION_TYPES.GET_BOARD_DELIVERABLE_ITEM_FAILURE> & {
  payload: string;
};

export type UpdateDeliverableContentRequestActionType = Action<DELIVERABLES_ACTION_TYPES.UPDATE_DELIVERABLE_CONTENT_REQUEST>;
export type UpdateDeliverableContentSuccessActionType = Action<DELIVERABLES_ACTION_TYPES.UPDATE_DELIVERABLE_CONTENT_SUCCESS>;

export type UpdateDeliverableContentFailureActionType = Action<DELIVERABLES_ACTION_TYPES.UPDATE_DELIVERABLE_CONTENT_FAILURE> & {
  loading?: boolean;
  payload?: CustomError;
};

export type UpdateDeliverableConceptRequestActionType = Action<DELIVERABLES_ACTION_TYPES.UPDATE_DELIVERABLE_CONCEPT_REQUEST>;

export type UpdateDeliverableConceptSuccessActionType = Action<DELIVERABLES_ACTION_TYPES.UPDATE_DELIVERABLE_CONCEPT_SUCCESS>;

export type UpdateDeliverableConceptFailureActionType = Action<DELIVERABLES_ACTION_TYPES.UPDATE_DELIVERABLE_CONCEPT_FAILURE> & {
  loading?: boolean;
  payload?: CustomError;
};

export type ApproveDeliverableRequestActionType = Action<DELIVERABLES_ACTION_TYPES.APPROVE_DELIVERABLE_REQUEST>;

export type ApproveDeliverableSuccessActionType = Action<DELIVERABLES_ACTION_TYPES.APPROVE_DELIVERABLE_SUCCESS>;

export type ApproveDeliverableFailureActionType = Action<DELIVERABLES_ACTION_TYPES.APPROVE_DELIVERABLE_FAILURE> & {
  payload: string;
};

export type UpdateDeliverableSectionAssetsActionType = Action<DELIVERABLES_ACTION_TYPES.UPDATE_DELIVERABLE_SECTION_ASSETS> & {
  payload: { asset: IAsset; type: DELIVERABLE_SECTION_TYPES };
};

export type UpdateDeliverableItemRequestActionType = Action<DELIVERABLES_ACTION_TYPES.UPDATE_DELIVERABLE_ITEM_REQUEST>;
export type UpdateDeliverableItemSuccessActionType = Action<DELIVERABLES_ACTION_TYPES.UPDATE_DELIVERABLE_ITEM_SUCCESS> & {
  payload: UpdateDeliverableItemDataType;
};

export type ClearOpenedDeliverableActionType = Action<DELIVERABLES_ACTION_TYPES.CLEAR_OPENED_DELIVERABLE_ITEM>;

export type ClearAssetsToUpdateActionType = Action<DELIVERABLES_ACTION_TYPES.CLEAR_ASSETS_TO_UPDATE>;

export type UpdateOpenedDeliverableActionType = Action<DELIVERABLES_ACTION_TYPES.UPDATE_OPENED_DELIVERABLE_ITEM> & {
  payload: IDeliverable;
};

export type SetDeliverablePageModelActionType = Action<DELIVERABLES_ACTION_TYPES.SET_DELIVERABLE_PAGE_MODEL> & {
  payload: DeliverableItemPage | Page<DeliverablePermission>;
};

export type UpdateDeliverableItemFailureActionType = Action<DELIVERABLES_ACTION_TYPES.UPDATE_DELIVERABLE_ITEM_FAILURE> & {
  payload: string;
};

export type DeliverablesActionsTypes =
  | GetBoardDeliverableItemFailureActionType
  | GetBoardDeliverableItemRequestActionType
  | GetBoardDeliverableItemSuccessActionType
  | UpdateDeliverableConceptFailureActionType
  | UpdateDeliverableConceptSuccessActionType
  | UpdateDeliverableConceptRequestActionType
  | UpdateDeliverableContentRequestActionType
  | UpdateDeliverableContentSuccessActionType
  | UpdateDeliverableContentFailureActionType
  | ApproveDeliverableRequestActionType
  | ApproveDeliverableSuccessActionType
  | ApproveDeliverableFailureActionType
  | UpdateDeliverableSectionAssetsActionType
  | UpdateDeliverableItemRequestActionType
  | UpdateDeliverableItemSuccessActionType
  | UpdateDeliverableItemFailureActionType
  | ClearOpenedDeliverableActionType
  | ClearAssetsToUpdateActionType
  | UpdateOpenedDeliverableActionType
  | SetDeliverablePageModelActionType
  | ClearDeliverablesErrorsActionType;

export type DeliverableThunkReturnType<ReturnType = void> = ThunkAction<Promise<ReturnType>, RootState, null, DeliverablesActionsTypes>;
export type DeliverableSyncThunkReturnType<ReturnType = void> = ThunkAction<ReturnType, RootState, null, DeliverablesActionsTypes>;
