import React, {FC} from 'react';

type PropsType = {
  className?: string;
};

const ImagePlaceholderIconV1: FC<PropsType> = props => {
  return (
    <svg className={props.className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
         fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M3.52553 3.68689C4.53296 2.60562 5.96808 1.99976 7.664 1.99976H16.3364C18.0371 1.99976 19.4735 2.60518 20.4803 3.68725C21.4816 4.7633 22.0019 6.24942 21.9997 7.91836V16.0821C21.9997 17.7507 21.4786 19.2365 20.4769 20.3124C19.4696 21.3944 18.033 21.9998 16.3314 21.9998H7.664C5.9645 21.9998 4.52902 21.3942 3.52222 20.3123C2.52109 19.2365 1.99969 17.7508 1.99969 16.0821V7.91737C1.99969 6.24787 2.52359 4.76226 3.52553 3.68689ZM4.62561 4.71174C3.92605 5.46257 3.50323 6.5599 3.50323 7.91737V16.0821C3.50323 16.247 3.50943 16.4081 3.52159 16.5651L4.22924 15.9585C5.53772 14.837 7.44962 14.7747 8.82832 15.8087C9.72386 16.4804 10.9828 16.3634 11.7392 15.5382L13.6103 13.497C14.9133 12.0755 17.082 11.874 18.6246 13.031L20.4962 14.4346V7.91737C20.498 6.55934 20.0776 5.46152 19.3796 4.71138C18.6872 3.96725 17.6658 3.50321 16.3364 3.50321H7.664C6.34083 3.50321 5.31968 3.9668 4.62561 4.71174ZM4.6229 19.2881C4.33801 18.982 4.09922 18.6181 3.91633 18.2024L5.20543 17.0974C5.98012 16.4334 7.11206 16.3965 7.92832 17.0087C9.44092 18.1432 11.5673 17.9455 12.8449 16.5518L14.716 14.5106C15.4978 13.6577 16.799 13.5368 17.7246 14.231L20.4923 16.3067C20.4484 17.5617 20.0361 18.5794 19.3765 19.288C18.6836 20.0322 17.6618 20.4963 16.3314 20.4963H7.664C6.33639 20.4963 5.3156 20.0324 4.6229 19.2881ZM7.76111 8.78496C7.76111 8.17957 8.25186 7.68916 8.85674 7.68896C9.46179 7.68976 9.95228 8.18022 9.95311 8.78526C9.95295 9.39091 9.4628 9.88096 8.85711 9.88096C8.25206 9.88096 7.76111 9.39049 7.76111 8.78496ZM8.85711 6.18896C7.42416 6.18896 6.26111 7.35049 6.26111 8.78496C6.26111 10.2194 7.42416 11.381 8.85711 11.381C10.2913 11.381 11.4531 10.2192 11.4531 8.78496V8.78423C11.4517 7.35159 10.2905 6.19037 8.85784 6.18896H8.85711Z"
            fill="#4E3B8B"/>
    </svg>
  );
};

export default ImagePlaceholderIconV1;

