import React from 'react';
import AdBlockDetectedModal from '../Modals/AddBlockDetectedModal';
import ToastNotification from '../ToastNotification';
import BuyMoreStorageModal from '../Modals/BuyMoreStorageModal';
import { toggleOpenExtraStorageModal } from '../../actions/payment.action';
import { useAppDispatch, useAppSelector } from '../../shared/hooks/reduxHooks';
import LimitAfterSignInModal from '../Modals/LimitAfterSignInModal';

const AppModals = () => {
  const dispatch = useAppDispatch();
  const isOpenExtraStorageModal = useAppSelector(state => state.payment.isOpenExtraStorageModal);

  return (
    <>
      <AdBlockDetectedModal />
      <ToastNotification />
      <BuyMoreStorageModal open={isOpenExtraStorageModal} onClose={() => dispatch(toggleOpenExtraStorageModal(false))} />
      <LimitAfterSignInModal />
    </>
  );
};

export default AppModals;
