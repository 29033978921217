/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React from 'react';
import styles from './TeamMemberCell.module.scss';
import { useMediaQuery } from 'react-responsive/src';
import avatar from '../../../assets/img/no-avatar.svg';
import TeamMemberTabletCell from './TeamMemberTabletCell';
import { useHistory } from 'react-router-dom';
import { ReactComponent as TrashGraySVG } from '../../../assets/img/icons/trash_gray.svg';
import TeamMemberStatus from '../../statuses/TeamMemberStatus';
import { MEMBER_ROLE_IN_ORGANIZATION_TYPES } from '../../../models/enums/MemberRoleInOrganizationTypes';

const TeamMemberCell = ({ member, org, user, handleRemove, canEditStatus, onChangeStatus }) => {
  const history = useHistory();
  const isHorizontal = useMediaQuery({
    query: '(min-width: 750px)',
  });

  const onRemove = member => {
    handleRemove(member);
  };

  const toMemberPage = () => {
    if (member.status_in_organization.toLowerCase() === 'pending') return;
    history.push(`/team-member/${member.user_id}`);
  };

  if (isHorizontal)
    return (
      <TeamMemberTabletCell
        onChangeStatus={onChangeStatus}
        canEditStatus={canEditStatus}
        member={member}
        org={org}
        user={user}
        handleRemove={handleRemove}
      />
    );
  return (
    <div className={styles.wrapper}>
      <div className={styles.flexRow} onClick={toMemberPage}>
        <div className={styles.avatar}>
          <img src={member.photo_location || avatar} alt="avatar" />
        </div>
        <div className={styles.nameWrapper}>
          {member.first_name ? (
            <div className={styles.name}>
              {member.first_name} {member.last_name}
            </div>
          ) : (
            <div className={styles.name}>{member.email}</div>
          )}
          {member?.role_in_organization || member?.status_in_organization ? (
            <TeamMemberStatus
              onSelect={role => onChangeStatus({ role, user_ids: [member.user_id] })}
              canEditStatus={canEditStatus}
              role_in_organization={member.role_in_organization}
              status={member.status_in_organization}
            />
          ) : null}
        </div>
      </div>
      {org.role?.toLowerCase() === MEMBER_ROLE_IN_ORGANIZATION_TYPES.OWNER && user.id !== member.user_id ? (
        <div className={styles.iconTrash}>
          <TrashGraySVG onClick={() => onRemove(member)} />
        </div>
      ) : (
        <div className={styles.emptyBlock}></div>
      )}
    </div>
  );
};

export default TeamMemberCell;
