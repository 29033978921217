import React, { FC, memo } from 'react';
import styles from './AddDeliverablesPlacementCell.module.scss';
import PlusSVG from '../../../../../../../expert/components/IconsSvg/PlusSVG';
import { useMediaQuery } from 'react-responsive';
import AddDeliverablesInputRow from '../AddDeliverablesInputRow';
import {
  DeliverablesFormSettingsDataType,
  IDeliverableFormData,
  UseDeliverablesFormHandlersType,
} from '../../hooks/useDeliverablesFormData';
import { FormikErrors, FormikTouched } from 'formik';
import cs from 'classnames';

type PropsType = {
  handlers: UseDeliverablesFormHandlersType;
  currentDeliverables: IDeliverableFormData[];
  deliverableErrors: (FormikErrors<IDeliverableFormData> | null)[];
  deliverableTouched: (FormikTouched<IDeliverableFormData> | null)[];
  platformName: keyof DeliverablesFormSettingsDataType;
  placementsName: DeliverablesFormSettingsDataType[keyof DeliverablesFormSettingsDataType][number];
  canEditDueDates: boolean;
  canEditNotifications: boolean;
};

const AddDeliverablesPlacementCell: FC<PropsType> = memo(
  ({
    currentDeliverables,
    handlers,
    placementsName,
    platformName,
    deliverableTouched,
    deliverableErrors,
    canEditDueDates,
    canEditNotifications,
  }) => {
    const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

    const dynamicInputRows = currentDeliverables.map((deliverable, index) => (
      <AddDeliverablesInputRow
        canEditNotifications={canEditNotifications}
        handlers={handlers}
        key={deliverable.id}
        deliverable={deliverable}
        deliverableTouched={deliverableTouched[index]}
        deliverableError={deliverableErrors[index]}
        index={index}
        canEditDueDates={canEditDueDates}
      />
    ));

    return (
      <div className={styles.root}>
        <div className={cs(styles.mainCell, { [styles.onlyName]: !canEditDueDates })}>
          {isTablet ? (
            dynamicInputRows
          ) : (
            <>
              <div></div>
              <h4 className={styles.cellText}>Name</h4>
              {canEditDueDates ? (
                <>
                  <h4 className={styles.cellText}>Concept Due:</h4>
                  <h4 className={styles.cellText}>Content Due:</h4>
                  <h4 className={styles.cellText}>Live Date:</h4>
                </>
              ) : null}
              <div></div>
              {dynamicInputRows}
            </>
          )}
        </div>
        {currentDeliverables.length < 10 && (
          <div className={styles.footer}>
            <div className={styles.addDeliverableBtn} onClick={() => handlers.createDeliverable(platformName, placementsName)}>
              <div className={styles.plusIconWrapper}>
                <PlusSVG fill={undefined} />
              </div>
              One More
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default AddDeliverablesPlacementCell;
