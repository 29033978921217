import React, { FC, useMemo } from 'react';
import { ModalMedium } from '../../../../../../../components/Modals';
import { IBriefAsset } from '../../../../../../../models/entities/asset/BriefAsset';
import styles from './VisualDescriptionModal.module.scss';
import DownloadSVG from '../../../../../../../expert/components/IconsSvg/DownloadSVG';
import cs from 'classnames';
import { useMediaQuery } from 'react-responsive/src';
import EditorComponent from '../../../../../../../components/EditorComponent';
import { formatBytes } from '../../../../../../../shared/utils/helpers';
import moment from 'moment/moment';
import useDownloadAsset from '../../../../../../../shared/hooks/useDownloadAsset';

type PropsType = {
  isOpen: boolean;
  openAsset: IBriefAsset | null;
  onClose: () => void;
};

const ONE_HOUR_IN_SECONDS = 3600;
const ONE_MINUTE_IN_SECONDS = 60;
const ONE_SECOND_IN_MILLISECONDS = 1000;
const dateFormat = 'DD:MM:YYYY HH:mm';

const VisualDescriptionModal: FC<PropsType> = props => {
  const withDescription = !!props.openAsset?.description?.trim().length;
  const isMobile = useMediaQuery({ query: '(max-width: 575px)' });

  const { isAssetLoading, downloadAsset } = useDownloadAsset();

  const onDownload = () => {
    if (isAssetLoading || !props?.openAsset?.url) return;
    downloadAsset(props.openAsset.url, props.openAsset.extension);
  };

  const duration: null | string = useMemo(() => {
    if (!props.openAsset?.duration) return null;

    if (props.openAsset?.duration > ONE_HOUR_IN_SECONDS) {
      return moment.utc(props.openAsset?.duration * ONE_SECOND_IN_MILLISECONDS).format('HH:mm:ss');
    }

    if (props.openAsset?.duration > ONE_MINUTE_IN_SECONDS) {
      return moment.utc(props.openAsset?.duration * ONE_SECOND_IN_MILLISECONDS).format('mm:ss');
    }

    return `${props.openAsset?.duration} sec`;
  }, [props.openAsset?.duration]);

  if (isMobile) {
    return (
      <ModalMedium
        margin={'0'}
        overlayPadding={'0px'}
        onClose={props.onClose}
        open={props.isOpen}
        maxWidth={'100vw'}
        classNames={cs(styles.root, { [styles.withDescription]: withDescription })}
        closeBtnClassName={cs({ [styles.closeBtnWithText]: withDescription, [styles.closeBtnWithoutText]: !withDescription })}
      >
        <div className={cs(styles.header, { [styles.withDescription]: withDescription })}>
          <h5 className={styles.mediaName}>Media Name</h5>
          <div className={styles.mediaCreationDateWrapper}>
            <p className={styles.mediaCreationDateTitle}>Added:</p>
            <p className={styles.mediaCreationDate}>{moment(props.openAsset?.created_at).format(dateFormat)}</p>
          </div>
        </div>

        <div className={styles.mediaContent}>
          <img className={styles.backgroundMedia} src={props.openAsset?.url || ''} alt={props.openAsset?.filename} />
          <div className={styles.mediaWrapper}>
            <img className={styles.media} src={props.openAsset?.url || ''} alt={props.openAsset?.filename} />
          </div>
        </div>

        {withDescription ? (
          <div className={styles.descriptionContent}>
            <h4 className={styles.descriptionTitle}>Description:</h4>
            {/* @ts-ignore */}
            <EditorComponent
              wrapperClassName={styles.descriptionTextWrapper}
              readOnly={true}
              editorClassName={styles.descriptionText}
              initialValue={props.openAsset?.description}
            />
            <p className={styles.editText}>To edit this description please click “Edit” button on the brief overview page</p>
          </div>
        ) : null}

        <div className={cs(styles.footer, { [styles.withDescription]: withDescription })}>
          <div className={styles.footerLeft}>
            <div className={styles.mediaInfo}>
              <p className={styles.mediaInfoText}>{props.openAsset?.extension}</p>
              <p className={styles.mediaInfoText}>{formatBytes(props.openAsset?.size)}</p>
              {duration ? <p className={styles.mediaInfoText}>{duration}</p> : null}
            </div>
          </div>
          <div className={styles.footerRight}>
            <button className={styles.downloadButton} onClick={onDownload}>
              <DownloadSVG />
              Download
            </button>
          </div>
        </div>
      </ModalMedium>
    );
  }

  return (
    <ModalMedium
      onClose={props.onClose}
      open={props.isOpen}
      maxWidth={'80vw'}
      classNames={cs(styles.root, { [styles.withDescription]: withDescription })}
      closeBtnClassName={cs({ [styles.closeBtnWithText]: withDescription, [styles.closeBtnWithoutText]: !withDescription })}
    >
      <div className={styles.mediaContent}>
        <div className={cs(styles.header, { [styles.withDescription]: withDescription })} />
        <img className={styles.backgroundMedia} src={props.openAsset?.url || ''} alt={props.openAsset?.filename} />
        <div className={styles.mediaWrapper}>
          <img className={styles.media} src={props.openAsset?.url || ''} alt={props.openAsset?.filename} />
        </div>
        <div className={cs(styles.footer, { [styles.withDescription]: withDescription })}>
          <div className={styles.footerLeft}>
            <div className={styles.mediaInfo}>
              <h5 className={styles.mediaName}>{props.openAsset?.filename}</h5>
              <p className={styles.mediaInfoText}>{props.openAsset?.extension}</p>
              <p className={styles.mediaInfoText}>{formatBytes(props.openAsset?.size)}</p>
              {duration ? <p className={styles.mediaInfoText}>{duration}</p> : null}
            </div>
            <div className={styles.mediaCreationDateWrapper}>
              <p className={styles.mediaCreationDateTitle}>Added:</p>
              <p className={styles.mediaCreationDate}>{moment(props.openAsset?.created_at).format(dateFormat)}</p>
            </div>
          </div>
          <div className={styles.footerRight}>
            <button className={styles.downloadButton} onClick={onDownload}>
              <DownloadSVG />
              Download
            </button>
          </div>
        </div>
      </div>
      {withDescription ? (
        <div className={styles.descriptionContent}>
          <h4 className={styles.descriptionTitle}>Description:</h4>
          {/* @ts-ignore */}
          <EditorComponent
            wrapperClassName={styles.descriptionTextWrapper}
            readOnly={true}
            editorClassName={styles.descriptionText}
            initialValue={props.openAsset?.description}
          />
          <p className={styles.editText}>To edit this description please click “Edit” button on the brief overview page</p>
        </div>
      ) : null}
    </ModalMedium>
  );
};

export default VisualDescriptionModal;
