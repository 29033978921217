import React, { ChangeEventHandler, DetailedHTMLProps, InputHTMLAttributes, useCallback, useRef } from 'react';

const useHiddenFileInput = (
  handler: ChangeEventHandler<HTMLInputElement>,
  inputProps?: Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'type' | 'onChange' | 'ref' | 'style'>
) => {
  const ref = useRef<HTMLInputElement | null>(null);

  const renderInput = useCallback(
    () => <input onChange={handler} type={'file'} ref={ref} style={{ display: 'none' }} {...inputProps} />,
    [ref, handler]
  );
  const clickFileInput = useCallback(() => {
    if (!ref?.current?.click) return;
    ref.current?.click();
  }, [ref]);

  return {
    renderInput,
    clickFileInput,
  };
};

export default useHiddenFileInput;
