import { formatBytes } from '../../../../shared/utils/helpers';
import { IPricingPlanDTO } from '../dto/PricingPlanDTO';
import { IPricingPlanView } from '../PricingPlanView';
import { ORGANIZATION_TYPES } from '../../../enums/OrganizationTypes';

export class PricingPlanHydrator implements IPricingPlanView {
  id: number;
  name: string;
  monthPrice: number;
  annualPrice: number;
  currency: string;
  description: string;
  abilities: string[];
  btn: string;
  isFree: boolean;
  isCustom: boolean;

  constructor(pricingPlanDTO: IPricingPlanDTO, isPlanExists: boolean) {
    this.id = pricingPlanDTO.id;
    this.name = pricingPlanDTO.name;
    this.monthPrice = Number(pricingPlanDTO.base_price_monthly);
    this.annualPrice = Number(pricingPlanDTO.base_price_yearly);
    this.currency = pricingPlanDTO.currency === 'USD' ? '$' : pricingPlanDTO.currency; // todo: create symbol fabric
    this.description = pricingPlanDTO.is_free
      ? 'Have your team manage influencer content management and approvals like a pro.'
      : 'Supercharge your Influencer content workflows and approvals.';
    this.abilities = this.getAbilities(pricingPlanDTO);
    this.btn = isPlanExists ? 'Upgrade' : 'Select plan';
    this.isFree = pricingPlanDTO.is_free;
    this.isCustom = false;
  }

  private getAbilities(pricingPlanDTO): string[] {
    const res: string[] = [];

    Object.keys(pricingPlanDTO).forEach(key => {
      const value = pricingPlanDTO[key];

      switch (key) {
        case 'team_members':
          if (!pricingPlanDTO.organization_type.includes(ORGANIZATION_TYPES.CONTENT_CREATOR)) {
            res.push(`${value === -1 ? 'Unlimited' : value} Team Member${value === 1 ? '' : 's'}`);
          }
          break;
        case 'storage':
          res.push(`${formatBytes(Number(value))} Storage`);
          break;
        case 'content_board_create_limit':
          if (value) {
            res.push(value === -1 ? 'Unlimited Content Board Creation' : `Create up to ${value} content boards`);
          }
          break;
        case 'content_board_collaborate_limit':
          if (value) {
            res.push(
              value === -1 ? 'Unlimited Board Collaboration' : `You will be able to receive only ${value} content board by invitation`
            );
          }
          break;
        // case 'campaign_brief_create_limit':
        //   if (value) {
        //     res.push(`${value === -1 ? 'Unlimited' : value} Campaign Briefs`);
        //   }
        //   break;
        // case 'campaign_brief_collaborate_limit':
        //   if (value) {
        //     res.push(`${value === -1 ? 'Unlimited' : value} Briefs collaborates`);
        //   }
        //   break;
        case 'chat_boards':
          if (value) {
            res.push('Chat Boards');
          }
          break;
        case 'email_support':
          if (value) {
            res.push('Email Support');
          }
          break;
        case 'knowledge_base_support':
          if (value) {
            res.push('Knowledge Base Support');
          }
          break;
        case 'account_manager':
          if (value) {
            res.push('Account Manager');
          }
      }
    });

    return res;
  }
}
