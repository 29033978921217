import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './CampaignBriefCreateItem.module.scss';
import MainLayout from '../../../components/MainLayout';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { createCampaignBriefItem, getBriefItem } from '../../../actions/brief.actions';
import { briefPageType } from '../briefPageType';
import { addFormikValuesFromBrief, briefItemFromFormikToAPI, formikBriefItemInitialValues } from '../formikBriefItemUtils';
import * as Yup from 'yup';
import BriefTab from '../components/BriefTab';
import TabCollaborators from '../components/tabs/TabCollaborators';
import SuitcaseSVG from '../../../components/IconsSvg/SuitcaseSVG';
import TabBriefDetails from '../components/tabs/TabBriefDetails';
import DocumentSVG from '../../../expert/components/IconsSvg/DocumentSVG';
import BriefFailureModal from '../components/BriefFailureModal';
import BriefPageContentWrapper from '../components/BriefPageContentWrapper';
import { TYPES_CAMPAIGNS, TYPES_CAMPAIGNS_TEXTS } from '../../../shared/constants/constants';
import useDeliverablesFormik from '../../../components/DeliverablesPlatforms/hooks/useDeliverablesFormik';
import { emailValidation } from '../../CreateTest/utils';
import BriefSubHeader from '../../ContentApproval/createPages/CreateBoardWrapper/CreateBoardBrief/components/BriefSubHeader';

function CreateCampaignItem() {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();

  const [isAutofill, setIsAutofill] = useState(false);
  const currentOrganizationID = useSelector(state => state.auth.currentOrganization?.organization_id);
  const currentBriefItem = useSelector(state => state.brief.currentBrief.briefItem);
  const pageType = match.params.briefItemID ? briefPageType.CREATOR_BRIEF_ITEM : briefPageType.CAMPAIGN_BRIEF_ITEM;

  const formik = useFormik({
    initialValues: {
      ...formikBriefItemInitialValues(),
      initiator_organization_type: { type: TYPES_CAMPAIGNS.BRAND, name: TYPES_CAMPAIGNS_TEXTS[TYPES_CAMPAIGNS.BRAND] },
      inviteEmail: '',
      inviteType: null,
      inviteTypeValidation: null,
    },
    validationSchema: Yup.object().shape({
      inviteEmail: Yup.string()
        .test('validate-email', emailValidation)
        .when('inviteTypeValidation', {
          is: value => value,
          then: Yup.string().required('Email is required'),
        }),
      itemName: Yup.string().trim().required('Name is required'),
      initiator_organization_type: Yup.object().required('Initiator organization type is required'),
      date_end: Yup.date()
        .nullable(true)
        .when('date_start', {
          is: value => value,
          then: Yup.date().nullable(true).required('End date is required'),
        }),
      suggestedCreators: Yup.string().when('showCreators', {
        is: value => value,
        then: Yup.string().trim().required('Suggested Creators is required'),
      }),
      boosting_budget: Yup.number().when('showBoosting', {
        is: value => value,
        then: Yup.number().min(1, 'Boosting budget is required').required('Boosting budget is required'),
      }),
      boosting_duration: Yup.number().when('showBoosting', {
        is: value => value,
        then: Yup.number().min(1, 'Boosting duration is required').required('Boosting duration is required'),
      }),
      exclusivity: Yup.string().when('showExclusivity', {
        is: value => value,
        then: Yup.string().trim().required('Exclusivity is required'),
      }),
      repostingPlatform: Yup.array().when('showSocial', {
        is: value => value,
        then: Yup.array().min(1, 'Reposting Platform is required').required('Reposting Platform is required'),
      }),
    }),
    onSubmit: () => {
      submitHandler();
    },
  });

  const deliverablesFormik = useDeliverablesFormik({});

  useEffect(() => {
    if (currentOrganizationID && pageType == briefPageType.CREATOR_BRIEF_ITEM) {
      dispatch(getBriefItem(currentOrganizationID, match.params.campaignID, match.params.briefItemID, () => history.push('/campaign/')));
    }
  }, [currentOrganizationID]);

  useEffect(() => {
    if (currentBriefItem && pageType == briefPageType.CREATOR_BRIEF_ITEM) {
      addFormikValuesFromBrief(formik, deliverablesFormik, currentBriefItem).then(() => {
        setIsAutofill(true);
      });
      formik.setFieldValue('name', '');
    }
  }, [currentBriefItem]);

  const submitHandler = () => {
    if (currentOrganizationID) {
      const createBriefData = {
        ...briefItemFromFormikToAPI(formik, deliverablesFormik),
        parent_id: match.params.briefItemID || 0,
        initiator_organization_type: formik.values.initiator_organization_type.type,
      };

      if (pageType === briefPageType.CAMPAIGN_BRIEF_ITEM) {
        if (formik.values.suggestedCreators) {
          createBriefData['suggested_creators'] = formik.values.suggestedCreators;
        }
      }

      dispatch(
        createCampaignBriefItem(
          currentOrganizationID,
          match.params.campaignID,
          createBriefData,
          {
            inviteEmail: formik.values.inviteEmail,
            inviteType: formik.values.inviteType,
          },
          id => {
            if (match.params.briefItemID) {
              history.push(`/campaign/${match.params.campaignID}/campaign-brief/${match.params.briefItemID}/creator-brief/${id}`);
            } else {
              history.push(`/campaign/${match.params.campaignID}/campaign-brief/${id}`);
            }
          }
        )
      );
    }
  };

  const tabsData = [
    {
      title: `Who do you want to collaborate with on the ${pageType == briefPageType.CAMPAIGN_BRIEF_ITEM ? 'Campaign' : 'Creator'} Brief?`,
      content: <TabCollaborators formik={formik} pageType={pageType} />,
      icon: <SuitcaseSVG />,
    },
    {
      title: `${pageType == briefPageType.CAMPAIGN_BRIEF_ITEM ? 'Campaign' : 'Creator'} brief details`,
      content: (
        <TabBriefDetails
          formik={formik}
          deliverablesFormik={deliverablesFormik}
          disableSuggestedCreators={pageType == briefPageType.CREATOR_BRIEF_ITEM}
        />
      ),
      icon: <DocumentSVG />,
      display: true,
    },
  ];

  const handleBack = () => {
    if (match.params.briefItemID) {
      history.push(`/campaign/${match.params.campaignID}/campaign-brief/${match.params.briefItemID}`);
    } else {
      history.push(`/campaign/${match.params.campaignID}`);
    }
  };

  const errors = useMemo(() => {
    return {
      ...formik.errors,
      ...deliverablesFormik.errors,
    };
  }, [formik.errors, deliverablesFormik.errors]);

  return (
    <form onSubmit={formik.handleSubmit} onKeyDown={e => e.keyCode === 13 && e.preventDefault()}>
      <BriefFailureModal formik={formik} />
      <MainLayout title={pageType == briefPageType.CAMPAIGN_BRIEF_ITEM ? 'Campaign Brief' : 'Creator brief'} contentClassName={styles.root}>
        <BriefSubHeader
          errors={errors}
          buttonTitle={'Create Brief'}
          title={pageType == briefPageType.CAMPAIGN_BRIEF_ITEM ? 'Create a brief' : 'Build a Creator Brief'}
          description={
            pageType == briefPageType.CAMPAIGN_BRIEF_ITEM
              ? 'Fill out the template below and share with brands or agencies to collaborate and discuss on your' +
                ' campaign requirements. You can share a blank brief if required.'
              : 'Fill out or adjust the template below and share with a creator'
          }
          onCancel={handleBack}
          canSubmit={currentOrganizationID && formik.dirty && formik.isValid && deliverablesFormik.isValid && !formik.isSubmitting}
        />
        <BriefPageContentWrapper>
          {!(pageType == briefPageType.CREATOR_BRIEF_ITEM && !isAutofill) && // if CREATOR_BRIEF_ITEM need autofill
            tabsData.map(item => (
              <div key={item.title}>
                <BriefTab data={item} />
              </div>
            ))}
        </BriefPageContentWrapper>
      </MainLayout>
    </form>
  );
}

export default CreateCampaignItem;
