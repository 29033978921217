import React, { forwardRef, useRef } from 'react';
import styles from './ErrorsInformationTooltip.module.scss';
import cs from 'classnames';
import { useClickOutside } from '../../../../../../../shared/hooks/useClickOutside';

type PropsType = {
  errors: (string | null | undefined)[];
  message:string;
  className: string;
  isOpen: boolean;
  onClose: () => void;
};

const ErrorsInformationTooltip = forwardRef<Element, PropsType>((props, ignoreRef) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useClickOutside(containerRef, ignoreRef, props.onClose);

  if (!props.isOpen) return null;
  return (
    <div ref={containerRef} className={cs(styles.root, props.className)}>
      <h5 className={styles.title}>{props.message}:</h5>
      <ul className={styles.errorsList}>
        {props.errors.map((error, index) => {
          if (!(typeof error === 'string')) return null;
          return (
            <li key={index} className={styles.errorItem}>
              <div className={styles.dotContainer}>
                <div className={styles.dot} />
              </div>
              <p className={styles.itemText}>{error}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
});

export default ErrorsInformationTooltip;
