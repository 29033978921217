import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { Guest } from '../../../models/permissions/users/Guest';

export default ({ children, ...rest }) => {
  const isAuthenticated = useSelector(state => !!state.auth.token);
  const userModel = useSelector(state => state.auth.userModel);

  if (isAuthenticated || (userModel instanceof Guest && userModel.isAllowRoute(rest.path))) {
    return <Route {...rest} render={() => children} />;
  }

  return (
    <Route
      {...rest}
      render={({ location }) => (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      )}
    />
  );
};
