import { useContext, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useSubscribeChannel from '../../../../centrifuge/useSubscribeChannel';
import { updateOpenedDeliverable, updateVersionAssets } from '../../../../actions/deliverablesActions/deliverables.actions';
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/reduxHooks';
import { DeliverablesReducerState } from '../../../../reducers/deliverables.reducer';
import { DELIVERABLE_SECTION_TYPES } from '../../../../models/enums/DeliverableSectionTypes';
import { OrganizationSocketsContext } from '../../../../contexts/organizationContext';
import { IDeliverable } from '../../../../models/entities/deliverable/Deliverable';

enum SUBSCRIBE_ACTIONS {
  CONTENT_BOARD_DELIVERABLE_REFRESH = 'content_board_deliverable_refresh',
}

type DeliverableRefreshSocketActionType = { data: { action: SUBSCRIBE_ACTIONS.CONTENT_BOARD_DELIVERABLE_REFRESH; data: IDeliverable } };

type DeliverableSocketsActionsTypes = DeliverableRefreshSocketActionType;

const useDeliverableSockets = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const currentOrganizationID = useAppSelector<any>(state => state.auth.currentOrganization?.organization_id);
  const openedDeliverable = useAppSelector<DeliverablesReducerState['openedDeliverable']>(state => state.deliverables.openedDeliverable);
  const JWT = useAppSelector<any>(state => state.auth?.token?.access_token);
  const context = useContext(OrganizationSocketsContext);
  const deliverableID: number = useMemo(() => +params.deliverableId, [params.deliverableId]);
  const boardID: number = useMemo(() => +params.boardId, [params.boardId]);

  const openedDeliverableRef = useRef(openedDeliverable);

  useEffect(() => {
    openedDeliverableRef.current = openedDeliverable;
  }, [openedDeliverable]);

  const currentDeliverableConvertedHandler = async (updatedVersions: { id: number; asset_id: number }[]) => {
    const currentVersionsArray: { id: number; type: DELIVERABLE_SECTION_TYPES }[] = [];
    if (openedDeliverableRef.current?.concept?.id)
      currentVersionsArray.push({ id: openedDeliverableRef.current.concept.id, type: DELIVERABLE_SECTION_TYPES.CONCEPT });
    if (openedDeliverableRef.current?.content?.id)
      currentVersionsArray.push({ id: openedDeliverableRef.current.content.id, type: DELIVERABLE_SECTION_TYPES.CONTENT });
    if (!currentVersionsArray.length) return;

    for (const currentVersion of currentVersionsArray) {
      const updatedVersion = updatedVersions.find(uV => uV.id === currentVersion.id);
      if (updatedVersion) {
        dispatch(
          updateVersionAssets(
            currentOrganizationID,
            boardID,
            deliverableID,
            currentVersion.id,
            currentVersion.type,
            updatedVersion?.asset_id || null
          )
        );
        break;
      }
    }
  };

  useEffect(() => {
    if (context) {
      (context.deliverableConvertedHandler as any) = ctx => {
        const data: {
          content_board_deliverable_versions: Array<{ id: number; asset_id: number }>;
          content_board_deliverable_id: number;
          content_board_deliverable_name: string;
        } = ctx.data.data;
        const updatedDeliverableId = data.content_board_deliverable_id;
        if (updatedDeliverableId === deliverableID) {
          currentDeliverableConvertedHandler(data.content_board_deliverable_versions);
        }
      };
    }
  }, [deliverableID, currentOrganizationID]);

  useSubscribeChannel(deliverableID, {
    channel: `content_board_deliverable:${deliverableID}`,
    events: (ctx: DeliverableSocketsActionsTypes) => {
      switch (ctx.data.action) {
        case SUBSCRIBE_ACTIONS.CONTENT_BOARD_DELIVERABLE_REFRESH: {
          dispatch(updateOpenedDeliverable(ctx.data.data));
          break;
        }
      }
    },
    opts: {
      data: { organization_id: currentOrganizationID, token: JWT, content_board_id: boardID, content_board_deliverable_id: deliverableID },
    },
    // onError: err => console.error('Centrifuge subscribe error: ', err),
    // onSubscribe: ctx => console.log('+ subscribe deliverable', ctx.channel),
    // onUnsubscribe: ctx => console.log('- unsubscribe deliverable', ctx.channel),
  });
};

export default useDeliverableSockets;
