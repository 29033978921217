import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MainLayout from '../../../components/MainLayout';
import styles from './ContentApprovalList.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearContentBoardsList,
  deleteContentBoard,
  getContentBoards,
} from '../../../actions/ContentApprovalActions/contentApproval.actions';
import ContentList from './components/ContentList';
import useContentApprovalSockets from './useContentApprovalListSockets';
import { getOrganizationUsers } from '../../../actions/user.actions';
import useFilters from '../../CampaignBrief/CampaignBriefList/useFilters';
import NoSearchResults from '../../../components/BriefsAndBoardsFlows/RootListPagesComponents/NoSearchResults';
import HeaderComponent from '../../../components/BriefsAndBoardsFlows/RootListPagesComponents/HeaderComponent';
import FiltersMobile from '../../../components/BriefsAndBoardsFlows/RootListPagesComponents/FiltersMobile';
import { useMediaQuery } from 'react-responsive/src';
import IconMobileSearch from '../../../components/IconMobileSearch';
import { BOARD_STATUTES_TYPES } from '../../../models/entities/board/enums/boardStatuses';
import boardStatusesTextConfig from '../Constants/boardStatusesConfig';
import LayoutHeader from '../components/LayoutHeader';
import { useHistory } from 'react-router';
import ContentApprovalEmptyPage from './EmptyState/ContentApprovalEmptyPage';
import ErrorLimitModal from '../../../components/Modals/ErrorLimitModal';
import ERROR_TYPE from '../../../models/entities/error/ErrorTypes';

const headerTextsConfig = {
  title: 'Content Approval',
  searchPlaceholder: 'Search Boards or Related Brief',
  statusPlaceholder: 'Status',
  memberPlaceholder: 'Team members',
  createButtonText: 'New Board',
  createButtonRoute: '/content-approval/create',
};

const statusOptions = [
  {
    key: BOARD_STATUTES_TYPES.BRIEFING,
    name: boardStatusesTextConfig[BOARD_STATUTES_TYPES.BRIEFING].status,
  },
  {
    key: BOARD_STATUTES_TYPES.PENDING_CONTRACT,
    name: boardStatusesTextConfig[BOARD_STATUTES_TYPES.PENDING_CONTRACT].status,
  },
  {
    key: BOARD_STATUTES_TYPES.CONTENT_CREATION,
    name: boardStatusesTextConfig[BOARD_STATUTES_TYPES.CONTENT_CREATION].status,
  },
  {
    key: BOARD_STATUTES_TYPES.UNDER_REVIEW,
    name: boardStatusesTextConfig[BOARD_STATUTES_TYPES.UNDER_REVIEW].status,
  },
  {
    key: BOARD_STATUTES_TYPES.COMPLETED,
    name: boardStatusesTextConfig[BOARD_STATUTES_TYPES.COMPLETED].status,
  },
];

const ContentApprovalList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentOrganizationID = useSelector(state => state.auth.currentOrganization?.organization_id);
  const currentOrg = useSelector(state => state.auth.currentOrganization);
  const currentPlan = useSelector(state => state.auth.currentOrganizationPlan);
  const { contentApprovalTabSwitcher, boards, boardsLoading, isAllBoardTypesEmpty } = useSelector(state => state.contentApproval);
  const pageSize = 10;
  const [showFiltersMobile, setShowFiltersMobile] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const showMobileSideBar = useSelector(state => state.sidebarMenu.showMobileSideBar);
  const [showPlanWarningModal, setShowPlanWarningModal] = useState(false);

  const { filter, setPagePagination, onSort, getParams, ...filterFunctions } = useFilters(
    pageSize,
    statusOptions,
    contentApprovalTabSwitcher,
    'OTTERFISH_FILTERS_APPROVALS'
  );

  const isNoSearchFiltersYet =
    !filter.searchValue && filter.statusSelectIndex === -1 && filter.memberSelectIndex === -1 && !filter.dateStart && !filter.dateEnd;

  const isNoBoardsYet = !boards.length && !boardsLoading && isNoSearchFiltersYet;
  const isDisplayEmptyResults = !boards.length && !boardsLoading && !isNoBoardsYet;

  useContentApprovalSockets();

  const getContentBoardsHandler = () => dispatch(getContentBoards(currentOrganizationID, getParams()));
  const deleteContentBoardHandler = boardId => dispatch(deleteContentBoard(currentOrganizationID, boardId, getParams()));

  useEffect(() => {
    if (currentOrganizationID) {
      getContentBoardsHandler();
      dispatch(getOrganizationUsers(currentOrganizationID));
    }
    return () => {
      dispatch(clearContentBoardsList());
    };
  }, [currentOrganizationID]);

  useEffect(() => {
    if (!currentOrganizationID) return;
    getContentBoardsHandler();
  }, [filter]);

  const showCountFilters = () => {
    const countValues = { ...filter };
    delete countValues.page;
    delete countValues.sort;
    delete countValues.isArchived;

    let count = 0;

    for (const key in countValues) {
      switch (key) {
        case 'searchValue':
          if (countValues[key]) count++;
          break;
        case 'dateStart':
          if (countValues[key] !== null) count++;
          break;
        case 'memberSelectIndex':
          if (countValues[key] !== -1) count++;
          break;
        case 'statusSelectIndex':
          if (countValues[key] !== -1) count++;
          break;
        default: {
          // nothing
        }
      }
    }
    return count;
  };

  const countFilters = useMemo(() => showCountFilters(), [filter]);

  const onCreateBoardClick = useCallback(
    route => {
      if (
        currentPlan?.content_board_create_limit > currentPlan?.usage?.content_board_created ||
        currentPlan?.content_board_create_limit === -1
      ) {
        history.push(route);
        return;
      }
      setShowPlanWarningModal(true);
    },
    [currentPlan?.content_board_create_limit, currentPlan?.usage?.content_board_created, history]
  );

  if (isAllBoardTypesEmpty) {
    return (
      <MainLayout title={<LayoutHeader />} contentClassName={styles.layout}>
        <ContentApprovalEmptyPage
          organizationType={currentOrg?.organization_type}
          createBoardRoute="/content-approval/create"
          onCreateBoardClick={onCreateBoardClick}
        />
        <ErrorLimitModal
          isOpen={showPlanWarningModal}
          manualType={ERROR_TYPE.CONTENT_BOARD_CREATE_LIMIT_ERROR}
          onClose={() => setShowPlanWarningModal(false)}
        />
      </MainLayout>
    );
  }

  return (
    <>
      <MainLayout contentClassName={styles.layout} title={<LayoutHeader title="Content approval" />}>
        <HeaderComponent
          textsConfig={headerTextsConfig}
          onCreateClick={onCreateBoardClick}
          filterFunctions={filterFunctions}
          statusOptions={statusOptions}
          filter={filter}
          switcher={contentApprovalTabSwitcher}
          showFilters={showFiltersMobile}
        />

        {isDisplayEmptyResults ? (
          <NoSearchResults buttonText="Create Board" route="/content-approval/create" onButtonClick={onCreateBoardClick} />
        ) : (
          <ContentList
            onCreateBoardClick={onCreateBoardClick}
            page={filter.page}
            setPage={setPagePagination}
            sort={filter.sort}
            setSort={onSort}
            pageSize={pageSize}
            onArchive={getContentBoardsHandler}
            isNoSearchFiltersYet={isNoSearchFiltersYet}
            onDelete={deleteContentBoardHandler}
            createBoardRoute="/content-approval/create"
            setShowPlanWarningModal={setShowPlanWarningModal}
          />
        )}
        {/* {isNoBoardsYet && <ContentApprovalWelcomePage onCreateClick={onCreateBoardClick} />}*/}
      </MainLayout>
      {!isNoBoardsYet && !showMobileSideBar && (
        <IconMobileSearch count={countFilters} setShowFiltersMobile={() => setShowFiltersMobile(!showFiltersMobile)} />
      )}
      {isMobile && (
        <FiltersMobile
          onClose={() => setShowFiltersMobile(false)}
          textsConfig={headerTextsConfig}
          filterFunctions={filterFunctions}
          statusOptions={statusOptions}
          filter={filter}
          switcher={contentApprovalTabSwitcher}
          showFilters={showFiltersMobile}
        />
      )}
      <ErrorLimitModal
        isOpen={showPlanWarningModal}
        manualType={ERROR_TYPE.CONTENT_BOARD_CREATE_LIMIT_ERROR}
        onClose={() => setShowPlanWarningModal(false)}
      />
    </>
  );
};

export default ContentApprovalList;
