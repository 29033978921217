import React, { useEffect, useMemo, useState } from 'react';
import CreateBoardBrief from '../CreateBoardWrapper/CreateBoardBrief';
import useBoardBriefFormik from '../CreateBoardWrapper/CreateBoardBrief/useBoardBriefFormik';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector, useThunkDispatch } from '../../../../shared/hooks/reduxHooks';
import {
  clearBoardBriefErrors,
  getBoardBrief,
  getBoardBriefAsset,
  updateBoardBrief,
} from '../../../../actions/BoardBriefActions/boardBriefActions';
import LoaderModal from '../../../../expert/components/Modal/LoaderModal';
import { BoardBriefFormikValuesHydrator } from '../../../../models/entities/boardBrief/hydrators/BoardBriefFormikValuesHydrator';
import useQuery from '../../../../shared/hooks/useQuery';
import useBriefVisualAssets from '../CreateBoardWrapper/CreateBoardBrief/useBriefVisualAssets';
import UpdateBoardBriefHydrator from '../../../../models/entities/boardBrief/hydrators/UpdateBoardBriefHydrator';
import ErrorModal from '../../../../expert/components/Modal/ErrorModal';
import useBoardCreator from '../../hooks/useBoardCreator';
import { ContentApprovalBreadcrumbsPageType } from '../../../../models/entities/breadcrumbs/enums/ContentApprovalBreadcrumbsPage';
import CustomError from '../../../../models/entities/error/CustomError';
import TypedServerError from '../../../../models/entities/error/TypedServerError';
import StorageLimitModal from '../../../../components/Modals/ErrorLimitModal';

const EditBoardBrief = () => {
  const query = useQuery();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const thunkDispatch = useThunkDispatch();
  const { boardId } = useParams<{ boardId: string }>();
  const currentOrganizationID = useAppSelector<any>(state => state.auth.currentOrganization?.organization_id);
  const { boardBrief, boardBriefAssets, boardBriefError, boardBriefUpdateLoading, boardBriefUpdateError } = useAppSelector<any>(
    state => state.boardBrief
  );
  const [storageLimitError, setStorageLimitError] = useState<TypedServerError | null>(null);
  const isBoardCreator = useBoardCreator();

  const boardBriefFormik = useBoardBriefFormik();
  const { briefFormAssets } = useBriefVisualAssets(boardBriefAssets);
  const [initialized, setInitialized] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const briefIds = useMemo(() => {
    return {
      campaignId: query.get('cb'),
      briefId: query.get('cbi'),
    };
  }, [query]);

  useEffect(
    function handleEditAbility() {
      if (isBoardCreator === false) {
        history.push('/content-approval');
      }
    },
    [isBoardCreator]
  );

  useEffect(() => {
    if (!briefIds.campaignId || !briefIds.briefId) {
      history.push('/content-approval');
    }
    if (currentOrganizationID && boardId && briefIds.campaignId && briefIds.briefId) {
      dispatch(getBoardBrief(currentOrganizationID, boardId, briefIds.campaignId, briefIds.briefId));
      dispatch(getBoardBriefAsset(currentOrganizationID, boardId, briefIds.campaignId));
    }
  }, [briefIds, currentOrganizationID, boardId]);

  useEffect(() => {
    if (boardBrief && !initialized) {
      setInitialized(true);
      boardBriefFormik.setValues(new BoardBriefFormikValuesHydrator(boardBrief || {}));
    }
  }, [boardBrief]);

  useEffect(() => {
    if (boardBriefError) {
      dispatch(clearBoardBriefErrors());
      history.push('/content-approval');
    }
  }, [boardBriefError]);

  const briefFormSubmit = () => {
    if (!briefIds.campaignId || !briefIds.briefId || !boardId || !currentOrganizationID) return;

    const briefs = new UpdateBoardBriefHydrator(boardBriefFormik.values);

    const updateBriefData = {
      briefData: briefs.brief,
      briefItemData: briefs.briefItem,
      visualElements: briefFormAssets.getUpdateSubmitState(),
    };

    thunkDispatch(updateBoardBrief(currentOrganizationID, boardId, briefIds.campaignId, briefIds.briefId, updateBriefData))
      .then(() => {
        history.push(`/content-board/${boardId}/brief`);
      })
      .catch((error: CustomError) => {
        if (error instanceof TypedServerError) {
          setStorageLimitError(error);
        } else {
          setSubmitError(error.getMessage());
        }
      });
  };

  const briefFormCancel = () => {
    if (history.length) {
      history.goBack();
    } else {
      history.push(`/content-board/${boardId}/brief`);
    }
  };

  return (
    <>
      {/* @ts-ignore */}
      <CreateBoardBrief
        title="Add Otterfish brief to your Content Board"
        description="Build a brief for the Campaign, then invite collaborators to finalize the requirements needed"
        buttonTitle="Save brief"
        isLoading={!initialized}
        formik={boardBriefFormik}
        visualAssets={briefFormAssets}
        isSubmitDisabled={boardBriefUpdateLoading}
        onCancel={briefFormCancel}
        onSubmit={briefFormSubmit}
        breadCrumbsPageKey={ContentApprovalBreadcrumbsPageType.BRIEF_EDIT}
      />
      <LoaderModal open={boardBriefUpdateLoading} />
      <StorageLimitModal isOpen={Boolean(storageLimitError)} error={storageLimitError} onClose={() => setStorageLimitError(null)} />
      <ErrorModal
        open={!!submitError}
        onClose={() => {
          setSubmitError(null);
          dispatch(clearBoardBriefErrors());
        }}
        text={submitError}
      />
    </>
  );
};

export default EditBoardBrief;
