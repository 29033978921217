import React, { FC, PropsWithChildren } from 'react';
import LoadingContainerWrapper from '../../../../../../../../../components/LoadingContainerWrapper';
import LoadingContainer from '../../../../../../../../../components/LoadingContainer';

type PropsType = {
  isLoading: boolean;
} & PropsWithChildren;

const ListLoadingWrapper: FC<PropsType> = props => {
  return (
    <>
      {props.isLoading
        ? [
            ...Array(3)
              .fill('')
              .map((_, idx) => (
                <LoadingContainerWrapper key={idx} columnGap="15px" alignItems="flex-start">
                  <LoadingContainer height="100px" width="100px" borderRadius="5px" minWidth="100px" />

                  <LoadingContainerWrapper height="100%" rowGap="8px" flexDirection="column" alignItems="flex-start">
                    <LoadingContainer height="12px" borderRadius="3px" />
                    <LoadingContainer height="12px" borderRadius="3px" />
                    <LoadingContainer height="12px" width="45%" borderRadius="3px" />
                  </LoadingContainerWrapper>
                </LoadingContainerWrapper>
              )),
          ]
        : props.children}
    </>
  );
};

export default ListLoadingWrapper;
