import React from 'react';
import ContentApprovalCell from '../../../../../components/Cells/ContentApprovalCell';
import LoadingState from '../../LoadingState';

const BoardsList = ({ boards, boardsLoading, sort, setSort, isTablet, onArchive, onDeleteActionClick, setShowPlanWarningModal }) => {
  return (
    <>
      {!isTablet && <ContentApprovalCell tableHeader sort={sort} setSort={setSort} />}
      {boardsLoading ? (
        <>
          {[...Array(10).keys()].map(index => (
            <LoadingState index={index} key={index} isTablet={isTablet} />
          ))}
        </>
      ) : (
        <>
          {boards.map(item => (
            <ContentApprovalCell
              key={item.id}
              board={item}
              isTablet={isTablet}
              onArchive={onArchive}
              onDeleteActionClick={onDeleteActionClick}
              setShowPlanWarningModal={setShowPlanWarningModal}
            />
          ))}
        </>
      )}
    </>
  );
};

export default BoardsList;
