import React, { FC, useCallback, useState } from 'react';
import styles from './InnerContent.module.scss';
import cs from 'classnames';
import EditorStyledField from '../../../../../../../../components/EditorStyledField';
import SectionDescriptionLoadingWrapper from '../../../components/SectionDescriptionLoadingWrapper';
import SectionFooter from '../../../components/SectionFooter';
import SelectCallToAction from '../SelectCallToAction';
import SectionVisualsWrapper from '../../../components/SectionVisualsWrapper';
import usePlatformPlacementSettings from '../../../../../hooks/usePlatformPlacementSettings';
import SectionSubTitle from '../SectionSubTitle';
import VisualsDraggableListWrapper from '../../../../visuals/VisualsDraggableListWrapper';
import VisualCard from '../../../../visuals/VisualCard';
import { DeliverablePermission } from '../../../../../../../../models/permissions/enum/DeliverablePermission';
import { ConceptFlowType, ContentFlowType } from '../../../../../hooks/useDeliverableState';
import { AssetStateType } from '../../../../../../../../models/entities/asset/AssetState';
import { IAssetMedia } from '../../../../../../../../models/entities/asset/AssetMedia';
import { useAppDispatch, useAppSelector } from '../../../../../../../../shared/hooks/reduxHooks';
import { DeliverablesReducerState } from '../../../../../../../../reducers/deliverables.reducer';
import { DELIVERABLE_STATUSES } from '../../../../../../../../models/enums/DeliverableStatuses';
import useGenerateContentWithAI from '../../useGenerateContentWithAI';
import ButtonLightPurpleWhite from '../../../../../../../../components/Buttons/ButtonLightPurpleWhite';
import useTooltip, { TOOLTIP_POSITIONS } from '../../../../../../../../shared/hooks/fixedTooltip/useTooltip';
import { createNotification } from '../../../../../../../../actions/toastNotificationActions/toastNotification.actions';
import notificationDataConfig from '../../../../../../../../shared/utils/notificationDataConfig';
import { TOAST_NOTIFICATION_TYPES } from '../../../../../../../../shared/constants/toastNotificationsData';

type PropsType = {
  isEditingMode: boolean;
  contentFlow: ContentFlowType;
  conceptFlow: ConceptFlowType;
  openAsset: (asset: AssetStateType | IAssetMedia) => void;
  setIsEditingMode: (value: boolean) => void;
  setIsOpenConfirmCancel: (value: boolean) => void;
  onEdit: () => void;
  onRemove: (() => void) | null;
};

const InnerContent: FC<PropsType> = ({
  isEditingMode,
  contentFlow,
  conceptFlow,
  openAsset,
  setIsEditingMode,
  setIsOpenConfirmCancel,
  onEdit,
  onRemove,
}) => {
  const dispatch = useAppDispatch();
  const isPageLoading = useAppSelector<DeliverablesReducerState['deliverableLoading']>(state => state.deliverables.deliverableLoading);
  const openedDeliverable = useAppSelector<DeliverablesReducerState['openedDeliverable']>(state => state.deliverables.openedDeliverable);
  const isPageUpdateLoading = useAppSelector<DeliverablesReducerState['deliverableContentUpdateLoading']>(
    state => state.deliverables.deliverableContentUpdateLoading
  );
  const pageModel = useAppSelector<DeliverablesReducerState['pageModel']>(state => state.deliverables.pageModel);

  const [isRerender, setIsRerender] = useState<boolean>(false);
  const [isFirstGenerate, setIsFirstGenerate] = useState(true);
  const { generate, isGenerateLoading, isGenerateAvailable } = useGenerateContentWithAI(isEditingMode, conceptFlow);

  const generateContentWithAI = useCallback(async () => {
    const loadingStyles = "font-size: 13px; font-style: italic; font-family: 'OpenSans', sans-serif;";

    await contentFlow.content.onChangeContent({
      description:
        contentFlow.content.description +
        `${
          isFirstGenerate ? '' : '<p></p>'
        }<p style="${loadingStyles}">Your caption idea is being generated, this might take up to one minute to complete.</p><p style="${loadingStyles}">Loading...</p>`,
    });
    setIsRerender(true);
    setIsRerender(false);

    await generate(result => {
      const value =
        contentFlow.content.description + `${isFirstGenerate ? '' : '<p></p>'}<p><strong>New Caption Idea:</strong></p><p>${result}</p>`;
      contentFlow.content.onChangeContent({ description: value });
      setIsRerender(true);
      setIsRerender(false);
      if (isFirstGenerate) {
        setIsFirstGenerate(false);
      }
    }).catch(err => {
      dispatch(
        createNotification(
          notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.DELIVERABLE_GENERATE_AI_ERROR, { text: [err.getMessage()] })
        )
      );
      contentFlow.content.onChangeContent({ description: contentFlow.content.description });
      setIsRerender(true);
      setIsRerender(false);
    });
  }, [generate, contentFlow, isFirstGenerate]);

  const { settings } = usePlatformPlacementSettings();
  const {
    toolTip: generateTooltip,
    onLeave: onGenerateLeave,
    onEnter: onGenerateEnter,
  } = useTooltip(TOOLTIP_POSITIONS.BOTTOM, {
    text: 'Upload a brief or add a concept to generate caption ideas with Otterfish AI',
    className: styles.generateTooltip,
  });
  const maxVisualsCount = settings?.visualsMaxCount || 1;

  return (
    <>
      <div className={styles.root}>
        <SectionDescriptionLoadingWrapper isLoading={isPageLoading}>
          <div className={styles.contentFieldsWrapper}>
            {(!isEditingMode && !contentFlow?.content?.description && !isPageUpdateLoading) || settings?.isVerticalView ? null : (
              <>
                <div className={styles.contentWrapper}>
                  {!isEditingMode && !isPageUpdateLoading && <SectionSubTitle subTitle="Caption" />}
                  <EditorStyledField
                    keyForRerender={isEditingMode && !isRerender ? false : `${Math.random()}`}
                    placeholder={'You can write your own caption here or generate caption ideas using Otterfish AI'}
                    initialValue={contentFlow?.content?.description}
                    onChange={value => contentFlow?.content?.onChangeContent({ description: value })}
                    readOnly={(!isEditingMode && !isPageUpdateLoading) || isGenerateLoading}
                    wrapperClassName={cs({
                      [styles.withBottomSpace]: isEditingMode,
                    })}
                    editorClassName={cs({ [styles.editorViewState]: !isPageUpdateLoading && !isEditingMode })}
                    toolbarClassName={styles.editorToolbar}
                  />
                  {isEditingMode && (
                    <div className={styles.generateButtonWrapper} onMouseEnter={onGenerateEnter} onMouseLeave={onGenerateLeave}>
                      <ButtonLightPurpleWhite
                        className={styles.generateButton}
                        onClick={generateContentWithAI}
                        size="small"
                        designVersion="v1"
                        disabled={isGenerateLoading || !isGenerateAvailable}
                      >
                        Generate Ideas with AI
                      </ButtonLightPurpleWhite>
                      {(isGenerateLoading || !isGenerateAvailable) && generateTooltip}
                    </div>
                  )}
                </div>
                {!!isEditingMode && !isFirstGenerate && (
                  <span className={cs(styles.generateText, styles.generateSubText)}>
                    Otterfish AI helps you to generate caption ideas for your deliverable as a starting point. You can either use this
                    caption or create one of your own based on these ideas
                  </span>
                )}
              </>
            )}
            <SelectCallToAction
              className={styles.callToActionInput}
              disabled={!isEditingMode && !isPageUpdateLoading}
              value={contentFlow?.content?.call_to_action?.[0] ?? null}
              onSelect={value => contentFlow.content.onChangeContent({ call_to_action: value ? [value] : null })}
            />
          </div>
        </SectionDescriptionLoadingWrapper>
        <SectionVisualsWrapper
          assetsFlow={contentFlow.contentAssets}
          isPageLoading={isPageLoading}
          isPageUpdateLoading={isPageUpdateLoading}
          isEditingMode={isEditingMode}
          maxVisualsLength={maxVisualsCount}
          subTitle="Feel free to add your content visuals"
          sectionSubTitle={'Your visuals'}
          infoBannerText={'Preview of uploaded files will be available after saving the content!'}
          fileTypes={settings?.fileTypes}
          isViewMore={true}
          isShowAllVisual={true}
          downloadButton={{
            isDisplay: Boolean(
              contentFlow?.content?.status !== DELIVERABLE_STATUSES.EMPTY &&
                pageModel?.roleCan(DeliverablePermission.CAN_DOWNLOAD_ASSETS) &&
                !!openedDeliverable?.content?.assets.length
            ),
            assets: openedDeliverable?.content?.assets,
          }}
        >
          {contentFlow.contentAssets.current.length > 0 ? (
            <div className={cs(styles.visualsList, { [styles.verticalView]: settings?.isVerticalView })}>
              <VisualsDraggableListWrapper
                onReorder={contentFlow.contentAssets.onReorder}
                assets={contentFlow.contentAssets.current}
                withDrag={isEditingMode && contentFlow.contentAssets.current.length > 1}
              >
                {contentFlow.contentAssets.current.map(asset => (
                  <VisualCard
                    isEffectsDisabled={false}
                    key={asset.id}
                    isStoryTile={!!settings?.isStoryTile}
                    withCaption={!!settings?.isVerticalView}
                    fileTypes={settings?.fileTypes}
                    asset={asset}
                    onDeleteVisual={contentFlow.contentAssets.onDelete}
                    onUpdateVisual={contentFlow.contentAssets.onUpdate}
                    openAsset={openAsset}
                    isEditingMode={isEditingMode}
                    isPageUpdateLoading={isPageUpdateLoading}
                    isViewMore={true}
                    textConfig={{
                      deleteWithDescription: 'Deleting a visual will delete its description too. Are you sure you want to delete it?',
                      deleteWithoutDescription: 'Are you sure you want to delete the visual?',
                      descriptionPlaceholder: 'Add more details about your visual',
                    }}
                    assetsCount={contentFlow.contentAssets.current.length}
                  />
                ))}
              </VisualsDraggableListWrapper>
            </div>
          ) : (
            <></>
          )}
        </SectionVisualsWrapper>
      </div>

      {!isPageLoading && pageModel?.roleCan(DeliverablePermission.CAN_SEE_SECTION_FOOTER) && (
        <>
          <SectionFooter
            assets={contentFlow?.contentAssets?.current}
            section={openedDeliverable?.content}
            editText="Edit Content"
            isEditingMode={isEditingMode}
            isPageUpdateLoading={isPageUpdateLoading}
            onCancel={() => {
              if (contentFlow?.isSectionChanged) {
                setIsOpenConfirmCancel(true);
              } else {
                setIsEditingMode(false);
              }
            }}
            onEdit={onEdit}
            onSave={() => {
              contentFlow.content.saveContent();
              setIsEditingMode(false);
            }}
            isDisabled={!contentFlow.isSectionChanged}
            onRemove={onRemove}
          />
        </>
      )}
    </>
  );
};

export default InnerContent;
