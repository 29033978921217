import React, { FC, ReactNode, SVGProps } from 'react';
import styles from './RoundedWhiteCard.module.scss';
import cs from 'classnames';

type PropsType = {
  withoutHeader?: boolean;
  headerTitle?: string;
  HeaderIcon?: FC<SVGProps<SVGSVGElement>>;
  renderHeaderLeft?: () => ReactNode | JSX.Element;
  renderHeaderRight?: () => ReactNode | JSX.Element;
  children?: ReactNode | JSX.Element;
  rootClass?: string;
  headerClass?: string;
  bodyClass?: string;
};

const RoundedWhiteCard: FC<PropsType> = props => {
  return (
    <div className={cs(styles.root, props.rootClass)}>
      {props.withoutHeader ? null : (
        <div className={cs(styles.header, props.headerClass)}>
          <div className={styles.headerSides}>
            {props.HeaderIcon ? <props.HeaderIcon /> : null}
            {props.headerTitle ? <h4 className={styles.headerTitle}>{props.headerTitle}</h4> : null}
            {props.renderHeaderLeft ? props.renderHeaderLeft() : null}
          </div>
          <div className={styles.headerSides}>{props.renderHeaderRight ? props.renderHeaderRight() : null}</div>
        </div>
      )}
      <div className={props.bodyClass}>{props.children}</div>
    </div>
  );
};

export default RoundedWhiteCard;
