import React from 'react';
import styles from './InviteMemberModal.module.scss';
import { Modal } from '../index';
import InputControl from '../../../expert/components/FormControls/InputControl';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';
import { useDispatch, useSelector } from 'react-redux';
import { clearFailure } from '../../../actions/user.actions';
import ErrorModal from '../../../expert/components/Modal/ErrorModal';
import SuccessModal from '../../../expert/components/Modal/SuccessModal';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { emailValidation } from '../../../pages/CreateTest/utils';

const InviteMember = ({ onClose, submitText, onSubmit }) => {
  const dispatch = useDispatch();
  const error = useSelector(state => state.users.failure.errors);
  const success = useSelector(state => state.users.success.success);
  const loading = useSelector(state => state.users.loading);
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().test('validate-email', emailValidation).required('Email is required'),
    }),
    onSubmit: async values => {
      await onSubmit(values.email);
    },
  });

  return (
    <div className={styles.root}>
      <span className={styles.title}>Invite Team Member</span>
      <span className={styles.text}>Emails</span>
      <InputControl
        placeholder="Member Email here"
        name="email"
        onChange={event => formik.setFieldValue('email', event.target.value.toLowerCase())}
        onBlur={formik.handleBlur}
        value={formik.values.email}
        errorMessage={formik.touched.email ? formik.errors.email : null}
        className={styles.input}
      />
      <ButtonCustom onClick={formik.handleSubmit} loading={loading} disabled={!formik.values.email || formik.errors.email}>
        Invite member
      </ButtonCustom>

      <ErrorModal
        open={!!error}
        onClose={() => dispatch(clearFailure())}
        title={'Oops, something went wrong.'}
        text={error?.body[0]?.message ?? 'Seems Email does not exist or something else'}
        submitText={submitText}
      />
      <SuccessModal
        open={!!success}
        onClose={() => {
          dispatch(clearFailure());
          onClose();
        }}
        text={'Invite has been successfully sent!'}
      />
    </div>
  );
};

const InviteMemberModal = props => {
  const loading = useSelector(state => state.users.loading);
  return (
    <Modal {...props} loading={loading}>
      <InviteMember {...props} />
    </Modal>
  );
};

export default InviteMemberModal;
