import React, { useCallback, useEffect, useRef } from 'react';
import { UserModelService } from '../../models/permissions/services/UserModelService';
import { setUserModel, updateUserTimezone } from '../../actions/auth.actions';
import { AuthorizedUser } from '../../models/permissions/users/AuthorizedUser';
import { UnauthorizedGuest } from '../../models/permissions/users/UnauthorizedGuest';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

const useSetUserModel = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuthenticated = useSelector(state => !!state.auth.token);
  const currentOrgRedux = useSelector(state => state.auth.currentOrganization);
  const loc = useLocation();

  const locRef = useRef(loc);

  useEffect(() => {
    locRef.current = loc;
  }, [loc]);

  return useCallback(
    (redirectUrl = null, isDisableAllowedRedirect = false) => {
      new UserModelService(isAuthenticated, currentOrgRedux?.organization_id, locRef.current?.pathname).getUserModel().then(user => {
        dispatch(setUserModel(user)).then(() => {
          if (!(user instanceof AuthorizedUser)) {
            if (user instanceof UnauthorizedGuest) {
              dispatch(updateUserTimezone({ name: moment.tz.guess() }));
            }
            if (!isDisableAllowedRedirect || redirectUrl) {
              const route = redirectUrl ? redirectUrl : user.allowedRoutes[0].path;
              history.push(route);
            }
          }
        });
      });
    },
    [isAuthenticated, currentOrgRedux]
  );
};

export default useSetUserModel;
