import React, { FC, useCallback } from 'react';
import styles from './ContentHeader.module.scss';
import { useDispatch } from 'react-redux';
import ContentBoardCollaborators from '../ContentBoardCollaborators';
import { useParams } from 'react-router-dom';
import ShareLinkButton from '../ShareLinkButton';
import { ContentBoardPermission } from '../../../../../models/permissions/enum/ContentBoardPermission';
import { OrganizationPermission } from '../../../../../models/permissions/enum/OrganizationPermission';
import BoardStatus from '../../../../../components/statuses/BoardStatus';
import { useAppSelector } from '../../../../../shared/hooks/reduxHooks';
import HeaderLoadingState from './HeaderLoadingState';
import { patchContentBoard } from '../../../../../actions/ContentBoardActions/contentBoard.actions';
import brandPreviewImage from '../../../../../assets/img/brandPlaceholder.svg';
import { User } from '../../../../../models/permissions/users/User';
import { UserPermission } from '../../../../../models/permissions/enum/UserPermission';
import { ReactComponent as EditIcon } from '../../../../../assets/img/icons/edit_pen.svg';
import { ReactComponent as PlusIcon } from '../../../../../assets/img/icons/plus.svg';
import ButtonLightPurpleWhite from '../../../../../components/Buttons/ButtonLightPurpleWhite';
import cs from 'classnames';
import TextField from '../../../../../components/FormControls/TextField';
import { INPUT_FILE_ALLOWED_TYPE } from '../../../../../shared/constants/tsConstants';
import useTooltip, { TOOLTIP_POSITIONS } from '../../../../../shared/hooks/fixedTooltip/useTooltip';
import { useMediaQuery } from 'react-responsive/src';
import useBoardInfoForm from './useBoardInfoForm';

const ContentHeader: FC = () => {
  const { openedBoard, boardLoading, pageModel } = useAppSelector(state => state.contentBoard);
  const currentOrg = useAppSelector<any>(state => state.auth.currentOrganization);
  const currentOrgModel = useAppSelector<any>(state => state.auth.currentOrganizationModel);
  const userModel = useAppSelector<User | null>(state => state.auth.userModel);
  const params: { boardId: string } = useParams();

  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const onUpdateBoardStatus = useCallback(
    status => {
      dispatch(patchContentBoard(currentOrg?.organization_id, params.boardId, { status }));
    },
    [currentOrg?.organization_id, params?.boardId]
  );

  const { infoFormState, infoFormActions } = useBoardInfoForm();

  const {
    toolTip: brandNameTooltip,
    onEnter: onEnterBrandName,
    onLeave: onLeaveBrandName,
  } = useTooltip(TOOLTIP_POSITIONS.BOTTOM, { text: infoFormState.brandName, className: styles.tooltip });
  const {
    toolTip: boardNameTooltip,
    onEnter: onEnterBoardName,
    onLeave: onLeaveBoardName,
  } = useTooltip(TOOLTIP_POSITIONS.BOTTOM, { text: infoFormState.boardName, className: styles.tooltip });

  if (!userModel?.hasAccess(UserPermission.CAN_SEE_CONTENT_BOARD_HEADER)) {
    return null;
  }

  if (boardLoading) {
    return <HeaderLoadingState />;
  }

  return (
    <div className={styles.root}>
      <div className={cs(styles.headerInfo, { [styles.editStyle]: infoFormState.isEdit })}>
        <div className={styles.formWrapper}>
          <div className={styles.brandWrapper}>
            <div className={styles.brandImageWrapper}>
              <img
                className={styles.brandImage}
                src={infoFormState.brandLogo.url || openedBoard?.brand_logo_location || brandPreviewImage}
              />
              {infoFormState.isEdit && (
                <div className={styles.hoverState}>
                  <PlusIcon />
                  <input
                    type="file"
                    accept={INPUT_FILE_ALLOWED_TYPE.IMAGE_INPUT_ALLOWED}
                    onChange={infoFormActions.handleLogoUpload}
                    className={styles.fileInput}
                  />
                </div>
              )}
            </div>
            {infoFormState.isEdit ? (
              <TextField
                wrapperClassName={styles.inputWrapper}
                className={cs(styles.input)}
                value={infoFormState.brandName}
                placeholder="Brand name"
                onChange={infoFormActions.handleBrandNameChange}
                error={infoFormState.brandNameError ? infoFormState.brandNameError : ''}
              />
            ) : (
              <div className={styles.brandName}>
                <span onMouseEnter={onEnterBrandName} onMouseLeave={onLeaveBrandName}>
                  {infoFormState.brandName}
                  {!isMobile && brandNameTooltip}
                </span>
              </div>
            )}
          </div>
          <div className={styles.boardWrapper}>
            {infoFormState.isEdit ? (
              <>
                <TextField
                  wrapperClassName={styles.inputWrapper}
                  className={cs(styles.input)}
                  value={infoFormState.boardName}
                  placeholder="Content board name"
                  onChange={infoFormActions.handleBoardNameChange}
                  error={infoFormState.boardNameError ? infoFormState.boardNameError : ''}
                />
              </>
            ) : (
              <>
                <div className={styles.boardName} onMouseEnter={onEnterBoardName} onMouseLeave={onLeaveBoardName}>
                  {infoFormState.boardName} {!isMobile && boardNameTooltip}
                </div>
                <div className={styles.boardNameSubtitle}>Content board</div>
              </>
            )}
          </div>
        </div>
        <div className={styles.actionWrapper}>
          {!infoFormState.isEdit ? (
            <>
              {pageModel?.roleCan(ContentBoardPermission.CAN_EDIT_BOARD_NAME) && (
                <div className={styles.edit} onClick={infoFormActions.onEdit}>
                  <EditIcon />
                </div>
              )}
            </>
          ) : (
            <ButtonLightPurpleWhite
              disabled={!!infoFormState.boardNameError || !!infoFormState.brandNameError}
              className={styles.saveChangesButton}
              size="small"
              onClick={infoFormActions.onSubmitSaveInfo}
            >
              Save changes
            </ButtonLightPurpleWhite>
          )}
        </div>
      </div>

      {pageModel?.roleCan(ContentBoardPermission.CAN_SEE_HEADER_INFO_AND_ACTIONS) && (
        <div className={styles.headerActions}>
          {currentOrgModel?.hasAccess(OrganizationPermission.CAN_SHARE_CONTENT_BOARD) && <ShareLinkButton className={styles.shareLink} />}
          <ContentBoardCollaborators className={styles.collaborators} />
          {openedBoard?.status ? (
            <BoardStatus
              wrapperClass={styles.boardStatus}
              status={openedBoard?.status}
              canEditStatus={true}
              onSelect={onUpdateBoardStatus}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default ContentHeader;
