import {IResultAsset} from "../../../models/entities/asset/ResultAsset";
import {useAppDispatch} from "../../../shared/hooks/reduxHooks";
import {useResultAssetsDownload} from "./useResultAssetsDownload";
import React, {FC, useCallback, useState} from "react";
import {deleteAllResultAssets} from "../../../actions/ResultsActions/results.actions";
import {useParams} from "react-router-dom";
import ConfirmPopup from "../../../components/ConfirmPopup";
import BlurBlockBackground from "../../../components/BlurBlockBackground";


type PropsType = {
  openConfirmModal: boolean;
  confirmDeleteText?: string;
  onSubmitDelete: () => void;
  setOpenConfirmModal: (value: boolean) => void;
};

const DeleteResultsConfirmModal: FC<PropsType> = props => {
  return (
    <>
      {props.openConfirmModal && (
        <BlurBlockBackground>
          <ConfirmPopup
            withoutWrapper
            text={props.confirmDeleteText || ''}
            buttonsConfig={{
              primaryButton: {
                text: 'Yes, delete',
                onClick: (e) => {
                  e.stopPropagation();
                  props.onSubmitDelete()
                  props.setOpenConfirmModal(false)
                },
              },
              secondaryButton: {
                text: 'Discard',
                onClick: (e) => {
                  e.stopPropagation();
                  props.setOpenConfirmModal(false)
                },
              },
            }}
          />
        </BlurBlockBackground>
      )}
    </>
  );
};

export default function useResultAssetGeneralActions(assets: IResultAsset[]) {
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState<boolean>(false);
  const params = useParams<{ boardId: string }>();
  const dispatch = useAppDispatch();

  const onDownload = useResultAssetsDownload(assets)
  const onDelete = useCallback(() => dispatch(deleteAllResultAssets(params.boardId)), [params.boardId]);
  const openDeleteModal = useCallback(() => setOpenConfirmDeleteModal(true), []);

  const confirmDeleteModal = (
    <DeleteResultsConfirmModal
      setOpenConfirmModal={setOpenConfirmDeleteModal}
      confirmDeleteText="Do you want to delete all results ?"
      openConfirmModal={openConfirmDeleteModal}
      onSubmitDelete={onDelete}
    />
  )

  return {onDelete: openDeleteModal, onDownload, confirmDeleteModal}
}
