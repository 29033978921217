import React, { FC } from 'react';
import styles from '../TextSection.module.scss';
import LoadingContainer from '../../../../../../../../components/LoadingContainer';

const TextSectionLoadingState: FC = () => {
  return (
    <li className={styles.sectionWrapper}>
      <div className={styles.sectionTitle} style={{ height: 38 }}></div>
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: '18px', marginTop: 25 }}>
        <LoadingContainer width={'40%'} height={16} />
        <LoadingContainer width={'100%'} height={16} />
        <LoadingContainer width={'100%'} height={16} />
        <LoadingContainer width={'100%'} height={16} />
        <LoadingContainer width={'100%'} height={16} />
        <LoadingContainer width={'100%'} height={16} />
        <LoadingContainer width={'100%'} height={16} />
        <LoadingContainer width={'100%'} height={16} />
        <LoadingContainer width={'100%'} height={16} />
        <LoadingContainer width={'100%'} height={16} />
        <LoadingContainer width={'100%'} height={16} />
        <LoadingContainer width={'100%'} height={16} />
        <LoadingContainer width={'100%'} height={16} />
        <LoadingContainer width={'100%'} height={16} />
        <LoadingContainer width={'100%'} height={16} />
        <LoadingContainer width={'100%'} height={16} />
      </div>
    </li>
  );
};

export default TextSectionLoadingState;
