import React, { useEffect, useMemo, useState } from 'react';
import styles from './NotificationsModal.module.scss';
import { Modal as ReactModal } from 'react-responsive-modal';
import { ReactComponent as CloseIcon } from '../../../assets/img/icons/close.svg';
import cs from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationsList, markReadNotifications } from '../../../actions/notifications.actions';
import { getNotificationsCells } from './utils/functions';
import CellLoader from './CellLoader';
import Loader from '../../../expert/components/Loader';
import useUserTimezone from '../../../shared/hooks/useUserTimezone';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive/src';

const Notifications = props => {
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const history = useHistory();
  const dispatch = useDispatch();
  const userId = useSelector(state => state.auth.user.id);
  const notificationsData = useSelector(state => state.notifications.data);
  const totalCount = useSelector(state => state.notifications.totalCount);
  const isLoading = useSelector(state => state.notifications.loading);
  // const selectedType = useSelector(state => state.notifications.selectedType);
  const { convertUtcDateToDateByTimezone } = useUserTimezone();

  // useEffect(() => {
  //   return () => {
  //     dispatch(setNotificationsSelectedType(null));
  //   };
  // }, []);

  useEffect(() => {
    setPage(1);
    // if (!selectedType) {
    dispatch(getNotificationsList(true, null, null, 1, pageSize));
    // } else {
    //   dispatch(getNotificationsList(true, selectedType, null, 1, pageSize));
    // }
  }, []);

  useEffect(() => {
    if (page > 1) {
      // if (!selectedType) {
      dispatch(getNotificationsList(false, null, null, page, pageSize));
      // } else {
      //   dispatch(getNotificationsList(false, selectedType, null, page, pageSize));
      // }
    }
  }, [page]);

  const onNotificationClick = id => {
    dispatch(markReadNotifications([id]));
  };

  const changeRoute = path => {
    history.push(path);
  };

  const closeModal = () => props.onClose();

  return (
    <div className={styles.root}>
      <div className={cs(styles.flexRow, styles.header)}>
        <div className={styles.title}>Notifications Center</div>
        <div className={styles.closeIcon} onClick={closeModal}>
          <CloseIcon />
        </div>
      </div>
      {/* <TypeSelector selectedType={selectedType} selectType={type => dispatch(setNotificationsSelectedType(type))} isLoading={isLoading} />*/}
      <div className={styles.list}>
        {getNotificationsCells(
          notificationsData,
          { convertUtcDateToDateByTimezone, onNotificationClick, changeRoute, closeModal },
          { userId: userId }
        )}
        {isLoading && [...Array(12).keys()].map((_, idx) => <CellLoader key={idx} />)}
        {notificationsData.length < totalCount && !isLoading && (
          <div className={styles.loadMore} onClick={() => setPage(page + 1)}>
            Load more notifications
          </div>
        )}
        {isLoading && (
          <div className={styles.loadingFooter}>
            <Loader className={styles.circleLoader} mainColor={styles.main} accentColor={styles.accentLoaderColor} /> Loading
          </div>
        )}

        {/* <DateSeparator text={'Notifications from epic'} />*/}
        {/* <GenericCell*/}
        {/*  unread*/}
        {/*  firstText={'Ollie has updated the concept for Deliverable:'}*/}
        {/*  boldText={'"Deliverable name"'}*/}
        {/*  secondText={'for your review.'}*/}
        {/*  timestamp={'12:14 PM'}*/}
        {/* />*/}
        {/* <GenericCell*/}
        {/*  unread*/}
        {/*  firstText={'Ollie has updated the concept for Deliverable:'}*/}
        {/*  boldText={'"Deliverable name"'}*/}
        {/*  secondText={'and is waiting for you to approve.'}*/}
        {/*  timestamp={'12:14 PM'}*/}
        {/* />*/}
        {/* <GenericCell*/}
        {/*  unread*/}
        {/*  firstText={'Ollie has created a new discussion room'}*/}
        {/*  boldText={'"Name of the room"'}*/}
        {/*  secondText={'to chat with you.'}*/}
        {/*  timestamp={'12:14 PM'}*/}
        {/* />*/}
        {/* <GenericCell*/}
        {/*  unread*/}
        {/*  firstText={'🎉 Woohoo!! content for Deliverable: '}*/}
        {/*  boldText={'"Deliverable name"'}*/}
        {/*  secondText={'has been approved.'}*/}
        {/*  timestamp={'12:14 PM'}*/}
        {/* />*/}
        {/* <GenericCell*/}
        {/*  unread*/}
        {/*  firstText={'🎉 Woohoo!! Concept for Deliverable: '}*/}
        {/*  boldText={'"Name of the deliverable"'}*/}
        {/*  secondText={'has been approved.'}*/}
        {/*  timestamp={'12:14 PM'}*/}
        {/* />*/}
        {/* <GenericCell*/}
        {/*  unread*/}
        {/*  firstText={"Ollie has updated the content's due date for Deliverable:"}*/}
        {/*  boldText={'"Deliverable name"'}*/}
        {/*  secondText={'to 09:00 AM on 20/09/2022.'}*/}
        {/*  timestamp={'12:14 PM'}*/}
        {/* />*/}
        {/* <GenericCell*/}
        {/*  unread*/}
        {/*  firstText={'Ollie has updated the content for Deliverable:'}*/}
        {/*  boldText={'"Deliverable name"'}*/}
        {/*  secondText={'for your review.'}*/}
        {/*  timestamp={'12:14 PM'}*/}
        {/* />*/}
        {/* <GenericCell*/}
        {/*  unread*/}
        {/*  firstText={'Ollie has updated the content for Deliverable:'}*/}
        {/*  boldText={'"Deliverable name"'}*/}
        {/*  secondText={'and is waiting for you to approve.'}*/}
        {/*  timestamp={'12:14 PM'}*/}
        {/* />*/}
        {/* <GenericCell*/}
        {/*  unread*/}
        {/*  firstText={'Content for the Deliverable:'}*/}
        {/*  boldText={'"Name of the deliverable"'}*/}
        {/*  secondText={'is due today.'}*/}
        {/*  timestamp={'12:14 PM'}*/}
        {/* />*/}
        {/* <GenericCell*/}
        {/*  unread*/}
        {/*  firstText={'Concept for the Deliverable:'}*/}
        {/*  boldText={'"Name of the deliverable"'}*/}
        {/*  secondText={'is due today.'}*/}
        {/*  timestamp={'12:14 PM'}*/}
        {/* />*/}
        {/* <GenericCell*/}
        {/*  unread*/}
        {/*  firstText={'Content for the Deliverable:'}*/}
        {/*  boldText={'"Name of the deliverable"'}*/}
        {/*  secondText={'is due in two days on 20/09/2022.'}*/}
        {/*  timestamp={'12:14 PM'}*/}
        {/* />*/}
        {/* <GenericCell*/}
        {/*  unread*/}
        {/*  firstText={'Concept for the Deliverable:'}*/}
        {/*  boldText={'"Name of the deliverable"'}*/}
        {/*  secondText={'is due in two days on 20/09/2022.'}*/}
        {/*  timestamp={'12:14 PM'}*/}
        {/* />*/}
        {/* <GenericCell*/}
        {/*  unread*/}
        {/*  firstText={'Content for the Deliverable:'}*/}
        {/*  boldText={'"Name of the deliverable"'}*/}
        {/*  secondText={'is due to go live today at 09:00 AM.'}*/}
        {/*  timestamp={'12:14 PM'}*/}
        {/* />*/}
        {/* <GenericCell*/}
        {/*  unread*/}
        {/*  firstText={'Content for the Deliverable:'}*/}
        {/*  boldText={'"Name of the deliverable"'}*/}
        {/*  secondText={'is due to go live in two days at 09:00 AM on 20/09/2022.'}*/}
        {/*  timestamp={'12:14 PM'}*/}
        {/* />*/}
        {/* <GenericCell*/}
        {/*  unread*/}
        {/*  firstText={"Ollie has updated the concept's due date for Deliverable:"}*/}
        {/*  boldText={'"Name of the deliverable"'}*/}
        {/*  secondText={'to 09:00 AM on 20/09/2022.'}*/}
        {/*  timestamp={'12:14 PM'}*/}
        {/* />*/}
        {/* <GenericCell*/}
        {/*  unread*/}
        {/*  firstText={"Ollie has updated the concept's live date for Deliverable:"}*/}
        {/*  boldText={'"Name of the deliverable"'}*/}
        {/*  secondText={'to 09:00 AM on 20/09/2022.'}*/}
        {/*  timestamp={'12:14 PM'}*/}
        {/* />*/}
        {/* <GenericCell*/}
        {/*  unread*/}
        {/*  firstText={'Ollie has updated the name of the "old name of the deliverable" to'}*/}
        {/*  boldText={'"new name of the deliverable"'}*/}
        {/*  timestamp={'12:14 PM'}*/}
        {/* />*/}
        {/* <GenericCell*/}
        {/*  unread*/}
        {/*  firstText={'Ollie has created a new content board'}*/}
        {/*  boldText={'"Name of the content board"'}*/}
        {/*  secondText={'for "Brief Name" brief.'}*/}
        {/*  timestamp={'12:14 PM'}*/}
        {/* />*/}
        {/* <GenericCell*/}
        {/*  unread*/}
        {/*  firstText={'Ollie has assigned content board'}*/}
        {/*  boldText={'"Name of the content board"'}*/}
        {/*  secondText={'to you.'}*/}
        {/*  timestamp={'12:14 PM'}*/}
        {/* />*/}
        {/* <GenericCell*/}
        {/*  unread*/}
        {/*  firstText={'Ollie(Org Name) has invited you to collaborate on the Content Board:'}*/}
        {/*  boldText={'"Name of the board"'}*/}
        {/*  timestamp={'12:14 PM'}*/}
        {/* />*/}
        {/* <GenericCell*/}
        {/*  unread*/}
        {/*  firstText={'Ollie(Org Name) has invited you to collaborate on the brief:'}*/}
        {/*  boldText={'"Brief Name"'}*/}
        {/*  timestamp={'12:14 PM'}*/}
        {/* />*/}
        {/* <GenericCell*/}
        {/*  unread*/}
        {/*  firstText={'Ollie has shared the content for Deliverable:'}*/}
        {/*  boldText={'"Name of the Deliverable"'}*/}
        {/*  secondText={'with Agency/Brand (Org Name) for approval.'}*/}
        {/*  timestamp={'12:14 PM'}*/}
        {/* />*/}
        {/* <GenericCell*/}
        {/*  unread*/}
        {/*  firstText={'Ollie has shared the concept for Deliverable:'}*/}
        {/*  boldText={'"Name of the Deliverable"'}*/}
        {/*  secondText={'with Agency/Brand (Org Name) for approval.'}*/}
        {/*  timestamp={'12:14 PM'}*/}
        {/* />*/}
        {/* <DateSeparator text={'Today'} />*/}
        {/* <GenericCell*/}
        {/*  unread*/}
        {/*  firstText={'You have been invited to collaborate on a content board'}*/}
        {/*  boldText={'"Content board name"'}*/}
        {/*  timestamp={'12:14 PM'}*/}
        {/* />*/}
        {/* <GenericCell firstText={'You have been invited to an organization'} boldText={'"Organization name"'} timestamp={'12:14 PM'} />*/}
        {/* <GenericCell*/}
        {/*  unread*/}
        {/*  firstText={'You have 10 Unread messages on a discussion board on'}*/}
        {/*  boldText={'"Deliverable name"'}*/}
        {/*  timestamp={'12:14 PM'}*/}
        {/* />*/}
        {/* <StatusChangeCell unread itemName={'Campaign brief name'} timestamp={'12:14 PM'} type={'campaign'} status={'approved'} />*/}
        {/* <StatusChangeCell unread itemName={'Content board name'} timestamp={'12:14 PM'} type={'contentApproval'} status={'in_progress'} />*/}
        {/* <StatusChangeCell itemName={'Content board name'} timestamp={'12:14 PM'} type={'contentApproval'} status={'pending'} />*/}
        {/* <GenericCell*/}
        {/*  firstText={'You have 10 Unread messages on a discussion board on'}*/}
        {/*  boldText={'"Deliverable name"'}*/}
        {/*  timestamp={'12:14 PM'}*/}
        {/* />*/}
      </div>
    </div>
  );
};

const NotificationsModal = props => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const modalStyles = useMemo(
    () => ({
      overlay: {
        padding: '0 0 53px',
        backdropFilter: 'blur(3px)',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        justifyContent: 'flex-end',
      },
      modal: {
        margin: 0,
        background: '#fff',
        padding: 0,
        width: isMobile ? '100%' : '511px',
        height: '100%',
        borderRadius: isMobile ? '0' : '10px 0 0 10px',
        boxShadow: '0px 30px 84px rgba(19, 10, 46, 0.08), 0px 8px 32px rgba(19, 10, 46, 0.07), 0px 6px 18px rgba(0, 0, 0, 0.06)',
      },
      closeButton: {
        display: 'none',
      },
    }),
    [isMobile]
  );

  return (
    <ReactModal {...props} maxWidth={isMobile ? '100%' : '511px'} styles={modalStyles}>
      <Notifications {...props} />
    </ReactModal>
  );
};

export default NotificationsModal;
