import React, { FC, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks/reduxHooks';
import { useHistory, useParams } from 'react-router-dom';
import { getBoardBrief, getBoardBriefAsset } from '../../../actions/BoardBriefActions/boardBriefActions';
import ContentApprovalHeaderBreadcrumbs from '../components/ContentApprovalHeaderBreadcrumbs';
import useBoardSetup from '../hooks/useBoardSetup';
import { ContentBoardPermission } from '../../../models/permissions/enum/ContentBoardPermission';
import useBoardCreator from '../hooks/useBoardCreator';
import useOpenEditBriefPage from '../createPages/EditBoardBrief/useOpenEditBriefPage';
import ViewBriefPage from '../../CampaignBrief/ViewBriefPage';
import { ContentApprovalBreadcrumbsPageType } from '../../../models/entities/breadcrumbs/enums/ContentApprovalBreadcrumbsPage';

const BoardBriefPage: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { boardId } = useParams<{ boardId: string }>();

  const organizationId = useAppSelector<any>(state => state.auth.currentOrganization?.organization_id);
  const { openedBoard, boardLoading, pageModel } = useAppSelector(state => state.contentBoard);
  const { boardBrief, boardBriefAssets, boardBriefLoading } = useAppSelector(state => state.boardBrief);

  useBoardSetup();
  const { onEditClick, isEditDisplay } = useOpenEditBriefPage(openedBoard);

  const isBoardCreator = useBoardCreator();
  const isLoading = useMemo(() => boardLoading || boardBriefLoading, [boardLoading, boardBriefLoading]);

  useEffect(() => {
    if (!pageModel || isBoardCreator === null) return;

    if (pageModel.roleCan(ContentBoardPermission.BOARD_DOCUMENTS_ONLY_VIEW_MODE) || isBoardCreator) return;

    if (boardId) {
      history.push(`/content-board/${boardId}`);
      return;
    }

    history.push('/content-approval');
  }, [pageModel, isBoardCreator, boardId]);

  useEffect(() => {
    if (!(openedBoard?.campaign_brief_item_id && openedBoard?.campaign_brief_id && organizationId)) return;
    dispatch(getBoardBrief(organizationId, boardId, openedBoard?.campaign_brief_id, openedBoard?.campaign_brief_item_id));
    dispatch(getBoardBriefAsset(organizationId, boardId, openedBoard?.campaign_brief_id));
  }, [openedBoard?.campaign_brief_item_id, openedBoard?.campaign_brief_id, organizationId]);

  return (
    <ViewBriefPage
      brief={boardBrief}
      visualAssets={boardBriefAssets}
      isLoading={isLoading}
      isEditDisplay={isEditDisplay}
      onEdit={onEditClick}
      title={<ContentApprovalHeaderBreadcrumbs page={ContentApprovalBreadcrumbsPageType.BOARD_BRIEF} />}
      mobileJSXTitle={<ContentApprovalHeaderBreadcrumbs page={ContentApprovalBreadcrumbsPageType.BOARD_BRIEF} asMobile />}
    />
  );
};

export default BoardBriefPage;
