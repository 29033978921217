import React, { FC } from 'react';
import styles from './SetupNotificationPreferencesPage.module.scss';
import SetupPreferencesForm from './components/SetupNotificationPreferencesForm';
import NotificationPreferencesLayout from './components/NotificationPreferencesLayout';

const SetupNotificationPreferencesPage: FC = () => {
  return (
    <div className={styles.addStylesNestingContainer}>
      <NotificationPreferencesLayout contentClassName={styles.root}>
        <div className={styles.container}>
          <h1 className={styles.title}>Communication preferences</h1>
          <SetupPreferencesForm />
        </div>
      </NotificationPreferencesLayout>
    </div>
  );
};

export default SetupNotificationPreferencesPage;
