import React, { ComponentProps, FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import SingleDatePicker from '../../BriefsAndBoardsFlows/LiveDueDate/components/SingleDatePicker';
import { MODALS_VERSION } from '../../BriefsAndBoardsFlows/LiveDueDate/components/CalendarWrapper';
import styles from './SingleDateCalendar.module.scss';
import dayjs from 'dayjs';
import mapCalendarDaysFabrik from '../../BriefsAndBoardsFlows/LiveDueDate/utils/mapCalendarsDatesFabrik';
import moment from 'moment';
import { dateWithoutTimeFormat } from '../../BriefsAndBoardsFlows/LiveDueDate/constants/timeFormats';
import cs from 'classnames';
import { useMediaQuery } from 'react-responsive/src';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';
import useUserTimezone from '../../../shared/hooks/useUserTimezone';

type PropsType = {
  renderInputComponent: ComponentProps<typeof SingleDatePicker>['renderInputComponent'];
  modalVersion?: MODALS_VERSION;
  date?: string | null;
  onChangeDate: (date: string | null) => void;
  calendarMinDate?: string | null;
  calendarMaxDate?: string | null;
  calendarRootClassName?: string;
  parseToFormat: string;
} & PropsWithChildren;

const SingleDateCalendar: FC<PropsType> = props => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const isNotebook: boolean = useMediaQuery({ query: '(max-width: 1200px)' });
  const [dateState, setDateState] = useState<null | string>(props.date || null);
  const { userTimezone } = useUserTimezone();

  const toDayWithoutTime = useMemo(
    () =>
      moment()
        .tz(userTimezone?.name || moment.tz.guess())
        .format(dateWithoutTimeFormat),
    [userTimezone]
  );

  const onChangeResult = useCallback(
    (values: string | null) => {
      props.onChangeDate(values);
      setExpanded(false);
    },
    [props.onChangeDate]
  );

  return (
    <SingleDatePicker
      renderInputComponent={props.renderInputComponent}
      calendarMinDate={props.calendarMinDate || toDayWithoutTime}
      calendarMaxDate={props.calendarMaxDate}
      modalVersion={props.modalVersion || MODALS_VERSION.HYBRID}
      onCloseModal={() => setExpanded(false)}
      calendarRootClassName={cs(styles.datePicker, props.calendarRootClassName)}
      onChangeDate={date => {
        // @ts-ignore
        const newDate = date ? dayjs(date).format(props.parseToFormat) : null;
        setDateState(newDate);
      }}
      date={dateState}
      mapCalendarDays={mapCalendarDaysFabrik(moment.utc(dateState).format(dateWithoutTimeFormat))}
      expanded={expanded}
      setExpanded={setExpanded}
      maxModalWidth={isNotebook ? '300px' : 'fit-content'}
    >
      <div className={styles.buttonsContainer}>
        <ButtonCustom className={styles.button} onClick={() => setDateState(null)} outline>
          Remove
        </ButtonCustom>
        <ButtonCustom className={styles.button} onClick={() => onChangeResult(dateState)}>
          {'Save'}
        </ButtonCustom>
      </div>
    </SingleDatePicker>
  );
};

export default SingleDateCalendar;
