import BriefTextCard from '../../components/BriefTextCard';
import TextRow from '../../components/TextRow';
import { IViewBriefData } from '../../../../../../../models/entities/boardBrief/ViewBrief';
import React, { FC, memo, useMemo } from 'react';
import TextContainer from '../../components/TextContainer';
import getMoneyView from '../../utils/getMoneyView';

type PropsType = {
  boosting_budget: IViewBriefData['boosting_budget'];
  boosting_budget_currency: IViewBriefData['boosting_budget_currency'];
  boosting_duration: IViewBriefData['boosting_duration'];
  boosting_manager: IViewBriefData['boosting_manager'];
  isLoading: boolean;
};

const BoostingCard: FC<PropsType> = memo(props => {
  const budgetText: string | null = useMemo(
    () =>
      props?.boosting_budget && props?.boosting_budget_currency
        ? getMoneyView(props.boosting_budget_currency, props.boosting_budget)
        : null,
    [props.boosting_budget, props.boosting_budget_currency]
  );
  const durationText = useMemo(() => (props?.boosting_duration ? `${props?.boosting_duration} Weeks` : null), [props.boosting_duration]);
  const managerText = useMemo(() => props?.boosting_manager?.join(' / ').replaceAll('_', ' '), [props.boosting_manager]);

  return (
    <BriefTextCard headerTitle={'Campaign details'}>
      <TextRow title={'Budget'}>
        <TextContainer isLoading={props.isLoading} text={budgetText} />
      </TextRow>
      <TextRow title={'Duration'}>
        <TextContainer isLoading={props.isLoading} text={durationText} />
      </TextRow>
      <TextRow title={'Manager'}>
        <TextContainer isLoading={props.isLoading} text={managerText} />
      </TextRow>
    </BriefTextCard>
  );
});

export default BoostingCard;
