import { IBoard } from '../Board';
import { BOARD_STATUTES_TYPES } from '../enums/boardStatuses';
import { IBoardDTO } from '../dto/Board';
import { ITextFile } from '../../file/TextFile';
import { TextFileHydrator } from '../../file/hydrators/TextFileHydrator';
import { BOARD_BRIEF_ITEM_TYPES } from '../enums/boardBriefItemType';
import { ICollaboratorDTO } from '../../collaborator/dto/Collaborator';
import { BOARD_DOCUMENT_STATES_TYPE } from '../enums/bordDocumentStatesType';

class BoardHydrator implements IBoard {
  id: number;
  name: string;
  status: BOARD_STATUTES_TYPES;
  collaborators: ICollaboratorDTO[];
  created_at: string;
  creator_id: string;
  updated_at: null | string;
  updater_id: null | string | number;
  is_archived: boolean;
  brand_name: null | string;
  brand_description: null | string;
  brand_logo_file_id: null | number;
  brand_logo_location: null | string;
  brand_logo_thumbnail_file_id: null | number;
  brand_logo_thumbnail_location: null | string;
  brand_provides_product: null | string;
  campaign_brief_id: number | null;
  campaign_brief_item_id: number | null;
  campaign_brief_item_name: null | string;
  campaign_brief_item_type: BOARD_BRIEF_ITEM_TYPES | null;
  campaign_brief_name: null | string;
  campaign_brief_parent_id: number | null;
  key_message: null | string;
  notes: null | string;

  briefFile: null | ITextFile;
  contractFile: null | ITextFile;

  // documents states
  states: {
    contract_file_state: BOARD_DOCUMENT_STATES_TYPE;
    brief_file_state: BOARD_DOCUMENT_STATES_TYPE;
    campaign_brief_state: BOARD_DOCUMENT_STATES_TYPE;
  };

  // documents permissions
  permissions: {
    contract_file_access: boolean;
    brief_file_access: boolean;
    campaign_brief_access: boolean;
  };

  constructor(boardDto: IBoardDTO) {
    this.id = boardDto.id;
    this.name = boardDto.name;
    this.status = boardDto.status;
    this.collaborators = boardDto.collaborators;
    this.created_at = boardDto.created_at;
    this.creator_id = boardDto.creator_id;
    this.updated_at = boardDto.updated_at;
    this.updater_id = boardDto.updater_id;
    this.is_archived = boardDto.is_archived;
    this.brand_name = boardDto.brand_name;
    this.brand_description = boardDto.brand_description;
    this.brand_logo_file_id = boardDto.brand_logo_file_id;
    this.brand_logo_location = boardDto.brand_logo_location;
    this.brand_logo_thumbnail_file_id = boardDto.brand_logo_thumbnail_file_id;
    this.brand_logo_thumbnail_location = boardDto.brand_logo_thumbnail_location;
    this.brand_provides_product = boardDto.brand_provides_product;
    this.campaign_brief_id = boardDto.campaign_brief_id;
    this.campaign_brief_item_id = boardDto.campaign_brief_item_id;
    this.campaign_brief_item_name = boardDto.campaign_brief_item_name;
    this.campaign_brief_item_type = boardDto.campaign_brief_item_type;
    this.campaign_brief_name = boardDto.campaign_brief_name;
    this.campaign_brief_parent_id = boardDto.campaign_brief_parent_id;
    this.key_message = boardDto.key_message;
    this.notes = boardDto.notes;

    this.briefFile = this.getFile(
      boardDto.brief_file_id,
      boardDto.brief_file_name,
      boardDto.brief_file_extension,
      boardDto.brief_file_location,
      boardDto.brief_file_mime_type,
      boardDto.brief_file_size
    );

    this.contractFile = this.getFile(
      boardDto.contract_file_id,
      boardDto.contract_file_name,
      boardDto.contract_file_extension,
      boardDto.contract_file_location,
      boardDto.contract_file_mime_type,
      boardDto.contract_file_size
    );

    this.states = boardDto.states;
    this.permissions = boardDto.permissions;
  }

  private getFile(
    id: string | number | null,
    name: string | null,
    extension: string | null,
    url: string | null,
    mimeType: string | null,
    size: string | number | null
  ) {
    if (!id || !name || !extension || !url || !mimeType || !size) return null;

    return new TextFileHydrator(String(id), name, extension, url, mimeType, size);
  }
}

export default BoardHydrator;
