import BriefTextCard from '../../components/BriefTextCard';
import TextRow from '../../components/TextRow';
import { IViewBriefData } from '../../../../../../../models/entities/boardBrief/ViewBrief';
import React, { FC, memo, useMemo } from 'react';
import TextContainer from '../../components/TextContainer';
import { PREFERRED_ADD_DECLARATION_TYPES } from '../../../../../../../models/entities/boardBrief/enums/AddDeclarationsTypes';
import useDateInUserTimezone from '../../hooks/useDateInUserTimezone';
import getDateDurationText from '../../utils/getDateDurationText';
import getMoneyView from '../../utils/getMoneyView';

type PropsType = {
  preferred_ad_declaration: IViewBriefData['preferred_ad_declaration'];
  date_start: IViewBriefData['date_start'];
  date_end: IViewBriefData['date_end'];
  objective: IViewBriefData['objective'];
  total_budget_currency: IViewBriefData['total_budget_currency'];
  total_budget: IViewBriefData['total_budget'];
  isLoading: boolean;
};

const dateFormat = 'D MMM YYYY';

const CampaignDetailsCard: FC<PropsType> = memo(props => {
  const getPreferredAddDeclarationView = (value: PREFERRED_ADD_DECLARATION_TYPES | null) => {
    switch (value) {
      case PREFERRED_ADD_DECLARATION_TYPES.AD_IN_CAPTION:
        return `Ad in Caption`;
      case PREFERRED_ADD_DECLARATION_TYPES.PAID_PARTNERSHIP_TOOL:
        return 'Paid Partnership Tool';
      default:
        return 'No information yet';
    }
  };

  const dateStart = useDateInUserTimezone(props.date_start || null);
  const dateEnd = useDateInUserTimezone(props.date_end || null);

  const objectiveText: string | null = useMemo(() => props?.objective?.join(', ')?.replaceAll('_', ' ') || null, [props.objective]);
  const campaignDurationText = useMemo(() => getDateDurationText(dateStart, dateEnd, dateFormat), [dateEnd, dateStart]);
  const budgetText: string | null = useMemo(
    () => (props?.total_budget_currency && props?.total_budget ? getMoneyView(props.total_budget_currency, props.total_budget) : null),
    [props.total_budget, props.total_budget_currency]
  );
  const preferredAddDeclarationText = useMemo(
    () => getPreferredAddDeclarationView(props?.preferred_ad_declaration || null),
    [props?.preferred_ad_declaration]
  );

  return (
    <BriefTextCard headerTitle={'Campaign details'}>
      <TextRow title={'Objective'}>
        <TextContainer isLoading={props.isLoading} text={objectiveText} />
      </TextRow>
      <TextRow title={'Campaign Duration'}>
        <TextContainer isLoading={props.isLoading} text={campaignDurationText} />
      </TextRow>
      <TextRow title={'Budget'}>
        <TextContainer isLoading={props.isLoading} text={budgetText} />
      </TextRow>
      <TextRow title={'Preferred Ad Declaration'}>
        <TextContainer isLoading={props.isLoading} text={preferredAddDeclarationText} />
      </TextRow>
    </BriefTextCard>
  );
});

export default CampaignDetailsCard;
