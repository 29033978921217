import React, { FC } from 'react';
import styles from './ContentApprovalEmptyContent.module.scss';
import { ReactComponent as CheckedIcon } from '../../../../../assets/img/icons/checked.svg';
import ContentBoardScreen from '../../../../../assets/img/ContentBoardScreen.png';
import { useMediaQuery } from 'react-responsive/src';
import cs from 'classnames';
import ButtonLightPurpleWhite from '../../../../../components/Buttons/ButtonLightPurpleWhite';
import ButtonWithPurpleRoundedIcon from '../../../../../components/Buttons/ButtonWithPurpleRoundedIcon';

type PropsType = {
  isArchive: boolean;
  onCreateBoardClick: () => void;
  rootClassName?: string;
  onWatchDemoClick: () => void;
};

const ContentApprovalEmptyContent: FC<PropsType> = props => {
  const isTablet: boolean = useMediaQuery({ query: '(max-width: 758px)' });

  return (
    <div className={cs(styles.root, props.rootClassName)}>
      <div className={styles.leftContent}>
        {props.isArchive ? (
          <h2 className={styles.heading}>You do not have any archived boards</h2>
        ) : (
          <>
            <h2 className={styles.heading}>Create a content board to achieve best visuals for your campaign</h2>
            <p className={styles.subHeading}>
              Say goodbye to shared drives. Otterfish allows you to collaborate, manage and store your content deliverables, all in one
              place.
            </p>
            <ul className={styles.textList}>
              <li className={styles.textListItem}>
                <CheckedIcon /> Manage all your content deliverables in one place
              </li>
              <li className={styles.textListItem}>
                <CheckedIcon /> Share with your agency or brand within click of a button
              </li>
              <li className={styles.textListItem}>
                <CheckedIcon /> No more emails. Invite and collaborate on your content deliverables in one place
              </li>
            </ul>
          </>
        )}
        <div className={styles.buttonsContainer}>
          {!props.isArchive ? (
            <ButtonWithPurpleRoundedIcon onClick={props.onCreateBoardClick} className={styles.purpleButton} size="medium" presetIcon="plus">
              New Content board
            </ButtonWithPurpleRoundedIcon>
          ) : null}
          {/* <ButtonLightPurpleWhite className={styles.whiteButton} size="large" designVersion="v2">*/}
          {/*  Learn more*/}
          {/* </ButtonLightPurpleWhite>*/}
        </div>
      </div>
      {!isTablet ? (
        <div className={styles.rightContent}>
          <img className={styles.image} src={ContentBoardScreen} alt="content board preview" />
          <ButtonLightPurpleWhite
            onClick={props.onWatchDemoClick}
            presetIcon="play"
            designVersion={'v2'}
            className={styles.watchDemoButton}
          >
            Watch demo
          </ButtonLightPurpleWhite>
        </div>
      ) : null}
    </div>
  );
};

export default ContentApprovalEmptyContent;
