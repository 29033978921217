import React from 'react';
import styles from './OrganizationsList.module.scss';
import OrganizationItem from '../OrganizationItem';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const OrganizationsList = ({ organizations, currentOrg }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const user = useSelector(state => state.auth.user);
  // const userUsage = useSelector(state => state.user.userUsage);
  // const canCreate = userUsage?.organizations - userUsage?.usage?.organizations > 0 && user.pricing_plan_id;

  const handleSwitch = org => {
    if (org) {
      localStorage.removeItem('OTTERFISH_CURRENT_ORGANIZATION');
      localStorage.setItem('OTTERFISH_CURRENT_ORGANIZATION', JSON.stringify(org));
      dispatch({
        type: 'SET_CURRENT_ORGANIZATION',
        payload: org,
      });
      history.push('/');
    }
  };

  return (
    <div className={styles.organizationsList}>
      {organizations?.length >= 1 &&
        organizations.map(org => (
          <OrganizationItem
            key={org.organization_id}
            org={org}
            selected={currentOrg}
            onClick={handleSwitch}
            handleConfig={() => history.push('/organization/details')}
          />
        ))}
      {/* <div*/}
      {/*  className={cs(styles.createItem, { [styles.disabled]: !canCreate })}*/}
      {/*  onClick={() => (canCreate ? history.push('/create-organization') : null)}*/}
      {/* >*/}
      {/*  <div className={styles.logo}>*/}
      {/*    <PlusSVG />*/}
      {/*  </div>*/}
      {/*  <div className={styles.text}>Create new</div>*/}
      {/* </div>*/}
    </div>
  );
};

export default OrganizationsList;
