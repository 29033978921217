import React, { FC, useEffect, useRef, useState } from 'react';
import cs from 'classnames';
import styles from './FormattedText.module.scss';

type FormattedTextProps = {
  text?: string;
  className?: string;
  buttonClassName?: string;
  withoutCollapse?: boolean;
};

const FormattedText: FC<FormattedTextProps> = ({ text, className, buttonClassName, withoutCollapse = false }) => {
  const [isVisibleExpand, setIsVisibleExpand] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(!withoutCollapse);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (wrapperRef.current && contentRef.current && !withoutCollapse) {
      const wrapperRect = wrapperRef.current?.getBoundingClientRect();
      const contentRect = contentRef.current?.getBoundingClientRect();

      if (contentRect?.height > wrapperRect?.height && wrapperRect?.height <= 80) {
        setIsVisibleExpand(true);
      } else {
        setIsVisibleExpand(false);
        setIsCollapsed(false);
      }
    }
  }, [text]);

  return (
    <>
      <div ref={wrapperRef} className={cs(styles.root, { [styles.collapsed]: isCollapsed })}>
        <div ref={contentRef} className={cs(className)} dangerouslySetInnerHTML={{ __html: text || '' }} />
      </div>
      {isVisibleExpand && (
        <span className={cs(styles.button, buttonClassName)} onClick={() => setIsCollapsed(!isCollapsed)}>
          {isCollapsed ? 'More' : 'Less'}
        </span>
      )}
    </>
  );
};

export default FormattedText;
