import React, { useRef } from 'react';
import { useMediaQuery } from 'react-responsive/src';
import useTooltip from '../../../../shared/hooks/fixedTooltip/useTooltip';
import styles from './Breadcrumb.module.scss';
import cs from 'classnames';
import DoneListSVG from '../../../../expert/components/IconsSvg/DoneListSVG';
import { ReactComponent as ArrowLeftIcon } from '../../../../assets/img/icons/calendar_arrow_right.svg';

const Breadcrumb = ({ onClick, item, index, isFirstItem = false, rootRef, emptyItem = false }) => {
  const rootRect = rootRef?.current?.getBoundingClientRect();
  const isDesktopLandscape = useMediaQuery({
    query: '(min-width: 992px) and (orientation: landscape)',
  });

  const breadCrumbWrapperRef = useRef(null);

  const { toolTip, onEnter, onLeave } = useTooltip(
    {
      top: rect => rect.y - (rootRect?.y || 0) + rect.height,
      left: rect => rect.x - (rootRect?.x || 0) + rect.width / 2,
    },
    {
      text: item?.name || '',
      className: styles.tooltip,
      wrapperRef: breadCrumbWrapperRef,
    }
  );

  return (
    <div
      className={cs(styles.breadcrumbWrapper, { [styles.empty]: emptyItem }, { [styles.mobileBreadcrumbsWrapper]: !isDesktopLandscape })}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
      onClick={onClick ? onClick(item?.callback, index) : null}
    >
      {isFirstItem && <DoneListSVG className={styles.iconContainer} />}
      {!isFirstItem && (
        <span className={styles.separator}>
          <ArrowLeftIcon className={styles.arrow} />
        </span>
      )}
      {!emptyItem && (
        <span ref={breadCrumbWrapperRef} className={styles.breadcrumb}>
          {item.name}
          {toolTip}
        </span>
      )}
    </div>
  );
};

export default Breadcrumb;
