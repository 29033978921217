import { useCallback, useMemo, useState } from 'react';
import { IAssetMedia } from '../../../../models/entities/asset/AssetMedia';
import { DELIVERABLE_SECTION_TYPES } from '../../../../models/enums/DeliverableSectionTypes';
import { ASSET_STATUSES } from '../../../../models/entities/asset/enums/AssetStatuses';
import { AssetStateType } from '../../../../models/entities/asset/AssetState';
import VideoConvertingPlaceholderImage from '../../../../assets/img/video-converting-placeholder.png';

export type AssetViewSettings = {
  currentAsset: AssetStateType | null;
  currentAssets: AssetStateType[];
  openedAssetId: AssetStateType['id'] | null;
  openedAssetIndex: number;
  setOpenedAssetId: (assetId: AssetStateType['id'] | null) => void;
  conceptMediaAssets: IAssetMedia[];
  contentMediaAssets: IAssetMedia[];
  currentMediaAssets: IAssetMedia[];
  currentMediaAsset: IAssetMedia | null;
};

type UseAssetsViewSettingsType = (conceptAssets: AssetStateType[], contentAssets: AssetStateType[]) => AssetViewSettings;

const mutateAssetToAssetView = (asset: AssetStateType) => {
  let url: string;
  let isVideo: boolean;
  let isVideoProcessing: boolean;

  if ('old_asset_id' in asset) {
    isVideo = /^video/g.test(asset.mime_type);
    isVideoProcessing = isVideo && asset.status === ASSET_STATUSES.PROCESSING;
    url = isVideoProcessing ? VideoConvertingPlaceholderImage : asset?.preview_location || asset?.location;
  } else {
    isVideo = /^video/g.test(asset.file.type);
    isVideoProcessing = isVideo && asset.status === ASSET_STATUSES.PROCESSING;
    url = isVideoProcessing ? VideoConvertingPlaceholderImage : asset.locationForPreview;
  }

  return {
    id: asset.id,
    url,
    isVideo,
    isVideoProcessing,
  };
};

const useAssetsViewSettings: UseAssetsViewSettingsType = (conceptAssets, contentAssets) => {
  const [openedAssetId, setOpenedAssetId] = useState<null | AssetStateType['id']>(null);
  const [openAssetType, setOpenAssetType] = useState<null | AssetStateType['type']>(null);

  const conceptMediaAssets = useMemo(() => conceptAssets.map(asset => mutateAssetToAssetView(asset)), [conceptAssets]);
  const contentMediaAssets = useMemo(() => contentAssets.map(asset => mutateAssetToAssetView(asset)), [contentAssets]);

  const currentAssets = useMemo(() => {
    if (!openAssetType) return [];
    return openAssetType === DELIVERABLE_SECTION_TYPES.CONTENT ? contentAssets : conceptAssets;
  }, [openAssetType, conceptAssets, contentAssets]);

  const currentMediaAssets = useMemo(() => {
    if (!openAssetType) return [];
    return openAssetType === DELIVERABLE_SECTION_TYPES.CONTENT ? contentMediaAssets : conceptMediaAssets;
  }, [openAssetType, conceptMediaAssets, contentMediaAssets]);

  const currentAsset = useMemo(() => currentAssets.find(asset => asset.id == openedAssetId) || null, [openedAssetId, currentAssets]);
  const currentMediaAsset = useMemo(() => (currentAsset ? mutateAssetToAssetView(currentAsset) : null), [currentAsset]);

  const openedAssetIndex = useMemo(() => currentAssets.findIndex(asset => asset.id == openedAssetId), [currentAssets, openedAssetId]);

  const clearCurrentAssetsState = () => {
    setOpenedAssetId(null);
    setOpenAssetType(null);
  };

  const onSetOpenedAssetId = useCallback(
    (assetId: AssetStateType['id'] | null) => {
      if (!(typeof assetId === 'string' || typeof assetId === 'number')) {
        clearCurrentAssetsState();
        return;
      }

      setOpenedAssetId(assetId);

      const allAssets = [...conceptAssets, ...contentAssets];

      const currentAsset = allAssets.find(asset => asset.id == assetId);

      if (!currentAsset) {
        clearCurrentAssetsState();
        return;
      }

      setOpenAssetType(currentAsset.type);
    },
    [conceptAssets, contentAssets]
  );

  return {
    conceptMediaAssets,
    contentMediaAssets,
    currentAssets,
    openedAssetId,
    openedAssetIndex,
    setOpenedAssetId: onSetOpenedAssetId,
    currentMediaAssets,
    currentMediaAsset,
    currentAsset,
  };
};

export default useAssetsViewSettings;
