import React, { useRef } from 'react';
import styles from './PostCarousel.module.scss';
import cs from 'classnames';
import ArrowLeftSVG from '../../../../expert/components/IconsSvg/ArrowLeftSVG';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/navigation/navigation.scss';
import './PostCarouselSliderCustomStyles.scss';

import SwiperCore, { Navigation } from 'swiper';
import SquareAsset from '../../../SquareAsset';
import { MEDIA_ORIENTATIONS } from '../../../../models/enums/ImageOrientations';

SwiperCore.use([Navigation]);

const PostCarousel = ({ assets, orientation = MEDIA_ORIENTATIONS.SQUARE }) => {
  const sliderPrevButtonRef = useRef(null);
  const sliderNextButtonRef = useRef(null);

  return (
    <div className={cs(styles.root, styles[orientation])}>
      <Swiper
        slidesPerView={1}
        navigation={{
          prevEl: sliderPrevButtonRef.current,
          nextEl: sliderNextButtonRef.current,
        }}
        onBeforeInit={swiper => {
          swiper.params.navigation.prevEl = sliderPrevButtonRef.current;
          swiper.params.navigation.nextEl = sliderNextButtonRef.current;
        }}
        className="PostCarouselSlider"
      >
        {assets.map((asset, idx) => (
          <SwiperSlide key={idx}>
            <div className={styles.slide}>
              {asset.isVideo && !asset.isVideoProcessing && (
                <SquareAsset className={styles.video} src={asset.url} type={'video'} controls />
              )}
              {(!asset.isVideo || (asset.isVideo && asset.isVideoProcessing)) && <img src={asset.url} alt="preview" />}
            </div>
          </SwiperSlide>
        ))}
        <div className={cs(styles.button, styles.prev, { [styles.hide]: assets.length < 2 })} ref={sliderPrevButtonRef}>
          <ArrowLeftSVG />
        </div>
        <div className={cs(styles.button, styles.next, { [styles.hide]: assets.length < 2 })} ref={sliderNextButtonRef}>
          <ArrowLeftSVG />
        </div>
      </Swiper>
    </div>
  );
};

export default PostCarousel;
