import React, { HTMLProps } from 'react';
import styles from './TextArea.module.scss';
import cs from 'classnames';

type PropsType = {
  max?: number;
  isValid?: boolean;
  className?: string;
  error?: string;
} & Omit<HTMLProps<HTMLTextAreaElement>, 'maxLength' | 'className'>;

const TextArea = ({ max, isValid = true, className, error, ...rest }: PropsType) => {
  return (
    <div className={cs(styles.root, { [styles.error]: !isValid || error }, className)}>
      <textarea className={styles.textarea} maxLength={max} {...rest} />
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

export default TextArea;
