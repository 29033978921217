import React, { FC, memo } from 'react';
import { FormikProps } from 'formik';
import { areEqualFormikState } from '../../../../../../../../shared/utils/typescriptHelpers';
import RadioButtons from '../../../../../../../CampaignBrief/components/RadioButtons';

const campaignObjectiveValues = [
  {
    key: 'awareness',
    title: 'Awareness',
    description:
      'Generate interest in your product or service. Increasing brand awareness is about telling people what makes your brand or product valuable.',
  },
  {
    key: 'engagement',
    title: 'Engagement',
    description:
      'Reach people more likely to engage with your post. Engagement includes likes, comments and shares but can also include offers claimed from your Page',
  },
  {
    key: 'sales_leads',
    title: 'Sales/Leads',
    description:
      'Collect leads for your business. Create ads that collect info from people interested in your product, such as sign-ups for newsletters, or have people give your business a call.',
  },
];

type PropsType = {
  formik: FormikProps<any>;
};

const propsAreEqual: Parameters<typeof memo<FC<PropsType>>>[1] = (prevProps, nextProps) => {
  return areEqualFormikState(['campaignObjective'], prevProps.formik, nextProps.formik);
};

const Index = memo(({ formik }: PropsType) => {
  const setCampaignObjective = item => {
    if (formik.values.campaignObjective.includes(item.key)) {
      const res = formik.values.campaignObjective.filter(value => value != item.key);
      formik.setFieldValue('campaignObjective', [...res]);
    } else {
      formik.setFieldValue('campaignObjective', [...formik.values.campaignObjective, item.key]);
    }
  };

  return (
    <div>
      <h3>What is your campaign Objective?</h3>
      {/* @ts-ignore */}
      <RadioButtons items={campaignObjectiveValues} activeItems={formik.values.campaignObjective} setItem={setCampaignObjective} />
    </div>
  );
}, propsAreEqual);

export default Index;
