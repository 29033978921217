import {ResultAssetListItemType} from './types';
import {MEDIA_FILE_TYPES} from '../../../../models/entities/file/enums/MediaFileTypes';
import {IResultAsset} from "../../../../models/entities/asset/ResultAsset";
import { v4 as uuidv4 } from 'uuid';

export const isImage = (type: string) => /^image/g.test(type);

function getType(type: string): MEDIA_FILE_TYPES {
  return isImage(type) ? MEDIA_FILE_TYPES.IMAGE : MEDIA_FILE_TYPES.VIDEO;
}

export function resultAssetToItem(asset: IResultAsset, file: File): ResultAssetListItemType {
  return {file: file, filename: asset.filename, type: getType(asset.mime_type), deliverableId: asset.deliverable_id, status: "uploaded", id: asset.id,}
}

export function createResultItemByFile(file: File): ResultAssetListItemType {
  return {file: file, filename: file.name, type: getType(file.type), deliverableId: null, status: "added", id: uuidv4()};
}
