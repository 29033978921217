import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import useCreateBoardFormik from '../CreateBoardWrapper/CreateBoard/useCreateBoardFormik';
import useBoardBriefFormik from '../CreateBoardWrapper/CreateBoardBrief/useBoardBriefFormik';
import useBriefVisualAssets from '../CreateBoardWrapper/CreateBoardBrief/useBriefVisualAssets';
import { IDeliverableFormData } from '../../ContentBoard/components/AddDeliverablesModal/hooks/useDeliverablesFormData';
import CreateBoard from '../CreateBoardWrapper/CreateBoard';
import CreateBoardBrief from '../CreateBoardWrapper/CreateBoardBrief';
import useSetupBoardFilledForm from '../hooks/useSetupBoardFilledForm';
import useBoardFormHandlers from '../hooks/useBoardFormHandlers';

const DuplicateBoardWrapper: FC = () => {
  const forms = useSetupBoardFilledForm();
  const createBoardFormik = useCreateBoardFormik();
  const boardBriefFormik = useBoardBriefFormik();
  const { briefFormAssets } = useBriefVisualAssets();
  const [initialized, setInitialized] = useState(false);

  const deliverablesSavedRef = useRef<IDeliverableFormData[]>([]);
  const boardFormHandlers = useBoardFormHandlers(createBoardFormik, boardBriefFormik, briefFormAssets);

  const initializeForms = async () => {
    if (!forms || initialized) return;

    createBoardFormik.setValues({ ...forms.boardFormikValues });

    deliverablesSavedRef.current = forms.boardFormikValues.deliverables;

    forms.briefFormAssets.length && briefFormAssets.setValue(forms.briefFormAssets);

    if (forms.briefFormikValues) {
      boardBriefFormik.setValues(forms.briefFormikValues);
    }

    setInitialized(true);
  };

  useEffect(() => {
    initializeForms();
  }, [forms, initialized]);

  const saveDeliverablesState = useCallback(
    (data: IDeliverableFormData[]) => {
      deliverablesSavedRef.current = data;
    },
    [deliverablesSavedRef]
  );

  return (
    <>
      {boardFormHandlers.page === 'board' ? (
        <CreateBoard
          formik={createBoardFormik}
          openCreateBriefPage={boardFormHandlers.onCreateBriefPage}
          savedDeliverables={deliverablesSavedRef}
          saveDeliverablesState={saveDeliverablesState}
          briefVisualAssets={briefFormAssets.value}
          onEditBrief={boardFormHandlers.onEditBrief}
          onDeleteBrief={boardFormHandlers.onDeleteBrief}
          isLoading={!initialized}
          isDuplicate
        />
      ) : (
        <CreateBoardBrief
          title="Add Otterfish brief to your Content Board"
          description="Build a brief for the Campaign, then invite collaborators to finalize the requirements needed"
          buttonTitle="Add brief to board"
          formik={boardBriefFormik}
          visualAssets={briefFormAssets}
          editedState={!!Object.keys(createBoardFormik.touched).length}
          onCancel={boardFormHandlers.briefFormCancel}
          onSubmit={boardFormHandlers.briefFormSubmit}
          withConfirmCancel
        />
      )}
    </>
  );
};

export default DuplicateBoardWrapper;
