import React, { useMemo } from 'react';
import lodash from 'lodash';

const DoneListSVG = ({ className }) => {
  const id = useMemo(() => lodash.uniqueId('clip_'), []);

  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath={`url(#${id})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0H24V17H21C18.7909 17 17 18.7908 17 21V24H0V0ZM19 24H24V19H21C19.8955 19 19 19.8954 19 21V24ZM18.7071 7.69287C19.0977 8.08339 19.0977 8.71656 18.7071 9.10708L11.5071 16.3071C11.1166 16.6976 10.4834 16.6976 10.0929 16.3071L6.4929 12.7071C6.10238 12.3166 6.10238 11.6834 6.4929 11.2929C6.88342 10.9023 7.51659 10.9023 7.90711 11.2929L10.8 14.1858L17.2929 7.69287C17.6834 7.30234 18.3166 7.30234 18.7071 7.69287Z"
          fill="#C9CCDA"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="24" height="24" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DoneListSVG;
