import React, { ButtonHTMLAttributes, ComponentProps, FC, forwardRef, HTMLProps, ReactElement, ReactNode, SVGProps } from 'react';
import styles from './ButtonCustom.module.scss';
import cs from 'classnames';
import Loader from '../../Loader';
import PlusSVG from '../../IconsSvg/PlusSVG';

type PropsType = {
  children?: JSX.Element | ReactElement | ReactNode | string | null;
  disabled?: boolean;
  loading?: boolean;
  loaderProps?: ComponentProps<typeof Loader> & { loaderClassName: string };
  mobileSmall?: boolean;
  shortTitle?: string;
  className?: string;
  Icon?: FC<{ size?: number } & unknown> | FC<SVGProps<SVGSVGElement>> | null;
  iconClassName?: string;
  SecondIcon?: typeof PlusSVG | FC<SVGProps<SVGSVGElement>>;
  onSecondIconHandler?: () => void;

  outline?: boolean;
  danger?: boolean;
  warning?: boolean;
  primary?: boolean;
  success?: boolean;
  secondary?: boolean;
  purple?: boolean;
  parentWidth?: boolean;
  iconSize?: number;
  clearBg?: boolean;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
} & Omit<HTMLProps<HTMLButtonElement>, 'type' | 'children' | 'className' | 'disabled' | 'ref'>;

const ButtonCustom = forwardRef<HTMLButtonElement | null, PropsType>(
  (
    {
      children,
      disabled,
      loading,
      loaderProps = {},
      mobileSmall,
      shortTitle,
      className,
      Icon,
      SecondIcon,
      onSecondIconHandler,
      iconClassName,

      outline,
      danger,
      warning,
      primary,
      success,
      secondary,
      purple,
      parentWidth,
      iconSize,
      clearBg,
      type = 'button',
      ...props
    },
    ref
  ) => {
    const { loaderClassName, ...otherLoaderProps } = loaderProps;

    let propClassName;
    if (success) propClassName = styles.success;
    else if (warning) propClassName = styles.warning;
    else if (danger) propClassName = styles.danger;
    else if (primary) propClassName = styles.primary;
    else if (secondary) propClassName = styles.secondary;
    else if (purple) propClassName = styles.purple;
    propClassName = cs(propClassName, outline ? styles.outline : styles.regular);

    return (
      <button
        type={type}
        className={cs(
          styles.btn,
          propClassName,
          {
            [styles.mobileSmall]: mobileSmall,
            [styles.parentWidth]: parentWidth,
            [styles.clearBg]: clearBg,
            [styles.btnShortTitle]: shortTitle,
          },
          className
        )}
        disabled={disabled || loading}
        {...props}
        ref={ref}
      >
        <span className={styles.iconBlock}>
          {loading ? (
            <Loader mainColor={'#f8f8fb'} className={cs(styles.loader, loaderClassName)} {...otherLoaderProps} />
          ) : Icon ? (
            <Icon className={iconClassName} size={iconSize ? iconSize : 20} />
          ) : null}
          {SecondIcon ? (
            <span
              onClick={e => {
                e.stopPropagation();
                onSecondIconHandler && onSecondIconHandler();
              }}
            >
              <SecondIcon size={iconSize ? iconSize : 20} />
            </span>
          ) : null}
        </span>

        <span className={styles.text}>{children}</span>
        {shortTitle && <span className={styles.shortTitle}>{shortTitle}</span>}
      </button>
    );
  }
);

export default ButtonCustom;
