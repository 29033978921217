import React, {memo, useCallback, useMemo, useState} from 'react';
import styles from './ResultsList.module.scss';
import {IResultAsset} from "../../../../models/entities/asset/ResultAsset";
import DeliverableCard from "../DeliverableCard";
import ResultVisualCard from "../ResultVisualCard";
import AssetsList from "../AssetsList";
import AssetModal from "../AssetModal";

type PropsType = {
  assets: IResultAsset[];
};


const ResultsList = memo<PropsType>(function Component(props) {
  const [openAsset, setOpenAsset] = useState<null | IResultAsset>(null);

  const assetsMapping = useMemo(() => {
    const mapping: Record<string, IResultAsset[]> = {};

    for (const asset of props.assets) {
      const deliverableId = String(asset.deliverable_id)
      const arr = mapping[deliverableId];

      if (!arr) {
        mapping[deliverableId] = [asset];
        continue;
      }

      mapping[deliverableId].push(asset);
    }

    return mapping;
  }, [props.assets]);

  const onHoverClick = useCallback((asset: IResultAsset) => setOpenAsset(asset), []);

  return (
    <>
      <div className={styles.root}>
        {Object.entries(assetsMapping).map(([key, assets]) => (
          <DeliverableCard
            key={key}
            platform={assets[0].deliverable_platform}
            placement={assets[0].deliverable_placement}
            deliverableName={assets[0].deliverable_name}
            content={
              <AssetsList>
                {assets.map(asset => (
                  <ResultVisualCard
                    key={asset.id}
                    asset={asset}
                    onHoverClick={onHoverClick}
                  />
                ))}
              </AssetsList>
            }
          />
        ))}
      </div>
      <AssetModal
        openAsset={openAsset}
        assetsMapping={assetsMapping}
        assets={props.assets}
        setOpenAsset={setOpenAsset}
      />
    </>
  )
});

export default ResultsList;
