import React, {FC} from 'react';

type PropsType = {
  className?: string
};

const VideoPlaceHolderSVG: FC<PropsType> = props => {
  return (
    <svg className={props.className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
         fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M5 4.25C2.92893 4.25 1.25 5.92893 1.25 8V16C1.25 18.0711 2.92893 19.75 5 19.75H13C15.0711 19.75 16.75 18.0711 16.75 16V15.2083L19.1039 17.1423L19.1042 17.1426C20.4058 18.2107 22.368 17.2896 22.37 15.6021V15.6015L22.382 8.25306V8.25255C22.3836 6.56724 20.4245 5.63496 19.118 6.70443L19.1179 6.7045L16.75 8.6424V8C16.75 5.92893 15.0711 4.25 13 4.25H5ZM16.75 10.5798V13.2678C16.7588 13.2744 16.7675 13.2813 16.7761 13.2884L20.0558 15.9831L20.0561 15.9833L20.0561 15.9834C20.3824 16.2507 20.8696 16.0181 20.87 15.6001L20.87 15.5996L20.882 8.25113C20.8824 7.83047 20.3935 7.59875 20.0681 7.86518L20.068 7.86524L16.775 10.5602C16.7668 10.567 16.7584 10.5735 16.75 10.5798ZM2.75 8C2.75 6.75736 3.75736 5.75 5 5.75H13C14.2426 5.75 15.25 6.75736 15.25 8V16C15.25 17.2426 14.2426 18.25 13 18.25H5C3.75736 18.25 2.75 17.2426 2.75 16V8Z"
            fill="#4E3B8B"/>
    </svg>
  )
};

export default VideoPlaceHolderSVG;
