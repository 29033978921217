import React, { FC, ReactElement } from 'react';
import styles from './LoadingContainer.module.scss';
import cs from 'classnames';

type LoadingContainerProps = {
  children?: ReactElement;
  width?: number | string;
  height?: number | string;
  borderRadius?: string;
  backgroundColor?: string;
  margin?: number | string;
  animationDelay?: string;
  className?: string;
  maxWidth?: string | number;
  minWidth?: string | number;
};

const LoadingContainer: FC<LoadingContainerProps> = ({
  children,
  width,
  height,
  borderRadius,
  backgroundColor,
  margin,
  animationDelay,
  className,
  maxWidth,
  minWidth,
}) => {
  return (
    <div
      className={cs(styles.loading, className)}
      style={{
        width: width || '100%',
        maxWidth: maxWidth || 'auto',
        minWidth: minWidth || 'auto',
        height: height || '100%',
        borderRadius: borderRadius || '100px',
        backgroundColor: backgroundColor || '#e3e6eb',
        margin: margin || '0px',
        animationDelay: animationDelay || '0s',
      }}
    >
      {children}
    </div>
  );
};

export default LoadingContainer;
