import React, { FC, useCallback, useMemo, useState } from 'react';
import styles from './VisualsList.module.scss';
import DraggableContext from '../../../../../../components/DnD/DraggableContext';
import CustomSortableContext from '../../../../../../components/DnD/CustomSortableContext';
import { DragEndEvent, DraggableAttributes, DragStartEvent } from '@dnd-kit/core';
import SortableItem from '../../../../../../components/DnD/SortableItem';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import AssetDnDHandler from '../../../../../../components/DnD/handlers/AssetDnDHandler';
import VisualCard from '../VisualCard';

type VisualsListProps = {
  onReorder: (...unknown) => void | unknown;
  assets: (object & { id: string | number })[];
  withDrag: boolean;
  children: ReturnType<typeof VisualCard>[];
};

const VisualsDraggableListWrapper: FC<VisualsListProps> = ({ withDrag, onReorder, assets, children }) => {
  const [activeAssetId, setActiveAssetId] = useState<typeof assets[number]['id'] | null>(null);
  const renderHandler = useCallback(
    (attributes: DraggableAttributes, setNodeRef: (node: HTMLElement | null) => void, listeners?: SyntheticListenerMap) => (
      <AssetDnDHandler attributes={attributes} listeners={listeners} setNodeRef={setNodeRef} />
    ),
    []
  );

  const renderDraggableVisualCards = useCallback(() => {
    return React.Children.map(children, child => {
      if (!child) return null;
      const currentAssetId = child.props.asset.id;
      return (
        <SortableItem
          key={currentAssetId}
          withHandler={true}
          defaultClass={styles.dragWrapper}
          renderHandler={renderHandler}
          id={currentAssetId}
          draggingClass={styles.placeHolder}
        >
          {React.cloneElement(child, { ...child.props })}
        </SortableItem>
      );
    });
  }, [children, renderHandler]);

  const renderActiveVisual = useMemo(() => {
    let activeChild;
    React.Children.forEach(children, child => {
      if (!child) return null;

      if (child.props.asset.id === activeAssetId) {
        activeChild = React.cloneElement(child, { ...child.props, isEffectsDisabled: true });
      }
    });
    return activeChild || null;
  }, [children, activeAssetId]);

  const dragStartHandler = useCallback((e: DragStartEvent) => {
    const activeAssetId = e.active.id;
    if (activeAssetId) {
      setActiveAssetId(activeAssetId);
    }
  }, []);

  const dragEndHandler = useCallback(
    ({ active, over }: DragEndEvent) => {
      setActiveAssetId(null);

      if (active.id === over?.id) return;
      onReorder(active.id, over?.id);
    },
    [onReorder]
  );

  if (!assets.length) return null;

  return withDrag ? (
    <DraggableContext
      withOverlay={true}
      renderOverlayChildren={() => renderActiveVisual}
      mouseSensorOptions={{ activationConstraint: { distance: 25 } }}
      onDragStart={dragStartHandler}
      onDragEnd={dragEndHandler}
    >
      <CustomSortableContext items={assets}>{renderDraggableVisualCards()}</CustomSortableContext>
    </DraggableContext>
  ) : (
    <>{children}</>
  );
};

export default VisualsDraggableListWrapper;
