import { instance, instanceWithForm } from '../shared/utils/api.config.creator';
import ErrorHandler from '../models/entities/error/ErrorHandler';
import { IBoardDTO } from '../models/entities/board/dto/Board';
import { IBoardCollaboratorDTO } from '../models/entities/board/dto/BoardCollaborator';
import { IUpdateBoardCollaboratorDTO } from '../models/entities/board/dto/UpdateBoardCollaborator';
import { IInviteBoardCollaboratorDTO } from '../models/entities/board/dto/InviteBoardCollaborator';
import { IBoardInvitedCollaboratorDTO } from '../models/entities/board/dto/BoardInvitedCollaborator';
import { DeliverablesSortDTOType } from '../models/entities/board/dto/DeliverablesSort';
import { IDeliverableDTO } from '../models/entities/deliverable/dto/Deliverable';
import { UpdateDeliverableItemDataType } from '../actions/deliverablesActions/types';
import { AxiosResponse } from 'axios';
import { OptionalObjectType } from '../models/utils/OptionalObjectType';
import { DELIVERABLE_SECTION_TYPES } from '../models/enums/DeliverableSectionTypes';
import { IGetDeliverableSectionDTO } from '../models/entities/deliverableSections/dto/DeliverableSection';
import { IAsset } from '../models/entities/asset/Asset';
import { ICreateDeliverableSectionDataDTO } from '../models/entities/deliverableSections/dto/CreateDeliverableSectionData';
import { ICreatedAsset } from '../models/entities/asset/createdAsset';
import { ICreateDeliverableFromBoardDTO } from '../models/entities/deliverable/dto/CreateDeliverableFromBoard';
import { IBoardAssetDTO } from '../models/entities/board/dto/BoardAsset';
import { BOARD_STATUTES_TYPES } from '../models/entities/board/enums/boardStatuses';
import { ICreateBoardDTO } from '../models/entities/board/dto/CreateBoard';
import { IBoardListItemDTO } from '../models/entities/board/dto/BoardListItem';
import { IDeliverableOptionDTO } from '../models/entities/board/dto/DeliverableOption';
import { IResultAssetDTO } from '../models/entities/asset/dto/ResultAsset';
import { PLATFORMS } from '../models/enums/Platforms';
import { PLACEMENTS } from '../models/enums/Placements';
import { IUpdateResultAssetDTO } from '../models/entities/asset/dto/updateResultAsset';

export type ContentApprovalFiltersType = {
  date_start?: string | null;
  date_end?: string | null;
  page?: number;
  search_filter?: string;
  status?: BOARD_STATUTES_TYPES;
  sort?: string;
  is_archived?: boolean;
  approver_id?: number | string;
};

export const getContentBoards = async (
  organizationId: string | number,
  params: ContentApprovalFiltersType
): Promise<{ data: IBoardListItemDTO[]; total_count: number }> => {
  const response = await instance.get<{ data: IBoardListItemDTO[]; total_count: number }>(
    `/organizations/${organizationId}/content-boards`,
    {
      params,
    }
  );
  return response.data;
};

export const getContentBoard = async (
  organizationId: string | number,
  boardId: string | number
): Promise<{
  data: IBoardDTO[];
}> => {
  const response = await instance.get<{
    data: IBoardDTO[];
  }>(`/organizations/${organizationId}/content-boards/${boardId}`);
  return response.data;
};

export const getContentBoardShareLink = async (
  organizationId: string | number,
  boardId: string | number
): Promise<{
  data: {
    hash: string;
  }[];
}> => {
  const response = await instance.post<{
    data: {
      hash: string;
    }[];
  }>(`/organizations/${organizationId}/content-boards/${boardId}/share`);
  return response.data;
};

export const archiveBoard = async (
  organizationId: string | number,
  boardId: string | number
): Promise<{
  data: [];
}> => {
  const response = await instance.post<{
    data: [];
  }>(`/organizations/${organizationId}/content-boards/${boardId}/archive`);
  return response.data;
};

export const unzipBoard = async (organizationId: string | number, boardId: string | number): Promise<{ data: [] }> => {
  const response = await instance.delete<{
    data: [];
  }>(`/organizations/${organizationId}/content-boards/${boardId}/archive`);
  return response.data;
};

export const getBoardCollaborators = async (
  organizationId: string | number,
  boardId: string | number,
  params?: object
): Promise<{ data: IBoardCollaboratorDTO[] }> => {
  const response = await instance.get<{ data: IBoardCollaboratorDTO[] }>(
    `/organizations/${organizationId}/content-boards/${boardId}/collaborators`,
    {
      params,
    }
  );
  return response.data;
};

export const updateBoardCollaborators = async (
  organizationId: string | number,
  boardId: string | number,
  params: IUpdateBoardCollaboratorDTO
): Promise<{ data: [] }> => {
  const response = await instance.patch<{
    data: [];
  }>(`/organizations/${organizationId}/content-boards/${boardId}/collaborators`, params);
  return response.data;
};

export const deleteBoardCollaborator = async (
  organizationId: string | number,
  boardId: string | number,
  collaboratorOrganizationId: string | number
): Promise<{ data: [] }> => {
  const response = await instance.delete<{ data: [] }>(
    `/organizations/${organizationId}/content-boards/${boardId}/collaborators/${collaboratorOrganizationId}`
  );
  return response.data;
};

export const deleteInvitedBoardCollaborator = async (
  organizationId: string | number,
  boardId: string | number,
  invitedId: string | number
): Promise<{ data: [] }> => {
  const response = await instance.delete<{
    data: [];
  }>(`/organizations/${organizationId}/content-boards/${boardId}/invites/${invitedId}`);
  return response.data;
};

export const inviteBoardCollaborator = async (
  organizationId: string | number,
  boardId: string | number,
  inviteData: IInviteBoardCollaboratorDTO
): Promise<{ data: { id: string }[] }> => {
  const response = await instance.post<{ data: { id: string }[] }>(
    `/organizations/${organizationId}/content-boards/${boardId}/invites`,
    inviteData
  );
  return response.data;
};

export const getInvitedBoardCollaborators = async (
  organizationId: string | number,
  boardId: string | number
): Promise<{ data: IBoardInvitedCollaboratorDTO[] }> => {
  const response = await instance.get<{ data: IBoardInvitedCollaboratorDTO[] }>(
    `/organizations/${organizationId}/content-boards/${boardId}/invites?accepted_at=null`
  );
  return response.data;
};

export const createBoardDeliverable = async (
  organizationId: string | number,
  boardId: string | number,
  deliverableData: {}
): Promise<{ data: [] }> => {
  const response = await instance.post<{ data: [] }>(
    `/organizations/${organizationId}/content-boards/${boardId}/deliverables`,
    deliverableData
  );
  return response.data;
};

export const getBoardDeliverables = async (
  organizationId: string | number,
  boardId: string | number,
  params: DeliverablesSortDTOType
): Promise<{ data: IDeliverableDTO[]; total_count: number; total_approved: number }> => {
  const response = await instance.get<{ data: IDeliverableDTO[]; total_count: number; total_approved: number }>(
    `/organizations/${organizationId}/content-boards/${boardId}/deliverables`,
    { params }
  );
  return response.data;
};

export const getBoardDeliverableItem = async (
  organizationId: string | number,
  boardId: string | number,
  deliverableId: string | number
): Promise<{ data: IDeliverableDTO[] }> => {
  const response = await instance.get<{ data: IDeliverableDTO[] }>(
    `/organizations/${organizationId}/content-boards/${boardId}/deliverables/${deliverableId}`
  );
  return response.data;
};

export const updateDeliverableItem = async (
  organizationId: string | number,
  boardId: string | number,
  deliverableId: string | number,
  data: UpdateDeliverableItemDataType
): Promise<
  AxiosResponse<{
    data: IDeliverableDTO[];
  }>
> => {
  return await instance.patch<{ data: IDeliverableDTO[] }>(
    `/organizations/${organizationId}/content-boards/${boardId}/deliverables/${deliverableId}`,
    data
  );
};

export const updateContentBoard = async (
  organizationId: string | number,
  boardId: string | number,
  data: OptionalObjectType<IBoardDTO> | FormData
): Promise<{ data: IBoardDTO[] } | void> => {
  try {
    let currentInstance = instance;
    if (data instanceof FormData) {
      currentInstance = instanceWithForm;
    }
    const response = await currentInstance.patch<{
      data: IBoardDTO[];
    }>(`/organizations/${organizationId}/content-boards/${boardId}`, data);
    return response.data;
  } catch (e) {
    if (e instanceof Error) {
      throw new ErrorHandler(e).getError();
    }
  }
};

export const createBoard = async (
  organizationId: string | number,
  data: ICreateBoardDTO
): Promise<{
  data: IBoardDTO[];
} | void> => {
  try {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }

    const response = await instanceWithForm.post<{
      data: IBoardDTO[];
    }>(`/organizations/${organizationId}/content-boards`, formData);
    return response.data;
  } catch (e) {
    if (e instanceof Error) {
      throw new ErrorHandler(e).getError();
    }
  }
};

export const getBoardDeliverableItemSection = async (
  organizationId: string | number,
  boardId: string | number,
  deliverableId: string | number,
  type: DELIVERABLE_SECTION_TYPES
): Promise<{ data: IGetDeliverableSectionDTO[] }> => {
  const response = await instance.get<{ data: IGetDeliverableSectionDTO[] }>(
    `/organizations/${organizationId}/content-boards/${boardId}/deliverables/${deliverableId}/versions/latest`,
    {
      params: { type },
    }
  );
  return response.data;
};

export const getBoardDeliverableItemSectionAssets = async (
  organizationId: string | number,
  boardId: string | number,
  deliverableId: string | number,
  versionId: string | number
): Promise<{ data: IAsset[] }> => {
  const response = await instance.get<{ data: IAsset[] }>(
    `/organizations/${organizationId}/content-boards/${boardId}/deliverables/${deliverableId}/versions/${versionId}/assets`
  );
  return response.data;
};

export const getBoardDeliverableItemSectionAsset = async (
  organizationId: string | number,
  boardId: string | number,
  deliverableId: string | number,
  versionId: string | number,
  assetId: string | number
): Promise<{
  data: (
    | IAsset
    | (IAsset & {
        content_board_deliverable_id?: string;
        creator_id?: string;
        updated_at?: string;
        updater_id?: string;
      })
  )[];
}> => {
  const response = await instance.get(
    `/organizations/${organizationId}/content-boards/${boardId}/deliverables/${deliverableId}/versions/${versionId}/assets/${assetId}`
  );
  return response.data;
};

export const createDeliverableSection = async (
  organizationId: string | number,
  boardId: string | number,
  deliverableId: string | number,
  data: ICreateDeliverableSectionDataDTO
): Promise<{ data: IGetDeliverableSectionDTO[] }> => {
  const response = await instance.post<{ data: IGetDeliverableSectionDTO[] }>(
    `/organizations/${organizationId}/content-boards/${boardId}/deliverables/${deliverableId}/versions`,
    data
  );
  return response.data;
};

export const updateDeliverableSection = async (
  organizationId: string | number,
  boardId: string | number,
  deliverableId: string | number,
  versionId: string | number,
  data: ICreateDeliverableSectionDataDTO
): Promise<{ data: IGetDeliverableSectionDTO[] }> => {
  const response = await instance.patch<{ data: IGetDeliverableSectionDTO[] }>(
    `/organizations/${organizationId}/content-boards/${boardId}/deliverables/${deliverableId}/versions/${versionId}`,
    data
  );
  return response.data;
};

export const deleteDeliverableSection = async (
  organizationId: string | number,
  boardId: string | number,
  deliverableId: string | number,
  type: DELIVERABLE_SECTION_TYPES
): Promise<{ data: [] }> => {
  const response = await instance.delete<{ data: [] }>(
    `/organizations/${organizationId}/content-boards/${boardId}/deliverables/${deliverableId}/versions`,
    { params: { type } }
  );
  return response.data;
};

export const addDeliverableAssets = async (
  organizationId: string | number,
  boardId: string | number,
  deliverableId: string | number,
  versionId: string | number,
  assetsArray: ICreatedAsset[]
): Promise<{ data: IAsset[] } | void> => {
  try {
    const formData = new FormData();
    const captions = {};
    const orders = {};

    for (let i = 0; i < assetsArray.length; i++) {
      const asset = assetsArray[i];
      const newFileName = i + '_' + asset.file.name;
      formData.append('files', asset.file, newFileName);
      if (asset.caption) {
        captions[newFileName] = asset.caption;
      }
      orders[newFileName] = asset.display_order;
    }

    formData.append('captions', JSON.stringify(captions));
    formData.append('display_orders', JSON.stringify(orders));

    const response = await instanceWithForm.post<{ data: IAsset[] }>(
      `/organizations/${organizationId}/content-boards/${boardId}/deliverables/${deliverableId}/versions/${versionId}/assets`,
      formData
    );
    return response.data;
  } catch (e) {
    if (e instanceof Error) {
      throw new ErrorHandler(e).getError();
    }
  }
};

type UpdateAssetData = {
  caption: string | null;
  file: File | null;
  display_order: number | null;
};

export const updateDeliverableAsset = async (
  organizationId: string | number,
  boardId: string | number,
  deliverableId: string | number,
  versionId: string | number,
  assetId: string | number,
  data: UpdateAssetData
): Promise<{ data: IAsset[] } | void> => {
  try {
    const formData = new FormData();
    if (data.file) {
      formData.append('file', data.file);
    }
    formData.append('caption', data.caption || ' ');
    // @ts-ignore
    formData.append('display_order', data.display_order);

    const response = await instanceWithForm.patch<{ data: IAsset[] }>(
      `/organizations/${organizationId}/content-boards/${boardId}/deliverables/${deliverableId}/versions/${versionId}/assets/${assetId}`,
      formData
    );
    return response.data;
  } catch (e) {
    if (e instanceof Error) {
      throw new ErrorHandler(e).getError();
    }
  }
};

export const deleteDeliverableAsset = async (
  organizationId: string | number,
  boardId: string | number,
  deliverableId: string | number,
  versionId: string | number,
  assetId: string | number
): Promise<{ data: [] }> => {
  const response = await instance.delete<{ data: [] }>(
    `/organizations/${organizationId}/content-boards/${boardId}/deliverables/${deliverableId}/versions/${versionId}/assets/${assetId}`
  );
  return response.data;
};

export const shareDeliverableSection = async (
  organizationId: string | number,
  boardId: string | number,
  deliverableId: string | number,
  versionId: string | number
): Promise<{ data: [] }> => {
  const response = await instance.post<{ data: [] }>(
    `/organizations/${organizationId}/content-boards/${boardId}/deliverables/${deliverableId}/versions/${versionId}/share`
  );
  return response.data;
};

export const approveDeliverableSection = async (
  organizationId: string | number,
  boardId: string | number,
  deliverableId: string | number,
  versionId: string | number
): Promise<{ data: [] }> => {
  const response = await instance.post<{ data: [] }>(
    `/organizations/${organizationId}/content-boards/${boardId}/deliverables/${deliverableId}/versions/${versionId}/approve`
  );
  return response.data;
};

export const sendEditNotification = async (
  organizationId: string | number,
  boardId: string | number,
  deliverableId: string | number,
  versionId: string | number
): Promise<{ data: [] }> => {
  const response = await instance.post<{ data: [] }>(
    `/organizations/${organizationId}/content-boards/${boardId}/deliverables/${deliverableId}/versions/${versionId}/send-edit-notification`
  );
  return response.data;
};

export const createDeliverables = async (
  organizationId: string | number,
  boardId: string | number,
  data: ICreateDeliverableFromBoardDTO[]
): Promise<{ data: IDeliverableDTO[] }> => {
  const response = await instance.post<{ data: IDeliverableDTO[] }>(
    `/organizations/${organizationId}/content-boards/${boardId}/deliverables`,
    data
  );
  return response.data;
};

export const getBoardDeliverablesAssets = async (organizationId: string | number, boardId: string | number): Promise<IBoardAssetDTO[]> => {
  const response = await instance.get<IBoardAssetDTO[]>(`/organizations/${organizationId}/content-boards/${boardId}/assets`);
  return response.data;
};

export const deleteDeliverableItem = async (
  organizationId: string | number,
  boardId: string | number,
  deliverableId: string | number
): Promise<{ data: [] }> => {
  const response = await instance.delete<{ data: [] }>(
    `/organizations/${organizationId}/content-boards/${boardId}/deliverables/${deliverableId}`
  );
  return response.data;
};

export const duplicateDeliverableItem = async (
  organizationId: string | number,
  boardId: string | number,
  deliverableId: string | number
): Promise<{ data: IDeliverableDTO[] }> => {
  const response = await instance.post<{ data: IDeliverableDTO[] }>(
    `/organizations/${organizationId}/content-boards/${boardId}/deliverables/${deliverableId}/duplicate`
  );
  return response.data;
};

export const duplicateContentBoard = async (organizationId: string | number, boardId: string | number): Promise<IBoardDTO> => {
  const response = await instance.post<{
    data: [IBoardDTO[]];
  }>(`/organizations/${organizationId}/content-boards/${boardId}/duplicate`);

  return response.data.data[0][0];
};

export const deleteContentBoard = async (
  organizationId: string | number,
  boardId: string | number
): Promise<{
  data: [];
}> => {
  const response = await instance.delete<{ data: [] }>(`/organizations/${organizationId}/content-boards/${boardId}`);

  return response.data;
};

export const generateDeliverableConceptWithAI = async (organizationId, boardId, deliverableId) => {
  const response = await instance.get(
    `/organizations/${organizationId}/content-boards/${boardId}/deliverables/${deliverableId}/generate-content?type=concept`
  );
  return response.data;
};

export const generateDeliverableContentWithAI = async (organizationId, boardId, deliverableId) => {
  const response = await instance.get(
    `/organizations/${organizationId}/content-boards/${boardId}/deliverables/${deliverableId}/generate-content?type=caption`
  );
  return response.data;
};

// RESULTS

export const getBoardDeliverableOptions = async (
  organizationId: string | number,
  boardId: string | number
): Promise<{
  data: IDeliverableOptionDTO[];
}> => {
  const response = await instance.get<{ data: IDeliverableOptionDTO[] }>(
    `/organizations/${organizationId}/content-boards/${boardId}/deliverables`,
    { params: { page_size: 5000 } }
  );
  return response.data;
};

type GetResultsAssetsParamsType = {
  page_size: number;
};

export const getBoardResultAssets = async (
  organizationId: string | number,
  boardId: string | number,
  params?: GetResultsAssetsParamsType
): Promise<{
  data: IResultAssetDTO[];
}> => {
  const response = await instance.get<{
    data: IResultAssetDTO[];
  }>(`/organizations/${organizationId}/content-boards/${boardId}/results`, { params });

  return response.data;
};

export const deleteAllResultAssets = async (organizationId: string | number, boardId: string | number): Promise<void> => {
  return instance.delete(`/organizations/${organizationId}/content-boards/${boardId}/results`);
};

export type AddResultAssetType = {
  files: File;
  content_board_deliverable_id: string | number;
};

export const addResultAsset = async (
  organizationId: string | number,
  boardId: string | number,
  data: AddResultAssetType
): Promise<IResultAssetDTO> => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => formData.append(key, value as any));

  const res = await instanceWithForm.post(`/organizations/${organizationId}/content-boards/${boardId}/results`, formData);
  return res.data.data;
};

export const patchResultAsset = async (
  organizationId: string | number,
  boardId: string | number,
  assetId: string | number,
  data: IUpdateResultAssetDTO
): Promise<IResultAssetDTO> => {
  const formData = new FormData();
  Object.entries(data).forEach(entry => formData.append(...entry));
  const res = await instanceWithForm.patch(`/organizations/${organizationId}/content-boards/${boardId}/results/${assetId}`, formData);
  return res.data.data;
};

export const requestResults = (organizationId: string | number, boardId: string | number): Promise<void> => {
  return instanceWithForm.post(`/organizations/${organizationId}/content-boards/${boardId}/request-results`);
};

export const deleteResultAsset = (organizationId: string | number, boardId: string | number, assetId: string | number): Promise<void> => {
  return instanceWithForm.delete(`/organizations/${organizationId}/content-boards/${boardId}/results/${assetId}`);
};
