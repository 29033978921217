import React, { FC, useEffect, useMemo } from 'react';
import styles from './HeaderSection.module.scss';
import SectionWrapper from '../components/SectionWrapper';
import DeliverablePlatform from '../../../../../../components/Cells/DeliverableCell/DeliverablePlatform';
import HeaderColumn from './HeaderColumn';
import { getFormattedPlacement } from '../../../../../../shared/utils/helpers';
import LiveDueDate from '../../../../../../components/BriefsAndBoardsFlows/LiveDueDate';
import HeaderName from '../../../../../../components/HeaderName';
import 'moment-timezone';
import { updateDeliverableItem } from '../../../../../../actions/deliverablesActions/deliverables.actions';
import { ReactComponent as AlarmSVG } from '../../../../../../assets/img/icons/alarm.svg';
import { useParams } from 'react-router-dom';
import LoadingState from './LoadingState';
import { Guest } from '../../../../../../models/permissions/users/Guest';
import { DeliverablePermission } from '../../../../../../models/permissions/enum/DeliverablePermission';
import LiveDueDateHeader from '../../../../../../components/BriefsAndBoardsFlows/LiveDueDate/components/LiveDueDateHeader';
import { getAllMembers } from '../../../../../../actions/organizations.actions';
import { useAppDispatch, useAppSelector } from '../../../../../../shared/hooks/reduxHooks';
import { DeliverablesReducerState } from '../../../../../../reducers/deliverables.reducer';
import { Moment } from 'moment';
import useBoardCreator from '../../../../hooks/useBoardCreator';
import { COLLABORATOR_ROLE_TYPES } from '../../../../../../models/permissions/collaborator/CollaboratorRole';
import { User } from '../../../../../../models/permissions/users/User';
import { MODALS_VERSION } from '../../../../../../components/BriefsAndBoardsFlows/LiveDueDate/components/CalendarWrapper';

const dateFormat = 'DD/MM/YYYY';
const timeFormat = 'hh:mm A';

const getTimeString = (moment: Moment) => {
  if (moment.hours() === 0 && moment.minutes() === 0) {
    return '';
  }
  return `at ${moment.format(timeFormat)}`;
};

const MAX_MEMBERS_NUMBER_IN_ONE_ORGANIZATION = 100;

const HeaderSection: FC = () => {
  const dispatch = useAppDispatch();
  const { openedDeliverable, deliverableLoading, pageModel } = useAppSelector<DeliverablesReducerState>(state => state.deliverables);
  const userModel = useAppSelector<User>(state => state.auth.userModel);
  const { collaborators } = useAppSelector<any>(state => state.contentBoard);
  const currentOrg = useAppSelector<any>(state => state.auth.currentOrganization);
  const params = useParams<{ boardId: string }>();
  const currentOrganizationID = useAppSelector<string | number | null>(state => state.auth.currentOrganization?.organization_id || null);
  const boardCreatorId = useAppSelector(state => state.contentBoard.openedBoard?.creator_id ?? null);
  const boardCreator = useAppSelector(state => state.contentBoard.collaborators.find(c => c.creator_id === boardCreatorId) ?? null);
  const boardCreatorFullName = `${boardCreator?.approver_first_name} ${boardCreator?.approver_last_name}`;

  useEffect(() => {
    if (currentOrganizationID) {
      dispatch(getAllMembers(currentOrganizationID, 1, MAX_MEMBERS_NUMBER_IN_ONE_ORGANIZATION));
    }
  }, [currentOrganizationID]);

  const currentCollaborator = useMemo(() => {
    if (currentOrganizationID && collaborators.length > 0) {
      return collaborators.find(collaborator => collaborator.organization_id == currentOrganizationID);
    }
    return {};
  }, [currentOrganizationID, collaborators]);

  const isBoardCreator = useBoardCreator();

  const canEditNotification = useMemo(() => {
    if (isBoardCreator === null || !pageModel) return false;

    return isBoardCreator || pageModel.roleCan(DeliverablePermission.CAN_EDIT_REMINDER_DATE);
  }, [pageModel, isBoardCreator]);

  const isContentCreatorCreatedBoard = useMemo(() => {
    if (isBoardCreator === null || !currentCollaborator) return false;

    return currentCollaborator.type === COLLABORATOR_ROLE_TYPES.CONTENT_CREATOR && isBoardCreator;
  }, [isBoardCreator, currentCollaborator?.type]);

  const canEditDueDates = useMemo(() => {
    if (!pageModel) return false;

    return isContentCreatorCreatedBoard || pageModel.roleCan(DeliverablePermission.CAN_EDIT_LIVE_DUE_DATE);
  }, [isContentCreatorCreatedBoard, pageModel]);

  const dateItems = useMemo(() => {
    if ((currentOrganizationID || userModel instanceof Guest) && openedDeliverable?.deliverable) {
      const updateDeliverable = async data => {
        if (!currentOrganizationID || !openedDeliverable?.deliverable) return;
        dispatch(
          updateDeliverableItem(
            currentOrganizationID,
            openedDeliverable?.deliverable?.content_board_id,
            openedDeliverable?.deliverable?.id,
            data
          )
        );
      };

      return [
        {
          title: 'Concept Due:',
          mainInputPlaceHolder: 'Pick Concept Due',
          initialMainDate: openedDeliverable?.deliverable?.concept_due_datetime || null,
          initialReminderDate: openedDeliverable?.deliverable?.concept_reminder_datetime || null,
          minMainDate: null,
          maxMainDate: openedDeliverable?.deliverable?.content_due_datetime || openedDeliverable?.deliverable?.live_datetime || null,
          onSave: async (date, remainderDate) => {
            await updateDeliverable({
              concept_due_datetime: date || null,
              concept_reminder_datetime: remainderDate || null,
            });
          },
          onClear: async () => {
            await updateDeliverable({
              concept_due_datetime: null,
              concept_reminder_datetime: null,
            });
          },
          tooltipTextData: {
            emptyState: () => {
              if (pageModel?.roleCan(DeliverablePermission.ONLY_CC_CAN_VIEW) && !isContentCreatorCreatedBoard) {
                return 'Due date to be filled by Agency/Brand/Talent Manager';
              }
              return 'Set a date when the concept is due to be delivered';
            },
            filledState: moment => `Your concept is due on ${moment.format(dateFormat)}`,
            filledStateToday: () => 'Your concept is due today.',
            filledStatePassed: moment => `Your concept was due on ${moment.format(dateFormat)}`,
          },
        },
        {
          title: 'Content Due:',
          mainInputPlaceHolder: 'Pick Content Due',
          initialMainDate: openedDeliverable?.deliverable?.content_due_datetime || null,
          initialReminderDate: openedDeliverable?.deliverable?.content_reminder_datetime || null,
          minMainDate: openedDeliverable?.deliverable?.concept_due_datetime || null,
          maxMainDate: openedDeliverable?.deliverable?.live_datetime || null,
          onSave: async (date, remainderDate) => {
            await updateDeliverable({
              content_due_datetime: date || null,
              content_reminder_datetime: remainderDate || null,
            });
          },
          onClear: async () => {
            await updateDeliverable({
              content_due_datetime: null,
              content_reminder_datetime: null,
            });
          },
          tooltipTextData: {
            emptyState: () => {
              if (pageModel?.roleCan(DeliverablePermission.ONLY_CC_CAN_VIEW) && !isContentCreatorCreatedBoard) {
                return 'Due date to be filled by Agency/Brand/Talent Manager';
              }
              return 'Set a date when the сontent is due to be delivered';
            },
            filledState: moment => `Your content is due on ${moment.format(dateFormat)}`,
            filledStateToday: () => 'Your content is due today.',
            filledStatePassed: moment => `Content was due on ${moment.format(dateFormat)}`,
          },
        },
        {
          title: 'Live Date',
          mainInputPlaceHolder: 'Pick Live Date',
          initialMainDate: openedDeliverable?.deliverable?.live_datetime || null,
          disableRemainder: true,
          initialReminderDate: null,
          minMainDate: openedDeliverable?.deliverable?.content_due_datetime || openedDeliverable?.deliverable?.concept_due_datetime || null,
          maxMainDate: null,
          onSave: async date => {
            await updateDeliverable({
              live_datetime: date,
            });
          },
          onClear: async () => {
            await updateDeliverable({
              live_datetime: null,
            });
          },
          tooltipTextData: {
            emptyState: () => {
              if (pageModel?.roleCan(DeliverablePermission.ONLY_CC_CAN_VIEW) && !isContentCreatorCreatedBoard) {
                return 'Live date to be filled by Agency/Brand/Talent Manager';
              }
              return 'Set a date to be reminded when the content is due to go live.';
            },
            filledState: moment => `Your content is due to go live ${getTimeString(moment)} on ${moment.format(dateFormat)}`,
            filledStateToday: moment => `Your content is due to go live today ${getTimeString(moment)}`,
            filledStatePassed: moment => `Your content was due to go live ${getTimeString(moment)} on ${moment.format(dateFormat)}`,
          },
        },
      ];
    }
    return [];
  }, [openedDeliverable?.deliverable, currentOrganizationID, userModel, pageModel]);

  return (
    <SectionWrapper>
      <div className={styles.root}>
        {deliverableLoading && <LoadingState />}

        {openedDeliverable && !deliverableLoading && (
          <>
            <HeaderColumn type="group" className={styles.deliverablePlatformBlock}>
              <div className={styles.platform}>
                <DeliverablePlatform
                  className={styles.placementComponent}
                  withoutText
                  platform={openedDeliverable?.deliverable?.platform}
                />
                {getFormattedPlacement(openedDeliverable?.deliverable?.placement)}
              </div>
              <HeaderName
                headerName={openedDeliverable?.deliverable?.name}
                action={name =>
                  dispatch(updateDeliverableItem(currentOrg.organization_id, params.boardId, params.deliverableId, { name: name }))
                }
                placeholder={'Deliverable name'}
                canEditHeaderName={pageModel?.roleCan(DeliverablePermission.CAN_EDIT_DELIVERABLE_NAME)}
              />
            </HeaderColumn>
            <HeaderColumn type="group" className={styles.dateBlock}>
              <AlarmSVG className={styles.alarmIcon} />
              {dateItems.map(item => (
                <LiveDueDate
                  modalVersion={MODALS_VERSION.HYBRID}
                  reminderRecipientName={boardCreatorFullName}
                  reminderDateText={isContentCreatorCreatedBoard ? 'Set Reminders for yourself' : 'Reminder'}
                  renderInputComponent={props => (
                    <div className={styles.datesWrapper}>
                      <LiveDueDateHeader
                        title={item.title}
                        withReminder={!!item.initialReminderDate}
                        titleClassName={styles.datesTitle}
                        dateClassName={styles.datesText}
                        tooltipTextData={item.tooltipTextData}
                        date={item.initialMainDate}
                        {...props}
                      />
                    </div>
                  )}
                  key={item.title}
                  canEdit={canEditDueDates}
                  disableRemainder={!canEditNotification}
                  {...item}
                />
              ))}
            </HeaderColumn>
          </>
        )}
      </div>
    </SectionWrapper>
  );
};

export default HeaderSection;
