import { CalendarProps } from 'react-multi-date-picker';
import { MouseEventHandler, ReactNode } from 'react';
import dayjs from 'dayjs';
import { dateWithoutTimeFormat } from '../constants/timeFormats';
import moment from 'moment/moment';
import { Moment } from 'moment';
import useTooltip from '../../../../shared/hooks/fixedTooltip/useTooltip';

type MapCalendarsDaysFabrikType = (
  mainDateWithoutTime: string | null,
  reminderDateWithoutTime?: string | null,
  mainTimeIndex?: number | null,
  activeInput?: 'main' | 'reminder',
  disableRemainder?: boolean,
  defaultNotificationDay?: Moment | null,
  tooltipSettings?: ReturnType<typeof useTooltip>
) => CalendarProps['mapDays'];

const mapCalendarDaysFabrik: MapCalendarsDaysFabrikType =
  (
    mainDateWithoutTime: string | null,
    reminderDateWithoutTime?: string | null,
    mainTimeIndex?: number | null,
    activeInput: 'main' | 'reminder' = 'main',
    disableRemainder?: boolean,
    defaultNotificationDay?: Moment | null,
    tooltipSettings?: ReturnType<typeof useTooltip>
  ) =>
  ({ date }) => {
    let className = '';
    // @ts-ignore
    const parsedDate = dayjs(date);
    const currentDateWithoutTime = parsedDate?.format(dateWithoutTimeFormat);

    if (mainDateWithoutTime && moment.utc(mainDateWithoutTime)?.isSame(moment.utc(currentDateWithoutTime), 'date')) {
      className += ' selected';
    }

    if (reminderDateWithoutTime && moment.utc(reminderDateWithoutTime).isSame(moment.utc(currentDateWithoutTime), 'date')) {
      className += ' notification-day';
    }

    const isActiveReminderInput = activeInput === 'reminder';

    if (!disableRemainder && isActiveReminderInput) {
      className += ' selecting-notification-day';
    }

    const isDefaultNotificationDay = defaultNotificationDay?.isSame(moment.utc(currentDateWithoutTime), 'day');
    if (isActiveReminderInput && !reminderDateWithoutTime && isDefaultNotificationDay) {
      className += ' default-notification-day';
    }

    let tooltipProps: null | { onMouseEnter: MouseEventHandler; onMouseLeave: MouseEventHandler; children: ReactNode | JSX.Element } = null;

    if (
      isActiveReminderInput &&
      moment.utc(mainDateWithoutTime).isSame(moment.utc(currentDateWithoutTime), 'day') &&
      !mainTimeIndex &&
      tooltipSettings
    ) {
      const calendarDateBoxValue = parsedDate.format('D');

      tooltipProps = {
        onMouseEnter: tooltipSettings?.onEnter,
        onMouseLeave: tooltipSettings?.onLeave,
        children: [tooltipSettings?.toolTip, calendarDateBoxValue],
      };
    }

    return { className, ...tooltipProps, key: currentDateWithoutTime };
  };

export default mapCalendarDaysFabrik;
