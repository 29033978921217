import React, {FC, useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../../../../../shared/hooks/reduxHooks";
import ResultsFilledCard from "../ResultsFilledCard";
import EmptyResultsCard from "../EmptyResultsCard";
import {clearResultsState, getResultAssets} from "../../../../../../../../actions/ResultsActions/results.actions";
import {useParams} from "react-router-dom";
import LoadingState from "./LoadingState";

type PropsType = {
  onUpload: (files: File[]) => void;
  loading: boolean;
};

const ResultsCard: FC<PropsType> = props => {
  const params = useParams() as { boardId: string };
  const dispatch = useAppDispatch();
  const assets = useAppSelector(state => state.results.resultAssets);
  const userModel = useAppSelector(state => state.auth.userModel);
  const loading = useAppSelector(state => state.results.requestResultsLoading);
  const currentOrganizationID = useAppSelector(state => state.auth.currentOrganization?.organization_id);

  useEffect(() =>
    function clearState() {
      dispatch(clearResultsState());
  }, []);

  useEffect(function getPreviewAsset() {
    if (params.boardId && userModel && currentOrganizationID) {
      dispatch(getResultAssets(params.boardId, {page_size: 1}));
    }
  }, [params.boardId, userModel, currentOrganizationID]);

  if (loading || props.loading) return <LoadingState/>;

  if (!assets.length) {
    return <EmptyResultsCard onUpload={props.onUpload} />
  }

  return <ResultsFilledCard onUpload={props.onUpload} assets={assets}/>
};

export default ResultsCard;
