import React, { useState } from 'react';
import CreateBoardBrief from '../CreateBoardWrapper/CreateBoardBrief';
import useBoardBriefFormik from '../CreateBoardWrapper/CreateBoardBrief/useBoardBriefFormik';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector, useThunkDispatch } from '../../../../shared/hooks/reduxHooks';
import { clearBoardBriefErrors, createBoardBrief } from '../../../../actions/BoardBriefActions/boardBriefActions';
import LoaderModal from '../../../../expert/components/Modal/LoaderModal';
import CreateBoardBriefHydrator from '../../../../models/entities/boardBrief/hydrators/CreateBoardBriefHydrator';
import useBriefVisualAssets from '../CreateBoardWrapper/CreateBoardBrief/useBriefVisualAssets';
import ErrorModal from '../../../../expert/components/Modal/ErrorModal';
import { ContentApprovalBreadcrumbsPageType } from '../../../../models/entities/breadcrumbs/enums/ContentApprovalBreadcrumbsPage';
import CustomError from '../../../../models/entities/error/CustomError';
import StorageLimitModal from '../../../../components/Modals/ErrorLimitModal';
import TypedServerError from '../../../../models/entities/error/TypedServerError';

const CreateBriefFromBoard = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const thunkDispatch = useThunkDispatch();
  const { boardId } = useParams<{ boardId: string }>();
  const currentOrganizationID = useAppSelector<string | number>(state => state.auth.currentOrganization?.organization_id);
  const { boardBriefCreateLoading } = useAppSelector(state => state.boardBrief);
  const boardBriefFormik = useBoardBriefFormik();
  const { briefFormAssets } = useBriefVisualAssets();
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [storageLimitError, setStorageLimitError] = useState<TypedServerError | null>(null);

  const briefFormSubmit = () => {
    const createBriefData = {
      briefData: new CreateBoardBriefHydrator(boardBriefFormik.values),
      visualElements: briefFormAssets.value,
    };

    thunkDispatch(createBoardBrief(currentOrganizationID, boardId, createBriefData))
      .then(() => {
        history.push(`/content-board/${boardId}`);
      })
      .catch((error: CustomError) => {
        if (error instanceof TypedServerError) {
          setStorageLimitError(error);
        } else {
          setSubmitError(error.getMessage());
        }
      });
  };

  const briefFormCancel = () => {
    if (history.length) {
      history.goBack();
    } else {
      history.push(`/content-board/${boardId}`);
    }
  };

  return (
    <>
      <CreateBoardBrief
        breadCrumbsPageKey={ContentApprovalBreadcrumbsPageType.BOARD_BRIEF_CREATE}
        title="Add Otterfish brief to your Content Board"
        description="Build a brief for the Campaign, then invite collaborators to finalize the requirements needed"
        buttonTitle="Add brief to board"
        formik={boardBriefFormik}
        visualAssets={briefFormAssets}
        isSubmitDisabled={boardBriefCreateLoading}
        onCancel={briefFormCancel}
        onSubmit={briefFormSubmit}
      />
      <LoaderModal open={boardBriefCreateLoading} />
      <StorageLimitModal isOpen={Boolean(storageLimitError)} error={storageLimitError} onClose={() => setStorageLimitError(null)} />
      <ErrorModal
        open={!!submitError}
        onClose={() => {
          dispatch(clearBoardBriefErrors());
          setSubmitError(null);
        }}
        text={submitError || ''}
      />
    </>
  );
};

export default CreateBriefFromBoard;
