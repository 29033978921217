import { IDeliverable } from '../Deliverable';
import { DELIVERABLE_CALL_TO_ACTIONS } from '../enums/DeliverableCallToActions';
import { PLACEMENTS } from '../../../enums/Placements';
import { PLATFORMS } from '../../../enums/Platforms';
import { DELIVERABLE_STATUSES } from '../../../enums/DeliverableStatuses';
import { IDeliverableDTO } from '../dto/Deliverable';

export class DeliverableHydrator implements IDeliverable {
  approved_at_concept?: null | string;
  approved_at_content?: null | string;
  call_to_action?: null | DELIVERABLE_CALL_TO_ACTIONS;
  concept_due_datetime: string | null;
  concept_reminder_datetime?: string | null;
  content_board_id: number;
  content_board_name: string;
  content_due_datetime: string | null;
  content_reminder_datetime?: string | null;
  created_at: string;
  creator_id: string;
  hashtags?: null | string[];
  id: number;
  live_datetime: string | null;
  name: string;
  placement: PLACEMENTS;
  platform: PLATFORMS;
  preview_thumbnail?: null | string;
  social_tagging?: null | string[];
  status_concept: DELIVERABLE_STATUSES;
  status_content: DELIVERABLE_STATUSES;
  updated_at: string | null;
  updater_id: string | null;

  constructor(dto: IDeliverableDTO) {
    this.approved_at_concept = dto.approved_at_content;
    this.approved_at_content = dto.approved_at_content;
    this.call_to_action = dto.call_to_action;
    this.concept_due_datetime = dto.concept_due_datetime;
    this.content_board_id = dto.content_board_id;
    this.content_board_name = dto.content_board_name;
    this.content_due_datetime = dto.content_due_datetime;
    this.content_reminder_datetime = dto.content_reminder_datetime;
    this.created_at = dto.created_at;
    this.creator_id = dto.creator_id;
    this.hashtags = dto.hashtags;
    this.id = dto.id;
    this.live_datetime = dto.live_datetime;
    this.name = dto.name;
    this.placement = dto.placement;
    this.platform = dto.platform;
    this.preview_thumbnail = dto.preview_thumbnail;
    this.social_tagging = dto.social_tagging;
    this.status_concept = dto.status_concept;
    this.status_content = dto.status_content;
    this.updated_at = dto.updated_at;
    this.updater_id = dto.updater_id;
  }
}
