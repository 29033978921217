import { IPricingPlanView } from '../PricingPlanView';
import CustomPlan from './CustomPlan';

export default class CustomPaidPlanTMBA extends CustomPlan implements IPricingPlanView {
  id: number;
  name: string;
  monthPrice: number;
  annualPrice: number;
  currency: string;
  description: string;
  abilities: string[];
  btn: string;
  isFree: boolean;
  isCustom?: boolean;
  action: (...args: any) => void;

  constructor() {
    super();
    this.id = 1;
    this.name = 'Pro Plan';
    this.monthPrice = -1;
    this.annualPrice = -1;
    this.currency = '$';
    this.description = 'Supercharge your Influencer content workflows and approvals.';
    this.abilities = [
      'Unlimited Content Boards',
      'Chat Boards',
      'Custom Storage',
      'Unlimited Team Members',
      'Knowledge Base Support',
      'Email Support',
      'Account Manager',
    ];
    this.btn = 'Talk to Sales';
    this.isFree = false;
    this.isCustom = true;

    this.action = () => {
      window.open('https://otterfish.com/get-in-touch/');
    };
  }
}
