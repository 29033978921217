import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import styles from './UploadResultsForm.module.scss';
import ResultsList from "../ResultsList";
import {ResultAssetListItemType} from "../types";
import ButtonLightPurpleWhite from "../../../../../components/Buttons/ButtonLightPurpleWhite";
import ButtonCustom from "../../../../../expert/components/Buttons/ButtonCustom";
import UploadArrowSvg from "../../../../../components/IconsSvg/UploadArrowSVG";
import useMultipleFilesUpload from "../../../hooks/useMultipleFilesUpload";
import {INPUT_FILE_ALLOWED_TYPE} from "../../../../../shared/constants/tsConstants";
import {createResultItemByFile} from "../utils";
import {useAppSelector, useThunkDispatch} from "../../../../../shared/hooks/reduxHooks";
import {useParams} from "react-router-dom";
import {IResultAsset} from "../../../../../models/entities/asset/ResultAsset";
import {uploadResultAssets} from "../../../../../actions/ResultsActions/results.actions";

type PropsType = {
  onCancel: () => void;
  onSubmit: () => void;
  isOpen: boolean;
  files: File[];
};

export const UPLOAD_RESULTS_FORM_INPUT_PROPS = {
  multiple: true,
  accept: `${INPUT_FILE_ALLOWED_TYPE.IMAGE_INPUT_ALLOWED}, ${INPUT_FILE_ALLOWED_TYPE.VIDEO_INPUT_ALLOWED}`
};

const UploadResultsForm: FC<PropsType> = props => {
  const params = useParams() as { boardId: string };
  const dispatch = useThunkDispatch();
  const [results, setResults] = useState<ResultAssetListItemType[]>([]);
  const [assetsDeliverableError, setAssetsDeliverableError] = useState<(string | number)[]>([]);
  const uploadLoading = useAppSelector(state => state.results.uploadResultAssetsLoading);
  const handleUpload = useCallback((files: File[]) => {
    setResults(prevState => [...prevState, ...files.map(createResultItemByFile)]);
  }, []);

  useEffect(() => {
    if (props.isOpen) {
      setResults([...props.files.map(createResultItemByFile)]);
    }
  }, [props.isOpen, props.files]);

  const handleDeliverableChange = useCallback((assetId: string | number, deliverableId: string | number) => {
    setResults(prevState => prevState
      .map(asset => {
        if (asset.id !== assetId) return asset;
        return {...asset, deliverableId}
      })
    )
    setAssetsDeliverableError(prevState => prevState.filter(id => id !== assetId));
  }, []);

  const checkErrors = (): boolean => {
    const assetsWithoutDeliverable = results.filter(res => !res.deliverableId);
    if (assetsWithoutDeliverable.length) {
      setAssetsDeliverableError(assetsWithoutDeliverable.map(asset => asset.id));
      return true;
    }
    return false
  }

  const getSubmitData = () => {
    return results
      .filter(res => res.status !== "uploaded" && res.status !== "loading")
      .map(res => ({content_board_deliverable_id: res.deliverableId as string | number, files: res.file}));
  }

  const setLoadingOnSubmit = () => {
    setResults(prevState => prevState
      .map(res => {
        if (res.status === "loading" || res.status === "uploaded") return res;
        return {...res, status: "loading"}
      }));
  }

  const onUpload = ({failed, assets}: { failed: File[]; assets: { asset: IResultAsset; file: File }[] }) => {
    if (!failed.length) props.onSubmit();

    setResults(prevState =>
      prevState.reduce<ResultAssetListItemType[]>((acc, prevRes) => {
        const isFailed = failed.some(file => file === prevRes.file);
        if (isFailed) {
          acc.push({...prevRes, status: "error"});
          return acc;
        }

        const newAsset = assets.find(asset => asset.file === prevRes.file)?.asset;
        if (!newAsset) {
          acc.push(prevRes)
          return acc;
        }

        return acc
      }, []));
  }

  const onSubmit = () => {
    if (checkErrors()) return;
    setLoadingOnSubmit();
    const submitData = getSubmitData();
    dispatch(uploadResultAssets(params.boardId, submitData)).then(onUpload);
  }

  const {onFileUpload, renderInput} = useMultipleFilesUpload(handleUpload, 10, UPLOAD_RESULTS_FORM_INPUT_PROPS);

  const isSomeUploaded = useMemo(() =>
      results.some(res => res.status === "uploaded"),
    [results]);

  const onReload = useCallback((assetId: number | string) => {
    const asset = results.find(res => res.id === assetId);
    if (!asset || !asset.deliverableId) return;

    setResults(prevState => prevState.map(res => {
      if (res.id === assetId) return {...res, status: "loading"};
      return res;
    }))

    dispatch(uploadResultAssets(params.boardId, [{files: asset.file, content_board_deliverable_id: asset.deliverableId}]))
      .then(onUpload);
  }, [results]);

  const onRemove = useCallback((assetId: string | number) => {
    setResults(prevState => prevState.filter(res => res.id !== assetId));
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.list}>
        <ResultsList
          onReload={onReload}
          onRemove={onRemove}
          assetsWithoutDeliverableErrors={assetsDeliverableError}
          onDeliverableChange={handleDeliverableChange}
          assets={results}
        />
      </div>
      <div className={styles.bottom}>
        <ButtonLightPurpleWhite onClick={props.onCancel} className={styles.button} size="medium" designVersion="v3">
          {isSomeUploaded ? "Close" : "Cancel"}
        </ButtonLightPurpleWhite>

        {!results.length ?
          <ButtonCustom
            onClick={onFileUpload}
            Icon={UploadArrowSvg}
            className={styles.button}
            iconSize={20}
          >
            {renderInput()}
            Upload
          </ButtonCustom>
          :
          <ButtonCustom
            onClick={onSubmit}
            className={styles.button}
            disabled={uploadLoading}
          >
            Submit
          </ButtonCustom>
        }
      </div>
    </div>
  )
};

export default UploadResultsForm;
