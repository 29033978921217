import React, { useCallback, useState } from 'react';
import styles from './ContentApprovalCell.module.scss';
import cs from 'classnames';
import { useHistory } from 'react-router-dom';
import brandPreviewImage from '../../../assets/img/brandPlaceholder.svg';
import CollaboratorsInfo from './CollaboratorsInfo';
import dayjs from 'dayjs';
import SortButtons from '../../SortButtons';
import TestActionModal from '../../Modals/TestActionModal';
import { useDispatch, useSelector } from 'react-redux';
import { archiveContentBoard, unzipContentBoard } from '../../../actions/ContentApprovalActions/contentApproval.actions';
import BoardStatus from '../../statuses/BoardStatus';
import BoardOptionsButton from './components/BoardOptionsButton';

const SortButtonsWrapper = ({ name, orderByName, sort, setSort }) => {
  return (
    <SortButtons
      name={name}
      orderBy={orderByName}
      isActive={orderType => orderByName === sort.order_by && orderType === sort.order_type}
      onSort={(orderBy, orderType) => setSort({ order_by: orderBy, order_type: orderType })}
    />
  );
};

const CellHeader = ({ sort, setSort, style }) => {
  return (
    <div className={cs(styles.root, styles.header)} style={style}>
      <span>
        <SortButtonsWrapper name={'Name of board'} sort={sort} setSort={setSort} orderByName="name" />
      </span>
      <span>
        <SortButtonsWrapper name={'Brand'} sort={sort} setSort={setSort} orderByName="brand_name" />
      </span>
      <span>Collaborators</span>
      <span className={styles.centeredTitle}>
        <SortButtonsWrapper name={'Creation date'} sort={sort} setSort={setSort} orderByName="created_at" />
      </span>
      <span className={cs(styles.centeredTitle, styles.boardStatusTitle)}>
        <SortButtonsWrapper name={'Board status'} sort={sort} setSort={setSort} orderByName="status" />
      </span>
      <span></span>
    </div>
  );
};

const CellContent = ({ board, style, onDetail, isTablet, onArchive, onDeleteActionClick, setShowPlanWarningModal }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentOrganizationID = useSelector(state => state.auth.currentOrganization?.organization_id);
  const currentPlan = useSelector(state => state.auth.currentOrganizationPlan);
  const brandLogo = board.brand_logo_thumbnail_location || board.brand_logo_location || brandPreviewImage;
  // const briefName = board?.campaign_brief_item_id ? board?.campaign_brief_item_name || 'unnamed brief' : 'Stand alone';
  // const { toolTip, onEnter, onLeave } = useTooltip('top', { text: briefName, className: styles.tooltip });

  const [isOpenArchiveModal, setIsOpenArchiveModal] = useState(false);

  // const goToBrief = e => {
  //   e.stopPropagation();
  //   window.open(
  //     `/campaign/${board?.campaign_brief_id}/campaign-brief/${board?.campaign_brief_parent_id}/creator-brief/${board?.campaign_brief_item_id}`
  //   );
  // };

  const handleDetailClick = () => {
    if (onDetail) {
      onDetail(board.id);
      return;
    }
    localStorage.setItem(
      'boardBackRoute',
      JSON.stringify({
        routes: [
          {
            route: '/content-approval',
            routeName: 'Content Approval',
          },
        ],
        boardId: board.id,
      })
    );
    history.push(`/content-board/${board.id}`);
  };

  const handleArchiveBoard = () => {
    dispatch(archiveContentBoard(currentOrganizationID, board.id)).then(() => onArchive(board, 'archive'));
  };
  const handleUnzipBoard = () => {
    dispatch(unzipContentBoard(currentOrganizationID, board.id)).then(() => onArchive(board, 'unzip'));
  };

  const onDuplicate = useCallback(() => {
    if (!board.id || !currentPlan) return;
    if (
      currentPlan?.content_board_create_limit > currentPlan?.usage?.content_board_created ||
      currentPlan?.content_board_create_limit === -1
    ) {
      history.push(`/content-board/${board.id}/duplicate`);
    }

    setShowPlanWarningModal(true);
  }, [board.id, currentPlan]);

  const onArchiveActions = useCallback(() => {
    setIsOpenArchiveModal(true);
  }, []);

  const onActionButtonDeleteClick = useCallback(() => {
    onDeleteActionClick(board);
  }, [board]);

  return (
    <>
      {!isTablet ? (
        <>
          <div className={styles.root} style={style} onClick={handleDetailClick}>
            <div className={styles.boardName}>
              <div className={cs(styles.boardNameText, styles.cellText)}>{board.name}</div>
            </div>
            <div className={styles.brand}>
              <div className={styles.logoWrapper}>
                <img src={brandLogo} alt="logo" />
              </div>
              {board.brand_name ? (
                <span className={styles.cellText}>{board.brand_name}</span>
              ) : (
                <span className={styles.emptyText}>No brand</span>
              )}
            </div>
            <div className={styles.creators}>
              <CollaboratorsInfo collaborators={board?.collaborators} />
            </div>
            <div className={cs(styles.createdDate, styles.cellText)}>{dayjs(board.created_at).format('DD / MMM / YYYY')}</div>
            {/* <div*/}
            {/*  className={cs(styles.brief, { [styles.alone]: !board?.campaign_brief_item_id })}*/}
            {/*  onMouseEnter={onEnter}*/}
            {/*  onMouseLeave={onLeave}*/}
            {/* >*/}
            {/*  <span>{briefName}</span>*/}
            {/*  {briefName.length > 20 ? toolTip : null}*/}
            {/* </div>*/}
            <BoardStatus withTooltip wrapperClass={styles.status} status={board.status} canEditStatus={false} />
            <div className={styles.archive}>
              <BoardOptionsButton
                isBoardArchived={board.is_archived}
                onArchive={onArchiveActions}
                onDelete={onActionButtonDeleteClick}
                onDuplicate={onDuplicate}
                onUnZip={onArchiveActions}
              />
            </div>
          </div>
        </>
      ) : (
        <div className={cs(styles.root, { [styles.rootMobile]: isTablet })} style={style} onClick={handleDetailClick}>
          <div className={styles.levelOne}>
            <div className={styles.boardName}>{board.name}</div>
            <div className={styles.rightContent}>
              <div className={styles.archive}>
                <BoardOptionsButton
                  isBoardArchived={board.is_archived}
                  onArchive={onArchiveActions}
                  onDelete={onActionButtonDeleteClick}
                  onDuplicate={onDuplicate}
                  onUnZip={onArchiveActions}
                />
              </div>
            </div>
          </div>
          {!!board?.collaborators.length && (
            <div className={styles.levelTwo}>
              <div className={styles.creators}>
                <CollaboratorsInfo collaborators={board?.collaborators} />
              </div>
              <BoardStatus withTooltip wrapperClass={styles.status} status={board.status} canEditStatus={false} />
            </div>
          )}
          <div className={styles.levelThree}>
            {/* {board?.campaign_brief_item_name && (*/}
            {/*  <div className={styles.container}>*/}
            {/*    <div className={styles.head}>Brief:</div>*/}
            {/*    <div onClick={e => goToBrief(e)} className={styles.briefItemName}>*/}
            {/*      {board?.campaign_brief_item_name}*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/* )}*/}

            <div className={styles.container}>
              <div className={styles.head}>Brand:</div>
              <div className={styles.brandContainer}>
                <img className={styles.brandLogo} src={brandLogo} alt="brand logo" />
                {board.brand_name ? <p className={styles.brandName}>{board.brand_name}</p> : <p className={styles.emptyText}>No brand</p>}
              </div>
            </div>

            {board.created_at && (
              <div className={styles.container}>
                <div className={styles.head}>Created:</div>
                <p className={styles.creationDate}>{dayjs(board.created_at).format('DD / MMM / YYYY')}</p>
              </div>
            )}
          </div>
        </div>
      )}

      <TestActionModal
        open={isOpenArchiveModal}
        title={
          board.is_archived
            ? `Are you sure you want to restore "${board.name}" content board`
            : `Are you sure you want to archive "${board.name}" content board`
        }
        agreeText="Yes"
        cancelText="No"
        onSubmit={() => {
          setIsOpenArchiveModal(false);
          if (board.is_archived) {
            handleUnzipBoard();
          } else {
            handleArchiveBoard();
          }
        }}
        onClose={() => setIsOpenArchiveModal(false)}
      />
    </>
  );
};

const ContentApprovalCell = ({ sort, setSort, tableHeader, ...props }) => {
  if (tableHeader) return <CellHeader style={props.style} sort={sort} setSort={setSort} />;
  return <CellContent {...props} />;
};

export default ContentApprovalCell;
