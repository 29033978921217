import RealisticOtterfishWithMobile from '../../assets/img/RealisticOtterfishWithMobile.png';
import oldOtterfishTakesSelfie from '../../assets/img/oldOtterfishTakesSelfie.png';
import { ORGANIZATION_TYPES } from '../../models/enums/OrganizationTypes';

export enum INPUT_FILE_ALLOWED_TYPE {
  IMAGE_INPUT_ALLOWED = 'image/*, .heif, .heic',
  VIDEO_INPUT_ALLOWED = 'video/*',
}

export const USER_COMMENTS_CONFIG = {
  [ORGANIZATION_TYPES.ADVERTISER]: {
    image: RealisticOtterfishWithMobile,
    name: 'Ella Johnson',
    company: 'Content & Brand Exec @ Fuse',
    message: 'Being able to brief multiple creators with a few clicks and have all content approvals in one place is a game changer',
    currentRate: 8,
    rateOf: 10,
  },
  [ORGANIZATION_TYPES.INFLUENCER]: {
    image: RealisticOtterfishWithMobile,
    name: 'Ella Johnson',
    company: 'Content & Brand Exec @ Fuse',
    message: 'Being able to brief multiple creators with a few clicks and have all content approvals in one place is a game changer',
    currentRate: 8,
    rateOf: 10,
  },
  [ORGANIZATION_TYPES.BRAND]: {
    image: RealisticOtterfishWithMobile,
    name: 'Ella Johnson',
    company: 'Content & Brand Exec @ Fuse',
    message: 'Being able to brief multiple creators with a few clicks and have all content approvals in one place is a game changer',
    currentRate: 8,
    rateOf: 10,
  },
  [ORGANIZATION_TYPES.AGENCY]: {
    image: RealisticOtterfishWithMobile,
    name: 'Ella Johnson',
    company: 'Content & Brand Exec @ Fuse',
    message: 'Being able to brief multiple creators with a few clicks and have all content approvals in one place is a game changer',
    currentRate: 8,
    rateOf: 10,
  },
  [ORGANIZATION_TYPES.TALENT_MANAGER]: {
    image: RealisticOtterfishWithMobile,
    name: 'Megan Kikuchi',
    company: 'Client Manager @ WeAreTENZING',
    message: 'Our creators love it, it saves them and us so much time by having everything in once place.',
    currentRate: 9,
    rateOf: 10,
  },
  [ORGANIZATION_TYPES.CONTENT_CREATOR]: {
    image: oldOtterfishTakesSelfie,
    name: 'Amber Peebles',
    company: 'Content Creator',
    message: 'I’ve only been using Otterfish for a month and it’s already saved me hours of time',
    currentRate: 10,
    rateOf: 10,
  },
};

export const PUBLIC_EMAIL_DOMAINS = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'outlook.com',
  'aol.com',
  'icloud.com',
  'mail.com',
  'inbox.com',
  'zoho.com',
  'protonmail.com',
  'fastmail.com',
  'yandex.com',
  'gmx.com',
  'tutanota.com',
  'startmail.com',
  'hushmail.com',
  'runbox.com',
  'posteo.de',
  'kolabnow.com',
  'lavabit.com',
  'riseup.net',
  'disroot.org',
  'mailbox.org',
  'sigaint.org',
  'cock.li',
  'elude.in',
  'onionmail.org',
  'torbox3uiot6wchz.onion',
  'me.com',
  'live.com',
  'msn.com',
  'rocketmail.com',
  'web.de',
  'rediffmail.com',
  'gmx.net',
  't-online.de',
  'aim.com',
  'mailinator.com',
  'usa.com',
  'blueyonder.co.uk',
  'ntlworld.com',
  'talktalk.net',
  'btinternet.com',
  'virginmedia.com',
  'sky.com',
  'btopenworld.com',
  'yahoo.co.uk',
  'o2.co.uk',
  'orange.fr',
  'free.fr',
  'laposte.net',
  'sfr.fr',
  'webmail.co.za',
  'telkomsa.net',
  'vodamail.co.za',
  'live.ca',
  'sympatico.ca',
  'mail.bg',
  'abv.bg',
  'yahoo.fr',
  'yahoo.ca',
  'yahoo.com.tw',
  'yahoo.com.hk',
  'yahoo.co.in',
  'yahoo.co.id',
  'yahoo.co.jp',
  'yahoo.com.ph',
  'yahoo.com.au',
  'yahoo.com.vn',
  'yahoo.com.sg',
  'yahoo.com.my',
  'yahoo.com.ar',
  'yahoo.com.mx',
  'yahoo.com.br',
  'yahoo.es',
  'yahoo.it',
  'mail.ch',
  'bluewin.ch',
  'gmx.ch',
  'yahoo.de',
  'hotmail.co.uk',
  'hotmail.ca',
  'hotmail.fr',
  'hotmail.it',
  'hotmail.es',
  'msn.cn',
  'msn.com.tw',
  'msn.com.cn',
  'live.hk',
  'live.tw',
  'live.cn',
  'live.com.my',
  'live.com.sg',
  'outlook.jp',
  'outlook.kr',
  'outlook.sa',
  'outlook.ae',
  'outlook.sg',
  'outlook.com.vn',
  'outlook.co.id',
  'outlook.in',
  'outlook.com.ar',
  'outlook.mx',
  'outlook.com.br',
  'outlook.com.tr',
  'outlook.com',
  'qq.com',
  '163.com',
  '126.com',
  'yeah.net',
  'sohu.com',
  'sina.com',
  'tom.com',
  'vip.qq.com',
  '139.com',
  'wo.com.cn',
  '139.com',
  '139.com.cn',
  '263.net',
  'xinhuan',
  'mac.com',
  'otterfish.com',
];
