import React, { FC } from 'react';
import VideoConvertingPlaceholderImage from '../../../../../../../assets/img/video-converting-placeholder.png';
import styles from './DesktopModalAssetPreview.module.scss';
import MediaDueType from '../MediaDueType';
import VideoTag from '../../../../../../../components/VideoPlayers/VideoTag';
import { IAssetMedia } from '../../../../../../../models/entities/asset/AssetMedia';

export type DesktopModalAssetPreviewProps = {
  asset: IAssetMedia | null;
};

const DesktopModalAssetPreview: FC<DesktopModalAssetPreviewProps> = ({ asset }) => {
  if (!asset) return null;
  const renderImageView = (onLoad: () => void) => (
    <>
      <img className={styles.mediaBackground} src={asset.url} alt="asset" />
      <img onLoad={onLoad} className={styles.media} src={asset.url} alt="asset" />
    </>
  );

  const renderVideoView = (onLoad: () => void) => (
    <>
      <VideoTag className={styles.mediaBackground} muted controls loop src={asset.url} />
      <VideoTag onLoadedDataCapture={onLoad} className={styles.media} controls src={asset.url} />
    </>
  );

  const renderVideoProcessingView = () => <img className={styles.convertingImage} src={VideoConvertingPlaceholderImage} alt={''} />;

  return (
    <MediaDueType
      isVideo={asset.isVideo}
      isVideoProcessing={asset.isVideoProcessing}
      renderImageView={(onLoad: () => void) => renderImageView(onLoad)}
      renderVideoView={(onLoad: () => void) => renderVideoView(onLoad)}
      renderVideoProcessingView={renderVideoProcessingView}
      loaderClassName={styles.loader}
    />
  );
};

export default DesktopModalAssetPreview;
