import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import styles from './HeaderName.module.scss';
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit_pen.svg';
import TextField from '../FormControls/TextField';
import cs from 'classnames';
import ButtonCustom from '../../expert/components/Buttons/ButtonCustom';
import { useMediaQuery } from 'react-responsive/src';
import useTooltip, { TOOLTIP_POSITIONS } from '../../shared/hooks/fixedTooltip/useTooltip';

type PropsType = {
  headerName?: string;
  action?: (name: string) => void;
  placeholder?: string;
  canEditHeaderName?: boolean;
  nameClass?: string;
  wrapperClass?: string;
  editWrapperClass?: string;
  nameInputClass?: string;
  buttonsClass?: string;
  buttonsBlockClass?: string;
};

const HeaderName: FC<PropsType> = ({
  headerName,
  action,
  placeholder,
  canEditHeaderName,
  nameClass,
  wrapperClass,
  editWrapperClass,
  nameInputClass,
  buttonsClass,
  buttonsBlockClass,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [nameSaveLoading, setNameSaveLoading] = useState<boolean>(false);
  const [itemName, setItemName] = useState<string>(headerName || '');

  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (isEdit && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEdit]);

  useEffect(() => {
    if (!isEdit) {
      setItemName(headerName || '');
    }
  }, [headerName]);

  const updateName = async () => {
    setNameSaveLoading(true);
    action && action(itemName.trim());
    setNameSaveLoading(false);
  };

  const onCancel = () => {
    setIsEdit(false);
    setItemName(headerName || '');
  };

  const onSubmit = () => {
    if (itemName?.trim() === headerName || !itemName.trim()) {
      setItemName(itemName);
      setIsEdit(false);
      return;
    }

    setIsEdit(false);
    updateName().then(() => setItemName(itemName?.trim()));
  };

  const onEdit = () => {
    if (nameSaveLoading) return;
    setIsEdit(true);
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  const { toolTip, onEnter, onLeave } = useTooltip(TOOLTIP_POSITIONS.BOTTOM, { text: itemName, className: styles.tooltip });

  return (
    <div className={cs(styles.root, wrapperClass, { [styles.editing]: isEdit })}>
      {isEdit ? (
        <div className={cs(styles.editWrapper, editWrapperClass)}>
          <TextField
            wrapperClassName={styles.inputWrapper}
            className={cs(styles.input, nameInputClass)}
            value={itemName}
            placeholder={placeholder}
            onBlur={isMobile ? () => {} : onSubmit}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setItemName(e.target.value)}
            onKeyDown={handleKeyDown}
            ref={inputRef}
          />
          <div className={cs(styles.buttonsBlock, buttonsBlockClass)}>
            {/* @ts-ignore */}
            <ButtonCustom className={buttonsClass} outline onClick={onCancel}>
              Cancel
            </ButtonCustom>
            {/* @ts-ignore */}
            <ButtonCustom className={buttonsClass} onClick={onSubmit}>
              Save
            </ButtonCustom>
          </div>
        </div>
      ) : (
        <>
          <div className={cs(styles.name, nameClass)} onMouseEnter={onEnter} onMouseLeave={onLeave}>
            {itemName}
          </div>
          {!isMobile && toolTip}
          {canEditHeaderName && (
            <div className={styles.edit} onClick={onEdit}>
              <EditIcon />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default HeaderName;
