import React, { FC, useMemo } from 'react';
import styles from './CollaboratorsInfo.module.scss';
import cs from 'classnames';
import { ICollaborator } from '../../../../models/entities/collaborator/Collaborator';
import CollaboratorInfo from './CollaboratorInfo';
import CollaboratorsCount from './CollaboratorsCount';

const displayableIconsCount = 4;

type PropsType = {
  collaborators: ICollaborator[];
};

const CollaboratorsInfo: FC<PropsType> = props => {
  const collaboratorsToDisplay = useMemo(() => props.collaborators.slice(0, displayableIconsCount + 1), [props.collaborators]);

  return (
    <div className={cs(styles.icons)}>
      {collaboratorsToDisplay.map((collaborator, index) => (
        <CollaboratorInfo collaborator={collaborator} index={index} key={index} />
      ))}
      {props.collaborators.length > displayableIconsCount ? (
        <CollaboratorsCount collaborators={props.collaborators} displayableCollaboratorsNumber={displayableIconsCount} />
      ) : null}
    </div>
  );
};

export default CollaboratorsInfo;
