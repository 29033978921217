import React, {FC} from 'react';
import styles from './ResultsList.module.scss';
import {ResultAssetListItemType} from "../types";
import ResultItem from "../ResultItem";
import DeliverableOptionsSelect from "../DeliverableOptionsSelect";
import ReloadSVG from "../../../../../components/IconsSvg/ReloadSVG";
import ThreeDotsButton from "../../../../../components/ThreeDotsButton";
import {DeleteIcon} from "../../../../../expert/components/IconsSvg/ReactIcons/ReactIcons";
import cs from "classnames";

type PropsType = {
  assets: ResultAssetListItemType[];
  onDeliverableChange: (assetId: string | number, deliverableId: string | number) => void;
  assetsWithoutDeliverableErrors: (string | number)[];
  onReload: (assetId: string | number) => void
  onRemove: (assetId: string | number) => void
};

const ResultsList: FC<PropsType> = props => {
  return (
    <div className={styles.root}>
      {props.assets.map((asset, i) => (
        <React.Fragment key={i}>
          <ResultItem {...asset} right={
            <div className={styles.itemRight}>
              <DeliverableOptionsSelect
                className={styles.customSelect}
                dropDownClassName={styles.customDropdown}
                error={props.assetsWithoutDeliverableErrors.includes(asset.id)}
                selectedId={asset.deliverableId}
                onSelect={(deliverableId) => {
                  if (!asset.id || !deliverableId) return
                  props.onDeliverableChange(asset.id, deliverableId)
                }}
                disabled={asset.status !== 'added'}
              />
              {asset.status === "error" ?
                <ThreeDotsButton
                  className={styles.actionsButton}
                  dropdownClassName={styles.actionDropdown}
                  options={[
                    {
                      icon: <ReloadSVG className={cs(styles.icon)}/>,
                      text: "try again",
                      onClick: () => props.onReload(asset.id),
                      className: cs(styles.actionDropdownButton)
                    },
                    {
                      icon: <DeleteIcon className={cs(styles.icon, styles.deleteIcon)}/>,
                      text: "Delete",
                      onClick: () => props.onRemove(asset.id),
                      className: cs(styles.actionDropdownButton, styles.deleteButton)
                    }
                  ]}
                />
                : null}
            </div>
          }
          />
          <div className={styles.line}/>
        </React.Fragment>
      ))}
    </div>
  )
};

export default ResultsList;
