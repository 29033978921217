import { IViewBriefData } from '../ViewBrief';
import { PLATFORMS } from '../../../enums/Platforms';
import { CURRENCIES_TYPES } from '../../../enums/Currencies';
import { PREFERRED_ADD_DECLARATION_TYPES } from '../enums/AddDeclarationsTypes';
import { IBoardBriefFormikValues, LogoFileObjectType } from '../BoardBriefFormikValues';
import { GENDERS_TYPES } from '../enums/Genders';
import dayjs from 'dayjs';

export class BoardBriefFormikValuesHydrator implements IBoardBriefFormikValues {
  itemName: string | null;

  brandLogo: LogoFileObjectType;
  brandName: string | null;
  brandBackground: string | null;
  brandProvidesProduct: string | null;
  showBrandProvidesProduct: boolean;
  notes: string | null;

  // brief items
  messaging: string | null;
  campaignObjective: string[] | null;

  adInCaption: boolean;

  date_start: Date | null;
  date_end: Date | null;
  total_budget: number | null;
  total_budget_currency: CURRENCIES_TYPES;

  boosting_manager: string[] | null;
  boosting_duration: number;
  boosting_budget: number;
  boosting_budget_currency: CURRENCIES_TYPES;
  showBoosting: boolean;

  date_post: Date | null;

  gender: string | GENDERS_TYPES;
  ageMax: number | null;
  ageMin: number | null;
  city: string[];
  country: string[];
  interests: string[];
  showTargetAudience: boolean;

  hashtags: string[] | null;
  socialTagging: string[] | null;

  showExclusivity: boolean;
  exclusivity: string | null;

  repostingPlatform: PLATFORMS[] | null;
  repostingDuration: number | null;
  showSocial: boolean;

  other_infos: string | null;

  constructor(boardBrief: IViewBriefData) {
    this.itemName = boardBrief.item_name || '';

    this.brandLogo = {
      file: null,
      fileUrl: boardBrief.brand_logo || null,
    };
    this.brandName = boardBrief.brand_name || '';
    this.brandBackground = boardBrief.brand_description || '';
    this.brandProvidesProduct = boardBrief.brand_provides_product || '';
    this.showBrandProvidesProduct = !!this.brandProvidesProduct || false;
    this.notes = boardBrief.notes || '';

    this.messaging = boardBrief.key_message || '';
    this.campaignObjective = boardBrief.objective || [];

    this.adInCaption = boardBrief.preferred_ad_declaration === PREFERRED_ADD_DECLARATION_TYPES.AD_IN_CAPTION;

    this.date_start = boardBrief.date_start ? dayjs(boardBrief.date_start).toDate() : null;
    this.date_end = boardBrief.date_end ? dayjs(boardBrief.date_end).toDate() : null;
    this.total_budget = boardBrief.total_budget || null;
    this.total_budget_currency = boardBrief.total_budget_currency || CURRENCIES_TYPES.NZD;

    this.boosting_manager = boardBrief.boosting_manager || [];
    this.boosting_duration = boardBrief.boosting_duration || 0;
    this.boosting_budget = boardBrief.boosting_budget || 0;
    this.boosting_budget_currency = boardBrief.boosting_budget_currency || CURRENCIES_TYPES.NZD;
    this.showBoosting = Boolean(
      this.boosting_manager?.length ||
        this.boosting_duration ||
        this.boosting_budget ||
        this.boosting_budget_currency !== CURRENCIES_TYPES.NZD
    );

    this.date_post = boardBrief.live_date_start ? dayjs(boardBrief.live_date_start).toDate() : dayjs(new Date()).toDate();

    this.gender = boardBrief.target_audience ? boardBrief.target_audience.gender[0] : '';
    this.ageMax = boardBrief.target_audience?.age ? boardBrief.target_audience.age.max : null;
    this.ageMin = boardBrief.target_audience?.age ? boardBrief.target_audience.age.min : null;
    this.city = boardBrief.target_audience?.location?.city ? boardBrief.target_audience.location.city : [];
    this.country = boardBrief.target_audience?.location?.country ? boardBrief.target_audience.location.country : [];
    this.interests = boardBrief.target_audience?.interests_and_behaviours ? boardBrief.target_audience.interests_and_behaviours : [];
    this.showTargetAudience = Boolean(
      this.gender?.length || this.ageMax || this.ageMin || this.city?.length || this.country?.length || this.interests?.length
    );

    this.hashtags = boardBrief.hashtags || [];
    this.socialTagging = boardBrief.social_tagging || [];

    this.exclusivity = boardBrief.exclusivity || '';
    this.showExclusivity = !!this.exclusivity;

    this.repostingPlatform = boardBrief.reposting_platform || [];
    this.repostingDuration = boardBrief.reposting_duration || 1;
    this.showSocial = Boolean(this.repostingPlatform?.length);

    this.other_infos = boardBrief.other_infos || '';
  }
}
