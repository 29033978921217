import { DELIVERABLE_CALL_TO_ACTIONS } from '../../../models/entities/deliverable/enums/DeliverableCallToActions';

export const DELIVERABLE_FLOWS = {
  CC_BA: 'CC_BA',
  CC_TM_BA: 'CC_TM_BA',
};

export const DELIVERABLE_CALL_TO_ACTIONS_TEXTS: { label: string; value: DELIVERABLE_CALL_TO_ACTIONS }[] = [
  { label: 'Apply Now', value: DELIVERABLE_CALL_TO_ACTIONS.APPLY_NOW },
  { label: 'Book Now', value: DELIVERABLE_CALL_TO_ACTIONS.BOOK_TRAVEL },
  { label: 'Call Now', value: DELIVERABLE_CALL_TO_ACTIONS.CALL_NOW },
  { label: 'Contact Us', value: DELIVERABLE_CALL_TO_ACTIONS.CONTACT_US },
  { label: 'Download', value: DELIVERABLE_CALL_TO_ACTIONS.DOWNLOAD },
  { label: 'Get Directions', value: DELIVERABLE_CALL_TO_ACTIONS.GET_DIRECTIONS },
  { label: 'Get Quote', value: DELIVERABLE_CALL_TO_ACTIONS.GET_QUOTE },
  { label: 'Get Showtimes', value: DELIVERABLE_CALL_TO_ACTIONS.GET_SHOWTIMES },
  { label: 'Learn More', value: DELIVERABLE_CALL_TO_ACTIONS.LEARN_MORE },
  { label: 'Listen Now', value: DELIVERABLE_CALL_TO_ACTIONS.LISTEN_NOW },
  { label: 'Order Now', value: DELIVERABLE_CALL_TO_ACTIONS.ORDER_NOW },
  { label: 'Request Time', value: DELIVERABLE_CALL_TO_ACTIONS.REQUEST_TIME },
  { label: 'Save', value: DELIVERABLE_CALL_TO_ACTIONS.SAVE },
  { label: 'See Menu', value: DELIVERABLE_CALL_TO_ACTIONS.SEE_MENU },
  { label: 'Send Message', value: DELIVERABLE_CALL_TO_ACTIONS.MESSAGE_PAGE },
  { label: 'Send Whatsapp Message', value: DELIVERABLE_CALL_TO_ACTIONS.WHATSAPP_MESSAGE },
  { label: 'Shop Now', value: DELIVERABLE_CALL_TO_ACTIONS.SHOP_NOW },
  { label: 'Sign Up', value: DELIVERABLE_CALL_TO_ACTIONS.SIGN_UP },
  { label: 'Subscribe', value: DELIVERABLE_CALL_TO_ACTIONS.SUBSCRIBE },
  { label: 'Watch More', value: DELIVERABLE_CALL_TO_ACTIONS.WATCH_MORE },
  { label: 'Get Offer', value: DELIVERABLE_CALL_TO_ACTIONS.GET_OFFER },
  { label: 'Play Game', value: DELIVERABLE_CALL_TO_ACTIONS.PLAY_GAME },
];
