import { ICreateBoardBriefAsset } from '../CreateBoardBriefAsset';
import { IBriefAsset } from '../BriefAsset';

export class BoardBriefFormAssetHydrator implements ICreateBoardBriefAsset {
  id: string | number;
  file: File | null;
  fileUrl: string;
  description: string;

  constructor(visualElement: IBriefAsset, newId?: string) {
    this.id = newId || visualElement.id;
    this.file = null;
    this.fileUrl = visualElement.url || '';
    this.description = visualElement.description || '';
  }

  async setFileByUrl(): Promise<void> {
    if (!this.fileUrl.trim().length) return;
    return fetch(this.fileUrl)
      .then(res => res.blob())
      .then(blob => {
        this.file = new File([blob], blob.name, { type: blob.type });
      });
  }
}
