import useDownloadAsset, { DOWNLOAD_RESULT_STATUSES } from '../../../shared/hooks/useDownloadAsset';
import { useCallback, useEffect, useMemo } from 'react';
import { IResultAsset } from '../../../models/entities/asset/ResultAsset';
import { createNotification } from '../../../actions/toastNotificationActions/toastNotification.actions';
import notificationDataConfig from '../../../shared/utils/notificationDataConfig';
import { TOAST_NOTIFICATION_TYPES } from '../../../shared/constants/toastNotificationsData';
import { useAppDispatch } from '../../../shared/hooks/reduxHooks';

export function useResultAssetsDownload(assets: IResultAsset[]) {
  const dispatch = useAppDispatch();
  const onResult = useMemo(() => {
    return {
      success: () => {
        dispatch(createNotification(notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.DOWNLOAD_ALL_ASSETS_SUCCESS, {})));
      },
      partiallyFailed: () => {
        dispatch(
          createNotification(notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.DOWNLOAD_ALL_ASSETS_PARTIALLY_FAILED, {}))
        );
      },
      allFailed: () => {
        dispatch(createNotification(notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.DOWNLOAD_ALL_ASSETS_FAILED, {})));
      },
    };
  }, []);

  const { isAssetsArrayLoading, downloadAssetsArray, resultStatus } = useDownloadAsset();

  useEffect(() => {
    if (resultStatus && onResult) {
      if (resultStatus === DOWNLOAD_RESULT_STATUSES.SUCCESS) onResult.success();
      if (resultStatus === DOWNLOAD_RESULT_STATUSES.PARTIALLY_FAILED) onResult.partiallyFailed();
      if (resultStatus === DOWNLOAD_RESULT_STATUSES.ALL_FAILED) onResult.allFailed();
    }
  }, [resultStatus]);

  const downloadAssets = useCallback(() => {
    if (isAssetsArrayLoading) return;
    downloadAssetsArray(
      assets.map(asset => ({
        assetUrl: asset.location,
        extension: asset.extension,
      }))
    );
  }, [assets, isAssetsArrayLoading]);

  return downloadAssets;
}
