import React, { FC, ReactElement, ReactNode } from 'react';
import styles from './NotificationPreferencesLayout.module.scss';
import FullLogoSVG from '../../../../../expert/components/IconsSvg/LogoSVG';
import ButtonCustom from '../../../../../expert/components/Buttons/ButtonCustom';
import { useHistory } from 'react-router-dom';
import cs from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as SmallLogoSVG } from '../../../../../assets/img/icons/otterfish_smal_logo.svg';

type PropsType = {
  children: ReactElement | ReactNode | JSX.Element;
  contentClassName: string;
};

const NotificationPreferencesLayout: FC<PropsType> = props => {
  const history = useHistory();
  const isTablet: boolean = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <>
      <header className={styles.header}>
        <div className={cs(styles.logo, isTablet ? styles.smallLogo : styles.bigLogo)} onClick={() => history.push('/content-approval')}>
          {isTablet ? <SmallLogoSVG /> : <FullLogoSVG />}
        </div>
        <ButtonCustom onClick={() => history.push('/content-approval')} className={styles.whiteButton}>
          Go to Otterfish
        </ButtonCustom>
      </header>
      <main className={styles.main}>
        <div className={props.contentClassName}>{props.children}</div>
      </main>
    </>
  );
};

export default NotificationPreferencesLayout;
