import notificationData, { ToastNotificationSettingsType } from '../constants/toastNotificationsData';
import { INotification, NotificationConfigObjectType, NotificationsConfigType, NotificationSettingsType } from '../../models/Notifications';

type OnlyStringKeys<Object extends object> = Extract<keyof Object, string>;

type ReplaceFunctionsWithReturnType<Object> = {
  [K in keyof Object]: Extract<Object[K], (...args: any) => any> extends never
    ? Extract<Object[K], object> extends never
      ? Object[K]
      : ReplaceFunctionsWithReturnType<Object[K]>
    : ReturnType<Extract<Object[K], (...args: any) => any>> extends void
    ? Object[K]
    : ReturnType<Extract<Object[K], (...args: any) => any>>;
};

class NotificationDataConfig<NotificationData extends NotificationsConfigType, SettingsType extends NotificationSettingsType> {
  private readonly notificationData: NotificationData;

  constructor(notificationData: NotificationData) {
    this.notificationData = notificationData;
  }

  public getNotificationData<NotificationKey extends OnlyStringKeys<NotificationData>>(
    notificationType: NotificationKey,
    settings: SettingsType[NotificationKey]
  ): INotification {
    const notificationData = this.notificationData[notificationType];

    if (!Object.keys(settings).length && this.isNotificationDataTypeEqualINotification(notificationData)) {
      return notificationData;
    }

    return this.applyNotificationSettings<NotificationConfigObjectType, SettingsType[NotificationKey]>(notificationData, settings);
  }

  private applyNotificationSettings<NotificationObjectType extends Object, SettingsObjectType extends Object>(
    notification: NotificationObjectType,
    settings: SettingsObjectType
  ): ReplaceFunctionsWithReturnType<NotificationObjectType> {
    const result = (Object.keys(notification) as (OnlyStringKeys<NotificationObjectType> & OnlyStringKeys<SettingsObjectType>)[]).reduce<
      ReplaceFunctionsWithReturnType<NotificationObjectType>
    >((acc, key) => {
      const notificationValue = notification[key];
      const settingValue = settings[key];

      if (typeof notificationValue === 'function' && Array.isArray(settingValue)) {
        return { ...acc, [key]: notificationValue(...settingValue) };
      }

      const isNotificationObject =
        typeof notificationValue === 'object' && !Array.isArray(notificationValue) && !(notificationValue === null);
      const isSettingsObject = typeof settingValue === 'object' && !Array.isArray(settingValue) && !(settingValue === null);

      if (isNotificationObject && isSettingsObject) {
        return {
          ...acc,
          [key]: this.applyNotificationSettings<typeof notificationValue, typeof settingValue>(notificationValue, settingValue),
        };
      }

      return { ...acc, [key]: settingValue ? settingValue : notificationValue };
    }, <ReplaceFunctionsWithReturnType<NotificationObjectType>>{});

    return result;
  }

  private isNotificationDataTypeEqualINotification(notificationData: Object): notificationData is INotification {
    return (
      (!('dateString' in notificationData) || typeof notificationData.dateString !== 'function') &&
      (!('image' in notificationData) || typeof notificationData.image !== 'function') &&
      (!('title' in notificationData) || typeof notificationData.title !== 'function') &&
      (!('text' in notificationData) || typeof notificationData.text !== 'function') &&
      (!('primaryButtonData' in notificationData) ||
        (notificationData.primaryButtonData instanceof Object &&
          'text' in notificationData.primaryButtonData &&
          typeof notificationData.primaryButtonData.text !== 'function' &&
          'action' in notificationData.primaryButtonData &&
          typeof notificationData.primaryButtonData.action === 'function')) &&
      (!('secondaryButtonData' in notificationData) ||
        (notificationData.secondaryButtonData instanceof Object &&
          'text' in notificationData.secondaryButtonData &&
          typeof notificationData.secondaryButtonData.text !== 'function' &&
          'action' in notificationData.secondaryButtonData &&
          typeof notificationData.secondaryButtonData.action === 'function'))
    );
  }
}

const notificationDataConfig = new NotificationDataConfig<typeof notificationData, ToastNotificationSettingsType>(notificationData);

export default notificationDataConfig;
