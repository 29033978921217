import AppCentrifuge from '../centrifuge/centrifuge';
import { store } from '../index';
import { Action } from 'redux';

enum CENTRIFUGE_ACTIONS_TYPES {
  SET_IS_CENTRIFUGE_CONNECTED = 'SET_IS_CENTRIFUGE_CONNECTED',
}

type SetIsCentrifugeConnectedActionType = Action<CENTRIFUGE_ACTIONS_TYPES.SET_IS_CENTRIFUGE_CONNECTED> & { payload: boolean };

type CentrifugeActionsTypes = SetIsCentrifugeConnectedActionType;

export type CentrifugeStateType = {
  appCentrifuge: AppCentrifuge;
  isCentrifugeConnected: boolean;
};

const initialState: CentrifugeStateType = {
  appCentrifuge: AppCentrifuge.getInstance()
    .subscribeConnect(() => store.dispatch(setIsCentrifugeConnected(true)))
    .subscribeDisconnect(() => store.dispatch(setIsCentrifugeConnected(false))),
  isCentrifugeConnected: false,
};

export default (state: CentrifugeStateType = initialState, { type, payload }: CentrifugeActionsTypes): CentrifugeStateType => {
  switch (type) {
    case CENTRIFUGE_ACTIONS_TYPES.SET_IS_CENTRIFUGE_CONNECTED: {
      return {
        ...state,
        isCentrifugeConnected: payload,
      };
    }
    default:
      return state;
  }
};

export const setIsCentrifugeConnected = isConnected => ({
  type: CENTRIFUGE_ACTIONS_TYPES.SET_IS_CENTRIFUGE_CONNECTED,
  payload: isConnected,
});
