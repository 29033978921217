import { CURRENCIES_TYPES } from '../../../../../../models/enums/Currencies';

const getMoneyView = (currency: CURRENCIES_TYPES, amount: number) => {
  let currencyView: string;
  if (currency === CURRENCIES_TYPES.USD) {
    currencyView = '$';
  } else {
    currencyView = `${currency} `;
  }

  const amountView = amount.toLocaleString('en-US', { maximumFractionDigits: 2 });

  return `${currencyView}${amountView}`;
};

export default getMoneyView;
