import React, { FC, ReactElement, ReactNode, SVGProps } from 'react';
import styles from './LoadingButton.module.scss';
import cs from 'classnames';

type PropsType = {
  children?: ReactNode | ReactElement | JSX.Element;
  Icon?: FC<{ size?: number } & unknown> | FC<SVGProps<SVGSVGElement>>;
  className?: string;
  iconWrapperClass?: string;
  childrenWrapperClass?: string;
};

const LoadingButton: FC<PropsType> = props => {
  return (
    <button className={cs(styles.root, props.className)}>
      {props.Icon ? (
        <div className={cs(styles.icon, props.iconWrapperClass)}>
          <props.Icon />
        </div>
      ) : null}
      {props.children ? <div className={props.childrenWrapperClass}>{props.children}</div> : null}
    </button>
  );
};

export default LoadingButton;
