import React, { FC, PropsWithChildren } from 'react';
import VisualPopover from '../VisualPopover';
import styles from './CollaboratorsTooltip.module.scss';
import UserColoredIcon from '../../UserColoredIcon';
import cs from 'classnames';
import { ICollaborator } from '../../../models/entities/collaborator/Collaborator';
import { TYPES_CAMPAIGNS_TEXTS } from '../../../shared/constants/constants';

type PropsType = {
  collaborators: ICollaborator[];
  indexes?: number[];
  withoutToolTip?: boolean;
} & PropsWithChildren;

const CollaboratorsTooltip: FC<PropsType> = props => {
  if (props.withoutToolTip) {
    return <>{props.children}</>;
  }

  return (
    <VisualPopover
      placement={'bottom'}
      render={() =>
        props.collaborators.map((collaborator, index) => {
          const collaboratorFullName = `${collaborator.collaborator_first_name} ${collaborator.collaborator_last_name}`;
          return (
            <div className={styles.root} key={index}>
              <div className={styles.collaboratorsCell}>
                {collaborator.photo_thumbnail_location ? (
                  <img className={styles.collaboratorImage} src={collaborator.photo_thumbnail_location} alt={collaboratorFullName} />
                ) : (
                  <UserColoredIcon
                    colorNumber={props.indexes ? props.indexes[index] : index}
                    className={styles.collaboratorImage}
                    text={collaborator.collaborator_first_name.slice(0, 1).toUpperCase()}
                  />
                )}
                <p className={cs(styles.cellText, styles.collaboratorName)}>{collaboratorFullName}</p>
                <p className={cs(styles.cellText, styles.collaboratorType)}>{TYPES_CAMPAIGNS_TEXTS[collaborator.collaborator_type]}</p>
              </div>
            </div>
          );
        })
      }
    >
      {props.children}
    </VisualPopover>
  );
};

export default CollaboratorsTooltip;
