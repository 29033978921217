import React, { FC } from 'react';
import styles from './ToastNotification.module.scss';
import { ReactComponent as ErrorIcon } from '../../assets/img/icons/toast-error-icon.svg';
import { ReactComponent as CrossIcon } from '../../assets/img/icons/close.svg';
import { ReactComponent as SuccessIcon } from '../../assets/img/icons/toast-success-icon.svg';
import { ReactComponent as InfoIcon } from '../../assets/img/icons/information-icon.svg';
import { ReactComponent as ClockIcon } from '../../assets/img/icons/clock-date-icon.svg';
import { ReactComponent as Clock2Icon } from '../../assets/img/icons/clock-date-icon-2.svg';
import { deleteNotification } from '../../actions/toastNotificationActions/toastNotification.actions';
import cs from 'classnames';
import ButtonCustom from '../../expert/components/Buttons/ButtonCustom';
import { TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES } from '../../models/Notifications';
import { useAppDispatch, useAppSelector } from '../../shared/hooks/reduxHooks';
import { IStateToastNotification } from '../../actions/toastNotificationActions/types';

const icons = {
  [TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.SUCCESS]: <SuccessIcon className={styles.iconSVG} />,
  [TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.ERROR]: <ErrorIcon className={styles.iconSVG} />,
  [TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.INFO]: <InfoIcon className={styles.iconSVG} />,
  [TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.CLOCK]: <ClockIcon className={styles.iconSVG} />,
  [TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.CLOCK2]: <Clock2Icon className={styles.iconSVG} />,
};

const ToastNotification: FC = () => {
  const notification = useAppSelector<IStateToastNotification[]>(state => state.toastNotification.notification);
  const dispatch = useAppDispatch();

  const onClose = (item: IStateToastNotification): void => {
    dispatch(deleteNotification(item));
  };

  return (
    <div className={styles.root}>
      {notification.map((item, index) => (
        <div key={item.id + index} className={cs(styles.notificationBlock)}>
          {item.type && (
            <span className={cs(styles.icon)}>
              {item.image ? (
                <img className={styles.image} src={typeof item.image === 'string' ? item.image : ''} alt="member image" />
              ) : (
                icons[item.type]
              )}
            </span>
          )}
          <div className={styles.content}>
            {item.title && <h3 className={styles.title}>{item.title}</h3>}

            {(item.textCallback && item.textCallback(() => onClose(item))) ||
              (item.text && <span className={styles.text}>{item.text}</span>)}

            {(!!item.primaryButtonData || !!item.secondaryButtonData || !!item.dateString) && (
              <div className={styles.footer}>
                <div className={styles.buttonsContainer}>
                  {item.primaryButtonData && (
                    <ButtonCustom
                      clearBg
                      onClick={() => item.primaryButtonData && item.primaryButtonData.action(() => onClose(item))}
                      className={cs(styles.btn, styles.primaryButton)}
                    >
                      {item.primaryButtonData.text}
                    </ButtonCustom>
                  )}
                  {item.secondaryButtonData && (
                    <ButtonCustom
                      outline
                      onClick={() => item.secondaryButtonData && item.secondaryButtonData.action(() => onClose(item))}
                      className={cs(styles.btn, styles.secondaryButton)}
                    >
                      {item.secondaryButtonData.text}
                    </ButtonCustom>
                  )}
                </div>
                {item.dateString && <span className={styles.date}>{item.dateString}</span>}
              </div>
            )}
          </div>

          <CrossIcon className={styles.closeIcon} onClick={() => onClose(item)} />
        </div>
      ))}
    </div>
  );
};

export default ToastNotification;
