import { Moment } from 'moment';

const getDateDurationText = (start: Moment | null, end: Moment | null, dateFormat: string) => {
  if (!start && !end) return null;

  const startFormattedDate = start ? start.format(dateFormat) : 'Not set';
  const endFormattedDate = end ? end?.format(dateFormat) : 'Not set';

  return `${startFormattedDate} - ${endFormattedDate}`;
};

export default getDateDurationText;
