import React, {FC, ReactNode} from 'react';
import styles from './UploadedResultsItem.module.scss';
import {ResultAssetListItemType} from "../types";
import {MEDIA_FILE_TYPES} from "../../../../../models/entities/file/enums/MediaFileTypes";
import ImagePlaceholderIconV1 from "../../../../../components/IconsSvg/ImagePlaceholderIconV1";
import VideoPlaceHolderSVG from "../../../../../components/IconsSvg/VideoPlaceHolderSVG";
import CheckMarkGreenShadowSVG from "../../../../../components/IconsSvg/CheckMarkGreenShadowSVG";
import DangerTriangleSVG from "../../../../../components/IconsSvg/DangerTriangleSVG";
import LoadingSvg from "../../../../../components/IconsSvg/LoadingSVG";

type PropsType = ResultAssetListItemType & {
  right?: ReactNode;
};

const STATUS_ICONS_MAP = {
  error: <DangerTriangleSVG className={styles.statusIcon}/>,
  loading: <LoadingSvg className={styles.statusIcon}/>,
  uploaded: <CheckMarkGreenShadowSVG className={styles.statusIcon}/>
}

const MEDIA_ICONS_MAP = {
    [MEDIA_FILE_TYPES.IMAGE]: <ImagePlaceholderIconV1 className={styles.mediaIcon}/>,
    [MEDIA_FILE_TYPES.VIDEO]: <VideoPlaceHolderSVG className={styles.mediaIcon}/>,
}

const ResultItem: FC<PropsType> = props => {
    return (
        <div className={styles.root}>
            <div className={styles.left}>
              {STATUS_ICONS_MAP[props.status]}
                <div className={styles.imageNameContainer}>
                    {MEDIA_ICONS_MAP[props.type]}
                    <p className={styles.name}>{props.filename}</p>
                </div>
            </div>
          {props.right}
        </div>
    )
};

export default ResultItem;
