import { FormikErrors } from 'formik';
import { useMemo } from 'react';

const useFormikErrorsArray = <FormikStateType extends object>(
  errorsArg: FormikErrors<FormikStateType> | null,
  errorTextSettings: { [K in keyof FormikStateType]?: string }
) => {
  const formikErrorsArray = useMemo(() => {
    if (!errorsArg) return [];
    const result: string[] = [];
    (Object.entries(errorsArg) as [string, FormikErrors<FormikStateType>[keyof FormikErrors<FormikStateType>]][]).forEach(
      ([errorKey, errorValue]) => {
        if (errorValue && errorKey in errorTextSettings) {
          result.push(errorTextSettings[errorKey]);
        }
      }
    );

    return result;
  }, [errorsArg]);

  return formikErrorsArray;
};

export default useFormikErrorsArray;
