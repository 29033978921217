import { IBriefAsset } from '../../../../models/entities/asset/BriefAsset';
import { BoardBriefFormAssetHydrator } from '../../../../models/entities/asset/hydrators/BoardBriefFormAssetHydrator';
import { ICreateBoardBriefAsset } from '../../../../models/entities/asset/CreateBoardBriefAsset';
import { uniqueId } from 'lodash';

const setAsyncNewBriefFormAssets = async (assets: IBriefAsset[]): Promise<ICreateBoardBriefAsset[]> => {
  const promiseArr: Promise<void>[] = [];

  const newAssets = assets.map(asset => {
    const newAsset = new BoardBriefFormAssetHydrator(asset, uniqueId('new-board-brief-asset_'));

    promiseArr.push(newAsset.setFileByUrl());

    return newAsset;
  });

  await Promise.allSettled(promiseArr);

  return newAssets;
};

export default setAsyncNewBriefFormAssets;
