import React from 'react';

const ImagePlaceholderIcon = ({ isWhite = false, size = 40 }) => {
  if (isWhite) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" style={{ width: size, height: size }} width="30" height="31" viewBox="0 0 30 31" fill="none">
        <g clipPath="url(#clip0_1608_47377)">
          <circle cx="10.1473" cy="10.6464" r="3.97059" fill="white" />
          <path
            d="M5.04348 21.7498C3.17391 21.3698 -0.097826 24.7581 -1.5 26.4998L0.369565 31.25H30.75V23.175C28.5688 21.1167 23.5522 17 20.9348 17C17.663 17 14.8587 22.225 11.587 23.175C8.31522 24.125 7.38043 22.2248 5.04348 21.7498Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 4.5C0 2.29086 1.79086 0.5 4 0.5H26C28.2091 0.5 30 2.29086 30 4.5V22.4878C27.5709 20.32 23.2853 17 20.9348 17C19.1492 17 17.5029 18.5562 15.8439 20.1244C14.4629 21.4298 13.0732 22.7435 11.587 23.175C9.44864 23.7959 8.30856 23.1993 7.12285 22.5788C6.49437 22.2499 5.85306 21.9143 5.04348 21.7498C3.68436 21.4735 1.5842 23.1888 0 24.8152V4.5ZM14.117 10.6464C14.117 12.8393 12.3393 14.617 10.1464 14.617C7.95347 14.617 6.17578 12.8393 6.17578 10.6464C6.17578 8.45347 7.95347 6.67578 10.1464 6.67578C12.3393 6.67578 14.117 8.45347 14.117 10.6464Z"
            fill="white"
            fillOpacity="0.3"
          />
        </g>
        <defs>
          <clipPath id="clip0_1608_47377">
            <rect y="0.5" width="30" height="30" rx="4" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_625_27538)">
        <rect width="40" height="40" rx="4" fill="#BFBBEE" />
        <circle cx="13.5295" cy="13.5285" r="5.29412" fill="#ECECFF" />
        <path
          d="M6.72464 28.333C4.23188 27.8264 -0.130435 32.3442 -2 34.6664L0.492754 41H41V30.2333C38.0918 27.4889 31.4029 22 27.913 22C23.5507 22 19.8116 28.9667 15.4493 30.2333C11.087 31.5 9.84058 28.9664 6.72464 28.333Z"
          fill="#ECECFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_625_27538">
          <rect width="40" height="40" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ImagePlaceholderIcon;
