import cs from 'classnames';
import styles from './TextColorDueDate.module.scss';
import React, { FC } from 'react';
import { DELIVERABLE_STATUSES } from '../../models/enums/DeliverableStatuses';
import moment from 'moment/moment';

type PropsType = {
  className?: string;
  baseClassName?: string;
  contentStatus?: DELIVERABLE_STATUSES;
  isPastedDate?: boolean;
  isUpcomingDate?: boolean;
  children?: JSX.Element | string | null;
  date?: string | moment.Moment | Date | null;
};

const TextColorDueDate: FC<PropsType> = ({ className, baseClassName, contentStatus, isPastedDate, isUpcomingDate, children, ...props }) => {
  const date = props?.date;
  const isApproved = contentStatus === DELIVERABLE_STATUSES.APPROVED;

  const dateClassName = cs(className, {
    [baseClassName || '']: !isPastedDate && !isUpcomingDate,
    [styles.withoutDate]: !date && !isApproved,
    [styles.pastDate]: isPastedDate && !isApproved,
    [styles.upcomingDate]: isUpcomingDate && !isApproved,
  });

  return <span className={dateClassName}>{children}</span>;
};

export default TextColorDueDate;
