import { BRIEF_TYPES } from '../actions/brief.actions';

const currentBriefInitState = {
  data: null,
  visualAssets: [],
  items: [],
  creatorItems: [],
  briefItem: null, // master brief or creator brief
  collaborators: [],
  invitedCollaborators: [],
  creatorBriefBoards: [],
};

const initialState = {
  briefList: [],
  briefListTabSwitcher: 'campaigns',
  currentBrief: currentBriefInitState,
  briefListLoading: true,
  totalCount: 0,
  archivedTotalCount: 0,
  failure: '',
};

export default function briefReducer(state = initialState, { type, payload }) {
  switch (type) {
    case BRIEF_TYPES.SET_BRIEF_LIST_TAB_SWITCHER: {
      return {
        ...state,
        briefListTabSwitcher: payload,
      };
    }

    case BRIEF_TYPES.CLEAR_FAILURE: {
      return { ...state, failure: '' };
    }
    case BRIEF_TYPES.CREATE_BRIEF.REQUEST: {
      return { ...state, failure: '' };
    }
    case BRIEF_TYPES.CREATE_BRIEF.SUCCESS: {
      return { ...state, failure: '' };
    }
    case BRIEF_TYPES.CREATE_BRIEF.FAILURE: {
      return { ...state, failure: payload };
    }
    case BRIEF_TYPES.GET_BRIEF_LIST.REQUEST: {
      return { ...state, briefListLoading: payload !== false };
    }
    case BRIEF_TYPES.GET_BRIEF_LIST.SUCCESS: {
      return {
        ...state,
        briefListLoading: false,
        briefList: payload.data,
        totalCount: payload.total_count,
        archivedTotalCount: payload.archived_total_count,
      };
    }

    case BRIEF_TYPES.UPDATE_BRIEFS_LIST: {
      return {
        ...state,
        briefList: payload,
      };
    }

    case BRIEF_TYPES.GET_BRIEF_LIST.FAILURE: {
      return { ...state, briefListLoading: false };
    }
    case BRIEF_TYPES.GET_BRIEF.REQUEST: {
      return { ...state, currentBrief: currentBriefInitState };
    }
    case BRIEF_TYPES.GET_BRIEF.SUCCESS: {
      return { ...state, currentBrief: { ...state.currentBrief, data: payload.data } };
    }
    case BRIEF_TYPES.GET_BRIEF.FAILURE: {
      return { ...state, currentBrief: currentBriefInitState };
    }
    case BRIEF_TYPES.UPDATE_BRIEF.FAILURE: {
      return { ...state, failure: payload };
    }
    case BRIEF_TYPES.GET_BRIEF_VISUAL_ASSETS.SUCCESS: {
      return { ...state, currentBrief: { ...state.currentBrief, visualAssets: payload.data } };
    }
    case BRIEF_TYPES.GET_BRIEF_ITEMS.SUCCESS: {
      return { ...state, currentBrief: { ...state.currentBrief, items: payload.data } };
    }
    case BRIEF_TYPES.GET_BRIEF_CREATOR_ITEMS.SUCCESS: {
      return { ...state, currentBrief: { ...state.currentBrief, creatorItems: payload.data } };
    }
    case BRIEF_TYPES.GET_BRIEF_ITEM.SUCCESS: {
      return { ...state, currentBrief: { ...state.currentBrief, briefItem: payload.data } };
    }
    case BRIEF_TYPES.GET_CREATOR_BRIEF_BOARDS.SUCCESS: {
      return { ...state, currentBrief: { ...state.currentBrief, creatorBriefBoards: payload.data } };
    }
    case BRIEF_TYPES.ADD_BRIEF_VISUAL_ASSET.SUCCESS: {
      return {
        ...state,
        currentBrief: { ...state.currentBrief, visualAssets: [...state.currentBrief.visualAssets, ...payload.visualElements] },
      };
    }
    case BRIEF_TYPES.DELETE_BRIEF_VISUAL_ASSET.SUCCESS: {
      return {
        ...state,
        currentBrief: { ...state.currentBrief, visualAssets: state.currentBrief.visualAssets.filter(item => item.id != payload.assetID) },
      };
    }
    case BRIEF_TYPES.GET_COLLABORATORS.SUCCESS: {
      return { ...state, currentBrief: { ...state.currentBrief, collaborators: payload.data } };
    }
    case BRIEF_TYPES.GET_INVITED_COLLABORATORS.SUCCESS: {
      return { ...state, currentBrief: { ...state.currentBrief, invitedCollaborators: payload.data } };
    }
    case BRIEF_TYPES.GET_INVITED_COLLABORATORS.REQUEST: {
      return { ...state, briefListLoading: false };
    }
    case BRIEF_TYPES.GET_INVITED_COLLABORATORS.FAILURE: {
      return { ...state, briefListLoading: true };
    }
    default: {
      return state;
    }
  }
}
