import React from 'react';
import Discussion from '../../../expert/components/Discussion';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../shared/hooks/reduxHooks';

const ContentApprovalDiscussionWrapper = () => {
  const params = useParams();
  const { collaborators } = useAppSelector(state => state.contentBoard);
  return <Discussion collaborators={collaborators} objectItemID={params.boardId} itemType={'content_board'} />;
};

export default ContentApprovalDiscussionWrapper;
