import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Modal } from '../../../../../../../components/Modals';
import styles from './DelegateOwnershipModal.module.scss';
import ButtonCustom from '../../../../../../../expert/components/Buttons/ButtonCustom';
import EmojiSadVG from '../../../../../../../components/IconsSvg/EmojiSadVG';
import cs from 'classnames';
import { getAllMembers } from '../../../../../../../actions/organizations.actions';
import CollaboratorsAvatar from '../../../../../../../components/CollaboratorsAvatar';
import { updateBoardCollaborators } from '../../../../../../../actions/ContentBoardActions/contentBoard.actions';
import { useParams } from 'react-router-dom';
import TextArea from '../../../../../../../components/Inputs/TextArea';
import { useAppDispatch, useAppSelector } from '../../../../../../../shared/hooks/reduxHooks';
import { IBoardCollaborator } from '../../../../../../../models/entities/board/BoardCollaborator';

type PropsType = {
  open: boolean;
  onClose: () => void;
  collaborators: IBoardCollaborator[];
};

function DelegateOwnershipModal(props: PropsType) {
  const dispatch = useAppDispatch();
  const params = useParams<{ boardId: string }>();

  const currentOrganization = useAppSelector(state => state.auth.currentOrganization);
  const { data: members, loading: memberLoading } = useAppSelector(state => state.organizations.allMembers);

  useEffect(() => {
    if (currentOrganization) {
      dispatch(getAllMembers(currentOrganization.organization_id, 1, 100));
    }
  }, [currentOrganization]);

  const [inputValue, setInputValue] = useState('');
  const [selectedMemberID, setSelectedMemberID] = useState(11);

  const onDelegateHandler = () => {
    dispatch(
      updateBoardCollaborators(currentOrganization?.organization_id, params.boardId, {
        approver_id: selectedMemberID,
        custom_message: inputValue.trim().length ? inputValue : null,
      })
    );
    props.onClose();
  };

  const activeMembers = useMemo(() => {
    return members?.filter(member => member.status_in_organization == 'active');
  }, [members]);

  const totalActiveMembers = useMemo(() => {
    return activeMembers?.length || 0;
  }, [activeMembers]);

  const ownerUserId = useMemo(() => {
    if (!props.collaborators?.length || !currentOrganization) return;
    const currentCollaborator = props.collaborators.find(
      collaborator => collaborator.organization_id == currentOrganization.organization_id
    );

    if (!currentCollaborator) return;

    return currentCollaborator.approver_id;
  }, [props.collaborators, currentOrganization]);

  return (
    <Modal className={styles.modalRoot} open={props.open} onClose={props.onClose} maxWidth="380px" borderRadius="10px">
      <div className={styles.modalContent}>
        <div>
          <h3>Delegate Ownership</h3>
          <p>Choose a team member from your organization.</p>
        </div>
        <div className={styles.organizationHeading}>{currentOrganization?.organization_name}</div>

        {totalActiveMembers > 1 && !memberLoading && (
          <>
            <div className={styles.memberList}>
              {activeMembers.map(
                member =>
                  member.user_id &&
                  member.user_id != ownerUserId && (
                    <div key={member.user_id} className={styles.memberItem} onClick={() => setSelectedMemberID(member.user_id)}>
                      <div className={cs(styles.radioButton, { [styles.active]: member.user_id === selectedMemberID })} />
                      <CollaboratorsAvatar imageUrl={member.photo_location} name={`${member.first_name}  ${member.last_name}`} secondary />
                      <div className={styles.memberName}>
                        {member.first_name ? `${member.first_name}  ${member.last_name}` : member.email}
                      </div>
                    </div>
                  )
              )}
            </div>
            <TextArea
              value={inputValue}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setInputValue(e.target.value)}
              className={styles.input}
              placeholder={'Leave a message for your team member, and we will notify them via email'}
            />
            <div style={{ display: 'flex', gap: 10 }}>
              <ButtonCustom className={styles.button} onClick={onDelegateHandler}>
                Delegate
              </ButtonCustom>
              <ButtonCustom className={cs(styles.button, styles.buttonClose)} onClick={props.onClose}>
                Close
              </ButtonCustom>
            </div>
          </>
        )}

        {totalActiveMembers <= 1 && !memberLoading && (
          <>
            <div className={styles.noMembersWrapper}>
              <EmojiSadVG />
              <p>
                Hey! <br /> You have no team members yet, please invite them from the business profile page, once they are part of your
                organization you will be able to delegate ownership
              </p>
              <a href={'https://help.otterfish.com/knowledge/how-to-change-ownership-of-a-content-board'} className={styles.link}>
                To know more, click here
              </a>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ButtonCustom className={cs(styles.button, styles.buttonClose)} onClick={props.onClose} style={{ width: 110 }}>
                Close
              </ButtonCustom>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}

export default DelegateOwnershipModal;
