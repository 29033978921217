import React, { useMemo } from 'react';
import styles from './StorageBanner.module.scss';
import StorageProgressBar from '../../../../components/StorageProgressBar';
import { ReactComponent as CloudIcon } from '../../../../assets/img/icons/storage-cloud-icon.svg';
import { useAppSelector } from '../../../../shared/hooks/reduxHooks';
import { formatBytes } from '../../../../shared/utils/helpers';

const StorageBanner = () => {
  const plan = useAppSelector(state => state.auth.currentOrganizationPlan);

  const maxStorage = useMemo(() => {
    if (plan?.storage) {
      if (plan?.storage === '-1') {
        return 'Unlimited';
      }
      return formatBytes(Number(plan.storage), 1);
    }
    return '';
  }, [plan?.storage]);

  const currentStorage = useMemo(() => {
    if (plan?.usage?.storage) {
      return formatBytes(Number(plan?.usage?.storage), 1);
    } else if (plan?.usage?.storage === 0) {
      return '0';
    }
    return '';
  }, [plan?.usage?.storage]);

  return (
    <div className={styles.root}>
      <div className={styles.storageWrapper}>
        <CloudIcon />
        {maxStorage && currentStorage && (
          <span>
            {currentStorage} / {maxStorage}
          </span>
        )}
      </div>
      {plan?.storage !== '-1' && <StorageProgressBar current={plan?.usage?.storage} of={+plan?.storage} />}
    </div>
  );
};

export default StorageBanner;
