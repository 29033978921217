import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Section from '../../CampaignBriefPage/components/Section';
import styles from './VisualDirectionsSection.module.scss';
import ButtonCustom from '../../../../expert/components/Buttons/ButtonCustom';
import { AddIcon } from '../../../../expert/components/IconsSvg/ReactIcons/ReactIcons';
import VisualElement from '../../CampaignBriefPage/components/VisualElement';
import AddVisualModal from '../../CampaignBriefPage/components/AddVisualModal';
import { deleteBriefVisualAsset } from '../../../../actions/brief.actions';
import DownloadAllButton from '../../../ContentApproval/components/DownloadAllButton';
import { useMediaQuery } from 'react-responsive/src';
import { createNotification } from '../../../../actions/toastNotificationActions/toastNotification.actions';
import notificationDataConfig from '../../../../shared/utils/notificationDataConfig';
import { TOAST_NOTIFICATION_TYPES } from '../../../../shared/constants/toastNotificationsData';
import ErrorModal from '../../../../expert/components/Modal/ErrorModal';
import StorageLimitModal from '../../../../components/Modals/ErrorLimitModal';

const VisualDirectionsSection = ({ showAddVisualButton = true }) => {
  const dispatch = useDispatch();
  const currentOrganizationID = useSelector(state => state.auth.currentOrganization?.organization_id);
  const currentBrief = useSelector(state => state.brief.currentBrief.data);
  const currentBriefVisualAssets = useSelector(state => state.brief.currentBrief.visualAssets);
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  const [addVisualModalState, setAddVisualModalState] = useState(false);
  const [addVisualError, setAddVisualError] = useState(null);
  const [addVisualErrorTyped, setAddVisualErrorTyped] = useState(null);

  const deleteVisual = assetId => {
    dispatch(deleteBriefVisualAsset(currentOrganizationID, currentBrief.id, assetId));
  };

  const onResultActions = useMemo(() => {
    return {
      success: () => {
        dispatch(createNotification(notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.DOWNLOAD_ALL_ASSETS_SUCCESS, {})));
      },
      partiallyFailed: () => {
        dispatch(
          createNotification(notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.DOWNLOAD_ALL_ASSETS_PARTIALLY_FAILED, {}))
        );
      },
      allFailed: () => {
        dispatch(createNotification(notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.DOWNLOAD_ALL_ASSETS_FAILED, {})));
      },
    };
  }, []);

  return (
    <>
      <StorageLimitModal isOpen={Boolean(addVisualErrorTyped)} error={addVisualErrorTyped} onClose={() => setAddVisualErrorTyped(null)} />
      <ErrorModal open={!!addVisualError} onClose={() => setAddVisualError(null)} text={addVisualError} />
      <AddVisualModal
        modalState={addVisualModalState}
        setModalState={setAddVisualModalState}
        currentCountVisualAssets={currentBriefVisualAssets.length}
        onSubmitError={setAddVisualError}
        onSubmitErrorTyped={setAddVisualErrorTyped}
      />
      <Section>
        <div className={styles.sectionHeader}>
          <div className={styles.title}>
            Visual directions <span>{currentBriefVisualAssets.length}</span>
          </div>
          <div className={styles.buttonsGroup}>
            {!!currentBriefVisualAssets?.length && <DownloadAllButton assets={currentBriefVisualAssets} onResult={onResultActions} />}
            {showAddVisualButton && (
              <ButtonCustom
                disabled={currentBriefVisualAssets.length >= 10}
                onClick={() => setAddVisualModalState(true)}
                Icon={AddIcon}
                className={styles.button}
              >
                Add {!isMobile && 'Visuals'}
              </ButtonCustom>
            )}
          </div>
        </div>
        {!!currentBriefVisualAssets.length && (
          <div className={styles.visualsList}>
            {currentBriefVisualAssets.map((item, index) => (
              <VisualElement data={item} deleteVisual={deleteVisual} key={index} />
            ))}
          </div>
        )}
      </Section>
    </>
  );
};

export default VisualDirectionsSection;
