import React, { FC, useState } from 'react';
import ButtonLightPurpleWhite from '../../../../../components/Buttons/ButtonLightPurpleWhite';
import ShareLinkModal from '../../../../../components/Modals/ShareLinkModal';
import useTooltip, { TOOLTIP_POSITIONS } from '../../../../../shared/hooks/fixedTooltip/useTooltip';

type PropsType = {
  className?: string;
};

const ShareLinkButton: FC<PropsType> = props => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { toolTip, onEnter, onLeave } = useTooltip(TOOLTIP_POSITIONS.BOTTOM, { text: 'Share board' });

  return (
    <>
      {/* @ts-ignore */}
      <ButtonLightPurpleWhite
        className={props.className}
        size="small"
        presetIcon="share"
        designVersion="v2"
        onClick={() => setIsModalOpen(true)}
        onMouseEnter={onEnter}
        onMouseLeave={onLeave}
      >
        Share
        {toolTip}
      </ButtonLightPurpleWhite>
      <ShareLinkModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
};

export default ShareLinkButton;
