import React, { FC, useState } from 'react';
import { BOARD_STATUTES_TYPES } from '../../../models/entities/board/enums/boardStatuses';
import ChangeableColoredStatus from '../components/ChangeableColoredStatus';
import boardStatusesConfig from '../../../pages/ContentApproval/Constants/boardStatusesConfig';
import useTooltip, { TOOLTIP_POSITIONS } from '../../../shared/hooks/fixedTooltip/useTooltip';
import styles from './BoardStatus.module.scss';

type PropsType = {
  wrapperClass?: string;
  status: BOARD_STATUTES_TYPES;
  canEditStatus: boolean;
  onSelect?: (status: BOARD_STATUTES_TYPES) => void;
  shortStatuses?: boolean;
  withTooltip?: boolean;
};

const BoardStatus: FC<PropsType> = ({ onSelect, ...props }) => {
  const [isDisplayTooltip, setIsDisplayTooltip] = useState(true);
  const { onEnter, onLeave, toolTip } = useTooltip(TOOLTIP_POSITIONS.BOTTOM, {
    text: boardStatusesConfig[props.status].text,
    className: styles.tooltip,
  });
  return (
    <div onMouseEnter={onEnter} onMouseLeave={onLeave} className={props.wrapperClass}>
      {props.withTooltip ? toolTip : null}
      <ChangeableColoredStatus
        statuses={Object.values(BOARD_STATUTES_TYPES)}
        statusConfig={boardStatusesConfig}
        onSelect={onSelect}
        onDropdownToggle={isOpen => setIsDisplayTooltip(!isOpen)}
        {...props}
      />
      {isDisplayTooltip && toolTip}
    </div>
  );
};

export default BoardStatus;
