import React, { useEffect, useMemo, useState, FocusEvent, MouseEvent, FC } from 'react';
import styles from './EditorComponent.module.scss';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import cs from 'classnames';
import { replaceHtml } from '../../shared/utils/helpers';
import ColorPicker from './toolbar/controls/ColorPicker';
import { handlePastedText } from './utils/handlePaste';

type PropsType = {
  initialValue?: string | null;
  onChange?: (text: string) => void;
  onFocus?: (e: FocusEvent) => void;
  onBlur?: (e: MouseEvent) => void;
  placeholder?: string;
  error?: string;
  wrapperClassName?: string;
  editorClassName?: string;
  toolbarClassName?: string;
  focusClassName?: string;
  disabled?: boolean;
  readOnly?: boolean;
  toolbarOnFocus?: boolean;
  mentionSuggestions?: { text: string; value: string; url?: string }[];
};
const EditorComponent: FC<PropsType> = ({
  initialValue = '',
  onChange,
  onFocus,
  onBlur,
  placeholder,
  error,
  wrapperClassName,
  editorClassName,
  toolbarClassName,
  focusClassName,
  disabled = false,
  readOnly = false,
  toolbarOnFocus = false,
  mentionSuggestions = [],
}) => {
  const [editorState, setEditorState] = useState(() => convertTextToEditorState(initialValue));
  const [focus, setFocus] = useState<boolean>(false);

  const isMacOs: boolean = useMemo(() => {
    return navigator.userAgent.indexOf('Mac') !== -1;
  }, []);

  useEffect(() => {
    if (typeof initialValue === 'string' && initialValue.length === 0 && !focus) {
      setEditorState(convertTextToEditorState(initialValue));
    }
  }, [initialValue]);

  const onChangeContent = editorState => {
    if (editorState instanceof EditorState) {
      let text = convertEditorStateToText(editorState);
      let newEditorState = editorState;
      const cleanValue = replaceHtml(text);

      if (!cleanValue) {
        text = ''; // Because empty editor input equal - <p></p> or any empty HTML

        // because we need to insert the initial html of the list, but erase it on deletion
        if (initialValue) {
          newEditorState = convertTextToEditorState(text); // Editor lost focus
        }
      }
      if (cleanValue.length === 1) {
        newEditorState = EditorState.moveFocusToEnd(editorState); // Editor get focus
      }
      setEditorState(newEditorState);
      onChange && onChange(text);
    }
  };

  const handleFocus = e => {
    setFocus(true);
    onFocus && onFocus(e);
  };

  const handleBlur = e => {
    setFocus(false);
    if (editorState instanceof EditorState) {
      const cleanValue = replaceHtml(convertEditorStateToText(editorState));
      // clean editor value on blur if current value empty list (<ul>, <ol>)
      !cleanValue && onChangeContent(EditorState.createEmpty());
    }
    onBlur && onBlur(e);
  };

  return (
    <>
      <Editor
        wrapperClassName={cs(
          styles.wrapperClassName,
          wrapperClassName,
          { [styles.disabled]: disabled },
          { [styles.focus]: !focusClassName && focus },
          focusClassName && focus ? focusClassName : null,
          { [styles.error]: error }
        )}
        editorClassName={cs(styles.editorClassName, editorClassName)}
        toolbarClassName={toolbarClassName}
        placeholder={focus ? '' : placeholder}
        onFocus={handleFocus}
        onBlur={handleBlur}
        toolbarOnFocus={toolbarOnFocus}
        toolbarHidden={readOnly}
        readOnly={disabled || readOnly}
        editorState={editorState}
        handlePastedText={handlePastedText}
        onEditorStateChange={onChangeContent}
        toolbar={{
          options: ['inline', 'list', 'colorPicker', 'remove', 'history'], // 'link', 'emoji', 'blockType', 'fontSize',
          inline: {
            options: ['bold', 'italic', 'underline'], // 'underline', 'strikethrough'
            inDropdown: false,
            underline: { title: `Underline: ${isMacOs ? 'Cmd' : 'Ctrl'}+U` },
          },
          list: {
            options: ['unordered', 'ordered'],
            inDropdown: false,
          },
          textAlign: {
            options: ['left', 'center', 'right', 'justify'],
            inDropdown: false,
          },
          link: {
            options: ['link', 'unlink'],
            inDropdown: false,
          },
          history: {
            inDropdown: false,
            options: ['undo', 'redo'],
            undo: { title: `Undo: ${isMacOs ? 'Cmd' : 'Ctrl'}+Z` },
            redo: { title: `Redo: ${isMacOs ? 'Cmd' : 'Ctrl'}+Shift+Z` },
          },
          colorPicker: {
            title: 'Highlight',
            popupClassName: styles.colorPickerPopup,
            component: ColorPicker,
            colors: [
              'rgb(97,189,109)',
              'rgb(26,188,156)',
              'rgb(84,172,210)',
              'rgb(44,130,201)',
              'rgb(147,101,184)',
              'rgb(71,85,119)',
              'rgb(65,168,95)',
              'rgb(0,168,133)',
              'rgb(61,142,185)',
              'rgb(41,105,176)',
              'rgb(85,57,130)',
              'rgb(40,50,78)',
              'rgb(0,0,0)',
              'rgb(251,160,38)',
              'rgb(235,107,86)',
              'rgb(226,80,65)',
              'rgb(163,143,132)',
              'rgb(250,197,28)',
              'rgb(243,121,52)',
              'rgb(209,72,65)',
              'rgb(184,49,47)',
              'rgb(124,112,107)',
            ],
          },
        }}
        mention={{
          separator: ' ',
          trigger: '@',
          suggestions: mentionSuggestions,
          // suggestions: [{ text: 'Dropwdown display name', value: 'name in message', url: 'Url for link <a>' }]
        }}
      />
      {error && <span className={styles.error}>{error}</span>}
    </>
  );
};

function convertTextToEditorState(text) {
  const contentBlock = htmlToDraft(text ?? '');
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  return EditorState.createWithContent(contentState);
}

function convertEditorStateToText(editorState) {
  return draftToHtml(convertToRaw(editorState.getCurrentContent()));
}

export default EditorComponent;
