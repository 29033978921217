import React, { ComponentProps, FC, useCallback, useEffect, useState } from 'react';
import styles from './SectionFooter.module.scss';
import EditedByInfo from '../../../../../../../components/EditedByInfo';
import GreenRedButton from '../../../../../../../components/Buttons/GreenRedButton';
import ButtonLightPurpleWhite from '../../../../../../../components/Buttons/ButtonLightPurpleWhite';
import GiveFeedbackButton from '../../../../../../../components/GiveFeedbackButton';
import ActionButtons from '../../../buttons/ActionButtons';
import { useMediaQuery } from 'react-responsive/src';
import { DeliverablePermission } from '../../../../../../../models/permissions/enum/DeliverablePermission';
import { AssetStateType } from '../../../../../../../models/entities/asset/AssetState';
import { IConceptSection } from '../../../../../../../models/entities/deliverableSections/ConceptSection';
import { IContentSection } from '../../../../../../../models/entities/deliverableSections/ContentSection';
import { useAppSelector } from '../../../../../../../shared/hooks/reduxHooks';
import { DeliverablesReducerState } from '../../../../../../../reducers/deliverables.reducer';
import { DELIVERABLE_STATUSES } from '../../../../../../../models/enums/DeliverableStatuses';
import { ORGANIZATION_TYPES } from '../../../../../../../models/enums/OrganizationTypes';

type PropsType = {
  onCancel: () => void;
  onSave: () => void;
  onEdit: () => void;
  onRemove: (() => void) | null;
  isEditingMode: boolean;
  isPageUpdateLoading: boolean;
  assets?: AssetStateType[];
  section?: IConceptSection | IContentSection | null;
  editText?: string;
  isDisabled: boolean;
  onViewMoreClick?: ComponentProps<typeof ButtonLightPurpleWhite>['onClick'];
  isViewMore?: boolean;
};

const SectionFooter: FC<PropsType> = ({
  onCancel,
  onSave,
  onEdit,
  onRemove,
  isEditingMode,
  isPageUpdateLoading,
  assets = [] as AssetStateType[],
  section,
  editText,
  isDisabled,
  onViewMoreClick,
  isViewMore,
}) => {
  const pageModel = useAppSelector<DeliverablesReducerState['pageModel']>(state => state.deliverables.pageModel);
  const collaborators = useAppSelector<any>(state => state.contentBoard.collaborators);
  const [updater, setUpdater] = useState(null);

  const isAssetsHasError: boolean = assets.some(asset => 'error' in asset && !!asset.error);
  const isEditDisplay: boolean = isEditingMode || isPageUpdateLoading;
  const isApproved: boolean = Boolean(section?.status === DELIVERABLE_STATUSES.APPROVED && section.approved_at);

  const isDesktopLandscape = useMediaQuery({ query: '(min-width: 992px) and (orientation: landscape)' });

  useEffect(() => {
    if (section != null && (section?.updater_id || section?.approver_id)) {
      const updaterId = Number(isApproved ? section?.approver_id : section?.updater_id);
      const newUpdater = collaborators.find(collaborator => +collaborator.approver_id === updaterId);
      if (newUpdater) {
        setUpdater({ ...newUpdater });
      }
    }
  }, [section, collaborators]);

  const getCollaborator = useCallback(() => {
    return collaborators.find(collaborator => {
      if (pageModel?.roleCan(DeliverablePermission.GIVE_FEEDBACK_FIRST_CONTENT_CREATOR)) {
        return collaborator.type === ORGANIZATION_TYPES.CONTENT_CREATOR || collaborator.type === ORGANIZATION_TYPES.TALENT_MANAGER;
      }
      return collaborator.type === ORGANIZATION_TYPES.TALENT_MANAGER;
    });
  }, [pageModel, collaborators]);

  return (
    <>
      <div className={styles.root}>
        {!(isEditDisplay || !section?.updated_at || !updater) && pageModel?.roleCan(DeliverablePermission.CAN_SEE_FOOTER_UPDATER_INFO) && (
          <EditedByInfo collaborator={updater} updatedAt={section?.updated_at} approvedAt={section?.approved_at} isApproved={isApproved} />
        )}

        <div className={styles.buttonsContainer}>
          {isEditDisplay && (
            <>
              <div
                className={styles.cancel}
                onClick={() => {
                  if (isPageUpdateLoading) return;
                  onCancel();
                }}
              >
                Cancel
              </div>
              <GreenRedButton
                className={styles.saveButton}
                loading={isPageUpdateLoading}
                color="green"
                size={!isDesktopLandscape ? 'large' : 'small'}
                onClick={onSave}
                disabled={isAssetsHasError || isDisabled}
              >
                {isPageUpdateLoading ? 'Saving' : 'Save'}
              </GreenRedButton>
            </>
          )}

          {isDesktopLandscape && !isEditDisplay && pageModel?.roleCan(DeliverablePermission.DISPLAY_GIVE_FEEDBACK) && (
            <GiveFeedbackButton collaborator={getCollaborator()}>Give feedback</GiveFeedbackButton>
          )}

          {!isEditDisplay && pageModel?.roleCan(DeliverablePermission.DISPLAY_ACTION_BUTTONS) && isDesktopLandscape && (
            <ActionButtons
              isDisplayEdit={true}
              editText={editText || ''}
              onEdit={onEdit}
              onRemove={onRemove}
              classNameWrapper={styles.actionButtonsWrapper}
              isDisplayRemove={!!onRemove}
            />
          )}
          {onViewMoreClick && !isEditDisplay && !isDesktopLandscape && (
            <ButtonLightPurpleWhite
              className={styles.viewMoreLessButton}
              designVersion="v2"
              presetIcon="view"
              size="large"
              onClick={onViewMoreClick}
            >
              {isViewMore ? 'View less ' : 'View more'}
            </ButtonLightPurpleWhite>
          )}
        </div>
      </div>
    </>
  );
};

export default SectionFooter;
