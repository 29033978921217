import { User } from '../users/User';
import * as contentApprovalServices from '../../../services/contentApproval';
import { UserPermission } from '../enum/UserPermission';
import * as sharedServices from '../../../services/shared';
import { Guest } from '../users/Guest';
import { AuthorizedUser } from '../users/AuthorizedUser';
import {addResultAsset, patchResultAsset} from "../../../services/contentApproval";
import {deleteResultAsset} from "../../../actions/ResultsActions/results.actions";

type RequestsType = { [requestName: string]: Function };

const permissionHandler = (user: User, permission: UserPermission, requestName: string, service: object) => {
  if (!user || !user.hasAccess(permission)) {
    return null;
  }

  let res;
  if (user instanceof Guest) {
    res = sharedServices[requestName].bind(null, user.sharedData.hash);
  } else if (user instanceof AuthorizedUser) {
    res = service[requestName].bind(null, user.organizationId);
  } else {
    return null;
  }

  return res;
};

export class SharedRequestsProxyService {
  user: User;
  requests: RequestsType = {
    getContentBoard: (user: User): Function | null =>
      permissionHandler(user, UserPermission.CAN_GET_BOARD_DATA, 'getContentBoard', contentApprovalServices),
    getBoardCollaborators: (user: User): Function | null =>
      permissionHandler(user, UserPermission.CAN_GET_BOARD_COLLABORATORS_DATA, 'getBoardCollaborators', contentApprovalServices),
    getInvitedBoardCollaborators: (user: User): Function | null =>
      permissionHandler(
        user,
        UserPermission.CAN_GET_BOARD_INVITED_COLLABORATORS_DATA,
        'getInvitedBoardCollaborators',
        contentApprovalServices
      ),
    getBoardDeliverableOptions: (user: User): Function | null =>
      permissionHandler(user, UserPermission.CAN_GET_BOARD_DATA, 'getBoardDeliverableOptions', contentApprovalServices),
    getBoardResultAssets: (user: User): Function | null =>
      permissionHandler(user, UserPermission.CAN_GET_BOARD_DATA, 'getBoardResultAssets', contentApprovalServices),
    deleteAllResultAssets: (user: User): Function | null =>
      permissionHandler(user, UserPermission.CAN_GET_BOARD_DATA, 'deleteAllResultAssets', contentApprovalServices),
    addResultAsset: (user: User): Function | null =>
      permissionHandler(user, UserPermission.CAN_GET_BOARD_DATA, 'addResultAsset', contentApprovalServices),
    patchResultAsset: (user: User): Function | null =>
      permissionHandler(user, UserPermission.CAN_GET_BOARD_DATA, 'patchResultAsset', contentApprovalServices),
    deleteResultAsset: (user: User): Function | null =>
      permissionHandler(user, UserPermission.CAN_GET_BOARD_DATA, 'deleteResultAsset', contentApprovalServices),
    requestResults: (user: User): Function | null =>
      permissionHandler(user, UserPermission.CAN_GET_BOARD_DATA, 'requestResults', contentApprovalServices),
    getBoardDeliverables: (user: User): Function | null =>
      permissionHandler(user, UserPermission.CAN_GET_BOARD_DELIVERABLES_DATA, 'getBoardDeliverables', contentApprovalServices),
    getBoardDeliverablesAssets: (user: User): Function | null =>
      permissionHandler(user, UserPermission.CAN_GET_ALL_BOARD_DELIVERABLES_ASSETS, 'getBoardDeliverablesAssets', contentApprovalServices),
    getBoardDeliverableItem: (user: User): Function | null =>
      permissionHandler(user, UserPermission.CAN_GET_DELIVERABLE_ITEM_DATA, 'getBoardDeliverableItem', contentApprovalServices),
    getBoardDeliverableItemSection: (user: User): Function | null =>
      permissionHandler(
        user,
        UserPermission.CAN_GET_DELIVERABLE_ITEM_SECTION_DATA,
        'getBoardDeliverableItemSection',
        contentApprovalServices
      ),
  };

  constructor(user: User) {
    this.user = user;
  }

  getRequestFunction(name: string): Function | null {
    return this.requests[name](this.user);
  }
}
