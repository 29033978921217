import React, { CSSProperties, FC, ReactElement, ReactNode } from 'react';
import styles from './Modals.module.scss';
import { CloseIcon } from '../../expert/components/IconsSvg/ReactIcons/ReactIcons';
import { Modal as ReactModal } from 'react-responsive-modal';
import cs from 'classnames';

type ModalPropsType = {
  title?: string;
  onClose: () => void;
  open: boolean;
  children?: JSX.Element | ReactElement | ReactNode | string | null;
  maxWidth?: CSSProperties['maxWidth'];
  width?: CSSProperties['width'];
  loading?: boolean;
  className?: string;
  closeBtnClassName?: string;
  backgroundColor?: CSSProperties['backgroundColor'];
  borderRadius?: CSSProperties['borderRadius'];
  customStyles?: CSSProperties;
  overflow?: CSSProperties['overflow'];
};

export const Modal: FC<ModalPropsType> = ({
  title,
  onClose,
  open,
  children,
  maxWidth,
  width,
  loading,
  className,
  closeBtnClassName,
  backgroundColor = '#fff',
  borderRadius,
  customStyles = {},
  overflow,
  ...props
}) => {
  const modalStyles = {
    overlay: {
      backdropFilter: 'blur(3px)',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      overflow: overflow || 'auto',
    },
    modal: {
      background: backgroundColor,
      padding: 0,
      borderRadius: borderRadius || '4px',
      maxWidth: maxWidth || '420px',
      width: width || '100%',
      boxShadow: ' 0 0 20px rgba(0, 0, 0, 0.1)',
    },
    closeButton: {
      display: 'none',
    },
  };

  for (const key of Object.keys(modalStyles)) {
    Object.assign(modalStyles[key], customStyles[key]);
  }

  return (
    <ReactModal center open={open} onClose={onClose} styles={modalStyles}>
      <div className={cs(styles.root, className)}>
        <button data-search="close-btn" onClick={onClose} className={cs(styles.close, closeBtnClassName)} disabled={loading}>
          <CloseIcon size={30} />
        </button>
        {title && (
          <div className={styles.head}>
            <p className={styles.title}>{title}</p>
          </div>
        )}
        {children}
      </div>
    </ReactModal>
  );
};

type ModalMediumPropsType = {
  maxWidth?: CSSProperties['maxWidth'];
  subtitle?: string;
  open: boolean;
  onClose: () => void;
  title?: string;
  children?: JSX.Element | ReactElement | ReactNode | string | null;
  classNames?: string;
  alternate?: boolean;
  closeBtnClassName?: string;
  overlayPadding?: CSSProperties['padding'] | null;
  margin?: CSSProperties['margin'] | null;
};

export const ModalMedium: FC<ModalMediumPropsType> = ({
  maxWidth,
  subtitle,
  open,
  onClose,
  title,
  children,
  classNames,
  alternate,
  closeBtnClassName,
  overlayPadding,
  margin,
}) => {
  const modalStyles = {
    overlay: {
      padding: overlayPadding || '12px',
      backdropFilter: 'blur(3px)',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
    modal: {
      margin: margin || 'auto',
      background: '#fff',
      padding: 0,
      borderRadius: '10px',
      maxWidth: maxWidth || '1120px',
      width: '100%',
      boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
    },
    closeButton: {
      display: 'none',
    },
  };
  return (
    <ReactModal center open={open} onClose={onClose} styles={modalStyles}>
      <div className={cs(styles.rootMedium, classNames)}>
        <button
          data-search="close-btn"
          onClick={onClose}
          className={cs(styles.close, closeBtnClassName, { [styles.alternate]: alternate })}
        >
          <CloseIcon size={30} />
        </button>
        {title && !alternate && (
          <div className={styles.head}>
            <p data-name="title" className={styles.title}>
              {title}
            </p>
            {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
          </div>
        )}
        {children}
      </div>
    </ReactModal>
  );
};
