import React, { FC, MouseEventHandler } from 'react';
import styles from './SquareCartButton.module.scss';
import { ReactComponent as DeleteIcon } from '../../../assets/img/icons/trash.svg';
import cs from 'classnames';
import useTooltip, { TOOLTIP_POSITIONS } from '../../../shared/hooks/fixedTooltip/useTooltip';

type PropsType = {
  onClick: MouseEventHandler;
  classNameWrapper?: string;
  classNameIcon?: string;
  Icon?: FC<React.SVGProps<SVGSVGElement>>;
  tooltipPosition: TOOLTIP_POSITIONS;
  tooltipText?: string;
};

const SquareCartButton: FC<PropsType> = props => {
  const { toolTip, onEnter, onLeave } = useTooltip(props.tooltipPosition, { text: props.tooltipText || '' });

  return (
    <div className={cs(styles.root, props.classNameWrapper)} onClick={e => props.onClick(e)} onMouseEnter={onEnter} onMouseLeave={onLeave}>
      {props.Icon && <props.Icon />}
      {!props.Icon && <DeleteIcon className={props.classNameIcon} />}

      {toolTip}
    </div>
  );
};

export default SquareCartButton;
