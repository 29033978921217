import { FlowsTypes, IFlow } from '../Flow';
import { COLLABORATOR_ROLE_TYPES } from '../../collaborator/CollaboratorRole';
import { DeliverablePermission } from '../../enum/DeliverablePermission';

export class DeliverableCCTMFlow implements IFlow<DeliverablePermission> {
  type: FlowsTypes = FlowsTypes.DELIVERABLE_CC_TM;
  permissions = {
    [COLLABORATOR_ROLE_TYPES.CONTENT_CREATOR]: [
      DeliverablePermission.ONLY_CC_CAN_VIEW,
      DeliverablePermission.CAN_REMOVE_SECTION,
      DeliverablePermission.CONCEPT_IN_PROGRESS_STATUS_TOOLTIP,
      DeliverablePermission.CONCEPT_PENDING_APPROVAL_STATUS_TOOLTIP_CC,
      DeliverablePermission.DISPLAY_ADD_SECTION_BUTTON,
      DeliverablePermission.DISPLAY_ACTION_BUTTONS,
    ],
    [COLLABORATOR_ROLE_TYPES.TALENT_MANAGER]: [
      DeliverablePermission.CAN_REMOVE_SECTION,
      DeliverablePermission.DISABLED_SHARE_BUTTON,
      DeliverablePermission.CAN_SHARE_SECTION,
      DeliverablePermission.CAN_APPROVE_SECTION,
      DeliverablePermission.SHARE_AS_APPROVE,
      DeliverablePermission.DISPLAY_ADD_SECTION_BUTTON,
      DeliverablePermission.DISPLAY_ACTION_BUTTONS,
      DeliverablePermission.CONTENT_APPROVE_BUTTON_TOOLTIP_BA,
    ],
  };

  generalPermissions: DeliverablePermission[] = [
    DeliverablePermission.CAN_SEE_SECTION_FOOTER,
    DeliverablePermission.CAN_SEE_VISUAL_CARD_POPOVER,
    DeliverablePermission.CAN_SEE_FOOTER_UPDATER_INFO,
    DeliverablePermission.CAN_EDIT_DELIVERABLE_NAME,
    DeliverablePermission.CAN_DOWNLOAD_ASSETS,
  ];

  getPermissions(roleType: COLLABORATOR_ROLE_TYPES): DeliverablePermission[] | null {
    switch (roleType) {
      case COLLABORATOR_ROLE_TYPES.CONTENT_CREATOR:
        return this.permissions[COLLABORATOR_ROLE_TYPES.CONTENT_CREATOR].concat(this.generalPermissions);
      case COLLABORATOR_ROLE_TYPES.TALENT_MANAGER:
        return this.permissions[COLLABORATOR_ROLE_TYPES.TALENT_MANAGER].concat(this.generalPermissions);
      default:
        return null;
    }
  }
}
