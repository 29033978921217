import React, { FC, useMemo } from 'react';
import SectionHeader from '../../../components/SectionHeader';
import { DeliverablePermission } from '../../../../../../../../models/permissions/enum/DeliverablePermission';
import {
  DeliverableStatusTileSettingsType,
  getDeliverableStatusesTooltips,
} from '../../../components/SectionHeader/statuses/useDeliverableStatusTile';
import { ConceptFlowType } from '../../../../../hooks/useDeliverableState';
import { useAppSelector } from '../../../../../../../../shared/hooks/reduxHooks';
import { DeliverablesReducerState } from '../../../../../../../../reducers/deliverables.reducer';
import { DELIVERABLE_SECTION_TYPES } from '../../../../../../../../models/enums/DeliverableSectionTypes';
import { DELIVERABLE_STATUSES } from '../../../../../../../../models/enums/DeliverableStatuses';
import QuestionTooltip from '../../../../../../../../components/QuestionTooltip';

type PropsType = {
  conceptFlow: ConceptFlowType;
  isDisplayQuestion: boolean;
  onRemove: (() => void) | null;
  onEdit: () => void;
  isEditingMode: boolean;
  isDisplayEmptyState: boolean;
};

const ConceptHeaderWrapper: FC<PropsType> = props => {
  const openedDeliverable = useAppSelector<DeliverablesReducerState['openedDeliverable']>(state => state.deliverables.openedDeliverable);
  const isPageUpdateLoading = useAppSelector<DeliverablesReducerState['deliverableConceptUpdateLoading']>(
    state => state.deliverables.deliverableConceptUpdateLoading
  );
  const pageModel = useAppSelector<DeliverablesReducerState['pageModel']>(state => state.deliverables.pageModel);
  const collaborators = useAppSelector<any>(state => state.contentBoard.collaborators);

  const isDataExists: boolean = useMemo(() => {
    return props.conceptFlow.isVisualExists || !!openedDeliverable?.concept?.description;
  }, [props.conceptFlow?.isVisualExists, openedDeliverable?.concept]);

  const approveButtonText: string = useMemo(
    () =>
      pageModel?.roleCan(DeliverablePermission.CAN_APPROVE_SECTION) &&
      (!!props.conceptFlow?.concept?.isShared || pageModel?.roleCan(DeliverablePermission.SHARE_AS_APPROVE))
        ? 'Approve'
        : 'Share with Agency',
    [pageModel, props.conceptFlow?.concept?.isShared]
  );

  const status = useMemo(() => props.conceptFlow?.concept?.status || DELIVERABLE_STATUSES.EMPTY, [props.conceptFlow?.concept?.status]);

  // const downloadSettings = useMemo(
  //   () => ({
  //     isDisplay:
  //       conceptFlow?.concept?.status !== DELIVERABLE_STATUSES.EMPTY &&
  //       pageModel?.roleCan(DeliverablePermission.CAN_DOWNLOAD_ASSETS) &&
  //       !!openedDeliverable.concept.assets.length,
  //     assets: openedDeliverable?.concept?.assets,
  //   }),
  //   [pageModel, conceptFlow?.concept?.status, openedDeliverable]
  // );

  const ToolTipTextSettingsConcept: DeliverableStatusTileSettingsType = useMemo(() => {
    const deliverableStatusTooltips = getDeliverableStatusesTooltips(pageModel?.role?.type, collaborators);

    return {
      approvedOutlined: true,
      toolTip: deliverableStatusTooltips?.concept || null,
    };
  }, [pageModel, collaborators]);

  return (
    <SectionHeader
      sectionTitle="Concept"
      sectionType={DELIVERABLE_SECTION_TYPES.CONCEPT}
      isDisplayQuestion={props.isDisplayQuestion}
      questionTooltip={<QuestionTooltip section={DELIVERABLE_SECTION_TYPES.CONCEPT} roleType={pageModel?.role?.type} />}
      isDataExists={isDataExists}
      status={status}
      isShared={!!props.conceptFlow?.concept?.isShared}
      onShare={props.conceptFlow.concept.shareConcept}
      onApprove={props.conceptFlow.concept.approveConcept}
      onShareAndApprove={props.conceptFlow.concept.shareAndApproveConcept}
      approveButtonText={approveButtonText}
      statusTileSettings={ToolTipTextSettingsConcept}
      isPageUpdateLoading={isPageUpdateLoading}
      isDisabledApprove={isPageUpdateLoading}
      onEdit={props.onEdit}
      onRemove={props.onRemove}
      isEditingMode={props.isEditingMode}
      isDisplayEmptyState={props.isDisplayEmptyState}
      // downloadSettings={downloadSettings}
    />
  );
};

export default ConceptHeaderWrapper;
