import { useAppSelector } from '../../../../../../shared/hooks/reduxHooks';
import { useMemo } from 'react';
import moment from 'moment/moment';
import { Moment } from 'moment';

const useDateInUserTimezone = (date: string | null): Moment | null => {
  const userTimezone = useAppSelector<any>(state => state.auth.userTimezone);
  const userTimeZoneName = useMemo(() => (userTimezone === 'Pacific/Yap' ? moment.tz.guess() : userTimezone?.name), [userTimezone]);

  return useMemo(() => (date ? moment(date).tz(userTimeZoneName) : null), [userTimeZoneName, date]);
};

export default useDateInUserTimezone;
