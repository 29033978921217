import {CONTENT_BOARD_ACTIONS_TYPES, ContentBoardActionsTypes} from '../actions/ContentBoardActions/types';
import {ErrorOnDeliverablesCreationType} from '../models/entities/board/ErrorOnDeliverablesCreation';
import {IDeliverable} from '../models/entities/deliverable/Deliverable';
import {IBoardInvitedCollaborator} from '../models/entities/board/BoardInvitedCollaborator';
import {IBoardCollaborator} from '../models/entities/board/BoardCollaborator';
import {IBoard} from '../models/entities/board/Board';
import {IBoardAsset} from '../models/entities/board/BoardAsset';
import {Page} from '../models/permissions/pages/Page';
import {ContentBoardPermission} from '../models/permissions/enum/ContentBoardPermission';

export type ContentBoardStateType = {
  openedBoard: null | IBoard;
  boardAssets: IBoardAsset[];
  pageModel: null | Page<ContentBoardPermission>;
  collaborators: IBoardCollaborator[];
  invitedCollaborators: IBoardInvitedCollaborator[];
  deliverables: IDeliverable[];
  deliverablesTotalCount: number;
  deliverablesApprovedCount: number;
  boardLoading: boolean;
  boardAssetsLoading: boolean;
  collaboratorsLoading: boolean;
  deliverablesLoading: boolean;
  boardError: null | string;
  creatingDeliverablesLoading: boolean;
  createDeliverablesErrorData: ErrorOnDeliverablesCreationType[];
  upcomingLiveDatesDeliverables: IDeliverable[];
  upcomingLiveDatesDeliverablesLoading: boolean;
  contentBoardPatchLoading: boolean;
};

const initialState: ContentBoardStateType = {
  openedBoard: null,
  boardAssets: [],
  pageModel: null,
  collaborators: [],
  invitedCollaborators: [],
  deliverables: [],
  deliverablesTotalCount: 0,
  deliverablesApprovedCount: 0,
  boardLoading: true,
  boardAssetsLoading: true,
  collaboratorsLoading: true,
  deliverablesLoading: true,
  boardError: null,
  creatingDeliverablesLoading: false,
  createDeliverablesErrorData: [],
  upcomingLiveDatesDeliverables: [],
  upcomingLiveDatesDeliverablesLoading: false,
  contentBoardPatchLoading: false,
};

const contentBoardReducer = (state = initialState, action: ContentBoardActionsTypes) => {
  switch (action.type) {
    case CONTENT_BOARD_ACTIONS_TYPES.CREATE_DELIVERABLES_REQUEST:
      return {
        ...state,
        creatingDeliverablesLoading: true,
      };
    case CONTENT_BOARD_ACTIONS_TYPES.CREATE_DELIVERABLES_SUCCESS:
      return {
        ...state,
        creatingDeliverablesLoading: false,
        createDeliverablesErrorData: [],
      };
    case CONTENT_BOARD_ACTIONS_TYPES.CREATE_DELIVERABLES_FAILURE:
      return {
        ...state,
        creatingDeliverablesLoading: false,
        createDeliverablesErrorData: action.payload,
      };
    case CONTENT_BOARD_ACTIONS_TYPES.GET_CONTENT_BOARD_REQUEST:
      return {
        ...state,
        boardLoading: true,
      };
    case CONTENT_BOARD_ACTIONS_TYPES.GET_CONTENT_BOARD_SUCCESS:
      return {
        ...state,
        openedBoard: action.payload,
        boardLoading: false,
      };
    case CONTENT_BOARD_ACTIONS_TYPES.GET_CONTENT_BOARD_FAILURE:
      return {
        ...state,
        boardLoading: false,
        boardError: action.payload,
      };
    case CONTENT_BOARD_ACTIONS_TYPES.UPDATE_OPENED_CONTENT_BOARD:
      return {
        ...state,
        openedBoard: action.payload,
      };
    case CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_COLLABORATORS_REQUEST:
      return {
        ...state,
        collaboratorsLoading: true,
      };
    case CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_COLLABORATORS_SUCCESS:
      return {
        ...state,
        collaborators: action.payload,
        collaboratorsLoading: false,
      };
    case CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_COLLABORATORS_FAILURE:
      return {
        ...state,
        collaboratorsLoading: false,
        boardError: action.payload,
      };
    case CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_INVITED_COLLABORATORS_REQUEST:
      return {
        ...state,
        collaboratorsLoading: true,
      };
    case CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_INVITED_COLLABORATORS_SUCCESS:
      return {
        ...state,
        invitedCollaborators: action.payload,
        collaboratorsLoading: false,
      };
    case CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_INVITED_COLLABORATORS_FAILURE:
      return {
        ...state,
        collaboratorsLoading: false,
      };
    case CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_DELIVERABLES_REQUEST:
      return {
        ...state,
        deliverablesLoading: true,
      };
    case CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_DELIVERABLES_SUCCESS:
      return {
        ...state,
        deliverables: action.payload,
        deliverablesLoading: false,
        deliverablesTotalCount: action.totalCount,
        deliverablesApprovedCount: action.approvedCount,
      };
    case CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_DELIVERABLES_FAILURE:
      return {
        ...state,
        deliverablesLoading: false,
        boardError: action.payload,
      };
    case CONTENT_BOARD_ACTIONS_TYPES.CLEAR_OPENED_CONTENT_BOARD:
      return {
        ...state,
        openedBoard: null,
        pageModel: null,
        deliverables: [],
        collaborators: [],
        invitedCollaborators: [],
        deliverablesTotalCount: 0,
        deliverablesApprovedCount: 0,
      };
    case CONTENT_BOARD_ACTIONS_TYPES.UPDATE_BOARD_DELIVERABLE_ITEM:
      return {
        ...state,
        deliverables: action.payload,
      };
    case CONTENT_BOARD_ACTIONS_TYPES.CLEAR_ERRORS:
      return {
        ...state,
        boardError: null,
      };
    case CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_DELIVERABLES_ASSETS_REQUEST: {
      return {
        ...state,
        boardAssetsLoading: true,
      };
    }
    case CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_DELIVERABLES_ASSETS_SUCCESS: {
      return {
        ...state,
        boardAssets: action.payload,
        boardAssetsLoading: false,
      };
    }
    case CONTENT_BOARD_ACTIONS_TYPES.GET_BOARD_DELIVERABLES_ASSETS_FAILURE: {
      return {
        ...state,
        boardAssetsLoading: false,
        boardError: action.payload,
      };
    }

    case CONTENT_BOARD_ACTIONS_TYPES.DUPLICATE_DELIVERABLE_ITEM_REQUEST: {
      return {
        ...state,
        deliverablesLoading: true,
      };
    }
    case CONTENT_BOARD_ACTIONS_TYPES.DUPLICATE_DELIVERABLE_ITEM_SUCCESS: {
      const isDeliverableAlreadyInState = state.deliverables.some(deliverable => deliverable.id === action.payload.deliverable.id);
      if (isDeliverableAlreadyInState) {
        return {...state, deliverablesLoading: false};
      }

      const duplicatedIndex = state.deliverables.findIndex(d => d.id === action.payload.afterId);
      const newDeliverables = [...state.deliverables];

      newDeliverables.splice(duplicatedIndex + 1, 0, action.payload.deliverable);
      return {
        ...state,
        deliverables: newDeliverables,
        deliverablesLoading: false,
      };
    }
    case CONTENT_BOARD_ACTIONS_TYPES.DUPLICATE_DELIVERABLE_ITEM_FAILURE: {
      return {
        ...state,
        deliverablesLoading: false,
      };
    }
    case CONTENT_BOARD_ACTIONS_TYPES.SET_CONTENT_BOARD_PAGE_MODEL: {
      return {
        ...state,
        pageModel: action.payload,
      };
    }
    case CONTENT_BOARD_ACTIONS_TYPES.GET_UPCOMING_LIVE_DATES_DELIVERABLES_REQUEST:
      return {
        ...state,
        upcomingLiveDatesDeliverablesLoading: true,
      };
    case CONTENT_BOARD_ACTIONS_TYPES.GET_UPCOMING_LIVE_DATES_DELIVERABLES_SUCCESS:
      return {
        ...state,
        upcomingLiveDatesDeliverablesLoading: false,
        upcomingLiveDatesDeliverables: action.payload,
      };
    case CONTENT_BOARD_ACTIONS_TYPES.GET_UPCOMING_LIVE_DATES_DELIVERABLES_FAILURE:
      return {
        ...state,
        upcomingLiveDatesDeliverablesLoading: false,
      };
    case CONTENT_BOARD_ACTIONS_TYPES.PATCH_OPENED_CONTENT_BOARD_REQUEST:
      return {
        ...state,
        contentBoardPatchLoading: true,
      };
    case CONTENT_BOARD_ACTIONS_TYPES.PATCH_OPENED_CONTENT_BOARD_SUCCESS:
      return {
        ...state,
        contentBoardPatchLoading: false,
      };
    case CONTENT_BOARD_ACTIONS_TYPES.PATCH_OPENED_CONTENT_BOARD_FAILURE:
      return {
        ...state,
        contentBoardPatchLoading: false,
      };
    case CONTENT_BOARD_ACTIONS_TYPES.PUSH_DELIVERABLES_TO_BOARD:
      return {
        ...state,
        deliverables: [...state.deliverables, ...action.payload],
      };

    default:
      return state;
  }
};

export default contentBoardReducer;



