import React, { FC, ReactElement, ReactNode } from 'react';
import styles from './SectionWrapper.module.scss';

type PropsType = {
  children?: JSX.Element | ReactNode | ReactElement | string | null;
};

const SectionWrapper: FC<PropsType> = ({ children }) => {
  return <div className={styles.root}>{children}</div>;
};

export default SectionWrapper;
