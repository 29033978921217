import React from 'react';

const ArrowLongSVG = () => {
  return (
    <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.37811 4.79326L5.58862 6.58982L6.60585 7.60704L10.1414 4.07151L6.60585 0.535977L5.58862 1.5532L7.37811 3.34976L0.721 3.34976L0.720999 4.79326L7.37811 4.79326Z"
        fill="#4E3B8B"
      />
    </svg>
  );
};

export default ArrowLongSVG;
