import React, { FC, PropsWithChildren, ReactNode } from 'react';
import styles from './AuthTwoSideLayout.module.scss';
import cs from 'classnames';
import dayjs from 'dayjs';

type PropsType = {
  leftPartContent: JSX.Element | ReactNode;
  isLaptop?: boolean;
  mobileLeftVisible?: boolean;
} & PropsWithChildren;

const AuthTwoSideLayout: FC<PropsType> = ({ children, leftPartContent, isLaptop, mobileLeftVisible }) => (
  <div className={cs(styles.root, { [styles.mobileLeftVisible]: mobileLeftVisible })}>
    <div className={styles.container}>
      {leftPartContent}
      <div className={cs(styles.main)}>
        {children}
        {isLaptop && <div className={styles.copyright}>© {dayjs().format('YYYY')} Otterfish Limited</div>}
      </div>
    </div>
  </div>
);

export default AuthTwoSideLayout;
