import React from 'react';
import cs from 'classnames';
import styles from './SwitcherTab.module.scss';

const SwitcherTab = ({ switcher, value, setSwitcher, count, disabled }) => {
  return (
    <div
      className={cs(styles.root, { [styles.active]: switcher === value, [styles.disabled]: disabled })}
      onClick={() => !disabled && setSwitcher(value)}
    >
      {value}
      <div className={styles.counter}>{count || 0}</div>
    </div>
  );
};

export default SwitcherTab;
