import React, {FC} from 'react';

type PropsType = {
  className?: string;
}

const UploadArrowSvg: FC<PropsType> = props => {
  return (
    <svg className={props.className} width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.4958 6.19497C12.2224 6.46834 11.7792 6.46834 11.5058 6.19497L7.70078 2.38995V12.1374C7.70078 12.524 7.38738 12.8374 7.00078 12.8374C6.61418 12.8374 6.30078 12.524 6.30078 12.1374V2.38995L2.49576 6.19498C2.22239 6.46834 1.77917 6.46834 1.50581 6.19498C1.23244 5.92161 1.23244 5.47839 1.50581 5.20503L6.50581 0.205025C6.77917 -0.0683417 7.22239 -0.0683417 7.49576 0.205025L12.4958 5.20503C12.7691 5.47839 12.7691 5.92161 12.4958 6.19497ZM1.00078 13.6874C0.614182 13.6874 0.300781 14.0008 0.300781 14.3874C0.300781 14.774 0.614182 15.0874 1.00078 15.0874H13.0008C13.3874 15.0874 13.7008 14.774 13.7008 14.3874C13.7008 14.0008 13.3874 13.6874 13.0008 13.6874H1.00078Z" fill="white"/>
    </svg>
  );
};

export default UploadArrowSvg;
