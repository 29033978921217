import BlurBlockBackground from '../../../../components/BlurBlockBackground';
import ConfirmPopup from '../../../../components/ConfirmPopup';
import React, { FC, useEffect, useRef } from 'react';

type PropsType = {
  isOpen: boolean;
  onConfirm: () => void;
  onDiscard: () => void;
  scrollBackPosition?: ScrollLogicalPosition;
  popUpText?: string;
  primaryBtnText?: string;
  secondaryBtnText?: string;
  scrollOnlyWhenViewPortLess?: boolean;
};

const BlurConfirmPopup: FC<PropsType> = props => {
  const confirmPopupRef = useRef<HTMLDivElement | null>(null);
  const blurBlockRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!blurBlockRef?.current || !confirmPopupRef?.current) return;

    const shouldScroll = props.scrollOnlyWhenViewPortLess ? document.body.offsetHeight < blurBlockRef.current.offsetHeight : true;

    if (props.isOpen && shouldScroll) {
      confirmPopupRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  }, [props.isOpen]);

  const onCancel = () => {
    if (!blurBlockRef?.current) return;
    const isBlurBlockHigherThanViewPort = document.body.offsetHeight < blurBlockRef.current?.offsetHeight;

    if (props.scrollBackPosition && isBlurBlockHigherThanViewPort) {
      blurBlockRef.current.scrollIntoView({ behavior: 'smooth', block: props.scrollBackPosition, inline: 'nearest' });
    }
    props.onDiscard();
  };

  return props.isOpen ? (
    <BlurBlockBackground ref={blurBlockRef}>
      <ConfirmPopup
        ref={confirmPopupRef}
        withoutWrapper
        text={props.popUpText}
        buttonsConfig={{
          primaryButton: {
            text: props.primaryBtnText,
            onClick: props.onConfirm,
          },
          secondaryButton: {
            text: props.secondaryBtnText,
            onClick: onCancel,
          },
        }}
      />
    </BlurBlockBackground>
  ) : null;
};

export default BlurConfirmPopup;
