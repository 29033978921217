enum ERROR_TYPE {
  STORAGE_LIMIT_ERROR = 'storage_limit_error',
  TEAM_MEMBER_LIMIT_ERROR = 'team_member_limit_error',
  CONTENT_BOARD_CREATE_LIMIT_ERROR = 'content_board_create_limit_error',
  CAMPAIGN_BRIEF_ITEM_CREATE_LIMIT_ERROR = 'campaign_brief_item_create_limit_error',
  CONTENT_BOARD_COLLABORATE_LIMIT_ERROR = 'content_board_collaborate_limit_error',
  CAMPAIGN_BRIEF_ITEM_COLLABORATE_LIMIT_ERROR = 'campaign_brief_item_collaborate_limit_error',
}

export default ERROR_TYPE;
