import React, { useEffect, useState } from 'react';
import ProfileDetailsForm from './ProfileDetailsForm';
import { useDispatch } from 'react-redux';
import { simplifiedSignUp } from '../../../actions/auth.actions';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { emailValidation } from '../../CreateTest/utils';

export const ORGANIZATION_DEFAULT_NAME_START = '###_';

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const utmSource = searchParams.get('utm_source');
  const utmMedium = searchParams.get('utm_medium');
  const utmContent = searchParams.get('utm_content');
  const utmData = {
    source: utmSource,
    medium: utmMedium,
    content: utmContent,
  };

  const [isSignUpLoading, setIsSignUpLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirm_password: '',

      first_name: '',
      last_name: '',
      company_name: '',
      company_type: '',
      phone_number: '64',
      // inviteTeamMembers: [],
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required('Email is required').test('validate-email', emailValidation),
      password: Yup.string().required('Password is required').min(6, 'Password must be at least 6 characters'),
      confirm_password: Yup.string()
        .required('Password is required')
        .min(6, 'Password must be at least 6 characters')
        .oneOf([Yup.ref('password'), null], "Passwords don't match."),

      first_name: Yup.string().trim().required('First name is required'),
      last_name: Yup.string().trim().required('Last name is required'),
      company_type: Yup.string().required('Company type is required'),
      phone_number: Yup.string().required('Phone number name is required'),
    }),
    validateOnMount: true,
  });

  useEffect(() => {
    formik.values.email = formik.values.email.trim();
  }, [formik.values.email]);

  const handleSignUp = async isChecked => {
    setIsSignUpLoading(true);
    const data = {
      email: formik.values.email,
      password: formik.values.password,
      first_name: formik.values.first_name,
      last_name: formik.values.last_name,
      company_name: ORGANIZATION_DEFAULT_NAME_START + new Date().getTime(),
      company_type: formik.values.company_type,
      marketing_consent: isChecked,
      phone_number: `+${formik.values.phone_number}`,
    };

    await dispatch(simplifiedSignUp(data, utmData));
    setIsSignUpLoading(false);
  };

  return <ProfileDetailsForm formik={formik} onSignUp={handleSignUp} isSignUpLoading={isSignUpLoading} />;
};
