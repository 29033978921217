import React, { useEffect } from 'react';
import styles from './NotificationsDropMenu.module.scss';
import { ReactComponent as BellIcon } from '../../../assets/img/icons/bell.svg';
import cs from 'classnames';
import NotificationsModal from '../../Modals/NotificationsModal';
import { useDispatch } from 'react-redux';
import { changeUnread, setIsOpenNotifications } from '../../../actions/notifications.actions';
import * as notificationsServices from '../../../services/notifications';
import { useAppSelector } from '../../../shared/hooks/reduxHooks';

type PropsType = {
  className?: string;
};

const NotificationsDropMenu = ({ className }: PropsType) => {
  const dispatch = useDispatch();
  const { isOpen, totalUnread } = useAppSelector(state => state.notifications);

  const getUnreadNotifications = async () => {
    return await notificationsServices.getNotificationsList({ page: 1, page_size: 1 });
  };

  useEffect(() => {
    getUnreadNotifications().then(data => dispatch(changeUnread(data.total_unread, true)));
  }, []);

  return (
    <div className={cs(styles.container, className)}>
      <div className={cs(styles.icon, { [styles.active]: isOpen })} onClick={() => dispatch(setIsOpenNotifications(!isOpen))}>
        <BellIcon />
        {!!totalUnread && <div className={styles.counter}>{totalUnread}</div>}
      </div>
      <NotificationsModal open={isOpen} onClose={() => dispatch(setIsOpenNotifications(false))} />
    </div>
  );
};

export default NotificationsDropMenu;
