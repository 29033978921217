import React, { MouseEventHandler, MutableRefObject } from 'react';
import { useMediaQuery } from 'react-responsive/src';
import BreadcrumbsDropdown from '../BreadcrumbsDropdown';
import Breadcrumb from '../Breadcrumb';
import { BreadcrumbRoute } from '../../../../models/entities/breadcrumbs/BreadcrumbRoute';

type PropsType = {
  items: BreadcrumbRoute[];
  onClick: (callback: (event: MouseEvent) => void, index: number) => ((event: MouseEvent) => void) | void;
  rootRef: MutableRefObject<HTMLElement | null>;
};

const DesktopLandscape = (props: PropsType) => {
  const isDesktopLandscape = useMediaQuery({
    query: '(min-width: 992px) and (orientation: landscape)',
  });

  return isDesktopLandscape ? (
    <React.Fragment>
      {props.items.length > 3 && (
        <>
          {/* @ts-ignore */}
          <Breadcrumb emptyItem itemsLength={props.items.length} index={0} isFirstItem />
          <BreadcrumbsDropdown items={props.items.slice(0, props.items.length - 2)} onClick={props.onClick} />
          <Breadcrumb
            onClick={props.onClick}
            item={props.items[props.items.length - 2]}
            index={props.items.length - 2}
            rootRef={props.rootRef}
            isFirstItem={false}
            // @ts-ignore
            itemsLength={props.items.length}
          />
          <Breadcrumb
            onClick={props.onClick}
            item={props.items[props.items.length - 1]}
            index={props.items.length - 1}
            rootRef={props.rootRef}
            isFirstItem={false}
            // @ts-ignore
            itemsLength={props.items.length}
          />
        </>
      )}
      {!(props.items.length > 3) &&
        props.items.map((item, i) => (
          <Breadcrumb
            key={item.name + i}
            isFirstItem={i === 0}
            onClick={props.onClick}
            item={item}
            index={i}
            rootRef={props.rootRef}
            // @ts-ignore
            itemsLength={props.items.length}
          />
        ))}
    </React.Fragment>
  ) : null;
};

export default DesktopLandscape;
