import React, { useEffect } from 'react';
import styles from '../SignIn.module.scss';
import InputControl from '../../../../expert/components/FormControls/InputControl';
import ButtonCustom from '../../../../expert/components/Buttons/ButtonCustom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputPassword from '../../../../components/Inputs/InputPassword';
import { useDispatch, useSelector } from 'react-redux';
import { clearFailure, signIn } from '../../../../actions/auth.actions';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { onClickEnter } from '../../../../shared/utils/helpers';
import AuthTwoSideLayout from '../../../../components/AuthTwoSideLayout';
import cs from 'classnames';
import LeftPartSignIn from '../../../../components/AuthTwoSideLayout/LeftPartSignIn';
import dayjs from 'dayjs';
import { emailValidation } from '../../../CreateTest/utils';

const EmailForm = () => {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.signIn.loading);
  const failure = useSelector(state => state.auth.failure);
  const location = useLocation();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().test('validate-email', emailValidation).required('Email is required'),
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: values => {
      dispatch(signIn(values.email, values.password)).then(() => {
        const from = location?.state?.from;
        if (from) {
          history.push(`${from.pathname}${from.search}`);
        }
      });
    },
  });

  useEffect(() => {
    if (failure) dispatch(clearFailure());
  }, [formik.values.email, formik.values.password]);

  const emailErrorMessage = failure.message?.includes('not exist') ? 'User does not exist' : formik.errors.email;
  const passwordErrorMessage = failure.message?.includes('password') ? 'Incorrect Password' : formik.errors.password;

  useEffect(() => {
    if (window.SparrowLauncher) window.SparrowLauncher('remove');
  }, []);

  return (
    <AuthTwoSideLayout mobileLeftVisible leftPartContent={<LeftPartSignIn />}>
      <div className={styles.root}>
        {/* <LogoSVG />*/}
        <div>
          <div className={styles.title}>Log in</div>
          <form className={styles.form}>
            <InputControl
              name="email"
              type="email"
              placeholder="Enter your email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              errorMessage={emailErrorMessage}
              className={cs(styles.input, { [styles.marginError]: !!emailErrorMessage })}
            />
            <div className={styles.password}>
              <InputPassword
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                errorMessage={passwordErrorMessage}
                className={cs(styles.input, { [styles.marginError]: !!passwordErrorMessage })}
                onKeyDown={e => onClickEnter(formik.handleSubmit, e)}
              />
            </div>
            <div className={styles.navigation}>
              <ButtonCustom onClick={formik.handleSubmit} type="button" loading={loading}>
                Sign in
              </ButtonCustom>
              <NavLink to="/forgot-password">
                <div className={styles.forgotPassword} onClick={() => dispatch(clearFailure())}>
                  Forgot password?
                </div>
              </NavLink>
              <div className={styles.signUp} onClick={() => dispatch(clearFailure())}>
                Don’t have account? <NavLink to="/sign-up">Sign up</NavLink>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className={styles.copyrightSignIn}>© {dayjs().format('YYYY')} otterfish Inc. All rights reserved.</div>
    </AuthTwoSideLayout>
  );
};

export default EmailForm;
