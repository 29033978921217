import React, { ComponentProps, FC } from 'react';
import styles from './AddVisualsButton.module.scss';
import ButtonLightPurpleWhite from '../../../../../../components/Buttons/ButtonLightPurpleWhite';
import AddAssetFileInput from '../../inputs/AddAssetFileInput';
import { ICreatedAsset } from '../../../../../../models/entities/asset/createdAsset';
import { DELIVERABLE_SECTION_TYPES } from '../../../../../../models/enums/DeliverableSectionTypes';
import { INPUT_FILE_ALLOWED_TYPE } from '../../../../../../shared/constants/tsConstants';

type PropsType = {
  fileTypes?: INPUT_FILE_ALLOWED_TYPE[];
  onAddAsset: (asset: ICreatedAsset) => void;
  size: ComponentProps<typeof ButtonLightPurpleWhite>['size'];
  maxVisualsLength: number;
  currentVisualsCount: number;
  assetType: DELIVERABLE_SECTION_TYPES;
};
const AddVisualsButton: FC<PropsType> = ({
  fileTypes = [INPUT_FILE_ALLOWED_TYPE.IMAGE_INPUT_ALLOWED],
  onAddAsset,
  size = 'small',
  maxVisualsLength,
  currentVisualsCount,
  assetType,
}) => {
  return (
    <div className={styles.addButton}>
      <ButtonLightPurpleWhite presetIcon="image" size={size} designVersion="v1">
        Add Visuals
      </ButtonLightPurpleWhite>
      <AddAssetFileInput
        onAddAsset={onAddAsset}
        assetType={assetType}
        fileTypes={fileTypes}
        allowedLength={maxVisualsLength - currentVisualsCount}
      />
    </div>
  );
};

export default AddVisualsButton;
