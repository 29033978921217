import { useEffect, useRef, useState } from 'react';
import { AuthorizedGuest } from '../models/permissions/users/AuthorizedGuest';
import { UnauthorizedGuest } from '../models/permissions/users/UnauthorizedGuest';
import { useAppSelector } from '../shared/hooks/reduxHooks';
import { User } from '../models/permissions/users/User';
import { ICentrifuge } from '../models/cetrifuge/Centrifuge';
import { ISubscribeArguments, ISubscriptionObject } from './types';

const useSubscribeChannel = (idIdentifier: number, subscribeArguments: ISubscribeArguments, stopCondition: boolean = false): void => {
  const JWT = useAppSelector<string | null>(state => state.auth?.token?.access_token);
  const userModel = useAppSelector<User>(state => state.auth?.userModel);
  const appCentrifuge = useAppSelector<ICentrifuge>(state => state.centrifuge.appCentrifuge);
  const isCentrifugeConnected = useAppSelector<boolean>(state => state.centrifuge.isCentrifugeConnected);
  const currentOrganizationID = useAppSelector<string | number>(state => state.auth.currentOrganization?.organization_id);
  const [currentSubscriptions, setCurrentSubscription] = useState<null | ISubscriptionObject>(null);
  const refSubscription = useRef<null | ISubscriptionObject>(null);

  useEffect(() => {
    return () => {
      if (refSubscription.current) {
        refSubscription.current.subscription.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    if (!isCentrifugeConnected && currentSubscriptions) {
      setCurrentSubscription(null);
      refSubscription.current = null;
    }
  }, [isCentrifugeConnected, currentSubscriptions]);

  useEffect(() => {
    if (
      !isCentrifugeConnected ||
      !currentOrganizationID ||
      !JWT ||
      !idIdentifier ||
      stopCondition ||
      userModel instanceof AuthorizedGuest ||
      userModel instanceof UnauthorizedGuest
    ) {
      return;
    }

    if (!currentSubscriptions || idIdentifier !== currentSubscriptions.id) {
      if (currentSubscriptions) {
        currentSubscriptions.subscription.unsubscribe();
      }
      const newSubscription = appCentrifuge.centrifuge
        .subscribe(subscribeArguments.channel, subscribeArguments.events, subscribeArguments.opts)
        .on('error', error => subscribeArguments.onError && subscribeArguments.onError(error))
        .on('subscribe', ctx => subscribeArguments.onSubscribe && subscribeArguments.onSubscribe(ctx))
        .on('unsubscribe', ctx => subscribeArguments.onUnsubscribe && subscribeArguments.onUnsubscribe(ctx));

      const newSubscriptionObject = { id: idIdentifier, subscription: newSubscription };
      refSubscription.current = newSubscriptionObject;
      setCurrentSubscription(newSubscriptionObject);
    }
  }, [isCentrifugeConnected, idIdentifier, JWT, currentOrganizationID, userModel, stopCondition]);
};

export default useSubscribeChannel;
