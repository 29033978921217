import React from 'react';
import LoadingContainer from '../../../../../../../components/LoadingContainer';

const FormLoadingState = (): React.ReactElement => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', gap: '10px' }}>
      <LoadingContainer height="220px" borderRadius="20px" animationDelay=".1s" />
      <LoadingContainer height="100%" borderRadius="20px" animationDelay=".2s" />
    </div>
  );
};

export default FormLoadingState;
