import React from 'react';
import styles from './MobileBreadcrumbs.module.scss';
import cs from 'classnames';
import { useMediaQuery } from 'react-responsive/src';

const MobileBreadcrumbs = ({ className, config }) => {
  const isDesktopLandscape = useMediaQuery({
    query: '(min-width: 992px) and (orientation: landscape)',
  });

  return <div className={cs(styles.root, { [styles.tabletStyle]: !isDesktopLandscape }, className)}>{config}</div>;
};

export default MobileBreadcrumbs;
