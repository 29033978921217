import React, { FC } from 'react';
import styles from './Visual.module.scss';
import { ReactComponent as DescriptionIcon } from '../../../../../../../assets/img/icons/assetText.svg';
import DownloadSVG from '../../../../../../../expert/components/IconsSvg/DownloadSVG';
import { useMediaQuery } from 'react-responsive/src';
import { IBriefAsset } from '../../../../../../../models/entities/asset/BriefAsset';
import VisualTileTooltipWrapper from '../../../../../../../components/WhiteTooltips/VisualTileTooltipWrapper';
import useDownloadAsset from '../../../../../../../shared/hooks/useDownloadAsset';
import VisualHover from '../../../../../../ContentApproval/components/VisualHover';

type PropsType = { asset: IBriefAsset; onHoverClick: (asset: IBriefAsset) => void };

const Visual: FC<PropsType> = props => {
  const isMediumDevice = useMediaQuery({ query: '(max-width: 992px)' });

  const { isAssetLoading, downloadAsset } = useDownloadAsset();

  const onDownload = () => {
    if (isAssetLoading || !props.asset.url) return;
    downloadAsset(props.asset.url, props.asset.extension);
  };

  return (
    <VisualTileTooltipWrapper isGreenTag={true} isEditingMode={false} asset={props.asset} placement={isMediumDevice ? 'bottom' : 'left'}>
      <div className={styles.root}>
        <VisualHover onClick={() => props.onHoverClick(props.asset)} className={styles.visualHover} />
        {props.asset?.description?.length ? <DescriptionIcon className={styles.textIcon} /> : null}
        <button className={styles.downloadButton} onClick={onDownload}>
          <DownloadSVG />
        </button>
        <img className={styles.media} src={props.asset.url || ''} alt={''} />
      </div>
    </VisualTileTooltipWrapper>
  );
};

export default Visual;
