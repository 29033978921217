import React, { useEffect, useRef, useState, useMemo } from 'react';
import styles from './CheckoutSuccess.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../expert/components/Loader';
import { me } from '../../actions/auth.actions';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { parseQuery } from '../../shared/utils/helpers';
import { useThunkDispatch } from '../../shared/hooks/reduxHooks';
import { updateOrganizationPricingPlan } from '../../actions/organizations.actions';
import { ORGANIZATION_DEFAULT_NAME_START } from '../Auth/SignUpNew';

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
};

const CheckoutSuccess = () => {
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const history = useHistory();
  const plan = useSelector(state => state.auth.currentOrganizationPlan);
  const currentOrg = useSelector(state => state.auth.currentOrganization);
  const location = useLocation();
  const [count, setCount] = useState(0);
  const [requestCount, setRequestCount] = useState(0);
  const [requestEnded, setRequestEnded] = useState(false);

  const query = useMemo(() => {
    return parseQuery(location.search);
  }, [location]);

  useEffect(() => {
    if (currentOrg && query?.pricing_plan_id && query?.pricing_plan_recurrence && requestCount === 0) {
      setRequestCount(requestCount + 1);
      thunkDispatch(
        updateOrganizationPricingPlan(currentOrg.organization_id, {
          pricing_plan_id: query.pricing_plan_id,
          pricing_plan_recurrence: query.pricing_plan_recurrence,
        })
      ).finally(() => setRequestEnded(true));
    }
  }, [query, currentOrg, requestCount]);

  useInterval(() => {
    if (!requestEnded) return;
    if (count < 5) {
      if (!plan?.id || plan?.id !== parseInt(query?.pricing_plan_id, 10)) {
        dispatch(me());
      } else {
        if (currentOrg.organization_name.startsWith(ORGANIZATION_DEFAULT_NAME_START)) {
          history.push('/complete-organization');
        } else {
          history.push('/organization/plan');
        }
      }
      setCount(count + 1);
    } else {
      history.push('/upgrade-plan');
    }
  }, 2500);

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <Loader />
      </div>
    </div>
  );
};

export default CheckoutSuccess;
