import React, { FC, ReactElement, ReactNode } from 'react';
import ButtonLightPurpleWhite from '../../../../../../components/Buttons/ButtonLightPurpleWhite';
import cs from 'classnames';
import DownloadAllButton from '../../../../components/DownloadAllButton';
import { AssetStateType } from '../../../../../../models/entities/asset/AssetState';

type PropsType = {
  classNameWrapper?: string;
  classNameButton?: string;
  onRemove?: (() => void) | null;
  onEdit: () => void;
  editText: string;
  isDisplayRemove?: boolean;
  children?: JSX.Element | ReactElement | ReactNode | string | null;
  isDisplayDownload?: boolean;
  downloadAssets?: AssetStateType[];
  isDisplayEdit?: boolean;
};

const ActionButtons: FC<PropsType> = ({
  classNameWrapper,
  classNameButton,
  onRemove,
  onEdit,
  editText,
  isDisplayRemove,
  children,
  isDisplayDownload,
  downloadAssets,
  isDisplayEdit,
}) => {
  return (
    <div className={cs({ [classNameWrapper || '']: classNameWrapper })}>
      {isDisplayRemove && (
        <ButtonLightPurpleWhite
          onClick={onRemove || (() => {})}
          className={classNameButton}
          designVersion="v2"
          presetIcon="deleteCart"
          size="small"
        >
          Remove
        </ButtonLightPurpleWhite>
      )}
      {/* @ts-ignore */}
      {isDisplayDownload && <DownloadAllButton className={classNameButton} designVersion="v2" size="small" assets={downloadAssets} />}
      {isDisplayEdit ? (
        <ButtonLightPurpleWhite className={classNameButton} designVersion="v2" presetIcon="editPencil" size="small" onClick={onEdit}>
          {editText}
        </ButtonLightPurpleWhite>
      ) : null}
      {children}
    </div>
  );
};

export default ActionButtons;
