import React, { FC, useMemo, useState } from 'react';
import styles from './CollaboratorInfo.module.scss';
import CollaboratorsTooltip from '../../../../WhiteTooltips/CollaboratorsTooltip';
import UserColoredIcon from '../../../../UserColoredIcon';
import { ICollaborator } from '../../../../../models/entities/collaborator/Collaborator';

type PropsType = {
  collaborator: ICollaborator;
  index: number;
};

const CollaboratorInfo: FC<PropsType> = props => {
  const letter = useMemo(() => props.collaborator.collaborator_first_name.slice(0, 1).toUpperCase(), [props.collaborator]);

  const [hover, setHover] = useState<boolean>(false);

  return (
    <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} key={props.index} className={styles.icon}>
      <CollaboratorsTooltip withoutToolTip={!hover} indexes={[props.index]} collaborators={[props.collaborator]}>
        <div className={styles.iconContent}>
          {props.collaborator.photo_thumbnail_location ? (
            <img
              className={styles.iconContent}
              src={props.collaborator.photo_thumbnail_location}
              alt={props.collaborator.collaborator_first_name}
            />
          ) : (
            <UserColoredIcon className={styles.iconContent} key={props.index} colorNumber={props.index} text={letter} />
          )}
        </div>
      </CollaboratorsTooltip>
    </div>
  );
};

export default CollaboratorInfo;
