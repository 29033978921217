import dayjs from 'dayjs';
import moment from 'moment/moment';
import { useMemo } from 'react';

export const useTimeRelativeToNow = date => {
  return useMemo(() => {
    const dateDifference = date ? dayjs(new Date()).diff(date) : null;
    const isPastedDate = dateDifference > 0;
    const isUpcomingDate = dateDifference < 0 && dateDifference > -172800000;
    const isTodayDate = moment(date).isSame(new Date(), 'day');

    return {
      dateDifference,
      isPastedDate,
      isUpcomingDate,
      isTodayDate,
    };
  }, [date]);
};
