import { CONTENT_APPROVAL_ACTION_TYPES, ContentApprovalActionTypes } from '../actions/ContentApprovalActions/types';
import { IBoardListItem } from '../models/entities/board/BoardListItem';

export type ContentApprovalReducerStateType = {
  boards: IBoardListItem[];
  archivedCount: number;
  boardsTotalCount: number;
  contentApprovalTabSwitcher: 'boards' | 'archived';
  boardsLoading: boolean;
  createBoardLoading: boolean;
  isAllBoardTypesEmpty: boolean;
};

const initialState: ContentApprovalReducerStateType = {
  boards: [],
  archivedCount: 0,
  boardsTotalCount: 0,
  contentApprovalTabSwitcher: 'boards',
  boardsLoading: true,
  createBoardLoading: false,
  isAllBoardTypesEmpty: false,
};

export default (
  state: ContentApprovalReducerStateType = initialState,
  action: ContentApprovalActionTypes
): ContentApprovalReducerStateType => {
  switch (action.type) {
    case CONTENT_APPROVAL_ACTION_TYPES.SET_CONTENT_APPROVAL_TAB_SWITCHER: {
      return {
        ...state,
        contentApprovalTabSwitcher: action.payload,
      };
    }
    case CONTENT_APPROVAL_ACTION_TYPES.GET_CONTENT_BOARDS_REQUEST:
      return {
        ...state,
        boardsLoading: action?.loading !== false,
      };
    case CONTENT_APPROVAL_ACTION_TYPES.GET_CONTENT_BOARDS_SUCCESS:
      return {
        ...state,
        boards: action.payload,
        boardsLoading: false,
        boardsTotalCount: action.totalCount,
        archivedCount: action.archivedTotalCount,
      };
    case CONTENT_APPROVAL_ACTION_TYPES.GET_CONTENT_BOARDS_FAILURE:
      return {
        ...state,
        boardsLoading: false,
      };
    case CONTENT_APPROVAL_ACTION_TYPES.CREATE_BOARD_REQUEST:
      return {
        ...state,
        createBoardLoading: true,
      };
    case CONTENT_APPROVAL_ACTION_TYPES.CREATE_BOARD_SUCCESS:
      return {
        ...state,
        createBoardLoading: false,
      };
    case CONTENT_APPROVAL_ACTION_TYPES.CREATE_BOARD_FAILURE:
      return {
        ...state,
        createBoardLoading: false,
      };
    case CONTENT_APPROVAL_ACTION_TYPES.UPDATE_CONTENT_APPROVAL_LIST_BOARD:
      return {
        ...state,
        boards: action.payload,
      };
    case CONTENT_APPROVAL_ACTION_TYPES.DUPLICATE_BOARD_REQUEST: {
      return { ...state, boardsLoading: true };
    }
    case CONTENT_APPROVAL_ACTION_TYPES.DUPLICATE_BOARD_SUCCESS: {
      const duplicatedIndex = state.boards.findIndex(d => d.id === action.payload.afterId);
      const newBoards = [...state.boards];
      newBoards.splice(duplicatedIndex + 1, 0, action.payload.board);
      return { ...state, boardsLoading: false, boards: newBoards };
    }
    case CONTENT_APPROVAL_ACTION_TYPES.DUPLICATE_BOARD_FAILURE: {
      return { ...state, boardsLoading: false };
    }
    case CONTENT_APPROVAL_ACTION_TYPES.DELETE_BOARD_REQUEST: {
      return { ...state, boardsLoading: true };
    }
    case CONTENT_APPROVAL_ACTION_TYPES.DELETE_BOARD_SUCCESS: {
      return { ...state, boardsLoading: false };
    }
    case CONTENT_APPROVAL_ACTION_TYPES.DELETE_BOARD_FAILURE: {
      return { ...state, boardsLoading: false };
    }
    case CONTENT_APPROVAL_ACTION_TYPES.SET_IS_ALL_BOARD_TYPES_EMPTY: {
      return { ...state, isAllBoardTypesEmpty: action.payload };
    }
    default:
      return state;
  }
};
