import { IUpdateNotificationPreferencesGroupDTO } from '../dto/UpdateNotificationPreferencesGroup';
import { NOTIFICATION_PREFERENCES_GROUP_TYPE } from '../enum/NotificationPreferencesGroupType';

class UpdateNotificationPreferencesGroupHydrator implements IUpdateNotificationPreferencesGroupDTO {
  group_type: NOTIFICATION_PREFERENCES_GROUP_TYPE;
  is_enabled: boolean;

  constructor(groupType: NOTIFICATION_PREFERENCES_GROUP_TYPE, is_enabled: boolean) {
    this.group_type = groupType;
    this.is_enabled = is_enabled;
  }
}

export default UpdateNotificationPreferencesGroupHydrator;
