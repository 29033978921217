import React, { FC, ReactElement } from 'react';
import MainLayout from '../../../components/MainLayout';
import cs from 'classnames';
import styles from './ViewBriefPage.module.scss';
import { useMediaQuery } from 'react-responsive/src';
import { IViewBriefData } from '../../../models/entities/boardBrief/ViewBrief';
import BoardBriefHeader from './components/BoardBriefHeader';
import BriefCardsSection from './components/BriefCardsSection';
import BrandNameSection from './components/BrandNameSection';
import BrandDetails from './components/BrandDetails';
import { IBriefAsset } from '../../../models/entities/asset/BriefAsset';

type ViewBriefPagePropsType = {
  isLoading: boolean;
  brief: IViewBriefData | null;
  visualAssets: IBriefAsset[];
  title?: ReactElement | string;
  mobileJSXTitle?: ReactElement;
  onEdit: () => void;
  isEditDisplay: boolean;
};

const ViewBriefPage: FC<ViewBriefPagePropsType> = props => {
  const isDesktopLandscape = useMediaQuery({
    query: '(min-width: 992px) and (orientation: landscape)',
  });

  return (
    // @ts-ignore
    <MainLayout contentClassName={cs(styles.mainLayout, { [styles.mobileLayout]: !isDesktopLandscape })} title={props.title || ''}>
      {props.mobileJSXTitle || null}
      <div className={styles.root}>
        <BoardBriefHeader
          isLoading={props.isLoading}
          briefName={props.brief?.name}
          className={styles.header}
          onEdit={props.onEdit}
          isEditDisplay={props.isEditDisplay}
        />
        <BriefCardsSection
          brief={props.brief}
          visualAssets={props.visualAssets}
          isLoading={props.isLoading}
          className={styles.rightSection}
        />
        <BrandNameSection
          isLoading={props.isLoading}
          className={styles.brandSection}
          brandLogo={props.brief?.brand_logo}
          brandName={props.brief?.brand_name}
        />
        <BrandDetails
          isLoading={props.isLoading}
          brief={props.brief}
          className={styles.textSection}
          onEdit={props.onEdit}
          isEditDisplay={props.isEditDisplay}
        />
      </div>
    </MainLayout>
  );
};

export default ViewBriefPage;
