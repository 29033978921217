import { useEffect, useState } from 'react';
import {
  approveDeliverableSection,
  clearAssetsToUpdate,
  deleteDeliverableSection,
  ISaveDeliverableSectionData,
  saveDeliverableSection,
  shareAndApproveDeliverableSection,
  shareDeliverableSection,
} from '../../../../actions/deliverablesActions/deliverables.actions';
import { useParams } from 'react-router-dom';
import { isEqual } from 'lodash';
import { getReorderedAssetsStates, mutateAssetsAfterConverting } from '../helpers';
import { arrayMove } from '@dnd-kit/sortable';
import { AssetStateType } from '../../../../models/entities/asset/AssetState';
import { ICreatedAsset } from '../../../../models/entities/asset/createdAsset';
import { DELIVERABLE_STATUSES } from '../../../../models/enums/DeliverableStatuses';
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/reduxHooks';
import { DeliverablesReducerState } from '../../../../reducers/deliverables.reducer';
import { DELIVERABLE_SECTION_TYPES } from '../../../../models/enums/DeliverableSectionTypes';

type ConceptStateType = {
  description: string;
  isChanged: boolean;
};

type AssetsLocalStateType = {
  current: AssetStateType[];
  delete: AssetStateType[];
  update: AssetStateType[];
  create: ICreatedAsset[];
  isDelete: boolean;
  isUpdate: boolean;
  isCreate: boolean;
};
export type ConceptAssetsStateType = {
  concept: {
    onChangeConcept: (newState: string) => void;
    shareConcept: () => void;
    approveConcept: () => void;
    shareAndApproveConcept: () => void;
    removeConcept: () => void;
    saveConcept: () => void;
    status: DELIVERABLE_STATUSES;
    isShared: boolean;
    isApproved: boolean;
  } & ConceptStateType;

  conceptAssets: {
    onCreate: (asset: ICreatedAsset) => void;
    onUpdate: (asset: AssetStateType) => void;
    onDelete: (asset: AssetStateType) => void;
    onReorder: (sourceId: AssetStateType['id'], destinationId: AssetStateType['id']) => void;
  } & AssetsLocalStateType;

  conceptEdit: {
    checkpointConcept: () => void;
    restoreConcept: () => void;
  };
};

type UseConceptStateType = () => ConceptAssetsStateType;

const conceptInitValue: ConceptStateType = {
  description: '',
  isChanged: false,
};

const assetsInitialState: AssetsLocalStateType = {
  current: [],
  delete: [],
  update: [],
  create: [],
  isDelete: false,
  isUpdate: false,
  isCreate: false,
};

const isConceptChanged = (nextState: ConceptStateType, currentState: ConceptStateType | null): boolean => {
  if (!currentState) return false;
  let res = false;
  if (currentState.description !== nextState.description) {
    res = true;
  }
  return res;
};

const removeFromArray = <ItemType extends { id: string | number }, ArrayItemType extends { id: string | number }>(
  array: ArrayItemType[],
  item: ItemType
): ArrayItemType[] => {
  return array.filter(i => i.id !== item.id);
};

const isAssetChanged = (array: AssetStateType[], item: AssetStateType) => {
  const mutableAsset = array.find(asset => asset?.id === item.id);
  if (!mutableAsset) return false;

  return (
    ((mutableAsset.caption === null ? '' !== item.caption : mutableAsset.caption?.replace(/\r/g, '') !== item.caption) ||
      ('file' in item && !!item.file)) &&
    !isEqual(item, mutableAsset)
  );
};

const useConceptState: UseConceptStateType = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const currentOrgId = useAppSelector<string | number>(state => state.auth.currentOrganization?.organization_id);
  const { openedDeliverable, assetsToUpdate } = useAppSelector<DeliverablesReducerState>(state => state.deliverables);

  // concept
  const [concept, setConcept] = useState<ConceptStateType>({ ...conceptInitValue });
  const [initConceptValue, setInitConceptValue] = useState<ConceptStateType | null>(null);
  const [savedConcept, setSavedConcept] = useState<ConceptStateType>({ ...conceptInitValue });
  // assets
  const [assetsState, setAssetsState] = useState<AssetsLocalStateType>({ ...assetsInitialState });
  const [savedAssets, setSavedAssets] = useState<AssetsLocalStateType>({ ...assetsInitialState });

  useEffect(() => {
    const newConceptInitValue: ConceptStateType = {
      ...conceptInitValue,
      description: openedDeliverable?.concept?.description || '',
    };
    setSavedConcept({ ...newConceptInitValue });
    setConcept({ ...newConceptInitValue });
    setInitConceptValue({ ...newConceptInitValue });

    const assets = openedDeliverable?.concept?.assets;
    // assets
    setAssetsState({
      ...assetsInitialState,
      current: assets ? [...assets] : [],
    });
    setSavedAssets({
      ...assetsInitialState,
      current: assets ? [...assets] : [],
    });
  }, [openedDeliverable?.concept]);

  const getUpdatedState = (newAssets: AssetStateType[], currentAssets: AssetsLocalStateType): AssetsLocalStateType => {
    const newState = { ...currentAssets };
    newState.current = mutateAssetsAfterConverting(newState.current, newAssets);
    newState.update = mutateAssetsAfterConverting(newState.update, newAssets);
    return newState;
  };

  useEffect(() => {
    if (assetsToUpdate && assetsToUpdate.type === DELIVERABLE_SECTION_TYPES.CONCEPT) {
      const updatedAssets = getUpdatedState([assetsToUpdate.asset], assetsState);
      const updatedSavedAssets = getUpdatedState([assetsToUpdate.asset], savedAssets);
      if (updatedAssets) setAssetsState(updatedAssets);
      if (updatedSavedAssets) setSavedAssets(updatedSavedAssets);
      dispatch(clearAssetsToUpdate());
    }
  }, [assetsToUpdate]);

  const onChangeConcept = (value: string) => {
    const nextState = { ...concept, description: value };
    setConcept({
      ...nextState,
      isChanged: isConceptChanged(nextState, initConceptValue),
    });
  };

  const onCreate = (asset: ICreatedAsset): void => {
    setAssetsState(prevState => {
      const newAssetOrder = prevState.current.length + 1;
      const newAsset = { ...asset, display_order: newAssetOrder };

      return {
        ...prevState,
        isCreate: true,
        current: [...prevState.current, newAsset],
        create: [...prevState.create, newAsset],
      };
    });
  };

  const onUpdate = (asset: AssetStateType) => {
    const newAsset = { ...asset };
    const isNewAssetOfCreatedType = 'file' in newAsset && typeof newAsset.id === 'string';
    const isValidUpdateAsset = typeof newAsset.id !== 'string' && isAssetChanged(openedDeliverable?.concept?.assets || [], newAsset);
    const newUpdateAssets = [...removeFromArray(assetsState.update, newAsset)];
    if (isValidUpdateAsset) {
      newUpdateAssets.push(newAsset);
    }

    setAssetsState({
      ...assetsState,
      isUpdate: !!newUpdateAssets.length,
      current: assetsState.current.map(obj => {
        if (obj.id === newAsset.id) return newAsset;
        return obj;
      }),
      create: assetsState.create.map(obj => {
        if (obj.id === newAsset.id && isNewAssetOfCreatedType) return newAsset;
        return obj;
      }),
      update: newUpdateAssets,
    });
  };

  const onReorder = (sourceId: AssetStateType['id'], destinationId: AssetStateType['id']) => {
    setAssetsState(oldAssetsState => {
      const sourceAssetIndex = oldAssetsState.current.findIndex(({ id }) => id === sourceId);
      const destinationAssetIndex = oldAssetsState.current.findIndex(({ id }) => id === destinationId);
      const reorderedAssets = arrayMove(oldAssetsState.current, sourceAssetIndex, destinationAssetIndex);

      const { updatedReorderedAssetsState, createdReorderedAssetsState, currentReorderedAssetsState } = getReorderedAssetsStates(
        oldAssetsState.update,
        oldAssetsState.create,
        oldAssetsState.current,
        reorderedAssets
      );

      return {
        ...oldAssetsState,
        isUpdate: true,
        current: currentReorderedAssetsState,
        update: updatedReorderedAssetsState,
        create: createdReorderedAssetsState,
      };
    });
  };

  const onDelete = (asset: AssetStateType) => {
    const isValidDeleteAsset = typeof asset.id !== 'string';
    const newDeleteAssets = [...assetsState.delete];
    if (isValidDeleteAsset) {
      newDeleteAssets.push(asset);
    }

    const newCurrentAssets = removeFromArray(assetsState.current, asset);
    const newCreateAssets = removeFromArray(assetsState.create, asset);
    const newUpdateAssets = removeFromArray(assetsState.update, asset);

    const { createdReorderedAssetsState, currentReorderedAssetsState, updatedReorderedAssetsState } = getReorderedAssetsStates(
      newUpdateAssets,
      newCreateAssets,
      newCurrentAssets,
      newCurrentAssets
    );

    setAssetsState({
      ...assetsState,
      isDelete: !!newDeleteAssets.length,
      isCreate: !!newCreateAssets.length,
      isUpdate: !!newUpdateAssets.length,
      current: currentReorderedAssetsState,
      create: createdReorderedAssetsState,
      update: updatedReorderedAssetsState,
      delete: newDeleteAssets,
    });
  };

  const checkpointConcept = () => {
    setSavedConcept({ ...concept });
    setSavedAssets({ ...assetsState });
  };

  const restoreConcept = () => {
    setConcept({ ...savedConcept });
    setAssetsState({ ...savedAssets });
  };

  const shareAndApproveConcept = () => {
    if (!openedDeliverable?.concept?.id) return;
    dispatch(shareAndApproveDeliverableSection(currentOrgId, +params.boardId, +params.deliverableId, openedDeliverable?.concept?.id));
  };

  const approveConcept = () => {
    if (!openedDeliverable?.concept?.id) return;
    dispatch(approveDeliverableSection(currentOrgId, +params.boardId, +params.deliverableId, openedDeliverable?.concept?.id));
  };

  const shareConcept = () => {
    if (!openedDeliverable?.concept?.id) return;
    dispatch(shareDeliverableSection(currentOrgId, +params.boardId, +params.deliverableId, openedDeliverable?.concept?.id));
  };

  const saveConcept = () => {
    const data: ISaveDeliverableSectionData = {
      section: {
        payload: {
          type: DELIVERABLE_SECTION_TYPES.CONCEPT,
          description: concept?.description || null,
        },
        isChanged: concept.isChanged,
      },
    };

    if (assetsState.isUpdate || assetsState.isCreate || assetsState.isDelete) {
      data.assets = {};
      if (assetsState.create.length) {
        data.assets.create = assetsState.create;
        data.assets.current = assetsState.current;
      }
      if (assetsState.update.length) data.assets.update = assetsState.update;
      if (assetsState.delete.length) data.assets.delete = assetsState.delete;
    }
    dispatch(saveDeliverableSection(currentOrgId, +params.boardId, +params.deliverableId, data));
  };

  const removeConcept = () => {
    dispatch(deleteDeliverableSection(currentOrgId, +params.boardId, +params.deliverableId, DELIVERABLE_SECTION_TYPES.CONCEPT));
  };

  return {
    concept: {
      ...concept,
      onChangeConcept,
      shareConcept,
      approveConcept,
      shareAndApproveConcept,
      removeConcept,
      saveConcept,
      status: openedDeliverable?.deliverable?.status_concept || DELIVERABLE_STATUSES.EMPTY,
      isShared: !!openedDeliverable?.concept?.is_shared,
      isApproved: openedDeliverable?.concept?.status === DELIVERABLE_STATUSES.APPROVED,
    },
    conceptAssets: {
      ...assetsState,
      onCreate,
      onUpdate,
      onDelete,
      onReorder,
    },
    conceptEdit: {
      checkpointConcept,
      restoreConcept,
    },
  };
};

export default useConceptState;
