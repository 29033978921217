import React, {FC, ReactNode, useCallback, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../shared/hooks/reduxHooks";
import { useParams} from "react-router-dom";
import {clearResultsState, getResultAssets} from "../../../actions/ResultsActions/results.actions";
import TopBar from "./TopBar";
import MainLayout from "../../../components/MainLayout";
import EmptyState from "./EmptyState";
import styles from './Results.module.scss';
import UploadResultsModal from "../components/results/UploadResultsModal";
import useBoardSetup from "../hooks/useBoardSetup";
import ResultsList from "./ResultsList";
import LoadingState from "./LoadingState";
import {useHistory} from "react-router";
import LayoutHeader from "../components/LayoutHeader";

const Results: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const params = useParams() as { boardId: string };
  const userModel = useAppSelector(state => state.auth.userModel);
  const assets = useAppSelector(state => state.results.resultAssets);
  const boardLoading = useAppSelector(state => state.contentBoard.boardLoading);
  const assetsLoading = useAppSelector(state => state.results.requestResultsLoading);
  const board = useAppSelector(state => state.contentBoard.openedBoard);
  const currentOrganizationID = useAppSelector(state => state.auth.currentOrganization?.organization_id);

  const [isOpenResultsModal, setIsOpenResultsModal] = useState<boolean>(false);
  const [uploadFiles, setUploadFiles] = useState<File[]>([]);
  const closeResultsModal = useCallback(() => {
    setIsOpenResultsModal(false);
    setUploadFiles([]);
  }, []);
  const openUploadModal = useCallback((files: File[]) => {
    setUploadFiles(files);
    setIsOpenResultsModal(true);
  }, []);

  useBoardSetup();

  useEffect(function getInitialResultsData() {
    if (!currentOrganizationID || !params.boardId || !userModel) return;
    dispatch(getResultAssets(params.boardId));
  }, [params.boardId, userModel, currentOrganizationID]);

  useEffect(() =>
    function clearState() {
      dispatch(clearResultsState());
    }, []);

  const loading = boardLoading || assetsLoading

  const getContent = (): ReactNode => {
    if (loading) return <LoadingState/>;
    if (!assets.length) return <EmptyState onUpload={openUploadModal}/>
    return <ResultsList assets={assets}/>;
  }

  return (
    <>
      <MainLayout
        title={board?.name ? `${board?.name}  Results` : ''}
        backButtonAction={() => history.push(`/content-board/${params.boardId}`)}
      >
        <div className={styles.root}>
          <TopBar onUpload={openUploadModal} loading={loading}/>
          {getContent()}
        </div>
      </MainLayout>
      <UploadResultsModal files={uploadFiles} onClose={closeResultsModal} isOpen={isOpenResultsModal}/>
    </>
  );
};

export default Results;
