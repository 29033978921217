import React, { memo, useEffect, useMemo, useState } from 'react';
import styles from './LeftPartSignUp.module.scss';
import { ReactComponent as Quote } from '../../../assets/img/icons/quote-icon.svg';
import cs from 'classnames';
import dayjs from 'dayjs';
import CirclesLoader from '../../CirclesLoader';
import { allMessages } from '../../../shared/constants/constants';
import screenCompaign from '../../../assets/img/icons/screen_compaign.png';
import screenChat from '../../../assets/img/icons/chat.png';
import BackTriangle from '../../../assets/img/icons/triange_background.png';
import { ReactComponent as StarSvg } from '../../../assets/img/icons/star-icon.svg';

const LeftPartSignUp = () => {
  const [currentHuman, setCurrentHuman] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!!allMessages.length) {
        setIsLoading(false);
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (currentHuman === allMessages.length - 1) setCurrentHuman(0);
      else setCurrentHuman(currentHuman + 1);
    }, 10000);
    return () => clearTimeout(timeout);
  }, [currentHuman]);

  const starsCount = useMemo(() => {
    const rate = Math.floor(allMessages[currentHuman].currentRate / allMessages[currentHuman].rateOf / 0.2);
    return rate === 0 ? 1 : rate;
  }, [currentHuman]);

  return (
    <div className={styles.root}>
      <div className={styles.background}>
        <img src={BackTriangle} alt="background" />
      </div>
      <img className={styles.compaign} src={screenCompaign} alt="no screen" />
      <img className={styles.chat} src={screenChat} alt="no screen" />
      <div className={styles.containerWrapper}>
        <div className={styles.container}>
          <Quote />
          {isLoading ? (
            <div className={styles.loading}>
              <CirclesLoader color="white" />
            </div>
          ) : (
            <div className={styles.content}>
              <div className={styles.title}>The people have spoken...</div>
              {allMessages.map((item, idx) => {
                if (idx === currentHuman)
                  return (
                    <div className={styles.text} key={idx}>
                      <span>
                        {Array(starsCount)
                          .fill(StarSvg)
                          .map((Star, idx) => (
                            <Star key={idx} />
                          ))}
                        <span>
                          {allMessages[currentHuman].currentRate} / {allMessages[currentHuman].rateOf}
                        </span>
                      </span>
                      {allMessages[currentHuman].message}
                    </div>
                  );
              })}
              <div className={styles.people}>
                {allMessages.map((item, idx) => {
                  if (idx === currentHuman)
                    return (
                      <div className={styles.peopleInfo} key={idx}>
                        <div className={styles.name}>{item.name}</div>
                        <div className={styles.company}>{item.company}</div>
                      </div>
                    );
                })}
                <div className={styles.dots}>
                  {allMessages.map((_, idx) => (
                    <div key={idx} className={cs(styles.point, { [styles.active]: currentHuman === idx })} />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={styles.copyright}>© {dayjs().format('YYYY')} Otterfish Limited</div>
    </div>
  );
};

export default memo(LeftPartSignUp);
