import React, { FC } from 'react';
import styles from './ContentBoard.module.scss';
import ContentHeader from './components/ContentHeader';
import DeliverablesList from './components/DeliverablesList';
import useContentBoardSockets from './useContentBoardSockets';
import BoardInfoSection from './components/BoardInfoSection';
import BoardLoaderModal from './components/BoardLoaderModal';
import useBoardSetup from '../hooks/useBoardSetup';
import GuestUserPurpleBanner from '../components/GuestUserPurpleBanner';
import GuestUserInfoBanner from './components/GuestUserInfoBanner';

const ContentBoard: FC = () => {
  useBoardSetup({
    withUpcomingLiveDate: true,
    withInvitedCollaborators: true,
  });

  useContentBoardSockets();

  return (
    <>
      <div className={styles.rootWrapper}>
        <div className={styles.root}>
          <div className={styles.content}>
            <GuestUserPurpleBanner />
            {/* <GuestInfoBanner />*/}
            <GuestUserInfoBanner />
            <ContentHeader />
            <BoardInfoSection />
            <DeliverablesList />
          </div>
        </div>
      </div>
      <BoardLoaderModal />
    </>
  );
};

export default ContentBoard;
