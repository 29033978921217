import React, { FC } from 'react';

type HashtagProps = {
  className?: string;
};

const HashtagSVG: FC<HashtagProps> = ({ className }) => {
  return (
    <svg className={className} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.33398 8.99988H24.0006" stroke="#46CFB0" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.5006 2L14.834 24" stroke="#46CFB0" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.1667 2L7.5 24" stroke="#46CFB0" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2 17H22.6666" stroke="#46CFB0" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default HashtagSVG;
