import React, { FC, useEffect, useState } from 'react';
import styles from './GuestUserInfoBanner.module.scss';
import { ReactComponent as InfoIcon } from '../../../../../assets/img/icons/information-icon.svg';
import { useAppSelector } from '../../../../../shared/hooks/reduxHooks';
import { UserPermission } from '../../../../../models/permissions/enum/UserPermission';
import { User } from '../../../../../models/permissions/users/User';
import LoadingContainer from '../../../../../components/LoadingContainer';

// remove every time when app will mount
localStorage.removeItem('GUEST_BOARD_INFO_BANNER_SHOULD_OPEN');

const GuestUserInfoBanner: FC = () => {
  const userModel = useAppSelector<User | null>(state => state.auth.userModel);
  const [isInfoBanner, setIsInfoBanner] = useState<boolean>(false);
  const contentBoard = useAppSelector(state => state.contentBoard.openedBoard);

  useEffect(() => {
    const lsValue = localStorage.getItem('GUEST_BOARD_INFO_BANNER_SHOULD_OPEN');
    const isBannerShouldOpen = lsValue ? JSON.parse(lsValue) : true;
    if (userModel?.hasAccess(UserPermission.CAN_SEE_BOARD_INFO_BANNER) && isBannerShouldOpen) {
      setIsInfoBanner(true);
    }
  }, [userModel]);

  return isInfoBanner && userModel?.hasAccess(UserPermission.CAN_SEE_BOARD_INFO_BANNER) ? (
    <div className={styles.root}>
      <div className={styles.titleWrapper}>
        {contentBoard?.name ? <h3 className={styles.title}>{contentBoard?.name}</h3> : <LoadingContainer width={300} height={22} />}
        <p className={styles.subCopy}>Content board</p>
      </div>
      <div className={styles.messageWrapper}>
        <InfoIcon className={styles.infoIcon} />
        <p className={styles.infoMessage}>
          This content board is a view only content board. You do not have edit permissions. If you need edit permissions, you need to be
          added as a collaborator on the board.{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://help.otterfish.com/knowledge/how-to-invite-a-user-as-a-collaborator-in-otterfish"
            className={styles.link}
          >
            Click here
          </a>{' '}
          to know more.
        </p>
      </div>
    </div>
  ) : null;
};

export default GuestUserInfoBanner;
