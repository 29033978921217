import React, { ComponentProps, FC } from 'react';
import styles from './ConfirmPopup.module.scss';
import ButtonLightPurpleWhite from '../Buttons/ButtonLightPurpleWhite';
import GreenRedButton from '../Buttons/GreenRedButton';
import { useMediaQuery } from 'react-responsive/src';

type WrapperPropsType = {
  children: JSX.Element;
  withoutWrapper: boolean;
};

type ConfirmPopupPropsType = {
  withoutWrapper?: boolean;
  text?: string;
  buttonsConfig: {
    primaryButton: {
      text?: string;
      color?: ComponentProps<typeof GreenRedButton>['color'];
      onClick: ComponentProps<typeof GreenRedButton>['onClick'];
    };
    secondaryButton: {
      text?: string;
      onClick: ComponentProps<typeof ButtonLightPurpleWhite>['onClick'];
    };
  };
};

const Wrapper: FC<WrapperPropsType> = ({ children, withoutWrapper }) => {
  if (withoutWrapper) return children;
  return <div className={styles.wrapper}>{children}</div>;
};

const ConfirmPopup = React.forwardRef<HTMLDivElement | null, ConfirmPopupPropsType>(
  ({ withoutWrapper = false, text, buttonsConfig }, ref) => {
    const { primaryButton, secondaryButton } = buttonsConfig;
    const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

    return (
      <Wrapper withoutWrapper={withoutWrapper}>
        <div className={styles.root} ref={ref}>
          <div className={styles.text}>{text}</div>
          <div className={styles.buttons}>
            {secondaryButton && (
              <ButtonLightPurpleWhite
                className={styles.button}
                designVersion="v2"
                size={isTablet ? 'large' : 'small'}
                onClick={secondaryButton.onClick}
              >
                {secondaryButton.text}
              </ButtonLightPurpleWhite>
            )}
            {primaryButton && (
              <GreenRedButton
                className={styles.button}
                color={primaryButton.color || 'red'}
                size={isTablet ? 'large' : 'small'}
                onClick={primaryButton.onClick}
              >
                {primaryButton.text}
              </GreenRedButton>
            )}
          </div>
        </div>
      </Wrapper>
    );
  }
);

export default ConfirmPopup;
