import React, { FC } from 'react';
import { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import styles from './AssetDnDHandler.module.scss';
import useTooltip, { TOOLTIP_POSITIONS } from '../../../../shared/hooks/fixedTooltip/useTooltip';

type PropsType = {
  attributes: DraggableAttributes;
  setNodeRef: (node: HTMLElement | null) => void;
  listeners?: SyntheticListenerMap;
};

const AssetDnDHandler: FC<PropsType> = ({ attributes, setNodeRef, listeners }) => {
  const { toolTip, onLeave, onEnter } = useTooltip(TOOLTIP_POSITIONS.BOTTOM, {
    text: 'Drag to change the order',
    className: styles.tooltip,
  });

  return (
    <div {...listeners} {...attributes} className={styles.root}>
      <div className={styles.dotsContainer} ref={setNodeRef} onMouseEnter={onEnter} onMouseLeave={onLeave}>
        {toolTip}
        <div className={styles.dragHandlerDot} />
        <div className={styles.dragHandlerDot} />
        <div className={styles.dragHandlerDot} />
        <div className={styles.dragHandlerDot} />
        <div className={styles.dragHandlerDot} />
        <div className={styles.dragHandlerDot} />
      </div>
    </div>
  );
};

export default AssetDnDHandler;
