import { ChangeEventHandler } from 'react';

export interface IFileData {
  file: File;
  url: string | ArrayBuffer | null;
}

export class FileData implements IFileData {
  file: File;
  url: string | ArrayBuffer | null;

  constructor(file: File, url: string | ArrayBuffer | null) {
    this.url = url;
    this.file = file;
  }
}

type UseFileUploadType = (settings: {
  handleCreate: (fileData: IFileData) => void;
  handleProcessing?: (this: FileReader, ev: ProgressEvent<FileReader>) => void;
  handleLoadingStart?: (this: FileReader, ev: ProgressEvent<FileReader>) => void;
  allowedLength?: number;
}) => ChangeEventHandler<HTMLInputElement>;

const useFileUpload: UseFileUploadType = settings => {
  const addFile = (file: File) =>
    new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadstart = settings.handleLoadingStart ?? null;
      reader.onprogress = settings.handleProcessing ?? null;
      reader.onloadend = () => {
        const fileData = new FileData(file, reader.result);
        resolve(fileData);
      };
    }).then(res => {
      if (res instanceof FileData) {
        settings.handleCreate(res);
      }
    });

  const handleAddFile: ChangeEventHandler<HTMLInputElement> = event => {
    const files = event.target.files;
    if (!files?.length) return;

    const allowedLength = settings.allowedLength || 1;

    for (let i = 0; i < files.length && i < allowedLength; i++) {
      addFile(files[i]).catch(e => {
        if (e instanceof Error) {
          console.error(e);
        }
      });
    }
  };

  return handleAddFile;
};

export default useFileUpload;
