import { COLLABORATOR_ROLE_TYPES } from '../../../../../../models/permissions/collaborator/CollaboratorRole';

export const EMPTY_CONCEPT_STATE_TEXT_CONFIG = {
  [COLLABORATOR_ROLE_TYPES.BRAND]:
    "You have nothing to currently review. If the content creator needs to share a concept idea you'll be able to view, leave feedback and approve here. Sit tight! We'll notify you if a concept idea has been shared.",
  [COLLABORATOR_ROLE_TYPES.AGENCY]:
    "You have nothing to currently review. If the content creator needs to share a concept idea you'll be able to view, leave feedback and approve here. Sit tight! We'll notify you if a concept idea has been shared.",
  [COLLABORATOR_ROLE_TYPES.TALENT_MANAGER]:
    "You have nothing to currently review or share. You can review your creators concept or even write on their behalf and share with agency or brand you are working with. Sit tight! We'll notify you if a concept idea has been shared by your creator",
  [COLLABORATOR_ROLE_TYPES.CONTENT_CREATOR]:
    'If you need to share and have a concept idea approved before shooting add this here. Once shared the collaborators will be able to review, leave feedback and approve your idea.',
};

export const EMPTY_CONTENT_STATE_TEXT_CONFIG = {
  [COLLABORATOR_ROLE_TYPES.BRAND]:
    "You have nothing to currently review. When the content creator uploads their content you'll be able to view, leave feedback and approve here. Sit tight! We'll notify you when the content has been shared.",
  [COLLABORATOR_ROLE_TYPES.AGENCY]:
    "You have nothing to currently review. When the content creator uploads their content you'll be able to view, leave feedback and approve here. Sit tight! We'll notify you when the content has been shared.",
  [COLLABORATOR_ROLE_TYPES.TALENT_MANAGER]:
    "You have nothing to currently review or share. You can review your creators content or even upload on their behalf and share with agency or brand you are working with. Sit tight! We'll notify you when the content has been shared by your creator",
  [COLLABORATOR_ROLE_TYPES.CONTENT_CREATOR]:
    'Upload and share your content with your collaborators to have them review, leave feedback and finally approve.',
};
