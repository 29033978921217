import React, { FC } from 'react';

type PropsType = {
  className?: string;
};

const SuitcaseSVG: FC<PropsType> = props => {
  return (
    <svg className={props.className} xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
      <g clipPath="url(#clip0_399_10148)">
        <path
          d="M0 7.07736V5.48727C0.0132279 5.47111 0.0237011 5.45288 0.0309981 5.43331C0.281853 4.22783 1.10732 3.55965 2.33863 3.55908H6.93093V3.30363C6.93093 2.78068 6.93093 2.25773 6.93093 1.73536C6.93782 0.70898 7.637 0.00520922 8.65993 0.00291307C10.2209 -0.00129654 11.7821 -0.00129654 13.3435 0.00291307C14.3659 0.00291307 15.0656 0.708406 15.0719 1.73536C15.0754 2.2652 15.0719 2.79503 15.0719 3.32487C15.0719 3.40007 15.0783 3.47527 15.0823 3.55908H15.3653C16.8331 3.55908 18.3015 3.55563 19.7693 3.55908C20.8393 3.5631 21.7784 4.25826 21.9259 5.28693C22.0283 5.98795 22.0256 6.70032 21.9179 7.40054C21.6808 8.8827 20.7578 9.8247 19.3307 10.2495C16.9869 10.9481 14.638 11.6209 12.2907 12.3097C11.5255 12.5342 10.758 12.6002 9.98481 12.3769C7.51243 11.6622 5.03776 10.9567 2.57341 10.2179C1.27379 9.82814 0.449472 8.93896 0.105049 7.62154C0.0574039 7.44187 0.0350163 7.25818 0 7.07736ZM13.6678 3.55161C13.6678 2.92878 13.6678 2.32834 13.6678 1.72847C13.6678 1.47704 13.5892 1.40299 13.328 1.40242H8.66797C8.40908 1.40242 8.33273 1.47819 8.33158 1.73134C8.33158 2.28969 8.33158 2.84804 8.33158 3.40638C8.33464 3.4551 8.34058 3.5036 8.34938 3.55161H13.6678Z"
          fill="#46CFB0"
        />
        <path
          d="M-0.000231082 10.2568C0.637525 10.8745 1.36942 11.3337 2.22589 11.5811C3.04963 11.8205 3.8728 12.0622 4.69425 12.3004C6.27974 12.7596 7.86868 13.2085 9.4513 13.6781C10.4989 13.9892 11.5339 13.9749 12.5792 13.6678C15.0206 12.9508 17.4672 12.2522 19.908 11.5341C20.6385 11.3154 21.3045 10.9212 21.8476 10.386C21.8821 10.3527 21.92 10.3229 21.9814 10.2712C21.9871 10.3596 21.994 10.4193 21.994 10.479C21.994 12.6056 21.994 14.7322 21.994 16.8588C21.994 18.1889 21.0515 19.1366 19.7231 19.1378C13.9085 19.1408 8.09428 19.1408 2.28042 19.1378C1.11168 19.1378 0.251771 18.4254 0.0342111 17.294C0.0253301 17.2677 0.0131803 17.2426 -0.00195312 17.2193L-0.000231082 10.2568Z"
          fill="#46CFB0"
        />
      </g>
      <defs>
        <clipPath id="clip0_399_10148">
          <rect width="22" height="19.1378" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SuitcaseSVG;
