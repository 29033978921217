import React, { useContext, useEffect, useMemo } from 'react';
import { addDiscussionRoomComment, addDiscussionRoomCommentAttachment, addDiscussionRoom } from '../../../actions/discussion.actions';
import { useDispatch, useSelector } from 'react-redux';
import useSubscribeChannel from '../../../centrifuge/useSubscribeChannel';
import { OrganizationSocketsContext } from '../../../contexts/organizationContext';

const SUBSCRIBE_ACTIONS = {
  CREATE_COMMENT: 'create_comment',
  CREATE_COMMENT_ATTACHMENT: 'create_comment_attachment',
};

const useDiscussionsSockets = (roomIndex, params) => {
  const dispatch = useDispatch();
  const currentOrganizationID = useSelector(state => state.auth.currentOrganization?.organization_id);
  const discussionRooms = useSelector(state => state.discussion.discussionRooms);
  const JWT = useSelector(state => state.auth?.token?.access_token);
  const context = useContext(OrganizationSocketsContext);

  const currentRoomId = useMemo(() => {
    return discussionRooms[roomIndex]?.id;
  }, [discussionRooms, roomIndex]);

  useEffect(() => {
    if (context) {
      context.createRoomHandler = ctx => {
        const room = ctx.data.data.discussionRoom;
        if (+params.object_id === +room.object_id && params.object_type === room.object_type) {
          dispatch(addDiscussionRoom(currentOrganizationID, { ...room }));
        }
      };
    }
  }, [params.object_id, params.object_type, currentOrganizationID]);

  useSubscribeChannel(currentRoomId, {
    channel: `discussion_room:${currentRoomId}`,
    events: ctx => {
      switch (ctx.data.action) {
        case SUBSCRIBE_ACTIONS.CREATE_COMMENT:
          const comment = ctx.data.data[0];
          comment.attachments = [];
          dispatch(addDiscussionRoomComment(comment));
          break;
        case SUBSCRIBE_ACTIONS.CREATE_COMMENT_ATTACHMENT:
          const attachment = ctx.data.data[0];
          dispatch(addDiscussionRoomCommentAttachment(attachment));
          break;
      }
    },
    opts: { data: { organization_id: currentOrganizationID, discussion_room_id: currentRoomId, token: JWT } },
    // onError: err => console.error('Centrifuge subscribe error: ', err),
    // onSubscribe: ctx => console.log('+ subscribe room', ctx.channel),
    // onUnsubscribe: ctx => console.log('- unsubscribe room', ctx.channel),
  });
};

export default useDiscussionsSockets;
