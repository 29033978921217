import React, { ComponentProps, FC, ReactElement } from 'react';
import styles from './SectionEmptyState.module.scss';
import { DeliverablePermission } from '../../../../../../../models/permissions/enum/DeliverablePermission';
import { useAppSelector } from '../../../../../../../shared/hooks/reduxHooks';
import { DeliverablesReducerState } from '../../../../../../../reducers/deliverables.reducer';
import ButtonWithPurpleRoundedIcon from '../../../../../../../components/Buttons/ButtonWithPurpleRoundedIcon';

type PropsType = {
  text?: string;
  buttonText?: string;
  iconPreset?: ComponentProps<typeof ButtonWithPurpleRoundedIcon>['presetIcon'];
  onAddConcept: () => void;
  additionalButtons?: ReactElement;
};

const SectionEmptyState: FC<PropsType> = ({ text, buttonText, onAddConcept, additionalButtons }) => {
  const { pageModel } = useAppSelector<DeliverablesReducerState>(state => state.deliverables);

  return (
    <>
      <div className={styles.text}>{text}</div>
      <div className={styles.buttonsContainer}>
        {pageModel?.roleCan(DeliverablePermission.DISPLAY_ADD_SECTION_BUTTON) && (
          <ButtonWithPurpleRoundedIcon className={styles.button} presetIcon={'plus'} size={'medium'} onClick={onAddConcept}>
            {buttonText}
          </ButtonWithPurpleRoundedIcon>
        )}
        {additionalButtons}
      </div>
    </>
  );
};

export default SectionEmptyState;
