/* eslint-disable camelcase */
import { instance } from '../shared/utils/api.config.creator';

const STRIPE_ROUTE = '/stripe';

export const createCheckoutSession = async (organization_id, success_route, cancel_route) => {
  const response = await instance.post(`${STRIPE_ROUTE}/checkout`, {
    organization_id,
    success_route,
    cancel_route,
  });
  return response.data;
};

export const createCustomerPortal = async (organization_id, return_route) => {
  const response = await instance.post(`${STRIPE_ROUTE}/customer-portal`, { organization_id, return_route });
  return response.data;
};
