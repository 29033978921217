import { useCallback, useState } from 'react';
import { useAppSelector } from '../../../../shared/hooks/reduxHooks';

type UseWatchDemoHandlersType = () => {
  isOpenWatchDemoModal: boolean;
  openWatchDemoModal: () => void;
  closeWatchDemoModal: () => void;
  onPlayAction: () => void;
  onPauseAction: () => void;
};

const useWatchDemoHandlers: UseWatchDemoHandlersType = () => {
  const [isOpenWatchDemoModal, setIsOpenWatchDemoModal] = useState<boolean>(false);
  const userId = useAppSelector<string | number | null>(state => state.auth.user?.id || null);

  const openWatchDemoModal = useCallback(() => {
    setIsOpenWatchDemoModal(true);
  }, []);

  const closeWatchDemoModal = useCallback(() => {
    setIsOpenWatchDemoModal(false);
  }, []);

  const onPlayAction = useCallback(() => {
    if (!userId) return;
    window.analytics.track('Play', {
      category: 'Videos',
      label: 'Campaign Demo Video',
      userId: userId,
    });
  }, [userId]);

  const onPauseAction = useCallback(() => {
    if (!userId) return;
    window.analytics.track('Pause', {
      category: 'Videos',
      label: 'Campaign Demo Video',
      userId: userId,
    });
  }, [userId]);

  return {
    isOpenWatchDemoModal,
    openWatchDemoModal,
    closeWatchDemoModal,
    onPlayAction,
    onPauseAction,
  };
};

export default useWatchDemoHandlers;
