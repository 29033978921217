import React, { ReactElement, useCallback, useRef, useState } from 'react';
import styles from './BriefSubHeader.module.scss';
import useFormikErrorsArray from '../../../../hooks/useFormikErrorsArray';
import ErrorsInformationTooltip from '../../../CreateBoard/components/ErrorsInformationTooltip';
import BorderedButton from '../../../../../../../components/Buttons/WhiteBorderedButton';
import { FormikErrors } from 'formik';
import { IBoardBriefFormikValues } from '../../../../../../../models/entities/boardBrief/BoardBriefFormikValues';

const errorsTextSettings = {
  itemName: 'Brief name',
  brandName: 'Brand name',
  brandLogo: 'Brand logo',
  date_end: 'Campaign duration end date',
  boosting_budget: 'Boosting budget',
  brandProvidesProduct: 'Brand provides product',
  repostingPlatform: 'Reposting platform',
  boosting_duration: 'Boosting duration',
  exclusivity: 'Exclusivity',
  // campaign
  initiator_organization_type: 'Initiator organization type',
  campaignName: 'Campaign name',
  deliverables: 'Deliverables',
};

type PropsType = {
  buttonTitle?: string;
  canSubmit?: boolean;
  errors: FormikErrors<IBoardBriefFormikValues> | null;
  title?: string | ReactElement;
  description?: string;
  onCancel?: (...args: any) => any;
  onSubmit: (...args: any) => any;
};

function BriefSubHeader({ buttonTitle, canSubmit, errors, title, description, onCancel, onSubmit }: PropsType) {
  const [isErrorTooltipOpen, setIsErrorTooltipOpen] = useState<boolean>(false);
  const onCloseErrorTooltip = useCallback(() => {
    setIsErrorTooltipOpen(false);
  }, []);

  const [isTriedToSubmit, setIsTriedToSubmit] = useState<boolean>(false);
  const ignoreRef = useRef(null);

  const formikErrorsArray = useFormikErrorsArray(errors, errorsTextSettings);

  const handleSubmit = useCallback(() => {
    setIsErrorTooltipOpen(true);
    if (!isTriedToSubmit) setIsTriedToSubmit(true);

    if (!canSubmit || !onSubmit) return;

    onSubmit();
  }, [isTriedToSubmit, onSubmit, canSubmit]);

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.title}>{title || 'Create a brief'}</div>
        <div className={styles.text}>
          {description ||
            'Fill out the template below and share with brands or agencies to collaborate and discuss on your campaign requirements. You can\n' +
              '          share a blank brief if required.'}
        </div>
      </div>
      <div className={styles.navigationButtons}>
        {onCancel && (
          <BorderedButton type="button" designVersion="transparent" size="large" onClick={onCancel}>
            Cancel
          </BorderedButton>
        )}
        <div className={styles.submitButtonContainer}>
          <BorderedButton
            // withDisabledClass={!!formikErrorsArray?.length}
            designVersion="white"
            type={!!onSubmit || !canSubmit ? 'button' : 'submit'}
            ref={ignoreRef}
            className={styles.submitButton}
            onClick={handleSubmit}
            size="large"
          >
            {buttonTitle}
          </BorderedButton>
          {formikErrorsArray?.length && isTriedToSubmit && isErrorTooltipOpen ? (
            <ErrorsInformationTooltip
              message="Before you add the brief, please fill in required fields"
              isOpen={isErrorTooltipOpen}
              onClose={onCloseErrorTooltip}
              ref={ignoreRef}
              className={styles.errorTooltip}
              errors={formikErrorsArray}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default BriefSubHeader;
