import React, { FC, ReactElement } from 'react';
import cs from 'classnames';
import styles from './FacebookPreview.module.scss';
import { DoneIcon, EarthIcon } from '../../../expert/components/IconsSvg/ReactIcons/ReactIcons';
import SquareAsset from '../../SquareAsset';
import { ReactComponent as EmptyImage } from '../../../assets/img/icons/not_uploaded_icon.svg';
import { ReactComponent as AvatarPlaceholder } from '../../../assets/img/icons/organization-placeholders/9.svg';
import FbImageLibrary from 'react-fb-image-grid';
import PostCarousel from '../InstagramPreview/PostCarousel';
import { IAssetMedia } from '../../../models/entities/asset/AssetMedia';
import { MEDIA_ORIENTATIONS } from '../../../models/enums/ImageOrientations';

type FacebookPreviewProps = {
  testDetailsPreview?: boolean;
  page?: object & { image: string; name: string };
  primaryText?: string | ReactElement;
  headline?: string;
  preview: IAssetMedia['url'][] | IAssetMedia['url'] | IAssetMedia[];
  title?: string;
  onClick?: (...unknown) => void;
  className?: string;
  selected?: boolean;
  selectedClassName?: string;
  size?: 'sm' | 'md';
  callToAction: string | null;
  isVideoPreview?: boolean;
  orientation?: MEDIA_ORIENTATIONS;
};

const FacebookPreview: FC<FacebookPreviewProps> = ({
  testDetailsPreview,
  page,
  primaryText,
  headline,
  preview,
  title,
  onClick,
  className,
  selected,
  selectedClassName,
  size,
  callToAction,
  isVideoPreview = false,
  orientation,
}) => {
  const getPreview = () => {
    if (!preview || !preview?.length) {
      return (
        <div className={styles.emptyImage}>
          <EmptyImage />
          <span>No {isVideoPreview ? 'video' : 'image'} yet</span>
        </div>
      );
    }
    if (Array.isArray(preview) && isVideoPreview) {
      return <PostCarousel assets={preview} orientation={orientation} />;
    }

    if (Array.isArray(preview)) {
      return <FbImageLibrary images={preview} hideOverlay />;
    }
    if (isVideoPreview) {
      return <SquareAsset src={preview} type={'video'} controls />;
    }

    return <img alt={title} src={preview} />;
  };

  return (
    <div
      className={cs(styles.root, {
        [className || '']: className,
        [styles.selected]: selected,
        [selectedClassName || '']: selected,
        [styles[size || '']]: size,
      })}
    >
      <div className={cs(styles.post, { [styles.testDetailsPreview]: testDetailsPreview })}>
        <div className={styles.header}>
          <div className={styles.profile}>
            <div className={styles.logo} style={{ backgroundImage: `url(${page?.image})` }}>
              {!page?.image && <AvatarPlaceholder />}
            </div>
            <div className={styles.credentials}>
              <div className={styles.name}>{page?.name || 'Organization name'}</div>
              <div className={styles.secondary}>
                Sponsored &nbsp;
                <EarthIcon />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.text}>{primaryText || ''}</div>
        <div className={styles.preview}>{getPreview()}</div>
        <div className={styles.link}>
          <div className={styles.headline}>{headline || ''}</div>
          {callToAction && <div className={styles.action}>{callToAction}</div>}
        </div>
      </div>
      <div className={styles.title}>{title || ''}</div>
      <div className={styles.tick}>
        <DoneIcon />
      </div>
    </div>
  );
};

export default FacebookPreview;
