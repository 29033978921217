import React, { ComponentProps, FC, MouseEventHandler } from 'react';
import styles from './CardImageButton.module.scss';
import ButtonCustom from '../../../../../../../../../expert/components/Buttons/ButtonCustom';
import cs from 'classnames';

type PropsType = {
  imageSrc: string;
  title: string;
  buttonText: string;
  ButtonIcon?: ComponentProps<typeof ButtonCustom>['Icon'];
  className?: string;
  buttonClassName?: string;
  onButtonClick: MouseEventHandler<HTMLButtonElement>;
};

const CardImageButton: FC<PropsType> = props => {
  return (
    <div className={cs(styles.root, props.className)}>
      <img className={styles.image} src={props.imageSrc} alt={'banner'} />
      <h5 className={styles.title}>{props.title}</h5>
      <ButtonCustom className={cs(styles.button, props.buttonClassName)} Icon={props.ButtonIcon} onClick={props.onButtonClick}>
        {props.buttonText}
      </ButtonCustom>
    </div>
  );
};

export default CardImageButton;
