import lodash from 'lodash';
import { INotification } from '../../models/Notifications';
import { IStateToastNotification, TOAST_NOTIFICATION_ACTION_TYPES, ToastTonitificationThunkReturnType } from './types';

export const createNotification =
  (data: INotification): ToastTonitificationThunkReturnType<IStateToastNotification> =>
  async dispatch => {
    const stateToastNotification: IStateToastNotification = { ...data, id: lodash.uniqueId('toast_notification_id_') };

    dispatch({ type: TOAST_NOTIFICATION_ACTION_TYPES.CREATE_NOTIFICATIONS, payload: stateToastNotification });
    if (stateToastNotification.delay) {
      setTimeout(() => {
        dispatch(deleteNotification(stateToastNotification));
      }, stateToastNotification.delay);
    }
    return stateToastNotification;
  };

export const deleteNotification =
  (data: IStateToastNotification): ToastTonitificationThunkReturnType =>
  async dispatch => {
    dispatch({ type: TOAST_NOTIFICATION_ACTION_TYPES.DELETE_NOTIFICATIONS, payload: data });
  };
