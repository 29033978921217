import React from 'react';
import styles from './MessageBody.module.scss';
import TextWithTagsHighlight from '../../../../components/TextWithTagsHighlight';
import CollaboratorsAvatar from '../../../../components/CollaboratorsAvatar';
import AttachmentItem from './AttachmentItem';

const MessageBody = ({ authorImg, author, date, text, attachments }) => {
  return (
    <div className={styles.root}>
      <CollaboratorsAvatar size={36} imageUrl={authorImg} name={author} />
      <div className={styles.messageBody}>
        <div className={styles.messageHeader}>
          <div className={styles.messageAuthor}>{author}</div>
          {date && <span className={styles.messageData}>{date}</span>}
        </div>
        {text && <TextWithTagsHighlight text={text} className={styles.messageText} withoutHighlight />}
        {attachments && attachments.map((item, index) => <AttachmentItem key={index} attachment={item} />)}
      </div>
    </div>
  );
};

export default MessageBody;
