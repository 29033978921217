import React, { FC } from 'react';
import styles from './LeftPartSignIn.module.scss';
import LogoSVG from '../../IconsSvg/LogoSVG';
import SignInImage from '../../../assets/img/signIn.png';
import SignInMobile3x from '../../../assets/img/signInMobile_3x.png';
import SignInMobile1x from '../../../assets/img/signInMobile_1x.png';
import SignInMobile2x from '../../../assets/img/signInMobile_2x.png';

import { useMediaQuery } from 'react-responsive/src';

const LeftPartSignIn: FC = () => {
  const isPortrait: boolean = useMediaQuery({ query: '(orientation: portrait)' });

  return (
    <div className={styles.leftPart}>
      <div className={styles.header}>
        {/* @ts-ignore*/}
        <LogoSVG />
      </div>
      <div className={styles.imageWrapper}>
        {isPortrait ? (
          <picture className={styles.signInPicture}>
            <source srcSet={`${SignInMobile1x}, ${SignInMobile2x} 2x, ${SignInMobile3x} 3x`} />
            <img className={styles.signInImage} src={SignInMobile1x} alt="preview" />
          </picture>
        ) : (
          <img className={styles.signInImage} src={SignInImage} alt="chat screen" />
        )}
      </div>
      {!isPortrait ? (
        <div className={styles.footer}>
          <div className={styles.title}>Create, collaborate and approve.</div>
        </div>
      ) : null}
    </div>
  );
};

export default LeftPartSignIn;
