import { instance } from '../shared/utils/api.config.creator';

export const getNotificationsList = async (params = {}) => {
  const response = await instance.get(`/notifications`, { params });
  return response.data;
};

export const markRead = async data => {
  const response = await instance.post(`/notifications/mark-read`, data);
  return response.data;
};
