import React, { FC, useEffect } from 'react';
import styles from './DownloadAllButton.module.scss';
import useDownloadAsset, { DOWNLOAD_RESULT_STATUSES } from '../../../../shared/hooks/useDownloadAsset';
import SmallLoader from '../../../../components/SmallLoader';
import cs from 'classnames';
import ButtonLightPurpleWhite from '../../../../components/Buttons/ButtonLightPurpleWhite';
import useTooltip, { TOOLTIP_POSITIONS } from '../../../../shared/hooks/fixedTooltip/useTooltip';
import { AssetStateType } from '../../../../models/entities/asset/AssetState';
import { IBoardAsset } from '../../../../models/entities/board/BoardAsset';

export type PropsType = {
  className?: string;
  assets: AssetStateType[] | IBoardAsset[];
  isDisplay?: boolean;
  onResult?: { success?: () => void; partiallyFailed?: () => void; allFailed?: () => void };
  size?: 'large' | 'medium' | 'small';
  designVersion?: 'v1' | 'v2' | 'v3';
};
const DownloadAllButton: FC<PropsType> = ({ className, assets, isDisplay = true, onResult = {}, size, designVersion }) => {
  const { isAssetsArrayLoading, downloadAssetsArray, resultStatus } = useDownloadAsset();
  const { toolTip, onEnter, onLeave } = useTooltip(TOOLTIP_POSITIONS.BOTTOM, { text: 'download all assets' });

  useEffect(() => {
    if (resultStatus) {
      if (resultStatus === DOWNLOAD_RESULT_STATUSES.SUCCESS && onResult.success) onResult.success();
      if (resultStatus === DOWNLOAD_RESULT_STATUSES.PARTIALLY_FAILED && onResult.partiallyFailed) onResult.partiallyFailed();
      if (resultStatus === DOWNLOAD_RESULT_STATUSES.ALL_FAILED && onResult.allFailed) onResult.allFailed();
    }
  }, [resultStatus]);

  const downloadAssets = () => {
    if (isAssetsArrayLoading) return;
    downloadAssetsArray(
      assets.map(asset => ({
        assetUrl: 'file' in asset ? URL.createObjectURL(asset.file) : asset.location,
        extension: asset.extension,
      }))
    );
  };

  return (
    <>
      {!assets.some(asset => !asset.extension) && isDisplay && (
        <ButtonLightPurpleWhite
          designVersion={designVersion}
          disabled={isAssetsArrayLoading}
          className={cs(styles.root, className)}
          onClick={downloadAssets}
          Icon={isAssetsArrayLoading ? DownloadLoader : null}
          presetIcon={isAssetsArrayLoading ? null : 'download'}
          size={size}
          onMouseEnter={onEnter}
          onMouseLeave={onLeave}
        >
          {toolTip}
          Download all
        </ButtonLightPurpleWhite>
      )}
    </>
  );
};

const DownloadLoader = props => <SmallLoader className={styles.loader} {...props} />;

export default DownloadAllButton;
