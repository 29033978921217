import React, { useEffect } from 'react';
import styles from './UserList.module.scss';
import { TYPES_CAMPAIGNS_OBJECTS } from '../../../../../../shared/constants/constants';
import CheckBox from '../../../../../../pages/CampaignBrief/components/CheckBox';
import { useSelector } from 'react-redux';
import cs from 'classnames';

const UserList = ({ formik, availableOrganizations }) => {
  const user = useSelector(state => state.auth.user);
  const { openedBoard } = useSelector(state => state.contentBoard);

  const typeOrg = type => {
    return TYPES_CAMPAIGNS_OBJECTS.filter(item => item.type === type)[0]?.name;
  };

  const getInitials = (first, last) => {
    return first[0].toUpperCase() + last[0].toUpperCase();
  };

  const isCollaboratorDefaultSelected = collaborator => {
    return user?.id === +collaborator.creator_id;
  };

  const selectCollaborators = item => {
    return isCollaboratorDefaultSelected(item) ? {} : changeCheckBox(item.organization_id, item.type);
  };

  const changeCheckBox = (id, type) => {
    if (formik.values.organizationsIds.includes(id)) {
      formik.setFieldValue(
        'organizationsIds',
        formik.values.organizationsIds.filter(itemId => itemId !== id)
      );
    } else if (type !== 'advertiser') {
      formik.setFieldValue('organizationsIds', [...formik.values.organizationsIds, id]);
    }
  };

  useEffect(() => {
    if (!user || !openedBoard || !availableOrganizations) return;

    const preselectedCollaborators = availableOrganizations.filter(c => isCollaboratorDefaultSelected(c));
    const isFormikFilled = preselectedCollaborators.every(c => formik.values.organizationsIds.includes(c.organization_id));
    if (preselectedCollaborators.length && !isFormikFilled) {
      formik.setFieldValue(
        'organizationsIds',
        preselectedCollaborators.map(c => c.organization_id)
      );
    }
  }, [openedBoard, user, availableOrganizations, formik]);

  return (
    <>
      <div className={styles.titleUserList}>Choose who you want to add:</div>
      <div className={styles.userList}>
        {availableOrganizations.map(item => (
          <div
            key={item.email}
            className={cs(styles.organizationItem, { [styles.disabledOrganizationItem]: isCollaboratorDefaultSelected(item) })}
            onClick={() => selectCollaborators(item)}
          >
            <CheckBox
              className={styles.checkbox}
              isActive={
                isCollaboratorDefaultSelected(item)
                  ? true
                  : formik.values.organizationsIds.includes(item.organization_id, item.organization_type)
              }
            />
            <div className={styles.iconOrg}>{getInitials(item.approver_first_name, item.approver_last_name)}</div>
            <div>
              <div className={styles.organizationName}>
                {item.approver_first_name} {item.approver_last_name}
              </div>
              <div className={styles.organizationType}>
                {typeOrg(item.type)} {openedBoard?.creator_id === item?.creator_id && '/Initiator'}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default UserList;
