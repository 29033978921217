import React, {FC} from 'react';

type PropsType = {
    className?: string;
};

const DangerTriangleSVG: FC<PropsType> = props => {
    return (
        <svg className={props.className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Information icons" clipPath="url(#clip0_446_4940)">
                <path id="coolicon"
                      d="M19.0278 18.5863H0.974114C0.626092 18.5863 0.304511 18.4006 0.130504 18.0992C-0.0435034 17.7978 -0.0435011 17.4264 0.13051 17.125L9.15688 1.53883C9.33103 1.23793 9.65234 1.05264 10 1.05264C10.3477 1.05264 10.669 1.23793 10.8431 1.53883L19.8695 17.125C20.0434 17.4263 20.0435 17.7974 19.8697 18.0988C19.696 18.4001 19.3747 18.5859 19.0269 18.5863H19.0278Z"
                      fill="#D2263B"/>
                <g id="Group 9393">
                    <path id="Rectangle 1493"
                          d="M8.94727 7.36843C8.94727 6.78707 9.41854 6.3158 9.9999 6.3158C10.5812 6.3158 11.0525 6.78708 11.0525 7.36843V12.6316C11.0525 13.2129 10.5812 13.6842 9.9999 13.6842C9.41854 13.6842 8.94727 13.2129 8.94727 12.6316V7.36843Z"
                          fill="white"/>
                    <rect id="Rectangle 1494" x="8.94727" y="14.7368" width="2.10526" height="2.10526" rx="1.05263"
                          fill="white"/>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_446_4940">
                    <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default DangerTriangleSVG;
