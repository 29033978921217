import React, { forwardRef } from 'react';
import { ITextConfig } from '../../types';
import cs from 'classnames';
import styles from './TextSection.module.scss';
import EditorComponent from '../../../../../../../components/EditorComponent';
import TextSectionLoadingState from './LoadingState';

type PropsType = {
  textConfig: ITextConfig[];
  className?: string;
  isLoading: boolean;
};

const TextSection = forwardRef<HTMLUListElement, PropsType>((props, ref) => {
  return (
    <ul ref={ref} className={cs(styles.root, props.className)}>
      {props.isLoading ? (
        <TextSectionLoadingState />
      ) : (
        props.textConfig.map(item => {
          if (!item.text?.trim().length) {
            return null;
          }
          return (
            <li className={styles.sectionWrapper} key={item.id} id={item.id}>
              <h4 className={styles.sectionTitle}>{item.title}</h4>
              {/* @ts-ignore */}
              <EditorComponent
                wrapperClassName={styles.sectionTextWrapper}
                readOnly={true}
                editorClassName={styles.sectionText}
                initialValue={item.text}
              />
            </li>
          );
        })
      )}
    </ul>
  );
});

export default TextSection;
