import React, { FC, memo } from 'react';
import styles from './SubTabOtherTerms.module.scss';
import { FormikProps } from 'formik';
import { areEqualFormikState } from '../../../../../../../../shared/utils/typescriptHelpers';
import SubTabExclusivityTerms from './SubTabExclusivityTerms';
import SubTabRepurposeRights from './SubTabRepurposeRights';
import SubTabAdditional from './SubTabAdditional';

type PropsType = {
  formik: FormikProps<any>;
};

const propsAreEqual: Parameters<typeof memo<FC<PropsType>>>[1] = (prevProps, nextProps) => {
  return areEqualFormikState(
    ['repostingPlatform', 'showSocial', 'showExclusivity', 'exclusivity', 'repostingDuration', 'other_infos'],
    prevProps.formik,
    nextProps.formik
  );
};

const TabOtherTerms = memo(({ formik }) => {
  return (
    <div>
      <div className={styles.root}>
        <h3 className={styles.title}>Other terms</h3>
        <SubTabExclusivityTerms formik={formik} />
        <div className={styles.line} />
        <SubTabRepurposeRights formik={formik} />
        <div className={styles.line} />
        <SubTabAdditional formik={formik} />
      </div>
    </div>
  );
}, propsAreEqual);

export default TabOtherTerms;
