import React, { FC, useMemo, useRef, useState } from 'react';
import styles from './QuestionTooltip.module.scss';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { ORGANIZATION_TYPES } from '../../models/enums/OrganizationTypes';
import { DELIVERABLE_SECTION_TYPES } from '../../models/enums/DeliverableSectionTypes';
import { COLLABORATOR_ROLE_TYPES } from '../../models/permissions/collaborator/CollaboratorRole';
import { useClickOutside } from '../../shared/hooks/useClickOutside';

const TooltipContent = ({ text, link }: { text: string; link: string }) => {
  return (
    <div className={styles.tooltipContent}>
      <div>{text}</div>
      <div>
        To know more <span onClick={() => window.open(link, '_blank')}>click here</span>
      </div>
    </div>
  );
};

const tooltipTextConfig = {
  [DELIVERABLE_SECTION_TYPES.CONCEPT]: {
    [ORGANIZATION_TYPES.AGENCY]: (
      <TooltipContent
        text="You have nothing to currently review. If the content creator needs to share a concept idea you'll be able to view, leave feedback
          and approve here. Sit tight! We'll notify you if a concept idea has been shared."
        link="https://help.otterfish.com/knowledge/how-does-otterfish-work-agency-brand"
      />
    ),
    [ORGANIZATION_TYPES.BRAND]: (
      <TooltipContent
        text="You have nothing to currently review. If the content creator needs to share a concept idea you'll be able to view, leave feedback
          and approve here. Sit tight! We'll notify you if a concept idea has been shared."
        link="https://help.otterfish.com/knowledge/how-does-otterfish-work-agency-brand"
      />
    ),
    [ORGANIZATION_TYPES.TALENT_MANAGER]: (
      <TooltipContent
        text="You have nothing to currently review or share. You can review your creators concept or even write on their behalf and share with agency or brand you are working with. Sit tight! We'll notify you if a concept idea has been shared by your creator."
        link="https://help.otterfish.com/knowledge/how-does-otterfish-work-for-talent-managers"
      />
    ),
    [ORGANIZATION_TYPES.CONTENT_CREATOR]: (
      <TooltipContent
        text="If you need to share and have a concept idea approved before shooting add this here. Once shared the collaborators will be able to review, leave feedback and approve your idea."
        link="https://help.otterfish.com/knowledge/how-does-otterfish-work-for-content-creators"
      />
    ),
  },
  [DELIVERABLE_SECTION_TYPES.CONTENT]: {
    [ORGANIZATION_TYPES.AGENCY]: (
      <TooltipContent
        text="You have nothing to currently review. When the content creator uploads their content you'll be able to view, leave feedback and
          approve here. Sit tight! We'll notify you when the content has been shared."
        link="https://help.otterfish.com/knowledge/how-does-otterfish-work-agency-brand"
      />
    ),
    [ORGANIZATION_TYPES.BRAND]: (
      <TooltipContent
        text="You have nothing to currently review. When the content creator uploads their content you'll be able to view, leave feedback and
          approve here. Sit tight! We'll notify you when the content has been shared."
        link="https://help.otterfish.com/knowledge/how-does-otterfish-work-agency-brand"
      />
    ),
    [ORGANIZATION_TYPES.TALENT_MANAGER]: (
      <TooltipContent
        text="You have nothing to currently review or share. You can review your creators content or even upload on their behalf and share with agency or brand you are working with. Sit tight! We'll notify you when the content has been shared by your creator."
        link="https://help.otterfish.com/knowledge/how-does-otterfish-work-for-talent-managers"
      />
    ),
    [ORGANIZATION_TYPES.CONTENT_CREATOR]: (
      <TooltipContent
        text="Upload and share your content with your collaborators to have them review, leave feedback and finally approve."
        link="https://help.otterfish.com/knowledge/how-does-otterfish-work-for-content-creators"
      />
    ),
  },
};

type PropsType = {
  text?: string;
  section?: DELIVERABLE_SECTION_TYPES;
  roleType?: COLLABORATOR_ROLE_TYPES | undefined;
};

const QuestionTooltip: FC<PropsType> = ({ text, section, roleType }) => {
  const [isDisplayTooltip, setIsDisplayTooltip] = useState(false);
  const ref = useRef(null);

  useClickOutside(ref, null, () => setIsDisplayTooltip(false));

  const tooltipText = useMemo(() => {
    return tooltipTextConfig[section || '']?.[roleType || ''];
  }, [section, roleType]);

  return (
    <div className={styles.root}>
      {text && <span className={styles.text}>{text}</span>}
      <div className={styles.question} ref={ref}>
        <AiFillQuestionCircle
          onClick={() => {
            if (!section || !roleType) return;
            setIsDisplayTooltip(!isDisplayTooltip);
          }}
        />
        {isDisplayTooltip && <div className={styles.tooltip}>{tooltipText}</div>}
      </div>
    </div>
  );
};

export default QuestionTooltip;
