import { Action } from 'redux';
import { IViewBriefData } from '../../models/entities/boardBrief/ViewBrief';
import { IBriefAsset } from '../../models/entities/asset/BriefAsset';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../store';

export enum BOARD_BRIEF_ACTION_TYPES {
  GET_BOARD_BRIEF_REQUEST = 'GET_BOARD_BRIEF_REQUEST',
  GET_BOARD_BRIEF_SUCCESS = 'GET_BOARD_BRIEF_SUCCESS',
  GET_BOARD_BRIEF_FAILURE = 'GET_BOARD_BRIEF_FAILURE',

  GET_BOARD_BRIEF_ASSETS_REQUEST = 'GET_BOARD_BRIEF_ASSETS_REQUEST',
  GET_BOARD_BRIEF_ASSETS_SUCCESS = 'GET_BOARD_BRIEF_ASSETS_SUCCESS',
  GET_BOARD_BRIEF_ASSETS_FAILURE = 'GET_BOARD_BRIEF_ASSETS_FAILURE',

  CREATE_BOARD_BRIEF_REQUEST = 'CREATE_BOARD_BRIEF_REQUEST',
  CREATE_BOARD_BRIEF_SUCCESS = 'CREATE_BOARD_BRIEF_SUCCESS',
  CREATE_BOARD_BRIEF_FAILURE = 'CREATE_BOARD_BRIEF_FAILURE',

  UPDATE_BOARD_BRIEF_REQUEST = 'UPDATE_BOARD_BRIEF_REQUEST',
  UPDATE_BOARD_BRIEF_SUCCESS = 'UPDATE_BOARD_BRIEF_SUCCESS',
  UPDATE_BOARD_BRIEF_FAILURE = 'UPDATE_BOARD_BRIEF_FAILURE',

  CLEAR_BOARD_BRIEF_ERRORS = 'CLEAR_BOARD_BRIEF_ERRORS',
  CLEAR_BOARD_BRIEF_STATE = 'CLEAR_BOARD_BRIEF_STATE',
}

export type GetBoardBriefRequestType = Action<BOARD_BRIEF_ACTION_TYPES.GET_BOARD_BRIEF_REQUEST>;
export type GetBoardBriefSuccessType = Action<BOARD_BRIEF_ACTION_TYPES.GET_BOARD_BRIEF_SUCCESS> & {
  payload: IViewBriefData;
};
export type GetBoardBriefFailureType = Action<BOARD_BRIEF_ACTION_TYPES.GET_BOARD_BRIEF_FAILURE> & { payload: string };

export type GetBoardBriefAssetsRequestType = Action<BOARD_BRIEF_ACTION_TYPES.GET_BOARD_BRIEF_ASSETS_REQUEST>;
export type GetBoardBriefAssetsSuccessType = Action<BOARD_BRIEF_ACTION_TYPES.GET_BOARD_BRIEF_ASSETS_SUCCESS> & {
  payload: IBriefAsset[];
};
export type GetBoardBriefAssetsFailureType = Action<BOARD_BRIEF_ACTION_TYPES.GET_BOARD_BRIEF_ASSETS_FAILURE>;

export type CreateBoardBriefRequestType = Action<BOARD_BRIEF_ACTION_TYPES.CREATE_BOARD_BRIEF_REQUEST>;
export type CreateBoardBriefSuccessType = Action<BOARD_BRIEF_ACTION_TYPES.CREATE_BOARD_BRIEF_SUCCESS>;
export type CreateBoardBriefFailureType = Action<BOARD_BRIEF_ACTION_TYPES.CREATE_BOARD_BRIEF_FAILURE> & { payload: string };

export type UpdateBoardBriefRequestType = Action<BOARD_BRIEF_ACTION_TYPES.UPDATE_BOARD_BRIEF_REQUEST>;
export type UpdateBoardBriefSuccessType = Action<BOARD_BRIEF_ACTION_TYPES.UPDATE_BOARD_BRIEF_SUCCESS>;
export type UpdateBoardBriefFailureType = Action<BOARD_BRIEF_ACTION_TYPES.UPDATE_BOARD_BRIEF_FAILURE> & { payload: string };

export type ClearBoardBriefErrors = Action<BOARD_BRIEF_ACTION_TYPES.CLEAR_BOARD_BRIEF_ERRORS>;
export type ClearBoardBriefState = Action<BOARD_BRIEF_ACTION_TYPES.CLEAR_BOARD_BRIEF_STATE>;

export type BoardBriefActionsType =
  | GetBoardBriefRequestType
  | GetBoardBriefSuccessType
  | GetBoardBriefFailureType
  | GetBoardBriefAssetsRequestType
  | GetBoardBriefAssetsSuccessType
  | GetBoardBriefAssetsFailureType
  | CreateBoardBriefRequestType
  | CreateBoardBriefSuccessType
  | CreateBoardBriefFailureType
  | UpdateBoardBriefRequestType
  | UpdateBoardBriefSuccessType
  | UpdateBoardBriefFailureType
  | ClearBoardBriefErrors
  | ClearBoardBriefState;

export type BoardBriefThunkReturnType<ReturnType = void> = ThunkAction<Promise<ReturnType>, RootState, null, BoardBriefActionsType>;
