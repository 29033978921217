import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './DeliverablesList.module.scss';
import DeliverableCell from '../../../../../components/Cells/DeliverableCell';
import Pagination from '../../../../../components/Pagination';
import { useHistory, useParams } from 'react-router-dom';
import {
  deleteDeliverableItem,
  duplicateDeliverableItem,
  getBoardDeliverables,
  getUpcomingLiveDate,
} from '../../../../../actions/ContentBoardActions/contentBoard.actions';
import cs from 'classnames';
import SortButtons from '../../../../../components/SortButtons';
import { useMediaQuery } from 'react-responsive/src';
import DeliverablesDownloadAll from '../DeliverablesDownloadAll';
import { AuthorizedUser } from '../../../../../models/permissions/users/AuthorizedUser';
import { ContentBoardPermission } from '../../../../../models/permissions/enum/ContentBoardPermission';
import AddDeliverablesModal from '../AddDeliverablesModal';
import ButtonLightPurpleWhite from '../../../../../components/Buttons/ButtonLightPurpleWhite';
import demoBackground from '../../../../../assets/img/deliverable-list-demo-background.png';
import demoTabletBackground from '../../../../../assets/img/deliverable-list-mobile-demo-background.png';
import CellRowLoadingState from './CellRowLoadingState';
import { useAppDispatch, useAppSelector, useThunkDispatch } from '../../../../../shared/hooks/reduxHooks';
import { SORT_ORDER_TYPE } from '../../../../../models/enums/SortOrderType';
import { DeliverablesSortType } from '../../../../../models/entities/board/DeliverablesSortType';
import { DELIVERABLES_SORT_FIELDS_TYPE } from '../../../../../models/entities/board/enums/DeliverablesSortFieldsType';
import BlurConfirmPopup from '../../../components/BlurConfirmPopup';
import ButtonWithPurpleRoundedIcon from '../../../../../components/Buttons/ButtonWithPurpleRoundedIcon';

const listHeader = [...Object.values(DELIVERABLES_SORT_FIELDS_TYPE)];
const columnName = {
  [DELIVERABLES_SORT_FIELDS_TYPE.NAME]: 'Name',
  [DELIVERABLES_SORT_FIELDS_TYPE.PLATFORM]: 'Platform',
  [DELIVERABLES_SORT_FIELDS_TYPE.LIVE_DATETIME]: 'Live Date',
  [DELIVERABLES_SORT_FIELDS_TYPE.CUSTOM_STATUS_CONCEPT]: 'Concept',
  [DELIVERABLES_SORT_FIELDS_TYPE.CUSTOM_STATUS_CONTENT]: 'Content Upload',
};

const DeliverablesList = () => {
  const dispatch = useAppDispatch();
  const thunkDispatch = useThunkDispatch();
  const history = useHistory();
  const params = useParams<{ boardId: string }>();
  const currentOrg = useAppSelector(state => state.auth.currentOrganization);
  const { openedBoard, deliverables, deliverablesTotalCount, deliverablesLoading, pageModel } = useAppSelector(state => state.contentBoard);
  const userModel = useAppSelector(state => state.auth.userModel);
  const [page, setPage] = useState<number>(1);
  const [sort, setSort] = useState<DeliverablesSortType>({
    order_by: DELIVERABLES_SORT_FIELDS_TYPE.LIVE_DATETIME,
    order_type: SORT_ORDER_TYPE.ASC,
  });
  const [isOpenAddDeliverableModal, setIsOpenAddDeliverableModal] = useState<boolean>(false);
  const [deliverableToDelete, setDeliverableToDelete] = useState<any>(null);
  const isTablet: boolean = useMediaQuery({ query: '(max-width: 768px)' });

  const PAGE_SIZE: number = 10;
  const isListEmpty: boolean = !deliverablesLoading && !deliverables?.length;

  const getDeliverables = useCallback(() => {
    dispatch(getBoardDeliverables(params.boardId, page, PAGE_SIZE, sort));
  }, [currentOrg, params, page, sort, userModel]);

  useEffect(() => {
    if (!currentOrg && userModel && userModel instanceof AuthorizedUser) return;
    getDeliverables();
  }, [page, currentOrg, sort, params, userModel]);

  const onDuplicate = useCallback(
    deliverableItem => {
      thunkDispatch(duplicateDeliverableItem(currentOrg.organization_id, params.boardId, deliverableItem.id)).then(() => {
        if (!deliverableItem.live_datetime) return;
        dispatch(getUpcomingLiveDate(params.boardId));
      });
    },
    [page, currentOrg, sort, params]
  );

  const onOpenConfirmDeleteAccept = useCallback(() => {
    setPage(1);
    thunkDispatch(deleteDeliverableItem(currentOrg.organization_id, params.boardId, deliverableToDelete.id, 1, PAGE_SIZE, sort)).then(
      () => {
        if (!deliverableToDelete.live_datetime) return;
        dispatch(getUpcomingLiveDate(params.boardId));
      }
    );
    setDeliverableToDelete(null);
  }, [page, currentOrg, sort, params, deliverableToDelete]);

  const onOpenConfirmDeleteDiscard = useCallback(() => {
    setDeliverableToDelete(null);
  }, []);

  const handleDeliverableItemClick = (id: string | number): void => {
    history.push(`/content-board/${openedBoard?.id}/deliverables/${id}`);
  };

  const isDeliverableCellActionsVisible: boolean = useMemo(() => {
    return !!pageModel?.roleCan(ContentBoardPermission.CAN_SEE_DELIVERABLE_ACTIONS);
  }, [pageModel]);

  return (
    <div className={cs(styles.root, { [styles.emptyRoot]: isListEmpty })}>
      {!deliverablesLoading && !isListEmpty ? (
        <div className={styles.header}>
          <div className={styles.headerLeft}>
            <span className={styles.headerTitle}>Deliverables</span>
            {isTablet && <div className={styles.deliverablesCount}>{deliverables.length}</div>}
          </div>
          <div className={styles.headerRight}>
            {pageModel?.roleCan(ContentBoardPermission.CAN_DOWNLOAD_ALL_DELIVERABLES_ASSETS) && (
              <DeliverablesDownloadAll className={styles.headerRightButton} />
            )}
            {pageModel?.roleCan(ContentBoardPermission.CAN_CREATE_NEW_DELIVERABLE) && (
              <ButtonLightPurpleWhite
                size={'medium'}
                designVersion="v2"
                className={cs(styles.addDeliverablesButton, styles.headerRightButton)}
                onClick={() => setIsOpenAddDeliverableModal(true)}
                presetIcon="plus2"
              >
                Add
              </ButtonLightPurpleWhite>
            )}
          </div>
        </div>
      ) : null}

      <div className={styles.contentInner}>
        {isListEmpty && (
          <div className={styles.empty}>
            <div className={styles.emptyContent}>
              <div className={styles.title}>No deliverables added</div>
              <div className={styles.subTitle}>
                Add in the deliverables your content creator need to work on. Once added, they will be able to upload their content for
                approval.
              </div>
              {pageModel?.roleCan(ContentBoardPermission.CAN_CREATE_NEW_DELIVERABLE) ? (
                <ButtonWithPurpleRoundedIcon
                  className={styles.btn}
                  size="medium"
                  presetIcon="plus"
                  onClick={() => setIsOpenAddDeliverableModal(true)}
                >
                  Add deliverables
                </ButtonWithPurpleRoundedIcon>
              ) : null}
            </div>
            <div className={styles.emptyImage}>
              <img alt={'content board demo'} src={isTablet ? demoTabletBackground : demoBackground} />
            </div>
          </div>
        )}

        <div className={cs(styles.list, { [styles.hidden]: isListEmpty })}>
          <div
            className={cs(styles.listHeader, {
              [styles.hideLastColumn]: !isDeliverableCellActionsVisible,
            })}
          >
            {listHeader.map(item => (
              <div key={item}>
                <SortButtons
                  name={columnName[item]}
                  orderBy={item}
                  isActive={orderType => item === sort.order_by && orderType === sort.order_type}
                  onSort={(orderBy, orderType) => setSort({ order_by: orderBy, order_type: orderType })}
                />
              </div>
            ))}
            {/* <div data-reason="progress-bar-column" />*/}
          </div>

          {deliverablesLoading ? (
            <>
              {[...Array(deliverables?.length || PAGE_SIZE).keys()].map(index => (
                <CellRowLoadingState index={index} key={index} isTablet={isTablet} hideActions={!isDeliverableCellActionsVisible} />
              ))}
            </>
          ) : (
            <>
              {deliverables?.map(itemData => (
                <DeliverableCell
                  key={itemData.id}
                  deliverable={itemData}
                  onClick={() => handleDeliverableItemClick(itemData.id)}
                  onDelete={() => setDeliverableToDelete(itemData)}
                  onDuplicate={() => onDuplicate(itemData)}
                  isTablet={isTablet}
                  hideActions={!isDeliverableCellActionsVisible}
                />
              ))}
            </>
          )}
        </div>
        {!!deliverables?.length && deliverablesTotalCount > PAGE_SIZE && (
          <Pagination
            activeClassName={styles.paginationActiveClassName}
            className={styles.pagination}
            amount={PAGE_SIZE}
            totalCount={deliverablesTotalCount}
            page={page}
            setPage={setPage}
          />
        )}
        <AddDeliverablesModal
          isOpen={isOpenAddDeliverableModal}
          onClose={() => setIsOpenAddDeliverableModal(false)}
          onSubmitted={getDeliverables}
        />
        <BlurConfirmPopup
          popUpText={`You are about to delete "${deliverableToDelete?.name}" deliverable. All your content will be lost. Are you sure?`}
          primaryBtnText={'Yes, delete'}
          secondaryBtnText={'Discard'}
          isOpen={!!deliverableToDelete}
          onConfirm={onOpenConfirmDeleteAccept}
          onDiscard={onOpenConfirmDeleteDiscard}
        />
      </div>
    </div>
  );
};

export default DeliverablesList;
