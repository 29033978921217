import React from 'react';
import styles from './ReleasesPage.module.scss';
import dayjs from 'dayjs';
import LoadingContainer from '../../../../LoadingContainer';

const ReleasesPage = ({ releases, onReleaseClick, isLoading, handleTellUsClick }) => {
  const getLoaders = () => {
    const loaders = [];
    for (let i = 0; i < 10; i++) {
      loaders.push(
        <LoadingContainer key={i} height="100px" width="80%" borderRadius="3px" margin={'0 0 10px 0'} animationDelay={`${-1 + i * 0.1}s`} />
      );
    }
    return loaders;
  };

  return (
    <>
      <div className={styles.releasesPageContainer}>
        <div className={styles.newsTitleContainer}>
          <div className={styles.titleText}>Latest News</div>
          <div className={styles.subTitle}>Check out all our latest releases here</div>
        </div>

        {isLoading && getLoaders()}

        {!isLoading && !releases?.length && <div className={styles.emptyNews}>No news yet.</div>}

        {!isLoading &&
          !!releases?.length &&
          releases.map(release => (
            <div key={release.id} className={styles.releaseItem} onClick={() => onReleaseClick(release)}>
              <div className={styles.releaseTitle}>{release.title2}</div>
              <div className={styles.releaseDate}>{dayjs(release.created_at).format('DD.MM.YYYY')}</div>
            </div>
          ))}
      </div>
      <div className={styles.releasesFooter}>
        <div>
          Have an idea or feature?
          <span className={styles.footerLink} onClick={handleTellUsClick}>
            Do tell us
          </span>
        </div>
      </div>
    </>
  );
};

export default ReleasesPage;
