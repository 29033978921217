import React, {FC, PropsWithChildren} from 'react';
import styles from './AssetsList.module.scss';

type PropsType = PropsWithChildren;

const AssetsList: FC<PropsType> = props => {
    return (
      <div className={styles.root}>
        {props.children}
      </div>
    )
};

export default AssetsList;
