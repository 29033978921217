import { DeleteIcon, DownloadIcon } from '../../../../../../expert/components/IconsSvg/ReactIcons/ReactIcons';
import { ReactComponent as PDFFileSVG } from '../../../../../../assets/img/icons/PDFFile.svg';
import { ReactComponent as DocFileSVG } from '../../../../../../assets/img/icons/DOCFile.svg';
import { ReactComponent as ContractFileSVG } from '../../../../../../assets/img/icons/ContractFile.svg';
import { ReactComponent as OtterfishBriefSVG } from '../../../../../../assets/img/icons/OtterfishBrief.svg';
import OtterfishFile from '../../../../../../assets/img/icons/otterfishFile.png';
import TextFile from '../../../../../../assets/img/icons/TextFile.png';
import { ReactComponent as ReplaceIcon } from '../../../../../../assets/img/icons/replace.svg';
import { ReactComponent as EditIcon } from '../../../../../../assets/img/icons/edit_pen.svg';
import React, { FC, SVGProps, useMemo } from 'react';
import styles from './useFilledDocumentCard.module.scss';
import cs from 'classnames';
import { DOCUMENT_CARD_VIEW_TYPES } from '../types/DocumentCardViewTypes';
import { ActionsDropdownV1Option } from '../../../../../../components/ActionsDropdownV1';

type GetActionOptionsSettingsType = {
  onEdit?: () => void;
  onDelete: () => void;
  onDownload?: () => void;
  onReplace?: () => void;
};

type GetActionOptionsType = (settings: GetActionOptionsSettingsType) => ActionsDropdownV1Option[];

type DocumentTypeConfigType = Record<
  DOCUMENT_CARD_VIEW_TYPES,
  { image: FC<SVGProps<SVGSVGElement>>; getOptions: GetActionOptionsType; canDownload: boolean; documentImage: string }
>;

const getFileDocumentActionOptions: GetActionOptionsType = settings => [
  {
    text: 'Replace',
    icon: <ReplaceIcon />,
    onClick: (event, close) => {
      event.stopPropagation();
      settings.onReplace && settings.onReplace();
      close();
    },
    className: cs(styles.replaceButton, styles.button),
  },
  {
    text: 'Download',
    icon: <DownloadIcon />,
    onClick: (event, close) => {
      event.stopPropagation();
      settings.onDownload && settings.onDownload();
      close();
    },
    className: cs(styles.downloadButton, styles.button),
  },
  {
    text: 'Delete',
    icon: <DeleteIcon />,
    onClick: (event, close) => {
      event.stopPropagation();
      settings.onDelete && settings?.onDelete();
      close();
    },
    className: cs(styles.deleteButton, styles.button),
  },
];

const getOtterfishDocumentActionOptions: GetActionOptionsType = settings => [
  {
    text: 'Edit',
    icon: <EditIcon />,
    onClick: (event, close) => {
      event.stopPropagation();
      settings.onEdit && settings.onEdit();
      close();
    },
    className: cs(styles.editButton, styles.button),
  },
  {
    text: 'Delete',
    icon: <DeleteIcon />,
    onClick: (event, close) => {
      event.stopPropagation();
      settings.onDelete && settings.onDelete();
      close();
    },
    className: cs(styles.deleteButton, styles.button),
  },
];

const documentTypesConfig: DocumentTypeConfigType = {
  [DOCUMENT_CARD_VIEW_TYPES.PDF]: {
    image: PDFFileSVG,
    getOptions: getFileDocumentActionOptions,
    canDownload: true,
    documentImage: TextFile,
  },
  [DOCUMENT_CARD_VIEW_TYPES.WORD]: {
    image: DocFileSVG,
    getOptions: getFileDocumentActionOptions,
    canDownload: true,
    documentImage: TextFile,
  },
  [DOCUMENT_CARD_VIEW_TYPES.CONTRACT]: {
    image: ContractFileSVG,
    getOptions: getFileDocumentActionOptions,
    canDownload: true,
    documentImage: TextFile,
  },
  [DOCUMENT_CARD_VIEW_TYPES.OTTERFISH_BRIEF]: {
    image: OtterfishBriefSVG,
    getOptions: getOtterfishDocumentActionOptions,
    canDownload: false,
    documentImage: OtterfishFile,
  },
};

const useFilledDocumentCardConfig = <DocumentTypes extends DOCUMENT_CARD_VIEW_TYPES = DOCUMENT_CARD_VIEW_TYPES>(
  documentType: DocumentTypes,
  buttonOptionSettings: GetActionOptionsSettingsType,
  isOnlyViewOptions?: boolean,
  isOnlyPreview?: boolean
): {
  image: typeof documentTypesConfig[DocumentTypes]['image'];
  options: ActionsDropdownV1Option[];
  documentImage;
} => {
  const options: ActionsDropdownV1Option[] = useMemo(() => {
    if (isOnlyPreview) return [];

    if (!isOnlyViewOptions) {
      return documentTypesConfig[documentType].getOptions(buttonOptionSettings);
    }
    const canDownload = documentTypesConfig[documentType].canDownload;

    if (canDownload) {
      return [
        {
          text: 'Download',
          icon: <DownloadIcon />,
          onClick: (event, close) => {
            event.stopPropagation();
            buttonOptionSettings.onDownload && buttonOptionSettings.onDownload();
            close();
          },
          className: cs(styles.downloadButton, styles.button),
        },
      ];
    }

    return [];
  }, [documentType, buttonOptionSettings, isOnlyViewOptions, isOnlyPreview]);

  return {
    image: documentTypesConfig[documentType].image,
    options,
    documentImage: documentTypesConfig[documentType].documentImage,
  };
};

export default useFilledDocumentCardConfig;
