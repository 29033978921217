import React, { ComponentProps, FC, memo } from 'react';
import styles from './EditorStyledField.module.scss';
import EditorComponent from '../EditorComponent';
import cs from 'classnames';

type PropsType = {
  keyForRerender?: string | false;
} & ComponentProps<typeof EditorComponent>;

const EditorStyledField: FC<PropsType> = memo(({
  editorClassName,
  wrapperClassName,
  focusClassName,
  toolbarOnFocus = true,
  readOnly = false,
  disabled,
  placeholder,
  keyForRerender,
  toolbarClassName,
  ...props
}) => {
  return (
    <>
      {[0].map(() => (
        <EditorComponent
          key={keyForRerender || 'editor-init-key'}
          editorClassName={cs(styles.editorClassName, editorClassName)}
          wrapperClassName={cs(styles.wrapperClassName, { [styles.readOnly]: readOnly }, { [styles.disabled]: disabled }, wrapperClassName)}
          toolbarClassName={cs(styles.toolbarClassName, toolbarClassName)}
          focusClassName={cs(styles.focusClassName, focusClassName)}
          toolbarOnFocus={toolbarOnFocus}
          disabled={disabled}
          placeholder={disabled ? '' : placeholder}
          readOnly={readOnly}
          {...props}
        />
      ))}
    </>
  );
});

export default EditorStyledField;
