import React, { useEffect, useImperativeHandle } from 'react';
import styles from './DeliverablesPlatforms.module.scss';
import FacebookSVG from '../../../../../../../components/IconsSvg/FacebookSVG';
import InstagramSVG from '../../../../../../../components/IconsSvg/InstagramSVG';
import TikTokSVG from '../../../../../../../components/IconsSvg/TikTokSVG';
import YoutubeSVG from '../../../../../../../components/IconsSvg/YoutubeSVG';
import BlogPostSVG from '../../../../../../../components/IconsSvg/BlogPostSVG';
import AddDeliverablesPlatform from '../AddDeliverablesPlatform';
import _ from 'lodash';
import { ICreateDeliverableFromBoardDTO } from '../../../../../../../models/entities/deliverable/dto/CreateDeliverableFromBoard';
import { useAppSelector } from '../../../../../../../shared/hooks/reduxHooks';
import { PLATFORMS } from '../../../../../../../models/enums/Platforms';
import useDeliverablesFormData, {
  DeliverablesFormSettingsDataType,
  IDeliverableFormData,
  UseDeliverablesFormHandlersType,
} from '../../hooks/useDeliverablesFormData';
import { FormikProps } from 'formik';

type DeliverablePlatformsProps = {
  onSubmit?: (deliverables: ICreateDeliverableFromBoardDTO[]) => void;
  setIsValid?: React.Dispatch<React.SetStateAction<boolean>>;
  initialValue?: IDeliverableFormData[];
  onValuesWereChanged?: (deliverables: IDeliverableFormData[]) => void;
  canEditDueDates: boolean;
  canEditNotifications: boolean;
};

const PlatformSVGs = {
  [PLATFORMS.FACEBOOK]: [FacebookSVG],
  [PLATFORMS.INSTAGRAM]: [InstagramSVG],
  [PLATFORMS.TIKTOK]: [TikTokSVG],
  [PLATFORMS.YOUTUBE]: [YoutubeSVG],
  [PLATFORMS.BLOG_POST]: [BlogPostSVG],
};

const DeliverablePlatforms = React.forwardRef<
  (UseDeliverablesFormHandlersType & { submit: FormikProps<IDeliverableFormData>['submitForm'] }) | null,
  DeliverablePlatformsProps
>(({ onSubmit = () => {}, setIsValid, onValuesWereChanged, initialValue = [], canEditDueDates, canEditNotifications }, ref) => {
  const errors = useAppSelector(state => state.contentBoard.createDeliverablesErrorData);
  const isCreatingLoading = useAppSelector(state => state.contentBoard.creatingDeliverablesLoading);
  const { handlers, deliverablesFormSettings, canSubmit, formik } = useDeliverablesFormData({
    onSubmit,
    initialValue,
  });

  useImperativeHandle(ref, () => ({
    ...handlers,
    submit: formik.submitForm,
  }));

  useEffect(() => {
    if (onValuesWereChanged) {
      onValuesWereChanged([...formik.values.deliverables]);
    }
  }, [formik.values.deliverables, onValuesWereChanged]);

  useEffect(() => {
    if (setIsValid) {
      setIsValid(!formik.values.deliverables.length || (canSubmit && !isCreatingLoading));
    }
  }, [canSubmit, setIsValid, isCreatingLoading, formik.values.deliverables]);

  useEffect(() => {
    if (errors.length) {
      errors.forEach(error => {
        const errorMessage = error.message.match(/([^\"]+$)/g);
        if (!errorMessage) return;
        const errorMessageView = errorMessage[0][0].toUpperCase() + errorMessage[0].slice(1);
        handlers.setErrorByIndex(error.path[0], { [error.path[1]]: errorMessageView });
      });
    }
  }, [errors]);

  return (
    <div className={styles.platformsList}>
      {(_.keys(deliverablesFormSettings) as (keyof DeliverablesFormSettingsDataType)[]).map((platformName, index) => (
        <AddDeliverablesPlatform
          canEditNotifications={canEditNotifications}
          canEditDueDates={canEditDueDates}
          deliverablesFormSettings={deliverablesFormSettings}
          handlers={handlers}
          formik={formik}
          platformName={platformName}
          platformSVGs={PlatformSVGs[platformName]}
          key={index}
        />
      ))}
    </div>
  );
});

export default DeliverablePlatforms;
