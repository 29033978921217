import React, { FC } from 'react';
import LoadingContainer from '../../../../../../../components/LoadingContainer';
import { useMediaQuery } from 'react-responsive/src';

const LoadingState: FC = () => {
  const isDesktopLandscape: boolean = useMediaQuery({
    query: '(min-width: 992px) and (orientation: landscape)',
  });

  if (isDesktopLandscape) {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <LoadingContainer width={'30%'} height={'50px'} borderRadius="5px" animationDelay={`${1}s`} />
        <LoadingContainer width={'40%'} height={'50px'} borderRadius="5px" animationDelay={`${1.4}s`} />
      </div>
    );
  }
  return (
    <>
      <LoadingContainer width={'100%'} height={'50px'} borderRadius="5px" animationDelay={`${0.1}s`} />
      <LoadingContainer width={'100%'} height={'15px'} borderRadius="5px" animationDelay={`${1.1}s`} />
      <LoadingContainer width={'100%'} height={'15px'} borderRadius="5px" animationDelay={`${2.1}s`} />
      <LoadingContainer width={'100%'} height={'15px'} borderRadius="5px" animationDelay={`${3.1}s`} />
    </>
  );
};

export default LoadingState;
