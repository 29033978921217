import { instance } from '../shared/utils/api.config.creator';

export const getAllPlans = async () => {
  const response = await instance.get('/pricing-plans?order_by=id&order_type=asc');
  return response.data;
};

/**
 *
 * @param {'primary' | 'extra'} pricingType
 */
export const getAllPlansByOrganizationId = async (orgId, pricingType = 'primary') => {
  const response = await instance.get(`/pricing-plans?organization_id=${orgId}&type=${pricingType}`);
  return response.data;
};
