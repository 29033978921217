import React, { ComponentProps, FC, useEffect, useMemo, useState } from 'react';
import styles from './InviteCollaboratorsModal.module.scss';
import { Modal } from '../index';
import SuitcaseSVG from '../../IconsSvg/SuitcaseSVG';
import TextField from '../../FormControls/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Option from '../../../pages/CampaignBrief/components/Option';
import Dropdown from '../../FormControls/Dropdown';
import LightPurpleButton from '../../../pages/CampaignBrief/CampaignBriefPage/components/LightPurpleButton';
import { ReactComponent as PlaneIcon } from '../../../assets/img/icons/plane.svg';
import TextArea from '../../Inputs/TextArea';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';
import { emailValidation } from '../../../pages/CreateTest/utils';
import cs from 'classnames';
import { TYPES_CAMPAIGNS } from '../../../shared/constants/constants';
import { IBoardCollaborator } from '../../../models/entities/board/BoardCollaborator';
import { IBoardInvitedCollaborator } from '../../../models/entities/board/BoardInvitedCollaborator';
import { COLLABORATOR_ROLE_TYPES } from '../../../models/permissions/collaborator/CollaboratorRole';
import { useAppSelector } from '../../../shared/hooks/reduxHooks';
import WarningCard, {
  WARNING_CARD_ICONS,
} from '../../../pages/ContentApproval/createPages/CreateBoardWrapper/CreateBoard/components/WarningCard';
import { PUBLIC_EMAIL_DOMAINS } from '../../../shared/constants/tsConstants';
import { MEMBER_ROLE_IN_ORGANIZATION_TYPES } from '../../../models/enums/MemberRoleInOrganizationTypes';

export type InviteCollaboratorsFormikType = {
  email: string;
  organizationType: { name: string; type: COLLABORATOR_ROLE_TYPES };
  custom_message: string;
};

type InitialStateCollaboratorFormikType = {
  email: string;
  organizationType: { name: string; type: COLLABORATOR_ROLE_TYPES | '' };
  custom_message: string;
};

export type CollaboratorsFormDropdownOptionsType = {
  name: string;
  type: COLLABORATOR_ROLE_TYPES;
};

type PropsType = {
  onClose: () => void;
  onSubmit: (formValues: InviteCollaboratorsFormikType) => void;
  availableOrganizationDropdownOption: CollaboratorsFormDropdownOptionsType[];
  isTablet?: boolean;
  collaborators: (IBoardCollaborator | IBoardInvitedCollaborator)[];
  allowedCollaborators: COLLABORATOR_ROLE_TYPES[];
};

const ModalContent: FC<PropsType> = ({
  onClose,
  onSubmit,
  availableOrganizationDropdownOption,
  isTablet,
  collaborators,
  allowedCollaborators,
}) => {
  const { data: members } = useAppSelector(state => state.organizations.allMembers);
  const user = useAppSelector(state => state.auth.user);
  const [emailMatch, setEmailMatch] = useState(false);
  const [emailMatchSameOrganization, setEmailMatchSameOrganization] = useState<boolean>(false);
  const [emailMatchAdminOrganizationDomain, setEmailMatchAdminOrganizationDomain] = useState<boolean>(false);

  const allowedOrgTypes = useMemo(() => {
    const isCCinCollaborators = collaborators.some(collaborator => {
      if ('type' in collaborator) {
        return collaborator?.type === TYPES_CAMPAIGNS.CONTENT_CREATOR;
      } else if ('new_organization_type' in collaborator) {
        return collaborator.new_organization_type === TYPES_CAMPAIGNS.CONTENT_CREATOR;
      }

      return false;
    });

    if (isCCinCollaborators) {
      return allowedCollaborators.filter(orgType => orgType !== TYPES_CAMPAIGNS.CONTENT_CREATOR);
    }

    return allowedCollaborators;
  }, [collaborators, allowedCollaborators]);

  const notAllowedEmails = useMemo(() => {
    return members.filter(member => member.user_id !== user?.id).map(member => member.email);
  }, [members, user?.id]);

  const formik = useFormik<InitialStateCollaboratorFormikType>({
    initialValues: {
      email: '',
      organizationType: {
        name: '',
        type: '',
      },
      custom_message: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().test('validate-email', emailValidation).required('Email is required'),
      organizationType: Yup.object()
        .shape({
          name: Yup.string().required('Organization type is required'),
          type: Yup.string().oneOf(allowedOrgTypes, 'Inviting a second content creator is not allowed to ensure data privacy'),
        })
        .required('Organization type is required'),
    }),
    validateOnChange: true,
    onSubmit: () => {},
  });

  useEffect(
    function handleEmailMatchAdminOrganizationDomain() {
      const valueEmailDomain = formik.values.email.split('@')[1];

      if (PUBLIC_EMAIL_DOMAINS.includes(valueEmailDomain)) {
        setEmailMatchAdminOrganizationDomain(false);
        return;
      }

      const admin = members.find(member => member.role_in_organization === MEMBER_ROLE_IN_ORGANIZATION_TYPES.OWNER);
      if (!admin) return;

      const adminEmailDomain = admin.email.split('@')[1];

      if (adminEmailDomain !== valueEmailDomain) {
        setEmailMatchAdminOrganizationDomain(false);
        return;
      }

      setEmailMatchAdminOrganizationDomain(true);
    },
    [formik.values.email, members]
  );

  const handleSubmit = () => {
    if (formik.values.organizationType.type === '') return;
    onSubmit(formik.values as InviteCollaboratorsFormikType);
    onClose();
  };

  useEffect(() => {
    for (let i = 0; i < collaborators.length; i++) {
      if (collaborators[i].email === formik.values.email) {
        return setEmailMatch(true);
      }
      setEmailMatch(false);
    }
  }, [formik.values.email]);

  useEffect(() => {
    for (let i = 0; i < notAllowedEmails.length; i++) {
      if (notAllowedEmails[i] === formik.values.email) {
        return setEmailMatchSameOrganization(true);
      }
      setEmailMatchSameOrganization(false);
    }
  }, [formik.values.email, notAllowedEmails]);

  const emailError: string = useMemo(() => {
    if (!formik.touched.email) return '';

    if (emailMatch) return 'This collaborator already invited';

    if (emailMatchSameOrganization) return ' ';

    if (emailMatchAdminOrganizationDomain) return ' ';

    if (formik.errors.email) return formik.errors.email;

    return '';
  }, [emailMatch, formik.touched?.email, emailMatchSameOrganization, formik.errors.email, emailMatchAdminOrganizationDomain]);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <span className={styles.icon}>
          <SuitcaseSVG />
        </span>
        Add collaborator
      </div>
      <div className={styles.agencySection}>
        <TextField
          name={'email'}
          placeholder={'Type Email to invite'}
          className={styles.input}
          onChange={event => formik.setFieldValue('email', event.target.value.toLowerCase())}
          value={formik.values.email}
          error={emailError}
          onBlur={formik.handleBlur}
        />
        <div className={styles.dropdownWrapper}>
          {/* @ts-ignore*/}
          <Dropdown
            placeholder="Type of organization"
            className={styles.dropdown}
            valueClassName={cs(styles.dropdownValue, { [styles.dropDownInputError]: !!formik?.errors?.organizationType?.type })}
            placeholderClassName={styles.dropdownPlaceholder}
            items={availableOrganizationDropdownOption}
            getId={i => i.type}
            getDisplayValue={i => i.name}
            renderItem={value => <Option value={value} />}
            selectedItem={formik.values.organizationType.type ? formik.values.organizationType : null}
            onSelect={i => {
              formik.setTouched({
                ...formik.touched,
                organizationType: { type: true, name: formik.touched.organizationType?.name ?? false },
              });
              formik.setFieldValue('organizationType', i);
            }}
            error={
              formik?.touched?.organizationType && (!!formik?.errors?.organizationType?.name || !!formik?.errors?.organizationType?.type)
            }
          />
          {formik?.touched?.organizationType && formik?.errors?.organizationType?.type && (
            <div className={styles.dropdownError}>{formik?.errors?.organizationType?.type}</div>
          )}
          {formik?.touched?.organizationType && formik?.errors?.organizationType?.name && (
            <div className={styles.dropdownError}>{formik?.errors?.organizationType?.name}</div>
          )}
        </div>
      </div>

      {(emailMatchSameOrganization || emailMatchAdminOrganizationDomain) && (
        <WarningCard
          className={styles.warningCard}
          type={WARNING_CARD_ICONS.WARNING}
          warningText={
            <div className={styles.warningCardTextContainer}>
              <span>
                {emailMatchSameOrganization
                  ? 'User you are trying to invite is already a part of your organization and has access to the board. If you want to assign the ownership of the board, use Delegate Ownership button from the collaborator section'
                  : 'Looks like you are trying to invite a member of your team. Add them as a team member.'}
              </span>
              <span
                className={styles.link}
                onClick={() => window.open('https://help.otterfish.com/knowledge/how-to-change-ownership-of-a-content-board', '_blank')}
              >
                Click here to know more
              </span>
            </div>
          }
        />
      )}

      <TextArea
        placeholder={
          "Hey there! Make sure your collaborator knows why you're inviting them by adding a message here, it will appear as a part of their invite email. You can also add any notes that may help in onboarding them to  your campaign"
        }
        name={'custom_message'}
        onChange={formik.handleChange}
        value={formik.values.custom_message}
        error={formik.errors.custom_message}
        className={styles.messageTextArea}
      />
      <div className={styles.btnBlock}>
        <LightPurpleButton
          onClick={() => handleSubmit()}
          className={styles.actionButton}
          disabled={
            !formik.isValid || !formik.values.email || emailMatch || emailMatchSameOrganization || emailMatchAdminOrganizationDomain
          }
        >
          Send Invite
          <PlaneIcon />
        </LightPurpleButton>
        {isTablet && (
          <ButtonCustom className={styles.closeBtn} outline onClick={() => onClose()}>
            Close
          </ButtonCustom>
        )}
      </div>
    </div>
  );
};

type InviteCollaboratorsModalPropsType = ComponentProps<typeof Modal> & PropsType;

const InviteCollaboratorsModal: FC<InviteCollaboratorsModalPropsType> = ({ ...props }) => {
  return (
    <Modal className={styles.modal} {...props} maxWidth="550px" borderRadius="10px">
      <ModalContent {...props} />
    </Modal>
  );
};

export default InviteCollaboratorsModal;
