import React, { FC } from 'react';
import styles from './FileLoadingBar.module.scss';
import cs from 'classnames';

type PropsType = {
  percentageProgress: number;
  className?: string;
};

const FileLoadingBar: FC<PropsType> = props => {
  return <div className={cs(styles.root, props.className)} style={{ width: `${props.percentageProgress}%` }} />;
};

export default FileLoadingBar;
