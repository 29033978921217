import React from 'react';
import styles from './ErrorBannerAbsolute.module.scss';
import { ReactComponent as WarningIcon } from '../../assets/img/icons/triangle_warning.svg';

const ErrorBannerAbsolute = ({ errorText }) => {
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <WarningIcon />
        <span>{errorText}</span>
      </div>
    </div>
  );
};

export default ErrorBannerAbsolute;
