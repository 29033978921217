import React, { FC, useCallback, useState } from 'react';
import styles from './AddBriefModal.module.scss';
import BlueHeaderModal from '../../../../../../../components/Modals/BlueHeaderModal';
import CardImageButton from './components/CardImageButton';
import DocumentBrief from '../../../../../../../assets/img/DocumentBrief.png';
import OtterFishBrief from '../../../../../../../assets/img/OtterfishBrief.png';
import { ReactComponent as UploadArrowSVG } from '../../../../../../../assets/img/icons/UploadArrow.svg';
import PlusSVG from '../../../../../../../expert/components/IconsSvg/PlusSVG';
import useFileActionHandlers from '../../../../../hooks/useFileActionHandlers';
import { useAppDispatch, useAppSelector, useThunkDispatch } from '../../../../../../../shared/hooks/reduxHooks';
import { useHistory } from 'react-router';
import { IFileData } from '../../../../../hooks/useFileUpload';
import { updateBoardDocument } from '../../../../../../../actions/ContentBoardActions/contentBoard.actions';
import { boardBriefToastNotificationConfig } from '../../constants/notificationTypes';
import CustomError from '../../../../../../../models/entities/error/CustomError';
import TypedServerError from '../../../../../../../models/entities/error/TypedServerError';
import StorageLimitModal from '../../../../../../../components/Modals/ErrorLimitModal';
import notificationDataConfig from '../../../../../../../shared/utils/notificationDataConfig';
import { TOAST_NOTIFICATION_TYPES } from '../../../../../../../shared/constants/toastNotificationsData';
import { createNotification } from '../../../../../../../actions/toastNotificationActions/toastNotification.actions';

type PropsType = {
  onClose: () => void;
  isOpen: boolean;
};
const AddBriefModal: FC<PropsType> = props => {
  const dispatch = useAppDispatch();
  const thunkDispatch = useThunkDispatch();
  const history = useHistory();
  const currentOrgId = useAppSelector<string | number | null>(state => state.auth?.currentOrganization?.organization_id || null);
  const board = useAppSelector(state => state.contentBoard.openedBoard);
  const [storageLimitError, setStorageLimitError] = useState<TypedServerError | null>(null);

  const changeBriefFile = useCallback(
    (value: IFileData | null) => {
      if (!currentOrgId || !board?.id || !value) return;

      thunkDispatch(updateBoardDocument(currentOrgId, board?.id, 'brief_file', value.file, boardBriefToastNotificationConfig.upload)).catch(
        (error: CustomError) => {
          if (error instanceof TypedServerError) {
            setStorageLimitError(error);
          }
        }
      );
      props.onClose();
    },
    [currentOrgId, board, props.onClose]
  );

  const { onFileUpload, renderInput } = useFileActionHandlers(
    changeBriefFile,
    board?.briefFile || null,
    { accept: '.pdf, .doc, .docx' },
    fileExtension => {
      const notification = notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.FILE_TYPE_ERROR_BRIEF_FILE, {
        text: [fileExtension],
      });
      dispatch(createNotification(notification));
      props.onClose();
    }
  );

  return (
    <>
      <BlueHeaderModal modalProps={{ onClose: props.onClose, open: props.isOpen, maxWidth: '695px' }} headerTitle={'Add brief'}>
        <div className={styles.root}>
          {renderInput()}
          <CardImageButton
            onButtonClick={() => history.push(`/content-board/${board?.id}/create-brief`)}
            imageSrc={OtterFishBrief}
            title={'Create an Otterfish Brief'}
            buttonText={'Otterfish Brief'}
            ButtonIcon={PlusSVG}
            buttonClassName={styles.plusButton}
          />
          <CardImageButton
            onButtonClick={onFileUpload}
            imageSrc={DocumentBrief}
            title={'Upload a Brief'}
            buttonText={'Upload a Brief'}
            ButtonIcon={UploadArrowSVG}
            buttonClassName={styles.arrowButton}
          />
        </div>
      </BlueHeaderModal>
      <StorageLimitModal isOpen={Boolean(storageLimitError)} error={storageLimitError} onClose={() => setStorageLimitError(null)} />
    </>
  );
};

export default AddBriefModal;
