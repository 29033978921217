import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './CampaignBriefList.module.scss';
import MainLayout from '../../../components/MainLayout';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearBriefsList, getBriefList, setBriefListTab } from '../../../actions/brief.actions';
import EmptyState from './EmptyState';
import { useMediaQuery } from 'react-responsive/src';
import WatchDemoBriefsModal from '../../../components/Modals/WatchDemoBriefsModal';
import { getOrganizationUsers } from '../../../actions/user.actions';
import useFilters from './useFilters';
import BriefList from './BriefList';
import NoSearchResults from '../../../components/BriefsAndBoardsFlows/RootListPagesComponents/NoSearchResults';
import HeaderComponent from '../../../components/BriefsAndBoardsFlows/RootListPagesComponents/HeaderComponent';
import { setDiscussionsIsExpanded } from '../../../actions/discussion.actions';
import FiltersMobile from '../../../components/BriefsAndBoardsFlows/RootListPagesComponents/FiltersMobile';
import IconMobileSearch from '../../../components/IconMobileSearch';
import LayoutHeader from '../../ContentApproval/components/LayoutHeader';
import ErrorLimitModal from '../../../components/Modals/ErrorLimitModal';
import ERROR_TYPE from '../../../models/entities/error/ErrorTypes';

const trackCampaignInitiate = userId => {
  window.analytics.track('campaign_brief_initiate', {
    category: 'Campaign Brief',
    userId: userId,
  });
};

const headerTextsConfig = {
  title: 'Campaign briefs',
  searchPlaceholder: 'Search',
  statusPlaceholder: 'Campaign status',
  memberPlaceholder: 'Team members',
  createButtonText: 'New Campaign',
  createButtonRoute: '/campaign/create',
  createButtonAction: trackCampaignInitiate,
};

const statusOptions = [
  {
    key: 'in_progress',
    name: 'In progress',
  },
  {
    key: 'completed',
    name: 'Completed',
  },
];

const CampaignBriefList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentOrganizationID = useSelector(state => state.auth.currentOrganization?.organization_id);
  const currentPlan = useSelector(state => state.auth.currentOrganizationPlan);
  const { briefList, briefListLoading, briefListTabSwitcher } = useSelector(state => state.brief);
  const userId = useSelector(state => state.auth.user.id);
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const [openWatchDemo, setOpenWatchDemo] = useState(false);
  const showMobileSideBar = useSelector(state => state.sidebarMenu.showMobileSideBar);
  const [showFiltersMobile, setShowFiltersMobile] = useState(false);
  const [showPlanWarningModal, setShowPlanWarningModal] = useState(false);
  const pageSize = 10;

  const { filter, setPagePagination, getParams, onSort, ...filterFunctions } = useFilters(
    pageSize,
    statusOptions,
    briefListTabSwitcher,
    'OTTERFISH_FILTERS_CAMPAIGNS'
  );

  const isNoSearchFiltersYet =
    !filter.searchValue && filter.statusSelectIndex === -1 && filter.memberSelectIndex === -1 && !filter.dateStart && !filter.dateEnd;

  const isNoBoardsYet = !briefList.length && !briefListLoading && isNoSearchFiltersYet;
  const isDisplayEmptyResults = !briefList.length && !briefListLoading && !isNoBoardsYet;

  const watchDemoClick = () => {
    setOpenWatchDemo(true);
  };

  const onPlayAction = () => {
    window.analytics.track('Play', {
      category: 'Videos',
      label: 'Campaign Demo Video',
      userId: userId,
    });
  };

  const onPauseAction = () => {
    window.analytics.track('Pause', {
      category: 'Videos',
      label: 'Campaign Demo Video',
      userId: userId,
    });
  };

  const onCloseAction = () => {
    setOpenWatchDemo(false);
    window.analytics.track('Closed', {
      category: 'Videos',
      label: 'Campaign Demo Video',
      userId: userId,
    });
  };

  useEffect(() => {
    if (currentOrganizationID) {
      dispatch(setBriefListTab('campaigns'));
      getBriefListsHandler();
      dispatch(getOrganizationUsers(currentOrganizationID));
    }
    return () => {
      dispatch(setBriefListTab('campaigns'));
      dispatch(clearBriefsList());
    };
  }, [currentOrganizationID]);

  useEffect(() => {
    if (currentOrganizationID) {
      getBriefListsHandler();
    }
  }, [filter]);

  const getBriefListsHandler = () => dispatch(getBriefList(currentOrganizationID, getParams(), () => history.push('/campaign/')));

  useEffect(() => {
    if (!isMobile) {
      dispatch(setDiscussionsIsExpanded(false));
    } else {
      dispatch(setDiscussionsIsExpanded(true));
    }
  }, []);

  const showCountFilters = () => {
    const countValues = { ...filter };
    delete countValues.page;
    delete countValues.sort;
    delete countValues.isArchived;

    let count = 0;

    for (const key in countValues) {
      switch (key) {
        case 'searchValue':
          if (countValues[key]) count++;
          break;
        case 'dateStart':
          if (countValues[key] !== null) count++;
          break;
        case 'memberSelectIndex':
          if (countValues[key] !== -1) count++;
          break;
        case 'statusSelectIndex':
          if (countValues[key] !== -1) count++;
          break;
        default: {
          // nothing
        }
      }
    }
    return count;
  };

  const countFilters = useMemo(() => showCountFilters(), [filter]);

  const onCreateCampaignClick = useCallback(
    (route, withAnalytic) => {
      if (
        currentPlan?.campaign_brief_create_limit > currentPlan?.usage?.campaign_brief_created ||
        currentPlan?.campaign_brief_create_limit === -1
      ) {
        if (withAnalytic) {
          window.analytics.track('campaign_brief_initiate', {
            category: 'Campaign Brief',
            userId: userId,
          });
        }
        history.push(route);
        return;
      }
      setShowPlanWarningModal(true);
    },
    [currentPlan?.campaign_brief_create_limit, currentPlan?.usage?.campaign_brief_created, history, userId]
  );

  return (
    <>
      <WatchDemoBriefsModal
        pageType="campaign"
        buttonText="New Campaign"
        buttonLink={'/campaign/create'}
        buttonAction={onCreateCampaignClick}
        open={openWatchDemo}
        onClose={() => onCloseAction()}
        onPause={onPauseAction}
        onPlay={onPlayAction}
      />
      <MainLayout title={<LayoutHeader title="Campaigns" />} contentClassName={styles.layout}>
        {!isNoBoardsYet && (
          <HeaderComponent
            textsConfig={headerTextsConfig}
            onCreateClick={onCreateCampaignClick}
            filterFunctions={filterFunctions}
            statusOptions={statusOptions}
            filter={filter}
            switcher={briefListTabSwitcher}
          />
        )}

        {(!!briefList.length || briefListLoading) && (
          <div className={styles.pageContent}>
            <BriefList
              page={filter.page}
              setPage={setPagePagination}
              sort={filter.sort}
              setSort={onSort}
              pageSize={pageSize}
              onArchive={getBriefListsHandler}
              isNoSearchFiltersYet={isNoSearchFiltersYet}
            />
          </div>
        )}

        {isDisplayEmptyResults && (
          <NoSearchResults
            buttonText="New Campaign"
            route="/campaign/create"
            trackAction={trackCampaignInitiate}
            onButtonClick={onCreateCampaignClick}
          />
        )}
        {isNoBoardsYet && <EmptyState isMobile={isTablet} openWatchDemoModal={watchDemoClick} onCreateClick={onCreateCampaignClick} />}
      </MainLayout>
      {!isNoBoardsYet && !showMobileSideBar && (
        <IconMobileSearch count={countFilters} setShowFiltersMobile={() => setShowFiltersMobile(!showFiltersMobile)} />
      )}
      {isMobile && (
        <FiltersMobile
          onClose={() => setShowFiltersMobile(false)}
          textsConfig={headerTextsConfig}
          filterFunctions={filterFunctions}
          statusOptions={statusOptions}
          filter={filter}
          switcher={briefListTabSwitcher}
          showFilters={showFiltersMobile}
        />
      )}
      <ErrorLimitModal
        isOpen={showPlanWarningModal}
        manualType={ERROR_TYPE.CAMPAIGN_BRIEF_ITEM_CREATE_LIMIT_ERROR}
        onClose={() => setShowPlanWarningModal(false)}
      />
    </>
  );
};

export default CampaignBriefList;
