import React, { ComponentProps, FC, useMemo } from 'react';
import { useAppSelector } from '../../../../../../../shared/hooks/reduxHooks';
import { Organization } from '../../../../../../../models/permissions/organization/Organization';
import { OrganizationPermission } from '../../../../../../../models/permissions/enum/OrganizationPermission';
import DeliverablePlatforms from '../../../../../ContentBoard/components/AddDeliverablesModal/components/AddDeliverablesPlatforms';

type PropsType = Omit<ComponentProps<typeof DeliverablePlatforms>, 'canEditDueDates' | 'canEditNotifications'>;

const TabAddDeliverables: FC<PropsType> = props => {
  const organizationModel = useAppSelector<Organization>(state => state.auth?.currentOrganizationModel || null);

  const canEditDates = useMemo(() => {
    if (!organizationModel) return false;

    return organizationModel.hasAccess(OrganizationPermission.CAN_SET_DELIVERABLES_DUE_DATE);
  }, [organizationModel]);

  return <DeliverablePlatforms {...props} canEditNotifications={canEditDates} canEditDueDates={canEditDates} />;
};

export default TabAddDeliverables;
