import React, { FC, PropsWithChildren, useEffect } from 'react';
import { OrganizationPermission } from '../../../models/permissions/enum/OrganizationPermission';
import { useAppSelector } from '../../../shared/hooks/reduxHooks';
import { useHistory } from 'react-router';

const CampaignRoutesMiddleware: FC<PropsWithChildren> = props => {
  const organizationModel = useAppSelector(state => state.auth?.currentOrganizationModel || null);
  const history = useHistory();

  useEffect(
    function redirectIfNotAllowed() {
      if (!organizationModel) return;

      const hasPermissionToViewPage = organizationModel.hasAccess(OrganizationPermission.CAN_SEE_CAMPAIGN_TAB);

      if (!hasPermissionToViewPage) {
        history.push('/content-approval');
      }
    },
    [organizationModel]
  );

  return organizationModel ? <>{props.children}</> : null;
};

export default CampaignRoutesMiddleware;
