import React from 'react';
import styles from './RouterLink.module.scss';
import cs from 'classnames';
import { useHistory } from 'react-router';

/**
 * @param {string} classname
 * @param {string} to
 * @constructor
 */
const RouterLink = ({ children, className, to, onClick }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(to);
    if (onClick) {
      onClick();
    }
  };
  return (
    <span className={cs(styles.root, className)} onClick={handleClick}>
      {children}
    </span>
  );
};

export default RouterLink;
