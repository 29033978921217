import React, {FC, useCallback, useEffect, useState} from 'react';
import styles from './ResultVisualCard.module.scss';
import useDownloadAsset from "../../../../shared/hooks/useDownloadAsset";
import VisualTileTooltipWrapper from "../../../../components/WhiteTooltips/VisualTileTooltipWrapper";
import VisualHover from "../../components/VisualHover";
import DownloadSVG from "../../../../expert/components/IconsSvg/DownloadSVG";
import {IResultAsset} from "../../../../models/entities/asset/ResultAsset";
import {isImage} from "../../components/results/utils";
import {deleteResultAsset, updateResultAsset} from "../../../../actions/ResultsActions/results.actions";
import {useAppDispatch, useThunkDispatch} from "../../../../shared/hooks/reduxHooks";
import {useParams} from "react-router-dom";
import {IFileData} from "../../hooks/useFileUpload";
import useFileActionHandlers from "../../hooks/useFileActionHandlers";
import useCanEditResult from "../hooks/useCanEditResult";
import {ReactComponent as ReplaceIcon} from '../../../../assets/img/icons/replace.svg';
import cs from "classnames";
import {useMediaQuery} from "react-responsive/src";
import {VisualLoading} from "../../components/VisualLoading";
import classNames from "classnames";

type PropsType = { asset: IResultAsset; onHoverClick: (asset: IResultAsset) => void };


const ResultVisualCard: FC<PropsType> = props => {
  const canEdit = useCanEditResult();
  const dispatch = useAppDispatch();
  const thunkDispatch = useThunkDispatch();
  const [isReplaceLoading, setIsReplaceLoading] = useState<boolean>(false)
  const params = useParams() as { boardId: string };
  const {isAssetLoading, downloadAsset} = useDownloadAsset();
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

  const onDownload = useCallback(() => {
    if (isAssetLoading || !props.asset.location) return;
    downloadAsset(props.asset.location, props.asset.extension);
  }, [props.asset, isAssetLoading]);

  const onDelete = useCallback(
    () => {
      if (!params.boardId) return;
      dispatch(deleteResultAsset(params.boardId, props.asset))
    },
    [params.boardId, props.asset],
  );

  const onReplace = (fileData: IFileData | null) => {
    if (!fileData) return;
    setIsReplaceLoading(true);
    thunkDispatch(updateResultAsset(params.boardId, props.asset.id, {file: fileData.file})).finally(() => {
      setIsReplaceLoading(false);
    })
  }

  const {renderInput, onFileUpload} = useFileActionHandlers(onReplace);

  const src = props.asset.location || props.asset.preview_location || props.asset.thumbnail_location || '';

  const onHoverClick = useCallback(() => props.onHoverClick(props.asset), [props.asset, props.onHoverClick]);

  return (
    <VisualTileTooltipWrapper
      isGreenTag={true}
      isEditingMode={canEdit}
      asset={props.asset}
      placement={isTablet ? 'bottom' : 'right'}
      onDelete={onDelete}
      onReplace={onFileUpload}
      isDisabled={isReplaceLoading}
    >
      <div className={classNames(styles.root, {[styles.disabledHover]: isReplaceLoading})}>
        {renderInput()}
        <VisualHover onClick={onHoverClick} className={styles.visualHover}/>
        <VisualLoading open={isReplaceLoading} />
        {canEdit ?
          <button className={cs(styles.absoluteButton, styles.replace)} onClick={onFileUpload}>
            <ReplaceIcon className={styles.replaceIcon}/>
          </button>
          : null
        }
        <button className={cs(styles.absoluteButton, styles.download)} onClick={onDownload}>
          <DownloadSVG className={styles.downloadIcon}/>
        </button>
        {isImage(props.asset.mime_type) ?
          <img className={styles.media} src={src} alt={''}/>
          : <video src={src} className={styles.media} autoPlay controls={false} loop muted/>
        }
      </div>
    </VisualTileTooltipWrapper>
  );
};

export default ResultVisualCard;
