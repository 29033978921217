import { IBoardAsset } from '../BoardAsset';
import { IBoardAssetDTO } from '../dto/BoardAsset';

export class BoardAssetHydrator implements IBoardAsset {
  caption: null | string;
  content_board_deliverable_id: number;
  content_board_id: number;
  duration: null | number;
  extension: string;
  file_id: number;
  filename: string;
  id: number;
  location: string;
  mime_type: string;
  preview_location: string;
  size: string;
  thumbnail_location: string;

  constructor(dto: IBoardAssetDTO) {
    this.caption = dto.caption;
    this.content_board_deliverable_id = dto.content_board_deliverable_id;
    this.content_board_id = dto.content_board_id;
    this.duration = dto.duration;
    this.extension = dto.extension;
    this.file_id = dto.file_id;
    this.filename = dto.filename;
    this.id = dto.id;
    this.location = dto.location;
    this.mime_type = dto.mime_type;
    this.preview_location = dto.preview_location;
    this.size = dto.size;
    this.thumbnail_location = dto.thumbnail_location;
  }
}
