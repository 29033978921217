import { combineReducers } from 'redux';
import { AUTH_TYPES } from '../actions/auth.actions';
import authReducer from './auth.reducer';
import configReducer from './config.reducer';
import adIntegrationReducer from './ad_integration.reducer';
import creativeStudioReducer from './creativeStudio.reducer';
import audienceReducer from './audience.reducer';
import testsReducer from './tests.reducer';
import userReducer from './user.reducer';
import usersReducer from './users.reducer';
import organizationsReducer from './organizations.reducer';
import assetsReducer from './assets.reducer';
import invoicesReducer from './invoices.reducer';
import adminReducer from './admin.reducer';
import adsReducer from './ads.reducer';
import adSetsReducer from './adSets.reducer';
import paymentReducer from './payment.reducer';
import stripeReducer from './stripe.reducer';
import visualAssetsReducer from './visualAssets.reducer';
import tagsReducer from './tags.reducer';
import templatesetsReducer from './templatesets.reducer';
import creativeTemplatesReducer from './creativeTemplates.reducer';
import generateCreativesReducer from './generateCreatives.reducer';
import externalPhotosReducer from './externalPhotos.reducer';
import sidebarMenuReducer from './sidebarMenu.reducer';
import graphicAssetsReducer from './graphicAssets.reducer';
import createAudienceReducer from './createAudience.reducer';
import contentApprovalReducer from './contentApproval.reducer';
import contentBoardReducer from './contentBoard.reducer';
import deliverablesReducer from './deliverables.reducer';
import briefReducer from './brief.reducer';
import discussionReducer from './discussion.reducer';
import centrifugeReducer from './centrifuge.reducer';
import toastNotificationReducer from './toastNotification.reducer';
import notificationsReducer from './notifications.reducer';
import boardBriefReducer from './boardBrief.reducer';
import resultsReducer from "./results.reducer";

const appReducer = combineReducers({
  boardBrief: boardBriefReducer,
  auth: authReducer,
  config: configReducer,
  ad_integration: adIntegrationReducer,
  creativeStudio: creativeStudioReducer,
  audience: audienceReducer,
  tests: testsReducer,
  ads: adsReducer,
  adSets: adSetsReducer,
  user: userReducer,
  organizations: organizationsReducer,
  assets: assetsReducer,
  invoices: invoicesReducer,
  admin: adminReducer,
  users: usersReducer,
  payment: paymentReducer,
  stripe: stripeReducer,
  visualAssets: visualAssetsReducer,
  tags: tagsReducer,
  templatesets: templatesetsReducer,
  creativeTemplates: creativeTemplatesReducer,
  generateCreative: generateCreativesReducer,
  externalPhotos: externalPhotosReducer,
  sidebarMenu: sidebarMenuReducer,
  graphicAssets: graphicAssetsReducer,
  createAudience: createAudienceReducer,
  brief: briefReducer,
  contentApproval: contentApprovalReducer,
  contentBoard: contentBoardReducer,
  deliverables: deliverablesReducer,
  discussion: discussionReducer,
  centrifuge: centrifugeReducer,
  toastNotification: toastNotificationReducer,
  notifications: notificationsReducer,
  results: resultsReducer,
});

const rootReducer = (state, action) => {
  // when a sign out action is dispatched it will reset redux state
  if (action.type === AUTH_TYPES.SIGN_OUT.SUCCESS) {
    state = {};
  }
  if (action.payload && action.payload.isAxiosError && action.payload.response.data.status === 401) {
    localStorage.removeItem('OTTERFISH_ACCESS_TOKEN');
    localStorage.removeItem('OTTERFISH_USER_EMAIL');
    localStorage.removeItem('OTTERFISH_CURRENT_ORGANIZATION');
    state = {
      auth: {
        ...state.auth,
        token: null,
        sendUserData: {},
        failure: { ...action.payload.response.data, message: 'Your session has expired. Please sign in again.' },
      },
    };
  }
  return appReducer(state, action);
};

export default rootReducer;
