import React, { FC, ReactElement, ReactNode, useCallback } from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  MouseSensorOptions,
  TouchSensor,
  TouchSensorOptions,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

type DraggableContextProps = {
  onDragStart: (event: DragStartEvent) => unknown | void;
  onDragEnd: (event: DragEndEvent) => unknown | void;
  children: ReactNode | ReactElement;
  withOverlay?: boolean;
  renderOverlayChildren?: (...unknown) => ReactNode | ReactElement;
  mouseSensorOptions?: MouseSensorOptions;
  touchSensorOptions?: TouchSensorOptions;
};

const DraggableContext: FC<DraggableContextProps> = ({
  onDragEnd,
  onDragStart,
  children,
  withOverlay,
  renderOverlayChildren,
  mouseSensorOptions,
  touchSensorOptions,
}) => {
  const sensors = useSensors(
    useSensor(MouseSensor, mouseSensorOptions),
    useSensor(TouchSensor, touchSensorOptions),
    useSensor(KeyboardSensor)
  );

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      onDragStart(event);
    },
    [onDragStart]
  );

  const handlerDragEnd = useCallback(
    (event: DragEndEvent) => {
      onDragEnd(event);
    },
    [onDragEnd]
  );

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={handlerDragEnd}>
      {children}
      {withOverlay && renderOverlayChildren && <DragOverlay style={{ transformOrigin: '0 0 ' }}>{renderOverlayChildren()}</DragOverlay>}
    </DndContext>
  );
};

export default DraggableContext;
