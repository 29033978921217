import React, { FC, ReactElement, ReactNode } from 'react';
import LoadingContainer from '../../../../../../../../../components/LoadingContainer';

type PropsType = {
  children?: JSX.Element | ReactElement | ReactNode | string | null;
  isLoading?: boolean;
};

const TitleLoaderWrapper: FC<PropsType> = ({ children, isLoading }) => {
  return <>{isLoading ? <LoadingContainer height="16px" width="80px" borderRadius="3px" /> : children}</>;
};

export default TitleLoaderWrapper;
