import React, { FC, memo } from 'react';
import styles from './TabBrandDetails.module.scss';
import CheckboxButton from '../../../../../../CampaignBrief/components/CheckboxButton';
import EditorStyledField from '../../../../../../../components/EditorStyledField';
import { areEqualFormikState } from '../../../../../../../shared/utils/typescriptHelpers';
import { FormikProps } from 'formik';
import useFormikDebouncedState from '../../../../../../../shared/hooks/useFormikDebouncedState';

type PropsType = {
  formik: FormikProps<any>;
};

const propsAreEqual: Parameters<typeof memo<FC<PropsType>>>[1] = (prevProps, nextProps) => {
  return areEqualFormikState(
    ['brandBackground', 'showBrandProvidesProduct', 'brandProvidesProduct', 'notes'],
    prevProps.formik,
    nextProps.formik
  );
};

const TabBrandDetails = memo(({ formik }: PropsType) => {
  const [brandBackground, setBrandBackground] = useFormikDebouncedState<string>(
    (value: string) => formik.setFieldValue('brandBackground', value),
    formik.values.brandBackground
  );

  const [brandProvidesProduct, setBrandProvidesProduct] = useFormikDebouncedState<string>(
    (value: string) => formik.setFieldValue('brandProvidesProduct', value),
    formik.values.brandProvidesProduct
  );

  const [notes, setNotes] = useFormikDebouncedState<string>((value: string) => formik.setFieldValue('notes', value), formik.values.notes);

  const setBrandProduct = () => {
    formik.setFieldValue('showBrandProvidesProduct', true);
  };

  const removeBrandProduct = () => {
    formik.setFieldValue('showBrandProvidesProduct', false);
  };

  return (
    <>
      <div>
        <p>Brand background - Set the stage. Give creators some colour around your product or brand and what you want to be known for.</p>
        <EditorStyledField
          toolbarClassName={styles.editorToolbar}
          wrapperClassName={styles.editorWrapperClassName}
          editorClassName={styles.editorClassName}
          initialValue={brandBackground}
          onChange={text => setBrandBackground(text)}
          placeholder={'Tip: Use Ctrl + Shift + V on windows or Cmd + Shift + V to paste your text with formatting'}
          // @ts-ignore
          error={formik.errors.brandBackground}
        />
      </div>
      <div>
        <h3 style={{ margin: 0 }}>Do you want to send your product to Creators</h3>
        <div className={styles.checkboxBlock}>
          {/* @ts-ignore */}
          <CheckboxButton onClick={setBrandProduct} isActive={formik.values.showBrandProvidesProduct} item={'Yes'} />
          {/* @ts-ignore */}
          <CheckboxButton onClick={removeBrandProduct} isActive={!formik.values.showBrandProvidesProduct} item={'No'} />
        </div>
        {formik.values.showBrandProvidesProduct && (
          <div style={{ marginTop: 10 }}>
            <EditorStyledField
              toolbarClassName={styles.editorToolbar}
              wrapperClassName={styles.editorWrapperClassName}
              editorClassName={styles.editorClassName}
              initialValue={brandProvidesProduct}
              onChange={text => setBrandProvidesProduct(text)}
              placeholder={
                'Please describe what you would like to send each creator, how they will receive it, how much it is' +
                ' worth at retail, and if you will need it back.'
              }
              // @ts-ignore
              error={formik.errors.brandProvidesProduct}
            />
          </div>
        )}
      </div>
      <div>
        <h3>Brand guidelines and Mandatories</h3>
        <p>Any do's and dont's to think about that is relevant to your campaign.</p>
        <EditorStyledField
          toolbarClassName={styles.editorToolbar}
          wrapperClassName={styles.editorWrapperClassName}
          editorClassName={styles.editorClassName}
          initialValue={notes}
          onChange={text => setNotes(text)}
          onBlur={formik.handleBlur}
          placeholder={'Tip: Use Ctrl + Shift + V on windows or Cmd + Shift + V to paste your text with formatting'}
          // @ts-ignore
          error={formik.errors.notes}
        />
      </div>
    </>
  );
}, propsAreEqual);

export default TabBrandDetails;
