import { MutableRefObject, useCallback, useEffect, useState } from 'react';

const useAnchorLinks = <ItemsType extends { id: string | number }>(
  items: ItemsType[],
  listRef: MutableRefObject<HTMLElement | null>,
  settings?: { disabled?: boolean }
) => {
  const [activeId, setActiveId] = useState<ItemsType['id']>(items[0].id);

  const changeActiveId = useCallback(
    (id: ItemsType['id']) => {
      if (!listRef.current) return;
      const element = document.getElementById(String(id));
      if (!element) return;
      const top = element.offsetTop - listRef.current?.offsetTop + 1;

      listRef.current?.scrollTo({
        top,
        behavior: 'smooth',
      });
    },
    [listRef]
  );

  const handleScroll = () => {
    if (!listRef.current) return;

    if (listRef.current?.scrollTop === 0) {
      setActiveId(items[0].id);
      return;
    }

    const listScroll = listRef.current?.scrollTop;
    const turningPointPositionInListView = listRef.current?.offsetTop;

    let minTop = Infinity;
    let resultId: ItemsType['id'] = items[0].id;

    for (const item of items) {
      const element = document.getElementById(String(item.id));
      if (!element) continue;

      const elementTopRelativeToParent = element.offsetTop - turningPointPositionInListView + element.offsetHeight;

      if (elementTopRelativeToParent >= listScroll && elementTopRelativeToParent < minTop) {
        resultId = item.id;
        minTop = elementTopRelativeToParent;
      }
    }

    setActiveId(resultId);
  };

  useEffect(() => {
    if (!listRef?.current) return;

    if (settings?.disabled) {
      listRef.current?.removeEventListener('scroll', handleScroll);
      return;
    }

    listRef.current.addEventListener('scroll', handleScroll);

    return () => {
      listRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [listRef.current, handleScroll, items, settings?.disabled]);

  return {
    activeId,
    changeActiveId,
  };
};

export default useAnchorLinks;
