import React, { ComponentProps, FC, ReactNode, useMemo, useState } from 'react';
import BlurBlockBackground from '../../../../components/BlurBlockBackground';
import ConfirmPopup from '../../../../components/ConfirmPopup';
import ConfirmDeleteVisualAssetModal from '../../../../components/Modals/ConfirmDeleteVisualAssetModal';
import { AssetStateType } from '../../../../models/entities/asset/AssetState';
import { ICreatedAsset } from '../../../../models/entities/asset/createdAsset';
import { IUpdatedAsset } from '../../../../models/entities/asset/UpdatedAsset';

type PropsType = {
  openConfirmModal: boolean;
  confirmDeleteText?: string;
  onSubmitDelete: ComponentProps<typeof ConfirmPopup>['buttonsConfig']['secondaryButton']['onClick'];
  setOpenConfirmModal: (value: boolean) => void;
};

type UseVisualCardHandlers = (
  onDelete: (asset: AssetStateType) => void,
  onUpdate: (asset: AssetStateType) => void,
  asset: AssetStateType,
  onCreate?: () => void,
  confirmDeleteText?: string,
  isBlurModal?: boolean
) => {
  deleteConfirmModal: JSX.Element | ReactNode;
  isConfirmOpened: boolean;
  handleUpdate: (data: ICreatedAsset | AssetStateType['caption']) => void;
  handleDelete: () => void;
  isImageDisplay: boolean;
};

const DeleteConfirmModal: FC<PropsType> = props => {
  return (
    <>
      {props.openConfirmModal && (
        <BlurBlockBackground>
          <ConfirmPopup
            withoutWrapper
            text={props.confirmDeleteText || ''}
            buttonsConfig={{
              primaryButton: {
                text: 'Yes, delete',
                onClick: props.onSubmitDelete,
              },
              secondaryButton: {
                text: 'Discard',
                onClick: () => props.setOpenConfirmModal(false),
              },
            }}
          />
        </BlurBlockBackground>
      )}
    </>
  );
};

const useVisualCardHandlers: UseVisualCardHandlers = (onDelete, onUpdate, asset, onCreate, confirmDeleteText, isBlurModal = true) => {
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  const handleUpdate = (data: ICreatedAsset | AssetStateType['caption']): void => {
    if (!asset) return;

    if (!data || typeof data === 'string') {
      onUpdate({
        ...asset,
        caption: !!data ? data : null,
      });
      return;
    }

    if (typeof asset.id === 'number' && asset.display_order) {
      const updateData: IUpdatedAsset = {
        ...data,
        id: asset.id,
        caption: asset.caption || null,
        display_order: asset.display_order,
      };
      onUpdate(updateData);
      return;
    }

    const updateData: ICreatedAsset = {
      ...data,
      id: asset.id,
      caption: asset.caption || null,
    };

    onUpdate(updateData);
  };

  const handleDelete = (): void => {
    setOpenConfirmModal(true);
  };

  const onSubmitDelete = (): void => {
    setOpenConfirmModal(false);
    onDelete(asset);
  };

  const isImageDisplay: boolean = useMemo(() => {
    let type: string | null = null;
    if ('mime_type' in asset) {
      type = asset.mime_type.match(/^\w*/g)?.[0] || null;
    }

    return (
      type === 'image' ||
      ('isImagePreview' in asset && asset.isImagePreview) ||
      (type === 'video' && 'thumbnail_location' in asset && !!asset?.thumbnail_location)
    );
  }, [asset]);

  return {
    deleteConfirmModal: isBlurModal ? (
      <DeleteConfirmModal
        openConfirmModal={openConfirmModal}
        confirmDeleteText={confirmDeleteText}
        onSubmitDelete={onSubmitDelete}
        setOpenConfirmModal={setOpenConfirmModal}
      />
    ) : (
      <ConfirmDeleteVisualAssetModal
        open={openConfirmModal}
        onSubmit={onSubmitDelete}
        onClose={() => setOpenConfirmModal(false)}
        text={`Are you sure you want to delete the visual?`}
      />
    ),
    isConfirmOpened: openConfirmModal,
    handleUpdate,
    handleDelete,
    isImageDisplay,
  };
};

export default useVisualCardHandlers;
