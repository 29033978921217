import { useCallback, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
export enum HTTP_METHODS_TYPE {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELTE = 'delete',
}

const useHttp = <RequestFn extends (...args: any[]) => Promise<any>, ParseResultFn extends (data: Awaited<ReturnType<RequestFn>>) => any>(
  callback: RequestFn,
  settings: {
    method: HTTP_METHODS_TYPE;
    args?: Parameters<RequestFn> | null;
    parseResultFn?: ParseResultFn;
    afterRequestCallback?: () => void;
    onError?: (e?: Error) => void;
  } = { method: HTTP_METHODS_TYPE.GET }
): {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  error: Error | null;
  request: (...args: Parameters<RequestFn>) => Promise<void>;
  clearError: () => void;
  clearSuccess: () => void;
  data: (typeof settings.parseResultFn extends undefined ? Awaited<ReturnType<RequestFn>> : ReturnType<ParseResultFn>) | null;
  isFirstRequestStarted: boolean;
} => {
  const [isFirstRequestStarted, setIsFirstRequestStarted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [lastArgs, setLastArgs] = useState<Parameters<RequestFn> | null | undefined>(settings.args);
  const [data, setData] = useState<
    (typeof settings.parseResultFn extends undefined ? Awaited<ReturnType<RequestFn>> : ReturnType<ParseResultFn>) | null
  >(null);

  useEffect(() => {
    if (!isEqual(settings.args, lastArgs)) {
      setLastArgs(settings.args);
    }
  }, [settings.args]);

  const handleLoading = () => {
    setError(null);
    setIsError(false);
    setIsSuccess(false);
    setIsFirstRequestStarted(true);
    setIsLoading(true);
  };

  const handleError = (e: unknown) => {
    if (e instanceof Error) {
      console.error(e);
      setIsError(true);
      setError(e);
      setIsSuccess(false);
      setIsLoading(false);

      if (settings.onError) {
        settings.onError(e);
      }
    }
  };

  const handleSuccess = () => {
    setIsLoading(false);
    setIsSuccess(true);
    if (settings.afterRequestCallback) {
      settings.afterRequestCallback();
    }
  };

  useEffect(
    function get() {
      if (settings.method === HTTP_METHODS_TYPE.GET && lastArgs !== null && !isLoading && data === null) {
        handleLoading();

        const argsToRequest = lastArgs === undefined ? [] : lastArgs;

        callback(...argsToRequest)
          .then(res => {
            handleSuccess();
            if (!settings?.parseResultFn) {
              setData(res?.data);
              return;
            }

            const newData = settings.parseResultFn(res?.data);
            setData(newData);
          })
          .catch(e => {
            handleError(e);
          });
      }
    },
    [lastArgs]
  );

  const request = useCallback(
    async (...args: Parameters<RequestFn>) => {
      handleLoading();
      try {
        const response = await callback(...args);

        handleSuccess();
        if (!settings.parseResultFn) {
          setData(response?.data);
          return;
        }

        const newData = settings.parseResultFn(response?.data);
        setData(newData);

        return newData;
      } catch (e) {
        handleError(e);
      }
    },
    [settings.afterRequestCallback, settings.onError]
  );

  const clearError = useCallback(() => {
    setError(null);
    setIsError(false);
  }, []);

  const clearSuccess = useCallback(() => {
    setIsSuccess(false);
  }, []);

  return { request, clearError, isLoading, isError, error, data, isSuccess, clearSuccess, isFirstRequestStarted };
};

export default useHttp;
