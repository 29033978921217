import { FormikProps } from 'formik';
import { isEqual } from 'lodash';

export const areEqualFormikState = (fields: string[], oldFormikState: FormikProps<any>, newFormikState: FormikProps<any>) => {
  return fields.every(field => {
    return (
      isEqual(oldFormikState.values[field], newFormikState.values[field]) &&
      isEqual(oldFormikState.touched[field], newFormikState.touched[field]) &&
      isEqual(oldFormikState.errors[field], newFormikState.errors[field])
    );
  });
};
