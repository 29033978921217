import cs from 'classnames';
import styles from './OrganizationDropdown.module.scss';
import ButtonCustom from '../../expert/components/Buttons/ButtonCustom';
import { ReactComponent as CogIcon } from '../../assets/img/icons/cog.svg';
import React, { useRef, useState } from 'react';
import { useClickOutside } from '../../shared/hooks/useClickOutside';
import { ReactComponent as ChevroneDownIcon } from '../../assets/img/icons/chevroneDown.svg';
import { ReactComponent as WarnIcon } from '../../assets/img/icons/warning_triangle_yellow.svg';
import { ReactComponent as PurpleStar } from '../../assets/img/icons/purple_star.svg';
import PlusSVG from '../../expert/components/IconsSvg/PlusSVG';
import { useHistory } from 'react-router-dom';
import SearchBar from '../SearchBar';
import { useSelector } from 'react-redux';
import { MEMBER_ROLE_IN_ORGANIZATION_TYPES } from '../../models/enums/MemberRoleInOrganizationTypes';

const OrganizationDropdown = ({
  className,
  ignoreRef,
  handleConfig,
  onSwitchOrg,
  currentOrganization,
  organizations,
  isAdmin,
  onClose,
}) => {
  const history = useHistory();
  const containerRef = useRef(null);
  const plan = useSelector(state => state.auth.currentOrganizationPlan);
  const [searchValue, setSearchValue] = useState('');
  const [middleSearch, setMiddleSearch] = useState('');
  const user = useSelector(state => state.auth.user);
  const isFreePlan = plan?.is_free;

  useClickOutside(containerRef, ignoreRef, () => {
    onClose();
  });

  const OrganizationItem = ({ organization }) => {
    const canSwitch =
      (user?.has_valid_subscription || organization?.role === MEMBER_ROLE_IN_ORGANIZATION_TYPES.OWNER) &&
      currentOrganization?.organization_id !== organization.organization_id;

    return (
      <div className={styles.itemWrapper} key={organization.organization_id}>
        <div className={styles.item}>
          <div className={cs(styles.nameLine, { [styles.width100]: !canSwitch })}>
            {organization?.role === MEMBER_ROLE_IN_ORGANIZATION_TYPES.OWNER && <PurpleStar />}
            <div className={styles.name}>{organization.organization_name || organization.name}</div>
          </div>
          {canSwitch && (
            <div className={styles.switchTo} onClick={() => onSwitchOrg(organization)}>
              <span>Switch to</span> <ChevroneDownIcon />
            </div>
          )}
        </div>
        {!user?.has_valid_subscription && (
          <div className={styles.warnMessage}>
            <WarnIcon />
            <span>Subscription cancelled</span>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={cs(styles.organizationExpander, className)} ref={containerRef}>
      {currentOrganization && (
        <div className={styles.top}>
          <div className={styles.orgHeader}>
            <div className={styles.currentOrganization}>
              <div className={styles.nameLine}>
                {currentOrganization?.role === MEMBER_ROLE_IN_ORGANIZATION_TYPES.OWNER && <PurpleStar />}
                <div className={styles.title}>{currentOrganization?.organization_name}</div>
              </div>
              <div className={styles.description}>Current organization</div>
            </div>
            {currentOrganization?.role === MEMBER_ROLE_IN_ORGANIZATION_TYPES.OWNER ? (
              <ButtonCustom Icon={CogIcon} outline className={styles.configBtn} onClick={handleConfig} />
            ) : (
              <div className={styles.memberView} onClick={handleConfig}>
                <span>View team</span>
                <ChevroneDownIcon />
              </div>
            )}
          </div>
          {organizations?.length > 1 && (
            <SearchBar
              searchValue={middleSearch}
              onChangeSearchValue={value => setMiddleSearch(value)}
              onSubmit={() => setSearchValue(middleSearch)}
              onClear={() => {
                setSearchValue('');
                setMiddleSearch('');
              }}
            />
          )}
        </div>
      )}
      {organizations?.length > 1 && (
        <div className={styles.organizationList}>
          {organizations
            .filter(item => item.organization_name.toLowerCase().includes(searchValue.toLowerCase()))
            .map(org => (
              <OrganizationItem organization={org} />
            ))}
        </div>
      )}
      <div className={styles.footer}>
        {!currentOrganization && !organizations.length && <div className={styles.text}>You are not a part of any organization</div>}
        <ButtonCustom Icon={PlusSVG} onClick={() => history.push('/create-organization')} disabled={isFreePlan}>
          Create organization
        </ButtonCustom>
        {isAdmin && <ButtonCustom className={styles.manageOrgsBtn}>Manage organizations</ButtonCustom>}
      </div>
    </div>
  );
};

export default OrganizationDropdown;
