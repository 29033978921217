import { EmptyObject } from 'redux';
import { NotificationsConfigType, TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES } from '../../models/Notifications';

const DEFAULT_DELAY_TIME = 5000;

export enum TOAST_NOTIFICATION_TYPES {
  // Deliverable
  SAVE_CHANGES = 'SAVE_CHANGES',
  REMOVE_DELIVERABLE_SECTION = 'REMOVE_DELIVERABLE_SECTION',
  DELIVERABLE_VERSION_ALREADY_CHANGED_ERROR = 'DELIVERABLE_VERSION_ALREADY_CHANGED_ERROR',
  ERROR_ON_SAVE_DELIVERABLE_ASSET = 'ERROR_ON_SAVE_DELIVERABLE_ASSET',
  DELIVERABLE_GENERATE_AI_ERROR = 'DELIVERABLE_GENERATE_AI_ERROR',

  // Content Board
  DELETE_DELIVERABLE_ITEM = 'DELETE_DELIVERABLE_ITEM',
  DUPLICATE_DELIVERABLE_ITEM = 'DUPLICATE_DELIVERABLE_ITEM',
  ERROR_ON_CREATE_DELIVERABLES = 'ERROR_ON_CREATE_DELIVERABLES',
  DELIVERABLES_CREATED = 'DELIVERABLES_CREATED',
  SHARED_LINK_COPIED_SUCCESS = 'SHARED_LINK_COPIED_SUCCESS',
  SHARED_LINK_COPIED_FAILED = 'SHARED_LINK_COPIED_FAILED',
  EMAIL_WITH_SHARED_LINK_SEND_SUCCESS = 'EMAIL_WITH_SHARED_LINK_SEND_SUCCESS',
  EMAIL_WITH_SHARED_LINK_SEND_FAILED = 'EMAIL_WITH_SHARED_LINK_SEND_FAILED',
  BOARD_CHANGES_SAVE_SUCCESS = 'BOARD_CHANGES_SAVE_SUCCESS',
  BOARD_CHANGES_SAVE_ERROR = 'BOARD_CHANGES_SAVE_ERROR',
  CONTRACT_UPLOAD_SUCCESS = 'CONTRACT_UPLOAD_SUCCESS',
  CONTRACT_REPLACE_SUCCESS = 'CONTRACT_REPLACE_SUCCESS',
  CONTRACT_DELETE_SUCCESS = 'CONTRACT_DELETE_SUCCESS',
  BOARD_BRIEF_UPLOAD_SUCCESS = 'BOARD_BRIEF_UPLOAD_SUCCESS',
  BOARD_BRIEF_REPLACE_SUCCESS = 'BOARD_BRIEF_REPLACE_SUCCESS',
  BOARD_BRIEF_DELETE_SUCCESS = 'BOARD_BRIEF_DELETE_SUCCESS',
  INVITE_COLLABORATOR_ERROR = 'INVITE_COLLABORATOR_ERROR',

  // Content-approval
  DUPLICATE_BOARD_FAILED = 'DUPLICATE_BOARD_FAILED',
  DELETE_BOARD_FAILED = 'DELETE_BOARD_FAILED',
  DELTE_BOARD_SUCCESS = 'DELTE_BOARD_SUCCESS',

  // all
  DOWNLOAD_ALL_ASSETS_SUCCESS = 'DOWNLOAD_ALL_ASSETS_SUCCESS',
  DOWNLOAD_ALL_ASSETS_PARTIALLY_FAILED = 'DOWNLOAD_ALL_ASSETS_PARTIALLY_FAILED',
  DOWNLOAD_ALL_ASSETS_FAILED = 'DOWNLOAD_ALL_ASSETS_FAILED',

  // ORGANIZATIONS
  MEMBER_ROLE_CHANGING_SUCCESS = 'MEMBER_ROLE_CHANGING_SUCCESS',
  MEMBER_ROLE_CHANGING_ERROR = 'MEMBER_ROLE_CHANGING_ERROR',
  PRICING_PLAN_EXTRA_STORAGE_UPDATE_SUCCESS = 'PRICING_PLAN_EXTRA_STORAGE_UPDATE_SUCCESS',
  PRICING_PLAN_EXTRA_STORAGE_UPDATE_ERROR = 'PRICING_PLAN_EXTRA_STORAGE_UPDATE_ERROR',

  // NotificationPreferences
  UPDATE_NOTIFICATION_PREFERENCES_ERROR = 'UPDATE_NOTIFICATION_PREFERENCES_ERROR',

  // FILES
  FILE_TYPE_ERROR = 'FILE_TYPE_ERROR',
  FILE_TYPE_ERROR_WITH_CUSTOM_MESSAGE = 'FILE_TYPE_ERROR_WITH_CUSTOM_MESSAGE',
  FILE_TYPE_ERROR_BRIEF_FILE = 'FILE_TYPE_ERROR_BRIEF_FILE',
  FILE_TYPE_ERROR_CONTRACT_FILE = 'FILE_TYPE_ERROR_CONTRACT_FILE',

  // RESULT
  RESULT_ASSET_UPLOAD_SUCCESS='RESULT_ASSET_UPLOAD_SUCCESS'
  //   Test
  //   TEST='TEST'
}

export type ToastNotificationSettingsType = {
  // Deliverable
  [TOAST_NOTIFICATION_TYPES.SAVE_CHANGES]: EmptyObject;
  [TOAST_NOTIFICATION_TYPES.REMOVE_DELIVERABLE_SECTION]: EmptyObject;
  [TOAST_NOTIFICATION_TYPES.DELIVERABLE_VERSION_ALREADY_CHANGED_ERROR]: EmptyObject;
  [TOAST_NOTIFICATION_TYPES.ERROR_ON_SAVE_DELIVERABLE_ASSET]: { text: [string] };
  [TOAST_NOTIFICATION_TYPES.DELIVERABLE_GENERATE_AI_ERROR]: { text: [string] };

  // Content Board
  [TOAST_NOTIFICATION_TYPES.DELETE_DELIVERABLE_ITEM]: EmptyObject;
  [TOAST_NOTIFICATION_TYPES.DUPLICATE_DELIVERABLE_ITEM]: EmptyObject;
  [TOAST_NOTIFICATION_TYPES.ERROR_ON_CREATE_DELIVERABLES]: EmptyObject;
  [TOAST_NOTIFICATION_TYPES.DELIVERABLES_CREATED]: EmptyObject;
  [TOAST_NOTIFICATION_TYPES.SHARED_LINK_COPIED_SUCCESS]: EmptyObject;
  [TOAST_NOTIFICATION_TYPES.SHARED_LINK_COPIED_FAILED]: EmptyObject;
  [TOAST_NOTIFICATION_TYPES.EMAIL_WITH_SHARED_LINK_SEND_SUCCESS]: EmptyObject;
  [TOAST_NOTIFICATION_TYPES.EMAIL_WITH_SHARED_LINK_SEND_FAILED]: EmptyObject;
  [TOAST_NOTIFICATION_TYPES.CONTRACT_UPLOAD_SUCCESS]: EmptyObject;
  [TOAST_NOTIFICATION_TYPES.CONTRACT_REPLACE_SUCCESS]: EmptyObject;
  [TOAST_NOTIFICATION_TYPES.CONTRACT_DELETE_SUCCESS]: EmptyObject;
  [TOAST_NOTIFICATION_TYPES.BOARD_BRIEF_UPLOAD_SUCCESS]: EmptyObject;
  [TOAST_NOTIFICATION_TYPES.BOARD_BRIEF_REPLACE_SUCCESS]: EmptyObject;
  [TOAST_NOTIFICATION_TYPES.BOARD_BRIEF_DELETE_SUCCESS]: EmptyObject;
  [TOAST_NOTIFICATION_TYPES.INVITE_COLLABORATOR_ERROR]: { text: [string] };
  // All
  [TOAST_NOTIFICATION_TYPES.DOWNLOAD_ALL_ASSETS_SUCCESS]: EmptyObject;
  [TOAST_NOTIFICATION_TYPES.DOWNLOAD_ALL_ASSETS_PARTIALLY_FAILED]: EmptyObject;
  [TOAST_NOTIFICATION_TYPES.DOWNLOAD_ALL_ASSETS_FAILED]: EmptyObject;

  // Content-approval
  [TOAST_NOTIFICATION_TYPES.DUPLICATE_BOARD_FAILED]: EmptyObject;
  [TOAST_NOTIFICATION_TYPES.DELETE_BOARD_FAILED]: EmptyObject;
  [TOAST_NOTIFICATION_TYPES.DELTE_BOARD_SUCCESS]: EmptyObject;

  // ORGANIZATIONS
  [TOAST_NOTIFICATION_TYPES.MEMBER_ROLE_CHANGING_SUCCESS]: EmptyObject;
  [TOAST_NOTIFICATION_TYPES.MEMBER_ROLE_CHANGING_ERROR]: { text: [string] };
  [TOAST_NOTIFICATION_TYPES.PRICING_PLAN_EXTRA_STORAGE_UPDATE_SUCCESS]: EmptyObject;
  [TOAST_NOTIFICATION_TYPES.PRICING_PLAN_EXTRA_STORAGE_UPDATE_ERROR]: EmptyObject;

  // NotificationPreferences
  [TOAST_NOTIFICATION_TYPES.UPDATE_NOTIFICATION_PREFERENCES_ERROR]: EmptyObject;

  // FILES
  [TOAST_NOTIFICATION_TYPES.FILE_TYPE_ERROR]: { text: [string] };
  [TOAST_NOTIFICATION_TYPES.FILE_TYPE_ERROR_WITH_CUSTOM_MESSAGE]: { text: [string] };
  [TOAST_NOTIFICATION_TYPES.FILE_TYPE_ERROR_BRIEF_FILE]: { text: [string] };
  [TOAST_NOTIFICATION_TYPES.FILE_TYPE_ERROR_CONTRACT_FILE]: { text: [string] };

  // RESULTS
  [TOAST_NOTIFICATION_TYPES.RESULT_ASSET_UPLOAD_SUCCESS]: { text: [string]}

  //   TEST
  //   [TOAST_NOTIFICATION_TYPES.TEST]: {
  //     secondaryButtonData: {
  //       text: [string, number]
  //     },
  //     primaryButtonData: {
  //       text: [() => JSX.Element]
  //     }
  //   }
};
const notificationData: NotificationsConfigType = {
  // Deliverable
  [TOAST_NOTIFICATION_TYPES.SAVE_CHANGES]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.SUCCESS,
    title: 'Save',
    text: 'Your changes has been saved',
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.REMOVE_DELIVERABLE_SECTION]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.SUCCESS,
    title: 'Remove',
    text: 'Your changes has been removed',
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.DELIVERABLE_VERSION_ALREADY_CHANGED_ERROR]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.ERROR,
    title: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.ERROR,
    text: 'The version already has been changed',
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.ERROR_ON_SAVE_DELIVERABLE_ASSET]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.ERROR,
    title: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.ERROR,
    text: (errorMessage: string) => errorMessage,
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.DOWNLOAD_ALL_ASSETS_SUCCESS]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.SUCCESS,
    title: 'All assets have been successfully downloaded',
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.DOWNLOAD_ALL_ASSETS_PARTIALLY_FAILED]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.ERROR,
    title: 'Some of the assets could not be downloaded',
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.DOWNLOAD_ALL_ASSETS_FAILED]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.ERROR,
    title: 'Download failed ! Please try again',
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.DELIVERABLE_GENERATE_AI_ERROR]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.ERROR,
    title: 'Error',
    text: (errorMessage: string) => errorMessage,
    delay: DEFAULT_DELAY_TIME,
  },

  // Content Board
  [TOAST_NOTIFICATION_TYPES.DELETE_DELIVERABLE_ITEM]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.SUCCESS,
    title: 'Your deliverable was successfully deleted.',
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.DUPLICATE_DELIVERABLE_ITEM]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.SUCCESS,
    title: 'Your deliverable was successfully duplicated.',
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.ERROR_ON_CREATE_DELIVERABLES]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.ERROR,
    text: 'Something went wrong. Please try add your deliverables again.',
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.DELIVERABLES_CREATED]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.SUCCESS,
    text: 'Your deliverable(s) was successfully added.',
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.SHARED_LINK_COPIED_SUCCESS]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.SUCCESS,
    title: 'Link copied successfully.',
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.SHARED_LINK_COPIED_FAILED]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.ERROR,
    title: 'Link could not be copied.',
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.EMAIL_WITH_SHARED_LINK_SEND_SUCCESS]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.SUCCESS,
    title: 'The share link was sent successfully.',
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.EMAIL_WITH_SHARED_LINK_SEND_FAILED]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.ERROR,
    title: 'The share link was not sent',
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.BOARD_CHANGES_SAVE_SUCCESS]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.SUCCESS,
    title: 'Save',
    text: 'Your changes has been saved',
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.BOARD_CHANGES_SAVE_ERROR]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.ERROR,
    title: 'Error',
    text: 'Something went wrong, please try to do your changes again',
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.BOARD_BRIEF_REPLACE_SUCCESS]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.SUCCESS,
    title: 'Save',
    text: 'Your brief has been successfully replaced.',
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.BOARD_BRIEF_DELETE_SUCCESS]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.SUCCESS,
    title: 'Save',
    text: 'Your brief has been successfully deleted.',
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.BOARD_BRIEF_UPLOAD_SUCCESS]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.SUCCESS,
    title: 'Save',
    text: 'Your brief has been successfully uploaded.',
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.CONTRACT_REPLACE_SUCCESS]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.SUCCESS,
    title: 'Save',
    text: 'Your contract has been successfully replaced.',
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.CONTRACT_DELETE_SUCCESS]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.SUCCESS,
    title: 'Save',
    text: 'Your contract has been successfully deleted.',
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.CONTRACT_UPLOAD_SUCCESS]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.SUCCESS,
    title: 'Save',
    text: ' Your contract has been successfully uploaded.',
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.INVITE_COLLABORATOR_ERROR]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.ERROR,
    title: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.ERROR,
    text: (errorMessage: string) => errorMessage,
    delay: DEFAULT_DELAY_TIME,
  },

  // Content-approval
  [TOAST_NOTIFICATION_TYPES.DUPLICATE_BOARD_FAILED]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.ERROR,
    title: 'Something went wrong. Please try duplicating your board again.',
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.DELETE_BOARD_FAILED]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.ERROR,
    title: 'Something went wrong. Please try deleting your board again.',
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.DELTE_BOARD_SUCCESS]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.SUCCESS,
    title: 'Your board was successfully deleted',
    delay: DEFAULT_DELAY_TIME,
  },

  // ORGANIZATIONS
  [TOAST_NOTIFICATION_TYPES.MEMBER_ROLE_CHANGING_SUCCESS]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.SUCCESS,
    title: 'Member role has been successfully changed',
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.MEMBER_ROLE_CHANGING_ERROR]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.ERROR,
    title: 'Error',
    text: (errorMessage: string): string => errorMessage,
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.PRICING_PLAN_EXTRA_STORAGE_UPDATE_SUCCESS]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.SUCCESS,
    title: 'Storage was successfully updated',
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.PRICING_PLAN_EXTRA_STORAGE_UPDATE_ERROR]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.ERROR,
    title: 'Something went wrong, please try again a bit later',
    delay: DEFAULT_DELAY_TIME,
  },

  // NotificationPreferences
  [TOAST_NOTIFICATION_TYPES.UPDATE_NOTIFICATION_PREFERENCES_ERROR]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.ERROR,
    title: 'Something went wrong, please try again a bit later',
    delay: DEFAULT_DELAY_TIME,
  },

  // FILE
  [TOAST_NOTIFICATION_TYPES.FILE_TYPE_ERROR]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.ERROR,
    title: 'Error',
    text: (fileType: string): string => `File of type "${fileType}" is not allowed`,
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.FILE_TYPE_ERROR_WITH_CUSTOM_MESSAGE]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.ERROR,
    title: 'Error',
    text: (errorMessage: string): string => errorMessage,
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.FILE_TYPE_ERROR_BRIEF_FILE]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.ERROR,
    title: 'Error',
    text: (fileType: string): string => `Your brief upload has failed. File of type "${fileType}" is not allowed`,
    delay: DEFAULT_DELAY_TIME,
  },
  [TOAST_NOTIFICATION_TYPES.FILE_TYPE_ERROR_CONTRACT_FILE]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.ERROR,
    title: 'Error',
    text: (fileType: string): string => `Your contract upload has failed. File of type "${fileType}" is not allowed`,
    delay: DEFAULT_DELAY_TIME,
  },

  // RESULT
  [TOAST_NOTIFICATION_TYPES.RESULT_ASSET_UPLOAD_SUCCESS]: {
    type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.SUCCESS,
    title: 'Success',
    text: (filename: string): string =>
      `File: "${filename}" successfully uploaded to results`,
    delay: DEFAULT_DELAY_TIME,
  },

  //   Test
  //   [TOAST_NOTIFICATION_TYPES.TEST]:{
  //     type: TOAST_NOTIFICATION_SETTINGS_TYPE_TYPES.ERROR,
  //     delay: DEFAULT_DELAY_TIME,
  //     secondaryButtonData: {
  //       text: (s: string, n: number) => `string : "${s}", number: ${n}`,
  //       action: () => {}
  //     },
  //     primaryButtonData: {
  //       text: (Component: () => JSX.Element) => <Component/>,
  //       action: () => {}
  //     }
  //   }
};

export default notificationData;
