import React from 'react';
import styles from './OrganizationItem.module.scss';
import RadioButton from '../../../FormControls/RadioButton';
import purpleStar from '../../../../assets/img/icons/purple_star_circle.svg';
import placeholders from '../placeholders';
import cs from 'classnames';
import { MEMBER_ROLE_IN_ORGANIZATION_TYPES } from '../../../../models/enums/MemberRoleInOrganizationTypes';

const OrganizationItem = ({ org, selected, onClick, handleConfig }) => {
  const isOwner = org?.role === MEMBER_ROLE_IN_ORGANIZATION_TYPES.OWNER;

  const isSelected = selected?.organization_id === org.organization_id;

  const handleOnClick = () => {
    if (!isSelected) {
      onClick(org);
    }
  };

  const getPlaceholder = () => {
    const hashNumber = org?.organization_name
      .split('')
      .map(char => char.codePointAt())
      .reduce((sum, num) => sum + num, 0);
    const placeholderIndex = hashNumber % placeholders?.length;
    const Placeholder = placeholders[placeholderIndex];
    return <Placeholder />;
  };

  return (
    <div className={styles.listItem} onClick={handleOnClick}>
      <RadioButton checked={isSelected} onChange={() => {}} />
      <div className={styles.itemContent}>
        <div className={styles.content}>
          <div className={styles.logoContainer}>
            <div className={styles.logo}>{getPlaceholder()}</div>
            <div className={cs(styles.logoSubIcon, { [styles.active]: isOwner })}>
              <img src={isOwner ? purpleStar : null} />
            </div>
          </div>
          <div className={styles.textContainer}>
            <span className={styles.name}>{org?.organization_name}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationItem;
