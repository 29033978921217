import React, {
  FC,
  ReactChild,
  ReactChildren,
  ReactElement,
  ReactFragment,
  ReactNode,
  ReactPortal,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import styles from './HeaderBreadcrumbs.module.scss';
import cs from 'classnames';
import { useMediaQuery } from 'react-responsive/src';
import Mobile from './Mobile';
import Tablet from './Tablet';
import DesktopLandscape from './DesktopLandscape';
import { BreadcrumbRoute } from '../../../models/entities/breadcrumbs/BreadcrumbRoute';
import { useAppSelector } from '../../../shared/hooks/reduxHooks';
import { MEMBER_ROLE_IN_ORGANIZATION_TYPES } from '../../../models/enums/MemberRoleInOrganizationTypes';

const isLastIndex = (index, items) => index === items.length - 1;

type PropsType = {
  items: BreadcrumbRoute[];
  asMobile: boolean;
};

const HeaderBreadcrumbs: FC<PropsType> = props => {
  const rootRef = useRef(null);
  const plan = useAppSelector(state => state.auth.currentOrganizationPlan);
  const organizationRole = useAppSelector(state => state.auth.currentOrganization?.role);
  const isDesktopLandscape = useMediaQuery({
    query: '(min-width: 992px) and (orientation: landscape)',
  });

  const isWidthWithPlanButton = useMemo(() => {
    return !!plan?.is_free && isDesktopLandscape && organizationRole !== MEMBER_ROLE_IN_ORGANIZATION_TYPES.MEMBER;
  }, [plan, isDesktopLandscape, organizationRole]);

  const onClick = useCallback(
    (callback, index) => {
      if (isLastIndex(index, props.items)) return;
      return event => callback(event);
    },
    [props.items]
  );

  return (
    <>
      <Wrapper asMobile={props.asMobile}>
        {props.items.length > 1 && (
          <div
            className={cs(
              styles.root,
              { [styles.mobileRoot]: !isDesktopLandscape },
              { [styles.widthWithPlanButton]: isWidthWithPlanButton }
            )}
            ref={rootRef}
          >
            <div className={styles.navWrapper}>
              <nav className={styles.nav}>
                <Mobile items={props.items} onClick={onClick} rootRef={rootRef} />
                <Tablet items={props.items} onClick={onClick} rootRef={rootRef} />
                <DesktopLandscape items={props.items} onClick={onClick} rootRef={rootRef} />
              </nav>
            </div>
          </div>
        )}
      </Wrapper>
    </>
  );
};

type WrapperPropsType = {
  children: any;
  asMobile: boolean;
};

const Wrapper = (props: WrapperPropsType) => {
  const isDesktopLandscape = useMediaQuery({
    query: '(min-width: 992px) and (orientation: landscape)',
  });

  if (!props.asMobile) {
    return props.children;
  }
  return <div className={cs(styles.wrapper, { [styles.hidden]: props.asMobile && isDesktopLandscape })}>{props.children}</div>;
};

export default HeaderBreadcrumbs;
