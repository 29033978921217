import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

const initialState = {
  page: 1,
  searchValue: '',
  statusSelectIndex: -1,
  memberSelectIndex: -1,
  dateStart: null,
  dateEnd: null,
  sort: {
    order_by: 'created_at',
    order_type: 'desc',
  },
  isArchived: false,
};

const useFilters = (pageSize, statusOptions, tabSwitcher, localStorageKey) => {
  const organizationUsers = useSelector(state => state.user.organizationUsers);

  const [filter, setFilterMain] = useState(JSON.parse(localStorage.getItem(localStorageKey)) || initialState);

  const setFilter = useCallback(filter => {
    localStorage.setItem(localStorageKey, JSON.stringify(filter));
    setFilterMain(filter);
  }, []);

  useEffect(() => {
    onChangeTabSwitcher(tabSwitcher);
  }, [tabSwitcher]);

  const setStatusIndex = index => {
    setFilter({ ...filter, statusSelectIndex: index, page: 1 });
  };

  const setMemberIndex = index => {
    setFilter({ ...filter, memberSelectIndex: index, page: 1 });
  };

  /**
   * @param {{startDate: Date, endDate: Date}} value
   */
  const applyDateFilterHandler = value => {
    setFilter({ ...filter, dateStart: value.startDate, dateEnd: value.endDate, page: 1 });
  };

  const clearStatusSelectHandler = () => {
    setFilter({ ...filter, statusSelectIndex: -1, page: 1 });
  };

  const clearMemberSelectHandler = () => {
    setFilter({ ...filter, memberSelectIndex: -1, page: 1 });
  };

  const clearAllHandler = () => {
    setFilter({ ...initialState });
  };

  const clearDateFilterHandler = () => {
    setFilter({ ...filter, dateStart: null, dateEnd: null, page: 1 });
  };

  const setPagePagination = page => {
    setFilter({ ...filter, page });
  };

  const onSort = newSort => {
    setFilter({ ...filter, sort: newSort });
  };

  const onSearch = searchValue => {
    setFilter({ ...filter, searchValue: searchValue, page: 1 }); // apply search value to the filter
  };

  const onClearSearch = () => {
    setFilter({ ...filter, searchValue: '', page: 1 });
  };

  const onChangeTabSwitcher = switcher => {
    const localFilters = JSON.parse(localStorage.getItem(localStorageKey));
    setFilter({ ...initialState, ...localFilters, isArchived: switcher === 'archived', page: 1 });
  };

  const addAllFilters = (search = '', status = -1, members = -1, dateStart = null, dateEnd = null) => {
    setFilter({
      ...initialState,
      searchValue: search,
      statusSelectIndex: status,
      memberSelectIndex: members,
      dateStart: dateStart,
      dateEnd: dateEnd,
      page: 1,
    });
  };

  const onClearAll = () => {
    setFilter({ ...initialState });
  };

  const getParams = () => {
    const params = {
      page: filter.page,
      page_size: pageSize,
      search_filter: filter.searchValue || null,
      status: (filter.statusSelectIndex !== -1 && statusOptions[filter.statusSelectIndex].key) || null,
      approver_id: (filter.memberSelectIndex !== -1 && organizationUsers[filter.memberSelectIndex]?.id) || null,
      date_start: (filter.dateStart && dayjs(filter.dateStart).format('YYYY-MM-DD')) || null,
      date_end: (filter.dateEnd && dayjs(filter.dateEnd).format('YYYY-MM-DD')) || null,
      is_archived: filter.isArchived,
    };

    if (filter.sort.order_by && filter.sort.order_type) {
      params.order_by = filter.sort.order_by;
      params.order_type = filter.sort.order_type;
    }

    return params;
  };

  return {
    filter,
    setStatusIndex,
    setMemberIndex,
    applyDateFilterHandler,
    clearDateFilterHandler,
    clearAllHandler,
    clearMemberSelectHandler,
    clearStatusSelectHandler,
    setPagePagination,
    onSort,
    onSearch,
    onClearSearch,
    getParams,
    onClearAll,
    addAllFilters,
  };
};

export default useFilters;
