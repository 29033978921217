import React, { FC, SVGProps } from 'react';
import styles from '../FilledDocumentCard.module.scss';
import LoadingContainer from '../../../../../../../../components/LoadingContainer';
import cs from 'classnames';

type PropsType = {
  image: FC<SVGProps<SVGSVGElement>>;
  isTablet: boolean;
  documentImage: string;
  isLoading: boolean;
  className?: string;
};

const FilledDocumentStateLoadingState: FC<PropsType> = props => {
  return (
    <div className={cs(styles.root, props.className)}>
      {!props.isTablet && (
        <div className={styles.cardBody}>
          {!props.isLoading && <img className={styles.documentImage} src={props.documentImage} alt={'document'} />}
        </div>
      )}
      <div className={styles.cardFooter}>
        <props.image />
        <div className={styles.textWrapper}>
          <LoadingContainer width={'100%'} height={16} borderRadius={'5px'} />
          <LoadingContainer width={100} height={10} borderRadius={'5px'} />
        </div>
        <div style={{ width: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', columnGap: '2px' }}>
          <LoadingContainer width={4} height={4} borderRadius={'100%'} />
          <LoadingContainer width={4} height={4} borderRadius={'100%'} />
          <LoadingContainer width={4} height={4} borderRadius={'100%'} />
        </div>
      </div>
    </div>
  );
};

export default FilledDocumentStateLoadingState;
