import { ChangeEventHandler, useCallback, useState } from 'react';
import useFileUpload, { IFileData } from './useFileUpload';

export interface IPreloadingFile {
  loaded: number;
  total: number;
}

export class PreloadingFile implements IPreloadingFile {
  loaded: number;
  total: number;

  constructor(loaded: number, total: number) {
    this.loaded = loaded;
    this.total = total;
  }
}

type UseFileUploadType = (settings: { handleCreate: (fileData: IFileData) => void; allowedLength?: number }) => {
  onLoadEnd: ChangeEventHandler<HTMLInputElement>;
  isUploading: boolean;
  loadingPercentageProgress: number;
  preloadingFile: IPreloadingFile | null;
};

const useFileUploadWithLoading: UseFileUploadType = settings => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [loadingPercentageProgress, setLoadingPercentageProgress] = useState(0);
  const [preloadingFile, setPreloadingFile] = useState<IPreloadingFile | null>(null);

  const handleProcessing = useCallback((event: ProgressEvent<FileReader>) => {
    setPreloadingFile(new PreloadingFile(event.loaded, event.total));
    const percentage = +((event.loaded / event.total) * 100).toFixed(2);
    setLoadingPercentageProgress(percentage);
  }, []);

  const onLoadingStart = useCallback((event: ProgressEvent<FileReader>) => {
    setIsUploading(true);
    setPreloadingFile(new PreloadingFile(event.loaded, event.total));
    setLoadingPercentageProgress(0);
  }, []);

  const onLoadingEnd = useCallback(
    (file: IFileData) => {
      setPreloadingFile(null);
      settings.handleCreate(file);
      setIsUploading(false);
      setLoadingPercentageProgress(0);
    },
    [settings.handleCreate]
  );

  const handleFileUpload = useFileUpload({
    handleCreate: onLoadingEnd,
    allowedLength: settings.allowedLength,
    handleProcessing: handleProcessing,
    handleLoadingStart: onLoadingStart,
  });

  return {
    onLoadEnd: handleFileUpload,
    isUploading,
    loadingPercentageProgress,
    preloadingFile,
  };
};

export default useFileUploadWithLoading;
