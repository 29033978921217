import React, { useEffect, useState } from 'react';
import Discussion from '../../../expert/components/Discussion';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

const BriefsDiscussionWrapper = () => {
  const match = useRouteMatch();
  const currentBriefItemId = match.params.creatorBriefID || match.params.briefItemID;
  const collaborators = useSelector(state => state.brief?.currentBrief?.collaborators);

  // Artificial unmount and chat mount. Don't delete this Crutch.
  const [displayDiscussion, setDisplayDiscussion] = useState(true);
  useEffect(() => {
    setDisplayDiscussion(false);
    Promise.resolve().then(() => setDisplayDiscussion(true));
  }, [currentBriefItemId]);

  return (
    <>
      {displayDiscussion && <Discussion collaborators={collaborators} objectItemID={currentBriefItemId} itemType={'campaign_brief_item'} />}
    </>
  );
};

export default BriefsDiscussionWrapper;
