import React, { useEffect, useState } from 'react';
import styles from './SubTabSuggestedContent.module.scss';
import CheckboxButton from '../../../CheckboxButton';
import TextField from '../../../../../../components/FormControls/TextField';
import EditorStyledField from '../../../../../../components/EditorStyledField';

function SubTabSuggestedContent({ formik }) {
  const [showCreators, setShowCreators] = useState(false);

  useEffect(() => {
    if (!!formik.values.suggestedCreators && !formik.values.suggestedCreatorsDescription) {
      setShowCreators(true);
    }
    if (!!formik.values.suggestedCreatorsDescription && !formik.values.suggestedCreators) {
      setShowCreators(false);
    }
  }, [formik]);

  const setShowCreatorsHandler = () => {
    setShowCreators(true);
    formik.setFieldValue('suggestedCreatorsDescription', '');
    formik.setFieldValue('showCreators', true);
  };

  const removeShowCreators = () => {
    setShowCreators(false);
    formik.setFieldValue('suggestedCreators', '');
    formik.setFieldValue('showCreators', false);
  };

  return (
    <div>
      <p>This is to give an indication of who you may want to work with. </p>
      <div className={styles.checkboxBlock}>
        <CheckboxButton onClick={setShowCreatorsHandler} isActive={showCreators} item={'Yes'} className={styles.checkboxHeading} />
        <CheckboxButton onClick={removeShowCreators} isActive={!showCreators} item={'No'} className={styles.checkboxHeading} />
      </div>

      {showCreators && (
        <div style={{ marginTop: 10, maxWidth: '500px' }}>
          <div>
            <TextField
              placeholder={'Creators'}
              name={'suggestedCreators'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.suggestedCreators || ''}
              error={formik.errors.suggestedCreators}
            />
          </div>
        </div>
      )}
      {!showCreators && (
        <div style={{ marginTop: 10, maxWidth: '500px' }}>
          <div>
            <EditorStyledField
              toolbarClassName={styles.editorToolbar}
              editorClassName={styles.editorClassName}
              initialValue={formik.values.suggestedCreatorsDescription}
              onChange={text => formik.setFieldValue('suggestedCreatorsDescription', text)}
              onBlur={formik.handleBlur}
              placeholder={
                "That's all right. Please describe about the content creator you would like to work with. For example, gender, location, the number of followers etc."
              }
              error={formik.errors.suggestedCreatorsDescription}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default SubTabSuggestedContent;
