import React, { ComponentProps, FC } from 'react';
import styles from './GreenRedButton.module.scss';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';
import cs from 'classnames';

type PropsType = {
  className?: string;
  color?: 'green' | 'red';
  size: 'large' | 'medium' | 'small';
  disabled?: boolean;
  children?: JSX.Element | null | string;
} & Omit<ComponentProps<typeof ButtonCustom>, 'color' | 'size'>;

const GreenRedButton: FC<PropsType> = ({ className, color = 'green', size = 'large', disabled, children, ...props }) => {
  const mainColorClassName = color + 'Main';
  return (
    <ButtonCustom
      className={cs(
        styles.root,
        styles[size],
        styles[color],
        { [styles.squareFormat]: !children },
        { [styles.disabled]: disabled && !props.loading },
        className
      )}
      loaderProps={{
        loaderClassName: styles.loadingClassName,
        mainColor: styles[mainColorClassName],
        accentColor: styles.accentLoaderColor,
      }}
      disabled={disabled}
      {...props}
    >
      {children}
    </ButtonCustom>
  );
};

export default GreenRedButton;
