import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { validateImage } from '../../../../../shared/utils/helpers';
import { patchContentBoard } from '../../../../../actions/ContentBoardActions/contentBoard.actions';
import { useAppSelector, useThunkDispatch } from '../../../../../shared/hooks/reduxHooks';
import { useParams } from 'react-router';

type BrandLogoType = { url: string | null; file: File | null };

const useBoardInfoForm = (): {
  infoFormState: {
    isEdit: boolean;
    boardName: string;
    brandName: string;
    brandLogo: BrandLogoType;
    boardNameError: string | null;
    brandNameError: string | null;
  };
  infoFormActions: {
    handleBrandNameChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleBoardNameChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleLogoUpload: (e: ChangeEvent<HTMLInputElement>) => void;
    onEdit: () => void;
    onSubmitSaveInfo: () => void;
  };
} => {
  const { openedBoard } = useAppSelector(state => state.contentBoard);
  const currentOrg = useAppSelector<any>(state => state.auth.currentOrganization);
  const params: { boardId: string } = useParams();
  const thunkDispatch = useThunkDispatch();

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [boardName, setBoardName] = useState<string>(openedBoard?.name || '');
  const [brandName, setBrandName] = useState<string>(openedBoard?.brand_name || '');
  const [boardNameError, setBoardNameError] = useState<string | null>(null);
  const [brandNameError, setBrandNameError] = useState<string | null>(null);
  const [brandLogo, setBrandLogo] = useState<BrandLogoType>({ url: null, file: null });

  const handleBrandNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!value.trim()) {
      setBrandNameError('Brand name is a required field');
    } else {
      setBrandNameError(null);
    }
    setBrandName(value);
  };

  const handleBoardNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!value.trim()) {
      setBoardNameError('Content board name is a required field');
    } else {
      setBoardNameError(null);
    }
    setBoardName(value);
  };

  const handleLogoUpload = event => {
    const reader = new FileReader();
    const file = validateImage(event.target.files[0]);
    if (!file) return;
    reader.onloadend = () => {
      setBrandLogo({ file: file, url: reader.result as string });
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (!isEdit) {
      setBoardName(openedBoard?.name || '');
    }
  }, [openedBoard?.name]);

  useEffect(() => {
    if (!isEdit) {
      setBrandName(openedBoard?.brand_name || '');
    }
  }, [openedBoard?.brand_name]);

  const onEdit = () => {
    setIsEdit(true);
  };

  const onSubmitSaveInfo = () => {
    setIsEdit(false);
    if (!brandLogo.file && brandName === openedBoard?.brand_name && boardName === openedBoard?.name) return;
    onUpdateBoardInfo({ brand_name: brandName, name: boardName, brand_logo: brandLogo.file });
  };

  const onUpdateBoardInfo = useCallback(
    (boardData: { brand_name: string; name: string; brand_logo?: File | null }) => {
      const formData = new FormData();
      formData.set('brand_name', boardData.brand_name);
      formData.set('name', boardData.name);
      if (boardData.brand_logo) {
        formData.set('brand_logo', boardData.brand_logo);
      }
      thunkDispatch(patchContentBoard(currentOrg.organization_id, params.boardId, formData)).then(() => {
        setBrandLogo({ url: null, file: null });
      });
    },
    [currentOrg?.organization_id, params?.boardId]
  );

  return {
    infoFormState: {
      isEdit,
      boardName,
      brandName,
      brandLogo,
      boardNameError,
      brandNameError,
    },
    infoFormActions: {
      handleBrandNameChange,
      handleBoardNameChange,
      handleLogoUpload,
      onEdit,
      onSubmitSaveInfo,
    },
  };
};

export default useBoardInfoForm;
