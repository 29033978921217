import React, { FC, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import styles from './SectionHeader.module.scss';
import { TYPES_CAMPAIGNS } from '../../../../../../../shared/constants/constants';
import useDeliverableStatusTile, { DeliverableStatusTileSettingsType } from './statuses/useDeliverableStatusTile';
import TitleLoaderWrapper from './components/TitleLoaderWrapper';
import ButtonsLoaderWrapper from './components/ButtonsLoaderWrapper';
import ApproveButton from './components/ApproveButton';
import cs from 'classnames';
import ButtonLightPurpleWhite from '../../../../../../../components/Buttons/ButtonLightPurpleWhite';
import ActionButtons from '../../../buttons/ActionButtons';
import { useMediaQuery } from 'react-responsive/src';
import GiveFeedbackButton from '../../../../../../../components/GiveFeedbackButton';
import { DeliverablePermission } from '../../../../../../../models/permissions/enum/DeliverablePermission';
import { DELIVERABLE_SECTION_TYPES } from '../../../../../../../models/enums/DeliverableSectionTypes';
import { DELIVERABLE_STATUSES } from '../../../../../../../models/enums/DeliverableStatuses';
import { useAppSelector } from '../../../../../../../shared/hooks/reduxHooks';
import { DeliverablesReducerState } from '../../../../../../../reducers/deliverables.reducer';
import { ToolTipSettings } from '../../../../../../../shared/hooks/fixedTooltip/useTooltip';
import { IDownloadSettings } from '../../types';

type PropsType = {
  sectionTitle: string;
  sectionType: DELIVERABLE_SECTION_TYPES;
  isDisplayQuestion?: boolean;
  questionTooltip?: ReactElement;
  isDataExists: boolean;
  status: DELIVERABLE_STATUSES;
  isShared: boolean;
  onShare: () => void;
  onShareAndApprove: () => void;
  onApprove: () => void;
  approveButtonText: string;
  statusTileSettings: DeliverableStatusTileSettingsType;
  isPageUpdateLoading: boolean;
  isDisabledApprove: boolean;
  onRemove: (() => void) | null;
  onEdit: () => void;
  isEditingMode: boolean;
  isDisplayEmptyState: boolean;
  approveButtonTooltipSettings?: ToolTipSettings | null;
  downloadSettings?: IDownloadSettings;
};

const SectionHeader: FC<PropsType> = ({
  sectionTitle,
  sectionType,
  isDisplayQuestion = false,
  questionTooltip = null,
  isDataExists,
  status,
  isShared,
  onShare,
  onShareAndApprove,
  onApprove,
  approveButtonText,
  statusTileSettings,
  isPageUpdateLoading,
  isDisabledApprove,
  onRemove,
  onEdit,
  isEditingMode,
  isDisplayEmptyState,
  approveButtonTooltipSettings,
  downloadSettings,
}) => {
  const isDesktopLandscape = useMediaQuery({
    query: '(min-width: 992px) and (orientation: landscape)',
  });

  const { deliverableLoading: isPageLoading, pageModel } = useAppSelector<DeliverablesReducerState>(state => state.deliverables);
  const { collaborators } = useAppSelector(state => state.contentBoard);

  const isEditDisplay: boolean = isEditingMode || isPageUpdateLoading;

  const isDisplayStatus: boolean =
    isDataExists || !!(pageModel?.roleCan(DeliverablePermission.ONLY_BA_CAN_VIEW) && status === DELIVERABLE_STATUSES.IN_PROGRESS);
  const conceptStatusTile = useDeliverableStatusTile(status, statusTileSettings);

  const isDisplayApprove: boolean =
    isDataExists &&
    status !== DELIVERABLE_STATUSES.APPROVED &&
    (!!(pageModel?.roleCan(DeliverablePermission.CAN_APPROVE_SECTION) && isShared && status === DELIVERABLE_STATUSES.PENDING_APPROVAL) ||
      !!(pageModel?.roleCan(DeliverablePermission.CAN_SHARE_SECTION) && !isShared));

  const getCollaborator = useCallback(() => {
    return collaborators.find(collaborator => {
      if (pageModel?.roleCan(DeliverablePermission.GIVE_FEEDBACK_FIRST_CONTENT_CREATOR)) {
        return collaborator.type === TYPES_CAMPAIGNS.CONTENT_CREATOR || collaborator.type === TYPES_CAMPAIGNS.TALENT_MANAGER;
      }
      return collaborator.type === TYPES_CAMPAIGNS.TALENT_MANAGER;
    });
  }, [pageModel, collaborators]);

  const [isActionModal, setIsActionModal] = useState<boolean>(false);

  const rootRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (rootRef.current && !rootRef.current.contains(event.target)) {
        setIsActionModal(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [rootRef]);

  const renderApproveButton = (): JSX.Element => {
    return (
      <ApproveButton
        buttonText={approveButtonText}
        disabled={isDisabledApprove}
        approveButtonTooltipSettings={approveButtonTooltipSettings}
        onClick={() => {
          if (pageModel?.roleCan(DeliverablePermission.CAN_APPROVE_SECTION) && isShared) onApprove();
          else if (
            pageModel?.roleCan(DeliverablePermission.CAN_APPROVE_SECTION) &&
            pageModel?.roleCan(DeliverablePermission.SHARE_AS_APPROVE)
          )
            onShareAndApprove();
          else if (pageModel?.roleCan(DeliverablePermission.CAN_SHARE_SECTION)) onShare();
        }}
      />
    );
  };

  return (
    <div className={cs(styles.titleRow, { [styles.titleEmptyState]: isDisplayEmptyState })}>
      <div className={styles.title}>
        <TitleLoaderWrapper isLoading={isPageLoading}>
          {sectionTitle} {isDisplayQuestion && questionTooltip}
        </TitleLoaderWrapper>
      </div>

      {!isEditingMode && (isDisplayStatus || isDisplayApprove) && (
        <div className={styles.buttonsContainer}>
          <ButtonsLoaderWrapper isLoading={isPageLoading}>
            {isDisplayStatus && conceptStatusTile}
            {isDisplayApprove && isDesktopLandscape && renderApproveButton()}
          </ButtonsLoaderWrapper>
        </div>
      )}

      {!isEditingMode &&
        !isDisplayEmptyState &&
        !isPageLoading &&
        !isDesktopLandscape &&
        pageModel?.roleCan(DeliverablePermission.CAN_SEE_SECTION_FOOTER) && (
          <div className={cs(styles.actionButtonWrapper, { [styles.actionActive]: isActionModal })} ref={rootRef}>
            <ButtonLightPurpleWhite
              designVersion="v3"
              size="medium"
              onClick={() => setIsActionModal(!isActionModal)}
              className={styles.actionButton}
            >
              <div className={styles.actionButtonContent}>
                <span className={styles.text}>Actions</span> <span className={styles.dots}>...</span>
              </div>
            </ButtonLightPurpleWhite>
            {isActionModal && (
              <ActionButtons
                isDisplayEdit={pageModel?.roleCan(DeliverablePermission.DISPLAY_ACTION_BUTTONS)}
                classNameButton={styles.classNameButton}
                classNameWrapper={styles.classNameWrapper}
                editText={`Edit ${sectionType}`}
                onEdit={() => {
                  onEdit();
                  setIsActionModal(false);
                }}
                isDisplayRemove={!!onRemove}
                onRemove={() => {
                  onRemove && onRemove();
                  setIsActionModal(false);
                }}
                isDisplayDownload={downloadSettings?.isDisplay}
                downloadAssets={downloadSettings?.assets}
              >
                {!isEditDisplay && pageModel?.roleCan(DeliverablePermission.DISPLAY_GIVE_FEEDBACK) && (
                  <GiveFeedbackButton collaborator={getCollaborator()}>Give feedback</GiveFeedbackButton>
                )}
              </ActionButtons>
            )}
          </div>
        )}

      {isDisplayApprove && !isDesktopLandscape && !isPageLoading && (
        <div className={styles.mobileApproveButtonWrapper}>{renderApproveButton()}</div>
      )}
    </div>
  );
};

export default SectionHeader;
