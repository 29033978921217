import React, { useMemo } from 'react';
import styles from './AssetImagePlaceholder.module.scss';
import { ReactComponent as AssetImagePlaceholderIcon } from '../../../../assets/img/icons/image-placeholder-icon.svg';
import { ReactComponent as AssetVideoPlaceholderIcon } from '../../../../assets/img/icons/video-placeholder-icon.svg';
import { PLACEMENTS } from '../../../../models/enums/Placements';

const placements = {
  [PLACEMENTS.STATIC_POST]: AssetImagePlaceholderIcon,
  [PLACEMENTS.VIDEO_POST]: AssetVideoPlaceholderIcon,
  [PLACEMENTS.HIGHLIGHT]: AssetImagePlaceholderIcon,
  [PLACEMENTS.REEL]: AssetVideoPlaceholderIcon,
  [PLACEMENTS.CAROUSEL]: AssetImagePlaceholderIcon,
  [PLACEMENTS.LIVE]: AssetVideoPlaceholderIcon,
  [PLACEMENTS.STORY_POST]: AssetImagePlaceholderIcon,
};

const AssetImagePlaceholder = ({ asset }) => {
  const Icon = useMemo(() => {
    return placements[asset.placement];
  }, [asset.placement]);

  return (
    <div className={styles.root}>
      <Icon />
    </div>
  );
};

export default AssetImagePlaceholder;
