/* eslint-disable react-hooks/exhaustive-deps */
import React, { PropsWithChildren, ReactElement, useEffect } from 'react';
import Header from '../Header';
import styles from './MainLayout.module.scss';
import Menu from '../Menu';
import { useMediaQuery } from 'react-responsive/src';
import { useDispatch } from 'react-redux';
import { deleteMarkAsNew } from '../../actions/user.actions';
import ConfirmInviteModal from '../Modals/ConfirmInviteModal';
import cs from 'classnames';
import { Route, Switch } from 'react-router-dom';
import ContentApprovalDiscussionWrapper from '../../pages/ContentApproval/ContentApprovalDiscussionWrapper';
import BriefsDiscussionWrapper from '../../pages/CampaignBrief/BriefsDiscussionWrapper';
import { UserPermission } from '../../models/permissions/enum/UserPermission';
import { getOrganization } from '../../actions/organizations.actions';
import { useAppSelector } from '../../shared/hooks/reduxHooks';

type PropsType = {
  renderHeaderContent?: () => string | ReactElement | null;
  title?: string | ReactElement | null;
  isFullWidth?: boolean;
  isFlow?: boolean;
  contentClassName?: string;
  backButtonAction?: () => void;
} & PropsWithChildren;

const MainLayout = ({ children, renderHeaderContent, title, isFullWidth, isFlow, contentClassName, backButtonAction }: PropsType) => {
  const dispatch = useDispatch();
  const isMinimizedMenu = useAppSelector(state => state.sidebarMenu.isMinimized);
  const currentOrg = useAppSelector(state => state.auth.currentOrganization);
  const organization = useAppSelector(state => state.organizations.currentOrganization);
  const isNewMember = useAppSelector(state => state.auth.isNewMember);
  const userModel = useAppSelector(state => state.auth.userModel);

  const isDesktopLandscape = useMediaQuery({
    query: '(min-width: 992px) and (orientation: landscape)',
  });

  useEffect(() => {
    if (!currentOrg) return;
    if (!organization) {
      dispatch(getOrganization(currentOrg?.organization_id));
    }
  }, [currentOrg]);

  const onCloseModal = () => {
    dispatch(deleteMarkAsNew());
  };

  const onCloseChat = () => {
    // @ts-ignore
    if (!window.HubSpotConversations) return;
    // @ts-ignore
    window.HubSpotConversations.widget.close();
  };

  useEffect(() => {
    if (!isDesktopLandscape) dispatch({ type: 'TOGGLE_MOBILE_SIDEBAR', payload: false });
  }, [isDesktopLandscape]);

  return (
    <div className={styles.root} onClick={onCloseChat}>
      {isDesktopLandscape && userModel?.hasAccess(UserPermission.CAN_SEE_LEFT_ROOT_MENU) && (
        <div className={styles.menuContainer}>
          <Menu isOpen />
        </div>
      )}
      <div className={cs(styles.layoutContentContainer, { [styles.minimized]: isMinimizedMenu })}>
        <div>
          {isDesktopLandscape && userModel?.hasAccess(UserPermission.CAN_SEE_HEADER) && (
            <Header extended renderContent={renderHeaderContent} title={title} isFlow={isFlow} backButtonAction={backButtonAction} />
          )}
        </div>
        <div className={cs(styles.layout, { [styles.withoutHeaderLayout]: !userModel?.hasAccess(UserPermission.CAN_SEE_HEADER) })}>
          {!isDesktopLandscape && userModel?.hasAccess(UserPermission.CAN_SEE_DISCUSSION) && <DiscussionRoutes />}
          {!isDesktopLandscape && userModel?.hasAccess(UserPermission.CAN_SEE_HEADER) && <Header />}
          <div className={cs(styles.main, { [styles.fullWidth]: isFullWidth }, contentClassName)}>
            {children}
            {isDesktopLandscape && userModel?.hasAccess(UserPermission.CAN_SEE_DISCUSSION) && <DiscussionRoutes />}
          </div>
        </div>
      </div>

      <ConfirmInviteModal open={isNewMember && !!organization} onClose={onCloseModal} name={organization?.name} />
    </div>
  );
};

const DiscussionRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path={['/content-board/:boardId', '/content-board/:boardId/deliverables/:deliverableId']}
        render={() => <ContentApprovalDiscussionWrapper />}
      />
      <Route
        path={[
          '/campaign/:campaignID/campaign-brief/:briefItemID/creator-brief/:creatorBriefID',
          '/campaign/:campaignID/campaign-brief/:briefItemID',
        ]}
        render={() => <BriefsDiscussionWrapper />}
      />
    </Switch>
  );
};

export default MainLayout;
