import React, { FC } from 'react';
import { ReactComponent as PurpleClockSVG } from '../../../../../../../../../assets/img/icons/PurpleClock.svg';
import styles from './UpcomingDatesEmptyState.module.scss';

const UpcomingDatesEmptyState: FC = () => {
  return (
    <div className={styles.root}>
      <div className={styles.clockImageBox}>
        <PurpleClockSVG className={styles.clockImage} />
      </div>
      <h5 className={styles.title}>No lives dates have yet been added to any deliverables</h5>
    </div>
  );
};

export default UpcomingDatesEmptyState;
