import { PLATFORMS } from '../../../../../../models/enums/Platforms';
import { PLACEMENTS } from '../../../../../../models/enums/Placements';

export const DELIVERABLES_PLATFORMS_NAMING_CONFIG = {
  [PLATFORMS.INSTAGRAM]: 'IG',
  [PLATFORMS.FACEBOOK]: 'FB',
  [PLATFORMS.YOUTUBE]: 'YT',
  [PLATFORMS.BLOG_POST]: 'Blog',
  [PLATFORMS.TIKTOK]: 'TT',
};

export const DELIVERABLES_PLACEMENTS_NAMING_CONFIG = {
  [PLACEMENTS.STATIC_POST]: 'Static',
  [PLACEMENTS.STORY_POST]: 'Story',
  [PLACEMENTS.LIVE]: 'Live',
  [PLACEMENTS.VIDEO_POST]: 'Video',
  [PLACEMENTS.REEL]: 'Reel',
  [PLACEMENTS.CAROUSEL]: 'Carousel',
  [PLACEMENTS.HIGHLIGHT]: 'Highlights',
};
