import React, { FC, memo } from 'react';
import { FormikProps } from 'formik';
import { areEqualFormikState } from '../../../../../../../../shared/utils/typescriptHelpers';
import EditorStyledField from '../../../../../../../../components/EditorStyledField';
import styles from '../TabBriefDetails.module.scss';
import useFormikDebouncedState from '../../../../../../../../shared/hooks/useFormikDebouncedState';

type PropsType = {
  formik: FormikProps<any>;
};

const propsAreEqual: Parameters<typeof memo<FC<PropsType>>>[1] = (prevProps, nextProps) => {
  return areEqualFormikState(['messaging'], prevProps.formik, nextProps.formik);
};

const SubTabKeyMessage = memo(({ formik }: PropsType) => {
  const [messaging, setMessaging] = useFormikDebouncedState<string>(
    (value: string) => formik.setFieldValue('messaging', value),
    formik.values.messaging
  );

  return (
    <div>
      <h3>Key messaging</h3>
      <p>Share any key messages you want to convey about the brand or product. Tip: Bullet points help to convey the message clearly.</p>
      <EditorStyledField
        toolbarClassName={styles.editorToolbar}
        wrapperClassName={styles.editorWrapperClassName}
        editorClassName={styles.editorClassName}
        initialValue={messaging}
        onChange={text => setMessaging(text)}
        onBlur={formik.handleBlur}
        placeholder={'Tip: Use Ctrl + Shift + V on windows or Cmd + Shift + V to paste your text with formatting'}
        // @ts-ignore
        error={formik.errors.messaging}
      />
    </div>
  );
}, propsAreEqual);

export default SubTabKeyMessage;
