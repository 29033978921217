import { NotificationPreferencesFormikValuesType } from '../hooks/NotificationPreferencesFormSettings';
import { NotificationPreferencesArrayType } from '../../../../models/entities/notificationPreferences/NotificationPreferences';
import { IUpdateNotificationPreferencesGroupDTO } from '../../../../models/entities/notificationPreferences/dto/UpdateNotificationPreferencesGroup';
import UpdateNotificationPreferencesGroupHydrator from '../../../../models/entities/notificationPreferences/hydrators/UpdateNotificationPreferencesGroupHydrator';

const normalizeFormikToUpdateGroupDTO = (
  initialState: NotificationPreferencesArrayType,
  formikState: NotificationPreferencesFormikValuesType
): IUpdateNotificationPreferencesGroupDTO[] => {
  const result: IUpdateNotificationPreferencesGroupDTO[] = [];

  initialState.forEach(preferenceGroupObject => {
    const isPreferenceInFormik: boolean = formikState.notificationPreferences.includes(preferenceGroupObject.group_type);

    if (isPreferenceInFormik === preferenceGroupObject.group_enabled) return;

    result.push(new UpdateNotificationPreferencesGroupHydrator(preferenceGroupObject.group_type, isPreferenceInFormik));
  });

  return result;
};

export default normalizeFormikToUpdateGroupDTO;
