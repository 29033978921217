import React, { FC, memo } from 'react';
import styles from '../SubTabOtherTerms.module.scss';
import CheckboxButton from '../../../../../../../../CampaignBrief/components/CheckboxButton';
import EditorStyledField from '../../../../../../../../../components/EditorStyledField';
import { FormikProps } from 'formik';
import { areEqualFormikState } from '../../../../../../../../../shared/utils/typescriptHelpers';
import useFormikDebouncedState from '../../../../../../../../../shared/hooks/useFormikDebouncedState';

type PropsType = {
  formik: FormikProps<any>;
};

const propsAreEqual: Parameters<typeof memo<FC<PropsType>>>[1] = (prevProps, nextProps) => {
  return areEqualFormikState(['showExclusivity', 'exclusivity'], prevProps.formik, nextProps.formik);
};

const SubTabExclusivityTerms = memo(({ formik }: PropsType) => {
  const [exclusivity, setExclusivity] = useFormikDebouncedState<string>(
    (value: string) => formik.setFieldValue('exclusivity', value),
    formik.values.exclusivity
  );

  const enableExclusivity = () => {
    formik.setFieldValue('exclusivity', '');
    formik.setFieldValue('showExclusivity', true);
  };

  const disableExclusivity = () => {
    formik.setFieldValue('exclusivity', '');
    formik.setFieldValue('showExclusivity', false);
  };

  return (
    <div className={styles.exclusivityTerms}>
      <h3 className={styles.subTitle}>Any exclusivity terms?</h3>

      <p style={{ marginBottom: 0 }}>
        Please specify if there you require the content creators to be exclusive for your brand. Also please mention the duration of your
        exclusivity and the exclusion categories and brands.
      </p>

      <div className={styles.checkboxButtonBlock}>
        {/* @ts-ignore */}
        <CheckboxButton
          onClick={enableExclusivity}
          isActive={formik.values.showExclusivity}
          item={'Yes'}
          className={styles.checkboxButton}
        />
        {/* @ts-ignore */}
        <CheckboxButton
          onClick={disableExclusivity}
          isActive={!formik.values.showExclusivity}
          item={'No'}
          className={styles.checkboxButton}
        />
      </div>

      {formik.values.showExclusivity && (
        // @ts-ignore
        <EditorStyledField
          // @ts-ignore
          toolbarClassName={styles.editorToolbar}
          wrapperClassName={styles.editorWrapperClassName}
          editorClassName={styles.editorClassName}
          initialValue={exclusivity}
          onChange={text => setExclusivity(text)}
          onBlur={formik.handleBlur}
          placeholder={'Describe exclusivity'}
          // @ts-ignore
          error={formik.errors.exclusivity}
        />
      )}
    </div>
  );
}, propsAreEqual);

export default SubTabExclusivityTerms;
