import React, {FC} from 'react';

type PropsType = {
    className?: string;
};

const CheckMarkGreenShadowSVG: FC<PropsType> = props => {
    return (
        <svg className={props.className} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_446_4945)">
                <rect x="3" y="3" width="20" height="20" rx="6" fill="#50E3C2"/>
                <path d="M17.6666 9.5L11.2499 15.9167L8.33325 13" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <filter id="filter0_d_446_4945" x="0" y="0" width="26" height="26" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feMorphology radius="3" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_446_4945"/>
                    <feOffset/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.27451 0 0 0 0 0.811765 0 0 0 0 0.690196 0 0 0 0.3 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_446_4945"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_446_4945" result="shape"/>
                </filter>
            </defs>
        </svg>
    );
};

export default CheckMarkGreenShadowSVG;
