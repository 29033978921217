import { IFile } from '../File';

export abstract class FileHydrator implements IFile {
  readonly id: string;
  readonly name: string;
  readonly extension: string;
  readonly url: string;
  readonly mimeType: string;
  readonly size: number;

  constructor(id: string, name: string, extension: string, url: string, mimeType: string, size: string | number) {
    this.id = id;
    this.name = name;
    this.extension = extension;
    this.url = url;
    this.mimeType = mimeType;
    this.size = Number(size);
  }
}
