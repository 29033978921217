import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from '../index';
import styles from './BuyMoreStorageModal.module.scss';
import GreenRedButton from '../../Buttons/GreenRedButton';
import * as paymentServices from '../../../services/payment';
import { useAppSelector, useThunkDispatch } from '../../../shared/hooks/reduxHooks';
import { CURRENCIES_TYPES } from '../../../models/enums/Currencies';
import { updateOrganizationPricingPlan } from '../../../actions/organizations.actions';
import Loader from '../../../expert/components/Loader';
import notificationDataConfig from '../../../shared/utils/notificationDataConfig';
import { TOAST_NOTIFICATION_TYPES } from '../../../shared/constants/toastNotificationsData';
import { createNotification } from '../../../actions/toastNotificationActions/toastNotification.actions';
import StorageCard from './StorageCard';

export interface IExtraPlanDTO {
  id: number;
  base_price_monthly: string;
  base_price_yearly: string;
  storage: string;
  currency: CURRENCIES_TYPES;
}

const BuyMoreStorageContent = ({ onClose }) => {
  const dispatch = useThunkDispatch();
  const currentOrg = useAppSelector(state => state.auth.currentOrganization);
  const isUpdatePlanLoading = useAppSelector(state => state.organizations.updateLoading);
  const [storagePlans, setStoragePlans] = useState<IExtraPlanDTO[]>([]);
  const [prepareDataLoading, setPrepareDataLoading] = useState<boolean>(true);
  const [selectedPlan, setSelectedPlan] = useState<IExtraPlanDTO | null>(null);

  const getExtraPlans = useCallback(async () => {
    const res = await paymentServices.getAllPlansByOrganizationId(currentOrg?.organization_id, 'extra');
    const plans = res?.data;
    if (plans?.length) {
      setStoragePlans(plans);
    }
    setPrepareDataLoading(false);
  }, [currentOrg]);

  useEffect(
    function initializeDataForModal() {
      if (!currentOrg) return;
      getExtraPlans();
    },
    [currentOrg]
  );

  const submitHandler = async () => {
    if (!selectedPlan) return;
    await dispatch(updateOrganizationPricingPlan(currentOrg?.organization_id, { pricing_plan_id: selectedPlan.id })).then(res => {
      if (res) {
        const notification = notificationDataConfig.getNotificationData(
          TOAST_NOTIFICATION_TYPES.PRICING_PLAN_EXTRA_STORAGE_UPDATE_SUCCESS,
          {}
        );
        dispatch(createNotification(notification));
      } else {
        const notification = notificationDataConfig.getNotificationData(
          TOAST_NOTIFICATION_TYPES.PRICING_PLAN_EXTRA_STORAGE_UPDATE_ERROR,
          {}
        );
        dispatch(createNotification(notification));
      }
    });
    onClose();
  };

  return (
    <div className={styles.root}>
      <div className={styles.modalTitle}>Buy additional storage</div>
      <div className={styles.cardsContainer}>
        {prepareDataLoading ? (
          <Loader />
        ) : (
          storagePlans.map(plan => (
            <StorageCard
              key={plan.id}
              plan={plan}
              onSelect={() => setSelectedPlan(plan)}
              isSelected={!!(selectedPlan && selectedPlan.id === plan.id)}
            />
          ))
        )}
      </div>
      <div className={styles.footer}>
        <GreenRedButton className={styles.button} size="large" disabled={!selectedPlan || isUpdatePlanLoading} onClick={submitHandler}>
          Buy Storage
        </GreenRedButton>
      </div>
    </div>
  );
};

const BuyMoreStorageModal = props => {
  return (
    <Modal className={styles.modalStyle} {...props} borderRadius="10px" maxWidth="540px">
      <BuyMoreStorageContent {...props} />
    </Modal>
  );
};

export default BuyMoreStorageModal;
