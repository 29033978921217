import React, { FC } from 'react';

type PropsType = {
  className?: string;
};

const CheckmarkSVG: FC<PropsType> = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
      <path
        d="M5.71589 8.98335L14.5894 0.339841C14.7835 0.150726 15.093 0.150726 15.2871 0.33984L16.6323 1.65016C16.8338 1.84643 16.8338 2.17022 16.6323 2.36649L6.06478 12.6602C5.87063 12.8493 5.56116 12.8493 5.36701 12.6602L0.367693 7.7904C0.166202 7.59413 0.166202 7.27034 0.367693 7.07407L1.71288 5.76375C1.90702 5.57463 2.21649 5.57463 2.41064 5.76375L5.71589 8.98335Z"
        fill="white"
      />
    </svg>
  );
};

export default CheckmarkSVG;
