import React, { useState } from 'react';
import { downloadFile } from '../utils/helpers';

const getFileName = extension => `asset-${Math.floor(Math.random() * 1e6)}.${extension}`;

export const DOWNLOAD_RESULT_STATUSES = {
  SUCCESS: 'success',
  PARTIALLY_FAILED: 'partially_failed',
  ALL_FAILED: 'all_failed',
};

const useDownloadAsset = getFileNameFn => {
  const [isAssetLoading, setIsAssetLoading] = useState(false);
  const [isAssetsArrayLoading, setIsAssetsArrayLoading] = useState(false);
  const [resultStatus, setResultStatus] = useState(null);

  /**
   *
   * @param {string} assetUrl
   * @param {string} extension
   */
  const downloadAsset = (assetUrl, extension) => {
    setIsAssetLoading(true);
    setResultStatus(null);
    const fileName = getFileNameFn ? getFileNameFn(extension) : getFileName(extension);
    downloadFile(assetUrl, fileName)
      .then(() => {
        setIsAssetLoading(false);
        setResultStatus(DOWNLOAD_RESULT_STATUSES.SUCCESS);
      })
      .catch(() => {
        setIsAssetLoading(false);
        setResultStatus(DOWNLOAD_RESULT_STATUSES.ALL_FAILED);
      });
  };

  /**
   *
   * @param {Array<{assetUrl: string, extension: string}>} assetsArray
   */
  const downloadAssetsArray = assetsArray => {
    const allCount = assetsArray.length;
    if (allCount) {
      setResultStatus(null);
      setIsAssetsArrayLoading(true);
    }

    let doneCount = 0;
    let failedCount = 0;

    const check = () => {
      if (doneCount + failedCount >= allCount) {
        if (doneCount === allCount) setResultStatus(DOWNLOAD_RESULT_STATUSES.SUCCESS);
        if (failedCount === allCount) setResultStatus(DOWNLOAD_RESULT_STATUSES.ALL_FAILED);
        if (doneCount > 0 && failedCount > 0) setResultStatus(DOWNLOAD_RESULT_STATUSES.PARTIALLY_FAILED);
        setIsAssetsArrayLoading(false);
      }
    };

    for (const asset of assetsArray) {
      downloadFile(asset.assetUrl, getFileName(asset.extension))
        .then(() => {
          doneCount++;
          check();
        })
        .catch(() => {
          failedCount++;
          check();
        });
    }
  };

  return {
    isAssetLoading,
    isAssetsArrayLoading,
    resultStatus,
    downloadAsset,
    downloadAssetsArray,
  };
};

export default useDownloadAsset;
