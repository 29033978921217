const repostingTimeTextConfig = [
  {
    key: 1,
    value: '1 month',
  },
  {
    key: 3,
    value: '3 months',
  },
  {
    key: 6,
    value: '6 months',
  },
  {
    key: 9,
    value: '9 months',
  },
  {
    key: 12,
    value: '1 year',
  },
  {
    key: 99,
    value: '1+ year',
  },
];

export default repostingTimeTextConfig;
