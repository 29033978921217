import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './ContentList.module.scss';
import cs from 'classnames';
import Pagination from '../../../../../components/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import BoardsList from '../BoardsList';
import { useMediaQuery } from 'react-responsive/src';
import { setContentApprovalTab } from '../../../../../actions/ContentApprovalActions/contentApproval.actions';
import SwitcherTab from '../../../../../components/BriefsAndBoardsFlows/RootListPagesComponents/SwitcherTab';
import ContentApprovalEmptyContent from '../../EmptyState/ContentApprovalEmptyContent';
import BlurConfirmPopup from '../../../components/BlurConfirmPopup';
import useWatchDemoHandlers from '../../hooks/useWatchDemoHandlers';
import WatchDemoBriefsModal from '../../../../../components/Modals/WatchDemoBriefsModal';

const ContentList = ({
  page,
  setPage,
  pageSize,
  sort,
  setSort,
  onArchive,
  isNoSearchFiltersYet,
  onCreateBoardClick,
  onDelete,
  createBoardRoute,
  setShowPlanWarningModal,
}) => {
  const dispatch = useDispatch();
  const { contentApprovalTabSwitcher, boardsTotalCount, archivedCount, boards, boardsLoading } = useSelector(
    state => state.contentApproval
  );
  const [boardToDelete, setBoardToDelete] = useState(null);
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

  const isEmpty = !boards.length && !boardsLoading && isNoSearchFiltersYet;

  const switcherValues = useMemo(
    () => [
      { value: 'boards', count: boardsTotalCount },
      { value: 'archived', count: archivedCount },
    ],
    [boardsTotalCount, archivedCount]
  );

  const paginationTotalCount = useMemo(() => {
    return switcherValues.find(item => item.value === contentApprovalTabSwitcher).count;
  }, [contentApprovalTabSwitcher, switcherValues]);

  const handleSwitcherChange = value => {
    if (value === contentApprovalTabSwitcher) return;
    dispatch(setContentApprovalTab(value));
  };

  const handleArchive = (board, action) => {
    // if (contentApprovalTabSwitcher === 'archived' && action === 'unzip' && boards.length === 1) {
    //   handleSwitcherChange('boards');
    //   return;
    // } else if (contentApprovalTabSwitcher === 'boards' && action === 'archive' && boards.length === 1) {
    //   handleSwitcherChange('archived');
    //   return;
    // }
    onArchive(board, action);
  };

  const onCreateBoard = useCallback(() => onCreateBoardClick(createBoardRoute), [onCreateBoardClick, createBoardRoute]);

  useEffect(() => {
    if (boards.length && boards[0].is_archived && contentApprovalTabSwitcher === 'boards' && isNoSearchFiltersYet) {
      dispatch(setContentApprovalTab('archived'));
    }
  }, [boards]);

  const onDeletePopupDiscard = useCallback(() => {
    setBoardToDelete(null);
  }, []);

  const onDeletePopupConfirm = useCallback(() => {
    if (!boardToDelete) return;
    onDelete(boardToDelete.id);
    setBoardToDelete(null);
  }, [boardToDelete]);

  const onDeleteActionClick = useCallback(board => {
    setBoardToDelete(board);
  }, []);

  const { isOpenWatchDemoModal, closeWatchDemoModal, onPauseAction, onPlayAction, openWatchDemoModal } = useWatchDemoHandlers();

  return (
    <>
      <div className={styles.root}>
        <div className={cs(styles.content, { [styles.contentMobile]: isTablet, [styles.emptyContent]: isEmpty })}>
          <div className={styles.switch}>
            {switcherValues.map(item => (
              <SwitcherTab
                disabled={boardsLoading}
                key={item.value}
                switcher={contentApprovalTabSwitcher}
                setSwitcher={handleSwitcherChange}
                value={item.value}
                count={item.count}
              />
            ))}
          </div>
          <div className={cs(styles.cellsWrapper, { [styles.cellsWrapperEmpty]: isEmpty })}>
            {isEmpty ? (
              <ContentApprovalEmptyContent
                onWatchDemoClick={openWatchDemoModal}
                rootClassName={styles.emptyBoardsRoot}
                onCreateBoardClick={onCreateBoard}
                isArchive={contentApprovalTabSwitcher === 'archived'}
              />
            ) : (
              <BoardsList
                onDeleteActionClick={onDeleteActionClick}
                onDelete={onDelete}
                boards={boards}
                boardsLoading={boardsLoading}
                sort={sort}
                setSort={setSort}
                isTablet={isTablet}
                onArchive={handleArchive}
                onCreateBoardClick={onCreateBoardClick}
                setShowPlanWarningModal={setShowPlanWarningModal}
              />
            )}
          </div>
          <BlurConfirmPopup
            popUpText={`You are about to delete "${boardToDelete?.name}" board. All your content will be lost. We recommended downloading the content before deleting. Do you wish to continue?`}
            primaryBtnText={'Yes, delete'}
            secondaryBtnText={'Discard'}
            isOpen={!!boardToDelete}
            onConfirm={onDeletePopupConfirm}
            onDiscard={onDeletePopupDiscard}
          />
          {!!boards.length && paginationTotalCount > pageSize && (
            <Pagination
              className={styles.pagination}
              amount={pageSize}
              totalCount={paginationTotalCount}
              page={page}
              setPage={id => setPage(id)}
            />
          )}
        </div>
      </div>
      <WatchDemoBriefsModal
        pageType="contentApproval"
        buttonText="New Board"
        buttonLink={'/content-approval/create'}
        open={isOpenWatchDemoModal}
        onClose={closeWatchDemoModal}
        onPause={onPauseAction}
        onPlay={onPlayAction}
      />
    </>
  );
};

export default ContentList;
