import React from 'react';
import LoadingContainer from '../../../../LoadingContainer';
import styles from '../ReleaseNewsItem/ReleaseNewsItem.module.scss';

const LoaderReleaseNewsItem = () => {
  return (
    <div className={styles.newsItem}>
      <LoadingContainer
        className={styles.itemTitle}
        height="25px"
        width="70%"
        borderRadius="3px"
        margin={'0 0 10px 0'}
        animationDelay={`${-1 + 1 * 0.1}s`}
      />
      <LoadingContainer
        className={styles.itemDate}
        height="12px"
        width="15%"
        margin={'0 0 20px 0'}
        borderRadius="3px"
        animationDelay={`${-1 + 2 * 0.1}s`}
      />

      <div className={styles.innovationsList}>
        {['', '', ''].map((text, idx) => (
          <div className={styles.innovationItem} key={text + idx}>
            <LoadingContainer
              className={styles.icon}
              height="20px"
              width="20px"
              borderRadius="3px"
              margin={'0 0 5px 0'}
              animationDelay={`${-1 + (idx + 3) * 0.1}s`}
            />
            <LoadingContainer
              className={styles.text}
              height="40px"
              width="100%"
              borderRadius="3px"
              margin={'0 0 5px 15px'}
              animationDelay={`${-1 + (idx + 3) * 0.1}s`}
            />
          </div>
        ))}
      </div>

      <LoadingContainer
        className={styles.imgContainer}
        height="274px"
        width="100%"
        borderRadius="10px"
        margin={'0 0 15px 0'}
        animationDelay={`${-1 + 6 * 0.1}s`}
      />

      <LoadingContainer className={styles.footerText} height="100px" width="100%" borderRadius="3px" animationDelay={`${-1 + 7 * 0.1}s`} />
    </div>
  );
};

export default LoaderReleaseNewsItem;
