import { FileHydrator } from './FileHydrator';
import { ITextFile } from '../TextFile';
import { TEXT_FILE_TYPES } from '../enums/TextFileTypes';

export class TextFileHydrator extends FileHydrator implements ITextFile {
  readonly type: TEXT_FILE_TYPES;

  constructor(id: string, name: string, extension: string, url: string, mimeType: string, size: string | number) {
    super(id, name, extension, url, mimeType, size);

    this.type = this.getType(extension);
  }

  protected getType(extension: string): TEXT_FILE_TYPES {
    if (extension === 'pdf') {
      return TEXT_FILE_TYPES.PDF;
    }

    return TEXT_FILE_TYPES.WORD;
  }
}
