import React, { FC } from 'react';
import styles from './Tag.module.scss';

type PropsType = {
  text: string;
};

const Tag: FC<PropsType> = props => {
  return (
    <div className={styles.root}>
      <p className={styles.text}>{props.text}</p>
    </div>
  );
};

export default Tag;
