import React from 'react';
import styles from './CurrentPlanLoading.module.scss';
import LoadingContainer from '../../../../components/LoadingContainer';
import { useMediaQuery } from 'react-responsive/src';

const CurrentPlanLoading = ({ isOwner }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 575px)' });
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <LoadingContainer
          width={isTablet ? '136px' : '159px'}
          height={isTablet ? '32px' : '40px'}
          animationDelay={isMobile ? '-1s' : '-0.5s'}
          backgroundColor="#E9E9F9"
        />
        {isOwner && (
          <div className={styles.btnContainer}>
            <LoadingContainer
              width={isTablet ? '50%' : '146px'}
              height={isTablet ? '36px' : '40px'}
              borderRadius={'5px'}
              margin={isTablet ? ' 0 15px 0 0' : '0 10px 0 0'}
              animationDelay={isMobile ? '-0.4s' : '-0.4s'}
              backgroundColor="#E9E9F9"
            />
          </div>
        )}
      </div>
      <div className={styles.container}>
        {[...Array(2).keys()].map(index => (
          <div className={styles.card} key={index}>
            <div>
              <div>
                <LoadingContainer
                  width={isTablet ? '30px' : '36px'}
                  height={isTablet ? '30px' : '36px'}
                  animationDelay={`${-0.6 + index * 0.1}s`}
                  backgroundColor="#E9E9F9"
                  margin="0 10px 0 0"
                  borderRadius={'7px'}
                />
                <LoadingContainer width={'74px'} height={'24px'} animationDelay={`${-0.6 + index * 0.1}s`} backgroundColor="#E9E9F9" />
              </div>
            </div>
            <LoadingContainer
              width={isTablet ? '30%' : 'auto'}
              height={'20px'}
              animationDelay={`${-0.6 + index * 0.1}s`}
              backgroundColor="#E9E9F9"
            />
          </div>
        ))}
      </div>
    </div>
  );
};
export default CurrentPlanLoading;
