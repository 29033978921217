import React, { FC, useMemo } from 'react';
import styles from './UserCommentsSection.module.scss';
import { ReactComponent as GreenStarSVG } from '../../../../assets/img/icons/greenStar.svg';
import cs from 'classnames';

type PropsType = {
  currentRate: number;
  rateOf: number;
  message: string;
  name: string;
  company: string;
  rootClassName?: string;
};

const UserCommentsSection: FC<PropsType> = props => {
  const starsCount = useMemo(() => {
    const rate = Math.floor(props.currentRate / props.rateOf / 0.2);
    return rate === 0 ? 1 : rate;
  }, [props.currentRate, props.rateOf]);

  return (
    <div className={cs(styles.root, props.rootClassName)}>
      <div className={styles.starsContainer}>
        {Array(starsCount)
          .fill(GreenStarSVG)
          .map((Star, idx) => (
            <Star key={idx} />
          ))}
        <p className={styles.starsCount}>
          {props.currentRate} / {props.rateOf}
        </p>
      </div>
      <p className={styles.subCopyMiddleText}>{props.message}</p>
      <div className={styles.subCopyBottomContainer}>
        <p className={styles.subCopyAuthorName}>{props.name}</p>
        <p className={styles.subCopyCompany}>{props.company}</p>
      </div>
    </div>
  );
};

export default UserCommentsSection;
