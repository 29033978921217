import React, { FC, memo, PropsWithChildren, useCallback, useMemo, useRef } from 'react';
import styles from './VisualCard.module.scss';
import SquareAsset from '../../../../../../components/SquareAsset';
import VisualHover from '../../../../components/VisualHover';
import AssetPlaceholderByFileType from '../../../../../../components/AssetPlaceholderByFileType';
import useVisualCardHandlers from '../../../hooks/useVisualCardHandlers';
import EditorStyledField from '../../../../../../components/EditorStyledField';
import cs from 'classnames';
import ErrorBannerAbsolute from '../../../../../../components/ErrorBannerAbsolute';
import { useMediaQuery } from 'react-responsive/src';
import SquareCartButton from '../../../../../../components/Buttons/SquareCartButton';
import { ReactComponent as ReplaceIcon } from '../../../../../../assets/img/icons/replace.svg';
import FileInformation from '../../../../../../components/FIleInformation';
import AddAssetFileInput from '../../inputs/AddAssetFileInput';
import { DeliverablePermission } from '../../../../../../models/permissions/enum/DeliverablePermission';
import { AssetStateType } from '../../../../../../models/entities/asset/AssetState';
import { IAssetMedia } from '../../../../../../models/entities/asset/AssetMedia';
import { useAppSelector } from '../../../../../../shared/hooks/reduxHooks';
import { DeliverablesReducerState } from '../../../../../../reducers/deliverables.reducer';
import VisualTileTooltipWrapper from '../../../../../../components/WhiteTooltips/VisualTileTooltipWrapper';
import { TOOLTIP_POSITIONS } from '../../../../../../shared/hooks/fixedTooltip/useTooltip';

type PropsType = {
  isStoryTile: boolean;
  withCaption: boolean;
  fileTypes?: string[];
  asset: AssetStateType;
  openAsset: (asset: AssetStateType | IAssetMedia) => void;
  onCreateVisual?: () => void;
  onDeleteVisual: (asset: AssetStateType) => void;
  onUpdateVisual: (asset: AssetStateType) => void;
  isEditingMode: boolean;
  isViewMore: boolean;
  isPageUpdateLoading: boolean;
  textConfig: {
    deleteWithDescription: string;
    deleteWithoutDescription: string;
    descriptionPlaceholder: string;
  };
  isEffectsDisabled: boolean;
  assetsCount: number;
};

const VisualCard: FC<PropsType> = memo(
  ({
    isStoryTile = false,
    withCaption = false,
    fileTypes,
    asset,
    openAsset,
    onCreateVisual,
    onDeleteVisual,
    onUpdateVisual,
    isEditingMode,
    isViewMore,
    isPageUpdateLoading,
    textConfig = {},
    isEffectsDisabled,
    assetsCount,
  }) => {
    const pageModel = useAppSelector<DeliverablesReducerState['pageModel']>(state => state.deliverables.pageModel);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const caption = (asset && asset.caption) || '';
    const assetUrl: string | null = useMemo(() => {
      if (!('file' in asset)) {
        return (asset?.thumbnail_file_id && asset?.thumbnail_location) || asset?.preview_location || asset?.location;
      }

      return null;
    }, [asset]);

    const isDesktopLandscape: boolean = useMediaQuery({
      query: '(min-width: 992px) and (orientation: landscape)',
    });

    const isMobile: boolean = useMediaQuery({ query: '(max-width: 575px)' });

    const { deleteConfirmModal, isConfirmOpened, handleUpdate, handleDelete, isImageDisplay } = useVisualCardHandlers(
      onDeleteVisual,
      onUpdateVisual,
      asset,
      onCreateVisual,
      caption ? textConfig.deleteWithDescription || '' : textConfig.deleteWithoutDescription || '',
      withCaption
    );

    const shouldEditorRender: boolean = useMemo(
      () => withCaption && ((isViewMore && !isEditingMode) || isEditingMode) && !(!caption.trim().length && !isEditingMode),
      [withCaption, isViewMore, caption, isEditingMode]
    );

    const onDelete = useCallback(
      (close: () => void) => {
        close();
        handleDelete();
      },
      [handleDelete]
    );

    const onReplace = useCallback(
      (_close: () => void) => {
        fileInputRef.current?.click();
      },
      [fileInputRef]
    );

    const renderVisualTileTooltipWrapper = (children: PropsWithChildren['children']) => {
      if (isDesktopLandscape && !isEffectsDisabled && pageModel?.roleCan(DeliverablePermission.CAN_SEE_VISUAL_CARD_POPOVER))
        return (
          <VisualTileTooltipWrapper
            onReplace={onReplace}
            onDelete={onDelete}
            asset={asset}
            placement={assetsCount === 1 ? 'right' : 'bottom'}
            isEditingMode={isEditingMode}
          >
            {children}
          </VisualTileTooltipWrapper>
        );

      return children;
    };

    return (
      <div
        className={cs(
          styles.root,
          { [styles.isEditingRoot]: isEditingMode },
          { [styles.showLess]: !isViewMore },
          { [styles.withCaption]: withCaption },
          { [styles.showMore]: isViewMore }
        )}
      >
        {renderVisualTileTooltipWrapper(
          <div
            className={cs(
              styles.visualTileWrapper,
              { [styles.storyTileWrapper]: isStoryTile },
              { [styles.withoutCaption]: !withCaption },
              { [styles.withCaption]: withCaption },
              { [styles.disabled]: false }
            )}
          >
            {!('is_new' in asset) || asset.is_new ? (
              <div className={cs(styles.newTag, { [styles.smallImageNewTag]: !isViewMore })}>New</div>
            ) : null}
            {assetUrl ? (
              <>
                {isImageDisplay ? (
                  <img className={styles.cardImage} src={assetUrl} alt="no image" />
                ) : (
                  <SquareAsset className={styles.cardImage} src={assetUrl} type={'video'} />
                )}
                {!isEffectsDisabled && <VisualHover className={styles.imageHover} onClick={() => openAsset(asset)} />}
              </>
            ) : (
              <AssetPlaceholderByFileType className={styles.cardImage} fileType={'file' in asset ? asset.file.type : ''} />
            )}

            {!('file' in asset) && isMobile && (
              <FileInformation
                withText={isViewMore}
                withPlaceholder
                isWhiteTextStyle
                className={cs(styles.fileInfo, styles.assetInfo)}
                asset={asset}
              />
            )}

            {'file' in asset && isMobile && <FileInformation withText={isViewMore} className={styles.fileInfo} asset={asset} />}

            {isEditingMode && !isConfirmOpened && !isPageUpdateLoading && !isDesktopLandscape && (
              <SquareCartButton
                tooltipPosition={TOOLTIP_POSITIONS.BOTTOM}
                tooltipText={'Delete Visual'}
                classNameWrapper={styles.iconClassName}
                onClick={handleDelete}
              />
            )}
            {isEditingMode && !isConfirmOpened && !isPageUpdateLoading && !isDesktopLandscape && (
              <SquareCartButton
                tooltipPosition={TOOLTIP_POSITIONS.BOTTOM}
                tooltipText={'Replace Visual'}
                classNameWrapper={cs(styles.iconClassName, styles.replaceIcon)}
                Icon={ReplaceIcon}
                onClick={() => fileInputRef.current?.click()}
              />
            )}
          </div>
        )}

        {shouldEditorRender && (
          <div className={styles.textContentWrapper}>
            <div>
              <EditorStyledField
                keyForRerender={isEditingMode ? false : `${Math.random()}`}
                initialValue={caption}
                onChange={handleUpdate}
                placeholder={isEditingMode ? textConfig.descriptionPlaceholder : ''}
                disabled={'error' in asset && !!asset.error}
                wrapperClassName={cs(
                  styles.wrapperClassName,
                  { [styles.isEditingMode]: isEditingMode || isPageUpdateLoading },
                  { [styles.disabled]: 'error' in asset && !!asset.error }
                )}
                toolbarClassName={styles.editorToolbar}
                editorClassName={styles.editorClassName}
                readOnly={!isEditingMode && !isPageUpdateLoading}
              />
              {'error' in asset && asset.error && <ErrorBannerAbsolute errorText={asset.error} />}
            </div>
          </div>
        )}
        {deleteConfirmModal}
        <AddAssetFileInput
          ref={fileInputRef}
          className={styles.hidden}
          fileTypes={fileTypes}
          onAddAsset={handleUpdate}
          assetType={asset?.type}
        />
      </div>
    );
  }
);

export default VisualCard;
