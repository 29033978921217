import React, { FC } from 'react';
import Loader from '../../expert/components/Loader';
import styles from './SmallLoader.module.scss';
import cs from 'classnames';

type SmallLoaderProps = {
  className?: string;
} & React.ComponentProps<typeof Loader>;

const SmallLoader: FC<SmallLoaderProps> = ({ className, ...props }) => <Loader className={cs(styles.root, className)} {...props} />;

export default SmallLoader;
