import React, {FC} from "react";
import classNames from "classnames";
import styles from './VisualLoading.module.scss';
import Loader from "../../../../expert/components/Loader";

interface VisualLoadingProps {
  className?: string;
  open: boolean;
}

export const VisualLoading: FC<VisualLoadingProps> = ({className, open}) => {
  return (
    <div
      className={
        classNames(styles.root, {[styles.open]: open}, [
          className
        ])
      }
    >
      <Loader />
    </div>
  );
};
