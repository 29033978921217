import React, {FC} from 'react';
import {useAppSelector} from "../../../../shared/hooks/reduxHooks";
import {ContentBoardPermission} from "../../../../models/permissions/enum/ContentBoardPermission";
import EmptyStateRequest from "./EmptyStateRequest";
import EmptyStateUpload from "./EmptyStateUpload";

type PropsType = {
  onUpload: (files: File[]) => void;
};

const EmptyState: FC<PropsType> = props => {
  const pageModel = useAppSelector(state => state.contentBoard.pageModel);

  if (!pageModel) return null;

  if (pageModel.roleCan(ContentBoardPermission.CAN_REQUEST_RESULTS)) return <EmptyStateRequest/>

  if (pageModel.roleCan(ContentBoardPermission.CAN_EDIT_RESULTS)) return <EmptyStateUpload onUpload={props.onUpload}/>

  return null;
};


export default EmptyState;
