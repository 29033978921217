import Centrifuge from 'centrifuge';
import { ICentrifuge } from '../models/cetrifuge/Centrifuge';

export default class AppCentrifuge implements ICentrifuge {
  public readonly centrifuge: Centrifuge;
  private static instance: AppCentrifuge;
  private onConnectCallback: () => void = () => {};
  private onDisconnectCallback: () => void = () => {};

  private constructor() {
    this.centrifuge = new Centrifuge(process.env.REACT_APP_WS_URL!);
    this.onConnect = this.onConnect.bind(this);
    this.onDisconnect = this.onDisconnect.bind(this);
    AppCentrifuge.instance = this;
    return AppCentrifuge.instance;
  }

  static getInstance(): AppCentrifuge {
    if (!AppCentrifuge.instance) {
      return new AppCentrifuge();
    }
    return AppCentrifuge.instance;
  }

  subscribeConnect(callback: () => void): AppCentrifuge {
    this.onConnectCallback = callback;
    return this;
  }

  subscribeDisconnect(callback: () => void): AppCentrifuge {
    this.onDisconnectCallback = callback;
    return this;
  }

  private onConnect(): void {
    // console.log('centrifuge connect');
    this.onConnectCallback();
  }

  private onDisconnect(): void {
    // console.log('centrifuge disconnect');
    this.onDisconnectCallback();
  }

  connect(JWT: string): void {
    // console.log('connect...');
    this.centrifuge.setConnectData({ token: JWT });
    this.centrifuge.on('connect', this.onConnect);
    this.centrifuge.on('disconnect', this.onDisconnect);
    this.centrifuge.connect();
  }

  disconnect(): void {
    // console.log('disconnect...');
    this.centrifuge.disconnect();
    this.centrifuge.off('connect', this.onConnect);
    this.centrifuge.off('disconnect', this.onDisconnect);
  }
}
