import React from 'react';
import styles from './PlanCard.module.scss';
import { ReactComponent as CheckedIcon } from '../../../../assets/img/icons/checked.svg';
import cs from 'classnames';
import { IPricingPlanView } from '../../../../models/entities/pricingPlans/PricingPlanView';
import { PRICING_PLAN_PERIOD } from '../../../../models/entities/pricingPlans/enum/PricingPlanPeriod';
import CustomPlan from '../../../../models/entities/pricingPlans/CustomPlans/CustomPlan';

const PeriodPriceKey = {
  [PRICING_PLAN_PERIOD.MONTHLY]: 'monthPrice',
  [PRICING_PLAN_PERIOD.YEARLY]: 'annualPrice',
};

type PropsType = {
  plan: IPricingPlanView;
  period: PRICING_PLAN_PERIOD;
  isCurrent?: boolean;
  onSelect: (plan: IPricingPlanView) => void;
  updatingPlan: boolean;
};

const PlanCard = ({ plan, period, isCurrent, onSelect, updatingPlan }: PropsType) => {
  const handleSelect = () => {
    if (isCurrent || updatingPlan) return;
    if (plan instanceof CustomPlan) {
      plan.action();
      return;
    }
    onSelect(plan);
  };

  return (
    <div className={styles.root}>
      <div className={styles.textWrapper}>
        <div className={styles.titleWrapper}>
          <span className={styles.title}>{plan.name}</span>
          {plan.isFree && <span className={styles.badge}>Free</span>}
        </div>
        <div className={styles.subCopy}>{plan.description}</div>
        <div className={styles.priceWrapper}>
          {plan[PeriodPriceKey[period]] >= 0 ? (
            <>
              <span className={styles.price}>
                ${plan[PeriodPriceKey[period]]} <div> USD</div>
              </span>
              <span className={styles.duration}>per {period === PRICING_PLAN_PERIOD.MONTHLY ? 'month' : 'year'} </span>
            </>
          ) : null}
        </div>
      </div>

      <div
        className={cs(styles.actionButton, { [styles.active]: !isCurrent && !updatingPlan }, { [styles.purpleStyle]: !!plan.isCustom })}
        onClick={handleSelect}
      >
        {isCurrent ? 'Current plan' : plan.btn}
      </div>

      <div className={styles.additionalList}>
        <div>Includes:</div>
        <ul>
          {plan.abilities.map(ability => (
            <li key={ability}>
              <div>
                <CheckedIcon />
              </div>
              <span>{ability}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PlanCard;
