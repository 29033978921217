import React, { ComponentProps, FC, memo, ReactElement, ReactNode } from 'react';
import { ModalMedium } from '../index';
import styles from './BlueHeaderModal.module.scss';
import cs from 'classnames';

type PropsType = {
  headerTitle?: string;
  children?: JSX.Element | ReactNode | ReactElement | string | null;
  modalProps?: ComponentProps<typeof ModalMedium>;
};

const BlueHeaderModal: FC<PropsType> = memo(props => {
  return (
    // @ts-ignore
    <ModalMedium classNames={cs(styles.root, props.modalProps.classNames)} {...props.modalProps}>
      <div className={styles.header}>
        <h3 className={styles.headerTitle}>{props.headerTitle}</h3>
      </div>
      <div className={styles.body}>{props.children}</div>
    </ModalMedium>
  );
});

export default BlueHeaderModal;
