import React, { FC, useRef } from 'react';
import styles from './SquareAsset.module.scss';
import cs from 'classnames';
import useSquareSize from './useSquareSize';
import { ReactComponent as PlayIcon } from '../../assets/img/icons/white-play-non-rounded.svg';

type SquareAssetProps = {
  className?: string;
  type: 'image' | 'gif' | 'video';
} & (
  | React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
  | React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>
);

const SquareAsset: FC<SquareAssetProps> = ({ className, type, ...props }) => {
  const imageRef = useRef<HTMLImageElement | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  useSquareSize(imageRef || videoRef);

  return (
    <>
      {type === 'image' || type === 'gif' ? (
        <img
          className={cs(styles.SquareAssetRoot, className)}
          {...(props as React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>)}
          ref={imageRef}
        />
      ) : (
        <video
          className={cs(styles.SquareAssetRoot, className)}
          {...(props as React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>)}
          ref={videoRef}
        />
      )}
      {type === 'gif' && (
        <div className={styles.playIcon}>
          <PlayIcon />
        </div>
      )}
    </>
  );
};

export default SquareAsset;
