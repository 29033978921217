import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { ICreateBoardBriefAsset } from '../../../../../models/entities/asset/CreateBoardBriefAsset';
import { IBriefAsset } from '../../../../../models/entities/asset/BriefAsset';
import { BoardBriefFormAssetHydrator } from '../../../../../models/entities/asset/hydrators/BoardBriefFormAssetHydrator';

export type UpdateBoardBriefVisualAssetsType = {
  toCreate: ICreateBoardBriefAsset[];
  toUpdate: ICreateBoardBriefAsset[];
  toDelete: ICreateBoardBriefAsset[];
};

export type UseBriefVisualAssetsReturnType = {
  briefFormAssets: {
    value: ICreateBoardBriefAsset[];
    setValue: Dispatch<SetStateAction<ICreateBoardBriefAsset[]>>;
    getUpdateSubmitState: () => UpdateBoardBriefVisualAssetsType;
  };
};

const useBriefVisualAssets = (initialState: IBriefAsset[] = []): UseBriefVisualAssetsReturnType => {
  const [visualAssets, setVisualAssets] = useState<ICreateBoardBriefAsset[]>([]);
  const [initialAssets, setInitialAssets] = useState<ICreateBoardBriefAsset[] | null>(null);

  useEffect(() => {
    if (initialState?.length && !initialAssets) {
      const value: ICreateBoardBriefAsset[] = initialState.map(visual => new BoardBriefFormAssetHydrator(visual));
      setVisualAssets([...value]);
      setInitialAssets([...value]);
    }
  }, [initialState]);

  const getUpdateSubmitState = useCallback(() => {
    const resultAssets: UpdateBoardBriefVisualAssetsType = {
      toCreate: [],
      toUpdate: [],
      toDelete: [],
    };

    if (!initialAssets) {
      resultAssets.toCreate.push(...visualAssets);
      return resultAssets;
    }

    for (const asset of initialAssets) {
      if (!visualAssets.find(a => a.id === asset.id)) {
        resultAssets.toDelete.push(asset);
      }
    }

    for (const asset of visualAssets) {
      if (typeof asset.id === 'string') {
        resultAssets.toCreate.push(asset);
      } else {
        const existingAsset = initialAssets.find(a => a.id === asset.id);
        if (existingAsset && (asset.file || existingAsset.description.trim() !== asset.description.trim())) {
          resultAssets.toUpdate.push(asset);
        }
      }
    }

    return resultAssets;
  }, [visualAssets, initialAssets]);

  return { briefFormAssets: { value: visualAssets, setValue: setVisualAssets, getUpdateSubmitState } };
};

export default useBriefVisualAssets;
