import React, { FC, MouseEventHandler } from 'react';
import styles from './DropdownCollaboratorCell.module.scss';
import { TYPES_CAMPAIGNS_TEXTS } from '../../../../../../../shared/constants/constants';
import UserColoredIcon from '../../../../../../../components/UserColoredIcon';
import { useMediaQuery } from 'react-responsive/src';
import UserSVG from '../../../../../../../components/IconsSvg/UserSVG';
import ArrowLongSVG from '../../../../../../../components/IconsSvg/ArrowLongSVG';
import { IBoardCollaborator } from '../../../../../../../models/entities/board/BoardCollaborator';
import { useAppSelector } from '../../../../../../../shared/hooks/reduxHooks';
import useTooltip, { TOOLTIP_POSITIONS } from '../../../../../../../shared/hooks/fixedTooltip/useTooltip';

const TableHeader = ({ isTablet }) => {
  return isTablet ? (
    <div className={styles.root}>
      <span style={{ textAlign: 'left' }}>Collaborator</span>
    </div>
  ) : (
    <div className={styles.root}>
      <span>Collaborator</span>
      <span>Organization</span>
      <span>Role</span>
    </div>
  );
};

type PropsType = {
  collaborator?: IBoardCollaborator;
  tableHeader?: boolean;
  onClickOwnership?: MouseEventHandler<HTMLDivElement>;
};

const DropdownCollaboratorCell: FC<PropsType> = ({ collaborator, tableHeader, onClickOwnership }) => {
  const user = useAppSelector(state => state.user);
  const openedBoard = useAppSelector(state => state.contentBoard.openedBoard);
  const currentOrganizationId = useAppSelector(state => state.auth.currentOrganization?.organization_id);
  const isInitiator = openedBoard?.creator_id === collaborator?.approver_id;
  const isCurrentUser = user.data.id === Number(collaborator?.approver_id);
  const isTablet: boolean = useMediaQuery({ query: '(max-width: 768px)' });

  const {
    toolTip: ownershipTooltip,
    onEnter: onEnterOwnership,
    onLeave: onLeaveOwnership,
  } = useTooltip(TOOLTIP_POSITIONS.BOTTOM, { text: 'Delegate Ownership' });

  if (tableHeader) return <TableHeader isTablet={isTablet} />;

  const renderCollaboratorColoredIcon = (
    <UserColoredIcon
      text={`${collaborator?.approver_first_name?.slice(0, 1)?.toUpperCase()}${collaborator?.approver_last_name
        ?.slice(0, 1)
        ?.toUpperCase()}`}
    />
  );

  const renderOwnershipButton = collaborator?.organization_id == currentOrganizationId && (
    <div className={styles.ownerButton} onClick={onClickOwnership} onMouseEnter={onEnterOwnership} onMouseLeave={onLeaveOwnership}>
      <UserSVG />
      <ArrowLongSVG />
      {ownershipTooltip}
    </div>
  );

  const renderName = (
    <span className={styles.name}>
      {collaborator?.approver_first_name} {collaborator?.approver_last_name} {isCurrentUser && '(You)'}
    </span>
  );

  return (
    <div className={styles.root}>
      {!isTablet ? (
        <>
          <div className={styles.rowWrapper}>
            {renderCollaboratorColoredIcon}
            {renderName}
            {renderOwnershipButton}
          </div>
          <div>{collaborator?.organization_name}</div>
          <div>
            {TYPES_CAMPAIGNS_TEXTS[collaborator?.type]}
            {isInitiator && <span className={styles.gray}> / Initiator</span>}
          </div>
        </>
      ) : (
        <>
          {renderCollaboratorColoredIcon}
          <div>
            <div className={styles.rowWrapper}>
              {renderName}
              {renderOwnershipButton}
            </div>
            <div>
              {collaborator?.organization_name} <br />
              {`${collaborator?.type?.[0]?.toUpperCase()}${collaborator?.type?.slice(1)}`} {isInitiator && '/Initiator'}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DropdownCollaboratorCell;
