import React, { DetailedHTMLProps, FC, useEffect, useRef, useState } from 'react';

import styles from './MobileAssetVideo.module.scss';
import cs from 'classnames';
import VideoTag from '../VideoTag';

type MobileAssetVideoProps = {
  isActive: boolean;
} & DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>;

const CenteredButtonVideo: FC<MobileAssetVideoProps> = ({ isActive, ...props }) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(true);
  const videoRef = useRef<HTMLVideoElement>(null);

  const play = () => {
    videoRef.current?.play();
    setIsVideoPlaying(true);
  };

  const pause = () => {
    videoRef.current?.pause();
    setIsVideoPlaying(false);
  };

  const onVideoClick = () => {
    isVideoPlaying ? pause() : play();
  };

  useEffect(() => {
    if (isActive === isVideoPlaying) return;

    isActive ? play() : pause();
  }, [isActive]);

  return (
    <div className={styles.root}>
      <VideoTag {...props} ref={videoRef} className={styles.video} onClick={onVideoClick} />
      <button onClick={onVideoClick} className={cs(styles.videoButton, { [styles.none]: isVideoPlaying })}>
        <div className={styles.triangle}></div>
      </button>
    </div>
  );
};

export default CenteredButtonVideo;
