export enum COLLABORATOR_ROLE_TYPES {
  BRAND = 'brand',
  AGENCY = 'agency',
  TALENT_MANAGER = 'talent_manager',
  CONTENT_CREATOR = 'content_creator',
}

export interface ICollaboratorRole<Permission> {
  type: COLLABORATOR_ROLE_TYPES;
  permissions: Permission[];
  setPermissions(permissions: Permission[]): void;
  hasAccess(permission: Permission): boolean;
}

export class CollaboratorRole<Permission> implements ICollaboratorRole<Permission> {
  type: COLLABORATOR_ROLE_TYPES;
  private _permissions: Permission[] = [];
  private readonly _constantPermissions: Permission[] = [];

  constructor(type: COLLABORATOR_ROLE_TYPES, constantPermissions?: Permission[]) {
    this.type = type;
    if (constantPermissions) {
      this._permissions = constantPermissions;
      this._constantPermissions = constantPermissions;
    }
  }

  hasAccess(permission: Permission): boolean {
    return this.permissions.includes(permission);
  }

  setPermissions(permissions: Permission[]): void {
    this._permissions = permissions.concat(this._constantPermissions);
  }

  get permissions() {
    return [...this._permissions];
  }
}
