import React, { useCallback, useRef } from 'react';
import CreateBoard from './CreateBoard';
import useCreateBoardFormik from './CreateBoard/useCreateBoardFormik';
import CreateBoardBrief from './CreateBoardBrief';
import useBoardBriefFormik from './CreateBoardBrief/useBoardBriefFormik';
import useBriefVisualAssets from './CreateBoardBrief/useBriefVisualAssets';
import { IDeliverableFormData } from '../../ContentBoard/components/AddDeliverablesModal/hooks/useDeliverablesFormData';
import useBoardFormHandlers from '../hooks/useBoardFormHandlers';

const CreateBoardWrapper = () => {
  const createBoardFormik = useCreateBoardFormik();
  const boardBriefFormik = useBoardBriefFormik();
  const { briefFormAssets } = useBriefVisualAssets();
  const deliverablesSavedRef = useRef<IDeliverableFormData[]>([]);

  const boardFormHandlers = useBoardFormHandlers(createBoardFormik, boardBriefFormik, briefFormAssets);

  const saveDeliverablesState = useCallback(
    (data: IDeliverableFormData[]) => {
      deliverablesSavedRef.current = data;
    },
    [deliverablesSavedRef]
  );

  return (
    <>
      {boardFormHandlers.page === 'board' ? (
        <CreateBoard
          formik={createBoardFormik}
          openCreateBriefPage={boardFormHandlers.onCreateBriefPage}
          savedDeliverables={deliverablesSavedRef}
          saveDeliverablesState={saveDeliverablesState}
          briefVisualAssets={briefFormAssets.value}
          onEditBrief={boardFormHandlers.onEditBrief}
          onDeleteBrief={boardFormHandlers.onDeleteBrief}
        />
      ) : (
        <CreateBoardBrief
          title="Add Otterfish brief to your Content Board"
          description="Build a brief for the Campaign, then invite collaborators to finalize the requirements needed"
          buttonTitle="Add brief to board"
          formik={boardBriefFormik}
          visualAssets={briefFormAssets}
          editedState={!!Object.keys(createBoardFormik.touched).length}
          onCancel={boardFormHandlers.briefFormCancel}
          onSubmit={boardFormHandlers.briefFormSubmit}
          withConfirmCancel
        />
      )}
    </>
  );
};

export default CreateBoardWrapper;
