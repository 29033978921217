import React from 'react';
import styles from './TeamMembersLoading.module.scss';
import cs from 'classnames';
import LoadingContainer from '../../../../components/LoadingContainer';
import { useMediaQuery } from 'react-responsive/src';

const TeamMembersLoading = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 575px)' });
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.counter}>
          <LoadingContainer
            width={'26px'}
            height={'26px'}
            borderRadius={'5px'}
            margin={isMobile ? '0 10px 0 0' : '0 18px 0 0'}
            animationDelay={'-1s'}
            backgroundColor="#E9E9F9"
          />
          <LoadingContainer
            width={'77px'}
            height={'20px'}
            margin={isMobile ? '0 10px 0 0' : '0 18px 0 0'}
            animationDelay={'-1s'}
            backgroundColor="#E9E9F9"
          />
          <LoadingContainer
            width={isTablet ? '100%' : '150px'}
            height={'10px'}
            margin={isMobile ? '0 10px 0 0' : '0 18px 0 0'}
            borderRadius={'10px'}
            animationDelay={'-1s'}
            backgroundColor="#E9E9F9"
          />
          <LoadingContainer width={'55px'} height={'24px'} borderRadius={'10px'} animationDelay={'-1s'} backgroundColor="#E9E9F9" />
        </div>
        <LoadingContainer
          width={isTablet ? '100%' : '110px'}
          height={isTablet ? '36px' : '40px'}
          borderRadius={'5px'}
          margin={isTablet ? '15px 15px 0 0' : '0'}
          animationDelay={'-0.9s'}
          backgroundColor="#E9E9F9"
        />
      </div>
      <div className={styles.container}>
        {[...Array(10).keys()].map(index => (
          <div className={styles.card} key={index}>
            <div className={styles.avatarContainer}>
              <LoadingContainer
                width={'30px'}
                height={'30px'}
                borderRadius={'50%'}
                margin={isMobile ? '0 10px 0 0' : '0 15px 0 0'}
                animationDelay={`${-0.8 + index * 0.1}s`}
                backgroundColor="#E9E9F9"
              />
              <LoadingContainer
                width={isMobile ? '83px' : '220px'}
                height={isMobile ? '11px' : '14px'}
                animationDelay={`${-0.8 + index * 0.15}s`}
                className={styles.tablet}
                backgroundColor="#E9E9F9"
              />
              <div className={cs(styles.nameContainer, styles.mobile)}>
                <LoadingContainer width={'83px'} height={'11px'} animationDelay={`${-0.8 + index * 0.2}s`} backgroundColor="#E9E9F9" />
              </div>
              <LoadingContainer
                width={'70px'}
                height={'26px'}
                borderRadius={'5px'}
                margin={isMobile ? '0' : '0 0 0 10px'}
                animationDelay={`${-0.8 + index * 0.25}s`}
                backgroundColor="#E9E9F9"
              />
            </div>
            <div className={cs(styles.nameContainer, styles.tablet)}>
              <LoadingContainer
                width={isMobile ? '63px' : '53%'}
                height={isMobile ? '8px' : '18px'}
                animationDelay={`${-0.8 + index * 0.25}s`}
                backgroundColor="#E9E9F9"
              />
            </div>
            <LoadingContainer
              width="85px"
              height="30px"
              borderRadius="5px"
              margin="0 0 0 10px"
              animationDelay={`${-0.8 + index * 0.3}s`}
              className={styles.tablet}
              backgroundColor="#E9E9F9"
            />
            <LoadingContainer
              width="61px"
              height="24px"
              borderRadius="5px"
              margin="0 0 0 20px"
              animationDelay={`${-0.8 + index * 0.3}s`}
              className={styles.mobile}
              backgroundColor="#E9E9F9"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamMembersLoading;
