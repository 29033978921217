import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IBoardBriefFormikValues } from '../../../../../models/entities/boardBrief/BoardBriefFormikValues';
import { CURRENCIES_TYPES } from '../../../../../models/enums/Currencies';

const useBoardBriefFormik = (values?: IBoardBriefFormikValues | null) =>
  useFormik<IBoardBriefFormikValues>({
    initialValues: {
      itemName: values?.itemName || '',

      brandBackground: values?.brandBackground || '',
      showBrandProvidesProduct: values?.showBrandProvidesProduct || false,
      brandProvidesProduct: values?.brandProvidesProduct || '',
      notes: values?.notes || '',

      // brief items
      messaging: values?.messaging || '',
      campaignObjective: values?.campaignObjective || [],

      adInCaption: !!values?.adInCaption || false,

      date_start: values?.date_start || null,
      date_end: values?.date_end || null,
      total_budget: values?.total_budget || null,
      total_budget_currency: values?.total_budget_currency || CURRENCIES_TYPES.NZD,

      boosting_manager: values?.boosting_manager || [],
      boosting_duration: values?.boosting_duration || 0,
      boosting_budget: values?.boosting_budget || 0,
      boosting_budget_currency: values?.boosting_budget_currency || CURRENCIES_TYPES.NZD,
      showBoosting: values?.showBoosting || false,

      date_post: values?.date_post || null,

      gender: values?.gender || '',
      ageMax: values?.ageMax || null,
      ageMin: values?.ageMin || null,
      city: values?.city || [],
      country: values?.country || [],
      interests: values?.interests || [],
      showTargetAudience: values?.showTargetAudience || false,

      hashtags: values?.hashtags || [],
      socialTagging: values?.socialTagging || [],

      showExclusivity: values?.showExclusivity || false,
      exclusivity: values?.exclusivity || '',

      repostingPlatform: values?.repostingPlatform || [],
      repostingDuration: values?.repostingDuration || 1,
      showSocial: values?.showSocial || false,

      other_infos: values?.other_infos || '',
    },
    validationSchema: Yup.object().shape({
      itemName: Yup.string().trim().max(200, 'Maximum length of 200 characters exceeded').required('Name is required'),
      // brandName: Yup.string().trim().max(200, 'Maximum length of 200 characters exceeded').required('Brand name is required'),
      // brandLogo: Yup.object({
      //   fileUrl: Yup.string(),
      //   file: Yup.mixed().when('fileUrl', {
      //     is: value => !value,
      //     then: Yup.mixed().required('File is required'),
      //   }),
      // }),
      date_end: Yup.date()
        .nullable(true)
        .when('date_start', {
          is: value => value,
          then: Yup.date().nullable(true).required('End date is required'),
        }),
      boosting_budget: Yup.number()
        .nullable(true)
        .when('showBoosting', {
          is: value => value,
          then: Yup.number().min(1, 'Boosting budget is required').required('Boosting budget is required'),
        }),
      brandProvidesProduct: Yup.string().when('showBrandProvidesProduct', {
        is: value => value,
        then: Yup.string().required('Brand Provides Product is required'),
      }),
      repostingPlatform: Yup.array().when('showSocial', {
        is: value => value,
        then: Yup.array().min(1, 'Reposting Platform is required').required('Reposting Platform is required'),
      }),
      boosting_duration: Yup.number()
        .nullable(true)
        .when('showBoosting', {
          is: value => value,
          then: Yup.number().min(1, 'Boosting duration is required').required('Boosting duration is required'),
        }),
      exclusivity: Yup.string().when('showExclusivity', {
        is: value => value,
        then: Yup.string().trim().required('Exclusivity is required'),
      }),
    }),
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: () => {},
  });

export default useBoardBriefFormik;
