import React, { FC } from 'react';
import styles from './SectionSubTitle.module.scss';
import cs from 'classnames';

type PropsType = {
  className?: string;
  subTitle?: string;
};

const SectionSubTitle: FC<PropsType> = props => {
  return <div className={cs(styles.root, props.className)}>{props.subTitle}:</div>;
};

export default SectionSubTitle;
