import React, { FC, useMemo } from 'react';
import ThreeDotsButton from '../../../../ThreeDotsButton';
import { ActionsDropdownV1Option } from '../../../../ActionsDropdownV1';
import { ReactComponent as ArchiveIcon } from '../../../../../assets/img/icons/archive-icon.svg';
import { ReactComponent as DuplicateIcon } from '../../../../../assets/img/icons/duplicate-icon.svg';
import { ReactComponent as UnzipIcon } from '../../../../../assets/img/icons/unzip-icon.svg';
import { DeleteIcon } from '../../../../../expert/components/IconsSvg/ReactIcons/ReactIcons';
import styles from './BoardOptionsButton.module.scss';
import cs from 'classnames';

type PropsType = {
  onArchive: () => void;
  onDuplicate: () => void;
  onDelete: () => void;
  onUnZip: () => void;
  isBoardArchived: boolean;
};

const BoardOptionsButton: FC<PropsType> = props => {
  const buttonOptions: ActionsDropdownV1Option[] = useMemo(() => {
    return props.isBoardArchived
      ? [
          {
            text: 'Unarchive',
            icon: <UnzipIcon />,
            className: cs(styles.button, styles.archiveButton),
            onClick: (event: React.MouseEvent<HTMLDivElement>, close: () => void) => {
              event.stopPropagation();
              close();
              props.onArchive();
            },
          },
          {
            text: 'Delete',
            icon: <DeleteIcon />,
            className: cs(styles.button, styles.deleteButton),
            onClick: (event: React.MouseEvent<HTMLDivElement>, close: () => void) => {
              event.stopPropagation();
              close();
              props.onDelete();
            },
          },
        ]
      : [
          {
            text: 'Archive',
            icon: <ArchiveIcon />,
            className: cs(styles.button, styles.archiveButton),
            onClick: (event: React.MouseEvent<HTMLDivElement>, close: () => void) => {
              event.stopPropagation();
              close();
              props.onArchive();
            },
          },
          {
            text: 'Duplicate',
            icon: <DuplicateIcon />,
            className: cs(styles.button, styles.duplicateButton),
            onClick: (event: React.MouseEvent<HTMLDivElement>, close: () => void) => {
              event.stopPropagation();
              close();
              props.onDuplicate();
            },
          },
          {
            text: 'Delete',
            icon: <DeleteIcon />,
            className: cs(styles.button, styles.deleteButton),
            onClick: (event: React.MouseEvent<HTMLDivElement>, close: () => void) => {
              event.stopPropagation();
              close();
              props.onDelete();
            },
          },
        ];
  }, [props]);
  return <ThreeDotsButton dropdownClassName={styles.root} options={buttonOptions}></ThreeDotsButton>;
};

export default BoardOptionsButton;
