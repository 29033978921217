import React, { useMemo } from 'react';
import styles from '../WatchDemoModal/WatchDemoModal.module.scss';
import { Modal as ReactModal } from 'react-responsive-modal';
import cs from 'classnames';
import { CloseIcon } from '../../../expert/components/IconsSvg/ReactIcons/ReactIcons';
import Button from '../../../expert/components/Buttons/ButtonCustom';
import VideoTag from '../../VideoPlayers/VideoTag';

const CONTENT_APPROVAL_URL = process.env.REACT_APP_CONTENT_APPROVAL_DEMO_VIDEO_URL;
const CAMPAIGN_URL = process.env.REACT_APP_BRIEFS_DEMO_VIDEO_URL;

const getExtension = url => {
  const extension = url?.trim().match(/(https?.*\/[^.]*\.)(\w*)/)[2];
  return {
    extension,
    oggExtension: /og/.test(extension) ? 'ogg' : null,
  };
};

const URLS = {
  contentApproval: CONTENT_APPROVAL_URL,
  campaign: CAMPAIGN_URL,
};

const titles = {
  contentApproval: 'Content approval',
  campaign: 'Campaign briefs',
};

const WatchDemoBriefsModal = props => {
  const modalStyles = {
    overlay: {
      padding: '20px',
      backdropFilter: 'blur(3px)',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
    modal: {
      background: '#fff',
      padding: 0,
      borderRadius: '10px',
      maxWidth: props.maxWidth || '700px',
      width: '100%',
      boxShadow: ' 0 0 20px rgba(0, 0, 0, 0.1)',
    },
    closeButton: {
      display: 'none',
    },
  };

  const onCloseIconHandler = () => {
    if (props.onCloseByIcon) {
      props.onCloseByIcon();
    }
    props.onClose();
  };

  const url = useMemo(() => URLS[props.pageType], [props.pageType]);
  const extension = useMemo(() => getExtension(url), [url]);
  const title = useMemo(() => titles[props.pageType], [props.pageType]);

  return (
    <ReactModal center open={props.open} onClose={props.onClose} styles={modalStyles}>
      <div className={cs(styles.root, props.className)}>
        <button onClick={onCloseIconHandler} className={styles.close} disabled={props.loading}>
          <CloseIcon size={30} />
        </button>

        <div className={styles.watchDemoContainer}>
          <div className={styles.purpleHeader}>{title}</div>
          <VideoTag width="100%" controls autoPlay={true} onPause={props.onPauseAction} onPlay={() => props.onPlay()}>
            <source src={url} type={`video/${extension.oggExtension ?? extension.extension}`} />
          </VideoTag>
          <div className={styles.popupFooter}>
            <Button onClick={() => props.buttonAction(props.buttonLink, true)}>{props.buttonText}</Button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default WatchDemoBriefsModal;
