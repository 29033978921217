export enum DELIVERABLE_CALL_TO_ACTIONS {
  APPLY_NOW = 'apply_now',
  BOOK_TRAVEL = 'book_travel',
  CALL_NOW = 'call_now',
  CONTACT_US = 'contact_us',
  DOWNLOAD = 'download',
  GET_DIRECTIONS = 'get_directions',
  GET_QUOTE = 'get_quote',
  GET_SHOWTIMES = 'get_showtimes',
  LEARN_MORE = 'learn_more',
  LISTEN_NOW = 'listen_now',
  ORDER_NOW = 'order_now',
  REQUEST_TIME = 'request_time',
  SAVE = 'save',
  SEE_MENU = 'see_menu',
  MESSAGE_PAGE = 'message_page',
  WHATSAPP_MESSAGE = 'whatsapp_message',
  SHOP_NOW = 'shop_now',
  SIGN_UP = 'sign_up',
  SUBSCRIBE = 'subscribe',
  WATCH_MORE = 'watch_more',
  GET_OFFER = 'get_offer',
  PLAY_GAME = 'play_game',
}
