import React, { useCallback, useMemo } from 'react';
import styles from './MenuInner.module.scss';
import * as icons from '../icons/index';
import cs from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Chapter from './components/Chapter';
import ModalsFlowContainer from '../ModalsContainer';
import ReportButton from './components/ReportButton';
import KnowledgeDropMenu from '../../../Header/KnowledgeDropMenu';
import { useMediaQuery } from 'react-responsive/src';
import { useAppSelector } from '../../../../shared/hooks/reduxHooks';
import { OrganizationPermission } from '../../../../models/permissions/enum/OrganizationPermission';
// import ButtonCustom from '../../../../expert/components/Buttons/ButtonCustom';
// import { useHistory } from 'react-router';

const MenuInner = ({ className, isMinimized, handleCloseMenu = () => {} }) => {
  const currentOrg = useSelector(state => state.auth.currentOrganization);
  const draftCreative = useSelector(state => state.generateCreative.draftCreative[currentOrg?.organization_id])?.templateset;
  const user = useSelector(state => state.auth.user);
  const organizationModel = useAppSelector(state => state.auth?.currentOrganizationModel || null);

  const canSeeCampaignTab = useMemo(() => {
    if (!organizationModel) return false;
    return organizationModel.hasAccess(OrganizationPermission.CAN_SEE_CAMPAIGN_TAB);
  }, [organizationModel]);

  const pathname = document.location.pathname;
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const dispatch = useDispatch();
  // const history = useHistory();

  const getChaptersConfig = useCallback(
    redirect => {
      const config = [
        {
          title: 'Instant creative',
          items: [
            // {
            //   text: 'Build Creatives',
            //   icon: iconsFactory('MyTemplatesIcon'),
            //   onClick: () => {
            //     window.analytics.track('instant_creative_flow', {
            //       userId: user.id,
            //       properties: {
            //         category: 'generate_creative',
            //         source: 'Menu',
            //       },
            //     });
            //     redirect('/my-templates');
            //   },
            //   disabled: !currentOrg?.has_valid_subscription && !currentOrg?.owner_has_free_pricing_plan,
            //   isContinue: !!draftCreative,
            //   isActive: pathname.includes('my-templates') || pathname.includes('instant-creative/generate-creatives'),
            // },
            // {
            //   text: 'My Uploads',
            //   icon: iconsFactory('MyUploadsIcon'),
            //   onClick: () => redirect('/my-uploads'),
            //   isActive: pathname.includes('my-uploads'),
            // },
            // {
            //   text: 'My Creatives',
            //   icon: iconsFactory('MyCreativesIcon'),
            //   onClick: () => redirect('/my-creatives'),
            //   isActive: pathname.includes('my-creatives'),
            // },
          ],
        },
        {
          title: 'Campaign studio',
          items: [
            // {
            //   text: 'Smart Ads',
            //   icon: iconsFactory('SmartAdsIcon'),
            //   onClick: () => redirect('/tests'),
            //   isActive: pathname.includes('tests'),
            // },
            // {
            //   text: 'Campaigns',
            //   icon: iconsFactory('CampaignsIcon'),
            //   onClick: () => {
            //     redirect('/campaign');
            //     dispatch({ type: 'TOGGLE_MOBILE_SIDEBAR', payload: false });
            //   },
            //   isActive: pathname.includes('campaign'),
            // },
            {
              text: 'Content approval',
              icon: iconsFactory('ContentApprovalIcon'),
              onClick: () => {
                redirect('/content-approval');
                dispatch({ type: 'TOGGLE_MOBILE_SIDEBAR', payload: false });
              },
              isActive: pathname.includes('content-approval') || pathname.includes('content-board'),
            },
            // {
            //   text: 'Winner Timeline',
            //   icon: iconsFactory('WinnerTimelinesIcon'),
            //   onClick: () => redirect('/winners-timeline'),
            //   isActive: pathname.includes('winners-timeline'),
            //   isBeta: true,
            // },
          ],
        },
        // {
        //   title: 'Creative brain',
        //   items: [
        //     {
        //       text: 'Dashboard',
        //       icon: iconsFactory('DashboardIcon'),
        //       onClick: () => {},
        //       isActive: false
        //     },
        //   ]
        // },
        // {
        //   title: 'Platforms',
        //   items: [
        //     {
        //       text: 'Meta',
        //       icon: iconsFactory('FacebookPlatformIcon'),
        //       onClick: () => redirect('/platforms/facebook/pages'),
        //       isActive: pathname.includes('platforms/facebook'),
        //     },
        //   ],
        // },
      ];

      // if (!canSeeCampaignTab) {
      //   config[1].items.splice(0, 1);
      // }

      return config;
    },
    [pathname, user, draftCreative, canSeeCampaignTab]
  );

  // const getActionButtonsConfig = useCallback(
  //   redirect => {
  //     const isActive = pathname.includes('create-test');
  //     return [
  //       {
  //         text: {
  //           default: 'Launch Smart Ads',
  //           continue: 'Continue Smart Ads',
  //         },
  //         icon: iconsFactory('LaunchSmartAdsIcon'),
  //         isActive: isActive,
  //         isContinue: !!draftTest,
  //         disabled: !currentOrg?.has_valid_subscription && !currentOrg?.owner_has_free_pricing_plan,
  //         onClick: () => {
  //           if (isActive) return;
  //           window.analytics.track('test_creation_flow', {
  //             userId: user.id,
  //             properties: {
  //               category: 'init_smart_ad',
  //               source: 'Menu',
  //             },
  //           });
  //           redirect('/create-test/creative');
  //         },
  //       },
  //     ];
  //   },
  //   [pathname, user, draftTest, draftCreative]
  // );

  return (
    <div className={cs(styles.inner, { [styles.minimized]: isMinimized }, className)}>
      <ModalsFlowContainer
        handleCloseMenu={handleCloseMenu}
        renderContent={(redirectTo, openBugReport) => (
          <>
            <div className={styles.chaptersContainer}>
              {getChaptersConfig(redirectTo).map((chapter, idx) => (
                <Chapter
                  key={chapter.title + idx}
                  title={chapter.title}
                  items={chapter.items}
                  isMinimized={isMinimized}
                  onCloseMenu={handleCloseMenu}
                />
              ))}
            </div>

            <div className={styles.buttonsContainer}>
              {/* <ul className={styles.actionButtons}>*/}
              {/*  {getActionButtonsConfig(redirectTo).map(buttonSettings => (*/}
              {/*    <ActionButton*/}
              {/*      key={buttonSettings.text.default}*/}
              {/*      isMinimized={isMinimized}*/}
              {/*      disabled={!currentOrg?.has_valid_subscription && !currentOrg?.owner_has_free_pricing_plan}*/}
              {/*      settings={buttonSettings}*/}
              {/*    />*/}
              {/*  ))}*/}
              {/* </ul>*/}

              <ul className={styles.dropdownMenuButtons}>
                {isMobile && <KnowledgeDropMenu isMobile={isMobile} />}
                <ReportButton
                  onClick={openBugReport}
                  icons={{ icon1: iconsFactory('ReportIcon'), icon2: iconsFactory('ReportTriangleIcon') }}
                  isMinimized={isMinimized}
                />
                {/* {isMobile && (*/}
                {/*  <ButtonCustom onClick={() => history.push('/upgrade-plan')} className={styles.updatePlanButton} clearBg>*/}
                {/*    Upgrade Plan*/}
                {/*  </ButtonCustom>*/}
                {/* )}*/}
              </ul>
            </div>
          </>
        )}
      />
    </div>
  );
};

function iconsFactory(currentKey) {
  return { icon: icons[currentKey], stylesKey: currentKey };
}

export default MenuInner;
