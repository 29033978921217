import React from 'react';
import StorageBanner from '../StorageBanner';
import styles from './LayoutHeader.module.scss';

type PropsType = {
  title?: string;
};

const LayoutHeader = (props: PropsType) => {
  return (
    <div className={styles.root}>
      {!!props.title && props.title}
      <StorageBanner />
    </div>
  );
};

export default LayoutHeader;
