import { Page } from './Page';
import { COLLABORATOR_ROLE_TYPES } from '../collaborator/CollaboratorRole';
import { DeliverablePermission } from '../enum/DeliverablePermission';
import { IFlow } from '../flows/Flow';
import { roleConstantPermissionsFactory } from '../factories/DeliverableItemPageFactories';

export class DeliverableItemPage extends Page<DeliverablePermission> {
  protected permissions: DeliverablePermission[] = [
    DeliverablePermission.DISPLAY_SECTION_EMPTY_STATE,
    DeliverablePermission.ONLY_BA_CAN_VIEW,
    DeliverablePermission.ONLY_CC_CAN_VIEW,
    DeliverablePermission.CAN_REMOVE_SECTION,
    DeliverablePermission.DISABLED_SHARE_BUTTON,
    DeliverablePermission.CONCEPT_IN_PROGRESS_STATUS_TOOLTIP,
    DeliverablePermission.CONCEPT_PENDING_APPROVAL_STATUS_TOOLTIP_CC,
    DeliverablePermission.CONCEPT_PENDING_APPROVAL_STATUS_TOOLTIP_BA,
    DeliverablePermission.CONTENT_APPROVE_BUTTON_TOOLTIP_BA,
    DeliverablePermission.CAN_APPROVE_SECTION,
    DeliverablePermission.CAN_SHARE_SECTION,
    DeliverablePermission.SHARE_AS_APPROVE,
    DeliverablePermission.GIVE_FEEDBACK_FIRST_CONTENT_CREATOR,
    DeliverablePermission.DISPLAY_GIVE_FEEDBACK,
    DeliverablePermission.DISPLAY_ADD_SECTION_BUTTON,
    DeliverablePermission.DISPLAY_ACTION_BUTTONS,
    DeliverablePermission.AUTO_SHARE_SAVED_DELIVERABLE_SECTION,
    DeliverablePermission.CAN_SEE_SECTION_FOOTER,
    DeliverablePermission.CAN_SEE_VISUAL_CARD_POPOVER,
    DeliverablePermission.CAN_SEE_FOOTER_UPDATER_INFO,
    DeliverablePermission.CAN_EDIT_DELIVERABLE_NAME,
    DeliverablePermission.CAN_DOWNLOAD_ASSETS,
  ];

  constructor(roleType: COLLABORATOR_ROLE_TYPES) {
    super(roleType, roleConstantPermissionsFactory(roleType));
    this.init();
  }

  applyFlow(flow: IFlow<DeliverablePermission>): Page<DeliverablePermission> {
    return super.applyFlow(flow);
  }
}
