import * as organizationServices from '../services/organizations';
import { UserPermission } from '../models/permissions/enum/UserPermission';
import notificationDataConfig from '../shared/utils/notificationDataConfig';
import { createNotification } from './toastNotificationActions/toastNotification.actions';
import { TOAST_NOTIFICATION_TYPES } from '../shared/constants/toastNotificationsData';

export const ORGANIZATIONS_TYPES = {
  GET_ORGANIZATIONS: {
    REQUEST: 'GET_ORGANIZATIONS_REQUEST',
    SUCCESS: 'GET_ORGANIZATIONS_SUCCESS',
    FAILURE: 'GET_ORGANIZATIONS_FAILURE',
  },
  GET_ORGANIZATION: {
    REQUEST: 'GET_ORGANIZATION_REQUEST',
    SUCCESS: 'GET_ORGANIZATION_SUCCESS',
    FAILURE: 'GET_ORGANIZATION_FAILURE',
  },
  SEARCH_ORGANIZATIONS: {
    REQUEST: 'SEARCH_ORGANIZATIONS__REQUEST',
    SUCCESS: 'SEARCH_ORGANIZATIONS__SUCCESS',
    FAILURE: 'SEARCH_ORGANIZATIONS__FAILURE',
  },
  UPDATE_ORGANIZATION: {
    REQUEST: 'UPDATE_ORGANIZATION_REQUEST',
    SUCCESS: 'UPDATE_ORGANIZATION_SUCCESS',
    FAILURE: 'UPDATE_ORGANIZATION_FAILURE',
  },
  UPDATE_ORGANIZATION_PRICING_PLAN: {
    REQUEST: 'UPDATE_ORGANIZATION_PRICING_PLAN_REQUEST',
    SUCCESS: 'UPDATE_ORGANIZATION_PRICING_PLAN_SUCCESS',
    FAILURE: 'UPDATE_ORGANIZATION_PRICING_PLAN_FAILURE',
  },
  GET_ORGANIZATION_MEMBERS: {
    REQUEST: 'GET_ORGANIZATION_MEMBERS_REQUEST',
    SUCCESS: 'GET_ORGANIZATION_MEMBERS_SUCCESS',
    FAILURE: 'GET_ORGANIZATION_MEMBERS_FAILURE',
  },
  GET_INVITED_MEMBERS: {
    REQUEST: 'GET_INVITED_MEMBERS_REQUEST',
    SUCCESS: 'GET_INVITED_MEMBERS_SUCCESS',
    FAILURE: 'GET_INVITED_MEMBERS_FAILURE',
  },
  GET_ALL_MEMBERS: {
    REQUEST: 'GET_ALL_MEMBERS_REQUEST',
    SUCCESS: 'GET_ALL_MEMBERS_SUCCESS',
    FAILURE: 'GET_ALL_MEMBERS_FAILURE',
  },
  DELETE_MEMBER_INVITE: {
    REQUEST: 'DELETE_MEMBER_INVITE_REQUEST',
    SUCCESS: 'DELETE_MEMBER_INVITE_SUCCESS',
    FAILURE: 'DELETE_MEMBER_INVITE_FAILURE',
  },
  CHANGE_USER_STATUS: {
    REQUEST: 'CHANGE_USER_STATUS_REQUEST',
    SUCCESS: 'CHANGE_USER_STATUS_SUCCESS',
    FAILURE: 'CHANGE_USER_STATUS_FAILURE',
  },
  GET_ORGANIZATION_OVERVIEW: {
    REQUEST: 'GET_ORGANIZATION_OVERVIEW_REQUEST',
    SUCCESS: 'GET_ORGANIZATION_OVERVIEW_SUCCESS',
    FAILURE: 'GET_ORGANIZATION_OVERVIEW_FAILURE',
  },
  ADD_ORGANIZATION: {
    REQUEST: 'ADD_ORGANIZATION_REQUEST',
    SUCCESS: 'ADD_ORGANIZATION_SUCCESS',
    FAILURE: 'ADD_ORGANIZATION_FAILURE',
  },
  CHANGE_MEMBER_ROLE: {
    REQUEST: 'CHANGE_MEMBER_ROLE_REQUEST',
    SUCCESS: 'CHANGE_MEMBER_ROLE_SUCCESS',
    FAILURE: 'CHANGE_MEMBER_ROLE_FAILURE',
  },
  CLEAR_FAILURE: 'CLEAR_FAILURE',
};

export const getOrganization = id => async (dispatch, getState) => {
  const state = getState();
  if (!state.auth.userModel?.hasAccess(UserPermission.CAN_GET_ORGANIZATIONS_DATA)) {
    return;
  }

  try {
    dispatch({
      type: ORGANIZATIONS_TYPES.GET_ORGANIZATION.REQUEST,
    });

    const res = await organizationServices.getOrganization(id);

    dispatch({
      type: ORGANIZATIONS_TYPES.GET_ORGANIZATION.SUCCESS,
      payload: res.data[0] || null,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: ORGANIZATIONS_TYPES.GET_ORGANIZATION.FAILURE,
      payload: error.response?.data,
    });
    return error;
  }
};

export const searchOrganizations = searchValue => async dispatch => {
  dispatch({ type: ORGANIZATIONS_TYPES.SEARCH_ORGANIZATIONS.REQUEST });

  organizationServices
    .getOrganizationList({ search_filter: searchValue, page_size: 1000 })
    .then(response => {
      dispatch({ type: ORGANIZATIONS_TYPES.SEARCH_ORGANIZATIONS.SUCCESS, payload: response });
    })
    .catch(error => {
      dispatch({ type: ORGANIZATIONS_TYPES.SEARCH_ORGANIZATIONS.FAILURE });
      console.error(error);
    });
};

export const updateOrganization = (id, data) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORGANIZATIONS_TYPES.UPDATE_ORGANIZATION.REQUEST,
    });

    const res = await organizationServices.updateOrganization(id, data);

    dispatch({
      type: ORGANIZATIONS_TYPES.UPDATE_ORGANIZATION.SUCCESS,
      payload: res.data[0],
    });
    return res.data[0];
  } catch (error) {
    dispatch({
      type: ORGANIZATIONS_TYPES.UPDATE_ORGANIZATION.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getOrganizationMembers = (organizationId, page, pageSize) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORGANIZATIONS_TYPES.GET_ORGANIZATION_MEMBERS.REQUEST,
    });
    const currentOrg = getState().auth.currentOrganization; // JSON.parse(localStorage.getItem('OTTERFISH_CURRENT_ORGANIZATION'));
    const params = {};
    params.page = page;
    params.page_size = pageSize;

    const data = await organizationServices.getOrganizationMembers(organizationId || currentOrg.organization_id, params);

    dispatch({
      type: ORGANIZATIONS_TYPES.GET_ORGANIZATION_MEMBERS.SUCCESS,
      payload: data.data,
      totalCount: data.total_count,
    });
    return data;
  } catch (error) {
    dispatch({
      type: ORGANIZATIONS_TYPES.GET_ORGANIZATION_MEMBERS.FAILURE,
      payload: error.response.data,
    });
    return error;
  }
};

export const getInvitedMembers = (organizationId, page, pageSize) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORGANIZATIONS_TYPES.GET_INVITED_MEMBERS.REQUEST,
    });
    const currentOrg = getState().auth.currentOrganization; // JSON.parse(localStorage.getItem('OTTERFISH_CURRENT_ORGANIZATION'));

    const params = {};
    params.page_size = pageSize;
    params.page = page;

    const data = await organizationServices.getInvitedMembers(organizationId || currentOrg.organization_id, params);

    dispatch({
      type: ORGANIZATIONS_TYPES.GET_INVITED_MEMBERS.SUCCESS,
      payload: data.data,
      totalCount: data.total_count,
    });
    return data;
  } catch (error) {
    dispatch({
      type: ORGANIZATIONS_TYPES.GET_INVITED_MEMBERS.FAILURE,
      payload: error.response.data,
    });
    return error;
  }
};

export const getAllMembers = (organizationId, page, pageSize, status) => async dispatch => {
  try {
    dispatch({
      type: ORGANIZATIONS_TYPES.GET_ALL_MEMBERS.REQUEST,
    });

    const params = {};
    if (page) params.page = page;
    if (pageSize) params.page_size = pageSize;
    if (status) params.status_in_organization = status;

    const data = await organizationServices.getAllMembers(organizationId, params);

    dispatch({
      type: ORGANIZATIONS_TYPES.GET_ALL_MEMBERS.SUCCESS,
      payload: data.data,
      totalCount: data.total_count,
    });
  } catch (error) {
    dispatch({
      type: ORGANIZATIONS_TYPES.GET_ALL_MEMBERS.FAILURE,
      payload: error.response.data,
    });
  }
};

export const changeUserStatus = (organizationId, userIds, status) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORGANIZATIONS_TYPES.CHANGE_USER_STATUS.REQUEST,
    });

    const data = await organizationServices.changeUserStatus(organizationId, userIds, status);
    const payload = getState().organizations.organizationMembers.data.map(item =>
      item.id === data.data[0].user_id ? { ...item, status_in_organization: data.data[0].status } : item
    );

    dispatch({
      type: ORGANIZATIONS_TYPES.CHANGE_USER_STATUS.SUCCESS,
      payload: payload,
    });
    return payload;
  } catch (error) {
    dispatch({
      type: ORGANIZATIONS_TYPES.CHANGE_USER_STATUS.FAILURE,
      payload: error.response.data,
    });
    return error;
  }
};

export const getOrganizationOverview = organizationId => async dispatch => {
  try {
    dispatch({
      type: ORGANIZATIONS_TYPES.GET_ORGANIZATION_OVERVIEW.REQUEST,
    });

    const res = await organizationServices.getOrganizationOverview(organizationId);

    dispatch({
      type: ORGANIZATIONS_TYPES.GET_ORGANIZATION_OVERVIEW.SUCCESS,
      payload: res.data[0],
    });
    return res.data[0];
  } catch (error) {
    dispatch({
      type: ORGANIZATIONS_TYPES.GET_ORGANIZATION_OVERVIEW.FAILURE,
      payload: error.response.data,
    });
  }
};

export const deleteMemberInvite = (organizationId, invitationId) => async dispatch => {
  try {
    dispatch({
      type: ORGANIZATIONS_TYPES.DELETE_MEMBER_INVITE.REQUEST,
    });

    const res = await organizationServices.deleteMemberInvite(organizationId, invitationId);
    dispatch(getInvitedMembers(organizationId));
    dispatch({
      type: ORGANIZATIONS_TYPES.DELETE_MEMBER_INVITE.SUCCESS,
    });
    return res.data[0];
  } catch (error) {
    dispatch({
      type: ORGANIZATIONS_TYPES.DELETE_MEMBER_INVITE.FAILURE,
      payload: error.response.data,
    });
  }
};

export const createOrganization = (type, name, email, phone) => async dispatch => {
  try {
    dispatch({
      type: ORGANIZATIONS_TYPES.ADD_ORGANIZATION.REQUEST,
    });

    const params = {};
    if (name) params.name = name;
    if (email) params.contact_email = email;
    if (phone) params.contact_phone_number = phone;
    if (type) params.type = type;

    const data = await organizationServices.createOrganization(params);

    dispatch({
      type: ORGANIZATIONS_TYPES.ADD_ORGANIZATION.SUCCESS,
      payload: data.data,
    });

    return data.data;
  } catch (error) {
    dispatch({
      type: ORGANIZATIONS_TYPES.ADD_ORGANIZATION.FAILURE,
      payload: error.response.data,
    });
  }
};

export const clearFailure = () => async dispatch => {
  try {
    dispatch({
      type: ORGANIZATIONS_TYPES.CLEAR_FAILURE,
    });
  } catch (err) {}
};

export const changeMemberRole = (organizationId, data) => async dispatch => {
  try {
    dispatch({ type: ORGANIZATIONS_TYPES.CHANGE_MEMBER_ROLE.REQUEST });
    await organizationServices.changeMemberRole(organizationId, data);

    dispatch({ type: ORGANIZATIONS_TYPES.CHANGE_MEMBER_ROLE.SUCCESS });

    const notification = notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.MEMBER_ROLE_CHANGING_SUCCESS, {});
    dispatch(createNotification(notification));
  } catch (e) {
    dispatch({ type: ORGANIZATIONS_TYPES.CHANGE_MEMBER_ROLE.FAILURE });

    if (e instanceof Error && e.response.status === 400) {
      const notification = notificationDataConfig.getNotificationData(TOAST_NOTIFICATION_TYPES.MEMBER_ROLE_CHANGING_ERROR, {
        text: [e.response.data.message],
      });

      dispatch(createNotification(notification));
    }
  }
};

export const updateOrganizationPricingPlan = (organizationId, data) => async dispatch => {
  try {
    dispatch({ type: ORGANIZATIONS_TYPES.UPDATE_ORGANIZATION_PRICING_PLAN.REQUEST });
    await organizationServices.updateOrganizationPricingPlan(organizationId, data);
    dispatch({ type: ORGANIZATIONS_TYPES.UPDATE_ORGANIZATION_PRICING_PLAN.SUCCESS });
    return true;
  } catch (e) {
    if (e instanceof Error) {
      dispatch({ type: ORGANIZATIONS_TYPES.UPDATE_ORGANIZATION_PRICING_PLAN.FAILURE, payload: e.message });
    }
  }
};
