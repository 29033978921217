import React, { useCallback, useEffect, useMemo } from 'react';
import styles from './OrganizationDetailsForm.module.scss';
import Button from '../../../expert/components/Buttons/ButtonCustom';
import TextField from '../../../components/FormControls/TextField';
import ErrorModal from '../../../expert/components/Modal/ErrorModal';
import { useDispatch } from 'react-redux';
import { clearFailure } from '../../../actions/auth.actions';
import LeftPartSignUp from '../../../components/AuthTwoSideLayout/LeftPartSignUp';
import AuthTwoSideLayout from '../../../components/AuthTwoSideLayout';
import cs from 'classnames';
import SelectDropdown from '../../../expert/components/FormControls/SelectDropdown';
import { useMediaQuery } from 'react-responsive/src';
import { MEMBER_ROLE_IN_ORGANIZATION_OBJECTS } from '../../../models/enums/MemberRoleInOrganizationTypes';
import ButtonLightPurpleWhite from '../../../components/Buttons/ButtonLightPurpleWhite';
import { useAppSelector } from '../../../shared/hooks/reduxHooks';
import _ from 'lodash';
import { FormikProps } from 'formik';
import Loader from '../../../expert/components/Loader';
import { CompleteOrganizationPageFormikValues } from '../index';
import { ORGANIZATION_TYPES } from '../../../models/enums/OrganizationTypes';

type PropsType = {
  onSubmit: () => void;
  formik: FormikProps<CompleteOrganizationPageFormikValues>;
  isSubmitLoading: boolean;
};

const companyTextConfig = {
  [ORGANIZATION_TYPES.BRAND]: 'Brand Name',
  [ORGANIZATION_TYPES.ADVERTISER]: 'Brand Name',
  [ORGANIZATION_TYPES.INFLUENCER]: 'Brand Name',
  [ORGANIZATION_TYPES.AGENCY]: 'Agency Name',
  [ORGANIZATION_TYPES.TALENT_MANAGER]: 'Agency Name',
  [ORGANIZATION_TYPES.CONTENT_CREATOR]: 'Workspace Name',
};

const OrganizationDetailsForm = ({ onSubmit, formik, isSubmitLoading }: PropsType) => {
  const dispatch = useDispatch();
  const organzationType = useAppSelector<ORGANIZATION_TYPES | null>(state => state.auth.currentOrganization?.organization_type || null);
  const authError = useAppSelector(state => state.auth.failure);
  const plan = useAppSelector(state => state.auth.currentOrganizationPlan);
  const loading = useAppSelector(state => state.auth.signUp.loading);
  const isLaptop = useMediaQuery({ query: '(max-width: 991px)' });

  const canAddFirstMember = useMemo(() => {
    return (plan?.team_members && plan?.team_members > 1) || plan?.team_members === -1;
  }, [plan?.team_members]);

  const canAddNewMember = useMemo(() => {
    return plan?.team_members === -1 || plan?.team_members - 1 > formik.values.inviteTeamMembers.length;
  }, [plan, formik]);

  const addNewMember = (isInit = false) => {
    formik.setFieldValue('inviteTeamMembers', [
      ...(isInit ? [] : formik.values.inviteTeamMembers),
      {
        id: _.uniqueId('invite_member_id_'),
        email: null,
        company_type: null,
      },
    ]);
  };

  useEffect(() => {
    if (canAddFirstMember) {
      addNewMember(true);
    }
  }, [plan?.team_members]);

  const companyText = companyTextConfig[organzationType || ORGANIZATION_TYPES.BRAND];

  return (
    <>
      <AuthTwoSideLayout leftPartContent={<LeftPartSignUp />} isLaptop={isLaptop}>
        <div className={styles.root}>
          {/* <div className={styles.logo}>*/}
          {/*  <LogoSVG className={styles.logoSvg} />*/}
          {/* </div>*/}
          <div className={styles.content}>
            <div className={styles.title}>Set up your organization</div>

            <div className={styles.subTitle}>{companyText}</div>
            <div className={styles.row}>
              <TextField
                wrapperClassName={styles.inputWrapper}
                className={styles.input}
                name="company_name"
                value={formik.values.company_name}
                placeholder={companyText}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                isValid={!(formik.touched.company_name && formik.errors.company_name)}
              />
              <div className={styles.errorMessage}>{!!formik.touched.company_name && formik.errors.company_name}</div>
            </div>

            {!!formik.values.inviteTeamMembers.length && (
              <div className={styles.members}>
                <div className={styles.subTitle}>Invite Team Members</div>

                {formik.values.inviteTeamMembers.map((item, idx) => (
                  <MemberInviteRow key={item.id} formik={formik} item={item} index={idx} />
                ))}

                {canAddNewMember && (
                  <ButtonLightPurpleWhite className={styles.inviteMoreButton} presetIcon="plus" onClick={() => addNewMember()}>
                    Invite More
                  </ButtonLightPurpleWhite>
                )}
              </div>
            )}

            <div className={styles.navigationButtons}>
              <Button
                className={styles.signUpButton}
                disabled={isSubmitLoading || !formik.isValid}
                onClick={() => onSubmit()}
                loading={loading}
              >
                Done
              </Button>
            </div>
          </div>
          <ErrorModal
            open={!!authError.message}
            text={authError?.errors?.body?.[0]?.message}
            onClose={() => {
              dispatch(clearFailure());
            }}
            title={'Oops, something went wrong.'}
          />
        </div>
      </AuthTwoSideLayout>
      {isSubmitLoading && (
        <div className={styles.loader}>
          <Loader textContent="We are completing your profile. You will be ready to go in seconds" />
        </div>
      )}
    </>
  );
};

const MemberInviteRow = ({ formik, item, index }) => {
  const getValue = useCallback(
    (object, fieldName) => {
      return object.inviteTeamMembers?.[index]?.[fieldName] || '';
    },
    [formik, index]
  );

  const handleChangeValue = (value, fieldName) => {
    const currentMember = formik.values.inviteTeamMembers.find(member => item.id === member.id);
    const newInvites = formik.values.inviteTeamMembers.map(member => {
      if (member.id === currentMember.id) {
        const newMember = { ...member };
        newMember[fieldName] = value;
        return newMember;
      }
      return member;
    });
    formik.setFieldValue('inviteTeamMembers', newInvites);
  };

  return (
    <div className={cs(styles.memberInviteRow, styles.doubleField)}>
      <div className={styles.half}>
        <TextField
          wrapperClassName={styles.inputWrapper}
          className={cs(styles.textField, styles.input)}
          name="email"
          value={getValue(formik.values, 'email')}
          placeholder="Email Address"
          onBlur={formik.handleBlur}
          onChange={e => handleChangeValue(e.target.value, 'email')}
          isValid={!(getValue(formik.touched, 'email') && getValue(formik.errors, 'email'))}
          error={getValue(formik.errors, 'email')}
        />
      </div>
      <div className={styles.half}>
        <SelectDropdown
          // @ts-ignore
          name="company_type"
          options={MEMBER_ROLE_IN_ORGANIZATION_OBJECTS}
          placeholder="Choose Member Role"
          optionKey={item => item?.name}
          renderOption={item => item?.name}
          className={styles.dropdown}
          onSelected={item => handleChangeValue(MEMBER_ROLE_IN_ORGANIZATION_OBJECTS[item].type, 'type')}
          selectedId={MEMBER_ROLE_IN_ORGANIZATION_OBJECTS.findIndex(item => item.type === getValue(formik.values, 'type'))}
        />
      </div>
    </div>
  );
};

export default OrganizationDetailsForm;
