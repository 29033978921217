import React, { ComponentProps, FC } from 'react';
import RoundedWhiteCard from '../../../../../../../components/Cards/RoundedWhiteCard';
import styles from './BriefTextCard.module.scss';

type PropsType = Omit<ComponentProps<typeof RoundedWhiteCard>, 'headerClass' | 'rootClass'>;

const BriefTextCard: FC<PropsType> = props => {
  return <RoundedWhiteCard rootClass={styles.root} headerClass={styles.header} {...props} bodyClass={styles.body} />;
};

export default BriefTextCard;
