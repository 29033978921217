import React, { FC, MouseEventHandler } from 'react';
import styles from './VisualHover.module.scss';
import { FaSearchPlus } from 'react-icons/fa';
import cs from 'classnames';

type PropsType = {
  onClick: MouseEventHandler<HTMLDivElement>;
  className?: string;
};

const VisualHover: FC<PropsType> = ({ onClick, className }) => {
  return (
    <div className={cs(styles.root, className)} onClick={onClick}>
      <FaSearchPlus />
    </div>
  );
};

export default VisualHover;
