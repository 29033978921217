import { IDeliverableFormData } from '../../../../pages/ContentApproval/ContentBoard/components/AddDeliverablesModal/hooks/useDeliverablesFormData';
import { PLATFORMS } from '../../../enums/Platforms';
import { PLACEMENTS } from '../../../enums/Placements';
import { IDeliverable } from '../Deliverable';
import _ from 'lodash';
import moment from 'moment';

const apiFormat = 'YYYY-MM-DDTHH:mm:ssZ';

export class DeliverablesFormHydrator implements IDeliverableFormData {
  id: string;
  name: string;
  platform: PLATFORMS;
  placement: PLACEMENTS;
  live_datetime: string | null;
  concept_due_datetime: string | null;
  content_due_datetime: string | null;
  concept_reminder_datetime: string | null;
  content_reminder_datetime: string | null;

  constructor(deliverable: IDeliverable, userTimezone: string) {
    this.id = _.uniqueId('create_deliverable_');
    this.name = deliverable.name;
    this.platform = deliverable.platform;
    this.placement = deliverable.placement;
    this.live_datetime = deliverable.live_datetime ? moment.utc(deliverable.live_datetime).tz(userTimezone).format(apiFormat) : null;
    this.concept_due_datetime = deliverable.concept_due_datetime
      ? moment.utc(deliverable.concept_due_datetime).tz(userTimezone).format(apiFormat)
      : null;
    this.content_due_datetime = deliverable.content_due_datetime
      ? moment.utc(deliverable.content_due_datetime).tz(userTimezone).format(apiFormat)
      : null;
    this.concept_reminder_datetime = deliverable.concept_reminder_datetime
      ? moment.utc(deliverable.concept_reminder_datetime).tz(userTimezone).format(apiFormat)
      : null;
    this.content_reminder_datetime = deliverable.content_reminder_datetime
      ? moment.utc(deliverable.content_reminder_datetime).tz(userTimezone).format(apiFormat)
      : null;
  }
}
