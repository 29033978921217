import React, { CSSProperties, FC, PropsWithChildren } from 'react';
import { useMediaQuery } from 'react-responsive/src';
import styles from './CalendarWrapper.module.scss';
import { Modal } from '../../../../Modals';

export enum MODALS_VERSION {
  ONLY_MODAL = 'only modal',
  HYBRID = 'hybrid',
}

type PropsType = {
  expanded: boolean;
  onCloseModal: () => void;
  modalVersion: MODALS_VERSION;
  maxWidth?: CSSProperties['maxWidth'];
} & PropsWithChildren;

const CalendarWrapper: FC<PropsType> = ({ children, expanded, onCloseModal, modalVersion, maxWidth }) => {
  const isNotebook = useMediaQuery({ query: '(max-width: 1200px)' });

  if (isNotebook || modalVersion === MODALS_VERSION.ONLY_MODAL) {
    return (
      <Modal
        className={styles.modal}
        maxWidth={maxWidth}
        borderRadius="10px"
        overflow={'hidden'}
        onClose={() => onCloseModal()}
        open={expanded}
      >
        {children}
      </Modal>
    );
  }

  return <div>{children}</div>;
};

export default CalendarWrapper;
