import React, { FC, useMemo } from 'react';
import styles from './AddedFileOrBrief.module.scss';
import { ReactComponent as CheckedSVG } from '../../../../../assets/img/icons/checked.svg';
import LogoSVG from '../../../../../assets/img/icons/otterfish_smal_logo.svg';
import { ReactComponent as EditSVG } from '../../../../../assets/img/icons/edit_pen.svg';
import { ReactComponent as CloseSvg } from '../../../../../assets/img/close_purple.svg';
import moment from 'moment';
import { ICreateBoardFormikValues } from '../../CreateBoardWrapper/CreateBoard/useCreateBoardFormik';
import PDFFileSVG from '../../../../../assets/img/icons/PDFFile.svg';
import WordFileSVG from '../../../../../assets/img/icons/DOCFile.svg';
import GrayFileSVG from '../../../../../assets/img/icons/GrayFile.svg';
import ContractFileSVG from '../../../../../assets/img/icons/ContractFile.svg';
import ButtonLightPurpleWhite from '../../../../../components/Buttons/ButtonLightPurpleWhite';
import { formatBytes } from '../../../../../shared/utils/helpers';
import cs from 'classnames';
import { useMediaQuery } from 'react-responsive';
import FileLoadingBar from '../FileLoadingBar';
import { IPreloadingFile, PreloadingFile } from '../../../hooks/useFileUploadWithLoading';

type PropsType = {
  value: ICreateBoardFormikValues['createdBrief'] | File | null;
  onDelete: () => void;
  onEdit?: () => void;
  onChangeFile: () => void;
  loadingPercentageProgress?: number;
  preloadingFile?: IPreloadingFile | null;
  isUploading?: boolean;
  type: 'brief' | 'contract';
  isBoardBrief?: boolean;
};

type RenderRightSettings = {
  onDelete: () => void;
  onEdit?: () => void;
  isTablet?: boolean;
};

const config = {
  getFileConfig: (file: File) => {
    const name = file.name.split('.').slice(0, -1).join('');
    const extension = file.name.split('.').slice(-1)[0];
    const fileSize = formatBytes(file.size);
    const image = file.type.endsWith('pdf') ? PDFFileSVG : WordFileSVG;

    return {
      rootClassName: styles.fileRoot,
      image,
      dateDescription: 'Uploaded',
      renderName: () => (
        <div className={styles.fileNameWrapper}>
          <p className={styles.name}>{name}</p>
          <p className={styles.name}>.{extension}</p>
        </div>
      ),
      renderRight: (settings: RenderRightSettings) => (
        <>
          <p className={styles.fileSize}>{fileSize}</p>
          <div className={styles.buttons}>
            <div onClick={settings.onDelete}>
              <CloseSvg />
            </div>
          </div>
        </>
      ),
      withReplaceButton: true,
    };
  },
  getOtterfishBriefConfig: (brief: ICreateBoardFormikValues['createdBrief'], isBoardBrief: boolean) => ({
    rootClassName: styles.briefRoot,
    image: LogoSVG,
    dateDescription: 'Created',
    renderName: () => <p className={styles.name}>{brief?.itemName}</p>,
    renderRight: (settings: RenderRightSettings) => (
      <>
        {!settings.isTablet ? <>{!isBoardBrief ? <div className={styles.briefType}>Campaign brief</div> : <div />}</> : null}

        <div className={styles.buttons}>
          <div onClick={settings?.onEdit}>
            <EditSVG />
          </div>
          <div onClick={settings.onDelete}>
            <CloseSvg />
          </div>
        </div>
      </>
    ),
    withReplaceButton: false,
  }),
  getFileLoadingConfig: (file: IPreloadingFile, type: 'brief' | 'contract') => {
    const fileSize = `${formatBytes(file.loaded)}/${formatBytes(file.total)}`;
    const image = type === 'brief' ? GrayFileSVG : ContractFileSVG;

    return {
      rootClassName: styles.fileRoot,
      image,
      dateDescription: 'Uploaded',
      renderName: () => (
        <div className={styles.fileNameWrapper}>
          <p className={styles.name}>{'Loading...'}</p>
        </div>
      ),
      renderRight: (_settings: RenderRightSettings) => (
        <>
          <p className={styles.fileSize}>{fileSize}</p>
          <div className={styles.buttons}>
            <div className={styles.disabledButton}>
              <CloseSvg />
            </div>
          </div>
        </>
      ),
      withReplaceButton: false,
    };
  },
};

const AddedFileOrBrief: FC<PropsType> = props => {
  const isTablet: boolean = useMediaQuery({ query: '(max-width: 768px)' });

  const viewConfig = useMemo(() => {
    if (props.preloadingFile instanceof PreloadingFile && props.isUploading) {
      return config.getFileLoadingConfig(props.preloadingFile, props.type);
    }

    if (props.value instanceof File) {
      return config.getFileConfig(props.value);
    }

    return config.getOtterfishBriefConfig(props.value, !!props.isBoardBrief);
  }, [props.value, props.preloadingFile, props.isUploading]);

  return (
    <>
      <div className={cs(styles.root, viewConfig.rootClassName)}>
        <div className={styles.checkBox}>
          <CheckedSVG />
        </div>
        <div className={styles.contentContainer}>
          <img className={styles.fileLogo} src={viewConfig.image} alt={'current'} />
          <div className={styles.infoContainer}>
            {viewConfig.renderName()}
            <div className={styles.date}>
              {viewConfig.dateDescription} {moment().format('DD MMM YYYY')}
            </div>
          </div>
        </div>
        {viewConfig.renderRight({ onEdit: props.onEdit, onDelete: props.onDelete, isTablet })}
        {props.loadingPercentageProgress !== undefined && props.isUploading ? (
          <div className={styles.loadingBar}>
            <FileLoadingBar percentageProgress={props.loadingPercentageProgress} />
          </div>
        ) : null}
      </div>
      {viewConfig.withReplaceButton ? (
        <ButtonLightPurpleWhite className={styles.replaceButton} onClick={props.onChangeFile} size={'medium'} presetIcon={'replace'}>
          Replace
        </ButtonLightPurpleWhite>
      ) : null}
    </>
  );
};

export default AddedFileOrBrief;
